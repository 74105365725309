<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
      <div style="
          padding: 16px 16px;
          height: calc(100vh - 100px);
          background-color: rgb(0, 10, 50);
          overflow-y: scroll;
          padding-bottom: 30px;
          background-color: #000a32;
          color: #fff;
        " class="theme-common">
        <div class="box">
          <div class="w-full p-5">
            <div class="w-full">
              <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
               
  
                <a-form-item :label="langObj['消息时间']" name="status">
                  <a-range-picker v-model:value="searchModel.date" :disabled-date="disabledDate"
                    @calendarChange="onCalendarChange" />
                </a-form-item>
  
                <a-form-item :label="langObj['消息类型']" name="status">
                  <a-select v-model:value="searchModel.type" style="width: 220px" allowClear
                    :placeholder="langObj['请选择消息类型']">
                    <a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value">
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
  
                <!-- <a-form-item :label="langObj['严重程度']" name="status">
                  <a-select v-model:value="searchModel.condition" style="width: 220px" allowClear
                    :placeholder="langObj['请选择严重程度']">
                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item> -->
  
                <!-- <a-form-item :label="langObj['是否推送成功']" name="status">
                  <a-select v-model:value="searchModel.expertCertification" style="width: 220px" allowClear
                    :placeholder="langObj['是否推送成功']">
                    <a-select-option v-for="(item, index) in expertCertificationList" :key="index" :value="item.value">
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item> -->
  
                <a-form-item>
                  <div class="flex items-center">
                    <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                      {{ langObj["重置"] }}
                    </div>
                    <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                      style="background-color: #072499">
                      {{ langObj["查询"] }}
                    </div>
                  </div>
                </a-form-item>
              </a-form>
            </div>
  
            <div class="box mt-4" style="width: 100%">
              <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="warningColumns"
                :data-source="machineWarningData" :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }" :pagination="pagination" @change="handleTableChange" rowKey="id">
                <template #bodyCell="{ column, text, record }">
                  <template v-if="column.dataIndex === 'type'">
                    <div class="flex items-center">
                      <div v-if="text === 4">
                        <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                      </div>
                      <div v-if="text === 5">
                        <img style="width: 20px" src="@/assets/screen/cgq_warn_bg.png" alt="" />
                      </div>
                      <div v-if="text === 6">
                        <img style="width: 20px" src="@/assets/screen/cjdy_warn_bg.png" alt="" />
                      </div>
                      <div v-if="text === 7">
                        <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                      </div>
                      <div class="ml-2">
                        {{
                          langObj[typeList.find((p) => p.value === text)?.label]
                        }}
                      </div>
                    </div>
                  </template>
                  <template v-if="column.dataIndex === 'members'">
                    <!-- {{ text ? langObj["是"] : langObj["否"] }} -->
                      <span v-for="(item,index) in record.members ">{{item.nickName}},</span>
                  </template>
  
                 
  
                 
                </template>
              </a-table>
            </div>
          </div>
        </div>
  
        <a-drawer v-model:visible="visibleDR" class="custom-class" size="large" :closable="false" placement="right">
          <template #title> </template>
          <DiagnosticReportDetails ref="mrDref" :reportId="reportDRId" @cancel="cancelDR">
          </DiagnosticReportDetails>
        </a-drawer>
      </div>
    </a-config-provider>
  </template>
  
  
  <script lang="ts" setup>
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
  } from "vue";
  import { useRouter } from "vue-router";
  import request from "../../common/request";
  import {
    transformDate,
    transformDate2,
    getGroupListByFactory,
    getRealFactoryIdList,
    getGroupName
  } from "../../common/tools";
  import { v4 as uuidv4 } from "uuid";
  import { message } from "ant-design-vue";
  import DownLoadComponent from "../components/downLoad.vue";
  import { Dayjs } from "dayjs";
  import { langList } from "../../common/lang";
  import enUS from "ant-design-vue/es/locale/en_US";
  import zhCN from "ant-design-vue/es/locale/zh_CN";
  import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
  import { getMemberOnboarding } from "../../common/tools";
  const router = useRouter();
  const getLang = () => {
    language.value = localStorage.getItem("language") || "Chinese";
    langObj.value = langList[language.value];
  };
  let typeList = [
    { label: "智能诊断", value: 4 },
    // {label: '维修记录', value: 2},
    // {label: '设备检查', value: 3},
    { label: "门限报警", value: 5 }, 
    { label: "传感器报警", value: 7 },
    { label: "采集单元报警", value: 6 },

  ];
  let langObj: any = ref({});
  let language: any = ref("Chinese");
  getLang();
  
  let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
  ];
  let conditionList2 = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用上", value: 1, color: "#d2de49" },
    { label: "可用下", value: 2, color: "#d2de49" },
    { label: "警戒上", value: 3, color: "#cd5f3b" },
    { label: "警戒中", value: 4, color: "#cd5f3b" },
    { label: "警戒下", value: 5, color: "#cd5f3b" },
    { label: "故障上", value: 6, color: "#921e37" },
    { label: "故障下", value: 7, color: "#921e37" },
  ];
  let conditionLists = [
    { label: "健康", value: 1, real: [0] },
    { label: "可用", value: 2, real: [1, 2] },
    { label: "警戒", value: 3, real: [3, 4, 5] },
    { label: "故障", value: 4, real: [6, 7] },
  ];
  let conditionAdd = [
    { label: "健康", value: 0 },
    { label: "可用", value: 1 },
    { label: "警戒", value: 3 },
    { label: "故障", value: 6 },
  ];
  let statusList = [
    { label: "未处理", value: 1 },
    { label: "已处理", value: 2 },
    { label: "已关闭", value: 3 },
  ];
  
  let expertCertificationList = [
    { value: null, label: "不限" },
    {
      value: true,
      label: "是",
    },
    { value: false, label: "否" },
  ];
  let warningColumns = [
    {
      title: langObj.value["消息时间"],
      dataIndex: "createAt",
    },
    {
      title: langObj.value["消息类型"],
      dataIndex: "type",
    },
    
  
    {
      title: langObj.value["消息内容"],
      dataIndex: "message",
    },
    {
      title: langObj.value["接收人"],
      dataIndex: "members",
    },
    // {
    //   title: langObj.value["推送平台"],
    //   dataIndex: "pushPlat",
    // },
    // {
    //   title: langObj.value["是否推送成功"],
    //   dataIndex: "isPushSuccess",
    // },
   
  
  
  ];
  const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
  }));
  let data: any = ref([]);
  let searchModel: any = ref({
    
    date: null,
    skip: 1,
    take: 10,
   
    handleStatus: null,
    expertCertification: null,
    type:null
  });
  let selectedRowKeys: any = ref();
  let groupList: any = ref([]);
  let formClose: any = ref({
    closeCause: "",
  });

  
  onMounted(() => {
    // getAdmin();
    // getCount();
    search();
    getLang();
  
    
  
  });
  
 
  
  
  const search = () => {
    searchModel.value.skip = 1;
    // getData();
    getMachineWarning()
  };
  
  const reset = () => {
    searchModel.value = {
      ...searchModel.value,
      date:null,
      handleStatus: null,
      type:null
    };
    search()
  };
 
  
 let machineWarningData :any=ref([])
 let warningTotal :any = ref(0)
  const getMachineWarning = async () => {
      let config: any = {
        params: {
          type:searchModel.value.type,
          skip:searchModel.value.skip,
          take:searchModel.value.take
          // status: 0
        },

        headers: {
          requestId: uuidv4(),
        },
      };
      if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }
     

      let result = await request.get("/api/sys-alarm/messages", config);
      if (result && result.status) {
        machineWarningData.value = result?.data?.list;
        searchModel.value.total = result.data.count;
      }
    };


 
  
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    // if (Object.keys(sorter).length) {
    //   var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    //   searchModel.value.sort = sorter.field;
    //   searchModel.value.order = order;
    // }
    // 处理分页数据
    searchModel.value.skip = pagination.current;
    searchModel.value.take = pagination.pageSize;
    getMachineWarning();
  };
  

  

  
  //复选框选择
  const onSelectChange = (ev: any) => {
    selectedRowKeys.value = ev;
  };
  
  
  
 
  
  // 查看已关闭
  let visibledel2 = ref(false);
  const viewClose = (ev: any) => {
    formClose.value.closeCause = ev.closeCause;
    visibledel2.value = true;
  };
  
  //查看报告
  let visibleDR: any = ref(false);
  let reportDRId: any = ref("");
  let mrDref: any = ref();
  const viewReport = (ev: any) => {
    if (ev.reportIds.length) {
      visibleDR.value = true;
      reportDRId.value = ev.reportIds[0];
      setTimeout(() => {
        mrDref.value.show(reportDRId.value);
      }, 500);
    } else {
      message.warning("暂无报告");
    }
    // router.push('/report/list')
  };
  
  const cancelDR = () => {
    visibleDR.value = false;
  };
  
  const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? "even-row" : "odd-row";
  };
  
  const dates = ref<any>();
  
  const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
      return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
    return tooEarly || tooLate;
  };
  
  const onCalendarChange = (val: any) => {
    dates.value = val;
  };
  
  </script>
  
  
  
  <style lang="less" scoped>
  .box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
  }
  
  .txt-select {
    border-bottom: 2px solid #00fff4;
  }
  
  .btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
  }
  
  .fff {
    color: #fff;
  }
  
  /deep/ .ant-table {
    background: #020f46;
    color: #fff;
  }
  
  /deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background: transparent;
  }
  
  /deep/ .ant-empty-normal {
    color: #fff;
  }
  
  /deep/ .ant-descriptions-item-content {
    color: #fff;
  }
  
  /deep/ .ant-table-tbody>tr>td {
    border-bottom: 0px solid #fff;
  }
  
  /deep/ .ant-picker-input>input {
    color: #fff;
  }
  
  /deep/ .ant-picker-clear span {
    background: #000 !important;
  }
  
  /deep/ .ant-drawer-close span svg {
    color: #000 !important;
  }
  
  /deep/ .ant-select-clear span {
    background-color: #000;
  }
  </style>
  