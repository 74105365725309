<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
        <div class="w-full  px-3  theme-bg theme-clique theme-common">
            <div class="theme-bg-img">
                <div class="p-tit">
                    <div class="p-text-color">{{ factory_name }}</div>
                </div>

                <div class="w-full flex center_box" style="margin-top: -2%;">


                    <!-- 左侧部分 -->
                    <div class="flex flex-col  box-size">
                        <div class="w-full state_wrap conditon_state">
                            <div class="title flex items-center">
                                <div></div>
                                <div class="p-text-color2 ml-10">{{ langObj['健康状态统计'] }}</div>
                            </div>

                            <div class="w-full flex items-center bg_wrap justify-center" style="padding: 2% 0;">
                                <div style="width: 60%; height: 18vh;margin-top:-10%;;position:relative">
                                    <div class="pie_tex_wrap">
                                        <div>{{ machinesAllV }}<span>{{ langObj['台'] }}</span></div>
                                        <div> {{ langObj['设备总数'] }}</div>
                                    </div>
                                    <div style="margin:0 auto">
                                        <My3DPie style="z-index:2" :optionsData="optionsData" />
                                    </div>

                                    <div class="pie_bg_wrap"></div>

                                </div>


                                <div>
                                    <div v-if="isShowRate" class="flex items-center">
                                    <div > {{ '可用率' }}：</div>
                                    <div>{{ availabilityRatio }}%</div>
                                </div>
                                
                                
                                    <div class="flex items-center cursor-pointer tl_state_wrap" @click="selectMachineCondition(1)">
                                        <div style="width:14px; height:14px; background-color: #61c08f"></div>
                                        <div class="ml-2"> {{ langObj['健康'] }}：</div>
                                        <div>{{ conditionChartData[0].value }}{{ getLanguage("台", language) }}</div>
                                    </div>
                                    <div class="rate_wrap">({{ getLanguage('健康率', language.value) }}{{ conditionChartData[0].rate }}%)</div>
                                    <div class="flex items-center cursor-pointer mt-3 tl_state_wrap" @click="selectMachineCondition(2)">
                                        <div style="width:14px; height:14px; background-color: #d2de49"></div>
                                        <div class="ml-2"> {{ langObj['可用'] }}：</div>
                                        <div>{{ conditionChartData[1].value }}{{ getLanguage("台", language) }}</div>
                                    </div>
                                    <div class="rate_wrap">({{ getLanguage('可用率', language.value) }}{{ conditionChartData[1].rate }}%)</div>
                                    <div class="flex items-center cursor-pointer mt-3 tl_state_wrap" @click="selectMachineCondition(3)">
                                        <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                                        <div class="ml-2"> {{ langObj['警戒'] }}：</div>
                                        <div>{{ conditionChartData[2].value }}{{ getLanguage("台", language) }}</div>
                                    </div>
                                    <div class="rate_wrap">({{ getLanguage('警戒率', language.value) }}{{ conditionChartData[2].rate }}%)</div>
                                    <div class="flex items-center cursor-pointer mt-3 tl_state_wrap" @click="selectMachineCondition(4)">
                                        <div style="width:14px; height:14px; background-color: #921e37"></div>
                                        <div class="ml-2"> {{ langObj['故障'] }}：</div>
                                        <div>{{ conditionChartData[3].value }}{{ getLanguage("台", language) }}</div>
                                    </div>
                                    <div class="rate_wrap">({{ getLanguage('故障率', language.value) }}{{ conditionChartData[3].rate }}%)</div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full state_wrap monitor_state mt-5">
                            <div class="title flex items-center">
                                <div></div>
                                <div class="p-text-color2 ml-10"> {{ langObj['设备监测统计'] }}</div>
                            </div>
                            <div class="w-full flex items-center bg_wrap">
                                <div class="moitor_clip cursor-pointer" @click="selectMachineStatus(1)">
                                    <img src="@/assets/screen/p_on_bg.png" />
                                    <div>{{ getLanguage('运行设备数', language) }}</div>
                                    <div><span>{{ statusChartData[0].num }}</span>{{ getLanguage("台", language) }}</div>
                                </div>
                                <div class="moitor_clip cursor-pointer">
                                    <img src="@/assets/screen/p_off_bg.png" @click="selectMachineStatus(0)" />
                                    <div class="off_text">{{langObj[stopLabel]}}{{ getLanguage('设备数', language) }}</div>
                                    <div><span>{{ statusChartData[1].num }}</span>{{ getLanguage("台", language) }}</div>
                                </div>
                                <div class="moitor_clip">
                                    <img src="@/assets/screen/p_monitor_bg.png" />
                                    <div class="monitor_text">{{ getLanguage('监测测点数', language) }}</div>
                                    <div><span>{{ statusChartData[2].num }}</span>{{ getLanguage("个", language) }}</div>
                                </div>
                            </div>
                        </div>

                        <div style="display:none" class="w-full state_wrap state_total mt-5">
                            <div class="title flex items-center">
                                <div class="p-text-color2 ml-10">{{ langObj['设备报警趋势'] }}</div>
                                <div class="flex-1"></div>
                                <a-dropdown>
                                    <div class="text-gray-50 flex items-center p-text-right" @click.prevent>

                                        {{ machineStatusTime == 2 ? langObj["本周"] : langObj["本月"] }}
                                        <CaretDownOutlined style="font-size: 16px;padding-left: 5px;" />
                                    </div>
                                    <template #overlay>
                                        <a-menu>
                                            <a-menu-item key="2" @click="getwarningTrendChartData2(2)">
                                                {{ langObj["本周"] }}
                                            </a-menu-item>
                                            <a-menu-item key="1" @click="getwarningTrendChartData2(1)">
                                                {{ langObj["本月"] }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </div>
                            <div class="w-full bg_wrap">
                                <div class="flex items-center" style="justify-content: center;padding-bottom: 2%;cursor:pointer">
                                    <div class="flex items-center ml-4 tl_text" @click="selectWarnTrend(2)">
                                        <div style="background-color: #d2de49"></div>
                                        <div class="ml-1">{{ langObj['可用报警'] }}</div>
                                    </div>
                                    <div class="flex items-center ml-4 tl_text" @click="selectWarnTrend(3)">
                                        <div style="background-color: #cd5f3b"></div>
                                        <div class="ml-1">{{ langObj['警戒报警'] }}</div>
                                    </div>
                                    <div class="flex items-center ml-4 tl_text" @click="selectWarnTrend(4)">
                                        <div style="background-color: #921e37"></div>
                                        <div class="ml-1">{{ langObj['故障报警'] }}</div>
                                    </div>

                                </div>
                                <div>
                                    <div id="chartD" style="width: 100%;height: 16vh"></div>
                                </div>
                            </div>
                        </div>

                        <div  class="w-full state_wrap yj_state mt-5">
                            <div class="title flex items-center">
                                <div></div>
                                <div class="p-text-color2 ml-10"> {{ langObj['硬件状态统计'] }}</div>
                            </div>
                            <div class="w-full items-center bg_wrap">
                                <div class="cgq_wrap flex items-center">
                                    <div class="cgq_clip img_clip_wrap">
                                        <img src="@/assets/screen/p_xj_bg.png" />
                                        <div class="mt-2">{{ getLanguage('传感器', language) }}</div>
                                    </div>
                                    <div class="flex-1">
                                        <div class="w-full flex">
                                            <div @click="selectYjState(1, null)" class="cgq_clip">
                                                <div class="text total_text">{{ yjStateData.sensorTotal }}</div>
                                                <div>{{ getLanguage('总数', language) }}</div>
                                            </div>
                                            <div @click="selectYjState(1, false)" class="cgq_clip">
                                                <div class="text low_text">{{ yjStateData.sensorLowNum }}</div>
                                                <div>{{ getLanguage('低电量', language) }}</div>
                                            </div>
                                            <div @click="selectYjState(1, true)" class="cgq_clip">
                                                <div class="text normal_text">{{ yjStateData.sensorNormalNum }}</div>
                                                <div>{{ getLanguage('正常', language) }}</div>
                                            </div>
                                        </div>
                                        <div class="charts_wrap" id="chartB1" style="height:5vh;width: 90%;margin-left: 5%;">

                                        </div>
                                    </div>
                                </div>
                                <div class="cgq_wrap flex items-center">
                                    <div class="cgq_clip img_clip_wrap">
                                        <img src="@/assets/screen/p_wg_bg.png" />
                                        <div class="mt-2">{{ getLanguage('采集单元', language) }}</div>
                                    </div>
                                    <div class="flex-1">
                                        <div class="w-full flex">
                                            <div @click="selectYjState(2, null)" class="cgq_clip">
                                                <div class="text total_text">{{ yjStateData.dasToatal }}</div>
                                                <div>{{ getLanguage('总数', language) }}</div>
                                            </div>
                                            <div @click="selectYjState(2, 0)" class="cgq_clip">
                                                <div class="text low_text">{{ yjStateData.dasOffLineNum }}</div>
                                                <div>{{ getLanguage('离线', language) }}</div>
                                            </div>
                                            <div @click="selectYjState(2, 1)" class="cgq_clip">
                                                <div class="text normal_text">{{ yjStateData.dasOnlineNum }}</div>
                                                <div>{{ getLanguage('在线', language) }}</div>
                                            </div>
                                        </div>
                                        <div class="charts_wrap" id="chartB2" style="height:5vh"></div>
                                    </div>
                                </div>


                            </div>
                        </div>




                    </div>
                    <!-- 中间部分 -->
                    <div class="flex-1 center_center_box">
                        <div class="w-full flex" style="justify-content: center">
                            <div class="title2">
                                <div class="flex center_text">
                                    <div>{{ weightedAverage }}</div>
                                    <div>{{ getLanguage('分', language) }}</div>
                                    <img v-if="weightedAverage >= 80" style="width: 1.7vw;" src="@/assets/screen/p_zan.png" />
                                </div>
                                <div class="center_text_b flex">
                                    <div>{{ isFacType == 0 ? getLanguage('集团运行设备健康度', language) : isFacType == 1 ? getLanguage('全厂运行设备健康度', language) : getLanguage('区域运行设备健康度', language) }}
                                    </div>
                                    <a-tooltip placement="topLeft">
                                        <template #title>
                                            <div style="background: #fff">
                                                <div>{{ langObj['针对运行中的设备, 状态得分的加权平均数计算为健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分'] }}</div>
                                            </div>
                                        </template>
                                        <question-circle-outlined class="ml-2 center_wh" />
                                    </a-tooltip>

                                </div>
                            </div>
                            <!-- <div @click="selectZnZdReport" class="ab_wrap left_top pointer"> -->
                            <div @click="selectZnZdWarn" class="ab_wrap left_top pointer">
                                <div class="top_bg flex">
                                    <div class="text">{{ getLanguage('智能诊断报警', language) }}</div>
                                    <div class="flex text_two">
                                        <div>{{ znZdWarnSearch.total }}</div>
                                        <div>{{ getLanguage('次', language) }}</div>
                                    </div>
                                </div>
                                <img class="center_bg" src="@/assets/screen/p_line_right.png" />
                                <img class="bottom_bg" src="@/assets/screen/p_zd_bg.png" />
                            </div>
                            <div @click="selectZnMxWarn" class="ab_wrap left_bottom pointer">
                                <div class="top_bg top_bg2 flex">
                                    <div class="text">{{ getLanguage('智能门限报警', language) }}</div>
                                    <div class="flex text_two">
                                        <div>{{ znMxWarnSearch.total }}</div>
                                        <div>{{ getLanguage('次', language) }}</div>
                                    </div>
                                </div>
                                <img class="center_bg" src="@/assets/screen/p_line_right.png" />
                                <img class="bottom_bg" src="@/assets/screen/p_mx_bg.png" />
                            </div>
                            <div @click="selectZnWeekReport" class="ab_wrap right_top pointer">
                                <div class="top_bg flex">
                                    <div class="text">{{ getLanguage('智能周报', language) }}</div>
                                    <div class="flex text_two">
                                        <div>{{ znWeekReportSearch.total }}</div>
                                        <div>{{ getLanguage('份', language) }}</div>
                                    </div>
                                </div>
                                <img class="center_bg" src="@/assets/screen/p_line_left.png" />
                                <img class="bottom_bg" src="@/assets/screen/p_week_bg.png" />
                            </div>
                            <div class="ab_wrap right_bottom">
                                <div class="top_bg flex">
                                    <div class="text flex">
                                        {{ getLanguage('平均故障间隔', language) }}
                                        <a-tooltip placement="topLeft">
                                            <template #title>
                                                <div style="background: #fff">
                                                    <div>{{ langObj['平均故障间隔=所有设备总运行时长/（所有设备总检修次数-可用检修次数）'] }}</div>
                                                </div>
                                            </template>
                                            <question-circle-outlined />
                                        </a-tooltip>

                                    </div>
                                    <div class="flex text_two">
                                        <div>{{ faultTme > 0 ? (faultTme / 24)?.toFixed(1) : 0.0 }}</div>
                                        <div>{{ getLanguage('天', language) }}</div>
                                    </div>
                                </div>
                                <img class="center_bg" src="@/assets/screen/p_line_left.png" />
                                <img class="bottom_bg" src="@/assets/screen/p_gz_bg.png" />
                            </div>

                        </div>
                        <div class="w-full flex">
                            <!-- <div style="width: 5%;"></div> -->
                            <div style="position: relative; width: 66%;margin: 0 auto;">
                                <div v-for="(item, index) in localeList" :key="index" style="position: absolute; color: red; "
                                     :style="{ 'left': item[0] + 'px', 'top': item[1] + 'px' }">
                                    <img style="width: 50px;" src="@/assets/screen/map_mark.png" alt="">
                                </div>
                                <img id="facImg" style="width: 100%;" :src="facPic" alt="">

                            </div>
                        </div>


                    </div>


                    <!-- 右侧部分 -->
                    <div class="flex flex-col  box-size">

                        <div class="w-full state_wrap zd_total">
                            <div class="title flex items-center">

                                <div class="p-text-color2 ml-10">{{ langObj['智能诊断报警统计'] }}</div>
                                <div class="flex-1"></div>
                            </div>

                            <div class="w-full bg_wrap">
                                <div class="flex items-center tl_wrap">
                                    <div style="cursor: pointer;" class="flex items-center tl_text" @click="selectZnZdWarn(2)">
                                        <div style="background-color: #d2de49;"></div>
                                        <div class="ml-1">{{ langObj['可用报警'] }}</div>
                                    </div>
                                    <div style="cursor: pointer;" class="flex items-center ml-2 tl_text" @click="selectZnZdWarn(3)">
                                        <div style="background-color: #cd5f3b"></div>
                                        <div class="ml-1">{{ langObj['警戒报警'] }}</div>
                                    </div>
                                    <div style="cursor: pointer;" class="flex items-center ml-2 tl_text" @click="selectZnZdWarn(4)">
                                        <div style="background-color: #921e37;"></div>
                                        <div class="ml-1">{{ langObj['故障报警'] }}</div>
                                    </div>
                                </div>
                                <swiper style="height:16.3vh;" class="swiper-zd" :slidesPerView=1 :spaceBetween=0 :autoplay="autoplay2" :speed="speed"
                                        :touchStartPreventDefault="false"
                                        :pagination="{ clickable: true }">
                                    <swiper-slide v-for="(item, index) in warningBarChartDataSplit" :key="index">
                                        <div style="width: 100%;height: 100%">
                                            <div :class="'chartE' + index" :id="'chartE' + index" style="width: 100%;height: 16vh"></div>
                                        </div>
                                    </swiper-slide>
                                </swiper>

                            </div>
                        </div>
                        <div class="w-full state_wrap gz_type_total mt-5">
                            <div class="title flex items-center">
                                <div class="p-text-color2 ml-10">{{ langObj['设备类型报警统计'] }}</div>
                                <div class="flex-1"></div>
                            </div>
                            <div class="w-full bg_wrap" style="cursor:pointer;">
                                <swiper class="machine-type-swiper" :slidesPerView=1 :spaceBetween=0 :autoplay="autoplay2" :loop="true" direction='vertical' :speed="speed">
                                    <swiper-slide v-for="(item, index) in machineTypeDataSplit" :key="index">
                                        <div class="bubble-chart">
                                            <div @click="selectMachineType(bubble.machineTypeId)" v-for="bubble in machineTypeDataSplit[index]" :key="bubble.id"
                                                 :style="bubbleStyle(bubble)"
                                                 class="bubble" :class="bubble.class">
                                                <a-tooltip placement="topLeft">
                                                    <template #title>
                                                        <div style="background: #fff">
                                                            <div>{{ bubble.text1 }}</div>
                                                        </div>
                                                    </template>
                                                    <div>
                                                        <div class="text-split">{{ bubble.text1_spli }}</div>
                                                        <div><span>{{ bubble.text2 }}</span>{{ bubble.text3 }}</div>
                                                    </div>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                </swiper>

                            </div>
                        </div>
                        <div class="w-full state_wrap state_total mt-5">
                            <div class="title flex items-center">
                                <div class="p-text-color2 ml-10">{{ isFacType == 2 ? langObj['区域设备状态排行'] : langObj['厂区设备状态排行'] }}</div>
                                <div class="flex-1"></div>
                            </div>
                            <div class="w-full bg_wrap">
                                <div class="flex items-center" style="justify-content: center;padding-bottom: 2%;cursor:pointer">
                                    <div class="flex items-center tl_text" @click="selectMachineList">
                                        <div style="background-color: #00D5FF;"></div>
                                        <div class="ml-1">{{ langObj['平均故障间隔'] }}({{ langObj['小时'] }})</div>
                                    </div>
                                    <div class="flex items-center ml-4 tl_text" @click="selectMachineList">
                                        <div style="background-color: #1CC48B"></div>
                                        <div class="ml-1">{{ langObj['运行设备健康分数'] }}({{ langObj['分'] }})</div>
                                    </div>

                                </div>
                                <!-- <div style="width: 100%;height: 16vh"> -->
                                <swiper style="height:16.5vh;" :slidesPerView=1 :spaceBetween=0 :autoplay="autoplay2" :speed="speed" :touchStartPreventDefault="false">
                                    <swiper-slide v-for="(item, index) in statePhData" :key="index">
                                        <div style="width: 100%;height: 100%">
                                            <div :class="'chartC' + index" :id="'chartC' + index" style="width: 100%;height: 16vh"></div>
                                        </div>

                                    </swiper-slide>
                                </swiper>

                                <!-- </div> -->
                            </div>
                        </div>
                    </div>


                    <!-- 设备列表 -->
                    <a-modal v-model:visible="machineVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="machineVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('设备列表', language) }}</div>

                            <a-form :model="machineModelSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <a-select @change="selectGroupMachine" v-model:value="machineModelSearch.groupId" style="width: 220px" allowClear
                                              :placeholder="getLanguage('请选择区域', language)">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="getLanguage('健康状态', language)" name="condition">
                                    <a-select v-model:value="machineModelSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择健康状态', language)">
                                        <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="getLanguage('运行状态', language)" name="status">
                                    <a-select v-model:value="machineModelSearch.status" style="width: 220px" allowClear :placeholder="getLanguage('请选择运行状态', language)">
                                        <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="getLanguage('搜索', language)" name="status">
                                    <a-input v-model:value="machineModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                                </a-form-item>

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetMachine" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getDataMachine" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="machineColumns" :data-source="machineDataList"
                                         @change="handleTableChangeMachineList" :pagination="paginationMachineList">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'factoryId'">
                                            <span>{{ getFactoryName(text) }}</span>
                                        </template>

                                        <template v-if="column.dataIndex === 'groupId'">
                                            <span>{{ getGroupName(text) }}</span>
                                        </template>

                                        <template v-if="column.dataIndex === 'experiment'">
                                            <span>{{
                                                getLanguage(whetherList.find((p) => p.value === text)?.label, language)

                                            }}</span>
                                        </template>

                                        <template v-if="column.dataIndex === 'condition'">
                                            <div class="flex items-center">
                                                <div style="width: 14px; height: 14px; border-radius: 50%"
                                                     :style="{ background: getMachienCondition(record)?.color }"></div>
                                                <div class="ml-1" :style="{ color: getMachienCondition(record)?.color }">
                                                    {{ getLanguage(getMachienCondition(record)?.label, language) }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="column.dataIndex === 'status'">
                                            <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                                                {{ getLanguage(getMachienStatus(record)?.label, language) }}
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'onlineHours'">
                                            <div class="ml-1">
                                                {{ record?.workStatus?.onlineHours?.toFixed(2) || 0 }}
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'faultIntervalTimes'">
                                            <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                                                {{ record?.workStatus?.faultIntervalTimes?.toFixed(2) || 0 }}
                                            </div>
                                        </template>




                                        <template v-if="column.dataIndex === 'act'">

                                            <a @click="viewMachine(1)">{{ langObj["健康总览"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="viewMachine(2)">{{ langObj["健康诊断"] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>










                        </div>
                    </a-modal>

                    <!-- 设备类型列表 -->
                    <a-modal v-model:visible="machineTypeVisible" :closable="false" :footer="null" style="top: 10%;" width="90%" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="machineTypeVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('设备类型列表', language) }}</div>

                            <div class="flex w-full">
                                <div class="box machine_wrap" style="height: 70vh; overflow-y: auto;min-width:250px;width:15%;">
                                    <div @click="selectMachineTypeList(item, index)" :class="machineTypeId === item.machineTypeId ? 'machine-type-selected' : ''" class="machine-type"
                                         v-for="(item, index) in machineTypeData">{{ item.firstCategory }}-{{ item.secondCategory }}</div>
                                </div>

                                <div class="flex-1 ml-4">
                                    <a-form :model="machineTypeSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                        <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                            <a-select @change="selectGroupZdWarn" v-model:value="machineTypeSearch.groupId" style="width: 220px" allowClear
                                                      :placeholder="getLanguage('请选择区域', language)">
                                                <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                                    {{ item.name }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>

                                        <a-form-item :label="langObj['设备']" name="status">
                                            <a-select v-model:value="machineTypeSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']" :options="machineListZdWarn"
                                                      :filter-option="filterOptionZdReport" :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear
                                                      show-search>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item :label="getLanguage('报警时间', language)" name="status">
                                            <a-range-picker v-model:value="machineTypeSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                        </a-form-item>
                                        <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                            <a-select v-model:value="machineTypeSearch.handleStatus" style="width: 220px" allowClear
                                                      :placeholder="getLanguage('请选择处理状态', language)">
                                                <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                                    {{ item.label }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                            <a-select v-model:value="machineTypeSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择严重程度', language)">
                                                <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                                    {{ item.label }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>

                                        <a-form-item>
                                            <div class="flex items-center">
                                                <div @click="resetMachineTypeSearch" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                                     style="background-color: #072499; color: #fff">
                                                    {{ getLanguage('重置', language) }}
                                                </div>

                                                <div @click="getMachineTypeListData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                                     style="background-color: #072499; color: #fff">
                                                    {{ getLanguage('查询', language) }}
                                                </div>
                                            </div>
                                        </a-form-item>
                                    </a-form>

                                    <div style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                        <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="machineTypeColumns" :data-source="machineTypeList"
                                                 @change="handleTableChangeMachineType" :pagination="paginationMachineType">
                                            <template #bodyCell="{ column, text, record }">
                                                <template v-if="column.dataIndex === 'type'">
                                                    <div class="flex items-center">
                                                        <div v-if="text === 1">
                                                            <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                                        </div>
                                                        <div v-if="text === 2">
                                                            <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                                        </div>
                                                        <div v-if="text === 3">
                                                            <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                                        </div>
                                                        <div v-if="text === 4">
                                                            <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                                        </div>
                                                        <div class="ml-2">
                                                            {{
                                                                langObj[typeList.find((p) => p.value === text)?.label]
                                                            }}
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="column.dataIndex === 'condition'">
                                                    <div class="flex items-center">
                                                        <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                            background: conditionList.find((p) => p.value === text)
                                                                ?.color,
                                                        }"></div>
                                                        <div class="ml-2">
                                                            {{
                                                                langObj[
                                                                conditionList.find((p) => p.value === text)?.label
                                                                ]
                                                            }}
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-if="column.dataIndex === 'handleStatus'">

                                                    <div v-if="text === 3">
                                                        {{ langObj["已关闭"] }}
                                                    </div>
                                                    <div v-if="text !== 3">
                                                        {{
                                                            handleStatusList.find((p) => p.value === text)
                                                                ? langObj[
                                                                handleStatusList.find((p) => p.value === text)?.label
                                                                ]
                                                                : langObj["未处理"]
                                                        }}
                                                    </div>
                                                </template>
                                                <template v-if="column.dataIndex === 'act'">
                                                    <a v-if="record?.reportIds?.length > 0" @click="viewReport(record)">{{ langObj["诊断报告"] }}</a>
                                                    <a-divider type="vertical" />
                                                    <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"] }}</a>
                                                    <a-divider type="vertical" />
                                                    <a @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"] }}</a>
                                                </template>
                                            </template>
                                        </a-table>
                                    </div>


                                </div>


                            </div>


                        </div>
                    </a-modal>


                    <!-- 报警列表 -->
                    <a-modal v-model:visible="warnVisible" :closable="false" :footer="null" style="top: 30%" width="1400px" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="warnVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full" style="font-size: 24px">{{ langObj['报警记录'] }}</div>

                            <div class="w-full">
                                <!-- <a-form
                                :model="warnSearch"
                                name="horizontal_login"
                                layout="inline"
                                autocomplete="off"
                            >
                                <a-form-item
                                    label="设备区域"
                                    name="groupId"
                                >
                                    <a-select
                                        v-model:value="warnSearch.groupId"
                                        style="width: 220px"
                                        placeholder="请选择设备区域"
                                        @change="selectGroup"
                                    >
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="设备"
                                    name="status"
                                    >
                                    <a-select
                                        v-model:value="warnSearch.machienId"
                                        style="width: 220px"
                                        placeholder="请选择设备"
                                        allowClear
                                    >
                                    <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="报警时间"
                                    name="status"
                                >
                                    <a-range-picker v-model:value="warnSearch.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                                </a-form-item>

                                <a-form-item
                                    label="报警类型"
                                    name="status"
                                    >
                                    <a-select
                                        v-model:value="warnSearch.type"
                                        style="width: 220px"
                                        allowClear
                                        placeholder="请选择报警类型"
                                    >
                                        <a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value"> {{ item.label }} </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="严重程度"
                                    name="status"
                                    >
                                    <a-select
                                        v-model:value="searchModel.condition"
                                        style="width: 220px"
                                        allowClear
                                        placeholder="请选择严重程度"
                                    >
                                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value"> {{ item.label }} </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4">重置</div>
                                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">查询</div>
                                        
                                    </div>
                                </a-form-item>
                                

                            </a-form> -->
                            </div>






                        </div>
                    </a-modal>

                    <!-- 硬件状态统计 -->
                    <a-modal v-model:visible="yjVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="yjVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('硬件状态统计', language) }}</div>
                            <div class="w-full flex items-center justify-center">
                                <div class="btn1 theme_equipment_btn" :style="{ 'background': typeSelectYj === 1 ? '#072499' : '' }"
                                     :class="typeSelectYj === 1 ? 'theme_equipment_btn_select' : ''"
                                     @click="selelctTypeYj(1)">{{ langObj['传感器'] }}</div>
                                <div class="btn1 theme_equipment_btn" :style="{ 'background': typeSelectYj === 2 ? '#072499' : '' }"
                                     :class="typeSelectYj === 2 ? 'theme_equipment_btn_select' : ''"
                                     @click="selelctTypeYj(2)">{{ langObj['采集单元'] }}</div>
                            </div>

                            <a-form v-if="typeSelectYj == 1" :model="yjModelSearch" name="horizontal_login" layout="inline" autocomplete="off">

                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <!-- <a-select   @change="selectYjGroup" v-model:value="yjModelSearch.groupId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择区域', language)">
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select> -->
                                    <a-select v-model:value="yjModelSearch.groupId" style="width: 220px" :placeholder="langObj['请选择设备区域']" @change="selectYjGroup">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="langObj['设备']" name="machine">
                                    <a-select @change="selectYjMachine" v-model:value="yjModelSearch.machine" style="width: 220px" :placeholder="langObj['请选择设备']"
                                              :options="machineListYjModel"
                                              :filter-option="filterOptionMachineListYj" :field-names="{ label: 'name', value: 'id', options: 'machineListYjModel' }" allowClear
                                              show-search>
                                    </a-select>
                                </a-form-item>






                                <!-- <a-form-item :label="getLanguage('指标数据状态', language)" name="status">
                                <a-select v-model:value="yjModelSearch.status" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择数据状态', language)">
                                    <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item> -->


                                <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="reseYjSearch" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getCgqListData(null)" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>
                            <a-form v-if="typeSelectYj == 2" :model="yjModelSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('数采器状态', language)" name="status">
                                    <a-select v-model:value="yjModelSearch.status" style="width: 220px" allowClear :placeholder="getLanguage('请选择数采器状态', language)">
                                        <a-select-option v-for="(item, index) in scqStatusList" :key="index" :value="item.value">
                                            {{ langObj[item.label] }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('搜索', language)" name="status">
                                    <a-input v-model:value="yjModelSearch.dasCode" :placeholder="getLanguage('请输入数采器编号搜索', language)" />
                                </a-form-item>

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="reseYjSearch" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getCjListData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>


                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table v-if="typeSelectYj == 1" style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="yjStateColumns"
                                         :data-source="cgqListData"
                                         :pagination="paginationYj" @change="handleTableChangeYj">
                                    <template #bodyCell="{ column, text, record }">

                                        <template v-if="column.dataIndex === 'groupId'">
                                            <span>{{ getGroupName(text) }}</span>
                                        </template>
                                        <template v-if="column.dataIndex === 'config_type'">
                                            <span>{{ text }}</span>
                                        </template>
                                        <template v-if="column.dataIndex === 'index_delay'">
                                            <span>{{ text ? langObj['是'] : langObj['否'] }}</span>
                                        </template>
                                        <template v-if="column.dataIndex === 'wireless'">
                                            <span>{{ text ? langObj['无线'] : langObj['有线'] }}</span>
                                        </template>



                                    </template>
                                </a-table>

                                <a-table v-if="typeSelectYj == 2" style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="yjCjStateColumns"
                                         :data-source="cjListData"
                                         :pagination="paginationYj" @change="handleTableChangeYj">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'config_status'">
                                            <div class="flex items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                    background: scqStatusList.find((p) => p.value === text)
                                                        ?.color,
                                                }"></div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[scqStatusList.find((p) => p.value === text)?.label]

                                                    }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'delay'">
                                            <div class="flex items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                    background: sjStateList.find((p) => p.value === text)
                                                        ?.color,
                                                }"></div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[sjStateList.find((p) => p.value === text)?.label]

                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 智能诊断报告记录 -->
                    <a-modal v-model:visible="znZdReportVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">

                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="znZdReportVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能诊断报告记录', language) }}</div>


                            <a-form :model="znZdReportSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <a-select @change="selectGroup" v-model:value="znZdReportSearch.groupId" style="width: 220px" allowClear
                                              :placeholder="getLanguage('请选择区域', language)">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="langObj['设备']" name="status">
                                    <a-select v-model:value="znZdReportSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']" :options="machineListZdReport"
                                              :filter-option="filterOptionZdReport" :field-names="{ label: 'name', value: 'id', options: 'machineListZdReport' }" allowClear
                                              show-search>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="getLanguage('报告时间', language)" name="status">
                                    <a-range-picker v-model:value="znZdReportSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                </a-form-item>
                                <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                    <a-select v-model:value="machineModelSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择严重程度', language)">
                                        <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>


                                <a-form-item :label="getLanguage('搜索', language)" name="status">
                                    <a-input v-model:value="machineModelSearch.keyWord" :placeholder="getLanguage('请输入关键词搜索', language)" />
                                </a-form-item>

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetZnZdReport" class="box px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getZnZdReport" class="box px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="znZdReportColumns" :data-source="znZdReportData"
                                         :pagination="pagination"
                                         @change="handleTableChangeZdReport">
                                    <template #bodyCell="{ column, text, record }">

                                        <template v-if="column.dataIndex === 'gradeText'">
                                            <div class="w-full flex items-center justify-items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%;margin-right: 5px;"
                                                     :style="{ 'background': gradeList.find(p => p.value === text)?.color }"></div>
                                                <span>{{langObj[gradeList.find(p => p.value === text)?.label]}} </span>
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'faultEvents'">
                                            <span>{{ langObj[text] || text }} </span>
                                        </template>
                                        <template v-if="column.dataIndex === 'act'">
                                            <a @click="goZdReport">{{ langObj["诊断报告"] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 智能周报记录 -->
                    <a-modal v-model:visible="znWeekReportVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">

                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="znWeekReportVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能周报记录', language) }}</div>


                            <a-form :model="znWeekReportSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('报告时间', language)" name="status">
                                    <a-range-picker v-model:value="znWeekReportSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                </a-form-item>
                                <a-form-item :label="getLanguage('报告类型', language)" name="status">
                                    <a-select v-model:value="znWeekReportSearch.type" style="width: 220px" allowClear :placeholder="getLanguage('请选择报告类型', language)">
                                        <a-select-option v-for="(item, index) in typeListWeek" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetWeek" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getDataWeek" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="znWeekReportColumns" :data-source="weekData"
                                         :pagination="paginationWeek"
                                         @change="handleTableChangeWeek">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'cycle'">
                                            <span>{{ getDate(record.date) }}</span>
                                        </template>
                                        <template v-if="column.dataIndex === 'cycle2'">
                                            <span>{{ getDate2(record.date, record.type) }}</span>
                                        </template>
                                        <template v-if="column.dataIndex === 'type'">
                                            <span>{{ text == 3 ? langObj['周报'] : langObj['月报'] }}</span>
                                        </template>

                                        <template v-if="column.dataIndex === 'act'">
                                            <a @click="detailsWeek(record)" style="" class="theme-table-link">{{ langObj['查看详情'] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 智能门限报警记录 -->
                    <a-modal v-model:visible="znMxWarnVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">

                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="znMxWarnVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能门限报警记录', language) }}</div>


                            <a-form :model="znMxWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <a-select @change="selectGroupMxWarn" v-model:value="znMxWarnSearch.groupId" style="width: 220px" allowClear
                                              :placeholder="getLanguage('请选择区域', language)">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('设备', language)" name="status">
                                    <!-- <a-select v-model:value="znMxWarnSearch.machineId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择设备名称', language)">
                                    <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select> -->
                                    <a-select v-model:value="znMxWarnSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']" :options="machineListMxWarn"
                                              :filter-option="filterOptionZdReport" :field-names="{ label: 'name', value: 'id', options: 'machineListMxWarn' }" allowClear
                                              show-search>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('报警时间', language)" name="status">
                                    <a-range-picker v-model:value="znMxWarnSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                </a-form-item>
                                <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                    <a-select v-model:value="znMxWarnSearch.handleStatus" style="width: 220px" allowClear :placeholder="getLanguage('请选择处理状态', language)">
                                        <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                    <a-select v-model:value="znMxWarnSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择严重程度', language)">
                                        <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>



                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetMxWarn" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getMxWarnData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :data-source="mxWarnData" :pagination="paginationMxWarn"
                                         @change="handleTableChangeMxWarn"
                                         :columns="znMxWarnColumns">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'type'">
                                            <div class="flex items-center">
                                                <div v-if="text === 1">
                                                    <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                                </div>
                                                <div v-if="text === 2">
                                                    <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                                </div>
                                                <div v-if="text === 3">
                                                    <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                                </div>
                                                <div v-if="text === 4">
                                                    <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                                </div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[typeList.find((p) => p.value === text)?.label]
                                                    }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'condition'">
                                            <div class="flex items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                    background: conditionList.find((p) => p.value === text)
                                                        ?.color,
                                                }"></div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[
                                                        conditionList.find((p) => p.value === text)?.label
                                                        ]
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="column.dataIndex === 'handleStatus'">
                                            <!-- @click="viewClose(record)" -->
                                            <div v-if="text === 3">
                                                {{ langObj["已关闭"] }}
                                            </div>
                                            <div v-if="text !== 3">
                                                {{
                                                    handleStatusList.find((p) => p.value == text)
                                                        ? langObj[
                                                        handleStatusList.find((p) => p.value == text)?.label
                                                        ]
                                                        : langObj["未处理"]
                                                }}
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'act'">

                                            <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 智能诊断报警记录 -->
                    <a-modal v-model:visible="znZdWarnVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="znZdWarnVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能诊断报警记录', language) }}</div>
                            <a-form :model="znZdWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <a-select @change="selectGroupZdWarn" v-model:value="znZdWarnSearch.groupId" style="width: 220px" allowClear
                                              :placeholder="getLanguage('请选择区域', language)">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="langObj['设备']" name="status">
                                    <a-select v-model:value="znZdWarnSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']" :options="machineListZdWarn"
                                              :filter-option="filterOptionZdReport" :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear
                                              show-search>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="langObj['报警时间']" name="status">
                                    <a-range-picker v-model:value="znZdWarnSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                </a-form-item>
                                <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                    <a-select v-model:value="znZdWarnSearch.handleStatus" style="width: 220px" allowClear :placeholder="getLanguage('请选择处理状态', language)">
                                        <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                    <a-select v-model:value="znZdWarnSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择严重程度', language)">
                                        <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>



                                <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetZdWarn" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getZdWarnData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="znZdWarnColumns" :data-source="zdWarnData"
                                         :pagination="paginationZdWarn"
                                         @change="handleTableChangeZdWarn">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'type'">
                                            <div class="flex items-center">
                                                <div v-if="text === 1">
                                                    <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                                </div>
                                                <div v-if="text === 2">
                                                    <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                                </div>
                                                <div v-if="text === 3">
                                                    <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                                </div>
                                                <div v-if="text === 4">
                                                    <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                                </div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[typeList.find((p) => p.value === text)?.label]
                                                    }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'condition'">
                                            <div class="flex items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                    background: conditionList.find((p) => p.value === text)
                                                        ?.color,
                                                }"></div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[
                                                        conditionList.find((p) => p.value === text)?.label
                                                        ]
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="column.dataIndex === 'handleStatus'">

                                            <div v-if="text === 3">
                                                {{ langObj["已关闭"] }}
                                            </div>
                                            <div v-if="text !== 3">
                                                {{
                                                    handleStatusList.find((p) => p.value === text)
                                                        ? langObj[
                                                        handleStatusList.find((p) => p.value === text)?.label
                                                        ]
                                                        : langObj["未处理"]
                                                }}
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'act'">


                                            <a v-if="record?.reportIds?.length > 0" @click="viewReport(record)">{{ langObj["诊断报告"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 设备报警趋势-->
                    <a-modal v-model:visible="warnTrendVisible" :closable="false" :footer="null" style="top: 10%" width="80%" :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="warnTrendVisible = false">
                                    <close-outlined style="font-size: 20px" />
                                </div>
                            </div>
                            <div class="text-center w-full mb-4" style="font-size: 24px">{{ langObj['报警记录'] }}</div>
                            <a-form :model="znZdWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                                <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                    <a-select @change="selectGroupWarnTrend" v-model:value="warnTrendSearch.groupId" style="width: 220px" allowClear
                                              :placeholder="getLanguage('请选择区域', language)">
                                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item :label="langObj['设备']" name="status">
                                    <a-select v-model:value="warnTrendSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']" :options="machineListZdWarn"
                                              :filter-option="filterOptionZdReport" :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear
                                              show-search>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="langObj['报警时间']" name="status">
                                    <a-range-picker v-model:value="warnTrendSearch.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange" />
                                </a-form-item>
                                <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                    <a-select v-model:value="warnTrendSearch.status" style="width: 220px" allowClear :placeholder="getLanguage('请选择处理状态', language)">
                                        <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                    <a-select v-model:value="warnTrendSearch.condition" style="width: 220px" allowClear :placeholder="getLanguage('请选择严重程度', language)">
                                        <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>



                                <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                                <a-form-item>
                                    <div class="flex items-center">
                                        <div @click="resetWarnTrend" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('重置', language) }}
                                        </div>

                                        <div @click="getWarnTrendData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499; color: #fff">
                                            {{ getLanguage('查询', language) }}
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-form>

                            <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                                <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="warnTrendColumns" :data-source="warnTrendData"
                                         :pagination="paginationWarnTrend"
                                         @change="handleTableChangeWarnTrend">
                                    <template #bodyCell="{ column, text, record }">
                                        <template v-if="column.dataIndex === 'type'">
                                            <div class="flex items-center">
                                                <div v-if="text === 1">
                                                    <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                                </div>
                                                <div v-if="text === 2">
                                                    <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                                </div>
                                                <div v-if="text === 3">
                                                    <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                                </div>
                                                <div v-if="text === 4">
                                                    <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                                </div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[typeList.find((p) => p.value === text)?.label]
                                                    }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'condition'">
                                            <div class="flex items-center">
                                                <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                                    background: conditionList.find((p) => p.value === text)
                                                        ?.color,
                                                }"></div>
                                                <div class="ml-2">
                                                    {{
                                                        langObj[
                                                        conditionList.find((p) => p.value === text)?.label
                                                        ]
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="column.dataIndex === 'handleStatus'">

                                            <div v-if="text === 3">
                                                {{ langObj["已关闭"] }}
                                            </div>
                                            <div v-if="text !== 3">
                                                {{
                                                    handleStatusList.find((p) => p.value === text)
                                                        ? langObj[
                                                        handleStatusList.find((p) => p.value === text)?.label
                                                        ]
                                                        : langObj["未处理"]
                                                }}
                                            </div>
                                        </template>
                                        <template v-if="column.dataIndex === 'act'">


                                            <a v-if="record?.reportIds?.length > 0" @click="viewReport(record)">{{ langObj["诊断报告"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"] }}</a>
                                            <a-divider type="vertical" />
                                            <a @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"] }}</a>
                                        </template>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                    </a-modal>
                    <!-- 检修报告 -->
                    <a-modal width="50%" :closable="false" :footer="null" v-model:visible="jxVisible">
                        <maintenanceReportDetails ref="mrD" @cancel="cancelJx"></maintenanceReportDetails>
                    </a-modal>
                    <!-- 诊断报告详情 -->
                    <a-modal width="50%" :closable="false" :footer="null" v-model:visible="visibleDR">
                        <DiagnosticReportDetails ref="mrDref" @cancel="cancelDR"></DiagnosticReportDetails>
                    </a-modal>
                    <!-- 关闭原因 -->
                    <a-modal v-model:visible="visibledel2" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="1200px"
                             :maskClosable="false">
                        <div style="color: #fff">
                            <div class="w-full flex">
                                <div class="flex-1"></div>
                                <div class="cursor-pointer" @click="visibledel2 = false">
                                    <close-outlined style="font-size: 20px; color: #fff !important" />
                                </div>
                            </div>
                            <div class="text-center w-full" style="font-size: 24px">
                                {{ langObj["关闭报警"] }}
                            </div>

                            <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px" :model="formClose">
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <a-form-item name="closeCause" label="关闭原因" required>
                                            <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" readonly />
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </a-form>
                        </div>
                    </a-modal>

                </div>
                <!-- 底部切换 -->
                <div class="w-full p-tit-bottom">
                    <div v-if="factoryListBottom.length > 2" style="width: 47%;margin-left: 26.5%;margin-top:-3%;">
                        <!-- :centeredSlides=true -->

                        <swiper class="swiper_con1" :slidesPerView=3 :spaceBetween=0 :navigation=true :autoplay="autoplay" :loop="true" :initialSlide=0 @swiper="onSwiper"
                                @slideChange="onSlideChange" :centeredSlides=true>
                            <swiper-slide v-for="(item, index) in factoryListBottom" :key="index">
                                <div class="fac_wrap" :class="{ 'selected': activeIndex === index }" @click="onSlideClick(index)">
                                    <img v-if="!item.parentId && item.factoryName" src="@/assets/screen/p_company_bg.png" />
                                    <img v-if="item.parentId" src="@/assets/screen/p_factory_bg.png" />
                                    <img v-if="!item.factoryName" src="@/assets/screen/p_qu_bg.png" />
                                    <div class="fac_text">{{ item.factoryName ? item.factoryName : item.name }}</div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>

                    <div v-if="factoryListBottom.length <= 2" style="width: 47%;margin-left: 26.5%;margin-top:-3%;">
                        <div class="change_wrap">
                            <div class="fac_wrap" v-for="(item, index) in factoryListBottom" :key="index" :class="{ 'selected': activeIndex2 === index }" @click="selectFac(index)">
                                <img v-if="!item.parentId && item.factoryName" src="@/assets/screen/p_company_bg.png" />
                                <img v-if="item.parentId" src="@/assets/screen/p_factory_bg.png" />
                                <img v-if="!item.factoryName" src="@/assets/screen/p_qu_bg.png" />
                                <div class="fac_text">{{ item.factoryName ? item.factoryName : item.name }}</div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    </a-config-provider>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import {
    transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    transformDate3,
    getGroupListByFactory,
    getRealFactoryIdList
} from "../../common/tools";
import MapImg from "../../assets/map.png";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import { getLanguage } from "../../common/translate"
import { Dayjs } from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css'; // 确保引入样式文件
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const router = useRouter();
let factory_id = ''
let bottom_select_facId = ref('') // 底部选择的工厂Id
let bottom_select_groupId = ref('')//底部选择的区域id
import My3DPie from "./pie3d.vue"
let stopLabel: any = ref('停机')
let isShowRate: any = ref(false)

let totalTime: any = ref(null) //系统运行时间
let weightedAverage: any = ref(0)    //全厂运行设备健康分数
let factoryList: any = ref([])
let factoryListBottom: any = ref([])//底部工厂数组
let groupListBottom: any = ref([])//底部区域数组
const factoryListLen: any = ref(1)
let groupList: any = ref([])     //区域
let machineList: any = ref([])   //
let sensorList: any = ref([])   //
let availabilityRatio:any = ref(0)

const swiperOptions = {
    slidesPerView: 3, // 一行显示 3 个幻灯片
    spaceBetween: 20, // 幻灯片之间的间距
    centeredSlides: true, // 将当前幻灯片居中
    navigation: true, // 启用导航按钮
    pagination: { clickable: true }, // 启用分页器
    on: {
        click: (swiper: any, event: MouseEvent) => {
            const clickedIndex = swiper.clickedIndex;
            if (clickedIndex !== undefined) {
                swiper.slideTo(clickedIndex); // 点击后滑动到该幻灯片
            }
        },
    },
};
let autoplay = ref({
    delay: 10 * 60 * 1000, // 10 分钟（单位：毫秒）
    disableOnInteraction: false, // 用户操作后是否停止自动轮播
})
let autoplay2 = ref({
    delay: 60 * 1000,
    disableOnInteraction: false, // 用户操作后是否停止自动轮播
})
let speed = ref(1000)
// Swiper 实例
let swiperInstance: any = null;
let swiperInstance2: any = null;
const activeIndex = ref(0); // 当前激活的幻灯片索引
const onSwiper = (swiper: any) => {
    swiperInstance = swiper;
};


// 幻灯片切换时更新激活索引
const onSlideChange = () => {
    if (swiperInstance) {
        activeIndex.value = swiperInstance.realIndex;

        selectFac(activeIndex.value)
    }
};

let activeIndex2 = 0;
let timerI = ref<number | null>(null);





// 点击幻灯片时滑动到中间
const onSlideClick = (index: number) => {
    if (swiperInstance) {
        if (activeIndex.value == index) {
            return
        } else {
            if (index - activeIndex.value == 1) {
                swiperInstance.slideNext()
            } else if (index - activeIndex.value == -1) {
                swiperInstance.slidePrev()
            } else if (index == 0) {
                swiperInstance.slideNext()
            }
            else if (index == factoryListBottom.value.length - 1) {
                swiperInstance.slidePrev()
            }
            else {
                swiperInstance.slideNext()
            }
        }
        // selectFac(index)
    }

};


// 传入数据生成 option
const optionsData = ref([
    // { name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
    // { name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
    // { name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
    // { name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
])//3d饼图数据
const onChange = (current: number) => {
    console.log(current);
};
let conditionSelectFac = ref('')    //设备健康状态统计 工厂选择
let conditionChartData: any = ref([
    { name: '健康', value: 0, rate: 0.00, itemStyle: { opacity: 0.8, color: '#1CC48B' } },
    { name: '可用', value: 0, rate: 0.00, itemStyle: { opacity: 0.8, color: '#d2de49' } },
    { name: '警戒', value: 0, rate: 0.00, itemStyle: { opacity: 0.8, color: '#cd5f3b' } },
    { name: '故障', value: 0, rate: 0.00, itemStyle: { opacity: 0.8, color: '#921e37' } },
])
let machinesAllV: any = ref(0)

let statusSelectFac = ref('')    //设备运行状态统计 工厂选择
let statusChartData: any = ref([      //设备运行状态统计 数据
    { label: '运行', num: 0, color: '#06DEF5' },
    { label: stopLabel.value, num: 0, color: '#ADADAD' },
    { label: '测点', num: 0, color: '#ADADAD' },
])

let handleStatusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];


// 设备报警数量统计时间
let machineWarningTime = ref(1)
let machineWarningFac = ref('')


// 各厂报警数量排行
let factoryWarningTime = ref(1)
let factoryWarningChartData: any = ref([])

// 智能报警统计
let warningTrendTime = ref(1)
let warningTrendFac = ref('')
let warningBarChartData: any = ref([])
let warningBarChartDataSplit: any = ref([])

let factory_name: any = ref('')
let factory_info: any = ref({})


let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let facPic = ref('')
let localeList: any = ref([])

let isMounted = false
onMounted(() => {
    getLang()
    init()
})

onUnmounted(() => {
    isMounted = true;
    if (timerI.value) {
        clearInterval(timerI.value)
    }
})


let isFacType = ref(0) //0 集团 1分厂 2区域
const init = async () => {
    factory_id = localStorage.getItem('factory_id') || ''
    if (!factory_id) return
    console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
    bottom_select_facId.value = factory_id
    // isShowGroup = localStorage.getItem('isShowGroup') || 'false'
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    if (!bottom_select_facId.value) return
    if (bottom_select_facId.value === '616bb0a6e731767afe63723a' || bottom_select_facId.value === '63bfaa5b4e5403690c2f098c' || bottom_select_facId.value === '63e5dcac4e5403690c2f0fe0') {
        stopLabel.value = '待机'
        isShowRate.value = true
    }
    conditionSelectFac.value = bottom_select_facId.value
    statusSelectFac.value = bottom_select_facId.value
    machineWarningFac.value = bottom_select_facId.value
    warningTrendFac.value = bottom_select_facId.value
    await loadFactory()
    // setTimeout(() => {
    //     swiperInstance.slidePrev()
    // }, 5000);
}
// 获取工厂
const loadFactory = async () => {
    localeList.value = []

    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {
        // 演示账号
        factoryList.value = result.data.filter((p: any) => p._id === bottom_select_facId.value || p.parentId === bottom_select_facId.value)
        if (factoryList.value.length == 1) {
            isFacType.value = 1;
            let groupListBottomc = factoryList.value.concat(factoryList.value[0].groups)
            groupListBottom.value = JSON.parse(JSON.stringify(groupListBottomc));
            for (let i = 0; i < groupListBottom.value.length; i++) {
                groupListBottom.value[i].selected = false;
                if (groupListBottom.value[i].factoryName) {
                    groupListBottom.value[i].selected = true;
                }
            }
            factoryListLen.value = Math.ceil((groupListBottomc.length) / 3)
            factoryListBottom.value = JSON.parse(JSON.stringify(groupListBottom.value));
        } else {
            isFacType.value = 0;
            for (let i = 0; i < factoryList.value.length; i++) {
                factoryList.value[i].selected = false;
                if (!factoryList.value[i].parentId) {
                    factoryList.value[i].selected = true;
                }
            }
            let factoryListBottomc = []
            factoryListBottomc = JSON.parse(JSON.stringify(factoryList.value))
            factoryListBottom.value = factoryListBottomc;
            factoryListLen.value = Math.ceil((factoryList.value.length) / 3)
            // factoryListBottom.value = splitArrayIntoSubarrays(factoryListBottomc, 3);
        }
        // 每隔10秒切换一次index


        if (factoryListBottom.value?.length !== 2) {
            if (timerI.value) {
                clearInterval(timerI.value)
            };
        }
        if (factoryListBottom.value?.length == 2) {
            if (!isMounted && !timerI.value) {
                let id = setInterval(() => {
                    activeIndex2 = activeIndex2 === 0 ? 1 : 0; // 在0和1之间切换
                    selectFac(activeIndex2)
                }, 600000 ); // 10000毫秒 = 10秒
                timerI.value = id
            }

        }




        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === bottom_select_facId.value)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
            if (info) {
                let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                totalTime.value = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
            } else {
                totalTime.value = 0
            }

        }
        factoryInfo()

    }

}
//获取工厂信息
const factoryInfo = async () => {
    let groups: any = []
    let config: any = {
        params: {
            factoryId: bottom_select_facId.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let reslut = await request.get('/api/factories/info', config)
    if (reslut?.data) {
        if (reslut?.data?.picture) {
            facPic.value = reslut.data.picture.url
        } else {
            facPic.value = MapImg
        }

        let facInfo = reslut.data
        if (facInfo) {
            if (facInfo?.position?.length > 0) {
                let list: any = []
                setTimeout(() => {
                    facInfo?.position?.map((d: any) => {
                        let left = 0
                        let right = 0
                        let div: any = document.getElementById('facImg');
                        let width = div.offsetWidth || 0;
                        let height = div.offsetHeight || 0;
                        left = width * (d && d[0] / 100) - 25
                        right = height * (d && d[1] / 100) - 25
                        list.push([left, right])
                    })

                    localeList.value = list

                }, 500);
            }

            if (!facInfo.position) {
                facInfo.position = []
            }

            factory_name.value = facInfo.factoryName
            factory_info.value = facInfo
        } else {
            factory_name.value = ''
        }

        factoryList.value.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
        groupList.value = groups;

        if (bottom_select_groupId.value) {
            let groups1 = groups.find((p: any) => p.sonID == bottom_select_groupId.value);
            factory_name.value = groups1.name
            getMain([groups1]);

        } else {
            getMain(groups);
        }


    }

}
const splitArrayIntoSubarrays = (arr: any, size: any) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => {
        const end = Math.min((i + 1) * size, arr.length); // 使用Math.min来避免越界
        return arr.slice(i * size, end);
    });
}
// 获取区域列表
const getMain = async (groups: any) => {
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value)
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    // groupList.value = [];



    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );

            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus.condition) {
                    weightAll = weightAll + 100
                }
                if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                    weightAll = weightAll + 80
                }
                if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                    weightAll = weightAll + 60
                }
                if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                    weightAll = weightAll + 30
                }
                count++
            })
            machineList.value = machineList.value.concat(item2.machines)

            // groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData() //健康状态统计
        getYjStateBarChartData()//硬件状态统计
        getStatusChartData() //设备监测统计
        getStateBarChartData()//状态排行
        // getFactoryWarningChartData()
        getwarningBarChartData()//智能诊断报警统计
        // getZnZdReport()
        getZdWarnData()
        getDataWeek()
        getMxWarnData()
        getMachineTypeData()//设别类型统计
        getwarningTrendChartData2(1) //设备报警趋势
    }, 500);

}




//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
    if (ev.reportIds.length) {
        visibleDR.value = true;
        reportDRId.value = ev.reportIds[0];
        setTimeout(() => {
            mrDref.value.show(reportDRId.value);
        }, 500);
    }
    // router.push('/report/list')
};

const cancelDR = () => {
    visibleDR.value = false;
};

//检修报告
let jxVisible: any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
    jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
    closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
    formClose.value.closeCause = ev.closeCause;
    visibledel2.value = true;
};

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0;
    let r1 = '0.00', r2 = '0.00', r3 = '0.00', r4 = '0.00';
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        if (bottom_select_groupId.value) {
            let group = f?.groups && f.groups.find((p: any) => p.sonID == bottom_select_groupId.value);
            group?.machines?.map((m: any) => {
                if (!m.workStableStatus.condition) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })

        } else {
            f?.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (!m.workStableStatus.condition) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        }

    }

    let machinesAll = (n1 + n2 + n3 + n4) * 1;
    machinesAllV.value = 0;
    if (machinesAll > 0) {
        machinesAllV.value = machinesAll
        r1 = ((n1 / machinesAll) * 100).toFixed(2)
        r2 = ((n2 / machinesAll) * 100).toFixed(2)
        r3 = ((n3 / machinesAll) * 100).toFixed(2)
        r4 = ((n4 / machinesAll) * 100).toFixed(2)

    } else {
        machinesAllV.value = 0
    }
    conditionChartData.value = [
        { name: '健康', value: n1, rate: r1, itemStyle: { opacity: 0.8, color: '#1CC48B' } },
        { name: '可用', value: n2, rate: r2, itemStyle: { opacity: 0.8, color: '#d2de49' } },
        { name: '警戒', value: n3, rate: r3, itemStyle: { opacity: 0.8, color: '#cd5f3b' } },
        { name: '故障', value: n4, rate: r4, itemStyle: { opacity: 0.8, color: '#921e37' } }
    ]

    optionsData.value = JSON.parse(JSON.stringify(conditionChartData.value))



    if((n1 + n2 + n3 + n4) >0) {
        availabilityRatio.value = (((n1 + n2) / (n1 + n2 + n3 + n4))*100).toFixed(2)
    } else {
        availabilityRatio.value = '0.00'
    }


    // initConditionChart()
}




// 获取设备监测统计 数据
const getStatusChartData = () => {
    let s1 = 0, s2 = 0, s3 = 0;
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === statusSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    s3 = s3 + m?.sensors?.length
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }

                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === statusSelectFac.value)

        if (bottom_select_groupId.value) {
            let group = f?.groups && f.groups.find((p: any) => p.sonID == bottom_select_groupId.value);

            group.machines && group.machines.map((m: any) => {
                s3 = s3 + m?.sensors?.length


                if (m.workStatus.wkStatus) {
                    s1++
                } else {
                    s2++
                }
            })



        } else {
            f?.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    s3 = s3 + m?.sensors?.length
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }
                })
            })
        }




    }

    statusChartData.value = [
        { label: '运行', num: s1, color: '#06DEF5' },
        { label: '停机', num: s2, color: '#ADADAD' },
        { label: '测点', num: s3, color: '#ADADAD' },
    ]


}

let yjStateData = ref({
    sensorTotal: 0,
    sensorLowNum: 0,
    sensorNormalNum: 0,
    dasToatal: 0,
    dasOnlineNum: 0,
    dasOffLineNum: 0
})
//获取硬件状态统计数据
const getYjStateBarChartData = async () => {


    // let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    // let factoryIds = []
    // if (facList && facList.length) {
    //     factoryIds = facList.map((p: any) => p._id)
    // } else {
    //     factoryIds = [machineWarningFac.value]
    // }

    let config: any = {
        params: {
            factoryId: machineWarningFac.value,
            begin: "",
            end: ""
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/hardware/infos', config)


    // yjStateData.value = []
    let sensorPowerHisData: any = ref([])
    let dasOnlineHisData: any = ref([])
    let name1 = ['低电量', '正常']
    let name2 = ['离线', '在线']
    if (result && result.data) {
        yjStateData.value.sensorLowNum = 0;
        yjStateData.value.sensorNormalNum = 0;
        yjStateData.value.dasOffLineNum = 0;
        yjStateData.value.dasOnlineNum = 0
        if (result.data?.sensorPowerHis?.length > 0) {
            yjStateData.value.sensorLowNum = result.data?.sensorPowerHis[0].low?.length;
            yjStateData.value.sensorNormalNum = result.data?.sensorPowerHis[0].normal?.length;
        }
        if (result.data?.dasOnlineHis?.length > 0) {
            yjStateData.value.dasOffLineNum = result.data?.dasOnlineHis[0].offline?.length;
            yjStateData.value.dasOnlineNum = result.data?.dasOnlineHis[0].online?.length;
        }


        let sensorPowerHis = result.data?.sensorPowerHis?.reverse();//传感器
        let dasOnlineHis = result.data.dasOnlineHis?.reverse() //采集单元
        yjStateData.value.sensorTotal = result.data.sensorTotal;
        sensorPowerHis.map((d: any) => {


            sensorPowerHisData.value.push({
                date: d.date,
                low: d.low?.length,
                normal: d.normal?.length,
            })
        })

        yjStateData.value.dasToatal = result.data.dasTotal;

        dasOnlineHis.map((d: any) => {

            dasOnlineHisData.value.push({
                date: d.date,
                low: d.offline?.length,
                normal: d.online?.length,
            })
        })


        setTimeout(() => {
            initYjStatusChart(sensorPowerHisData.value, name1, 'chartB1')
        }, 200)
        setTimeout(() => {
            initYjStatusChart(dasOnlineHisData.value, name2, 'chartB2')
        }, 200)



    }

}
// 绘制硬件状态统计图
const initYjStatusChart = (data: any, name: any, id: any) => {

    if (!document.getElementById(id)) return
    let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
    let xData: any = []
    xData = data?.map((d: any) => d.date.substring(5, 10))
    let statusListLow = data?.map((d: any) => d.low)
    let statusListNormal = data?.map((d: any) => d.normal)
    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: xData,
                axisLabel: {
                    color: theme.value == 'white' ? '#333' : "#ffffff",
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    show: false, // 是否显示标签
                },
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: false //隐藏或显示
                },
            }
        ],
        series: [

            {
                name: langObj.value[name[1]],
                type: 'line',

                emphasis: {
                    focus: 'series'
                },
                data: statusListNormal,
                areaStyle: {
                    color: {
                        type: 'linear', // 线性渐变
                        x: 0, y: 0, x2: 0, y2: 1, // 渐变方向和范围
                        colorStops: [
                            { offset: 0, color: 'rgba(45, 205, 255, 0.3)' }, // 0%处的颜色
                            { offset: 1, color: 'rgba(45, 205, 255, 0.3)' }  // 100%处的颜色
                        ]
                    }
                },
                itemStyle: {

                    color: '#3AAB85'
                }
            },
            {
                name: langObj.value[name[0]],
                type: 'line',

                emphasis: {
                    focus: 'series'
                },
                data: statusListLow,
                itemStyle: {
                    color: '#D14E4F'
                },
                areaStyle: {
                    color: {
                        type: 'linear', // 线性渐变
                        x: 0, y: 0, x2: 0, y2: 1, // 渐变方向和范围
                        colorStops: [
                            { offset: 0, color: 'rgba(45, 205, 255, 0.3)' }, // 0%处的颜色
                            { offset: 1, color: 'rgba(45, 205, 255, 0.3)' }  // 100%处的颜色
                        ]
                    }
                },
            },
        ]
    };

    myChart && myChart.setOption(option)
}

//设备趋势报警
let warningTrendTimeD = ref(1)
// let warningTrendFac = ref('')
let warningTrendChartData: any = ref([])
// 获取设备报警趋势数据
// let visibleChartE = ref(true)
const getwarningTrendChartData2 = async (type: any) => {
    warningTrendTimeD.value = type;
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()
    //本月
    if (warningTrendTimeD.value === 1) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (warningTrendTimeD.value === 2) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [warningTrendFac.value]
    }

    let config: any = {
        params: {
            skip: 1,
            groupId: bottom_select_groupId.value,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    warningTrendChartData.value = []

    if (result && result.data && result.data.list) {
        result.data.list.reverse()

        let dateList = result.data.list.map((d: any) => d.date.substring(0, 10))
        dateList = [... new Set(dateList)]

        dateList.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.date.includes(d))

            let statusList1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let statusList2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let statusList3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            warningTrendChartData.value.push({
                date: d,
                statusList1,
                statusList2,
                statusList3,
            })
        })


    }

    // visibleChartE.value = false
    // visibleChartE.value = true

    setTimeout(() => {
        initWarningTrendChartD()
    }, 200);

}

// 绘制设备报警趋势图
const initWarningTrendChartD = () => {
    if (!document.getElementById("chartD")) return
    let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
    // myChart.clear()
    let colors: any = ['#DDD246', '#CB6A34', '#B81212']
    let xData: any = []

    xData = warningTrendChartData.value.map((d: any) => d.date.substring(5, 10))
    let statusList1 = warningTrendChartData.value.map((d: any) => d.statusList1)
    let statusList2 = warningTrendChartData.value.map((d: any) => d.statusList2)
    let statusList3 = warningTrendChartData.value.map((d: any) => d.statusList3)

    let option = {
        // color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '5%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
            show: false,
        },
        xAxis: [
            {
                show: true,
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xData,
                splitLine: {
                    show: false,
                    lineStyle: {
                        opacity: 0.3
                    }
                },
                axisLabel: {
                    color: theme.value == 'white' ? '#333' : "#ffffff",
                    fontSize: 12,
                    showMaxLabel: true
                },
            }
        ],
        yAxis: {
            type: 'value',
            scale: true,
            splitLine: {    //网格线
                lineStyle: {
                    color: 'rgba(255,255,255,0.5)',
                    type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true //隐藏或显示
            },
        },

        series: [
            {
                name: langObj.value['可用报警'],
                type: 'line',
                data: statusList1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒报警'],
                type: 'line',
                data: statusList2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障报警'],
                type: 'line',
                data: statusList3,
                itemStyle: {
                    color: '#921e37'
                }
            }
        ]
    };

    myChart && myChart.setOption(option)
}









// 获取厂区状态排行
let visibleChartC = ref(true)
let statePhData: any = ref([])
let faultTme: any = ref(0)


const selectMachineList = (condition: any) => {
    resetMachine()
    machineModelSearch.value.factoryId = conditionSelectFac.value;
    machineVisible.value = true
    machineModelSearch.value.groupId = bottom_select_groupId.value;
    getDataMachine()
}

const getStateBarChartData = async () => {


    // let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    // let factoryIds = []
    // if (facList && facList.length) {
    //     factoryIds = facList.map((p: any) => p._id)
    // } else {
    //     factoryIds = [machineWarningFac.value]
    // }

    let config: any = {
        params: {
            factoryId: machineWarningFac.value,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/machines/fault-interval-times', config)

    // 1平均故障间隔，2运行设备健康分数

    statePhData.value = []
    faultTme.value = 0;
    let statePhData2: any = ref([])
    let fault_time_all: any = ref(0)
    let repairCount: any = ref(1)

    if (result && result.data) {
        if (isFacType.value == 0) {
            if (result.data.factories?.length > 0) {
                getData(result.data.factories)
            }

        }
        if (isFacType.value == 1) {
            let groups = result.data?.groups?.filter((p: any) => p.factoryId == bottom_select_facId.value) || []
            if (groups.length > 0) {
                getData(groups)
            }

        }
        if (isFacType.value == 2) {
            let machines = result.data?.machines?.filter((p: any) => p.groupId == bottom_select_groupId.value) || []
            if (machines.length > 0) {
                getData(machines)
            }

        }
    }
    function getData(data: any) {
        data?.map((d: any) => {

            let statusList1 = d?.repairReportCount > 0 ? (d.onlineHours / d.repairReportCount).toFixed(2) : d.onlineHours?.toFixed(2);
            let statusList2 = d.score
            fault_time_all.value += d.onlineHours;
            repairCount.value += d?.repairReportCount
            statePhData2.value.push({
                name: isFacType.value == 0 ? d.factoryName : isFacType.value == 1 ? d.groupName : d.machineName,
                statusList1,
                statusList2,
            })
        })
        if (repairCount.value?.length > 0) {
            faultTme.value = (fault_time_all.value / repairCount.value).toFixed(2);
        } else {
            faultTme.value = fault_time_all.value?.toFixed(2);
        }

    }
    statePhData.value = splitArrayIntoSubarrays(statePhData2.value, 4)
    for (let i = 0; i < statePhData.value.length; i++) {
        setTimeout(() => {
            initMachineWarningChart(statePhData.value[i], 'chartC' + i)
        }, 200)
    }
}

// 绘制区域设备状态排行
const initMachineWarningChart = (statePhData: any, id: any) => {
    // if (!document.getElementById(id)) return
    // let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
    let xData: any = []

    xData = statePhData.map((d: any) => d.name)
    let statusList1 = statePhData.map((d: any) => d.statusList1)
    let statusList2 = statePhData.map((d: any) => d.statusList2)

    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            show: true,
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: 'axis',
            confine: false, // 默认是false，设为true会限制在图表区域内
            z: 9999, // 确保层级最高
            appendToBody: true, // 可选，确保 Tooltip 能溢出
            axisPointer: {
                type: 'cross',
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: xData,
                axisLabel: {
                    color: theme.value == 'white' ? '#333' : "#ffffff",
                    fontSize: 12,
                    showMaxLabel: true,
                    formatter: (value: string) => {
                        // 截断过长的文字
                        if (value.length > 5) {
                            return value.slice(0, 5) + '...';
                        }
                        return value;
                    },
                },

            }
        ],
        yAxis: [
            {
                type: 'value',
                name:'小时',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            },
            {
                type: 'value',
                name:'分',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: false //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['平均故障间隔'],
                type: 'bar',
                // barGap:"10px",
                barWidth: 20,
                emphasis: {
                    focus: 'series'
                },
                data: statusList1,
                yAxisIndex: 0, // 指定使用第一个y轴
                itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0, color: 'rgba(45, 205, 255, 0.3)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(45, 205, 255, 1)' // 100% 处的颜色
                    }])
                },

            },
            {
                name: langObj.value['运行设备健康分数'],
                type: 'bar',
                barWidth: 20,
                // barGap:"10px",
                emphasis: {
                    focus: 'series'
                },
                data: statusList2,
                yAxisIndex: 1, // 指定使用第一个y轴
                itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0, color: 'rgba(28, 196, 139, 0.1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(28, 196, 139, 1)' // 100% 处的颜色
                    }])
                }
            }
        ]
    };
    // myChart && myChart.setOption(option)
    let myChart = null;
    const list = document.getElementsByClassName(id);
    for (let jj = 0; jj < list.length; jj++) {
        const element = list[jj];
        myChart = echarts.init(element);
        myChart.clear();
        myChart.setOption(option);
    }

    myChart && myChart.on('click', (params: any) => {
        console.log(params);
    });




}

// 获取各厂报警数量排行数据
let visibleChartD = ref(true)
const getFactoryWarningChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()

    //本年
    if (factoryWarningTime.value === 1) {
        startDate = `${year}/01/01 00:00:00`
    }
    //本季度
    if (factoryWarningTime.value === 2) {
        let m: any = 0
        if (month >= 1 && month <= 3) {
            m = 1
        }
        if (month >= 4 && month <= 6) {
            m = 4
        }
        if (month >= 7 && month <= 9) {
            m = 7
        }
        if (month >= 10 && month <= 12) {
            m = 10
        }
        m = m > 9 ? m : '0' + m
        startDate = `${year}/${m}/01 00:00:00`
    }
    //本月
    if (factoryWarningTime.value === 3) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (factoryWarningTime.value === 4) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    // let factoryIds = factoryList.value.map((p:any) => p._id)

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [machineWarningFac.value]
    }


    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    factoryWarningChartData.value = []

    if (result && result.data) {
        let facIds = [... new Set(result.data.list.map((p: any) => p.factoryId))]
        facIds.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.factoryId === d)
            let condition1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let condition2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let condition3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            // factoryWarningChartData.value.push([
            //     { label: '可用报警', num: condition1, color: '#DDD246' },
            //     { label: '警戒报警', num: condition2, color: '#CB6A34' },
            //     { label: '故障报警', num: condition3, color: '#B81212' },
            // ])

            factoryWarningChartData.value.push({
                factoryName: factoryList.value.find((p: any) => p._id === d)?.factoryName,
                data: [condition1, condition2, condition3],
            })
        })
    }

    visibleChartD.value = false
    visibleChartD.value = true

    setTimeout(() => {
        initFactoryWarningChart()
    }, 200);

}

// 绘制各厂报警数量排行图
const initFactoryWarningChart = () => {
    if (!document.getElementById("chartD")) return
    let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
    let list1: any = [], list2: any = [], list3: any = [], list4: any = []
    factoryWarningChartData.value.map((d: any) => {
        list1.push(d.factoryName)
        list2.push(d.data[0])
        list3.push(d.data[1])
        list4.push(d.data[2])
    })

    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: list1,
                axisLabel: {
                    color: theme.value == 'white' ? '#333' : "#ffffff",
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: list2,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list3,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list4,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };


    myChart && myChart.setOption(option)
}

// 获取智能诊断报警统计
let visibleChartE = ref(true)
const getwarningBarChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [warningTrendFac.value]
    }

    let config: any = {
        params: {
            factoryId: warningTrendFac.value,
            groupId: bottom_select_groupId.value
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/workflows/alarm-statistics', config)
    warningBarChartData.value = []
    if (result && result.data) {
        result.data.reverse()
        result.data.map((d: any) => {
            let statusList1 = d.alertCount
            let statusList2 = d.alarmCount
            let statusList3 = d.dangerCount
            warningBarChartData.value.push({
                date: d.date,
                statusList1,
                statusList2,
                statusList3,
            })
        })
    }
    // visibleChartE.value = false
    // visibleChartE.value = true
    warningBarChartDataSplit.value = splitArrayIntoSubarrays(warningBarChartData.value, 5)
    for (let i = 0; i < warningBarChartDataSplit.value.length; i++) {
        setTimeout(() => {
            initWarningTrendChart(warningBarChartDataSplit.value[i], 'chartE' + i)
        }, 200)
    }
}


// 获取智能诊断报警统计
const initWarningTrendChart = (warningBarChartData: any, id: any) => {
    // if (!document.getElementById(id)) return
    // let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
    // myChart.clear()
    let colors: any = ['#DDD246', '#CB6A34', '#B81212']
    let xData: any = []

    xData = warningBarChartData.map((d: any) => d.date).slice(0, warningBarChartData.length)
    let statusList1 = warningBarChartData.map((d: any) => d.statusList1)
    let statusList2 = warningBarChartData.map((d: any) => d.statusList2)
    let statusList3 = warningBarChartData.map((d: any) => d.statusList3)
    let option = {
        tooltip: {
            show: true,
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: 'axis',
            appendToBody: true, // 可选，确保 Tooltip 能溢出
            axisPointer: {
                type: 'cross',
            }
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '5%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
            show: false,
        },
        xAxis: [
            {
                show: true,
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xData,
                splitLine: {
                    show: false,
                    lineStyle: {
                        opacity: 0.3
                    }
                },
                axisLabel: {
                    color: theme.value == 'white' ? '#333' : "#ffffff",
                    fontSize: 12,
                    showMaxLabel: true,
                },
            }
        ],
        yAxis: {
            type: 'value',
            scale: true,
            splitLine: {    //网格线
                lineStyle: {
                    color: 'rgba(255,255,255,0.5)',
                    type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true //隐藏或显示
            },
        },

        series: [
            {
                name: langObj.value['可用报警'],
                type: 'bar',
                data: statusList1,
                barWidth: 15,
                itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0, color: 'rgba(221, 210, 70, 0.1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(221, 210, 70, 1)' // 100% 处的颜色
                    }])
                }
            },
            {
                name: langObj.value['警戒报警'],
                type: 'bar',
                barWidth: 15,
                data: statusList2,
                itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0, color: 'rgba(203, 67, 52, 0.1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(203, 67, 52, 1)' // 100% 处的颜色
                    }])
                }
            },
            {
                name: langObj.value['故障报警'],
                type: 'bar',
                barWidth: 15,
                data: statusList3,
                itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0, color: 'rgba(158, 3, 4, 0.1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(158, 3, 4, 1)' // 100% 处的颜色
                    }])
                }
            }
        ]
    };

    // myChart && myChart.setOption(option)
    const list = document.getElementsByClassName(id);
    for (let jj = 0; jj < list.length; jj++) {
        const element = list[jj];
        let myChart = echarts.init(element);
        myChart.clear();
        myChart.setOption(option);
    }



}

//硬件状态统计弹窗
let yjVisible = ref(false);
let typeSelectYj = ref(1)
let yjModelSearch: any = ref({
    groupId: null,
    machine: "",

    status: "",
    dasCode: "",
    total: 0,
    skip: 1,
    take: 10
})
const paginationYj = computed(() => ({
    total: yjModelSearch.value.total,
    current: yjModelSearch.value.skip,
    pageSize: yjModelSearch.value.take,
}));

let machineListYjModel = ref([])

const filterOptionMachineListYj = (input: string, option: any) => {
    return option.name.indexOf(input) >= 0;
};
// 选择区域
const selectYjGroup = (ev: any) => {
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    yjModelSearch.value.machine = ''
    if (ev) {
        machineListYjModel.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
    } else {
        machineListYjModel.value = []
    }
}
// 选择设备
let sensorList2 = ref([])
const selectYjMachine = async (ev: any) => {
    yjModelSearch.value.sensor = ''
    if (ev) {
        sensorList2.value = await getSensorList(ev)
    } else {
        sensorList2.value = []
    }
}
const filterOptionSensorListYj = (input: string, option: any) => {
    return option.sensorPlace.indexOf(input) >= 0;
};
const getSensorList = async (machine: any) => {

    let config: any = {
        params: {
            machineId: machine,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors", config);
    if (result && result.data) {
        return result.data
    }
};

let cgqListData =ref([])//传感器列表数据
    let cjListData = ref([])//采集单元列表
    const scqStatusList = ref([
        {label:'在线',value:1,color:"#61c08f"},
        {label:'离线',value:0,color:"#FF0000"},
    ])
    let cgqState:any = ref(null)
    const handleTableChangeYj = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      yjModelSearch.value.skip = pagination.current
      yjModelSearch.value.take = pagination.pageSize;
      if(typeSelectYj.value==1){
            getCgqListData()
        }else{
            getCjListData()
        }
    };
    
    const sjStateList =ref([
        {label:'延迟',value:true,color:"#FF0000"},
    ])
    
    const yjStateColumns = [
        {
            title: getLanguage('区域', language.value),
            dataIndex: "groupName",
        },
        
        {
            title: getLanguage('设备名称', language.value),
            dataIndex: "machineName",
        },
        {
            title: getLanguage('测点', language.value),
            dataIndex: "sensorPlace",
        },
        {
            title: getLanguage('传感器SN号', language.value),
            dataIndex: "sensorSn",
        },
       
        {
            title: getLanguage('传感器类型', language.value),
            dataIndex: "wireless",                                                      
        },
         {
            title: getLanguage('数据延迟', language.value),
            dataIndex: "index_delay",
        },
        
        // {
        //     title: getLanguage('波形数据延迟', language.value),
        //     dataIndex: "bx",
        // },
        // {
        //     title: getLanguage('指标数据延迟', language.value),
        //     dataIndex: "zb",
        // },
        {
            title: getLanguage('电压', language.value),
            dataIndex: "peak_vol",
        },
        {
            title: getLanguage('电量', language.value)+"(%)",
            dataIndex: "bat_vol",
        }
        
    ];
    const yjCjStateColumns = [
        {
            title: getLanguage('数采器编码', language.value),
            dataIndex: "config_code",
        },
        
        {
            title: getLanguage('数采器类型', language.value),
            dataIndex: "config_type_name",
        },
        {
            title: getLanguage('数采器状态', language.value),
            dataIndex: "config_status",
        },
       
        {
            title: getLanguage('数据延时', language.value),
            dataIndex: "delay",                                                      
        },
         {
            title: getLanguage('最后通讯时间', language.value),
            dataIndex: "config_last_online",
        },
        // {
        //     title: getLanguage('波形数据延迟', language.value),
        //     dataIndex: "bx",
        // },
        // {
        //     title: getLanguage('指标数据延迟', language.value),
        //     dataIndex: "zb",
        // },
        
    ];
    const selelctTypeYj = (type:any) => {
        typeSelectYj.value = type;
        yjModelSearch.value.skip = 1;
      
        cgqListData.value=[]
        cjListData.value=[]
        if(typeSelectYj.value==1){
            getCgqListData()
        }else{
            getCjListData()
        }
    }
    const selectYjState = (type:any,state:any) => {
        yjVisible.value = true;
        yjModelSearch.value.groupId = bottom_select_groupId.value;
        typeSelectYj.value = type;
        cgqState.value = state
       
    
        if(typeSelectYj.value==1){
            cgqListData.value=[]
            yjModelSearch.value.skip = 1;
            getCgqListData()
        }else{
            cjListData.value=[];
            yjModelSearch.value.skip = 1;
            yjModelSearch.value.status = state;
            getCjListData()
        }
        
       
    }
    const reseYjSearch =()=>{
            yjModelSearch.value={
                groupId: null,
                machine:"",
                status:"",
                dasCode:"",
                total:0,
                skip:1,
                take:10
        }
        if(typeSelectYj.value==1){
            cgqListData.value=[]
            yjModelSearch.value.skip = 1;
            getCgqListData()
        }else{
            cjListData.value=[]
            yjModelSearch.value.skip = 1;
            getCjListData()
        }
    }
    //获取硬件传感器列表
    const getCgqListData = async(state:any) => {
        let config: any = {
            params: {
                groupId:yjModelSearch.value.groupId,
                machineId: yjModelSearch.value.machine,
                isBatteryNormal:cgqState.value,
                skip:yjModelSearch.value.skip,
                take:yjModelSearch.value.take,
               
                factoryId:bottom_select_facId.value,
               
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let  result = await request.get('/api/external/hardware/sensor/list', config)
        if(result && result.status === 200) {
            cgqListData.value=result?.data?.list;
            yjModelSearch.value.total = result?.data?.count
        }
       
    }
    
    //获取采集单元列表
    const getCjListData = async() => {
       
       
        let config: any = {
            params: {
                factoryId:bottom_select_facId.value,
                status:yjModelSearch.value.status,
                dasCode:yjModelSearch.value.dasCode,
                skip:yjModelSearch.value.skip,
                take:yjModelSearch.value.take,
                // grade:znZdReportSearch.value.grade,
            },
            headers: {
                requestId: uuidv4(),
            },
        };
       
       let result = await request.get('/api/external/hardware/das/list', config)
       if(result && result.status === 200) {
            cjListData.value=result.data.list;
            // znZdReportData.value = result.data.list
            yjModelSearch.value.total = result.data.count
        }
    }
    

//智能诊断报告记录
let znZdReportVisible = ref(false);
let machineListZdReport = ref([])
let znZdReportSearch: any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    grade: null,
    order: -1
})
const filterOptionZdReport = (input: string, option: any) => {
    return option.name.indexOf(input) >= 0;
};
// 选择区域
const selectGroup = (ev: any) => {
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    znZdReportSearch.value.machineId = ''
    if (ev) {
        machineListZdReport.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
    } else {
        machineListZdReport.value = []
    }
}

const znZdReportColumns = [
    {
        title: getLanguage('报告时间', language.value),
        dataIndex: "date",
    },
    {
        title: getLanguage('区域', language.value),
        dataIndex: "groupName",
    },

    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    // {
    //     title: getLanguage('测点', language.value),
    //     dataIndex: "point",
    // },

    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },

    {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }

];
let znZdReportData = ref([])
const selectZnZdReport = (type: any) => {
    resetZnZdReport()
    znZdReportVisible.value = true;
    getZnZdReport()
}
const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
};
const onCalendarChange = (val: any) => {
    dates.value = val;
};
let gradeList = [
    { value: -1, label: "健康", color: '#61c08f' },
    { value: 0, label: "可用", color: '#d2de49' },
    { value: 1, label: "警戒", color: '#cd5f3b' },
    { value: 2, label: "故障", color: '#921e37' },
];

const pagination = computed(() => ({
    total: znZdReportSearch.value.total,
    current: znZdReportSearch.value.skip,
    pageSize: znZdReportSearch.value.take,
}));

const resetZnZdReport = () => {
    znZdReportSearch.value = {
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
        total: 0,
        skip: 1,
        take: 10,
        sort: 'date',
        grade: null,
        order: -1
    }
    getZnZdReport()
}
const handleTableChangeZdReport = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znZdReportSearch.value.sort = sorter.field
        znZdReportSearch.value.order = order
    }
    // 处理分页数据
    znZdReportSearch.value.skip = pagination.current
    znZdReportSearch.value.take = pagination.pageSize
    getZnZdReport();
};
//获取智能诊断报告
const getZnZdReport = async () => {
    let config: any = {
        params: {
            skip: znZdReportSearch.value.skip,
            take: znZdReportSearch.value.take,
            type: [1],
            keyword: znZdReportSearch.value.keyword,
            sort: znZdReportSearch.value.sort,
            order: znZdReportSearch.value.order,
            groupId: znZdReportSearch.value.groupId,
            machineId: znZdReportSearch.value.machineId,
            factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
            grade: znZdReportSearch.value.grade,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (znZdReportSearch.value.date && znZdReportSearch.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(znZdReportSearch.value.date[0]), 1);
        config.params.end = transformDate2(new Date(znZdReportSearch.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)

    if (result && result.status === 200) {
        let member = JSON.parse(localStorage.getItem('memberInfo') || '')
        result.data.list.forEach((element: any) => {
            if (element.expertReport) {
                element.expertReport.faultCause = element.expertReport.faultCause
                    ? element.expertReport.faultCause.replace(/\n/g, "<br>")
                    : "";
                element.gradeText = element.expertReport.grade

                let faultEvents = ''
                element.expertReport.diagnosisDetails.forEach((item2: any, index2: any) => {
                    var grade = gradeList.find((t) => t.value == item2.grade);
                    item2.gradeText = grade ? grade.label : "";
                    faultEvents = faultEvents + item2.faultEvent
                });

                element.faultEvents = faultEvents
            }
            if (element.machines && element.machines[0]) {
                element.machineName = element.machines[0].machineName;
                // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
                element.groupName = getGroupName(element.machines[0]?.groupId)
            }
        });

        znZdReportData.value = result.data.list
        znZdReportSearch.value.total = result.data.count
    }
}
const goZdReport = () => {
    router.push('/intelligent-diagnosis-manage/listReport')
}

//智能周报记录
let znWeekReportVisible = ref(false);
let typeListWeek = [
    { label: '周报', value: 3 },
    { label: '月报', value: 4 },
]
let weekData = ref([])
let znWeekReportSearch: any = ref({
    date: null,
    type: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1,
    groupId: "",
    type: 3
})
const paginationWeek = computed(() => ({
    total: znWeekReportSearch.value.total,
    current: znWeekReportSearch.value.skip,
    pageSize: znWeekReportSearch.value.take,
}));

const znWeekReportColumns = [
    {
        title: langObj.value['报告周期'],
        dataIndex: 'cycle',
    },
    {
        title: langObj.value['报告标题'],
        dataIndex: 'title',
    },
    {
        title: langObj.value['报告类型'],
        dataIndex: 'type',
    },
    {
        title: langObj.value['健康设备'] + '（台）',
        dataIndex: 'count1',
    },
    {
        title: langObj.value['可用设备'] + '（台）',
        dataIndex: 'count2',
    },
    {
        title: langObj.value['警戒设备'] + '（台）',
        dataIndex: 'count3',
    },
    {
        title: langObj.value['故障设备'] + '（台）',
        dataIndex: 'count4',
    },
    {
        title: langObj.value['分析周期'],
        dataIndex: 'cycle2',
    },
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },



];
const selectZnWeekReport = () => {
    resetWeek()
    znWeekReportVisible.value = true;
    getDataWeek()
}

const detailsWeek = (ev: any) => {
    router.push('/report/mwDetail/' + ev.id)
}



const handleTableChangeWeek = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znWeekReportSearch.value.sort = sorter.field
        znWeekReportSearch.value.order = order
    }
    // 处理分页数据
    znWeekReportSearch.value.skip = pagination.current
    znWeekReportSearch.value.take = pagination.pageSize
    getDataWeek();
};

const getDataWeek = async () => {
    let type = znWeekReportSearch.value.type ? [znWeekReportSearch.value.type] : [3, 4]
    if (bottom_select_groupId.value) {
        znWeekReportSearch.value.groupId = bottom_select_groupId.value
    } else {
        znWeekReportSearch.value.groupId = ''
    }
    let config: any = {
        params: {
            skip: znWeekReportSearch.value.skip,
            take: znWeekReportSearch.value.take,
            type,
            sort: znWeekReportSearch.value.sort,
            order: znWeekReportSearch.value.order,
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
            groupId: znWeekReportSearch.value.groupId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (znWeekReportSearch.value.date && znWeekReportSearch.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(znWeekReportSearch.value.date[0]), 1);
        config.params.end = transformDate2(new Date(znWeekReportSearch.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)


    if (result && result.status === 200) {
        result.data.list.map((d: any) => {
            let count1 = 0, count2 = 0, count3 = 0, count4 = 0
            d.weekReport && d.weekReport.map((i: any) => {
                if (i.status === '故障') {
                    count4++
                }
                if (i.status === '警戒') {
                    count3++
                }
                if (i.status === '可用') {
                    count2++
                }
                if (i.status === '健康') {
                    count1++
                }
            })
            d.count1 = count1
            d.count2 = count2
            d.count3 = count3
            d.count4 = count4

            if (language.value === 'English') {
                d.title = d.title.replace('周报', 'weekly report')
            }
        })


        weekData.value = result.data.list
        znWeekReportSearch.value.total = result.data.count
    }
}
const getDate = (date: any) => {
    date = new Date(date)
    let year = date.getFullYear()
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    let w = date.getDay(),
        d = date.getDate();
    if (w == 0) {
        w = 7;
    }

    if (language.value == 'Chinese') {
        return year + '年' + month + '月' + ' ' + '第' + Math.ceil((d + 6 - w) / 7) + '周'
    } else {
        let monList = [
            { label: 'January', value: '01' },
            { label: 'February', value: '02' },
            { label: 'March', value: '03' },
            { label: 'April', value: '04' },
            { label: 'May', value: '05' },
            { label: 'June', value: '06' },
            { label: 'July', value: '07' },
            { label: 'August', value: '08' },
            { label: 'January', value: '09' },
            { label: 'October', value: '10' },
            { label: 'November', value: '11' },
            { label: 'December', value: '12' },
        ]
        return monList.find((p: any) => p.value == month)?.label + ' ' + year + ',' + 'Week' + Math.ceil((d + 6 - w) / 7)
    }


}

const getDate2 = (ev: any, type: any) => {
    let day = type === 3 ? 7 : 30
    let date = new Date(ev).getTime() - (1000 * 60 * 60 * 24 * day)
    let begin = transformDate3(new Date(date))
    return begin + ' ~ ' + transformDate3(ev)
}
const resetWeek = () => {
    znWeekReportSearch.value = {
        ...znWeekReportSearch.value,
        date: null,
        type: 3,
        groupId: ""
    }
    // getDataWeek()
}

//智能门限报警记录
let znMxWarnVisible = ref(false);

let mxWarnData = ref([])
let znMxWarnSearch: any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type: 4,
    total: 0,
})
const paginationMxWarn = computed(() => ({
    total: znMxWarnSearch.value.total,
    current: znMxWarnSearch.value.skip,
    pageSize: znMxWarnSearch.value.take,
}));


const znMxWarnColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
    },
    {
        title: langObj.value["报警类型"],
        dataIndex: "type",
    },
    {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
    },
    {
        title: langObj.value["设备"],
        dataIndex: "machineName",
    },
    {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
    },

    {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
    },
    {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }

];
const selectZnMxWarn = (type: any) => {
    resetMxWarn()
    znMxWarnVisible.value = true
    getMxWarnData()
}

let machineListMxWarn = ref([])
// 选择区域
const selectGroupMxWarn = (ev: any) => {

    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if (ev) {
        machineListMxWarn.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
    } else {
        machineListMxWarn.value = []
    }
}
const getMxWarnData = async () => {

    let config: any = {
        params: {
            skip: znMxWarnSearch.value.skip,
            take: znMxWarnSearch.value.take,
            groupId: znMxWarnSearch.value.groupId,
            machineId: znMxWarnSearch.value.machineId,
            type: znMxWarnSearch.value.type,
            handleStatus: znMxWarnSearch.value.handleStatus,
            expertCertification: znMxWarnSearch.value.expertCertification,
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
            requestId: uuidv4(),
        },
    };



    if (znMxWarnSearch.value.date && znMxWarnSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
            new Date(znMxWarnSearch.value.date[0]),
            1
        );
        config.params.end = transformDate2(new Date(znMxWarnSearch.value.date[1]), 2);
    }

    if (znMxWarnSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value == znMxWarnSearch.value.condition
        )?.realValue;

    }

    let result = await request.get("/api/workflows", config);
    if (result) {
        mxWarnData.value = result.data.list;
        znMxWarnSearch.value.total = result.data.total;
    }
};

const resetMxWarn = () => {
    znMxWarnSearch.value = {
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type: 4
    }
    // getMxWarnData()
}
const handleTableChangeMxWarn = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znMxWarnSearch.value.sort = sorter.field;
        znMxWarnSearch.value.order = order;
    }
    // 处理分页数据
    znMxWarnSearch.value.skip = pagination.current;
    znMxWarnSearch.value.take = pagination.pageSize;
    getZdWarnData();
};



//智能诊断报警记录
let znZdWarnVisible = ref(false);
let typeList = [
    { label: "智能诊断", value: 1 },
    // {label: '维修记录', value: 2},
    // {label: '设备检查', value: 3},
    { label: "门限报警", value: 4 },
];
let zdWarnData = ref([])
let znZdWarnSearch: any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type: 1
})
const paginationZdWarn = computed(() => ({
    total: znZdWarnSearch.value.total,
    current: znZdWarnSearch.value.skip,
    pageSize: znZdWarnSearch.value.take,
}));


const znZdWarnColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
    },
    {
        title: langObj.value["报警类型"],
        dataIndex: "type",
    },
    {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
    },
    {
        title: langObj.value["设备"],
        dataIndex: "machineName",
    },
    {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
    },

    {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
    },
    {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }

];
const selectZnZdWarn = (type: any) => {
    znZdWarnVisible.value = true
    resetZdWarn()
    znZdWarnSearch.value.condition = type;
    znZdWarnSearch.value.groupId = bottom_select_groupId.value;
    getZdWarnData()
}
let machineListZdWarn = ref([])
// 选择区域
const selectGroupZdWarn = (ev: any) => {

    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if (ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getZdWarnData = async () => {
    // if(bottom_select_groupId.value){
    //      znZdWarnSearch.value.groupId = bottom_select_groupId.value
    // }else{
    //     znZdWarnSearch.value.groupId = ""
    // }

    let config: any = {
        params: {
            skip: znZdWarnSearch.value.skip,
            take: znZdWarnSearch.value.take,
            groupId: znZdWarnSearch.value.groupId,
            machineId: znZdWarnSearch.value.machineId,
            type: znZdWarnSearch.value.type,
            handleStatus: znZdWarnSearch.value.handleStatus,
            expertCertification: znZdWarnSearch.value.expertCertification,
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
            requestId: uuidv4(),
        },
    };



    if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
            new Date(znZdWarnSearch.value.date[0]),
            1
        );
        config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
    }

    if (znZdWarnSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value == znZdWarnSearch.value.condition
        )?.realValue;

    }

    let result = await request.get("/api/workflows", config);
    if (result) {
        zdWarnData.value = result.data.list;
        znZdWarnSearch.value.total = result.data.total;
    }
};

const resetZdWarn = () => {
    znZdWarnSearch.value = {
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type: 1
    }
    // getZdWarnData()
}
const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znZdWarnSearch.value.sort = sorter.field;
        znZdWarnSearch.value.order = order;
    }
    // 处理分页数据
    znZdWarnSearch.value.skip = pagination.current;
    znZdWarnSearch.value.take = pagination.pageSize;

    getZdWarnData();
};


//设备报警趋势弹窗
let warnTrendVisible = ref(false);
let warnTrendData = ref([])
let warnTrendSearch: any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    typeList: [1, 4]
})
const paginationWarnTrend = computed(() => ({
    total: warnTrendSearch.value.total,
    current: warnTrendSearch.value.skip,
    pageSize: warnTrendSearch.value.take,
}));


const warnTrendColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
    },
    {
        title: langObj.value["报警类型"],
        dataIndex: "type",
    },
    {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
    },
    {
        title: langObj.value["设备"],
        dataIndex: "machineName",
    },
    {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
    },

    {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
    },
    {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }

];
const selectWarnTrend = (type: any) => {
    warnTrendVisible.value = true
    resetWarnTrend()
    warnTrendSearch.value.condition = type;
    warnTrendSearch.value.groupId = bottom_select_groupId.value;
    getWarnTrendData()
}

// 选择区域
const selectGroupWarnTrend = (ev: any) => {

    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    warnTrendSearch.value.machineId = ''
    if (ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getWarnTrendData = async () => {


    let config: any = {
        params: {
            skip: warnTrendSearch.value.skip,
            take: warnTrendSearch.value.take,
            groupId: warnTrendSearch.value.groupId,
            machineId: warnTrendSearch.value.machineId,
            typeList: warnTrendSearch.value.typeList,
            handleStatus: warnTrendSearch.value.handleStatus,
            expertCertification: warnTrendSearch.value.expertCertification,
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
            requestId: uuidv4(),
        },
    };



    if (warnTrendSearch.value.date && warnTrendSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
            new Date(warnTrendSearch.value.date[0]),
            1
        );
        config.params.end = transformDate2(new Date(warnTrendSearch.value.date[1]), 2);
    }

    if (warnTrendSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value == warnTrendSearch.value.condition
        )?.realValue;

    }

    let result = await request.get("/api/workflows", config);
    if (result) {
        warnTrendData.value = result.data.list;
        warnTrendSearch.value.total = result.data.total;
    }
};

const resetWarnTrend = () => {
    warnTrendSearch.value = {
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        typeList: [1, 4]
    }
    // getWarnTrendData()
}
const handleTableChangeWarnTrend = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        warnTrendSearch.value.sort = sorter.field;
        warnTrendSearch.value.order = order;
    }
    // 处理分页数据
    warnTrendSearch.value.skip = pagination.current;
    warnTrendSearch.value.take = pagination.pageSize;

    getWarnTrendData();
};




//设备类型统计
const bubbles: any = ref([
    { id: 1, x: 0, y: 0, w: 7, h: 7, class: "anUp", text1: "转子不平衡", text2: 20, text3: "条报警", },
    { id: 2, x: 7.2, y: 2.5, w: 4.4, h: 4.4, class: "anDown", text1: "转子不平衡", text2: 20, text3: "条报警" },
    { id: 3, x: 7.2, y: 0.5, w: 3, h: 3, class: "anLeft", text1: "部件碰摩", text2: 20, text3: "条" },
    { id: 4, x: 10.5, y: 6, w: 3, h: 3, class: "anRight", text1: "部件碰摩", text2: 20, text3: "条" },
    { id: 5, x: 12, y: 0, w: 3, h: 3, class: "anUp2", text1: "部件碰摩", text2: 20, text3: "条" },
    { id: 6, x: 16, y: 2.8, w: 4.4, h: 4.4, class: "anDown", text1: "转子不平衡", text2: 20, text3: "条报警" },
    // { id: 6, x: 10.2, y: 2.5,w:3,h:3,class:"anRight",text1:"部件碰摩",text2:20,text3:"条"},
])
const bubbleStyle = (bubble: any) => {
    return {
        width: `${bubble.w}vw`,
        height: `${bubble.h}vw`,
        left: `${bubble.x}vw`,
        bottom: `${bubble.y}vw`, // 使用bottom而非top是因为我们的动画是向上移动的，所以需要从底部开始计算位置。
    };
}
//获取类型数据
let machineTypeDataSplit: any = ref([])
let machineTypeData = ref([])
const getMachineTypeData = async () => {
    let config: any = {
        params: {
            factoryId: bottom_select_facId.value,
            groupId: bottom_select_groupId.value

        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/machine-type/alarm-statistics', config)
    if (result && result.status === 200) {
        machineTypeData.value = result.data;
        machineTypeDataSplit.value = splitArrayIntoSubarrays(result.data, 7)
        machineTypeDataSplit.value.map((item: any) => {
            item.map((item2: any, index: any) => {
                item2.id = index,
                    item2.x = bubbles.value[index].x,
                    item2.y = bubbles.value[index].y,
                    item2.w = bubbles.value[index].w,
                    item2.h = bubbles.value[index].h,
                    item2.class = bubbles.value[index].class,
                    item2.text1 = item2.secondCategory,
                    item2.text1_spli = item2.secondCategory?.length > 4 ? item2.secondCategory?.substring(0, 4) + "..." : item2.secondCategory,
                    item2.text2 = item2.faultsTotal,
                    item2.text3 = bubbles.value[index].text3
            })
        })
    }
}




//设备类型列表弹窗
let machineTypeVisible = ref(false)
let machineTypeList = ref([])
let machineTypeIndex = ref(0)
let machineTypeId = ref(null)
let machineTypeSearch: any = ref({
    total: 0,
    skip: 1,
    take: 10,
    groupId: null,
    machineId: null,
    date: null,
    handleStatus: null,
    condition: null


})

const handleTableChangeMachineType = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    machineTypeSearch.value.skip = pagination.current
    machineTypeSearch.value.take = pagination.pageSize

    getMachineTypeListData();

};


const paginationMachineType = computed(() => ({
    total: machineTypeSearch.value.total,
    current: machineTypeSearch.value.skip,
    pageSize: machineTypeSearch.value.take,
}));

const resetMachineTypeSearch = () => {
    machineTypeSearch.value = {
        total: 0,
        skip: 1,
        take: 10,
        groupId: null,
        machineId: null,
        date: null,
        handleStatus: null,
        condition: null
    }
    getMachineTypeListData()
}



const machineTypeColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
    },
    {
        title: langObj.value["报警类型"],
        dataIndex: "type",
    },
    {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
    },
    {
        title: langObj.value["设备"],
        dataIndex: "machineName",
    },
    {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
    },

    {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
    },
    {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
];

//获取machineType列表数据
const getMachineTypeListData = async () => {
    let config: any = {
        params: {
            factoryId: bottom_select_facId.value,
            groupId: machineTypeSearch.value.groupId,
            skip: machineTypeSearch.value.skip,
            take: machineTypeSearch.value.take,
            machineId: machineTypeSearch.value.machineId,
            begin: null,
            end: null,
            handleStatus: machineTypeSearch.value.handleStatus,
            conditionList: [],
            machineTypeId: machineTypeId.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };


    if (machineTypeSearch.value.date && machineTypeSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
            new Date(machineTypeSearch.value.date[0]),
            1
        );
        config.params.end = transformDate2(new Date(machineTypeSearch.value.date[1]), 2);
    }

    if (machineTypeSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value == machineTypeSearch.value.condition
        )?.realValue;

    }

    let result = await request.get('/api/external/machine-type/alarms', config)
    if (result && result.status === 200) {
        machineTypeList.value = result.data.list;
        machineTypeSearch.value.total = result.data.total;
    }
}





const selectMachineType = (id: any) => {
    machineTypeVisible.value = true;
    machineTypeSearch.value.groupId = bottom_select_groupId.value;
    if (id) {
        machineTypeId.value = id;
    } else {
        machineTypeId.value = machineTypeData.value && machineTypeData.value[0]?.machineTypeId;
    }

    getMachineTypeListData()
    // machineTypeList.value = machineTypeData.value[machineTypeIndex.value].faults;
}
const selectMachineTypeList = (item: any, index) => {
    machineTypeId.value = item.machineTypeId;
    getMachineTypeListData()
}








// 设备列表弹框
let machineModelSearch: any = ref({
    condition: null,
    status: null,
    groupId: null,
    factoryId: '',
    keyWord: '',
    skip: 1,
    take: 10,
    total: 0,

})
let machineVisible = ref(false)
let machineDataList: any = ref([])

const viewMachine = (type: any) => {
    if (type == 1) {
        router.push('/dashboard/machine')
    } else {
        router.push('/diagnosisN/overview')
    }

}



const handleTableChangeMachineList = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    machineModelSearch.value.skip = pagination.current
    machineModelSearch.value.take = pagination.pageSize

};


const paginationMachineList = computed(() => ({
    total: machineModelSearch.value.total,
    current: machineModelSearch.value.skip,
    pageSize: machineModelSearch.value.take,
}));




let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
    { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
    { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
];


let whetherList = [
    { label: "是", value: true },
    { label: "否", value: false },
];

const machineColumns = [
    {
        title: getLanguage('设备编号', language.value),
        dataIndex: "machineCode",
    },
    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    {
        title: getLanguage('所属工厂', language.value),
        dataIndex: "factoryId",
    },
    {
        title: getLanguage('设备区域', language.value),
        dataIndex: "groupId",
    },
    {
        title: getLanguage('设备位置', language.value),
        dataIndex: "position",
    },
    // {
    //     title: getLanguage('设备厂家', language.value),
    //     dataIndex: "supplier",
    // },
    // {
    //     title: getLanguage('设备型号', language.value),
    //     dataIndex: "model",
    // },
    {
        title: getLanguage('设备类型', language.value),
        dataIndex: "machineTypeAll",
    },
    {
        title: getLanguage('设备SN号', language.value),
        dataIndex: "sn",
    },
    {
        title: getLanguage('巡检序号', language.value),
        dataIndex: "sort",
    },
    // {
    //     title: getLanguage('设备描述', language.value),
    //     dataIndex: "remark",
    // },
    {
        title: getLanguage('健康状态', language.value),
        dataIndex: "condition",
    },
    {
        title: getLanguage('运行状态', language.value),
        dataIndex: "status",
    },
    {
        title: getLanguage('设备运行时间', language.value) + '(' + langObj.value['小时'] + ')',
        dataIndex: "onlineHours",
    },
    {
        title: getLanguage('检修次数', language.value),
        dataIndex: "repairReportsCount",
    },
    {
        title: getLanguage('平均故障间隔时间', language.value) + '(' + langObj.value['小时'] + ')',
        dataIndex: "faultIntervalTimes",
    },
    {
        title: "操作",
        dataIndex: "act",
    },
];

// 选择区域
const selectGroupMachine = (ev: any) => {
    // if(ev){
    //     machineModelSearch.value.groupId = ev;
    // }else{
    //     machineModelSearch.value.groupId = bottom_select_groupId.value
    // }
}


const selectMachineCondition = (condition: any) => {
    resetMachine()
    machineModelSearch.value.condition = condition
    machineModelSearch.value.factoryId = conditionSelectFac.value;
    machineVisible.value = true
    machineModelSearch.value.groupId = bottom_select_groupId.value;
    getDataMachine()
}


const selectMachineStatus = (status: any) => {
    resetMachine()
    machineModelSearch.value.status = status
    machineModelSearch.value.factoryId = statusSelectFac.value
    machineModelSearch.value.groupId = bottom_select_groupId.value;
    machineVisible.value = true
    getDataMachine()
}

const resetMachine = () => {
    machineModelSearch.value = {
        condition: null,
        status: null,
        groupId: null,
        factoryId: '',
        keyWord: '',
    }
}

const getDataMachine = async () => {
    // if(bottom_select_groupId.value){
    //     machineModelSearch.value.groupId = bottom_select_groupId.value
    // }else{
    //     machineModelSearch.value.groupId=machineModelSearch.value.groupId
    // }

    let config: any = {
        params: {
            groupId: machineModelSearch.value.groupId,
            status: machineModelSearch.value.status,
            factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
            keyWord: machineModelSearch.value.keyWord,
            mode: [1],
        },
        headers: {
            requestId: uuidv4(),
        },
    };


    if (machineModelSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value === machineModelSearch.value.condition
        )?.realValue;
    }

    let result = await request("/api/machines", config);

    if (result && result.data) {
        machineDataList.value = result.data
        machineTypeSearch.value.total = result.data.count;
    } else {
        machineDataList.value = []
    }
}

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.stableCondition) {
        return conditionList.find(
            (p: any) => p.value === ev.workStatus.stableCondition
        );
    } else {
        return conditionList[0];
    }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
    if (ev.workStatus?.quotaWkStatus === true) {
        return statusList[0]
    } else if (ev.workStatus?.quotaWkStatus === false) {
        return statusList[1];
    } else if (ev.workStatus?.scoreWkStatus) {
        return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
    } else {
        return statusList[1];
    }
};

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? "even-row" : "odd-row";
};



// 设备报警列表弹窗
let warnVisible = ref(false)

const selectWarningStatus = (condition: any) => {

    // warnVisible.value = true
}
//底部选择工厂
const selectFac = (index: any) => {
    activeIndex2 = index;
    bottom_select_groupId.value = ''
    if (factoryList.value.length > 1) {
        for (let i = 0; i < factoryList.value.length; i++) {
            factoryList.value[i].selected = false
        }
        factoryListBottom.value = JSON.parse(JSON.stringify(factoryList.value))
        // factoryListBottom.value = splitArrayIntoSubarrays(factoryList.value, 3);
        factoryListBottom.value[index].selected = true;

        factory_name.value = factoryListBottom.value[index].factoryName;
        bottom_select_facId.value = factoryListBottom.value[index]._id;
        if (!factoryListBottom.value[index]?.parentId) {
            isFacType.value = 0//集团
        } else {
            isFacType.value = 1;//分厂
        }
    } else {

        for (let i = 0; i < groupListBottom.value.length; i++) {
            groupListBottom.value[i].selected = false;
        }
        factoryListBottom.value = JSON.parse(JSON.stringify(groupListBottom.value));
        factoryListBottom.value[index].selected = true;
        factory_name.value = factoryListBottom.value[index].factoryName || factoryListBottom.value[index].name;

        bottom_select_facId.value = factoryListBottom.value[index]._id;


        if (!factoryListBottom.value[index]?._id) {
            isFacType.value = 2 //此刻选择是区域
            bottom_select_groupId.value = factoryListBottom.value[index].sonID;
            factory_name.value = factoryListBottom.value[index].name;
        } else {
            isFacType.value = 1 //此刻选择是分厂;
            bottom_select_facId.value = factoryListBottom.value[index]._id
            factory_name.value = factoryListBottom.value[index].factoryName;
        }

    }

    if (bottom_select_groupId.value) {
        znMxWarnSearch.value.groupId = bottom_select_groupId.value;
        znZdWarnSearch.value.groupId = bottom_select_groupId.value;
        machineModelSearch.value.groupId = bottom_select_groupId.value

    } else {
        znMxWarnSearch.value.groupId = null;
        znZdWarnSearch.value.groupId = null;

    }

    if (!bottom_select_facId.value) {
        bottom_select_facId.value = factory_id;
    }
    conditionSelectFac.value = bottom_select_facId.value
    statusSelectFac.value = bottom_select_facId.value
    machineWarningFac.value = bottom_select_facId.value
    warningTrendFac.value = bottom_select_facId.value





    setTimeout(() => {
        factoryInfo()
        // getConditionChartData()
        // getStatusChartData()
        // getStateBarChartData()//状态排行
        // getFactoryWarningChartData()
        // getwarningBarChartData()//智能诊断报警统计
        // // getZnZdReport()
        // getZdWarnData()
        // getDataWeek()
        // getMxWarnData()
    }, 100);

}



</script>

<style lang="less" scoped>
.theme-bg {
    background-color: #000A31;
    color: #fff;
    overflow: hidden;
    height: calc(100vh - 100px);

}

.theme-bg-img {
    width: 100%;
    height: 100%;
    background: url("@/assets/screen/p_body_scren.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
}

.enW {
    width: 150px;
}

.center_box {
    height: 85%;
}

.pointer {
    cursor: pointer;
}

/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff
}

.btn1 {
    padding: 0.5% 1%;
    border-radius: 2px;
    border: 1px solid #072499;
    cursor: pointer;
    margin-bottom: 1%;
}

.title {
    width: 100%;
    // height: 48px;
    font-size: 1.2vw;
    // text-shadow: 0 4px 0 #0a285fb0;
    font-family: "YouSheBiaoTiHei";
    background: url('@/assets/screen/p_left_tit_bg.png') no-repeat;
    background-size: 100% 100%;
    font-weight: 700;
    font-style: italic;

    // justify-content: space-between;
    .p-text-right {
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;
        padding-right: 1%;
    }

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}

.select_style {
    font-style: normal;
    font-family: "PingFang SC";
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 500;
    line-height: 0.8vw;
    padding-right: 1%;
}

.title2 {
    // padding: 10px 100px;
    // font-size: 40px;
    // color: #06DEF5;
    width: 16vw;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgba(0, 136, 255, 0) 3.79%, rgba(0, 136, 255, 0.8) 29.93%, rgba(0, 136, 255, 0.8) 68.93%, rgba(0, 136, 255, 0) 95.63%);
    margin-top: 8%;
    padding: 1% 0;

    .center_text {
        justify-content: center;
        background: linear-gradient(180deg, #FEFEFE 42.19%, #9ABCFF 76.56%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        align-items: center;
        margin-left: 10%;
        font-weight: 700;

        div:nth-of-type(1) {
            font-size: 2vw;
            line-height: 2vw;
        }

        div:nth-of-type(2) {
            font-size: 1.2vw;
            line-height: 1.2vw;
            padding-top: 4%;
            padding-right: 4%;
        }

        img {
            display: block;
            // width: 10%;
        }
    }

    .center_text_b {
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 600;
        line-height: 0.8vw;
        background: linear-gradient(180deg, #FEFEFE 42.19%, #9ABCFF 76.56%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        justify-content: center;

        .center_wh {
            font-size: 0.8vw;
            color: rgba(154, 188, 255, 1);

        }
    }
}

.box {
    // width:100%!important; 
    // border: 1px solid #0d53b7cc;
    // background: #07249933;
    padding: 20px 10px;
    background: url('@/assets/screen/m_fac_bg.png') no-repeat;
    background-size: 100% 100%;
}

.box::-webkit-scrollbar {
    width: 5px; //修改滚动条宽度
}

.box1 {
    width: 230px;
    height: 80px;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        color: #06DEF5;
    }
}

.box-size {
    width: 21.7%;
    min-width: 400px;
    // .state_wrap{
    //     height: 26vh;
    //     overflow: hidden;
    // }
}

.p-tit {
    padding-bottom: 1%;
    width: 100%;
    min-height: 7.6vh;
    // height: 9.2vh;
    background: url("@/assets/screen/p_titc_bg2.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-family: "Microsoft YaHei";
    font-style: normal;
    font-weight: 700;
    // line-height: 40px;
    font-size: 1.7vw;
    // font-size: 36.7px;
}

.p-tit-bottom {
    position: fixed;
    width: 100%;
    height: 6.8vh;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: url("@/assets/screen/p_tit_bg.png") no-repeat;
    background-size: 100% 100%;

}

.bg_wrap {
    width: 100%;
    height: 21vh;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 2%;

    /* For demo */
    .ant-carousel :deep(.slick-slide) {
        text-align: center;
        height: 16vh;
        // line-height: 160px;
        // background: #364d79;
        overflow: hidden;
    }

    .ant-carousel :deep(.slick-slide h3) {
        color: #fff;
    }

}


.moitor_clip {
    width: 33%;
    text-align: center;
    margin-top: 5%;
    padding-bottom: 5%;

    img {
        display: block;
        width: 65%;
        margin: 0 auto;
        // height: 105px;
    }

    div:nth-of-type(1) {
        color: #4efaee;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.8vw;

        &.off_text {
            color: #a7a7a7;
        }

        &.monitor_text {
            color: #71a4ff;
        }
    }

    div:nth-of-type(2) {
        padding-bottom: 5%;
        margin-top: 5%;
        color: #ffffff;
        font-family: "YouSheBiaoTiHei";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5vw;

        span {
            // color: #ffffff;
            font-family: "YouSheBiaoTiHei";
            font-size: 1.2vw;
            font-weight: 700;
        }
    }

}

.cgq_wrap {
    width: 100%;
    margin-bottom: 2%;

    // border: 1.02px solid rgba(185, 232, 255, 0.1);
    .cgq_clip {
        width: 33%;
        cursor: pointer;
        font-size: 0.6vw;
        color: #b9e8ff;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        line-height: 0.6vw;
        text-align: center;

        // padding: 1% 0;
        &.img_clip_wrap {
            width: 19%;
            height: 9vh;
            background: rgba(185, 232, 255, 0.1);
            box-sizing: border-box;
            padding-top: 5%;
        }

        img {
            display: block;
            height: 35%;
            margin: 0 auto;

        }

        .text {
            color: rgb(255, 255, 255);
            font-family: "YouSheBiaoTiHei";
            font-size: 0.9vw;

            font-weight: 700;
            line-height: 0.9vw;
            margin-bottom: 3%;

            &.total_text {
                color: rgb(255, 255, 255);
            }

            &.low_text {
                color: rgb(209, 78, 79);
            }

            &.normal_text {
                color: rgba(58, 171, 133, 1);
            }
        }

    }

}

//左侧部分样式
.tl_state_wrap {
    font-size: 0.6vw;
}

.rate_wrap {
    color: #ffffff99;
    font-family: "PingFang SC";
    font-size: 0.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 0.6vw;
    padding-left: 20px;
}

.pie_bg_wrap {
    position: absolute;
    width: 85%;
    height: 8vh;
    background: url("@/assets/screen/p_pie_bg.png") no-repeat;
    background-size: 100% 100%;
    top: 55%;
    left: -10%;
    z-index: 0;
}

.pie_tex_wrap {
    position: absolute;
    width: 55%;
    text-align: center;
    left: 5%;
    top: 35%;

    // z-index:3;
    div:nth-of-type(1) {
        font-family: "YouSheBiaoTiHei";
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 700;
        color: #fff;
        line-height: 1.2vw;

        span {
            font-size: 0.7vw;
            line-height: 0.7vw;
        }
    }

    div:nth-of-type(2) {
        color: #f6f6f6;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 400;
        line-height: 0.6vw;
        margin-top: 2%;
    }

}

//中间部分样式
.center_center_box {
    position: relative;
}

.ab_wrap {
    width: 13.8vw;
    height: 16vh;
    min-height: 243px;
    flex-shrink: 0;
    position: absolute;
    z-index: 1000;

    // background: red;
    &.left_top {
        left: 3%;
        top: 8%;

        img {
            display: block;
            // position: absolute;
        }

        .top_bg {
            width: 80%;
            background: url("@/assets/screen/p_warn_tit_bg.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 20%;
            padding: 3% 5%;
            justify-content: space-between;
            align-items: center;

            &.top_bg2 {
                background: url("@/assets/screen/p_warn_tit_bg2.png") no-repeat;
            }

            .text {
                color: rgb(255, 255, 255);
                font-family: "PingFang SC";
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 500;
                line-height: 0.8vw;
            }

            .text_two {
                color: rgb(6, 222, 245);
                font-family: "YouSheBiaoTiHei";
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: 0.9vw;
                align-items: end;

                div:nth-of-type(2) {
                    font-size: 0.6vw;
                    line-height: 0.6vw;
                    margin-top: -2%;
                }
            }
        }

        .center_bg {
            // height:5.9vh;
            width: 12%;
            margin-top: -3%;
            margin-left: 35%;

        }

        .bottom_bg {
            width: 53%;
            margin-left: 10%;
            margin-top: 0%;
        }
    }

    &.left_bottom {
        left: 3%;
        // bottom:15%;
        top: 58%;

        img {
            display: block;
            // position: absolute;
        }

        .top_bg {

            width: 80%;
            background: url("@/assets/screen/p_warn_tit_bg.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 20%;
            padding: 3% 5%;
            justify-content: space-between;
            align-items: center;

            .text {
                color: rgb(255, 255, 255);
                font-family: "PingFang SC";
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 500;
                line-height: 0.8vw;
            }

            .text_two {
                color: rgb(6, 222, 245);
                font-family: "YouSheBiaoTiHei";
                font-size: 1vw;
                font-style: normal;
                font-weight: 700;
                line-height: 1vw;
                align-items: end;
                z-index: 99;

                div:nth-of-type(2) {
                    font-size: 0.8vw;
                    line-height: 0.8vw;
                    margin-top: -2%;
                }
            }
        }

        .center_bg {
            // height:5.9vh;
            width: 12%;
            margin-top: -3%;
            margin-left: 35%;

        }

        .bottom_bg {
            width: 53%;
            margin-left: 10%;
            margin-top: 0%;
        }
    }

    &.right_top {
        right: 3%;
        top: 8%;

        img {
            display: block;
            // position: absolute;
        }

        .top_bg {
            width: 80%;
            background: url("@/assets/screen/p_warn_tit_bg.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 20%;
            padding: 3% 5%;
            justify-content: space-between;
            align-items: center;

            .text {
                color: rgb(255, 255, 255);
                font-family: "PingFang SC";
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 500;
                line-height: 0.8vw;
            }

            .text_two {
                color: rgb(6, 222, 245);
                font-family: "YouSheBiaoTiHei";
                font-size: 1vw;
                font-style: normal;
                font-weight: 700;
                line-height: 1vw;
                align-items: end;

                div:nth-of-type(2) {
                    font-size: 0.8vw;
                    line-height: 0.8vw;
                    margin-top: -2%;
                }
            }
        }

        .center_bg {
            // height:5.9vh;
            width: 12%;
            margin-top: -3%;
            margin-left: 58%;

        }

        .bottom_bg {
            width: 53%;
            margin-left: 43%;
            margin-top: -5%;
        }
    }

    &.right_bottom {
        right: 3%;
        // bottom:15%;
        top: 58%;

        img {
            display: block;
            // position: absolute;
        }

        .top_bg {
            width: 85%;
            background: url("@/assets/screen/p_warn_tit_bg.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 20%;
            padding: 3% 3%;
            justify-content: space-between;
            align-items: center;

            .text {
                color: rgb(255, 255, 255);
                font-family: "PingFang SC";
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 500;
                line-height: 0.8vw;
                z-index: 99;
            }

            .text_two {
                color: rgb(6, 222, 245);
                font-family: "YouSheBiaoTiHei";
                font-size: 1vw;
                font-style: normal;
                font-weight: 700;
                line-height: 1vw;
                align-items: end;

                div:nth-of-type(2) {
                    font-size: 0.8vw;
                    line-height: 0.8vw;
                    margin-top: -2%;
                }
            }
        }

        .center_bg {
            // height:5.9vh;
            width: 12%;
            margin-top: -3%;
            margin-left: 58%;

        }

        .bottom_bg {
            width: 53%;
            margin-left: 43%;
            margin-top: -5%;
        }
    }




}

//右侧样式
.tl_wrap {
    justify-content: right;
    padding-bottom: 3%;
}

.tl_text {
    font-size: 0.6vw;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    div:nth-of-type(1) {
        width: 0.4vw;
        height: 0.2vw;
    }
}

.machine-type-swiper {
    width: 100%;
    height: 19vh;
}

.bubble-chart {
    position: relative;
    width: 100%;
    height: 19vh;
    //   border:1px dashed #fff;
    //   margin-left:5%;
}

.bubble {
    position: absolute;
    border-radius: 50%;
    //   background-color: blue;
    background: linear-gradient(180deg, #DDD246 0%, #ddd24600 100%);
    opacity: 0;
    text-align: center;
    //   animation: floatUp 3s infinite;
}

.anUp {
    animation: floatUp 5s infinite ease;
    // animation-name: floatUp; 
    // animation-duration: 4s;   /* 动画持续时间为4秒 */
    // animation-timing-function: cubic-bezier(1, 1, 0.8, 0.2);
    text-align: center;
    // background:rgba(45, 205, 255, 0.3);
    background: url("@/assets/screen/p_qp_blue.png") no-repeat;
    background-size: 100% 100%;

    div:nth-of-type(1) {
        color: #2dcdff;
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.8vw;
        margin-top: 33%;
    }

    div:nth-of-type(2) {
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3vw;

        span {
            font-size: 1vw;
        }
    }
}

.anUp2 {
    animation: floatUp 5s infinite;
    background: url("@/assets/screen/p_qp_blue.png") no-repeat;
    background-size: 100% 100%;

    div:nth-of-type(1) {
        color: #fb5d5d;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.7vw;
        margin-top: 25%;
    }

    div:nth-of-type(2) {
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;

        span {
            font-size: 0.6vw;
        }
    }
}

.anDown {
    animation: floatDown 5s infinite;
    background: url("@/assets/screen/p_qp_yellow.png") no-repeat;
    background-size: 100% 100%;

    div:nth-of-type(1) {
        color: #F8B360;
        font-family: "PingFang SC";
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.7vw;
        margin-top: 33%;
    }

    div:nth-of-type(2) {
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1vw;

        span {
            font-size: 0.8vw;
        }
    }
}

.anLeft {
    animation: floatLeft 5s infinite;
    background: url("@/assets/screen/p_qp_green.png") no-repeat;
    background-size: 100% 100%;

    div:nth-of-type(1) {
        color: #28f6a6;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.7vw;
        margin-top: 25%;
    }

    div:nth-of-type(2) {
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;

        span {
            font-size: 0.6vw;
        }
    }
}

.anRight {
    animation: floatRight 5s infinite;
    background: url("@/assets/screen/p_qp_or.png") no-repeat;
    background-size: 100% 100%;

    div:nth-of-type(1) {
        color: #fb5d5d;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.7vw;
        margin-top: 25%;
    }

    div:nth-of-type(2) {
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;

        span {
            font-size: 0.6vw;
        }
    }
}



@keyframes floatUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(-2vw);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes floatUp2 {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(-1vw);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes floatDown {
    0% {
        transform: translateY(-2.5vw);
        opacity: 1;
    }

    50% {
        transform: translateY(-1vw);
        opacity: 1;
    }

    100% {
        transform: translateY(-2.5vw);
        opacity: 1;
    }
}

@keyframes floatLeft {
    0% {
        transform: translateX(1.5vw);
        opacity: 1;
    }

    50% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(1.5vw);
        opacity: 1;
    }
}

@keyframes floatRight {
    0% {
        transform: translateX(1vw);
        opacity: 1;
    }

    50% {
        transform: translateX(2.5vw);
        opacity: 1;
    }

    100% {
        transform: translateX(1vw);
        opacity: 1;
    }
}




// 走马灯
.change_wrap {
    display: flex !important;
    justify-content: center;

    .fac_wrap {
        width: 33.3%;
        //     img{
        //         display:block;
        //         width:20%;
        //         margin:0 auto;
        //         transform:scale(0.9)
        //     } 
        //     &.selected{
        //         img{
        //             transform:scale(1.2)
        //         }
        //    }
    }

    // margin-top:-3%
}

.fac_wrap {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    img {
        display: block;
        width: 29%;
        margin: 0 auto;
        transform: scale(0.9)
    }

    div.fac_text {
        display: block;
        color: #fff;
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 7px;

    }

    &.selected {
        img {
            transform: scale(1.2)
        }

        // transform: translateX(300px); /* 应用平移效果 */
        // transition:transform 2s ease;
    }
}

.moved {
    transform: translateX(33.3%);
    /* 应用平移效果 */
}

:deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    //   background: #364d79;
    overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
    display: flex !important;
    align-items: center;
    width: 1vw;
    height: 25%;
    // padding:2% 0;
    // height: 51.02px;
    color: #9FD1FF;
    font-size: 1vw;
    font-weight: 700;
    background: #116ae64d;
    transition: ease all 0.3s;
    opacity: 0.5;
    z-index: 1;
}

:deep(.slick-arrow.custom-slick-arrow:before) {
    display: none;
}

:deep(.slick-arrow.custom-slick-arrow:hover) {
    color: #fff;
    opacity: 0.5;
}

:deep(.slick-slide h3) {
    color: #fff;
}

:deep(.swiper-button-prev, .swiper-button-next) {
    width: 1.3vw;
    height: 40%;
    min-height: 60px;
    background: #116ae64d;
}

:deep(.swiper-button-next) {
    width: 1.3vw;
    height: 40%;
    min-height: 60px;
    background: #116ae64d;
}

:deep(.swiper-pagination-bullet) {
    border-radius: 0;
    /* 方形 */
    width: 12px;
    /* 小圆点宽度 */
    height: 6px;
    /* 小圆点高度 */
    background-color: #ccc;
    /* 默认颜色 */
    opacity: 1;
    /* 不透明度 */
}

:deep(.swiper-pagination-bullet-active) {
    background-color: #007aff;
    /* 激活状态的颜色 */
}

:deep(.swiper-pagination) {
    position: absolute;
    bottom: -4%;
    /* 距离底部的位置 */
    left: 50%;
    /* 水平居中 */
    transform: translateX(-50%);
}

.machine-type {
    padding: 10px;
    cursor: pointer;

    &.machine-type-selected {
        background: #2D55F5
    }
}

// /deep/ .swiper-wrapper{
//     display:flex;
//     align-items: center; /* 垂直居中 */
//     justify-content:center;
// }</style>