import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bule_list_page theme-common" }
const _hoisted_2 = { class: "main" }

import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "@/common/lang";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";

import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

import { operateLogs } from "@/common/logs";
import { getLanguage } from "@/common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'Role',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang();
const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
operateLogs('访问角色管理');
/**操作日志记录 */
const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
  factoryId: '',
  total: 0,
});

const modalState = reactive({
  visible: false,
  title: langObj.value["新增"],
  confirmLoading: false,
});

let modalForm = reactive({
  name: "",
  menuIds: [],
  machineIds: [],
  factoryIds: [],
  groupIds: [],
  id: 0,
  factoryId: '',
  powerIds: [],
});

let total: any = ref(0)
let pagination = computed(() => ({
  total: formState.total,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
const columns = [
  {
    title: langObj.value["工厂"],
    dataIndex: "factoryName",
    width: "15%",
    align: "center",
  },
  {
    title: langObj.value["角色名"],
    dataIndex: "name",
    width: "15%",
    align: "center",
  },
  {
    title: langObj.value["菜单"],
    dataIndex: "menuIds",
    width: "55%",
    align: "center",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "id",
    align: "center",
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  modalForm = reactive({
    name: "",
    menuIds: [],
    machineIds: [],
    factoryIds: [],
    groupIds: [],
    id: 0,
    factoryId: '',
    powerIds: [],
  });
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);

};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/roles/info", config).then((res) => {
    if (res) {
      operateLogs('删除角色，角色名称：' + val.name + ",id:" + val.id);
      message.success("操作成功");
      search();
    } else {
    }
  });
};
const formStateRef = ref<any>();

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  modalForm = reactive(JSON.parse(JSON.stringify(val)));

  modalForm.menuIds = modalForm.menuIds ?? [];
  let index = modalForm.menuIds?.length ? modalForm.menuIds.length - 1 : -1

  for (let i = index; i >= 0; i--) {
    const element = modalForm.menuIds[i];
    let parentInfo = menuList.value.find((p: any) => p.id === element)
    if (parentInfo) {
      modalForm.menuIds.splice(i, 1)
    }
  }

  modalForm.powerIds = val.groupIds
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};
const checkedNodes = ref<any>([])
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes
};
const filterOption = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
const filterOption2 = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let form = JSON.parse(JSON.stringify(modalForm))
      let groupsList: any = []
      allFactory.value.map((d: any) => {
        // groupsList = groupsList.concat(d.groups)
        if (d?.groups?.length > 0) {
          groupsList = groupsList.concat(d.groups.map((p: any) => ({
            name: p.name,
            sonID: p.sonID,
            factoryId: d._id,
            factoryParentId: d.parentId,
          })))
        }
      })

      let factoryIds: any = []
      let groupIds: any = []

      form.powerIds.map((d: any) => {
        let info = groupsList.find((p: any) => p.sonID === d)
        if (info) {
          groupIds.push(d)
          factoryIds.push(info.factoryId)
          if (info.factoryParentId) {
            factoryIds.push(info.factoryParentId)
          }
        }
      })

      factoryIds = [...new Set(factoryIds)]
      groupIds = [...new Set(groupIds)]

      form.factoryIds = factoryIds
      form.groupIds = groupIds

      let menuIds: any = []
      form.menuIds.map((d: any) => {
        menuIds.push(d)
        let p = menuListAll.value.find((p: any) => p.id === d)
        if (p?.parentId !== '0') {
          menuIds.push(p?.parentId)
        }
      })

      form.menuIds = [...new Set(menuIds)]

      if (form.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/roles/info", form, config).then((res) => {
          if (res) {
            operateLogs('修改角色，角色名称：' + form.roleName + ",id:" + form.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/roles", form, config).then((res) => {
          if (res) {
            operateLogs('添加角色，角色名称：' + form.roleName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current
  formState.take = pagination.pageSize
  search();
};
// 查询
const search = () => {
  const config = {
    params: {
      skip: formState.skip,
      take: formState.take,
      keyword: formState.keyWork,
      factoryId: formState.factoryId
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/roles", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.total = res.data.count
      // total.vlaue = res.data.count
    }
  });
};
search();
let menuList = ref<any>();
let menuListAll = ref<any>();
let menuListNotLevel = ref<any>([]);
const getMenu = () => {
  request.get("/api/menus").then((res: any) => {
    if (res) {
      menuListAll.value = res.data;
      res.data = res.data.map((t: any) => ({
        ...t,
        key: t.id,
      }));
      menuListNotLevel.value = res.data;
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      menuList.value = oneLevel;
    }
  });
};
getMenu();

//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();

  //新注册用户，未经过引导的需弹窗进行引导
  if (!getMemberOnboarding()) {
    highlightStep(getPopover(".addRoleDriver", "3/9 新建角色", "在“角色管理”中，点击新增可创建角色，角色可勾选需要的系统权限。该功能主要用于管理系统账号角色权限。", "新建角色", "right", "start", nextRoute, addRole));
  }
});

let factoryList = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups)
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: 'factory',
        children: t.groups.map((group: any) => ({
          type: 'group',
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryList.value = factory;
      // loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let config = {
    params: { factoryIds: factoryIds },
    headers: {
      requestId: requestId,
    },
  }
  let res = await request.get("/api/machines", config)

  factoryList.value.forEach((element: any) => {
    allMachine.value = res.data.map((t: any) => t.id)
    element.children.forEach((sonFact: any) => {
      if (sonFact.type == 'factory' && sonFact.children) {
        sonFact.children.forEach((groupFact: any) => {
          if (groupFact.type == 'group') {
            groupFact.children = res.data.filter((t: any) => t.groupId == groupFact.value).map((t: any) => ({
              type: 'machine',
              ...t,
              value: t.id,
              label: t.machineName,
              title: t.machineName,
              key: t.id,
            }));

          }
        });
      } else if (sonFact.type == 'group') {
        sonFact.children = res.data.filter((t: any) => t.groupId == sonFact.value).map((t: any) => ({
          type: 'machine',
          ...t,
          value: t.id,
          label: t.machineName,
          title: t.machineName,
          key: t.id,
        }));

      }
    });

  });
};


const getMenuNames = (id:any) => {
  let menu = menuListNotLevel.value.find((p:any) => p.key === id)?.title
  return langObj.value[menu] || getLanguage(menu)
}


//新用户引导新建角色按钮回调
let editRindex = ref("auto");
let dropdownClassName = ref("");
const addRole = () => {
  editRindex = 1000000001;
  dropdownClassName = "dropdownStyleDriver";
  add();
};
//新用户引导新建角色下一步按钮回调
const nextRoute = () => {
  router.push("/system/member");
  return;
};

return (_ctx: any,_cache: any) => {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['工厂']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: formState.factoryId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.factoryId) = $event)),
                      style: {"min-width":"200px"},
                      options: _unref(factoryList),
                      placeholder: _unref(langObj)['选择工厂'],
                      "filter-option": filterOption,
                      "show-search": "",
                      allowClear: ""
                    }, null, 8, ["value", "options", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['角色名']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: formState.keyWork,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.keyWork) = $event)),
                      placeholder: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: search
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['搜索']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: add,
                      class: "addRoleDriver"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_unref(langObj)['新增']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: _unref(langObj)['暂无数据'],
                columns: columns,
                "data-source": dataSource.value,
                pagination: _unref(pagination),
                loading: loading.value,
                onChange: handleTableChange,
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'menuIds')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(text, (item, index) => {
                        return (_openBlock(), _createElementBlock("span", { key: index }, [
                          _createVNode(_component_a_tag, { color: "#2db7f5" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(getMenuNames(item)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'id')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_a_popconfirm, {
                          title: _unref(langObj)['确定删除此条数据么'] + '?',
                          "ok-text": _unref(langObj)['确定'],
                          "cancel-text": _unref(langObj)['取消'],
                          onConfirm: ($event: any) => (deleteThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined),
                                _createTextVNode(" " + _toDisplayString(_unref(langObj)['删除']), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "plft",
                          onClick: ($event: any) => (editThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_edit_outlined),
                            _createTextVNode(" " + _toDisplayString(_unref(langObj)['编辑']), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["emptyText", "data-source", "pagination", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: modalState.visible,
          "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((modalState.visible) = $event)),
          title: modalState.title,
          cancelText: _unref(langObj)['关闭'],
          okText: _unref(langObj)['确定'],
          "confirm-loading": modalState.confirmLoading,
          onOk: handleOk,
          "z-index": _unref(editRindex),
          "onUpdate:zIndex": _cache[11] || (_cache[11] = ($event: any) => (_isRef(editRindex) ? (editRindex).value = $event : editRindex = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(modalForm),
              ref_key: "formStateRef",
              ref: formStateRef,
              name: "basic",
              autocomplete: "off",
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['工厂'],
                  name: "factoryId",
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (clearValidate('factoryId'))),
                  rules: [
            { required: true, message: _unref(langObj)['请选择工厂'], trigger: 'blur' },
          ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(modalForm).factoryId,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(modalForm).factoryId) = $event)),
                      style: {"width":"100%"},
                      options: _unref(factoryList),
                      placeholder: _unref(langObj)['选择工厂'],
                      "filter-option": filterOption2,
                      "show-search": "",
                      allowClear: "",
                      dropdownClassName: _unref(dropdownClassName)
                    }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['角色名称'],
                  name: "name",
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (clearValidate('name'))),
                  rules: [
            { required: true, message: _unref(langObj)['请输入角色名称'], trigger: 'blur' },
          ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).name,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(modalForm).name) = $event)),
                      placeholder: _unref(langObj)['输入角色名称']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单'],
                  name: "menuIds",
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (clearValidate('menuIds'))),
                  rules: [{ required: true, message: _unref(langObj)['请选择菜单'], trigger: 'blur' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tree, {
                      checkedKeys: _unref(modalForm).menuIds,
                      "onUpdate:checkedKeys": _cache[6] || (_cache[6] = ($event: any) => ((_unref(modalForm).menuIds) = $event)),
                      checkable: "",
                      "tree-data": _unref(menuList)
                    }, {
                      title: _withCtx(({ title, key }) => [
                        _createTextVNode(_toDisplayString(_unref(langObj)[title] || _unref(getLanguage)(title)), 1)
                      ]),
                      _: 1
                    }, 8, ["checkedKeys", "tree-data"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['工厂权限'],
                  name: "menuIds",
                  onChange: _cache[9] || (_cache[9] = ($event: any) => (clearValidate('menuIds'))),
                  rules: [{ required: true, message: _unref(langObj)['请选择工厂权限'], trigger: 'blur' }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tree, {
                      checkedKeys: _unref(modalForm).powerIds,
                      "onUpdate:checkedKeys": _cache[8] || (_cache[8] = ($event: any) => ((_unref(modalForm).powerIds) = $event)),
                      checkable: "",
                      "tree-data": _unref(factoryList),
                      onCheck: selectTree
                    }, {
                      title: _withCtx(({ title, key }) => [
                        _createTextVNode(_toDisplayString(title), 1)
                      ]),
                      _: 1
                    }, 8, ["checkedKeys", "tree-data"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title", "cancelText", "okText", "confirm-loading", "z-index"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})