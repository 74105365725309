import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/weixiu.png'
import _imports_2 from '@/assets/menxian.png'


const _hoisted_1 = {
  class: "w-full theme-common",
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000a32","overflow-y":"scroll","padding-bottom":"30px"}
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = { style: {"width":"12%","margin-top":"31px"} }
const _hoisted_4 = { class: "flex-1 flex ml-4 blueBgColorWhite whiteBgColorBlack" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "w-full flex items-center" }
const _hoisted_7 = { class: "size20" }
const _hoisted_8 = { style: {"transform":"translateY(-3px)"} }
const _hoisted_9 = { style: {"transform":"translateY(-3px)"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "w-full flex" }
const _hoisted_12 = { class: "flex-1 content content_heng2 whiteBg" }
const _hoisted_13 = { class: "w-full flex items-center" }
const _hoisted_14 = { class: "w-full flex items-center" }
const _hoisted_15 = { style: {"font-size":"22px"} }
const _hoisted_16 = { class: "w-full flex items-center" }
const _hoisted_17 = {
  key: 0,
  class: "opt8 w-full text-center size12"
}
const _hoisted_18 = { class: "w-full text-center" }
const _hoisted_19 = { class: "flex-1" }
const _hoisted_20 = {
  key: 0,
  class: "opt8 w-full text-center size12"
}
const _hoisted_21 = { class: "w-full text-center" }
const _hoisted_22 = {
  style: {"width":"325px"},
  class: "ml-4 content content_shu"
}
const _hoisted_23 = { class: "w-full flex items-center" }
const _hoisted_24 = { class: "size20" }
const _hoisted_25 = { class: "w-full" }
const _hoisted_26 = { class: "w-full flex items-center mt-2" }
const _hoisted_27 = { class: "flex-1" }
const _hoisted_28 = { class: "opt6" }
const _hoisted_29 = { class: "flex-1" }
const _hoisted_30 = { class: "opt6" }
const _hoisted_31 = { class: "w-full flex items-center mt-2" }
const _hoisted_32 = { class: "flex-1" }
const _hoisted_33 = { class: "opt6" }
const _hoisted_34 = { class: "flex-1" }
const _hoisted_35 = { class: "opt6" }
const _hoisted_36 = { class: "w-full flex items-center mt-2" }
const _hoisted_37 = { class: "flex-1" }
const _hoisted_38 = { class: "opt6" }
const _hoisted_39 = { class: "flex-1" }
const _hoisted_40 = { class: "opt6" }
const _hoisted_41 = { class: "w-full mt-2" }
const _hoisted_42 = { class: "opt6" }
const _hoisted_43 = { class: "content content_heng2 mt-4 whiteBg" }
const _hoisted_44 = { class: "w-full flex items-center flex-wrap" }
const _hoisted_45 = { class: "size20" }
const _hoisted_46 = {
  class: "flex items-center ml-4 cursor-pointer opt8",
  style: {"font-size":"14px"}
}
const _hoisted_47 = { class: "flex items-center" }
const _hoisted_48 = { class: "ml-1" }
const _hoisted_49 = { class: "flex ml-2" }
const _hoisted_50 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_51 = { class: "w-full" }
const _hoisted_52 = {
  key: 0,
  class: "mt-5 text-center size18",
  style: {"padding-right":"120px"}
}
const _hoisted_53 = {
  key: 1,
  class: "w-full flex px-16 mt-8 sjz"
}
const _hoisted_54 = {
  key: 0,
  style: {"position":"absolute","left":"-50%","bottom":"-17px","font-size":"10px","width":"53px"}
}
const _hoisted_55 = ["title"]
const _hoisted_56 = {
  key: 0,
  class: "imgShow",
  src: _imports_0
}
const _hoisted_57 = {
  key: 1,
  class: "imgShow",
  src: _imports_1
}
const _hoisted_58 = {
  key: 2,
  class: "imgShow",
  src: _imports_2
}
const _hoisted_59 = { class: "mt-16 size18 flex items-center px-12" }
const _hoisted_60 = { class: "text-center flex-1" }
const _hoisted_61 = { style: {"font-size":"14px"} }
const _hoisted_62 = {
  id: "chartCover",
  style: {"height":"340px"},
  class: "w-full"
}
const _hoisted_63 = { class: "mt-5 text-center size18 flex items-center px-12" }
const _hoisted_64 = { class: "text-center flex-1" }
const _hoisted_65 = { style: {"font-size":"14px"} }
const _hoisted_66 = { class: "content mt-4 content_heng whiteBg" }
const _hoisted_67 = { class: "w-full flex items-center" }
const _hoisted_68 = { class: "size20" }
const _hoisted_69 = {
  class: "flex items-center ml-4 cursor-pointer opt8",
  style: {"font-size":"14px"}
}
const _hoisted_70 = { class: "flex items-center" }
const _hoisted_71 = { class: "ml-1" }
const _hoisted_72 = { class: "flex ml-2" }
const _hoisted_73 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_74 = { class: "mt-5 text-center size18" }
const _hoisted_75 = { class: "w-full flex" }
const _hoisted_76 = { style: {"width":"320px"} }
const _hoisted_77 = { class: "w-full flex items-center" }
const _hoisted_78 = {
  class: "content w-full",
  style: {"padding":"10px 0px","background-color":"#020f46"}
}
const _hoisted_79 = { class: "pl-2" }
const _hoisted_80 = {
  class: "w-full flex items-center mt-3 pl-2",
  style: {"opacity":"0.6"}
}
const _hoisted_81 = { style: {"width":"40%","text-align":"center"} }
const _hoisted_82 = { style: {"width":"20%","text-align":"center"} }
const _hoisted_83 = { style: {"width":"40%","text-align":"center"} }
const _hoisted_84 = { key: 0 }
const _hoisted_85 = { style: {"width":"40%","text-align":"center"} }
const _hoisted_86 = { style: {"width":"20%","text-align":"center"} }
const _hoisted_87 = { style: {"width":"40%","text-align":"center"} }
const _hoisted_88 = { class: "flex-1" }
const _hoisted_89 = ["id"]
const _hoisted_90 = {
  key: 0,
  class: "content mt-4 content_heng whiteBg"
}
const _hoisted_91 = { class: "w-full flex items-center" }
const _hoisted_92 = { class: "size20" }
const _hoisted_93 = {
  class: "flex items-center ml-4 cursor-pointer opt8",
  style: {"font-size":"14px"}
}
const _hoisted_94 = { class: "flex items-center" }
const _hoisted_95 = { class: "flex ml-2" }
const _hoisted_96 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_97 = { class: "w-full" }
const _hoisted_98 = {
  style: {"width":"310px"},
  class: "ml-4 theme-bg whiteBg"
}
const _hoisted_99 = {
  class: "w-full text-center",
  style: {"height":"23px"}
}

import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate,
  transformDate2,
  getGroupName,
  inIframe
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import OrderListComponent from "./orderList.vue";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import pictureListComponent from "../components/picture-list.vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'overview',
  setup(__props) {

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

let picList: any = ref([]);
let value = ref("");
let value1 = ref(false);
let machine_id = ref("");
let machineInfo: any = ref({});
let statusInfo: any = ref({});
let sensorList: any = ref([]);
let sensorListAll: any = ref([]);
let changeCondition = ref(true); //true 稳定   false 实时
let selectSensorRandar = ref("");
let orderC = ref();

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f" },
  { label: "可用", value: [1, 2], color: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37" },
];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "停机", value: false, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
  { label: "运行", value: true, color: "#4EFAEE" },
];

let scrollTop = ref("120px");
let isShowHealthRandar: any = ref(true)
let isShowHealthHistrend: any = ref(true)
let iframe = ref(false);
iframe.value = inIframe();
if (iframe.value) {
  scrollTop.value = '10px'
}

let isShowTimeLine: any = ref(false)
onMounted(() => {
  if (JSON.parse(localStorage.getItem("userName") || '"admin"') == 'freqx_yszh') {
    isShowTimeLine.value = false
  } else {
    isShowTimeLine.value = true
  }

})

const selectMachine = (ev: any) => {
  orderC.value && orderC.value.closeAll();
  histrendSearchModel.value.isShow = true;
  sensorSearchModel.value.isShow = true;
  selectSensorRandar.value = "";
  histrendSearchModel.value.sensorId = "";
  machine_id.value = ev;
  setTimeout(() => {
    init();
  }, 200);
};

const init = () => {
  myChartC && myChartC.clear();
  myChartD && myChartD.clear();
  histrendSearchModel.value = {
    type: 1,
    date: null,
    sensorId: "",
    isShow: true,
  };
  sensorSearchModel.value = {
    date: null,
    type: "1",
    isShow: true,
  };
  statusSearchModel.value = {
    date: null,
    type: "1",
    isShow: true,
  };

  picList.value = [];
  getMachineInfo();
};

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
        machineInfo.value &&
        machineInfo.value.workStatus &&
        machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
          p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    if (
        machineInfo.value &&
        machineInfo.value.workStatus &&
        machineInfo.value.workStatus.scoreCondition
    ) {
      machineInfo.value.conditionC = conditionList.find((p: any) =>
          p.value.includes(machineInfo.value.workStatus.scoreCondition)
      );
    } else {
      machineInfo.value.conditionC = conditionList[0];
    }

    if (
        machineInfo.value &&
        machineInfo.value.workStatus) {
      machineInfo.value.status = statusList.find(
          (p: any) => p.value === (machineInfo.value.workStatus.quotaWkStatus ?? machineInfo.value.workStatus.scoreWkStatus ?? 0)
      );
    } else {
      machineInfo.value.status = statusList[0];
    }
  }
  if (machineInfo.value.pictures?.length > 0) {
    machineInfo.value.pictures.map((d: any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getFaultName();
  getMachineWKstatus();
};

// 获取测点列表\
let picListAll: any = ref([]);
const getSensorList = async () => {
  let senSearch = {
    params: { machineId: machine_id.value },
    headers: {
      requestId: uuidv4(),
    },
  };

  let quotaAlarmsRes = await request.get("/api/sensors/quota-alarm", { params: { machineId: machine_id.value } });
  let quotaAlarmList = quotaAlarmsRes?.data || [];
  let senList = await request.get("/api/sensors", senSearch);
  senList.data &&
  senList.data.map((d: any) => {
    d.quotaAlarms = quotaAlarmList.filter((p: any) => p.sensorId === d.id) || [];
    if (d.pictures) {
      d.pictures.map((p: any) => {
        picList.value.push({
          name: d.sensorPlace,
          url: p.url,
        });
      });
    }
  });

  picListAll.value = picList.value;
  sensorList.value = senList.data;
  sensorListAll.value = [
    { sensorPlace: langObj.value["全设备测点"], url: "", id: "" },
  ].concat(senList.data);
  getSensorTrendData();
};

// 获取指标数据
const getFaultName = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id.value },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result2 = await request.get("/api/machines/faultnames", faultnamesSearch);
  if (result2 && result2.data) {
    faultName = result2.data.faultNames;
    faultNameII = result2.data.faultNameExpects;
    machineInfo.value.vibrationLabel = {};
    machineInfo.value.healthLabel = {};
    result2.data.faultNames.map((d: any) => {
      machineInfo.value.vibrationLabel[d.column] =
          langObj.value[d.title] || d.title;
    });
    result2.data.faultNameExpects.map((d: any) => {
      machineInfo.value.healthLabel[d.column] =
          langObj.value[d.title] || d.title;
    });
  }
  selectWhichShow();
  getHistrendData();
};

//选择综合/当前状态
const conditionChange = (ev: any) => {
  changeCondition.value = ev;
  selectWhichShow();
};

// 选择测点
const selectRandarSensor = (ev: any) => {
  selectWhichShow();
};

// 判断展示稳定分还是实时分
const selectWhichShow = () => {
  let isShowStable = false;
  let isShowCurrent = false;
  let statusInfo = machineInfo.value;
  if (
      statusInfo.workStatus &&
      statusInfo.workStatus.stableScores &&
      Object.keys(statusInfo.workStatus.stableScores).length
  ) {
    isShowStable = true;
  }
  if (
      statusInfo.workStatus &&
      statusInfo.workStatus.scores &&
      Object.keys(statusInfo.workStatus.scores).length
  ) {
    isShowCurrent = true;
  }

  if (changeCondition.value) {
    if (!isShowStable && isShowCurrent) {
      changeCondition.value = false;
    }
  } else {
    if (isShowStable && !isShowCurrent) {
      changeCondition.value = true;
    }
  }

  getRandarData();
};

// 获取雷达图数据
const getRandarData = () => {
  let statusInfo = machineInfo.value;
  let healthLabel = statusInfo.healthLabel;
  let vibrationLabel = statusInfo.vibrationLabel;
  let scoreList: any = {};
  let Slist: any = [];
  let Tlist: any = [];
  if (changeCondition.value) {
    // 稳定
    if (selectSensorRandar.value) {
      // cedia
      let stableSensor = statusInfo.workStatus.stableSensors.find(
          (p: any) => p.id === selectSensorRandar.value
      );
      if (stableSensor) {
        scoreList = stableSensor;
      }
    } else {
      // quanbu
      if (statusInfo.workStatus && statusInfo.workStatus.stableScores) {
        scoreList = statusInfo.workStatus.stableScores;
      }
    }
  } else {
    // 实时
    if (selectSensorRandar.value) {
      // cefdian
      let stableSensor = statusInfo.workStatus.scoreSensors.find(
          (p: any) => p.id === selectSensorRandar.value
      );
      if (stableSensor) {
        scoreList = stableSensor;
      }
    } else {
      // quanbu
      if (statusInfo.workStatus && statusInfo.workStatus.scores) {
        scoreList = statusInfo.workStatus.scores;
      }
    }
  }

  // 处理数据
  for (let index in scoreList) {
    const item = scoreList[index];
    if (index.includes("S")) {
      Slist.push({
        quota: index,
        score: item,
        text: vibrationLabel[index] || "",
      });
    }

    if (index.includes("T")) {
      Tlist.push({
        quota: index,
        score: item,
        text: healthLabel[index] || "",
      });
    }
  }

  if (Tlist?.length > 0) {
    isShowHealthRandar.value = true
  } else {
    isShowHealthRandar.value = false
  }

  initChart(Tlist, "chartAover");
  initChart(Slist, "chartBover");
};

// 雷达图绘制
const initChart = (dataList: any, id: any) => {
  const ec = echarts as any;
  if (!document.getElementById(id)) return;
  let myChart = ec.init(document.getElementById(id));
  myChart.clear();

  if (!dataList.length) return;

  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = theme.value == 'white' ? '#333' : "#fff";
    }

    let name = "";
    if (language.value == "Chinese" && d.text) {
      if (d.text.length > 8) {
        name =
            d.text.substring(0, 4) +
            "\n" +
            d.text.substring(4, 8) +
            "\n" +
            d.text.substring(8, d.text.length);
      } else if (d.text.length > 4 && d.text.length <= 8) {
        name =
            d.text.substring(0, 4) + "\n" + d.text.substring(4, d.text.length);
      } else {
        name = d.text;
      }
    }

    if (language.value == "English" && d.text) {
      let list1 = d.text.split(" ");

      let str: any = "";
      let num = 0;
      let sy = "";
      list1.map((n: any, i: any) => {
        if (i == 0) {
          if (n.length <= 12) {
            num = n.length;
            str = n;
          } else {
            num = 12;
            let a1 = n.substring(0, 12);
            sy = n.slice(12);
            str = a1;
          }
        } else {
          if (sy) {
            str = str + "\n";
            let n2 = sy.length + n.length;
            if (n2 <= 12) {
              num = n2;
              str = str + sy + " " + n;
              sy = "";
            } else {
              num = 12;
              str = str + sy;
              let n2 = 12 - sy.length >= 0 ? 12 - sy.length : 0;

              let a1 = n.substring(0, n2);
              sy = n.slice(12);
              str = str + a1;
            }
          } else {
            if (num + n.length <= 12) {
              str = str + " " + n;
              num = num + n.length;
            } else {
              str = str + "\n" + n;
              num = n.length;
            }
          }
        }
      });
      name = str;
    }

    indicator.push({
      color,
      max: 100,
      text: name,
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: {},
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 90,
        startAngle: 90,
        splitNumber: 5,
        axisName: {
          formatter: "{value}",
          fontSize: 10,
        },
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 14,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: langObj.value["当前值"],
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};

//设备信息查看更多
const machineMore = () => {
  router.push("/equipment/list");
};

// 分数趋势图
// 五分钟自动刷新
let intervalTimer: any = null;
let fiveRefresh: any = ref(null);
let histrendSearchModel: any = ref({
  type: 1,
  date: null,
  sensorId: "",
  isShow: true,
});
let scoreExpertMarkLine: any = null;
let scoreMarkLine: any = null;
let faultName: any = [];
let faultNameII: any = [];
let myChartC: any = null;
let myChartD: any = null;
let isShowHealthRemark: any = ref(true);
let isShowVibrationRemark = ref(true);
let isHideHealth = ref(false);
let isHideVibration = ref(false);
let faultChartSelectlenged: any = {};
let healthChartSelectlenged: any = {};

const refresh = (ev: any) => {
  if (ev) {
    intervalTimer = setInterval(() => {
      getHistrendData();
    }, 1000 * 60 * 5);
  } else {
    clearInterval(intervalTimer);
  }
};

onBeforeUnmount(() => {
  clearInterval(intervalTimer);
});

// 获取趋势图
const getHistrendData = async () => {
  faultName.map((item: any) => {
    healthChartSelectlenged[langObj.value[item.title] || item.title] = true;
  });

  faultNameII.map((item: any) => {
    faultChartSelectlenged[langObj.value[item.title] || item.title] = true;
  });

  let beginDate: any = "";
  let endDate: any = "";

  let machine_date = machineInfo.value.workStatus?.scoreDate;

  if (histrendSearchModel.value.type == "0") {
    if (
        histrendSearchModel.value.date &&
        histrendSearchModel.value.date.length === 2
    ) {
      let start = histrendSearchModel.value.date[0];
      let end = histrendSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let time = new Date(machine_date);
    let date = time.getTime();
    if (histrendSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (histrendSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (histrendSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = machine_date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  let search = {
    params: {
      begin,
      end,
      sensorId: "",
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  getTimeLineData(begin, end);

  // 获取全设备数据(开关机数据)
  let scoreA = await request.get("/api/external/machines/score-full", search);
  let scoreAll = scoreA.data;

  let scoreExpertAll = scoreA.data;

  // 获取开关机线
  //健康
  if (scoreExpertAll.length > 0) {
    let markLine: any = {
      data: [],
      silent: true,
    };
    let wkStatus = 0;
    let x = "";
    scoreExpertAll.map((item: any, index: any) => {
      let time = new Date(item.date);
      let month =
          time.getMonth() + 1 > 9
              ? time.getMonth() + 1
              : "0" + (time.getMonth() + 1);
      let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
      let hours = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      let minutes =
          time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
      let seconds =
          time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
      x = month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
      if (index == 0) {
        wkStatus = item.wkStatus;
      } else {
        if (item.wkStatus != wkStatus) {
          if (item.wkStatus == 1) {
            markLine.data.push({
              name: "开",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "green" },
            });
          } else {
            markLine.data.push({
              name: "关",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
          wkStatus = item.wkStatus;
        }
      }
    });
    scoreExpertMarkLine = markLine;
  }

  //振动
  if (scoreAll.length > 0) {
    let markLine: any = {
      data: [],
      silent: true,
    };
    let wkStatus = 0;
    let x = "";
    scoreAll.map((item: any, index: any) => {
      let time = new Date(item.date);
      let month =
          time.getMonth() + 1 > 9
              ? time.getMonth() + 1
              : "0" + (time.getMonth() + 1);
      let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
      let hours = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      let minutes =
          time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
      let seconds =
          time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
      x = month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
      if (index == 0) {
        wkStatus = item.wkStatus;
      } else {
        if (item.wkStatus != wkStatus) {
          if (item.wkStatus == 1) {
            markLine.data.push({
              name: "开",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "green" },
            });
          } else {
            markLine.data.push({
              name: "关",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
          wkStatus = item.wkStatus;
        }
      }
    });
    scoreMarkLine = markLine;
  }

  if (histrendSearchModel.value.sensorId) {
    search.params.sensorId = histrendSearchModel.value.sensorId;
    let scoreA = await request.get("/api/external/machines/score-full", search);
    scoreAll = scoreA.data;
  }

  faultName.map((d: any) => {
    d.data = [];
    d.dateList = [];
  });
  faultNameII.map((d: any) => {
    d.data = [];
    d.dateList = [];
  });

  scoreAll.map((item: any) => {
    faultName.map((fault: any) => {
      if (item[fault?.column] != null) {
        fault.dateList.push(item.date);
        let date = item.date.slice(5, 19);
        fault.data.push([date, item[fault.column] || null]);
      } else {
      }
    });

    for (let fault of faultNameII) {
      if (item[fault?.column] != null) {
        fault.dateList.push(item.date);
        let date = item.date.slice(5, 19);
        fault.data.push([date, item[fault?.column] || null]);
      }
    }
  });

  scoreExpertAll.map((item: any) => {});

  faultNameData.value = {
    scoreAll,
    scoreExpertAll,
  };

  isShowHealthHistrend.value = false
  if (faultNameII?.length > 0) {
    isShowHealthHistrend.value = true
  } else {
    isShowHealthHistrend.value = false
  }

  setTimeout(() => {
    initChartC();
    initChartD();
  }, 200);
};

// 健康分数曲线图
// 健康
const initChartC = () => {
  let data = faultNameII;

  let maxLengthIndex = 0;
  let currentLength = 0;
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.data.length > currentLength) {
      currentLength = item.data.length;
      maxLengthIndex = i;
    }
  }

  let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];

  const ec = echarts as any;
  if (!document.getElementById("chartCover")) return;
  myChartC = ec.init(document.getElementById("chartCover"));
  myChartC.resize();

  let colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  data.map((item: any, index: any) => {
    if (item.data && item.data.length) {
      LengData.push(langObj.value[item.title] || item.title);
      if (isShowHealthRemark.value == true) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          data: item.data,
          smooth: false,
          symbolSize: 3,
          markLine: scoreExpertMarkLine,
          connectNulls: true,
        });
      } else if (isShowHealthRemark.value == false) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          yAxisIndex: 0,
          data: item.data,
          smooth: false,
          symbolSize: 3,
          connectNulls: true,
        });
      }
    }
  });

  if (!DataInfo || !DataInfo.length) {
    isHideHealth.value = true;
  }

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }
      const ymax = Math.round(max) * 5;

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: theme.value == 'white' ? '#333' : "#fff"
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: theme.value == 'white' ? '#333' : "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: theme.value == 'white' ? '#333' : "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",

              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: theme.value == 'white' ? '#333' : "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(faultChartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChartC && myChartC.clear();
    myChartC && myChartC.setOption(option, true);
  }

  myChartC
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlenged = params.selected;
        Init(faultChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChartC?.clear();
          myChartC?.setOption(option, true);
        }
      });
};

// 振动
const initChartD = () => {
  let data = faultName;

  let maxLengthIndex = 0;
  let currentLength = 0;
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.data.length > currentLength) {
      currentLength = item.data.length;
      maxLengthIndex = i;
    }
  }

  let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];

  const ec = echarts as any;
  if (!document.getElementById("chartDover")) return;
  myChartD = ec.init(document.getElementById("chartDover"));
  myChartD.resize();

  let min = 0,
      max = 100,
      colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  data.map((item: any, index: any) => {
    if (item.data && item.data.length) {
      LengData.push(langObj.value[item.title] || item.title);
      if (isShowVibrationRemark.value == true) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          data: item.data,
          smooth: false,
          symbolSize: 3,
          markLine: scoreMarkLine,
          connectNulls: true,
        });
      } else if (isShowVibrationRemark.value == false) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          yAxisIndex: 0,
          data: item.data,
          smooth: false,
          symbolSize: 3,
          connectNulls: true,
        });
      }
    }
  });

  if (!DataInfo || !DataInfo.length) {
    isHideVibration.value = true;
  }

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }
      const ymax = Math.round(max) * 5;

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: theme.value == 'white' ? '#333' : "#fff",
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: theme.value == 'white' ? '#333' : "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: theme.value == 'white' ? '#333' : "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: theme.value == 'white' ? '#333' : "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(healthChartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChartD && myChartD.clear();
    myChartD && myChartD.setOption(option, true);
  }

  myChartD
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlenged = params.selected;
        Init(healthChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChartD?.clear();
          myChartD?.setOption(option, true);
        }
      });
};

//数据下载
let faultNameData: any = ref({ scoreAll: [], scoreExpertAll: [] });
let downLoadList: any = ref([]);
let downLoadVisible = ref(false);
const downLoad1 = () => {
  downLoadList.value = [];
  let faultNameS = JSON.parse(JSON.stringify(faultName));
  let faultNameIIS = JSON.parse(JSON.stringify(faultNameII));
  let dataList = [...faultNameS, ...faultNameIIS];
  let scoreAll = JSON.parse(JSON.stringify(faultNameData.value.scoreAll));
  let scoreExpertAll = JSON.parse(
      JSON.stringify(faultNameData.value.scoreExpertAll)
  );
  let dateList: any = [];
  dataList &&
  dataList.map((d: any) => {
    dateList = dateList.concat(d.dateList);
  });

  dateList = [...new Set(dateList)].sort();
  let list: any = [];
  let nameList = dataList.map((p: any) => ({
    title: p.title,
    column: p.column,
  }));

  dateList.map((date: any, index: any) => {
    let score = scoreAll.find((p: any) => p.date === date);
    let scoreExpert = scoreExpertAll.find((p: any) => p.date === date);
    if (score) {
      delete score.date;
      delete score.wkStatus;
    }

    if (scoreExpert) {
      delete scoreExpert.date;
      delete scoreExpert.wkStatus;
    }

    for (let i in score) {
      let name = nameList.find((p: any) => p.column === i)?.title;
      list.push({
        date: date,
        machineName: machineInfo.value.machineName,
        title: name,
        score: score[i],
      });
    }

    for (let i in scoreExpert) {
      let name = nameList.find((p: any) => p.column === i)?.title;
      list.push({
        date: date,
        machineName: machineInfo.value.machineName,
        title: name,
        score: scoreExpert[i],
      });
    }
  });
  downLoadList.value = list;
  downLoadVisible.value = true;
};

const downLoadCancel = () => {
  downLoadVisible.value = false;
};

// 运行时间趋势
let statusSearchModel: any = ref({
  date: null,
  type: "1",
  isShow: true,
});

const getMachineWKstatus = async () => {
  let startDate = "";
  let endDate: any = "";
  if (statusSearchModel.value.type == "0") {
    if (
        statusSearchModel.value.date &&
        statusSearchModel.value.date.length === 2
    ) {
      startDate = transformDate(statusSearchModel.value.date[0]);
      endDate = transformDate(statusSearchModel.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (statusSearchModel.value.type == "2") {
      num = 30;
    }
    if (statusSearchModel.value.type == "3") {
      num = 60;
    }
    startDate = transformDate(
        new Date(
            new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num
        )
    );
  }

  // startSave.value = transformDate2(startDate, 1)
  // endSave.value = transformDate2(endDate, 2)

  let search = {
    params: {
      begin: transformDate2(startDate, 1),
      end: transformDate2(endDate, 2),
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/external/machines/onlines", search);
  let list: any = [];

  function compare() {
    return function (a: any, b: any) {
      var value1 = a["date"];
      var value2 = b["date"];
      return value1 > value2 ? 1 : -1;
    };
  }

  if (result && result.data) {
    list = result.data;
  }
  list.sort(compare());
  let xData = list.map((p: any) => p.date);
  let yData = list.map((p: any) =>
      Math.round(p.online / 3600) > 24 ? 24 : Math.round(p.online / 3600)
  );

  initStatusChart(xData, yData);
};

const initStatusChart = (xData: any, yData: any) => {
  if (!document.getElementById("chartStatus")) return;
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById("chartStatus"));
  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      name: "h",
      type: "value",
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart && myChart.setOption(option);
};

// 测点趋势
let sensorSearchModel: any = ref({
  date: null,
  type: "1",
  isShow: true,
});

let sensorLoading = ref(false);

let dataTypeList: any = [];
let sensorChartLength: any = [];
let startSave: any = ref("");
let endSave: any = ref("");

const getSensorTrendData = async () => {
  sensorLoading.value = true;
  let result = await request.get("/api/sensors/datatype");
  dataTypeList = result.data;
  // dataTypeList.map((d: any, i: any) => {

  //   d.name = langObj.value[d.name]
  // })

  let machine_date = transformDate(new Date());
  let startDate = "";
  let endDate = "";
  if (sensorSearchModel.value.type == "0") {
    if (
        sensorSearchModel.value.date &&
        sensorSearchModel.value.date.length === 2
    ) {
      startDate = transformDate(sensorSearchModel.value.date[0]);
      endDate = transformDate(sensorSearchModel.value.date[1]);
      if (endDate > machine_date) {
        endDate = machine_date;
      }
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = machine_date;
    let num = 1;
    if (sensorSearchModel.value.type == "2") {
      num = 7;
    }
    if (sensorSearchModel.value.type == "3") {
      num = 30;
    }
    startDate = transformDate(
        new Date(
            new Date(transformDate(machine_date)).getTime() -
            1000 * 60 * 60 * 24 * num
        )
    );
  }

  startSave.value = transformDate2(startDate, 1);
  endSave.value = transformDate2(endDate, 2);
  try {
    for (let index in sensorList.value) {
      let item = sensorList.value[index];
      if (item.dataType && item.dataType.length) {
        if (item.dataType.includes("高频加速度")) {
          item.dataType.map((d: any, i: any) => {
            if (d === "高频加速度") {
              item.dataType.unshift(item.dataType.splice(i, 1)[0]);
            }
          });
        }
      }
      await getSensorDataInfo(item, index, startSave.value, endSave.value);
    }
  } catch (error) {
    sensorLoading.value = false;
  }
  sensorLoading.value = false;
};

const getSensorDataInfo = async (ev: any, index: any, start: any, end: any) => {
  let senLength = sensorChartLength.find((p: any) => p.sensorId === ev.id);
  let typeList = [];

  if (senLength && senLength.length && Object.keys(senLength.length).length) {
    for (let i in senLength.length) {
      let d = senLength.length[i];
      if (d) {
        let type = ev.dataType.find((p: any) => i.includes(p));
        typeList.push(type);
      }
    }
  } else {
    ev.dataType &&
    ev.dataType.length &&
    ev.dataType.map((d: any, i: any) => {
      let datatype = dataTypeList.find((p: any) => p.name === d) || {
        unit: "",
      };

      let tt = ''
      // if(d === '高频加速度' || d === '温度') {
      //   tt = `${d}(${datatype.unit})`
      // }
      if (d === '低频加速度X') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'X')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度X(${datatype.unit})`
      } else if (d === '低频加速度Y') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Y')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度Y(${datatype.unit})`
      } else if (d === '低频加速度Z') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Z')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度Z(${datatype.unit})`
      } else if (d === '速度X') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'X')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度X(${datatype.unit})`
      } else if (d === '速度Y') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Y')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度Y(${datatype.unit})`
      } else if (d === '速度Z') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Z')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度Z(${datatype.unit})`
      } else {
        tt = `${d}(${datatype.unit})`
      }

      if (ev?.defaultDataType?.length > 0) {
        // typeList = []
      } else {
        if (i === 0) {
          typeList.push(d);
        }
      }

      let senLength = sensorChartLength.find(
          (p: any) => p.sensorId === ev.id
      );

      if (ev?.defaultDataType?.length > 0) {
        let dS = ev?.defaultDataType.find((p: any) => p === d)

        if (dS) {
          typeList.push(d)
        }
        if (senLength && senLength.length) {
          senLength.length[tt] = dS ? true : false;
        } else {
          sensorChartLength.push({
            sensorId: ev.id,
            length: { [tt]: dS ? true : false },
          });
        }


      } else {
        if (senLength && senLength.length) {
          senLength.length[tt] = i === 0 ? true : false;
        } else {
          sensorChartLength.push({
            sensorId: ev.id,
            length: { [tt]: i === 0 ? true : false },
          });
        }
      }
    });
  }

  // return
  let senLengthA = sensorChartLength.find((p: any) => p.sensorId === ev.id);
  if (!typeList.length) return;
  senLengthA.loadLength = typeList;

  let search = {
    sensorId: ev.id,
    type: typeList,
    begin: startSave.value,
    end: endSave.value,
  };

  let result = await request.post("/api/external/sensors/quota", search);
  sensorList.value[index].dataList = result.data;
  initSensorDataChart(ev, index);
};

const reloadSensorInfo = async (sensor: any, index: any, length: any) => {
  let senLength = sensorChartLength.find((p: any) => p.sensorId === sensor.id);
  let obj: any = {};
  if (language.value == "English") {
    for (let i in length) {
      let d = length[i];
      let list1 = i.split('-')
      if (list1.length == 1) {
        let list2 = list1[0].split('(')
        let tt = `${getLanguage(list2[0], language.value)}(${list2[1]}`
        obj[tt] = d;
      } else {
        let list2 = list1[1].split('(')
        let tt1 = `${getLanguage(list2[0], language.value)}(${list2[1]}`
        let tt = `${list1[0]}-${tt1}`
        obj[tt] = d;
      }
    }

    senLength.length = obj
    length = obj
  } else {
    senLength.length = length;
  }

  let typeList: any = [];
  for (let i in length) {
    let d = length[i];
    if (d) {
      let type = null
      if (i.includes('加速度')) {
        type = sensor.dataType.find((p: any) => p.includes('加速度') && i.includes(p));
      } else {
        type = sensor.dataType.find((p: any) => i.includes(p));
      }
      if (type) {
        typeList.push(type);
      }
    }
  }

  senLength.loadLength = typeList;

  let search = {
    sensorId: sensor.id,
    type: typeList,
    // type: ["高频速度", "高频加速度"],
    begin: startSave.value,
    end: endSave.value,
  };

  let result = await request.post("/api/external/sensors/quota", search);
  sensorList.value[index].dataList = result.data;


  initSensorDataChart(sensor, index);
};

let legendHoverTimeout: any = null;
let legendLeaveTimeout: any = null;
const initSensorDataChart = (sensor: any, index: any) => {
  let dataList = sensor.dataList;
  let selectLength = sensorChartLength.find((p: any) => p.sensorId === sensor.id)?.length || {};

  let obj: any = {};
  for (let i in selectLength) {
    let d = selectLength[i];
    let list1 = i.split('-')
    if (list1.length == 1) {
      let list2 = i.split('(')
      let tt = `${getLanguage(list2[0], language.value)}(${list2[1]}`
      obj[tt] = d;
    } else {
      let list2 = list1[1].split('(')
      let tt1 = `${getLanguage(list2[0], language.value)}(${list2[1]}`
      let tt = `${list1[0]}-${tt1}`
      obj[tt] = d;
    }
  }

  selectLength = obj;

  const ec = echarts as any;
  if (!document.getElementById("chartSensor" + index)) return;
  let myChart = ec.init(document.getElementById("chartSensor" + index));
  myChart.clear();

  let allDate: any = [];
  let xData: any = [];

  let option: any = null;

  let colors: any,
      LengData: any = [],
      YaData,
      SeriesData,
      DataInfo: any = [];
  colors = [
    "#5793f3",
    "orange",
    "#163AFF",
    "#00ff00",
    "#ff3333",
    "#00ffff",
    "green",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];
  dataList.map((item: any, i: any) => {
    item.dateSimple = item.date.map((d: any) => d.substring(5));
    allDate = allDate.concat(item.date);
    xData = xData.concat(item.dateSimple);
    item.dataShow = item.dateSimple.map((d: any, i: any) => [d, item.value[i]]);
  });
  LengData = Object.keys(selectLength);

  LengData.map((item: any, i: any) => {
    let data = dataList.find((p: any) => {
      let list1 = item.split('-')

      if (list1.length == 1) {
        let list2 = item.split('(')
        return list2[0] === getLanguage(p.type, language.value)
      } else {
        let list2 = list1[1].split('(')
        return list2[0] === getLanguage(p.type, language.value)
      }
    });

    DataInfo.push({
      name: item,
      type: "line",
      yAxisIndex: i,
      data: data ? data.dataShow : [],
      symbolSize: 2,
      smooth: false,
      connectNulls: true,
    });
  });

  allDate = [...new Set(allDate)].sort();
  xData = allDate.map((p: any) => p.substring(5));

  function Init(sel: { [x: string]: any }, dataInfo: any) {
    let selectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          name: LengData[n],
          splitLine: {
            show: false,
          },
          position: YaData.length % 2 == 0 ? "left" : "right",
          offset:
              YaData.length + 1 <= 2
                  ? 0
                  : (Math.ceil((YaData.length + 1) / 2) - 1) * 50,
          axisLine: {
            lineStyle: {
              color: colors[n],
            },
          },
          axisLabel: {
            formatter: function (value: any) {
              if (n < 2) {
                return value;
              } else {
                return value;
              }
            },
          },
          nameLocation: "center",
          nameTextStyle: {
            align: "left",
            padding: [25, 0, 25, -90]
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = YaData.length - 1 < 0 ? 0 : YaData.length - 1;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }

    let left = YaData.length > 1 ? Math.ceil(YaData.length / 2) * 60 : 60
    let right = YaData.length > 1 ? Math.floor(YaData.length / 2) * 60 : 60

    option = {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        top: "70px",
        // right: YaData.length ? `${(YaData.length / 2) * 8}%` : "5%",
        // left: YaData.length > 1 ? `${(YaData.length / 2) * 8}%` : "7%",
        right: right,
        left: left
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        y: "-3px",
        data: LengData,
        selected: selectedData,
        textStyle: {
          color: theme.value == 'white' ? '#333' : "#fff",
        },

        tooltip: {
          show: true,
          formatter: function (e: any) {
            // legend没有onHover事件，只能通过tooltip触发
            if (legendHoverTimeout) {
              clearTimeout(legendHoverTimeout) // 防抖
            }

            legendHoverTimeout = setTimeout(async () => {
              let sensorId = sensor.id;
              sensor.dataType = sensor.dataType || []

              let dataType = sensor.dataType.find((t: string) => {
                return e.name.includes(t);
              })

              if (!dataType) return;

              let quotaAlarm = sensor.quotaAlarms.find((t: any) => {
                return t.dataType === dataType && t.sensorId === sensorId && t.enable
              })

              if (!quotaAlarm) return;

              let level1Threshold = quotaAlarm ? quotaAlarm.level1Threshold : 0
              let level2Threshold = quotaAlarm ? quotaAlarm.level2Threshold : 0
              let level3Threshold = quotaAlarm ? quotaAlarm.level3Threshold : 0

              updateSplitLine(level1Threshold, level2Threshold, level3Threshold)

              // 让其他线变淡，达到当前线高亮的效果
              for (let i in selectedData) {
                if (i === e.name) { continue; }
                myChart.setOption({
                  series: {
                    name: i,
                    lineStyle: {
                      width: 0.3
                    }
                  }
                });
              }

            }, 300)
            return "";
          },
        }
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: theme.value == 'white' ? '#333' : "#fff",
            fontSize: 12,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: YaData,
      series: SeriesData,
    };
  }

  Init(selectLength, DataInfo);
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }

  myChart.off("legendselectchanged");
  myChart.on("legendselectchanged", function (params: { selected: any }) {
    // 查看是否需要请求数据
    let select1: any = [];
    for (let i in params.selected) {
      let d = params.selected[i];
      if (d) {
        let list1 = i.split('-')
        if (list1.length == 1) {
          let list2 = i.split('(')
          select1.push(list2[0]);
        } else {
          let list2 = list1[1].split('(')
          select1.push(list2[0]);
        }
      }
    }
    let select2 =
        sensorChartLength.find((p: any) => p.sensorId === sensor.id)
            ?.loadLength || [];
    let isReload = false;

    select1.map((d: any) => {
      // let a = select2.find((p: any) => d === p)
      let a = select2.find((p: any) => {d === getLanguage(p, language.value)});
      if (!a) {
        isReload = true;
      }
    });

    if (isReload) {
      reloadSensorInfo(sensor, index, params.selected);
    } else {
      selectLength = params.selected;
      Init(selectLength, DataInfo);
      if (option && typeof option === "object") {
        if (myChart) {
          myChart?.clear();
        }
        myChart.setOption(option, true);
      }
    }
  });

  // 获取表格数据
  let dateValue = allDate[allDate.length - 1];
  let list: any = [];

  if (machineInfo.value.workStatusRms) {
    let info = machineInfo.value.workStatusRms.find(
        (p: any) => p.sensorId === sensorList.value[index].id
    );

    LengData.map((d: any) => {
      let name = JSON.parse(JSON.stringify(d));
      let dataType = sensor.dataType.find((x: string) => d.includes(x));
      let n = name.replace(" (", "(").split("(")[0];
      if (n.includes('-')) {
        n = n.split("-")[1]
      }
      if (language.value == 'English') {
        n = langObj.value[n]
      }

      let val = { name: d, value: '--', thresholdText: "" }

      let rmsInfo = info && info.values && info.values.find((p: any) => p.type === n);
      if (rmsInfo?.value) {
        val.value = rmsInfo.value.toFixed(2);
      }

      if (sensor.quotaAlarms && dataType) {
        let alarm = sensor.quotaAlarms.find((p: any) => p.dataType === dataType && p.enable);
        let level1Threshold = alarm?.level1Threshold || "--";
        let level2Threshold = alarm?.level2Threshold || "--";
        let level3Threshold = alarm?.level3Threshold || "--";
        if (level1Threshold || level2Threshold || level3Threshold) {
          val.thresholdText = `${level1Threshold} / ${level2Threshold} / ${level3Threshold}`
        }
      }

      list.push(val);
    });
  }

  sensorList.value[index].sensorData = list;
  sensorList.value[index].sensorDate = dateValue;

  myChart.off("downplay");
  myChart.on("downplay", function (params: any) {
    if (params?.seriesName) {
      // 如果params包含seriesName, 表示鼠标移开了legend
      if (legendLeaveTimeout) {
        clearTimeout(legendLeaveTimeout) // 防抖
      }
      legendLeaveTimeout = setTimeout(async () => {
        updateSplitLine(0, 0, 0)
        for (let i in selectLength) {
          myChart.setOption({
            series: {
              name: i,
              lineStyle: {
                width: 1
              }
            }
          });
        }
      }, 300)
    }
  });


  function updateSplitLine(level1Threshold: number, level2Threshold: number, level3Threshold: number) {
    if (!level1Threshold && !level2Threshold && !level3Threshold) {
      myChart.setOption({
        graphic: [
          {
            id: "available",
            type: 'line',
            position: [0, 0],
            z: 100,
            style: {
              stroke: "red",
              lineWidth: 0,
              lineDash: "dashed"
            },
            draggable: false,
          },
          {
            id: "warning",
            type: 'line',
            position: [0, 0],
            z: 100,
            style: {
              stroke: "red",
              lineWidth: 0,
              lineDash: "dashed"
            },
            draggable: false,
          },
          {
            id: "fault",
            type: 'line',
            position: [0, 0],
            z: 100,
            style: {
              stroke: "red",
              lineWidth: 0,
              lineDash: "dashed"
            },
            draggable: false,
          },
        ]
      });
      return;
    }
    let width = myChart.getWidth();
    let offset = 60;
    let lineShape = {
      x1: offset,
      x2: width - offset,
      y1: 0,
      y2: 0
    };

    let availableThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ yAxisIndex: 0 }, level1Threshold));
    let warningThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ yAxisIndex: 0 }, level2Threshold));
    let faultThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ yAxisIndex: 0 }, level3Threshold));

    let gOptions = {
      graphic: [
        {
          id: "available",
          type: 'line',
          position: [0, availableThresholdSplitPixel],
          z: 100,
          shape: lineShape,
          style: {
            stroke: "yellow",
            lineWidth: 1,
            lineDash: "dashed"
          },
          cursor: 'move',
          draggable: false,
        },
        {
          id: "warning",
          type: 'line',
          position: [0, warningThresholdSplitPixel],
          z: 100,
          shape: lineShape,
          style: {
            stroke: "orange",
            lineWidth: 1,
            lineDash: "dashed"
          },
          draggable: false,
        },
        {
          id: "fault",
          type: 'line',
          position: [0, faultThresholdSplitPixel],
          z: 100,
          shape: lineShape,
          style: {
            stroke: "red",
            lineWidth: 1,
            lineDash: "dashed"
          },
          draggable: false,
        },
      ]
    };
    myChart.setOption(gOptions);
  }
};

// 获取时间轴数据
// getTimeLineData(begin, end)
let timeLineList: any = ref([]);
const getTimeLineData = async (begin: any, end: any) => {
  let search = {
    params: {
      begin,
      end,
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/workstatus/stables", search);
  let stableList: any = [];
  if (result && result.data) {
    stableList = result.data;
  }

  let warnSearch = {
    params: {
      begin,
      end,
      machineId: machine_id.value,
      skip: 1,
      take: 5000,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let warnResult = await request.get("/api/workflows", warnSearch);
  let orderList: any = [];
  if (warnResult && warnResult.data) {
    orderList = warnResult.data.list;
  }

  let conditionListA = [
    { value: 1, valueL: [0], color: "#61c08f" },
    { value: 2, valueL: [1, 2], color: "#d2de49" },
    { value: 3, valueL: [3, 4, 5], color: "#cd5f3b" },
    { value: 4, valueL: [6, 7], color: "#921e37" },
  ];

  let conditionA: any = 0;
  stableList.map((d: any, i: any) => {
    if (stableList[i + 1]) {
      let order = orderList.find(
          (p: any) => d.date <= p.date && stableList[i + 1].date > p.date
      );
      d.order = order;
    } else {
      let order = orderList.find((p: any) => d.date === p.date);
      d.order = order;
    }
    d.isShowDate = false;
    let color = conditionListA.find((p: any) =>
        p.valueL.includes(d.condition)
    )?.color;
    d.color = color;
    if (i == 0) {
      d.isShowDate = true;
      d.showDate = d.date.substring(5, 16);
      conditionA = conditionListA.find((p: any) =>
          p.valueL.includes(d.condition)
      )?.value;
    } else if (i == stableList.length - 1) {
      d.isShowDate = true;
      d.showDate = d.date.substring(5, 16);
    } else {
      let conditionB = conditionListA.find((p: any) =>
          p.valueL.includes(d.condition)
      )?.value;
      if (conditionB !== conditionA) {
        d.isShowDate = false;
        d.showDate = d.date.substring(5, 16);
      }
      conditionA = conditionB;
    }
  });
  timeLineList.value = stableList;
};

const getRowClassName = (index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "odd-row" : "even-row";
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const disabledDate2 = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 180;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 180;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

return (_ctx: any,_cache: any) => {
  const _component_poweroff_outlined = _resolveComponent("poweroff-outlined")!
  const _component_smile_outlined = _resolveComponent("smile-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: _normalizeStyle([{"width":"12%","position":"fixed","height":"calc(100vh - 130px)"}, { top: _unref(scrollTop) }])
        }, [
          _createVNode(machineListLeftComponent, {
            onSelectMachine: selectMachine,
            type: 1,
            mode: 1
          })
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(machineInfo)?.machineName), 1),
            _createVNode(_component_poweroff_outlined, {
              class: "ml-2",
              style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.status?.color }, {"border-radius":"50%","padding":"2px","font-size":"14px"}])
            }, null, 8, ["style"]),
            _createElementVNode("div", {
              style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.status?.color }, {"font-size":"12px","height":"13px","padding":"0 2px","transform":"translateX(-2.5px)","border-radius":"2px"}])
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.status?.label]), 1)
            ], 4),
            _createVNode(_component_smile_outlined, {
              class: "ml-2",
              style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.condition?.color }, {"border-radius":"50%","padding":"2px","font-size":"14px"}])
            }, null, 8, ["style"]),
            _createElementVNode("div", {
              style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.condition?.color }, {"font-size":"12px","height":"13px","padding":"0 2px","transform":"translateX(-2.5px)","border-radius":"2px"}])
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.condition?.label]), 1)
            ], 4),
            _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            (
                _unref(machineInfo) &&
                _unref(machineInfo).workStatus &&
                _unref(machineInfo).workStatus.onLine
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(langObj)["累计运行"]) + " " + _toDisplayString(_unref(machineInfo).workStatus.onLine) + "小时 ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (conditionChange(true))),
                  class: _normalizeClass(["btn1", { 'btn1-select': _unref(changeCondition) }])
                }, _toDisplayString(_unref(langObj)["综合状态"]), 3),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (conditionChange(false))),
                  class: _normalizeClass(["ml-4 btn1", { 'btn1-select': !_unref(changeCondition) }])
                }, _toDisplayString(_unref(langObj)["当前状态"]), 3),
                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_unref(langObj)["设备健康状态"]) + "：", 1),
                  _createElementVNode("span", {
                    style: _normalizeStyle({
                      color: _unref(changeCondition)
                        ? _unref(machineInfo)?.condition?.color
                        : _unref(machineInfo)?.conditionC?.color,
                    })
                  }, _toDisplayString(_unref(changeCondition)
                        ? _unref(langObj)[_unref(machineInfo)?.condition?.label]
                        : _unref(langObj)[_unref(machineInfo)?.conditionC?.label]), 5)
                ]),
                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(selectSensorRandar),
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_isRef(selectSensorRandar) ? (selectSensorRandar).value = $event : selectSensorRandar = $event)),
                  style: {"min-width":"150px"},
                  onChange: selectRandarSensor
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListAll), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[39] || (_cache[39] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _withDirectives(_createElementVNode("div", null, [
                  _cache[37] || (_cache[37] = _createElementVNode("div", {
                    id: "chartAover",
                    style: {"width":"342px","height":"322px"}
                  }, null, -1)),
                  (
                      _unref(machineInfo) &&
                      _unref(machineInfo).workStatus &&
                      _unref(machineInfo).workStatus.loseSensorExpert
                    )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_unref(langObj)["有测点数据未传回，因此本条分数数据仅供参考"]), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)["健康指标"]), 1)
                ], 512), [
                  [_vShow, _unref(isShowHealthRandar)]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_19, null, 512), [
                  [_vShow, _unref(isShowHealthRandar)]
                ]),
                _createElementVNode("div", null, [
                  _cache[38] || (_cache[38] = _createElementVNode("div", {
                    id: "chartBover",
                    style: {"width":"342px","height":"322px"}
                  }, null, -1)),
                  (
                      _unref(machineInfo) &&
                      _unref(machineInfo).workStatus &&
                      _unref(machineInfo).workStatus.loseSensor
                    )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_unref(langObj)["有测点数据未传回，因此本条分数数据仅供参考"]), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(langObj)["振动指标"]), 1)
                ]),
                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex-1" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)["设备基本信息"]), 1),
                _cache[41] || (_cache[41] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  onClick: machineMore,
                  class: "flex items-center cursor-pointer opt8"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_unref(langObj)["查看更多"]), 1),
                  _createVNode(_component_right_outlined, { class: "ml-2" })
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(pictureListComponent, {
                  type: 2,
                  picList: _unref(picListAll)
                }, null, 8, ["picList"])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(langObj)["设备名称"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineName), 1)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(langObj)["设备区域"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(getGroupName)(_unref(machineInfo).groupId)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)["设备位置"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).position), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(langObj)["设备型号"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).model), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(langObj)["设备厂家"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).supplier), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(langObj)["设备编号"]), 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineCode), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(langObj)["设备描述"]), 1),
                _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).remark), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(langObj)["智能诊断分数趋势"]), 1),
              _createElementVNode("div", _hoisted_46, [
                (_unref(histrendSearchModel).isShow)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(histrendSearchModel).isShow = false)),
                      class: "flex items-center"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)["收起"]), 1),
                      _createVNode(_component_caret_up_outlined, { class: "ml-1" })
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(histrendSearchModel).isShow)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(histrendSearchModel).isShow = true)),
                      class: "flex items-center"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)["展开"]), 1),
                      _createVNode(_component_caret_down_outlined, { class: "ml-1" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[42] || (_cache[42] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _withDirectives(_createElementVNode("div", _hoisted_47, [
                _createVNode(_component_a_switch, {
                  checked: _unref(fiveRefresh),
                  "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => (_isRef(fiveRefresh) ? (fiveRefresh).value = $event : fiveRefresh = $event)),
                  onChange: _cache[6] || (_cache[6] = ($event: any) => (refresh($event))),
                  "checked-children": _unref(langObj)['开启'],
                  "un-checked-children": _unref(langObj)['关闭']
                }, null, 8, ["checked", "checked-children", "un-checked-children"]),
                _createElementVNode("span", _hoisted_48, _toDisplayString(_unref(langObj)["自动刷新"]), 1),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(histrendSearchModel).type == '1' }]),
                    style: _normalizeStyle([{
                      background:
                        _unref(histrendSearchModel).type == '1' ? '#072498' : '',
                    }, {"border-radius":"5px 0 0 5px"}]),
                    onClick: _cache[7] || (_cache[7] = ($event: any) => ((_unref(histrendSearchModel).type = '1'), getHistrendData()))
                  }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(histrendSearchModel).type == '2' }]),
                    style: _normalizeStyle({
                      background:
                        _unref(histrendSearchModel).type == '2' ? '#072498' : '',
                    }),
                    onClick: _cache[8] || (_cache[8] = ($event: any) => ((_unref(histrendSearchModel).type = '2'), getHistrendData()))
                  }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(histrendSearchModel).type == '3' }]),
                    style: _normalizeStyle({
                      background:
                        _unref(histrendSearchModel).type == '3' ? '#072498' : '',
                    }),
                    onClick: _cache[9] || (_cache[9] = ($event: any) => ((_unref(histrendSearchModel).type = '3'), getHistrendData()))
                  }, _toDisplayString(_unref(langObj)["近两月"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(histrendSearchModel).type == '0' }]),
                    style: _normalizeStyle([{
                      background:
                        _unref(histrendSearchModel).type == '0' ? '#072498' : '',
                    }, {"border-radius":"0 5px 5px 0"}]),
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(histrendSearchModel).type = '0'))
                  }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                ]),
                (_unref(histrendSearchModel).type == '0')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                      _createVNode(_component_a_range_picker, {
                        value: _unref(histrendSearchModel).date,
                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(histrendSearchModel).date) = $event)),
                        "disabled-date": disabledDate2,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(histrendSearchModel).sensorId,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(histrendSearchModel).sensorId) = $event)),
                  style: {"min-width":"150px"},
                  onChange: getHistrendData
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListAll), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"]),
                _createElementVNode("div", {
                  onClick: getHistrendData,
                  class: "btn3 ml-2"
                }, _toDisplayString(_unref(langObj)["筛选"]), 1),
                _createElementVNode("div", {
                  class: "btn3 ml-2",
                  onClick: downLoad1
                }, _toDisplayString(_unref(langObj)["数据下载"]), 1)
              ], 512), [
                [_vShow, _unref(histrendSearchModel).isShow]
              ])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_51, [
              (_unref(timeLineList).length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_52, _toDisplayString(_unref(langObj)["设备健康事件时间轴"]), 1))
                : _createCommentVNode("", true),
              (_unref(timeLineList).length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(timeLineList), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex-1",
                        key: index,
                        style: _normalizeStyle([{ background: item.color }, {"height":"20px","position":"relative"}])
                      }, [
                        (item.isShowDate)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(item.showDate), 1))
                          : _createCommentVNode("", true),
                        (item.order)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              title: item.date,
                              style: {"position":"absolute","left":"-50%","bottom":"23px"}
                            }, [
                              (item.order.type === 1)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_56))
                                : _createCommentVNode("", true),
                              (item.order.type === 2 || item.order.type === 3)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_57))
                                : _createCommentVNode("", true),
                              (item.order.type === 4)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_58))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_55))
                          : _createCommentVNode("", true)
                      ], 4))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, _toDisplayString(_unref(langObj)["健康指标诊断分数趋势图"]), 1),
                _createVNode(_component_a_switch, {
                  checked: _unref(isShowHealthRemark),
                  "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => (_isRef(isShowHealthRemark) ? (isShowHealthRemark).value = $event : isShowHealthRemark = $event)),
                  onChange: _cache[14] || (_cache[14] = ($event: any) => (initChartC())),
                  "checked-children": _unref(langObj)['显示'],
                  "un-checked-children": _unref(langObj)['隐藏']
                }, null, 8, ["checked", "checked-children", "un-checked-children"]),
                _createElementVNode("div", _hoisted_61, _toDisplayString(_unref(langObj)["开关机线"]), 1)
              ], 512), [
                [_vShow, _unref(isShowHealthHistrend)]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_62, null, 512), [
                [_vShow, _unref(isShowHealthHistrend)]
              ]),
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(langObj)["振动指标诊断分数趋势图"]), 1),
                _createVNode(_component_a_switch, {
                  checked: _unref(isShowVibrationRemark),
                  "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => (_isRef(isShowVibrationRemark) ? (isShowVibrationRemark).value = $event : isShowVibrationRemark = $event)),
                  onChange: _cache[16] || (_cache[16] = ($event: any) => (initChartD())),
                  "checked-children": _unref(langObj)['显示'],
                  "un-checked-children": _unref(langObj)['隐藏']
                }, null, 8, ["checked", "checked-children", "un-checked-children"]),
                _createElementVNode("div", _hoisted_65, _toDisplayString(_unref(langObj)["开关机线"]), 1)
              ]),
              _cache[43] || (_cache[43] = _createElementVNode("div", {
                id: "chartDover",
                style: {"height":"340px"},
                class: "w-full"
              }, null, -1))
            ], 512), [
              [_vShow, _unref(histrendSearchModel).isShow]
            ])
          ]),
          _createElementVNode("div", _hoisted_66, [
            _createElementVNode("div", _hoisted_67, [
              _createElementVNode("div", _hoisted_68, _toDisplayString(_unref(langObj)["指标监测趋势"]), 1),
              _createElementVNode("div", _hoisted_69, [
                (_unref(sensorSearchModel).isShow)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_unref(sensorSearchModel).isShow = false)),
                      class: "flex items-center"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)["收起"]), 1),
                      _createVNode(_component_caret_up_outlined, { class: "ml-1" })
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(sensorSearchModel).isShow)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_unref(sensorSearchModel).isShow = true)),
                      class: "flex items-center"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)["展开"]), 1),
                      _createVNode(_component_caret_down_outlined, { class: "ml-1" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[44] || (_cache[44] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _withDirectives(_createElementVNode("div", _hoisted_70, [
                _createVNode(_component_a_switch, {
                  checked: _unref(value1),
                  "onUpdate:checked": _cache[19] || (_cache[19] = ($event: any) => (_isRef(value1) ? (value1).value = $event : value1 = $event)),
                  "checked-children": _unref(langObj)['开启'],
                  "un-checked-children": _unref(langObj)['关闭']
                }, null, 8, ["checked", "checked-children", "un-checked-children"]),
                _createElementVNode("span", _hoisted_71, _toDisplayString(_unref(langObj)["自动刷新"]), 1),
                _createElementVNode("div", _hoisted_72, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(sensorSearchModel).type == '1' }]),
                    style: _normalizeStyle([{
                      background:
                        _unref(sensorSearchModel).type == '1' ? '#072498' : '',
                    }, {"border-radius":"5px 0 0 5px"}]),
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (
                      (_unref(sensorSearchModel).type = '1'), getSensorTrendData()
                    ))
                  }, _toDisplayString(_unref(langObj)["近一天"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(sensorSearchModel).type == '2' }]),
                    style: _normalizeStyle({
                      background:
                        _unref(sensorSearchModel).type == '2' ? '#072498' : '',
                    }),
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (
                      (_unref(sensorSearchModel).type = '2'), getSensorTrendData()
                    ))
                  }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(sensorSearchModel).type == '3' }]),
                    style: _normalizeStyle({
                      background:
                        _unref(sensorSearchModel).type == '3' ? '#072498' : '',
                    }),
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (
                      (_unref(sensorSearchModel).type = '3'), getSensorTrendData()
                    ))
                  }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(sensorSearchModel).type == '0' }]),
                    style: _normalizeStyle([{
                      background:
                        _unref(sensorSearchModel).type == '0' ? '#072498' : '',
                    }, {"border-radius":"0 5px 5px 0"}]),
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_unref(sensorSearchModel).type = '0'))
                  }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                ]),
                (_unref(sensorSearchModel).type == '0')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                      _createVNode(_component_a_range_picker, {
                        value: _unref(sensorSearchModel).date,
                        "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(sensorSearchModel).date) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  onClick: getSensorTrendData,
                  class: "btn3 ml-2"
                }, _toDisplayString(_unref(langObj)["筛选"]), 1)
              ], 512), [
                [_vShow, _unref(sensorSearchModel).isShow]
              ])
            ]),
            _withDirectives(_createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorList), (item, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", _hoisted_74, _toDisplayString(item.sensorPlace), 1),
                  _createElementVNode("div", _hoisted_75, [
                    _createElementVNode("div", _hoisted_76, [
                      _createElementVNode("div", _hoisted_77, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", null, _toDisplayString(_unref(langObj)["测点指标值"]), 1),
                          _createElementVNode("div", null, _toDisplayString(item.sensorDate), 1)
                        ]),
                        _cache[45] || (_cache[45] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                      ]),
                      _createElementVNode("div", _hoisted_78, [
                        _createElementVNode("div", _hoisted_79, _toDisplayString(item.sensorPlace), 1),
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, _toDisplayString(_unref(langObj)["指标名称"]), 1),
                          _createElementVNode("div", _hoisted_82, _toDisplayString(_unref(langObj)["指标值"]), 1),
                          _createElementVNode("div", _hoisted_83, _toDisplayString(_unref(langObj)["门限"] || "门限"), 1)
                        ]),
                        (item.sensorData)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sensorData, (data, index2) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  style: {"padding":"5px 5px"},
                                  class: _normalizeClass([[getRowClassName(index2)], "w-full flex items-center"]),
                                  key: index2
                                }, [
                                  _createElementVNode("div", _hoisted_85, _toDisplayString(data.name), 1),
                                  _createElementVNode("div", _hoisted_86, _toDisplayString(data.value), 1),
                                  _createElementVNode("div", _hoisted_87, _toDisplayString(data.thresholdText), 1)
                                ], 2))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_88, [
                      _createVNode(_component_a_spin, { spinning: _unref(sensorLoading) }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "w-full h-96",
                            id: 'chartSensor' + index
                          }, null, 8, _hoisted_89)
                        ]),
                        _: 2
                      }, 1032, ["spinning"])
                    ])
                  ])
                ]))
              }), 128))
            ], 512), [
              [_vShow, _unref(sensorSearchModel).isShow]
            ])
          ]),
          (_unref(isShowTimeLine))
            ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                _createElementVNode("div", _hoisted_91, [
                  _createElementVNode("div", _hoisted_92, _toDisplayString(_unref(langObj)["运行时间趋势"]), 1),
                  _createElementVNode("div", _hoisted_93, [
                    (_unref(statusSearchModel).isShow)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: _cache[25] || (_cache[25] = ($event: any) => (_unref(statusSearchModel).isShow = false)),
                          class: "flex items-center"
                        }, [
                          _createElementVNode("div", null, _toDisplayString(_unref(langObj)["收起"]), 1),
                          _createVNode(_component_caret_up_outlined, { class: "ml-1" })
                        ]))
                      : _createCommentVNode("", true),
                    (!_unref(statusSearchModel).isShow)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: _cache[26] || (_cache[26] = ($event: any) => (_unref(statusSearchModel).isShow = true)),
                          class: "flex items-center"
                        }, [
                          _cache[46] || (_cache[46] = _createElementVNode("div", null, "展开", -1)),
                          _createVNode(_component_caret_down_outlined, { class: "ml-1" })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[47] || (_cache[47] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _withDirectives(_createElementVNode("div", _hoisted_94, [
                    _createElementVNode("div", _hoisted_95, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(statusSearchModel).type == '1' }]),
                        style: _normalizeStyle([{
                      background:
                        _unref(statusSearchModel).type == '1' ? '#072498' : '',
                    }, {"border-radius":"5px 0 0 5px"}]),
                        onClick: _cache[27] || (_cache[27] = ($event: any) => (
                      (_unref(statusSearchModel).type = '1'), getMachineWKstatus()
                    ))
                      }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(statusSearchModel).type == '2' }]),
                        style: _normalizeStyle({
                      background:
                        _unref(statusSearchModel).type == '2' ? '#072498' : '',
                    }),
                        onClick: _cache[28] || (_cache[28] = ($event: any) => (
                      (_unref(statusSearchModel).type = '2'), getMachineWKstatus()
                    ))
                      }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(statusSearchModel).type == '3' }]),
                        style: _normalizeStyle({
                      background:
                        _unref(statusSearchModel).type == '3' ? '#072498' : '',
                    }),
                        onClick: _cache[29] || (_cache[29] = ($event: any) => (
                      (_unref(statusSearchModel).type = '3'), getMachineWKstatus()
                    ))
                      }, _toDisplayString(_unref(langObj)["近两月"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(statusSearchModel).type == '0' }]),
                        style: _normalizeStyle([{
                      background:
                        _unref(statusSearchModel).type == '0' ? '#072498' : '',
                    }, {"border-radius":"0 5px 5px 0"}]),
                        onClick: _cache[30] || (_cache[30] = ($event: any) => (_unref(statusSearchModel).type = '0'))
                      }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                    ]),
                    (_unref(statusSearchModel).type == '0')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(statusSearchModel).date,
                            "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_unref(statusSearchModel).date) = $event))
                          }, null, 8, ["value"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      onClick: getMachineWKstatus,
                      class: "btn3 ml-2"
                    }, _toDisplayString(_unref(langObj)["筛选"]), 1)
                  ], 512), [
                    [_vShow, _unref(statusSearchModel).isShow]
                  ])
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_97, _cache[48] || (_cache[48] = [
                  _createElementVNode("div", {
                    class: "w-full h-96",
                    id: "chartStatus"
                  }, null, -1)
                ]), 512), [
                  [_vShow, _unref(statusSearchModel).isShow]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_98, [
          _createElementVNode("div", _hoisted_99, _toDisplayString(_unref(getLanguage)('咨询客服')) + ": +86-18112346207", 1),
          _createVNode(OrderListComponent, {
            ref_key: "orderC",
            ref: orderC,
            machineInfo: _unref(machineInfo)
          }, null, 8, ["machineInfo"])
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      width: 1400,
      visible: _unref(downLoadVisible),
      "onUpdate:visible": _cache[32] || (_cache[32] = ($event: any) => (_isRef(downLoadVisible) ? (downLoadVisible).value = $event : downLoadVisible = $event)),
      title: _unref(langObj)['下载'],
      maskClosable: false,
      footer: false
    }, {
      default: _withCtx(() => [
        _createVNode(DownLoadComponent, {
          onDownLoadCancel: downLoadCancel,
          type: 1,
          dataList: _unref(downLoadList)
        }, null, 8, ["dataList"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ]))
}
}

})