<template>
  <div class="chat-messages-container" ref="messagesContainerRef">
    <div v-for="(message, index) in messages" :key="index" :class="['message-wrapper', 'message-wrapper-' + message.type]">
      <div class="avatar">
        <template v-if="message.type === 'bot'">
          <!-- Bot SVG Avatar -->
          <img src="@/assets/chat/chat-bot.png" alt="chat-bot" class="bot-avatar">
        </template>
        <template v-else>
          <!-- User SVG Avatar -->
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="user-avatar">
            <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
          </svg>
        </template>
      </div>
      <div class="chat-message-content">
        <div class="chat-message" v-html="renderMarkdown(message.content)">
        </div>
        <div v-if="message.type === 'bot'" class="chat-message-time">
          {{ transformDate(message.time) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue';
import { ChatMessage } from './chat.types';
import { transformDate } from '@/common/tools';
import { marked } from 'marked';

const props = defineProps({
  messages: {
    type: Array as () => ChatMessage[],
    required: true
  }
});

const messagesContainerRef = ref<HTMLElement | null>(null);

const scrollToBottom = () => {
  if (messagesContainerRef.value) {
    nextTick(() => {
      messagesContainerRef.value!.scrollTop = messagesContainerRef.value!.scrollHeight;
    });
  }
};

// Watch for changes to the messages array
watch(() => props.messages, () => {
  scrollToBottom();
}, { deep: true });

// Scroll to bottom on initial mount
onMounted(() => {
  scrollToBottom();
});

// Configure marked for safe rendering
marked.setOptions({
  breaks: true,
  gfm: true,
});

const renderMarkdown = (content: string) => {
  try {
    return marked(content);
  } catch (e) {
    return content;
  }
};
</script>

<style scoped lang="less">
.chat-messages-container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(3, 23, 106, 0.3); // Darker blue background (transparent)
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #04239f; // Same blue as the bot message background
    border-radius: 4px;

    &:hover {
      background: #0530c0; // Slightly lighter blue for hover state
    }
  }

  // Firefox scrollbar compatibility
  scrollbar-width: thin;
  scrollbar-color: #04239f rgba(3, 23, 106, 0.3);
}

.message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  max-width: 85%;
}

.message-wrapper-user {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-wrapper-bot {
  margin-right: auto;
}

.message-wrapper-user .chat-message {
  background-color: #03176a;
}

.message-wrapper-bot .chat-message {
  background-color: #04239f;
}

.avatar {
  min-width: 36px;
  height: 36px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .bot-avatar,
  .user-avatar {
    width: 24px;
    height: 24px;
    fill: white;
  }
}

.chat-message-content {
  display: flex;
  flex-direction: column;
}

.chat-message {
  padding: 8px 12px;
  border-radius: 8px;
  word-break: break-word;
  white-space: wrap;
  line-height: 1.5; // Add this line to increase line height

  :deep(p) {
    margin: 0;
  }

  :deep(pre) {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }

  :deep(code) {
    font-family: monospace;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2px 4px;
    border-radius: 3px;
  }

  :deep(ul, ol) {
    margin: 4px 0;
    padding-left: 20px;
  }

  :deep(a) {
    color: #66b3ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.chat-message-time {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 3px;
}
</style>
