import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "w-full flex justify-center" }
const _hoisted_3 = { style: {"width":"600px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  class: "mt-4",
  style: {"font-size":"18px"}
}
const _hoisted_6 = {
  class: "mt-4",
  style: {"font-size":"18px"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { style: {"margin-top":"8px","color":"#fff"} }
const _hoisted_9 = { "ml-2": "" }
const _hoisted_10 = { class: "w-full flex justify-center mt-5" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  class: "mt-4 flex items-center",
  style: {"font-size":"18px"}
}
const _hoisted_13 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_14 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_15 = { style: {"background":"#fff"} }
const _hoisted_16 = {
  key: 0,
  style: {"color":"#08c"}
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 4,
  class: "flex items-center"
}
const _hoisted_19 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "w-full flex justify-center mt-5" }
const _hoisted_22 = { key: 2 }
const _hoisted_23 = {
  class: "mt-4",
  style: {"font-size":"18px"}
}
const _hoisted_24 = { class: "flex items-center mt-4" }
const _hoisted_25 = { class: "w-full flex items-center" }
const _hoisted_26 = { class: "ml-2" }
const _hoisted_27 = {
  class: "mt-2 ml-8",
  style: {"border-left":"2px solid gray"}
}
const _hoisted_28 = { class: "w-full flex items-center flex-wrap table1 ml-8" }
const _hoisted_29 = { style: {"width":"200px"} }
const _hoisted_30 = { class: "w-full text-center mb-2" }
const _hoisted_31 = { class: "mt-2" }
const _hoisted_32 = {
  style: {"width":"200px"},
  class: "ml-4"
}
const _hoisted_33 = { class: "w-full text-center mb-2" }
const _hoisted_34 = { class: "mt-2 flex" }
const _hoisted_35 = { class: "w-full text-center mb-2" }
const _hoisted_36 = { class: "mt-2 flex" }
const _hoisted_37 = { class: "flex items-center" }
const _hoisted_38 = { class: "ml-2" }
const _hoisted_39 = { class: "ml-1" }
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = { class: "ml-2" }
const _hoisted_43 = { class: "w-full flex items-center flex-wrap table1 ml-8 mt-2" }
const _hoisted_44 = { class: "w-full text-center mb-2" }
const _hoisted_45 = { class: "mt-2" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { key: 2 }
const _hoisted_49 = { class: "w-full flex justify-center mt-5" }

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  createVNode
} from "vue";
import { getLanguage } from "../../../common/translate";
import { langList } from "../../../common/lang";
import { v4 as uuidv4 } from "uuid";
import request from "../../../common/request";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../../common/tools";
import { message } from "ant-design-vue";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';




export default /*@__PURE__*/_defineComponent({
  __name: 'add-machine',
  props: {
    machineFormEdit: null,
    fileList: null,
    dropdownClassName: null,
    isEdit: null,
},
  emits: ["close", 'resetData'],
  setup(__props, { emit: __emit }) {

let dropdownClassName: any = ref("");
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
const current = ref<number>(0);
const steps = [
  {
    title: getLanguage('填写设备基础信息') ,
  },
  {
    title: getLanguage('填写测点信息'),
  },
  {
    title: getLanguage('填写设备提资信息'),
  },
];

let machineForm:any = ref({
    params: {},
    mode: [],
    machineTypeDetails: {},
})
let groupList:any = ref([])
let machineFileList:any = ref([])
let machineTypeList:any = ref([])
let machineTypeListAll:any = ref([])
let machineType3Ds:any = ref([])
let macForm:any = ref()
let isShowNext = computed(() => {
  return machineForm.value.mode.includes(1)
})

let sensorForm:any = ref({

})

let categoryAll:any = ref([])
let options:any = ref([])
let structureList:any = ref([])
let bearingParameters: any = ref([])
let isEdit:any = ref(false)

const props: any = __props;

watch(
  () => props.dropdownClassName,
  (newValue, oldValue) => {
    dropdownClassName.value = newValue;
  }
);

if(props.machineFormEdit) {
    machineForm.value = {...props.machineFormEdit };
}
if(props.fileList?.length) {
    machineFileList.value = props.fileList
}
if(props.dropdownClassName) {
    dropdownClassName.value = props.dropdownClassName
}
isEdit.value = props.isEdit

onMounted(() => {
    init()
})

const init = async() => {
    getGroupList();
    await getMachineType()
    getSearch3()
    getStructureList()
    getBearingParameter()
    getStep1()
}

const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;
  }
};

// 获取智能诊断巡检配置
const getSearch3 = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [...new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [...new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [...new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options.value = list
}

// 获取结构信息
const getStructureList = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-structures/all", config);
  if (result?.data) {
    structureList.value = result.data;
  }
}

// 获取轴承型号
const getBearingParameter = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/bearing-parameters/all', config)
  if (result?.data?.length) {
    bearingParameters.value = result.data.map((d: any) => ({
      label: d.bearingModel,
      value: d.bearingModel,
    }))
  }
}

// 第一步，设备基础信息
const getStep1 = () => {
    current.value = 0
    if(machineForm.value.machineTypeId) {
        let mtInfo = machineTypeListAll.value.find((p:any) => machineForm.value.machineTypeId === p.id )
        machineType3Ds.value = mtInfo?.pictures || []
    }
}

const isRequiredDas = () => {
    let r = true
    sensorList.value.map((d:any) => {
        if(!d.sensorSn.startsWith('63')) {
            r = false
        }
    })
    return !r
}


const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

const selectMachineType = (ev:any) => {
    if(ev?.length === 3) {
        let mtInfo = machineTypeListAll.value.find((p:any) => p.FirstCategory===ev[0] && p.SecondCategory===ev[1] && p.ThirdCategory===ev[2] )
        machineType3Ds.value = mtInfo?.pictures || []
        if(machineType3Ds.value?.length) {
            machineForm.value.machineTypeDetails.pictureUrl = machineType3Ds.value[0].url
        }
    } else {
        machineForm.value.pictureUrl = ''
        machineType3Ds.value = []
    }

}

// 上一步
const previous = () => {
    current.value --
}

// 下一步
const next = () => {
    if(current.value == 0) {
        if(!machineForm.value.machineTypeId ) {
            return message.warning("请选择设备类型后进行下一步");
        } else {
            getStep2()
        }
    }
    current.value ++
}

// 保存设备基础信息
const submit1 = (isClose:any=true) => {
    if(!machineForm.value.mode.length) {
        return message.warning('请选择检测类别')
    }
    macForm.value?.clearValidate();
    macForm.value.validate().then(async (res: any) => {
        let form = JSON.parse(JSON.stringify(machineForm.value));
        if (machineFileList.value && machineFileList.value.length) {
            form.pictures = machineFileList.value.map((p: any) => ({
            url: p.url,
            type: 1,
            }));
        }

        if (form.type && form.type.length === 3) {
            let info = machineTypeListAll.value.find(
            (p: any) =>
                p.FirstCategory === form.type[0] &&
                p.SecondCategory === form.type[1] &&
                p.ThirdCategory === form.type[2]
            );
            form.machineTypeId = info.id;
            machineForm.value.machineTypeId = info.id
        } else {
            form.machineTypeId = null;
        }
        delete form.type;
        let result = form.id
            ? await request.put("/api/machines/info", form)
            : await request.post("/api/machines", form);
        if (result && result.status === 200) {
            message.success("操作成功");
            if(!form.id) {
                machineForm.value.id = result.data;
            }


            if(isClose) {
                emits("close", result.data);
            } else {
                emits("resetData");
                next()
            }
            await getMember();
        } else {
            message.warning("操作失败,请联系管理员!");
        }



    })
}

const getMember = async () => {
  let result2 = await request.get("/api/members/info");
  localStorage.setItem("memberInfo", JSON.stringify(result2.data));
};

const emits = __emit;


let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};


// 第二步填写测点信息
let selectSensorList:any = ref([])
let sensorTreeData:any = ref([])
let sensorList:any = ref([])
let sortList:any = ref([])
let directionList:any = ['水平径向','轴向', '竖直径向']
let powerEtcList:any = ref([])
let speedList:any = ref([])
const sensorColumns:any = [
    {
        title: langObj.value["安装位置"],
        dataIndex: "sensorPlaceSelect",
    },
    {
        title: langObj.value["传感器SN"],
        dataIndex: "sensorSn",
    },
    {
        title: getLanguage("数采器编号"),
        dataIndex: "dasCode",
    },
    {
        title: getLanguage("显示顺序"),
        dataIndex: "sort",
    },
    {
        title: getLanguage("轴向名称"),
        dataIndex: "sensorGroup",
    },
    // {
    //     title: langObj.value["设备类型"],
    //     dataIndex: "type",
    // },
    // {
    //     title: langObj.value["设备功率"],
    //     dataIndex: "national",
    // },
    // {
    //     title: getLanguage('智能诊断巡检配置'),
    //     dataIndex: "inspectionSubId",
    // },
    // {
    //     title: getLanguage('转速范围'),
    //     dataIndex: "defaultSpeed",
    // },
    {
        title: langObj.value["是否启用"],
        dataIndex: "disable",
    },
    {
        title: getLanguage('更新模型'),
        dataIndex: "isUpdateParameter",
    },
    {
        title: langObj.value["操作"],
        dataIndex: "act",
    },
]
const getStep2 = () => {
    console.log('machineForm.value.machineTypeId', machineForm.value.machineTypeId)
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.id === machineForm.value.machineTypeId)
    // 获取测点列表
    let sensorList1 = machineTypeInfo?.sensorTemplate?.sensorList || []
    sensorTreeData.value = []

    sensorList1.map((d: any) => {
        let first: any = {
          label: d.name || machineTypeInfo.ThirdCategory,
          value: d.uniqueId,
          selectable: false,
          children: []
        }
        if (d.sensors?.length > 0) {
          d.sensors.map((s: any) => {
            let second = {
              label: s.sensorPlace,
              value: d.uniqueId + '-' + s.sensorPlace,
              selectable: true
            }
            first.children.push(second)
          })
        }
        sensorTreeData.value.push(first)
    })

    if(machineForm.value?.machineTypeDetails?.sensorList?.length > 0) {
        sensorList.value = machineForm.value.machineTypeDetails.sensorList
    }

    console.log('sensorList.value', sensorList.value)
    sensorList.value.map(async(d: any, index:any) => {
        if(d.isAutoCreated) {
            d.sensorPlaceSelect = d.partUniqueId + '-' + d.sensorPlace
        }
        d.isUpdateParameter = false
    })
}

// 新建测点
// isUpdateParameter
const addSensor = () => {
    sensorList.value.push({
        sensorPlaceSelect: '',
        sensorSn: '',
        sort: sensorList.value.length + 1,
        isUpdateParameter: false,
        isAutoCreated: true
    })
    sortList.value = Array.from({ length: sensorList.value.length }, (_, index) => index + 1)
}

const selectSensorPlace = (ev:any, record:any) => {
    console.log('ev', ev)
    console.log('record', record)
    record.partUniqueId = ev.split('-')[0]
    record.sensorPlace = ev.split('-')[1]
    record.isUpdateParameter = true
}

// 确认选择
const selectSensor = (ev:any) => {
    if (!selectSensorList.value?.length) return message.warning('请先选择测点！')
    let sensorList_select = selectSensorList.value.map((d: any) => {
        return {
            partUniqueId: d.split('-')[0],
            sensorPlace: d.split('-')[1]
        }
    })
    sensorList.value = sensorList_select
    sensorList.value.map((d:any, index:any) => {
        d.sort = index+1
        d.sensorSn = ''
    })
    sortList.value = Array.from({ length: sensorList_select.length }, (_, index) => index + 1)
}

// 测点选择设备类型
const changeThirdTypeId = async (ev: any, index:any) => {
    if (ev && ev.length === 3) {
        let info = machineTypeListAll.value.find(
        (p: any) =>
            p.FirstCategory === ev[0] &&
            p.SecondCategory === ev[1] &&
            p.ThirdCategory === ev[2]
        );
        sensorList.value[index].machineTypeId = info.id;

        let config = {
        params: { id: info.id },
        headers: {
            requestId: uuidv4(),
        },
        };

        let result = await request.get(
            "/api/sensors/machine-types/power-etc",
            config
        );
        if (!result?.data) {
            sensorList.value[index].powerEtcList = [];
            sensorList.value[index].national = null;
            return;
        }

        sensorList.value[index].powerEtcList = result.data;
        if (result.data.length > 0) {
            sensorList.value[index].national = result.data[0].powerEtc;
        } else {
            sensorList.value[index].national = null;
        }
    } else {
        sensorList.value[index].powerEtcList = []
        sensorList.value[index].national = null;
    }
};

// 选择巡检设备细分
const changeISub = (ev: any, index:any) => {
  if (ev?.length === 3) {
    sensorList.value[index].speedList = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    sensorList.value[index].speedList = []
    sensorList.value[index].defaultSpeed = null
  }
}

// 删除测点
const delSensor = (index:any) => {
    sensorList.value.splice(index, 1)
}

// 保存
const submit2 = async (isNext:any = false) => {
    if (!sensorList.value.length) return message.warning('请先选择测点！')

    for(let i in sensorList.value) {
        let d = sensorList.value[i]
        if(!d.sensorSn.startsWith('63') &&!d.dasCode) return message.warning('请先填写数采器编号！')
    }

    let submit = {
        id: machineForm.value.id,
        machineTypeDetails: {
            sensorList: sensorList.value
        }
    }

    let result = await request.post("/api/machines/machine-type-details/sensors", submit);
    if(result?.status == 200) {
        message.success("操作成功");
        if(isNext) {
            emits("resetData");
            current.value ++
            getStep3()
        }
    } else {
        message.warning("操作失败,请联系管理员!");
    }

}

// 判断是否为单轴
const isSingleAxis = (record: any) => {
    return record.sensorSn.startsWith('73')
}

// 判断数采器是否可以输入
const isDisableDas = (record: any) => {
    if(record.sensorSn.startsWith('63')) {
        record.dasCode = ''
    }
    return record.sensorSn.startsWith('63')
}


// 第三步，设备提资信息
let isFrequentStart:any = ref(false)
let partsList:any = ref([])
let parts_old:any = ref([])
const getStep3 = async() => {    
    let sensorList_select = JSON.parse(JSON.stringify(sensorList.value))
    let uniqueIds = [... new Set(sensorList_select.map((d: any) => d.partUniqueId))]
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.id === machineForm.value.machineTypeId)
    let sensorList_template = machineTypeInfo?.sensorTemplate?.sensorList || []
    let parts: any = []


    for (let i in uniqueIds) {
        let d = uniqueIds[i]
        let structures: any = []
        let partInfo = sensorList_template.find((p: any) => p.uniqueId === d)
        if (partInfo?.sensors?.length > 0) {
            // 获取细分列表
            let config: any = {
                params: {
                    machineTypeId: machineTypeInfo.id,
                    partUniqueId: machineTypeInfo.isPart ? '' : d,
                    take: 999
                },
                headers: {
                    requestId: uuidv4(),
                },
            };
            let result = await request.get('/api/machine-type/segments/list', config)
            let resultList = result?.data?.list || []
            let tableTitles: any = []
            resultList.map((r: any) => {
                tableTitles = tableTitles.concat(Object.keys(r?.columns || {}))
            })
            let firstColumnsData: any = []
            if (tableTitles?.length > 0) {
                resultList.map((t: any) => {
                firstColumnsData.push(t?.columns[tableTitles[0]])
                })
            }
            let segments = {
                columns: [... new Set(tableTitles)],
                dataList: resultList,
                columnsData0: [... new Set(firstColumnsData)],
            }

            let sensors_part = partInfo.sensors.filter((p: any) => sensorList_select.find((s: any) => s.sensorPlace === p.sensorPlace))

            sensors_part.map((s: any) => {
                // structureList.value
                s.machineStructure.map((m: any) => {
                let structureInfo = structureList.value.find((p: any) => p.id === m)
                // 轴承齿轮需要绑定测点
                if (m === "672c55e3e19a0d0e17e653b7" || m === "672c55e3e19a0d0e17e653b9" || m === "672c55e3e19a0d0e17e653ba") {
                    structures.push({
                    sensorPlace: s.sensorPlace,
                    machineStructureId: m,
                    machineStructureName: structureInfo?.name,
                    parmarList: getStructureParmar(m)
                    })
                } else {
                    if (!structures.find((p: any) => p.machineStructureId === m)) {
                    structures.push({
                        sensorPlace: '',
                        machineStructureId: m,
                        machineStructureName: structureInfo?.name,
                        parmarList: getStructureParmar(m)
                    })
                    }
                }


                })
            })

            parts.push({
                partUniqueId: d,
                partName: partInfo.name,
                machineTypeSegmentId: null,
                params: {
                    Rated_speed: null,
                    PowerFre: null,
                    startFre: null,
                    stopFre: null,
                },
                structures,
                segments,
            })

            if(machineForm.value?.machineTypeDetails?.parts) {
                parts_old.value = machineForm.value.machineTypeDetails.parts
            }
            parts.map((part:any) => {
                let part_old = null
                if(parts_old.value?.length) {
                    part_old = parts_old.value.find((p:any) => p.partUniqueId === part.partUniqueId)
                }
                if(part_old) {
                    part.params = part_old.params
                    part.disable = true

                    part.structures.map((structure: any) => {
                        let structure_old = part_old.structures.find((p: any) => p.sensorPlace === structure.sensorPlace && p.machineStructureId === structure.machineStructureId)
                        structure.parmarList && structure.parmarList.map((parmar: any) => {
                        if (structure_old?.params && structure_old?.params[parmar.key]) {
                            parmar.value = structure_old?.params[parmar.key]
                        }
                        })
                    })
                }
                if(part_old?.machineTypeSegmentId) {
                    // parts.segments
                    part.segments.id = part_old.machineTypeSegmentId
                    let dataInfo = part.segments.dataList.find((p:any) => p.id === part.segments.id)
                    if(dataInfo) {
                        part.segments.columns.map((c:any, i:any) => {
                            part.segments['data'+i] =  dataInfo.columns[c]
                            if(i > 0) {
                                let dataListS = part.segments.dataList.filter((p: any) => p.columns[part.segments.columns[i-1]])
                                let columnsData:any = []
                                dataListS.map((t: any) => {
                                    columnsData.push(t?.columns[part.segments.columns[i]])
                                })
                                part.segments['columnsData'+i] = [... new Set(columnsData)]
                            }
                        })
                    }
                    
                }   
            })


        }
    }
    partsList.value = parts

}

// 输入额定转速
const changeRs = (ev:any, part:any) => {
    console.log('ev', ev)
    console.log('part', part)
    part?.structures?.length && part.structures.map((d:any) => {
        if(d.machineStructureName == "柱塞") {
            d.parmarList.map((p:any) => {
                if(p.key== 'fr_Min') {
                    p.value = Number((ev/60).toFixed(2))
                }
                if(p.key== 'fr_Max') {
                    p.value = Number((ev/60).toFixed(2))
                }
            })
        }
        if(d.machineStructureName == "电机") {
            d.parmarList.map((p:any) => {
                if(p.key== 'Rated_speed') {
                    p.value = Number((ev/60).toFixed(2))
                }
            })
        }
    })
}

// 获取不同结构的参数
const getStructureParmar = (id: any) => {
  let list = [
    {
      id: "672c55e3e19a0d0e17e653b6",
      name: '叶轮',
      parmar: [
        { label: '叶轮级数', value: 1, key: 'Impeller_series' },
        { label: '叶轮数目', value: null, key: 'BladeNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bc",
      name: '电机',
      parmar: [
        { label: '额定转频', value: null, key: 'Rated_speed' },
        { label: '额定电源频率', value: 50, key: 'PowerFre' },
        { label: '电机极对数', value: null, key: 'p' },
        { label: '转子条数', value: null, key: 'rot_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b7",
      name: '轴承',
      parmar: [
        { label: '轴承类型', value: null, key: 'type', type: 'select', option: [{ label: '滚动轴承', value: 1 }, { label: '滑动轴承', value: 2 }] },
        // {label: '轴承参数id', value: null, key: 'bearingParameterId', type: 'string'},
        { label: '轴承型号', value: null, key: 'bearingModel', type: 'select', option: bearingParameters.value },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b9",
      name: '行星齿轮',
      parmar: [
        { label: '太阳轮齿数', value: null, key: 'Zs', },
        { label: '一倍啮合频率', value: null, key: 'MeshFre' },
        { label: '行星轮个数', value: null, key: 'PLanetary' },
        { label: '齿圈齿数', value: null, key: 'RingTeeth' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653ba",
      name: '定轴齿轮',
      parmar: [
        { label: '主动齿轮齿数', value: null, key: 'GeaTeethNum', },
        { label: '从动齿轮齿数', value: null, key: 'GeaOutTeethNum' },
        { label: '提取啮合频率谐波阶数', value: 5, key: 'mesh_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b8",
      name: '柱塞',
      parmar: [
        { label: '柱塞数目', value: null, key: 'z', },
        { label: '柱塞泵滤波参数', value: 2000, key: 'wps' },
        { label: '柱塞泵滤波参数', value: 3000, key: 'wss' },
        { label: '柱塞泵转频上限', value: null, key: 'fr_Min' },
        { label: '柱塞泵转频下限', value: null, key: 'fr_Max' },
        { label: '轴柱塞泵个数', value: null, key: 'PlungerNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bb",
      name: '螺杆',
      parmar: [
        { label: '螺杆阳转子齿数', value: null, key: 'ScrTeethNum', },
        { label: '螺杆阴转子齿数', value: null, key: 'ScrOutTeethNum' }
      ]
    },

  ]
  return list.find((p: any) => p.id === id)?.parmar || []
}

// 选择细分内容
const selectPartParmar = (segments: any, index: any) => {
    if (index + 1 >= segments.columns.length) {
        let dataListC = JSON.parse(JSON.stringify(segments.dataList))
        for (let i in segments.columns) {
            let c = segments.columns[i]
            dataListC = dataListC.filter((p: any) => {
                return p.columns[c] === segments['data' + i]
            })
        }
        if (dataListC?.length) {
            segments.id = dataListC[0].id
        } else {
            message.warning(getLanguage('暂无选择的细分， 请重新选择'))
        }
        return
    }
    let column = segments.columns[index + 1]

    let columnsData: any = []

    let dataList1 = JSON.parse(JSON.stringify(segments.dataList))
    for(let i = 0; i <= index; i++) {
        let column1 = segments.columns[i]
        dataList1 = dataList1.filter((p:any) => p.columns[column1] == segments['data'+i])
    }

    let dataListS = dataList1.filter((p: any) => p.columns[segments.columns[index]])
    dataListS.map((t: any) => {
        columnsData.push(t?.columns[column])
    })
    columnsData = [ ... new Set(columnsData)]
    segments['columnsData' + (index + 1)] = columnsData

    for(let i = segments.columns.length; i > index; i--) {
        if(segments['data'+i]) {
            segments['data'+i] = null
        }
    }
}

// 提交设备提资信息
let isSubmitV: any = ref(false)
const submit3 = async() => {
    let parts = JSON.parse(JSON.stringify(partsList.value))
    let isComplete = true
    let isCompleteMsg = ''
    let isSub = true
    

    parts.map((d: any) => {
        if(!d.params?.Rated_speed && d.params?.Rated_speed !== 0) {
            isComplete = false
            isCompleteMsg = '请输入额定转速后提交!'
        }
        if(!d.params?.startFre && d.params?.startFre !== 0) {
            isComplete = false
            isCompleteMsg = '请输入转速范围后提交!'
        }
        if(!d.params?.stopFre && d.params?.stopFre !== 0) {
            isComplete = false
            isCompleteMsg = '请输入转速范围后提交!'
        }

        d.machineTypeSegmentId = d.segments.id
        delete d.segments
        d.structures.map((s: any) => {
            let parmarList = s.parmarList
            delete s.parmarList
            let parmar: any = {}
            parmarList.map((p: any) => {
                parmar[p.key] = p.value
                if (!p.value && p.value !== 0) {
                    if(s.machineStructureName == "轴承" && p.key === 'bearingModel') {
                        let type = parmarList.find((p:any) => p.key = 'type')
                        if(type.value !== 2) {
                            isSub = false
                        }
                    } else {
                        isSub = false
                    }
                }
            })
            s.params = parmar
        })
    })

    if(!isComplete) {
        return message.warning(isCompleteMsg)
    }
    if (!isSub && !isSubmitV.value) {
        Modal.confirm({
            title: '',
            icon: createVNode(ExclamationCircleOutlined),
            content: createVNode('div', { style: 'color:#fff;' }, '有部件或结构信息未填写完整，对应的健康指标将不会显示，您确认要提交吗?'),
            onOk() {
                isSubmitV.value = true
                submit3()
            },
            onCancel() {
            },
            style: { color: '#fff' },
            zIndex: 1000000003
        });
        return
    }

    let submit = {
        id:  machineForm.value.id,
        machineTypeDetails: {
            isFrequentStart: isFrequentStart.value,
            parts: parts
        }
    }

    let result = await request.post('/api/machines/machine-type-details', submit)
    if(result?.status == 200) {
        message.success('提交成功!')
        emits("close", result.data);
    }
}


const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};


return (_ctx: any,_cache: any) => {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_steps, {
          size: "small",
          current: current.value
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (item) => {
              return _createVNode(_component_a_step, {
                key: item.title,
                title: item.title
              }, null, 8, ["title"])
            }), 64))
          ]),
          _: 1
        }, 8, ["current"])
      ])
    ]),
    (current.value == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(getLanguage)('监测设备类别')) + ":", 1),
          _createVNode(_component_a_form, {
            ref_key: "macForm",
            ref: macForm,
            model: _unref(machineForm),
            layout: "inline",
            autocomplete: "off",
            class: "w-full",
            "label-col": { style: { width: '180px', wordWrap: 'break-word' } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, {
                class: "w-full",
                gutter: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('监测类别'),
                        name: "mode",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_checkbox_group, {
                            value: _unref(machineForm).mode,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(machineForm).mode) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, { value: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(langObj)['在线监测']), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_checkbox, { value: 2 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(langObj)['巡检']), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(getLanguage)('设备基础信息')) + ":", 1),
          _createVNode(_component_a_form, {
            ref_key: "macForm",
            ref: macForm,
            model: _unref(machineForm),
            layout: "inline",
            autocomplete: "off",
            class: "w-full",
            "label-col": { style: { width: '180px', wordWrap: 'break-word' } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, {
                class: "w-full",
                gutter: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备区域'],
                        name: "groupId",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(machineForm).groupId,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(machineForm).groupId) = $event)),
                            placeholder: _unref(langObj)['请选择设备区域'],
                            onChange: selectGroupM,
                            dropdownClassName: _unref(dropdownClassName)
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder", "dropdownClassName"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备名称'],
                        name: "machineName",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).machineName,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(machineForm).machineName) = $event)),
                            placeholder: _unref(langObj)['请输入设备名称']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备类型'],
                        name: "type",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_cascader, {
                            style: {"max-width":"236px"},
                            value: _unref(machineForm).type,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(machineForm).type) = $event)),
                            options: _unref(machineTypeList),
                            placeholder: _unref(langObj)['请选择设备类型'],
                            dropdownClassName: _unref(dropdownClassName),
                            onChange: selectMachineType
                          }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['巡检序号'],
                        name: "sort",
                        required: true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_number, {
                            controls: false,
                            style: {"width":"100%"},
                            value: _unref(machineForm).sort,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(machineForm).sort) = $event)),
                            placeholder: _unref(langObj)['请输入']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备编号'],
                        name: "machineCode"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).machineCode,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(machineForm).machineCode) = $event)),
                            placeholder: _unref(langObj)['请输入设备编号']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备型号'],
                        name: "model"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).model,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(machineForm).model) = $event)),
                            placeholder: _unref(langObj)['请输入设备型号']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备位置'],
                        name: "position"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).position,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(machineForm).position) = $event)),
                            placeholder: _unref(langObj)['请输入设备位置']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备厂家'],
                        name: "supplier"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).supplier,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(machineForm).supplier) = $event)),
                            placeholder: _unref(langObj)['请输入设备厂家']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备SN号'],
                        name: "sn"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).sn,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(machineForm).sn) = $event)),
                            placeholder: _unref(langObj)['请输入设备SN号']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备功率'],
                        name: "sn"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).machinePower,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(machineForm).machinePower) = $event)),
                            placeholder: _unref(langObj)['请输入设备功率']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备ID'],
                        name: "sn"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).params.ysshId,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(machineForm).params.ysshId) = $event)),
                            placeholder: _unref(langObj)['请输入设备ID']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备位号'],
                        name: "sn"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).bitNumber,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(machineForm).bitNumber) = $event)),
                            placeholder: _unref(langObj)['请输入设备位号']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备执行人'],
                        name: "sn"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineForm).params.ysshExecutor,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(machineForm).params.ysshExecutor) = $event)),
                            placeholder: _unref(langObj)['请输入设备执行人']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备描述'],
                        name: "remark"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _unref(machineForm).remark,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(machineForm).remark) = $event)),
                            placeholder: "请输入",
                            rows: 4
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备照片']
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_upload, {
                            "file-list": _unref(machineFileList),
                            "onUpdate:fileList": _cache[15] || (_cache[15] = ($event: any) => (_isRef(machineFileList) ? (machineFileList).value = $event : machineFileList = $event)),
                            "list-type": "picture-card",
                            onPreview: handlePreview,
                            "custom-request": _unref(customRequest),
                            "auto-upload": false,
                            accept: "image",
                            "max-count": 6
                          }, {
                            default: _withCtx(() => [
                              (!_unref(machineFileList) || _unref(machineFileList).length < 6)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_plus_outlined),
                                    _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)['上传']), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["file-list", "custom-request"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备3D图'),
                        name: "3D"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio_group, {
                            value: _unref(machineForm).machineTypeDetails.pictureUrl,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(machineForm).machineTypeDetails.pictureUrl) = $event))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineType3Ds), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_radio, {
                                  value: item.url
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_image, {
                                      height: "100px",
                                      src: item.url
                                    }, null, 8, ["src"]),
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.remark), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 256))
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_10, [
                    (current.value !== 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: _cache[17] || (_cache[17] = ($event: any) => (previous())),
                          class: "btn-default py-1 px-6 cursor-pointer btn-submit"
                        }, _toDisplayString(_unref(getLanguage)('上一步')), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      onClick: submit1,
                      class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
                    }, _toDisplayString(_unref(getLanguage)('保存')), 1),
                    (_unref(isShowNext))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: _cache[18] || (_cache[18] = ($event: any) => (submit1(false))),
                          class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
                        }, _toDisplayString(_unref(getLanguage)('下一步')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]))
      : _createCommentVNode("", true),
    (current.value == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createTextVNode(_toDisplayString(_unref(getLanguage)('测点信息')) + ": ", 1),
            _createElementVNode("div", {
              onClick: _cache[19] || (_cache[19] = ($event: any) => (addSensor())),
              style: {"font-size":"14px"},
              class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('新建测点')), 1)
          ]),
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: sensorColumns,
            "data-source": _unref(sensorList),
            pagination: false
          }, {
            headerCell: _withCtx(({ column }) => [
              (column.dataIndex === 'sensorSn')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[26] || (_cache[26] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(langObj)["传感器SN"]), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'dasCode')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (isRequiredDas())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, "*"))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)("数采器编号")), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'isUpdateParameter')
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)('更新模型')) + " ", 1),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('选择“是”：系统会根据选择的测点更新绑定的模型配置；选择“否”：系统只会修改安装位置名称，不会更新模型配置。')), 1)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_question_circle_outlined, { class: "ml-2 center_wh" })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            bodyCell: _withCtx(({ column, text, record, index }) => [
              (column.dataIndex === 'sensorPlaceSelect')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (record.isAutoCreated)
                      ? (_openBlock(), _createBlock(_component_a_tree_select, {
                          key: 0,
                          value: record.sensorPlaceSelect,
                          "onUpdate:value": ($event: any) => ((record.sensorPlaceSelect) = $event),
                          "show-search": "",
                          style: {"min-width":"300px"},
                          "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                          "allow-clear": "",
                          "tree-data": _unref(sensorTreeData),
                          "tree-node-filter-prop": "label",
                          onChange: ($event: any) => (selectSensorPlace($event, record)),
                          dropdownClassName: _unref(dropdownClassName)
                        }, {
                          title: _withCtx(({ value: val, label }) => [
                            (val === 'parent 1-1')
                              ? (_openBlock(), _createElementBlock("b", _hoisted_16, "sss"))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(label), 1)
                                ], 64))
                          ]),
                          _: 2
                        }, 1032, ["value", "onUpdate:value", "tree-data", "onChange", "dropdownClassName"]))
                      : _createCommentVNode("", true),
                    (!record.isAutoCreated)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(text), 1))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sensorSn')
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 1,
                    value: record.sensorSn,
                    "onUpdate:value": ($event: any) => ((record.sensorSn) = $event),
                    placeholder: _unref(langObj)['请输入测点SN号']
                  }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'dasCode')
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 2,
                    disabled: isDisableDas(record),
                    value: record.dasCode,
                    "onUpdate:value": ($event: any) => ((record.dasCode) = $event),
                    placeholder: _unref(langObj)['请输入测点SN号']
                  }, null, 8, ["disabled", "value", "onUpdate:value", "placeholder"]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sort')
                ? (_openBlock(), _createBlock(_component_a_select, {
                    key: 3,
                    style: {"width":"50px"},
                    value: record.sort,
                    "onUpdate:value": ($event: any) => ((record.sort) = $event),
                    dropdownClassName: _unref(dropdownClassName)
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["value", "onUpdate:value", "dropdownClassName"]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sensorGroup')
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _cache[29] || (_cache[29] = _createTextVNode(" Z: ")),
                    _createVNode(_component_a_select, {
                      style: {"width":"100px"},
                      value: record.sensorGroupNameZ,
                      "onUpdate:value": ($event: any) => ((record.sensorGroupNameZ) = $event),
                      dropdownClassName: _unref(dropdownClassName)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(directionList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(getLanguage)(item)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value", "dropdownClassName"]),
                    (!isSingleAxis(record))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _cache[27] || (_cache[27] = _createTextVNode(" X: ")),
                          _createVNode(_component_a_select, {
                            style: {"width":"100px"},
                            value: record.sensorGroupNameX,
                            "onUpdate:value": ($event: any) => ((record.sensorGroupNameX) = $event),
                            dropdownClassName: _unref(dropdownClassName)
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(directionList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)(item)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "dropdownClassName"]),
                          _cache[28] || (_cache[28] = _createTextVNode(" Y: ")),
                          _createVNode(_component_a_select, {
                            style: {"width":"100px"},
                            value: record.sensorGroupNameY,
                            "onUpdate:value": ($event: any) => ((record.sensorGroupNameY) = $event),
                            dropdownClassName: _unref(dropdownClassName)
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(directionList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)(item)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "dropdownClassName"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'disable')
                ? (_openBlock(), _createBlock(_component_a_switch, {
                    key: 5,
                    style: {"width":"60px"},
                    checked: record.disable,
                    "onUpdate:checked": ($event: any) => ((record.disable) = $event),
                    "checked-children": _unref(langObj)['禁用'],
                    "un-checked-children": _unref(langObj)['启用']
                  }, null, 8, ["checked", "onUpdate:checked", "checked-children", "un-checked-children"]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'isUpdateParameter')
                ? (_openBlock(), _createBlock(_component_a_radio_group, {
                    key: 6,
                    value: record.isUpdateParameter,
                    "onUpdate:value": ($event: any) => ((record.isUpdateParameter) = $event)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_radio, { value: true }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)('是')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, { value: false }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)('否')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["value", "onUpdate:value"]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'act')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 7,
                    onClick: ($event: any) => (delSensor(index))
                  }, _toDisplayString(_unref(langObj)['删除']), 9, _hoisted_20))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source"]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              onClick: _cache[20] || (_cache[20] = ($event: any) => (previous())),
              class: "btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('上一步')), 1),
            _createElementVNode("div", {
              onClick: _cache[21] || (_cache[21] = ($event: any) => (submit2(false))),
              class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('保存')), 1),
            _createElementVNode("div", {
              onClick: _cache[22] || (_cache[22] = ($event: any) => (submit2(true))),
              class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('下一步')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (current.value == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(getLanguage)('设备提资信息')) + ":", 1),
          _createElementVNode("div", _hoisted_24, [
            _createTextVNode(_toDisplayString(_unref(getLanguage)('设备是否频繁启停')) + ": ", 1),
            _createVNode(_component_a_radio_group, {
              style: {"margin-left":"20px"},
              value: _unref(isFrequentStart),
              "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => (_isRef(isFrequentStart) ? (isFrequentStart).value = $event : isFrequentStart = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio, { value: true }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)('是')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio, { value: false }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)('否')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(partsList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mt-4 part",
              key: index
            }, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('部件')) + ":", 1),
                _createElementVNode("div", null, _toDisplayString(item?.partName), 1),
                _createElementVNode("div", _hoisted_26, "(" + _toDisplayString(_unref(getLanguage)('请完善部件信息')) + ")", 1)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createTextVNode(_toDisplayString(_unref(getLanguage)('额定转速')) + "(rpm)", 1),
                      _cache[30] || (_cache[30] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(_component_a_input_number, {
                        onChange: ($event: any) => (changeRs($event, item)),
                        style: {"width":"200px"},
                        value: item.params.Rated_speed,
                        "onUpdate:value": ($event: any) => ((item.params.Rated_speed) = $event),
                        controls: false
                      }, null, 8, ["onChange", "value", "onUpdate:value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createTextVNode(_toDisplayString(_unref(getLanguage)('转速范围')), 1),
                      _cache[31] || (_cache[31] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createVNode(_component_a_input_number, {
                        style: {"width":"90px"},
                        value: item.params.startFre,
                        "onUpdate:value": ($event: any) => ((item.params.startFre) = $event),
                        controls: false
                      }, null, 8, ["value", "onUpdate:value"]),
                      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "mx-1" }, "——", -1)),
                      _createVNode(_component_a_input_number, {
                        style: {"width":"90px"},
                        value: item.params.stopFre,
                        "onUpdate:value": ($event: any) => ((item.params.stopFre) = $event),
                        controls: false
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  true
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.segments.columns, (item2, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index2,
                          style: {"width":"200px"},
                          class: "ml-4"
                        }, [
                          _createElementVNode("div", _hoisted_35, [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)(item2)), 1),
                            _cache[33] || (_cache[33] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                          ]),
                          _createElementVNode("div", _hoisted_36, [
                            _createVNode(_component_a_select, {
                              value: item.segments['data' + index2],
                              "onUpdate:value": ($event: any) => ((item.segments['data' + index2]) = $event),
                              style: {"width":"200px"},
                              onChange: ($event: any) => (selectPartParmar(item.segments, index2)),
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.segments['columnsData' + index2], (s, sIndex) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: sIndex,
                                    value: s
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(s), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["value", "onUpdate:value", "onChange", "dropdownClassName"])
                          ])
                        ]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.structures, (structure, index2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "mt-4",
                    key: index2
                  }, [
                    _createElementVNode("div", _hoisted_37, [
                      _cache[34] || (_cache[34] = _createElementVNode("div", null, "—", -1)),
                      _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(getLanguage)('结构')) + ": ", 1),
                      _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(getLanguage)(structure.machineStructureName)), 1),
                      (structure.sensorPlace)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, "——"))
                        : _createCommentVNode("", true),
                      (structure.sensorPlace)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_unref(getLanguage)(structure.sensorPlace)), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_42, "(" + _toDisplayString(_unref(getLanguage)('请完善结构信息，若该结构信息不全，将不会显示该测点的健康指标')) + ")", 1)
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(structure.parmarList, (parmar, index3) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index3,
                          style: {"width":"200px"},
                          class: _normalizeClass({ 'ml-4': index3 !== 0 })
                        }, [
                          _createElementVNode("div", _hoisted_44, _toDisplayString(_unref(getLanguage)(parmar.label)), 1),
                          _createElementVNode("div", _hoisted_45, [
                            (parmar.type === 'select')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                                  _createVNode(_component_a_select, {
                                    value: parmar.value,
                                    "onUpdate:value": ($event: any) => ((parmar.value) = $event),
                                    style: {"width":"200px"},
                                    options: parmar.option,
                                    showSearch: true,
                                    dropdownClassName: _unref(dropdownClassName)
                                  }, null, 8, ["value", "onUpdate:value", "options", "dropdownClassName"])
                                ]))
                              : (parmar.type === 'string')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                    _createVNode(_component_a_input, {
                                      style: {"width":"200px"},
                                      value: parmar.value,
                                      "onUpdate:value": ($event: any) => ((parmar.value) = $event)
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_48, [
                                    _createVNode(_component_a_input_number, {
                                      style: {"width":"200px"},
                                      value: parmar.value,
                                      "onUpdate:value": ($event: any) => ((parmar.value) = $event),
                                      controls: false
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]))
                          ])
                        ], 2))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("div", {
              onClick: _cache[24] || (_cache[24] = ($event: any) => (previous())),
              class: "btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('上一步')), 1),
            _createElementVNode("div", {
              onClick: _cache[25] || (_cache[25] = ($event: any) => (_isRef(isSubmitV) //@ts-ignore
 ? isSubmitV.value = false : isSubmitV = false, submit3())),
              class: "ml-4 btn-default py-1 px-6 cursor-pointer btn-submit"
            }, _toDisplayString(_unref(getLanguage)('保存')), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})