<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
        <div class="w-full flex whiteBg" style="color: #fff;">
            <!-- 左侧 -->
            <div style="width: 270px;">
                <div class="w-full box p-2" style="width: 270px;">
                    <a-radio-group v-model:value="searchModel.type" style="width: 100%;" @change="init">
                        <a-radio v-for="(item, index) in typeList" :key="index" style="display: block;"
                            :value="item.value">{{ item.label }}</a-radio>
                    </a-radio-group>
                   
                </div>

                <div class="box" style="width: 270px;">
                    <a-table style="width: 100%;" size="small" :row-class-name="getRowClassName" :columns="columns"
                        :data-source="dataList" :pagination="pagination" @change="handleTableChange"
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, hideSelectAll: true }"
                        rowKey="_id">
                        <template #bodyCell="{ column, text }">
                            <template v-if="column.dataIndex === 'opt'">
                                <a class="theme-table-link">{{ langObj['下载'] }}</a>
                            </template>

                            <template v-if="column.dataIndex === 'value'">
                                <span v-if="text || text == 0"> {{ text.toFixed(2) }}</span>
                            </template>
                        </template>
                    </a-table>
                </div>

            </div>

            <!-- 右侧 -->
            <div class="flex-1 box ml-4 p-3">
                <div class="w-full flex items-center">
                    <div class="mr-4">{{ langObj['频谱'] }}</div>
                    <a-radio-group @change="getChartData" v-model:value="searchModel.isWashA" button-style="solid">
                        <a-radio :value="false">{{ getLanguage('原始') }}</a-radio>
                        <a-radio :value="true">{{ getLanguage('数据清洗') }}</a-radio>
                    </a-radio-group>
                    <div class="flex-1"></div>
                    <div class="mr-2">{{ langObj['选择范围'] }}:</div>
                    <a-input-number id="inputNumber" v-model:value="searchModel.number" :min="1" :controls="false" />
                    <div class="mx-2">HZ</div>
                </div>

                <div id="chart2A" style="width: 100%; height: 350px;"></div>

                <div class="flex items-center mt-4">
                    <div class="mr-4">{{ langObj['包络解调'] }}</div>
                    <a-radio-group @change="getOtherrChartData" v-model:value="searchModel.isWashB" button-style="solid">
                        <a-radio :value="false">{{ getLanguage('原始') }}</a-radio>
                        <a-radio :value="true">{{ getLanguage('数据清洗') }}</a-radio>
                    </a-radio-group>
                </div>

                <div id="chart2B" style="width: 100%; height: 350px;"></div>

            </div>

        </div>
    </a-config-provider>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../../common/lang";
import { getLanguage } from "../../../common/translate";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()


let typeList = [
    { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
    { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
    // {label : '温度', value: 2},
    { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
    { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
    { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
    { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
    { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
    { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];

const columns = [
    {
        title: langObj.value["时间"],
        dataIndex: "date",
    },
    {
        title: langObj.value["值"],
        dataIndex: "value",
    },
];


let dataList: any = ref([])
let chartA: any
let chartB: any
let visible = ref(true)

const props: any = defineProps({
    sensorInfo: null,
});
let sensorInfo: any = ref()
sensorInfo.value = props.sensorInfo
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);
const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
}));
let searchModel: any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
    number: 1000,
    isWashA: false,
    isWashB: false,
})

let dataSave: any

onMounted(() => {
    init()
   
})

const init = () => {
    if (!sensorInfo.value || !sensorInfo.value.id) return
    getTrendListAll()
}

let selectedRowKeys: any = ref([])
const onSelectChange = (ev: any) => {
    if (ev.length) {
        selectedRowKeys.value = [ev[ev.length - 1]]
    } else {
        selectedRowKeys.value = []
    }
    getChartData()
}


const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};

//获取所有时间点数据
const getTrendListAll = async () => {
    dataList.value = []
    selectedRowKeys.value = []
    searchModel.value.total = 0
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId,
            sensorId: sensorInfo.value.id,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: typeList.find((p: any) => p.value === searchModel.value.type)?.type
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/singles', config)

    if (result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count
    }

    if (localStorage.getItem('chartInfo')) {
        let chartInfo = JSON.parse(localStorage.getItem('chartInfo') || null)

        if (chartInfo) {
            onSelectChange([chartInfo._id])
        }
    }
}

let jietiaoUrl = ref('')
const getChartData = async () => {
    if (selectedRowKeys.value.length) {
        chartA && chartA.clear()
        // let info = dataList.value.find((p:any) => p._id === selectedRowKeys.value[0])
        let t = typeList.find((p: any) => p.value === searchModel.value.type)?.type
        if (!t) return
        let info: any = {}
        let chartInfo = JSON.parse(localStorage.getItem('chartInfo') || null)

        if (chartInfo) {
            info = chartInfo
            localStorage.removeItem('chartInfo')
        } else {
            info = dataList.value.find((p: any) => p._id === selectedRowKeys.value[0])
        }

        let config: any = {
            params: {
                sensorId: sensorInfo.value.id,
                date: info.date,
                // date: '2023/09/14 17:00:00',
                type: t,
                latest: true
            },
            headers: {
                requestId: uuidv4(),
            },
        };

        let result = await request.get('/api/external/singles/info', config)
        if (!result || !result.data || !result.data.url) {
            chartA && chartA.clear()
            return
        }
        jietiaoUrl.value = result.data.url

        // info.url = "http://source.freqx.com/singles/632d1ec9735daf1ba76d4f9f/20231124/null_633434ab735daf1ba76d55e1_HZ_20231124_140000.Single"
        let res = await request.post('/draw/api/fft-sort', { url: [result.data.url], isWashed: searchModel.value.isWashA })
        if (res && res.data) {
            visible.value = false
            visible.value = true
            let fftData = getChartDataTool(res?.data)
            dataSave = fftData
            setTimeout(() => {
                initChart()
            }, 200);
        } else {
            chartA && chartA.clear()
        }

    } else {
        chartA && chartA.clear()
    }
}


// 绘图
let markX: any
let startFre1:any = ref()
let endFre1:any = ref()
const initChart = () => {
    let { axisX, axisY } = dataSave
    let axisXN = axisX.map((number: any) => number.toString());

    const ec = echarts as any;
    if (!document.getElementById('chart2A')) return
    chartA = ec.init(document.getElementById('chart2A'));
    let option = {
        color: theme.value == 'white' ? ["#274CE0"] : ["#00FFF4"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [
            {
                id: "dataZoomX",
                type: "slider",
                xAxisIndex: [0],
                filterMode: "filter",
                bottom: "0",
            },
            {
                id: "dataZoomY",
                type: "slider",
                yAxisIndex: [0],
                filterMode: "filter",
                left: "30",
            },
            { type: 'inside' }
        ],
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                showMaxLabel: true
            },
            splitLine: {
                show: false,
                lineStyle: {
                    opacity: 0.3
                }
            },
            data: axisXN,
            name: 'hz',
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
            },
            name: searchModel.value.type >= 1 && searchModel.value.type <= 5 ? 'm/s²' : 'mm/s'
        },
        series: [
            {
                data: axisY,
                type: 'line'
            }
        ]
    };

    chartA && chartA.setOption(option)

    if (axisX && axisX.length) {
        startFre1.value = axisX[0]
        endFre1.value = axisX[axisX.length - 1]
        getOtherrChartData()
    }

    chartA.getZr().off('click');
    chartA.getZr().on('click', (params: { offsetX: any; offsetY: any; }) => {
        var pointInPixel = [params.offsetX, params.offsetY];
        if (chartA.containPixel('grid', pointInPixel)) {
            /*此处添加具体执行代码*/
            var pointInGrid = chartA.convertFromPixel({ seriesIndex: 0 }, pointInPixel);
            //X轴序号
            var xIndex = pointInGrid[0];
            //获取当前图表的option
            var op = chartA.getOption();
            //获得图表中我们想要的数据
            let nowDate = op.xAxis[0].data[xIndex];
            markX = nowDate

            let number = Number(markX)
            if (isNaN(number)) {
                return
            }
            let markLineList: any = []
            let numberA = number - (searchModel.value.number / 2)
            let numberB = number + (searchModel.value.number / 2)
            let numberAS: any
            let numberBS: any

            let numberAL = axisX.filter((p: any) => p <= numberA)
            if (numberAL && numberAL.length) {
                numberAS = numberAL[numberAL.length - 1]
                markLineList.push({
                    "name": numberAL[numberAL.length - 1].toString(),
                    "xAxis": numberAL[numberAL.length - 1].toString(),
                    "label": {
                        "show": true,
                        "color": theme.value == 'white' ? '#333' : "#fff",
                        "formatter": "{b}"
                    },
                    "lineStyle": {
                        "color": "blue"
                    }
                })
            } else {
                if (axisX && axisX.length) {
                    numberAS = axisX[0]
                }
            }

            let numberBL = axisX.filter((p: any) => p >= numberB)
            if (numberBL && numberBL.length) {
                numberBS = numberBL[0]
                markLineList.push({
                    "name": numberBL[0].toString(),
                    "xAxis": numberBL[0].toString(),
                    "label": {
                        "show": true,
                        "color": theme.value == 'white' ? '#333' : "#fff",
                        "formatter": "{b}"
                    },
                    "lineStyle": {
                        "color": "blue"
                    }
                })
            } else {
                if (axisX && axisX.length) {
                    numberBS = axisX[axisX.length - 1]
                }
            }

            markLineList.push({
                "name": nowDate,
                "xAxis": nowDate,
                "label": {
                    "show": true,
                    "color": theme.value == 'white' ? '#333' : "#fff",
                    "formatter": "{b}"
                },
                "lineStyle": {
                    "color": "red"
                }
            })



            option.series[0].markLine = {
                data: markLineList,
                silent: false
            }

            chartA && chartA.setOption(option)
            startFre1.value = numberAS
            endFre1.value = numberBS
            getOtherrChartData()
        }
    })


}


const getOtherrChartData = async () => {
    chartB && chartB.clear()
    let startFre = startFre1.value
    let endFre = endFre1.value
    // info.url = "http://source.freqx.com/singles/632d1ec9735daf1ba76d4f9f/20231124/null_633434ab735daf1ba76d55e1_HZ_20231124_140000.Single"
    let form = {
        startFre,
        endFre,
        url: jietiaoUrl.value
    }
    let result = await request.post('/draw/api/fft-hht-sort', form)
    if (result && result.data) {
        let fftData = getChartDataTool(result?.data)
        initChartB(fftData)
    } else {
        chartB && chartB.clear()
    }

}

//绘图B
const initChartB = (data: any) => {
    let { axisX, axisY } = data
    let axisXN = axisX.map((number: any) => number.toString());

    const ec = echarts as any;
    if (!document.getElementById('chart2B')) return
    chartB = ec.init(document.getElementById('chart2B'));
    var maxX = Number(axisXN[axisXN.length - 1])
    var end = 100
    if (maxX > 1000) {
        end = 1000 / maxX * 100
    }
    let option = {
        color: theme.value == 'white' ? ["#274CE0"] : ["#00FFF4"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [
            {
                id: "dataZoomX",
                type: "slider",
                xAxisIndex: [0],
                filterMode: "filter",
                bottom: "0",
                start: 0,
                end: end,
            },
            {
                id: "dataZoomY",
                type: "slider",
                yAxisIndex: [0],
                filterMode: "filter",
                left: "30",
            },
            { type: 'inside' }
        ],
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                showMaxLabel: true
            },
            splitLine: {
                show: false,
                lineStyle: {
                    opacity: 0.3
                }
            },
            data: axisXN,
            name: 'hz'
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
            },
            name: typeList.find((p: any) => p.value === searchModel.value.type)?.unit

        },
        series: [
            {
                data: axisY,
                type: 'line'
            }
        ]
    };

    chartB && chartB.setOption(option)
}



</script>

<style lang="less" scoped>
/deep/ .ant-picker {
    background-color: #07249933 !important;
}

.box {
    border: 1px solid #0d53b7cc;
    // background: #07249933;
    width: 250px;
    background: url("@/assets/screen/p_left_bodyb_bg.png") no-repeat;
    background-size: 100% 100%;
}

.btn1 {
    padding: 3px 10px;
}

/deep/ .ant-table {
    color: #fff
}

/deep/ .ant-table-thead .ant-table-cell {
    background-color: #020F46;
    color: #fff;
}

/deep/ table tbody tr:hover>td {
    background-color: #020F46 !important;
}

/deep/ .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #020F46 !important;
}

/deep/ .ant-table-tbody>tr>td {
    transition: 0s;
}

/deep/ .ant-table-tbody>tr.ant-table-row-selected>td {
    background: transparent;
    border-color: #fff;
}

/deep/ .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder {
    background: #020F46;
}

/deep/ .ant-empty-description {
    color: #fff;
}
</style>
