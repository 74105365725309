<template>
  <div v-if="picList?.length > 0">
    <div v-if="type === 1" style="width: 350px">
      <div class="w-full mt-2 flex" style="justify-content: center">
        <a-image
        style="max-width: 400px;max-height: 180px;"
          v-if="selectPic"
          :src="selectPic"
          alt=""
        />
      </div>
      <div class="mt-1 w-full flex items-center">
        <div class="cursor-pointer" @click="switchover(false)">
          <left-outlined class="opt8 size20" />
        </div>
        <div class="flex-1 flex items-center">
          <img
            class="flex-1"
            v-for="(item, index) in picList"
            :key="index"
            style="height: 48px; max-width: 48px"
            :src="item.url"
            alt=""
          />
        </div>
        <div class="cursor-pointer" @click="switchover(true)">
          <right-outlined class="opt8 size20" />
        </div>
      </div>
    </div>

    <div class="w-full" v-if="type === 2">
      <div class="w-full mt-2 flex" style="justify-content: center;">
        <a-image
        style="max-width: 400px;max-height: 180px;"
          v-if="selectPic"
          :src="selectPic"
          alt=""
        />
        
      </div>
      <div class="mt-1 w-full flex items-center">
        <div @click="switchover(false)" class="cursor-pointer">
          <left-outlined class="opt8 size20" />
        </div>
        <div class="flex-1 flex items-center">
          <img
            class="flex-1 ml-1"
            v-for="(item, index) in picList"
            :key="index"
            style="height: 48px; max-width: 48px"
            :src="item.url"
            alt=""
          />
        </div>
        <div @click="switchover(false)" class="cursor-pointer">
          <right-outlined class="opt8 size20" />
        </div>
      </div>
    </div>

    <div class="mt-4 flex items-center" v-if="type === 3">
      <div class="cursor-pointer" @click="switchover(false)">
        <left-outlined style="font-size: 35px; opacity: 0.5" />
      </div>
     

      <div class="flex-1 flex" style="justify-content: center;height: 180px;">
        <a-image class="pic" :src="selectPic" alt="" style="max-width: 400px;max-height: 180px;"/>
      </div>

      <div class="cursor-pointer" @click="switchover(true)">
        <right-outlined style="font-size: 35px; opacity: 0.5" />
      </div>
    </div>

    <div class="picBox mt-4 flex items-center" v-if="type === 4">
      <div class="cursor-pointer" style="margin-left: 2%;" @click="switchover(false)">
        <left-outlined style="font-size: 1.2vw; opacity: 0.5" />
      </div>

      <div class="flex-1">
        <div class="flex" style="justify-content: center;width: 70%;height:14vh;margin: 0 auto;">
          <a-image :src="selectPic" alt=""  style="max-width:100%;max-height: 100%;"/>
        </div>
        <div style="text-align: center">{{ selectName }}</div>
      </div>

      <div class="cursor-pointer" style="margin-right: 2%;" @click="switchover(true)">
        <right-outlined style="font-size: 1.2vw; opacity: 0.5" />
      </div>

      <div class="page">
        <div>{{ selectIndex + 1 }} / {{ picList.length }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";

let type = ref(1);
let picList: any = ref([]);

const props: any = defineProps({
  type: null,
  picList: [],
});

type.value = props.type;
picList.value = props.picList;
let selectPic = ref("");
let selectName = ref("");
let selectIndex = 0;

onMounted(() => {
  init();
});

watch(
  () => props.picList,
  (newValue, oldValue) => {

    picList.value = props.picList;
    setTimeout(() => {
      init();
    }, 200);
  }
);

const init = async () => {
  if (!picList.value) return;
  selectPic.value = "";
  picList.value = picList.value.filter((p: any) => p.url);
 
  if (picList.value && picList.value.length) {
    selectPic.value = picList.value[0].url;
    selectName.value = picList.value[0].name;
    selectIndex = 0;
  }

};

const switchover = (ev: any) => {
  //true下一个  false上一个
  let length = picList.value.length;
  if (length == 0) {
    return;
  }
  if (ev) {
    if (selectIndex < length - 1) {
      selectIndex = selectIndex + 1;
    } else {
      selectIndex = 0;
    }
  } else {
    if (selectIndex == 0) {
      selectIndex = length - 1;
    } else {
      selectIndex = selectIndex - 1;
    }
  }
  selectPic.value = picList.value[selectIndex].url;
  selectName.value = picList.value[selectIndex].name;
};
</script>

<style lang="less" scoped>
.picBox {
  width: 100%;
  min-height: 18vh;
  // border: 1px solid #0d53b7cc;
  // background: #07249933;
  // padding: 20px 10px 0px 10px;
  position: relative;

  .page {
    position: absolute;
    right: 45%;
    // margin-right: -22.5%;
    bottom: -5%;
    width: 10%;
    height: 16px;
    border-radius: 2px;
    background: #0000004d;

    div {
      opacity: 0.6;
      line-height: 16px;
      text-align: center;
    }
  }

  .pic {
    height: 220px !important;
    width: 400px !important;
  }
  
}
</style>
