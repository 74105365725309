import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/chat/chat-role-btn1.png'


const _hoisted_1 = { class: "chat-role-selector text-primary" }
const _hoisted_2 = { class: "selector-content" }
const _hoisted_3 = { class: "selector-title" }
const _hoisted_4 = { class: "selector-description" }
const _hoisted_5 = { class: "selector-tiles" }
const _hoisted_6 = { class: "selector-tile-title" }
const _hoisted_7 = ["alt"]

import { getLanguage } from "../../../common/translate";
type ChatRole = 'common' | 'diagnostic';


export default /*@__PURE__*/_defineComponent({
  __name: 'chat-role-selector',
  emits: ["chat-role-changed"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const selectRole = (role: ChatRole) => {
    emit('chat-role-changed', role);
};

return (_ctx: any,_cache: any) => {
  const _component_description = _resolveComponent("description")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(getLanguage)('我是天机智能AI，很高兴为您服务')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(getLanguage)('您好，我是天机智能AI，很乐意向您解答关于设备故障诊断及维护相关问题，请点击下方按钮，选择我扮演的角色。')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "selector-tile",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (selectRole('common')))
        }, [
          _createElementVNode("p", _hoisted_6, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: _unref(getLanguage)('维保专家图标')
            }, null, 8, _hoisted_7),
            _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('维保专家')), 1)
          ]),
          _createVNode(_component_description, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(getLanguage)('为您提供设备维护保养建议、设备故障推断、工业领域问题解答、典型设备故障维修建议...')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})