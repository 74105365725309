<template>
   <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div class="bule_list_page theme-common">
    <a-layout-content style="padding: 30px 30px">
      <a-form layout="inline">
        <a-form-item :label="langObj['关键词']">
          <a-input v-model:value="formState.keyword" placeholder="" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="formState.skip = 1, search()">{{langObj['搜索']}}</a-button>
        </a-form-item>
        <!-- <a-form-item>
          <a-button v-if="isEditable" type="primary" @click="add">
            <template #icon><plus-outlined /></template>{{langObj['新增']}}</a-button
          >
        </a-form-item> -->
      </a-form>
      <div class="main">
        <a-table
          bordered
          :emptyText="langObj['暂无数据']"
          :columns="columns"
          :data-source="dataSource"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          size="small"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'pictures'">
              <span v-for="item in text">
                <a-image :src="item.url" style="max-width: 100px"></a-image>
              </span>
            </template>


            <template v-if="column.dataIndex === 'act'">
              <a-button v-if="isEditable" type="primary" class="plft" @click="editThis(record)"
                ><edit-outlined />{{langObj['编辑']}}</a-button
              >

              <a-popconfirm :title="langObj['确定删除此条数据么']+'?'" :ok-text="langObj['确定']" :cancel-text="langObj['取消']" @confirm="deleteThis(record)">
                <a-button v-if="isShowDel" style="margin-left: 10px;" type="primary" danger><delete-outlined />{{langObj['删除']}}</a-button>
              </a-popconfirm>

            </template>
          </template>
        </a-table>
      </div>
    </a-layout-content>
    <a-modal
      :width="850"
      :maskClosable="false"
      v-model:visible="modalState.visible"
      :title="modalState.title"
      :confirm-loading="modalState.confirmLoading"
      :cancelText="langObj['取消']"
      :okText="langObj['确认']"
      @ok="handleOk"
    >
      <a-form
        ref="formStateRef"
        :model="formMachine"
        name="basic"
        autocomplete="off"
        :label-col="{ style: { width: '180px' } }"
      >
        <a-form-item :label="langObj['一级分类']" name="FirstCategory">
          <a-input
            v-model:value="formMachine.FirstCategory"
            :placeholder="langObj['请输入一级分类']"
          />
        </a-form-item>
        <a-form-item :label="langObj['二级分类']" name="SecondCategory">
          <a-input
            v-model:value="formMachine.SecondCategory"
            :placeholder="langObj['请输入二级分类']"
          />
        </a-form-item>
        <a-form-item :label="langObj['三级分类']" name="ThirdCategory">
          <a-input
            v-model:value="formMachine.ThirdCategory"
            :placeholder="langObj['请输入三级分类']"
          />
        </a-form-item>
        <a-form-item :label="langObj['缩写']" name="ThirdCategoryAbbreviation">
          <a-input
            v-model:value="formMachine.ThirdCategoryAbbreviation"
            :placeholder="langObj['请输入缩写']"
          />
        </a-form-item>
        <a-form-item :label="langObj['模型图']" name="pic">
          <a-upload
            v-model:file-list="fileList"
            list-type="picture-card"
            @preview="handlePreview"
            :custom-request="customRequest"
            :auto-upload="false"
            accept="image"
            :max-count="6"
          >
            <div v-if="!fileList || fileList.length < 1">
              <plus-outlined />
              <div style="margin-top: 8px">{{langObj['上传']}}</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :title="previewTitle"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { UploadProps } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
const router = useRouter();
const loading = ref(false);
const formRef = ref();
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
let factoryId = "";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { getLanguage } from "@/common/translate";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()
operateLogs("访问设备管理");
/**操作日志记录 */
const formState = reactive({
  keyword: "",
  groupId: "",
  skip: 1,
  take: 10,
});
let total = ref(0);
let pagination = computed(() => ({
  total: total.value,
  current: formState.skip,
  pageSize: formState.take,
}));

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current;
  formState.take = pagination.pageSize
  search();
};
let formMachine:any = ref({
  FirstCategory: '',
  SecondCategory: '',
  ThirdCategory: '',
  ThirdCategoryAbbreviation: '',
});

const modalState = reactive({
  visible: false,
  title:langObj.value["新增"],
  confirmLoading: false,
  roleName: "",
  meun: [],
});

const formStateRef = ref();
const clearValidate = (name: any) => {
  if (formStateRef.value) {
    formStateRef.value.clearValidate(name);
  }
};
const dataSource = ref([]);
const columns = [
  {
    title: langObj.value["一级分类"],
    dataIndex: "FirstCategory",
    align: "center",
  },
  {
    title: langObj.value["二级分类"],
    dataIndex: "SecondCategory",
    align: "center",
  },
  {
    title: langObj.value["三级分类"],
    dataIndex: "ThirdCategory",
    align: "center",
  },
  // {
  //   title: getLanguage("3D模型图"),
  //   dataIndex: "pictures3D",
  //   align: "center",
  // },
  {
    title: getLanguage("2D模型图"),
    dataIndex: "pictures",
    align: "center",
  },
  // {
  //   title: langObj.value["操作"],
  //   dataIndex: "act",
  //   with: 120,
  //   align: "center",
  // },
];
// 新增
const add = () => {
  formStateRef.value?.clearValidate();
  modalState.title = langObj.value["新增"];
  formMachine.value = {
    FirstCategory: '',
    SecondCategory: '',
    ThirdCategory: '',
    ThirdCategoryAbbreviation: '',
  };
  fileList.value = [];
  modalState.visible = true;
};


const editThis = (val: any) => {
  formStateRef.value?.clearValidate();
  modalState.title = langObj.value["编辑"];
  formMachine.value = JSON.parse(JSON.stringify(val));
  fileList.value = [];
  if (formMachine.value.pictures?.length > 0) {
    formMachine.value.pictures.map((p: any) => {
      p.type = "";
    });
    fileList.value = formMachine.value.pictures;
  } else {
  }
  modalState.visible = true;
};
let fileList = ref<any>([]);

// 保存
const handleOk = () => {
  formStateRef.value?.clearValidate();
  formStateRef.value
    .validate()
    .then((result: any) => {
      if (fileList.value && fileList.value.length) {
        formMachine.value.pictures = fileList.value.map((p: any) => ({
          url: p.url,
          type: 15,
        }));
      }

      console.log('formMachine.value', formMachine.value)

      if (formMachine.value.id) {
        request
          .put("/api/machine-type", formMachine.value)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      } else {
        request
          .post("/api/machine-type", formMachine.value)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 删除
const deleteThis = (ev:any) => {
  console.log('ev', ev.id);
  const config = {
    params: {
      id: ev.id
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request
   .delete("/api/machine-type", config )
   .then((res) => {
      if (res) {
        message.success("操作成功");
        formState.skip = 1;
        search();
      } else {
        message.warning("操作失败");
      }
    });
}

// 查询
const search = () => {
  const config = {
    params: formState,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/machine-type/list", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      total.value = res.data.count;
    }
  });
};

const selectedFactory = (value: any, selectedOptions: any) => {
  console.log(value);
  console.log(selectedOptions);
  formMachine.value.groupId = value[value.length - 1];
  formMachine.value.factoryId = value[value.length - 2];

  if (value.length > 2) {
    formMachine.value.parentFactoryId = value[value.length - 3];
  }
};

let isShowDel:any = ref(false)
const getMemberInfo = async() => {
  let result = await request.get("/api/members/info")
  if (result?.data?.admin) {
    isShowDel.value = true
  }
}
let isEditable:any = ref(false)
// 在组件加载完成后执行一些操作
onMounted(async () => {
  isEditable.value = true
  getMemberInfo()
  search();
});

let groupList = ref<any>();

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");

const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};
</script>
<style scoped>
.main {
  margin-top: 30px;
}
.bule_list_page {
  height: calc(100vh - 100px) !important;
}
</style>
