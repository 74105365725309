<template>
    <div class="chat-role-selector text-primary">
        <div class="selector-content">
            <p class="selector-title">{{ getLanguage('我是天机智能AI，很高兴为您服务') }}</p>
            <p class="selector-description">
                {{ getLanguage('您好，我是天机智能AI，很乐意向您解答关于设备故障诊断及维护相关问题，请点击下方按钮，选择我扮演的角色。') }} 
            </p>
            <div class="selector-tiles">
                <div class="selector-tile" @click="selectRole('common')">
                    <p class="selector-tile-title">
                        <img src="@/assets/chat/chat-role-btn1.png" :alt="getLanguage('维保专家图标')">
                        {{ getLanguage('维保专家') }}
                    </p>
                    <description>{{ getLanguage('为您提供设备维护保养建议、设备故障推断、工业领域问题解答、典型设备故障维修建议...') }}
                    </description>
                </div>
                <!--
                <div class="selector-tile" @click="selectRole('diagnostic')">
                    <p class="selector-tile-title">
                        <img src="@/assets/chat/chat-role-btn2.png" alt="诊断专家图标">
                        诊断专家
                    </p>
                    <description>为您分析设备波形图谱，分析设备运行状态，可根据设备实际运行情况，提供设备故障原因、维修建议...
                    </description>
                </div>
            -->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getLanguage } from "../../../common/translate";
type ChatRole = 'common' | 'diagnostic';

const emit = defineEmits<{
    (event: 'chat-role-changed', role: ChatRole): void
}>();

const selectRole = (role: ChatRole) => {
    emit('chat-role-changed', role);
};
</script>

<style lang="less" scoped>
.chat-role-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;

    .selector-content {
        max-width: 800px;
        text-align: center;
    }

    .selector-title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .selector-description {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
    }
}


.selector-tiles {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.selector-tile {
    flex: 0.35;
    padding: 8px;
    border: 1px solid #0d53b7cc;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(to bottom, #052174, #021357);

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    description {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        line-height: 1.5;
    }
}

.selector-tile-title {
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: 900;
    background: linear-gradient(to bottom, #ffffff, #7cb8ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 90px;
        height: 90px;
        object-fit: contain;
    }

}
</style>
