<template>
  <div class="w-full theme-bg theme-machine theme-common"
       style="background-color: #000a31; color: #fff;overflow-y: hidden; height: calc(100vh - 100px);">
  <div class="w-full mt-4 flex px-7 py-2 " style="height:100%;overflow-y: hidden;">
      <!-- 左侧搜索 -->
      <div style="width: 12%;">
        <machineListLeftComponent @selectMachine="selectMachine" @selectGroup="selectGroup" :type="3" :mode="1">
        </machineListLeftComponent>
      </div>

      <!-- 右侧内容 -->
      <div class="flex-1 ml-7" style="overflow-y:scroll" v-if="selectType == 1">
        <div class="center-tit-bg">
          <span style="font-size: 24px">{{ machineInfo.machineName }}</span>
        </div>

        <div class="w-full flex mt-2">
          <!-- 左 -->
          <div class="mr-8" style="min-width: 476px;width:20%;position: relative;z-index: 1000;">
           
            <!-- 设备诊断 -->
            <div class="zd_wrap " style="width: 100%">
                <div class="title flex items-center">
                        <div class="p-text-color2 ml-10">{{ langObj['设备诊断'] }}</div>
                        <div class="flex-1"></div>
                    </div>
                
                  <div class="bg_wrap">
                    <div class="w-full flex items-center justify-between whiteBg">
                      <div class="flex-1"></div>
                      
                      <div @click="selectTag(2)" class="btn1 cursor-pointer" :class="selecrRandar === 2 ? 'btn1BgSelected' : ''"
                          :style="{ color: selecrRandar === 2 ? '#fff' : '' }" style="margin-right: 10px">
                        {{ langObj['健康指标'] }}
                      </div>
                      <div @click="selectTag(1)" class="btn1 cursor-pointer" :class="selecrRandar === 1 ? 'btn1BgSelected' : ''"
                          :style="{ color: selecrRandar === 1 ? '#fff' : '' }">
                        {{ langObj['振动指标'] }}
                      </div>
                     
                      <!-- <div @click="selectTag(3)" class="btn1 cursor-pointer" style="margin-left: 10px"
                          :class="selecrRandar === 3 ? 'btn1BgSelected' : ''"
                          :style="{ color: selecrRandar === 3 ? '#fff' : '' }">
                        {{ langObj['分数趋势'] }}
                      </div> -->
                      <div class="flex-1"></div>
                    </div>

                    <div class="w-full flex">
                      <div class="flex-1"></div>
                      <div v-if="visibleChart2" id="chartMachienB" style="width: 100%; height: 240px"></div>
                      <div class="flex-1"></div>
                    </div>
                  </div>
            </div>

             <!-- 诊断趋势 -->
             <div class="zd_wrap mt-2" style="width: 100%">
                <div class="title flex items-center">
                        <div class="p-text-color2 ml-10">{{ langObj['诊断趋势'] }}</div>
                        <div class="flex-1"></div>
                        <div class="flex-1"></div>
                        <a-dropdown>
                            <div class="text-gray-50 flex items-center p-text-right" @click.prevent>
                            
                                {{scoreTime==1?langObj["近一周"]:langObj["近一月"] }}
                                <CaretDownOutlined style="font-size: 16px;padding-left: 5px;" />
                            </div>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item key="1" @click="getScore(1)">
                                        {{langObj["近一周"] }}
                                    </a-menu-item>
                                    <a-menu-item key="2" @click="getScore(2)">
                                        {{langObj["近一月"] }}
                                    </a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown>
                    </div>
                
                  <div class="bg_wrap">
                    
                    
                      <div  id="chartMachienB2" style="width: 100%; height: 260px"></div>
                     
                  </div>
            </div>


            <!-- 设备状态占比 -->
            <div class="w-full mt-2">
             
                <div class="title flex items-center">
                        
                    <div class="p-text-color2 ml-10">{{ langObj['设备状态占比'] }}</div>
                    <div class="flex-1"></div>
                    <a-dropdown>
                        <div class="text-gray-50 flex items-center p-text-right" @click.prevent>
                        
                            {{machineStatusTime==1?langObj["近一周"]:langObj["近一月"] }}
                            <CaretDownOutlined style="font-size: 16px;padding-left: 5px;" />
                        </div>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="1" @click="getMachineStatusTime(1)">
                                    {{langObj["近一周"] }}
                                </a-menu-item>
                                <a-menu-item key="2" @click="getMachineStatusTime(2)">
                                    {{langObj["近一月"] }}
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                  </div>
              






              <div class="w-full flex bg_wrap">
                <div class="flex-1 flex flex-col" style="height: 100%">
                  <div class="flex-1">
                    <div v-if="visibleChart" id="chartAM" style="height: 200px; width: 200px"></div>
                  </div>

                  <div class="w-full" >
                    {{ langObj['累计运行时间'] }}：<span style="color: #4efaee">{{
                      conditionTotal.wkStatsu
                    }}</span>
                  </div>
                </div>

                <div class="ml-4">
                  <div>
                    <div class="flex items-center" style="font-size: 16px">
                      <div class="box4" style="background-color: #4efaee"></div>
                      <div class="ml-2"> {{ langObj['运行占比'] }} :</div>
                      <div class="ml-2">{{ conditionTotal.wkStatus1Rate }}%</div>
                    </div>
                    <div class="flex items-center" style="font-size: 13px">
                      <div style="width: 12px"></div>
                      <div class="ml-2 opt8">{{ langObj['运行时间'] }}:</div>
                      <div class="ml-2 opt8">
                        {{ conditionTotal.wkStatus1Date }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex items-center" style="font-size: 16px">
                      <div class="box4" style="background-color: #1cc48b"></div>
                      <div class="ml-2">{{ langObj['健康占比'] }}:</div>
                      <div class="ml-2">{{ conditionTotal.condition1Rate }}%</div>
                    </div>
                    <div class="flex items-center" style="font-size: 13px">
                      <div style="width: 12px"></div>
                      <div class="ml-2 opt8">{{ langObj['健康时间'] }}:</div>
                      <div class="ml-2 opt8">
                        {{ conditionTotal.condition1Date }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex items-center" style="font-size: 16px">
                      <div class="box4" style="background-color: #ddd246"></div>
                      <div class="ml-2">{{ langObj['可用占比'] }}:</div>
                      <div class="ml-2">{{ conditionTotal.condition2Rate }}%</div>
                    </div>
                    <div class="flex items-center" style="font-size: 13px">
                      <div style="width: 12px"></div>
                      <div class="ml-2 opt8">{{ langObj['可用时间'] }}:</div>
                      <div class="ml-2 opt8">
                        {{ conditionTotal.condition2Date }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex items-center" style="font-size: 16px">
                      <div class="box4" style="background-color: #cb6a34"></div>
                      <div class="ml-2">{{ langObj['警戒占比'] }}:</div>
                      <div class="ml-2">{{ conditionTotal.condition3Rate }}%</div>
                    </div>
                    <div class="flex items-center" style="font-size: 13px">
                      <div style="width: 12px"></div>
                      <div class="ml-2 opt8">{{ langObj['警戒时间'] }}:</div>
                      <div class="ml-2 opt8">
                        {{ conditionTotal.condition3Date }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex items-center" style="font-size: 16px">
                      <div class="box4" style="background-color: #b81212"></div>
                      <div class="ml-2">{{ langObj['故障占比'] }}:</div>
                      <div class="ml-2">{{ conditionTotal.condition4Rate }}%</div>
                    </div>
                    <div class="flex items-center" style="font-size: 13px">
                      <div style="width: 12px"></div>
                      <div class="ml-2 opt8">{{ langObj['故障时间'] }}:</div>
                      <div class="ml-2 opt8">
                        {{ conditionTotal.condition4Date }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 中 -->
          <div class="flex-1" >
            <div class="flex  w-full mb-3 mt-4">
              <div  class="flex items-center center_img_wrap">
                <img src="@/assets/screen/m_state_bg.png"/>
                <div class="ml-2 center_text">
                  <div>{{getLanguage("设备健康状态",language)}}</div>
                  <div class="w-full" :style="{ color: machineInfo?.condition?.color }"
                      style="font-size: 24px;">
                    {{ langObj[machineInfo?.condition?.label] }}
                  </div>
                </div>
              </div>

              <div  class="flex items-center center_img_wrap">
                <img src="@/assets/screen/m_run_bg.png"/>
                <div class="ml-2 center_text">
                  <div>{{getLanguage("设备运行状态",language)}}</div>
                  <div class="w-full" :style="{ color: machineInfo?.status?.color }"
                      style="font-size: 24px;">
                    {{ langObj[machineInfo?.status?.label] }}
                  </div>
                </div>
              </div>

              <div  class="flex items-center center_img_wrap">
                <img src="@/assets/screen/m_zd_bg.png"/>
                <div class="ml-2 center_text">
                  <div>{{getLanguage("智能诊断报警",language)}}</div>
                  <div class="w-full" style="font-size: 24px;color:#0C79FF">
                   {{znZdWarnNum}}{{langObj['条'] }}
                  </div>
                </div>
              </div>

              <div  class="flex items-center center_img_wrap">
                <img src="@/assets/screen/m_mx_bg.png"/>
                <div class="ml-2 center_text">
                  <div>{{getLanguage("平均故障间隔",language)}}({{langObj['小时'] }})</div>
                  <div class="w-full"
                      style="font-size: 24px;color:#2DCDFF">
                    {{ machineInfo?.workStatus?.faultIntervalTimes?.toFixed(2) || 0.00 }}
                  </div>
                </div>
              </div>



            </div>
            <div class="w-full flex mt-1">
              <div class="flex-1"></div>
              <div class="flex items-center mr-8">
                <div class="circle" style="background: #1cc48b"></div>
                <div class="ml-2">{{ langObj['健康'] }}</div>
                <div class="circle ml-8" style="background: #ddd246"></div>
                <div class="ml-2">{{ langObj['可用'] }}</div>
                <div class="circle ml-8" style="background: #cb6a34"></div>
                <div class="ml-2">{{ langObj['警戒'] }}</div>
                <div class="circle ml-8" style="background: #b81212"></div>
                <div class="ml-2">{{ langObj['故障'] }}</div>
              </div>
            </div>
            <d3d-viewer :config3d="config_3d" :machine-id="machine_id" v-if="config_3d.type === '3d'" style="margin:auto auto auto -200px" :width="1200" :height="800"></d3d-viewer>
            <div id="yulan" v-if="config_3d && config_3d.img && config_3d.type !== '3d'"  style="
                margin: auto;
                padding: 20px;
                width: 800px;
                height: 600px;
                position: relative;
                top:5%;
                
                ">
              <img :src="config_3d?.picture" style="position: absolute" :style="{
                width: config_3d?.img?.width + 'px',
                height: config_3d?.img?.height + 'px',
                top: config_3d?.img?.y + 'px',
                left: config_3d?.img?.x + 'px',
                transform: 'rotate(' + config_3d?.img?.rotate + 'deg)',
              }" />
              <!-- 加载标点 -->
              <div v-for="(item, index) in config_3d?.marks" :key="index">
                <div class="d3_point" v-if="item.bind.indexOf('a-') > -1"
                    :style="{ top: item.y + 'px', left: item.x + 'px' }"></div>
              </div>
              <!-- 加载标签 -->
              <div v-for="(item, index) in config_3d?.marks" :key="index">
                <div class="d3_bq" v-if="item.bind.indexOf('b-') > -1" :style="{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(item.condition),
                  'box-shadow': getShadow(item.condition),
                }" style="width: 225px; height: 100px">
                  <div class="w-full" style="padding: 5px 10px">
                    <div class="w-full flex">
                      <div>{{ item.sensorPlace }}</div>
                      <div class="flex-1"></div>
                      <div :style="{ 'background-color': getColor2(item.condition) }"
                          style="width: 15px; height: 15px; border-radius: 50%">
                      </div>
                    </div>
                    <div class="w-full flex items-center">
                      <div style="width: 35%">
                        <div>{{ item.hz }}m/s²</div>
                        <div style="font-size: 12px; opacity: 0.6">
                          {{ langObj['振动加速度'] }}
                        </div>
                      </div>
                      <div style="width: 35%">
                        <div>{{ item.sz }}mm/s</div>
                        <div style="font-size: 12px; opacity: 0.6">{{
                          langObj['振动速度'] }}</div>
                      </div>
                      <div style="width: 30%;word-wrap: break-word;">
                        <div>{{ item.temp }}℃</div>
                        <div style="font-size: 12px; opacity: 0.6">{{
                          langObj['温度'] }}</div>
                      </div>
                    </div>
                    <div class="w-full flex">
                      <div class="flex-1"></div>
                      <div class="mr-2" style="font-size: 12px; opacity: 0.6">
                        {{ item.date }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 划线 -->
              <div v-for="(item, index) in line" :key="index">
                <div class="d3_line" :style="{
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                }"></div>
              </div>
            </div>
          </div>

          <!-- 右 -->
          <div class="ml-8" style="width:20%;min-width: 350px">
             <!-- 设备信息 -->
             <div class="w-full mt-2">
              <div class="title flex items-center">
                    <div class="p-text-color2 ml-10">{{ langObj['设备信息'] }}</div>
                    <div class="flex-1"></div>
              </div>
              <div class="bg_wrap" style="height:auto">
                  <pictureListComponent :type="3" :picList="picListAll">
                  </pictureListComponent>
                  <div v-if="!picListAll.length" style="height:180px"></div>

                  <div class="w-full flex flex-wrap mt-4">
                    <div class="w-1/2 text-center">
                      <div style="opacity: 0.8">{{ langObj['设备名称'] }}</div>
                      <div>{{ machineInfo.machineName }}</div>
                    </div>
                    <div class="w-1/2 text-center">
                      <div style="opacity: 0.8">{{ langObj['设备区域'] }}</div>
                      <div>{{ getGroupName(machineInfo.groupId) }}</div>
                    </div>
                    <div class="w-1/2 mt-2 text-center">
                      <div style="opacity: 0.8">{{ langObj['设备厂家'] }}</div>
                      <div>{{ machineInfo.supplier }}</div>
                    </div>
                    <div class="w-1/2 mt-2 text-center">
                      <div style="opacity: 0.8">{{ langObj['设备编号'] }}</div>
                      <div>{{ machineInfo.machineCode }}</div>
                    </div>
                  </div>
              </div>
            </div>

            <div class="mt-2 w-full">

              <div class="title flex items-center">
                    <div class="p-text-color2 ml-10">{{ langObj['设备事件'] }}<span class="text-small">
                      （{{ warningTotal
                       }} {{langObj['条']}}）
                    </span></div>
                    <div class="flex-1"></div>
                    <!-- @click="selectWarnTrend" -->
                    <!-- @click="viewMore" -->
                    
                    <div  @click="viewWarnMore"   class="p-text-right" style="cursor: pointer">
                  {{ langObj['查看更多'] }} >
                </div>
              </div>

              <div class="bg_wrap" style="height:auto;">
                <div class="mt-2 w-full flex whiteBg">
                  <div class="flex-1"></div>
                  <div @click="(warningType = 1), getMachineWarning()" class="btn1 cursor-pointer"
                      :class="warningType === 1 ? 'btn1BgSelected' : ''" :style="{ color: warningType === 1 ? '#fff' : '' }">
                    {{ langObj['报警事件'] }}
                  </div>
                  <div @click="(warningType = 2), getMachineWarning()" class="btn99 cursor-pointer"
                      style="margin-left: 10px" :class="warningType === 2 ? 'btn1BgSelected' : ''"
                      :style="{ color: warningType === 2 ? '#fff' : '' }">
                    {{ langObj['检修记录'] }}
                  </div>
                  <div class="flex-1"></div>
                </div>

                <div class="mt-2 w-full mb-4">
                  <div v-for="(item, index) in machineWarningData" :key="index" class="box5 mt-2">
                    <div class="w-full flex items-center">
                      <img v-if="item.type === 1" style="width: 18px; height: 18px" src="@/assets/zhenduan.png" alt="" />
                      <img v-if="item.type === 2" style="width: 18px; height: 18px" src="@/assets/weixiu.png" alt="" />
                      <img v-if="item.type === 4" style="width: 18px; height: 18px" src="@/assets/menxian.png" alt="" />
                      <div v-if="item.type === 1" :style="{
                        color: conditionList.find((p) =>
                          p.value.includes(item.condition)
                        )?.color,
                      }" class="ml-2" style="font-size: 16px">
                        {{ langObj['诊断报醒'] }}：{{
                          langObj[conditionList.find((p) =>
                            p.value.includes(item.condition)
                          )?.label ?? ""]
                        }}
                      </div>
                      <div class="ml-2" v-if="item.type === 2">{{ langObj['现场维修'] }}
                      </div>
                      <div class="ml-2" v-if="item.type === 4">{{ langObj['门限报警'] }}
                      </div>
                      <div class="flex-1"></div>
                      <!-- <div v-if="
                        item.reportIds && item.reportIds.length && item.type === 1
                      " class="box6 opt8">
                        {{ langObj['诊断报告'] }}
                      </div> -->
                      <!-- <div v-if="
                        item.reportIds && item.reportIds.length && item.type === 2
                      " class="box6 opt8">
                        {{ langObj['检修报告'] }}
                      </div> -->
                    </div>

                    <div class="mt-2">{{ item.diagnosticNotes }}</div>
                    <div class="mt-2 opt6" style="font-size: 12px">
                      {{ item.date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 ml-7" style="height:98%;overflow-y:scroll" v-if="selectType == 2">
        <div class="w-full flex items-center">
          <span style="font-size: 24px">{{ group_info.name }}</span>
        </div>
        <div class="w-full flex">
          <!-- 左侧设备列表 -->
          <div>
            <div class="group-box mt-3 p-2" v-for="(item, index) in group_machineList" :key="index">
              <div class="w-full flex items-center" style="border-bottom: 1px solid rgba(62, 73, 114, 1);">
                <div class="shu"></div>
                <div class="ml-3">{{ item.name }}</div>
              </div>
              <div class="mt-2 w-full flex items-center">
                <div class="pic">
                  <img style="width: 100%; max-height: 100%;" :src="item.picture" alt="">
                </div>

                <div class="flex-1 ml-4">
                  <div class="flex items-center" v-if="item.scoreWkStatus">
                    <div class="line">
                      <div class="line animation"></div>
                    </div>
                    <span class="text-gray-50 pl-2">{{ langObj['运行'] }}</span>
                  </div>
                  <div class="flex items-center" v-else>
                    <div class="down">
                      <div class="down animation"></div>
                    </div>
                    <span class="text-gray-50 pl-2">{{ langObj['停机'] }}</span>
                  </div>


                  <div class="flex items-center mt-2">
                    <div class="line" :style="{ 'background-color': item?.condition?.color }">
                      <div class="line animation" :style="{ 'background-color': item?.condition?.color }">
                      </div>
                    </div>
                    <span class="text-gray-50 pl-2">{{ item?.condition?.label
                      }}</span>
                  </div>
                </div>

              </div>

            </div>


          </div>
          <!-- 右侧区域3D大图 -->
          <div class="flex-1 flex">
            <div style="width: 10%;"></div>
            <div style="width: 80%;display: flex; justify-content: center; position: relative;">

              <div id="yulan" v-if="config_3d_group && config_3d_group.img" style="
                      margin: auto;
                      margin-top:6%;
                      padding: 20px;
                      width: 1000px;
                      height: 800px;
                      position: relative;
                     
                     
                  ">
                <img :src="config_3d_group.picture" style="position: absolute" :style="{
                  width: config_3d_group.img.width + 'px',
                  height: config_3d_group.img.height + 'px',
                  top: config_3d_group.img.y + 'px',
                  left: config_3d_group.img.x + 'px',
                  transform: 'rotate(' + config_3d_group.img.rotate + 'deg)',
                }" />
                <!-- 加载标点 -->
                <div v-for="(item, index) in config_3d_group.marks" :key="index">
                  <div class="d3_point" v-if="item.bind.indexOf('a-') > -1"
                      :style="{ top: item.y + 'px', left: item.x + 'px' }"></div>
                </div>
                <!-- 加载标签 -->
                <div v-for="(item, index) in config_3d_group.marks" :key="index">
                  <div class="d3_bq" v-if="item.bind.indexOf('b-') > -1" :style="{
                    top: item.y - 50 + 'px',
                    left: item.x - 100 + 'px',
                    'border-color': getColor(0),
                  }" style="width: 220px; height: auto">
                    <div class="w-full" style="padding: 5px 10px">
                      <div class="w-full flex">
                        <div style="font-size: 18px;">{{ group_info?.name }}</div>
                      </div>

                      <div v-for="(item, index) in group_machineList" :key="index" class="w-full flex items-center">
                        <div>{{ item.name }}</div>
                        <div class="flex-1"></div>
                        <div style="width: 16px;height: 16px;border-radius: 50%;min-width: 16px"
                            :style="{ 'background': item.condition.color }"></div>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- 划线 -->
                <div v-for="(item, index) in lineGroup" :key="index">
                  <div class="d3_line" :style="{
                    top: item.y + 5 + 'px',
                    left: item.x + 5 + 'px',
                    width: item.distance + 'px',
                    transform: 'rotate(' + item.angle + 'deg)',
                  }"></div>
                </div>
              </div>


            </div>


          </div>
        </div>
      </div>

      <!-- 设备报警-->
      <a-modal v-model:visible="warnTrendVisible" :closable="false" :footer="null"
                    style="top: 10%" width="80%" :maskClosable="false">
                    <div style="color: #fff">
                        <div class="w-full flex">
                            <div class="flex-1"></div>
                            <div class="cursor-pointer" @click="warnTrendVisible  = false">
                                <close-outlined style="font-size: 20px" />
                            </div>
                        </div>
                        <div class="text-center w-full mb-4" style="font-size: 24px">{{ langObj['报警记录']}}</div>
                        <a-form :model="znZdWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                            <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                <a-select  @change="selectGroupWarnTrend" v-model:value="warnTrendSearch.groupId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择区域', language)">
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                          
                            <a-form-item :label="langObj['设备']" name="status">
                                <a-select v-model:value="warnTrendSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']"
                                    :options="machineListZdWarn" :filter-option="filterOptionZdReport"
                                    :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear show-search>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="langObj['报警时间']" name="status">
                                <a-range-picker v-model:value="warnTrendSearch.date" :disabled-date="disabledDate"
                                @calendarChange="onCalendarChange" />
                            </a-form-item>
                            <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                <a-select v-model:value="warnTrendSearch.status" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择处理状态', language)">
                                    <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                <a-select v-model:value="warnTrendSearch.condition" style="width: 220px" allowClear
                                :placeholder="getLanguage('请选择严重程度', language)">
                                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>



                            <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                            <a-form-item>
                                <div class="flex items-center">
                                    <div @click="resetWarnTrend" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('重置', language) }}
                                    </div>

                                    <div @click="getWarnTrendData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('查询', language) }}
                                    </div>
                                </div>
                            </a-form-item>
                        </a-form>

                        <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName"
                                :columns="warnTrendColumns" :data-source="warnTrendData" :pagination="paginationWarnTrend" @change="handleTableChangeWarnTrend">
                                <template #bodyCell="{ column, text, record }">
                                    <template v-if="column.dataIndex === 'type'">
                                        <div class="flex items-center">
                                            <div v-if="text === 1">
                                            <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                            </div>
                                            <div v-if="text === 2">
                                            <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                            </div>
                                            <div v-if="text === 3">
                                            <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                            </div>
                                            <div v-if="text === 4">
                                            <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                            </div>
                                            <div class="ml-2">
                                            {{
                                                langObj[typeList.find((p) => p.value === text)?.label]
                                            }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'condition'">
                                        <div class="flex items-center">
                                            <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                            background: conditionList.find((p) => p.value === text)
                                                ?.color,
                                            }"></div>
                                            <div class="ml-2">
                                            {{
                                                langObj[
                                                conditionList.find((p) => p.value === text)?.label
                                                ]
                                            }}
                                            </div>
                                         </div>
                                    </template>

                                    <template v-if="column.dataIndex === 'handleStatus'">
                                      
                                        <div  v-if="text === 3">
                                           {{ langObj["已关闭"] }}
                                        </div>
                                        <div v-if="text !== 3">
                                            {{
                                            handleStatusList.find((p) => p.value === text)
                                                ? langObj[
                                                handleStatusList.find((p) => p.value === text)?.label
                                                ]
                                                : langObj["未处理"]
                                            }}
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'act'">
                                      
                                       
                                        <a  v-if="record?.reportIds?.length>0" @click="viewReport(record)" >{{ langObj["诊断报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a  @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"]}}</a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                 </a-modal>


                 <a-modal v-model:visible="maintenanceSearch.visible" :closable="false" :footer="null"
                 style="top: 10%" width="80%" :maskClosable="false">
                  <div style="color: #fff">
                    <div class="w-full flex">
                        <div class="flex-1"></div>
                        <div class="cursor-pointer" @click="maintenanceSearch.visible  = false">
                            <close-outlined style="font-size: 20px" />
                        </div>
                    </div>
                    <div class="text-center w-full mb-4" style="font-size: 24px">{{ langObj['检修记录']}}</div>
                    <a-form :model="maintenanceSearch" name="horizontal_login" layout="inline" autocomplete="off">
                        <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                            <a-select  @change="selectGroupMaintenance" v-model:value="maintenanceSearch.groupId" style="width: 220px" allowClear
                                :placeholder="getLanguage('请选择区域', language)">
                                <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                      
                        <a-form-item :label="langObj['设备']" name="status">
                            <a-select v-model:value="maintenanceSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']"
                                :options="machineListMaintenance" :filter-option="filterOptionZdReport"
                                :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear show-search>
                            </a-select>
                        </a-form-item>
                        <a-form-item :label="langObj['报警时间']" name="status">
                            <a-range-picker v-model:value="maintenanceSearch.date" :disabled-date="disabledDate"
                             />
                        </a-form-item>

                        <a-form-item :label="getLanguage('搜索', language)" name="status">
                            <a-input v-model:value="maintenanceSearch.keyword" :placeholder="getLanguage('请输入关键词搜索', language)" />
                        </a-form-item>

                        <a-form-item>
                            <div class="flex items-center">
                                <div @click="resetMaintenance" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                    style="background-color: #072499; color: #fff">
                                    {{ getLanguage('重置', language) }}
                                </div>

                                <div @click="maintenanceSearch.skip=1;  getMaintenanceList()" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                    style="background-color: #072499; color: #fff">
                                    {{ getLanguage('查询', language) }}
                                </div>
                            </div>
                        </a-form-item>
                    </a-form>

                    <div class="box mt-4" style="width: 100%;">
                        <a-table style="width: 100%;" size="small" :row-class-name="getRowClassName" :columns="maintenanceColumns"
                            :data-source="maintenanceList" :pagination="maintenancePagination" @change="handleTableChangeMaintenance">
                            <template #bodyCell="{ column, text, record }">
                                <template v-if="column.dataIndex === 'factoryId'">
                                    <span>{{getFactoryName(text)}} </span>
                                </template>

                                <template v-if="column.dataIndex === 'act'">
                                    <a @click="detailsMaintenance(record)" style="" class="theme-table-link">{{ langObj['查看详情'] }}</a>
                                </template>
                            </template>
                        </a-table>
                    </div>




                  </div>

                 </a-modal>




                 <!-- 检修报告 -->
                 <a-modal width="50%" :closable="false" :footer="null" v-model:visible="jxVisible">
                    <maintenanceReportDetails ref="mrD" @cancel="cancelJx"></maintenanceReportDetails>
                 </a-modal>
                 <!-- 诊断报告详情 -->
                 <a-modal width="50%" :closable="false" :footer="null" v-model:visible="visibleDR">
                    <DiagnosticReportDetails   ref="mrDref" @cancel="cancelDR"></DiagnosticReportDetails>
                 </a-modal>
                 <!-- 关闭原因 -->
                 <a-modal v-model:visible="visibledel2" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
                    style="top: 30%" width="1200px" :maskClosable="false">
                    <div style="color: #fff">
                    <div class="w-full flex">
                        <div class="flex-1"></div>
                        <div class="cursor-pointer" @click="visibledel2 = false">
                        <close-outlined style="font-size: 20px; color: #fff !important" />
                        </div>
                    </div>
                    <div class="text-center w-full" style="font-size: 24px">
                        {{ langObj["关闭报警"] }}
                    </div>

                    <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
                        :model="formClose">
                        <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-item name="closeCause" label="关闭原因" required>
                            <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" readonly />
                            </a-form-item>
                        </a-col>
                        </a-row>
                    </a-form>
                    </div>
                </a-modal>


      
    
    
    
         </div>
  </div>
</template>

<script lang="ts" setup>
import { ref,computed,component  } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import { langList } from "../../common/lang";
import {
  transformDate,
  transformDate2,
  getGroupName,
  getRealFactoryIdList ,
  getGroupListByFactory,
  getFactoryName,
} from "../../common/tools";
import * as echarts from "echarts";
import D3dViewer from "../components/d3d-viewer.vue";
import { getLanguage } from "../../common/translate"

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
  console.log(theme.value)
}
getLang()

const router = useRouter();
let machine_id = "";
let machineInfo: any = ref({});

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7" },
  { label: "可用", value: [1, 2], color: "#d2de49", color2: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b", color2: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37", color2: "#921e37" },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

// let conditionLists = [
//     {label: '健康', value: 0, color: '#61c08f'},
//     {label: '可用', value: 1, color: '#d2de49'},
//     {label: '可用', value: 2, color: '#d2de49'},
//     {label: '警戒', value: 3, color: '#cd5f3b'},
//     {label: '警戒', value: 4, color: '#cd5f3b'},
//     {label: '警戒', value: 5, color: '#cd5f3b'},
//     {label: '故障', value: 6, color: '#921e37'},
//     {label: '故障', value: 7, color: '#921e37'},
// ]
let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "停机", value: false, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
  { label: "运行", value: true, color: "#4EFAEE" },
];

let selectType: any = ref(1)

const selectMachine = (ev: any) => {
  selectType.value = 1
  machine_id = ev;
  selecrRandar.value = 2;
  picListAll.value = [];
  picList.value = [];
  init();
};
let  groupList = ref([])
let machineListZdWarn = ref([])

const init = () => {
  
  getMachineInfo();
  getMachineStatusTime(1);
  getMachineWarning();
  getMachineWarningZnZd()
  groupList.value = getGroupListByFactory()
};

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    machineInfo.value.status = statusList[0];
    if (machineInfo.value &&
      machineInfo.value.workStatus) {
      let status = statusList.find(
        (p: any) => p.value === (machineInfo.value.workStatus.quotaWkStatus ?? machineInfo.value.workStatus.scoreWkStatus ?? 0)
      );
      status && (machineInfo.value.status = status)
    }
    load3D();
  }

  if (machineInfo.value.pictures?.length > 0) {
    // picList.value.push({
    //   name: "设备",
    //   url: machineInfo.value.pictures[0].url,
    // });
    machineInfo.value.pictures.map((d: any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getRandarChartData();
  getScore(1)
  // initScoreChart()
  // getRunTime()

};

let picList: any = ref([]);
let picListAll: any = ref([]);
const getSensorList = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures) {
        d.pictures.map((p: any) => {
          picList.value.push({
            name: d.sensorPlace,
            url: p.url
          })
        })
      }
    });
  }

  picListAll.value = picList.value;
};

// 获取设备状态占比
let satusTimeDate: any = ref(1);
let visibleChart = ref(true);
let conditionTotal: any = ref({
  condition1Date: "--",
  condition2Date: "--",
  condition3Date: "--",
  condition4Date: "--",
  condition1Rate: 0,
  condition2Rate: 0,
  condition3Rate: 0,
  condition4Rate: 0,
  wkStatus1Date: "--",
  wkStatus2Date: "--",
  wkStatus1Rate: 0,
  wkStatus2Rate: 0,
});
let machineStatusTime = ref(1);
const getMachineStatusTime = async (dateType:any) => {
  machineStatusTime.value = dateType;
  conditionTotal.value = {
    condition1Date: "--",
    condition2Date: "--",
    condition3Date: "--",
    condition4Date: "--",
    condition1Rate: 0,
    condition2Rate: 0,
    condition3Rate: 0,
    condition4Rate: 0,
    wkStatus1Date: "--",
    wkStatus2Date: "--",
    wkStatus1Rate: 0,
    wkStatus2Rate: 0,
  };
  let time = new Date();
  let endDate = transformDate2(time, 1);


  // satusTimeDate.value = 3;
  // //本年
  // if (satusTimeDate.value === 3) {
  //   startDate = `${year}/01/01 00:00:00`;
  // }
  //本月
  let num = dateType === 2 ? 30 : 7

  let startTime = time.getTime() - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let stableSearch = {
    params: {
      begin: startDate,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result2 = await request.get(
    "/api/machines/workstatus/stables",
    stableSearch
  );
  let stableList: any = [];
  if (result2 && result2.data && result2.data.length) {
    stableList = result2.data;
  }

  let beginT = new Date(startDate).getTime();
  let endT = new Date(endDate).getTime();

  let condition0 = 0; //无
  let condition1 = 0; //健康
  let condition2 = 0; //可用
  let condition3 = 0; //警戒
  let condition4 = 0; //故障
  let conditionT = endT - beginT;
  if (stableList?.length > 0) {
    stableList.map((d: any, index: any) => {
      if (index == 0) {
        condition0 = new Date(d.date).getTime() - beginT;
      } else {
        let time =
          new Date(d.date).getTime() -
          new Date(stableList[index - 1].date).getTime();

        if (d.condition === 0) {
          condition1 = condition1 + time;
        }
        if (d.condition >= 1 && d.condition <= 2) {
          condition2 = condition2 + time;
        }
        if (d.condition >= 3 && d.condition <= 5) {
          condition3 = condition3 + time;
        }
        if (d.condition >= 6 && d.condition <= 7) {
          condition4 = condition4 + time;
        }
      }

      if (index === stableList.length - 1) {
        condition0 = condition0 + (endT - new Date(d.date).getTime());
      }
    });
  } else {
    condition1 = 0
    condition2 = 0
    condition3 = 0
    condition4 = 0
    condition0 = conditionT
  }


  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
  let wkStatus1 = 0; //运行
  let wkStatus2 = 0; //停机
  let dateWK1 = 0
  if (result4 && result4.data) {
    result4.data.map((d: any) => {
      dateWK1 = dateWK1 + Math.round(d.online / 3600)
    })
  }
  wkStatus1 = dateWK1 * 60 * 60 * 1000
  wkStatus2 = conditionT - wkStatus1

  function convertTimestamp(timestamp: any) {
    var milliseconds = timestamp;
    var date: any = new Date(milliseconds);

    var days = Math.floor(date / (24 * 60 * 60 * 1000));
    var hours = Math.floor((date % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    var minutes = Math.floor((date % (60 * 60 * 1000)) / (60 * 1000));
    return days + getLanguage("天", language.value) + ' ' + hours + getLanguage("时", language.value) + ' ' + minutes + getLanguage("分", language.value);
  }

  conditionTotal.value = {
    condition1Date: convertTimestamp(condition1),
    condition2Date: convertTimestamp(condition2),
    condition3Date: convertTimestamp(condition3),
    condition4Date: convertTimestamp(condition4),
    condition0Rate: Number(((condition0 / conditionT) * 100).toFixed(0)),
    condition1Rate: Number(((condition1 / conditionT) * 100).toFixed(0)),
    condition2Rate: Number(((condition2 / conditionT) * 100).toFixed(0)),
    condition3Rate: Number(((condition3 / conditionT) * 100).toFixed(0)),
    condition4Rate: Number(((condition4 / conditionT) * 100).toFixed(0)),
    wkStatus1Rate: Number(((wkStatus1 / conditionT) * 100).toFixed(0)),
    wkStatus2Rate: Number(
      (((wkStatus2) / conditionT) * 100).toFixed(0)
    ),
    wkStatus1Date: convertTimestamp(wkStatus1),
    wkStatus2Date: convertTimestamp(wkStatus2),
    wkStatsu: convertTimestamp(wkStatus1)
  };

  visibleChart.value = false;
  visibleChart.value = true;

  setTimeout(() => {
    initMacineStatusRateChart();
  }, 500);
};

//设备报警趋势弹窗
let typeList = [
  { label: "智能诊断", value: 1 },
  {label: '检修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let handleStatusList:any=ref([
    { label: '未处理', value: 2 },
    { label: '已处理', value: 3 },
])

let warnTrendVisible = ref(false);
let warnTrendData = ref([])
let warnTrendSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    typeList:[1,4]
})
const paginationWarnTrend = computed(() => ({
  total: warnTrendSearch.value.total,
  current: warnTrendSearch.value.skip,
  pageSize: warnTrendSearch.value.take,
}));
 

const warnTrendColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];

const viewWarnMore = () => {
  if(warningType.value === 1) {
    selectWarnTrend()
  }
  if(warningType.value === 2) {
    selectMaintenanceTrend()
  }
}


const selectWarnTrend = () => {
    warnTrendVisible.value = true
    resetWarnTrend()
    if(warningType.value==1){
      warnTrendSearch.value.typeList = [1,4]
    }else{
      warnTrendSearch.value.typeList = [2]
    }
    warnTrendSearch.value.groupId = machineInfo.value.groupId;
    warnTrendSearch.value.machineId = machine_id
    if(warnTrendSearch.value.groupId) {
      let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '{}')
      machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === machineInfo.value.groupId)
    }
    getWarnTrendData()
}  
const filterOptionZdReport = (input: string, option: any) => {
    return option.name.indexOf(input) >= 0;
};

// 选择区域
const selectGroupWarnTrend = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    warnTrendSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getWarnTrendData = async () => {
   

  let config: any = {
    params: {
      skip: warnTrendSearch.value.skip,
      take: warnTrendSearch.value.take,
      groupId: warnTrendSearch.value.groupId,
      machineId: warnTrendSearch.value.machineId,
      typeList:warnTrendSearch.value.typeList,
      handleStatus: warnTrendSearch.value.handleStatus,
      expertCertification: warnTrendSearch.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (warnTrendSearch.value.date && warnTrendSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnTrendSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(warnTrendSearch.value.date[1]), 2);
  }
   
  if (warnTrendSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == warnTrendSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warnTrendData.value = result.data.list;
    warnTrendSearch.value.total = result.data.total;
  }
};

const resetWarnTrend = () => {
    warnTrendSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        typeList:[1,4]
    }
    // getWarnTrendData()
}
const handleTableChangeWarnTrend = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    warnTrendSearch.value.sort = sorter.field;
    warnTrendSearch.value.order = order;
  }
  // 处理分页数据
  warnTrendSearch.value.skip = pagination.current;
  warnTrendSearch.value.take = pagination.pageSize;

  getWarnTrendData();
};

// 检修记录弹窗
let maintenanceSearch:any = ref({
  skip: 1,
  take: 10,
  total: 0,
  groupId: null,
  machineId: null,
  date: null,
  visible: false,
  keyword: '',
})
const maintenancePagination = computed(() => ({
    total: maintenanceSearch.value.total,
    current: maintenanceSearch.value.skip,
    pageSize: maintenanceSearch.value.take,
}));
let machineListMaintenance:any = ref([])
let maintenanceList:any = ref([])
const maintenanceColumns = [
    {
        title: langObj.value['报告编号'],
        dataIndex: 'id',
    },
    {
        title: getLanguage('项目名称'),
        dataIndex: 'factoryId',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['检修内容'],
        dataIndex: 'maintainMessage',
    },
    {
        title: langObj.value['提交时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['检修人'],
        dataIndex: 'maintainPerson',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]
const selectMaintenanceTrend = () => {
  maintenanceSearch.value.visible = true
  maintenanceSearch.value.groupId = machineInfo.value.groupId;
  maintenanceSearch.value.machineId = machine_id
  if(maintenanceSearch.value.groupId) {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '{}')
    machineListMaintenance.value = memberInfo.machines.filter((p:any) => p.groupId === machineInfo.value.groupId)
  }
  getMaintenanceList()
}

const resetMaintenance = () => {
  maintenanceSearch.value = {
    ... maintenanceSearch.value,
    groupId: null,
    machineId: null,
    date: null,
    keyword: '',
  }
}

const handleTableChangeMaintenance = (pagination: any) => {
  maintenanceSearch.value.skip = pagination.current
  maintenanceSearch.value.take = pagination.pageSize
  getMaintenanceList();
}

const getMaintenanceList = async() => {
  let config: any = {
      params: {
        skip: maintenanceSearch.value.skip,
        take: maintenanceSearch.value.take,
        type: [2],
        keyword: maintenanceSearch.value.keyword,
        groupId: maintenanceSearch.value.groupId,
        machineId: maintenanceSearch.value.machineId,
        factoryIds: getRealFactoryIdList(),
      },
      headers: {
          requestId: uuidv4(),
      },
  };

  if (maintenanceSearch.value.date && maintenanceSearch.value.date.length === 2) {
      config.params.begin = transformDate2(new Date(maintenanceSearch.value.date[0]), 1);
      config.params.end = transformDate2(new Date(maintenanceSearch.value.date[1]), 2);
  }

  let result = await request.get('/api/external/reports', config)
  if (result && result.status === 200) {
      result.data.list.forEach((element: any) => {
          if (element.machines && element.machines[0]) {
              element.machineName = element.machines[0].machineName;
              element.groupName = getGroupName(element.machines[0]?.groupId)
          }

          if (element.repairReport) {
              element.disposition = element.repairReport.disposition
              element.maintainMessage = element.repairReport.maintainMessage
              element.maintainPerson = element.repairReport.maintainPerson
          }
      })
      maintenanceList.value = result.data.list
      maintenanceSearch.value.total = result.data.count
    }


}


const selectGroupMaintenance = (ev:any) => {
  let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
  maintenanceSearch.value.machineId = ''
  if(ev) {
    machineListMaintenance.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
  } else {
    machineListMaintenance.value = []
  }
}

const detailsMaintenance = (ev: any) => {
    // console.log(ev)
    // maintenanceSearch.value.visible2 = true
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.id)
    }, 1000);
}

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}


//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};



//获取当前设备从创建到此时的运行时间
const getRunTime = async() => {
 
  let endT = new Date().getTime();
  let endDate = transformDate2(endT, 1)
  let stableSearch = {
    params: {
      begin: machineInfo.value.createdAt,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
}

//绘制设备状态占比图
const initMacineStatusRateChart = () => {
  const ec = echarts as any;
  if (!document.getElementById("chartAM")) return;
  let myChart = ec.init(document.getElementById("chartAM"));

  const data = [
    {
      name: "外层饼图",
      children: [
        { value: conditionTotal.value.condition1Rate, name: "健康" },
        { value: conditionTotal.value.condition2Rate, name: "可用" },
        { value: conditionTotal.value.condition3Rate, name: "警戒" },
        { value: conditionTotal.value.condition4Rate, name: "故障" },
        { value: conditionTotal.value.condition0Rate, name: "停机" },
      ],
    },
    {
      name: "内层饼图",
      children: [
        { value: conditionTotal.value.wkStatus1Rate, name: "运行" },
        { value: conditionTotal.value.wkStatus2Rate, name: "停机" },
      ],
    },
  ];

  // 配置选项
  const option = {
    color: ["#1CC48B", "#DDD246", "#CB6A34", "#B81212", "#ADADB0"],
    grid: {
      right: "0%",
      left: "0%",
      top: 0,
      bottom: 0,
    },
    series: [
      {
        name: "外层饼图",
        type: "pie",
        radius: ["70%", "100%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[0].children,
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
      {
        name: "内层饼图",
        type: "pie",
        radius: ["40%", "70%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[1].children,
        color: ["#4DFAED", "#ADADB0"],
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
    ],
  };

  // 使用配置项显示图表
  myChart.setOption(option);
};

// 获取雷达图数据
let selecrRandar = ref(2); //1健康  2振动
let visibleChart2 = ref(true);
const getRandarChartData = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/faultnames", faultnamesSearch);
  let faultName = result.data.faultNames;
  let faultNameII = result.data.faultNameExpects;

  let Slist: any = [];
  let Tlist: any = [];

  if (
    machineInfo.value.workStatus &&
    machineInfo.value.workStatus.stableScores
  ) {
    for (let index in machineInfo.value.workStatus.stableScores) {
      const item = machineInfo.value.workStatus.stableScores[index];
      if (index.includes("S")) {
        Slist.push({
          quota: index,
          score: item,
          text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title,
        });
      }

      if (index.includes("T")) {
        Tlist.push({
          quota: index,
          score: item,
          text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
        });
      }
    }
  }

  machineInfo.value.Tlist = Tlist;
  machineInfo.value.Slist = Slist;

  if (!Tlist && Slist) {
    selecrRandar.value = 1;
  } else {
    selecrRandar.value = 2;
  }

  

  visibleChart2.value = false;
  visibleChart2.value = true;

  setTimeout(() => {
    if (selecrRandar.value === 1) {
      initRandarChart(Slist);
    }
    if (selecrRandar.value === 2) {
      initRandarChart(Tlist);
    }
  }, 500);
};

// 绘制雷达图
const initRandarChart = (dataList: any) => {
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  if (!dataList.length) return;
  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = theme.value == 'white' ? '#333' : "#fff";
    }

    let name = ''
    if (language.value == "Chinese" && d.text) {
      if (d.text.length > 8) {
        name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
      } else if (d.text.length > 4 && d.text.length <= 8) {
        name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
      } else {
        name = d.text
      }
    }

    if (language.value == "English" && d.text) {
      let list1 = d.text.split(' ')

      let str: any = ''
      let num = 0
      let sy = ''
      list1.map((n: any, i: any) => {
        if (i == 0) {
          if (n.length <= 10) {
            num = n.length
            str = n
          } else {
            num = 10
            let a1 = n.substring(0, 10)
            sy = n.slice(10)
            str = a1
          }
        } else {

          if (sy) {
            str = str + '\n';
            let n2 = sy.length + n.length
            if (n2 <= 10) {
              num = n2
              str = str + sy + ' ' + n
              sy = ''
            } else {
              num = 10
              str = str + sy
              let n2 = 10 - sy.length >= 0 ? 10 - sy.length : 0

              let a1 = n.substring(0, n2)
              sy = n.slice(10)
              str = str + a1
            }
          } else {
            if (num + n.length <= 10) {
              str = str + ' ' + n
              num = num + n.length
            } else {
              str = str + '\n' + n
              num = n.length
            }
          }
        }
      })
      name = str
    }

    indicator.push({
      color,
      max: 100,
      text: name
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: { position: { left: '0px' } },
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 60,
        startAngle: 90,
        splitNumber: 5,
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 12,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: "当前值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};
//获取分数趋势
let scoreTime=ref(1)
const getScore = async(dateType:any)=>{
  scoreTime.value = dateType
  // 获取分数趋势数据
  // let end = transformDate(new Date());
  // let begin = transformDate(new Date().getTime() - 1000 * 60 * 60 * 24);
  let time = new Date();
  let endDate = transformDate2(time, 1);
  //本月
  let num = dateType === 2 ? 30 : 7

  let startTime = time.getTime() - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let search = {
    params: {
      begin:startDate,
      end:endDate,
      sensorId: "",
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let score = await request.get("/api/external/machines/score-full", search);
  let scoreAll = score.data;

  let scoreDataList: any = [];

  scoreAll.map((item: any) => {
    let d = item.date;
    let info1 = scoreAll.find((p: any) => p.date === d);
    delete info1.date;
    delete info1.wkStatus;
    delete info1.condition;
    let list = Object.values(info1).sort();
    scoreDataList.push({
      date: d,
      value: list[0],
    });
  });

  machineInfo.value.scoreDataList = scoreDataList;
  setTimeout(() => {
    initScoreChart()
  }, 500);
 
}

//绘制分数趋势图
const initScoreChart = () => {
  let data = machineInfo.value.scoreDataList;
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB2")) return;
  let myChart = ec.init(document.getElementById("chartMachienB2"));
  myChart.clear();
  let xData = data?.map((p: any) => p.date);
  let yData = data?.map((p: any) => p.value);

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
        fontSize:9
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      type: "value",
      name: langObj.value['分'], 
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart.setOption(option);
};

// 选择雷达图、分数趋势图
const selectTag = (ev: any) => {
  selecrRandar.value = ev;
  if (ev === 1) {
    initRandarChart(machineInfo.value.Slist);
  }

  if (ev === 2) {
    initRandarChart(machineInfo.value.Tlist);
  }

  if (ev === 3) {
    // initScoreChart();
  }
};

// 获取设备事件
let warningType = ref(1);
let warningTotal = ref(0);
let machineWarningData: any = ref([]);
const getMachineWarning = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: warningType.value === 1 ? [1, 4] : [2],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
    machineWarningData.value = result.data.list;
    warningTotal.value = result.data.total;
  }
};
let znZdWarnNum = ref(0)
//获取当前设备的智能诊断报警数量
const getMachineWarningZnZd = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: [1],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
   
    znZdWarnNum.value = result.data.total;
  }
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks?.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let markList = ref<any[]>([]);

let config_3d = ref<any>({
  marks: [],
});

let line = ref<any[]>([]);

const load3D = async () => {
  config_3d.value = {};
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);
  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);

      if (res.data) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    config_3d.value.marks = config_3d.value.marks || [];
    markList.value = config_3d.value.marks;
  }
  line.value = [];
  if (config_3d.value.marks?.length > 0) {
    for (var i = 0; i < config_3d.value.marks.length; i++) {
      var item = config_3d.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
        var find = config_3d.value.marks.find(
          (t) =>
            t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
            t.bind.indexOf("b-") > -1
        );
        if (find) {
          var distance = Math.sqrt(
            Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
            Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          line.value.push({
            ...item,
            distance: distance,
            angle: angle_d,
          });
        }
      }
    }
  }

  if (config_3d.value.marks.length > 0) {
    get3DData();
  }
};

// 查看更多
const viewMore = () => {
  router.push("/warning-manage/warning-list");
};


// 选择区域
let group_info: any = ref({})
let group_machineList: any = ref([])
let config_3d_group: any = ref({})
let lineGroup: any = ref([])

const selectGroup = (ev: any) => {
  selectType.value = 2
  if (ev) {
    group_info.value = ev
    group_machineList = ev.machineList || []
  }

  loadGroup3D()
}

const loadGroup3D = async () => {
  config_3d_group.value = { marks: [] };

  let config: any = {
    params: {
      groupId: group_info.value.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/group-3D", config);
  console.log('result.data2222222222', result.data)
  if (result?.data) {
    config_3d_group.value = result.data

    markList.value = config_3d_group.value.marks;
  }


  lineGroup.value = [];
  for (var i = 0; i < config_3d_group.value.marks.length; i++) {
    var item = config_3d_group.value.marks[i];
    if (item.bind.indexOf("a-") > -1) {
      var find = config_3d_group.value.marks.find(
        (t: any) =>
          t.bind.substring(2, t.bind.length) ==
          item.bind.substring(2, item.bind.length) &&
          t.bind.indexOf("b-") > -1
      );
      if (find) {
        var distance = Math.sqrt(
          Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
        );
        var angle_d =
          Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
        lineGroup.value.push({
          ...item,
          distance: distance,
          angle: angle_d,
        });
      }
    }

  }
}

</script>

<style lang="less" scoped>
.group-box {
  width: 247px;
  height: 160px;
  background: rgba(7, 36, 153, 0.2);
  border: 1px solid #0D53B7;

  .shu {
    width: 2px;
    height: 10px;
    background: rgba(3, 243, 245, 1);
  }

  .pic {
    width: 120px;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line {
  width: 10px;
  height: 10px;
  background-color: #4efaee;
  border-radius: 5px;
}

.down {
  width: 10px;
  height: 10px;
  background-color: #29314d;
  border-radius: 5px;
}

.animation {
  -webkit-animation: twinkling 5s infinite ease-in-out;
  animation: twinkling 5s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.center-tit-bg{
  width:75%;
  background: url('@/assets/screen/m_center_bg.png') no-repeat;
  background-size: 100% 100%;
  margin:0 auto;
  text-align:center;
  box-sizing:border-box;
  padding:0.5% 0;
}

/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

.box1 {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  width: 220px;
  padding: 20px 10px;
}

.box2 {
  width: 200px;
  height: 80px;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }
}

.box3 {
  border: 1px solid #0d53b7;
  background: #02004d33;
  box-shadow: inset 0 0 34px 0 #00249b;
  border-radius: 2px;
  padding: 15px;
}

.box4 {
  width: 12px;
  height: 12px;
}

.box5 {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #0d53b7cc;
  padding: 10px;
}

.box6 {
  width: 66px;
  // height: 24px;
  border-radius: 2px;
  border: 1px solid #ffffff66;
  text-align: center;
  // line-height: 24px;
}

.btn1 {
  width: 80px;
  // height: 30px;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid #0d53b7;
  text-align: center;
  padding: 5px 5px;
  // line-height: 30px;
}

.btn99 {
  width: 90px;
  // height: 30px;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid #0d53b7;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 5px;
  // line-height: 30px;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size18 {
  font-size: 18px;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.title {
    width: 100%;
    // height: 48px;
    font-size: 1vw;
    // text-shadow: 0 4px 0 #0a285fb0;
    font-family: "YouSheBiaoTiHei";
    background: url('@/assets/screen/p_left_tit_bg.png') no-repeat;
    background-size: 100% 100%;
    font-weight: 700;
    font-style: italic;
    .text-small{
      font-size:0.6vw
    }
    // justify-content: space-between;
    .p-text-right{
        font-family: "PingFang SC";
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.7vw;
        padding-right:1%;
    }

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}
.bg_wrap{
    width: 100%;
    height:21vh;
    min-height: 270px;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 2%;
   
}

.center_img_wrap{
  width:25%;
  background: url('@/assets/screen/m_center_state_bg.png');
  background-size:100% 100%;
  img{
    width:2.5vw
  }
}
.center_text{
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  div:nth-of-type(1){
    font-size:0.7vw!important;
    line-height:0.7vw!important;
  }
  div:nth-of-type(2){
    margin-top:0.2vw;
    font-size:1vw!important;
    line-height:1vw!important;
  }
}
</style>
