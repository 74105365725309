<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="theme-common">
      <a-layout-content style="padding: 0 20px">
        <a-form layout="inline">
          <a-form-item :label="getLanguage('设备区域')">
            <a-select
                v-model:value="searchModel.groupId"
                style="width: 220px"
                @change="selectGroup"
                allowClear
            >
              <a-select-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="getLanguage('设备')">
            <a-select
                v-model:value="searchModel.machineId"
                style="width: 220px"
                allowClear
            >
              <a-select-option
                  v-for="(item, index) in machineList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="getLanguage('反馈时间')">
            <a-range-picker
                allowClear
                v-model:value="searchModel.date"
            />
          </a-form-item>

          <a-form-item :label="getLanguage('处理状态')">
            <a-select
                v-model:value="searchModel.status"
                style="width: 220px"
                allowClear
            >
              <a-select-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <div class="flex items-center">
              <div
                  @click="reset"
                  class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
              >
                {{ getLanguage('重置') }}
              </div>
              <div
                  @click="search"
                  class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                  style="background-color: #072499"
              >
                {{ getLanguage('查询') }}
              </div>
            </div>
          </a-form-item>

        </a-form>


        <div class="main">
          <a-table bordered :columns="columns" :dataSource="dataList" :pagination="false"
                   :loading="loading" size="small">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'degree'">
                {{ feedbackGradeList.find((p: any) => p.value === text)?.label }}
              </template>
              <template v-if="column.dataIndex === 'status'">
                {{ statusList.find((p: any) => p.value === text)?.label }}
              </template>
              <template v-if="column.dataIndex === 'act'">
                <a v-if="record.status === 1" @click="dealWith(record)" class="theme-table-link">处理</a>
                <a v-if="record.status !== 1" @click="view(record)" class="theme-table-link">查看详情</a>

              </template>
            </template>
          </a-table>
        </div>
      </a-layout-content>


      <!-- 处理 -->
      <a-modal
          v-model:visible="visible"
          :closable="false"
          :footer="null"
          :bodyStyle="{ background: '#020F46' }"
          style="top: 30%"
          width="1200px"
          :maskClosable="false"
      >
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visible = false">
              <close-outlined style="font-size: 20px"/>
            </div>
          </div>

          <div class="text-center w-full" style="font-size: 24px">
            {{ feedbackForm.title }}
          </div>

          <div class="my-2">诊断报告</div>

          <a-table
              style="width: 100%;"
              size="small"
              :row-class-name="getRowClassName"
              :columns="columnsReport"
              :data-source="feedbackForm.reports"
              :pagination="false"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'degree'">
                {{ feedbackGradeList.find((p: any) => p.value === text)?.label }}
              </template>
            </template>

          </a-table>

          <div class="my-2">诊断调整</div>
          <div>
            <a-radio-group v-model:value="feedbackForm.index" @change="selectSensorFault1">
              <a-radio style="margin-top: 10px" v-for="(item, index) in feedbackForm.sensorFaults" :key="index"
                       :value="index">
                <div class="flex items-center">
                  <div>测点：</div>
                  <div>{{ item.sensorPlace }},</div>
                  <div>故障指标：</div>
                  <div>{{ item.faultName }},</div>
                  <div class="mx-4">{{ item.faultFunction }}</div>
                  <div class="flex items-center flex-wrap">
                    <a-tag v-for="(f, index2) in item.originThresholdList" :key="index2" color="green">
                        <span>{{ f.label }}:</span>
                        <span>{{ f.value }}</span>
                    </a-tag>
                  </div>
                  
                </div>
              </a-radio>

            </a-radio-group>
          </div>

          <div class="mt-4 flex justify-center">

            <div class="flex ml-2">
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '1' }"
                  :style="{
                        background:
                        feedbackSearch.type == '1' ? '#072498' : '',
                        }"
                  style="border-radius: 5px 0 0 5px"
                  @click="(feedbackSearch.type = '1'), selectSensorFault(feedbackForm.index)"
              >
                近一周
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '2' }"
                  :style="{
                        background:
                        feedbackSearch.type == '2' ? '#072498' : '',
                        }"
                  @click="(feedbackSearch.type = '2'), selectSensorFault(feedbackForm.index)"
              >
                近一月
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '3' }"
                  :style="{
                        background:
                        feedbackSearch.type == '3' ? '#072498' : '',
                        }"
                  @click="(feedbackSearch.type = '3'), selectSensorFault(feedbackForm.index)"
              >
                近两月
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :style="{
                        background:
                        feedbackSearch.type == '0' ? '#072498' : '',
                        }"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '0' }"
                  @click="feedbackSearch.type = '0'"
                  style="border-radius: 0 5px 5px 0"
              >
                自定义
              </div>

              <div class="ml-2" v-if="feedbackSearch.type == '0'">
                <a-range-picker
                    v-model:value="feedbackSearch.date"
                    :disabled-date="disabledDate2"
                    @calendarChange="feedbackSearch"
                />
              </div>

              <div @click="selectSensorFault(feedbackForm.index)" class="btn3 ml-2 theme-datesetected">
                查询
              </div>

            </div>

          </div>

          <div class="mt-4">
            <div id="feedback1" style="height: 340px" class="w-full"></div>
          </div>

          <div class="btn w-full mt-4">
            <div class="w-full flex items-center px-12 py-2" v-for="(item, index) in paramList" :key="index">
              <div style="width: 150px">{{ item.title }}</div>
              <div v-if="index !== 3" class="flex items-center flex-wrap">
                <a-tag v-for="(f, index2) in item.paramList" :key="index2" color="green">
                  <span>{{ f.label }}:</span>
                  <span>{{ f.value }}</span>
                </a-tag>
              </div>
              <div v-if="index === 3" class="flex items-center flex-wrap">
                <div class="ml-4" v-for="(f, index2) in item.paramList" :key="index2">
                  <span>{{ f.label }}:</span>
                  <a-input-number style="margin-left: 15px;" v-model:value="f.value" :controls="false"/>
                </div>
              </div>
              <div class="flex-1"></div>
              <div @click="selectParmar(item)" v-if="index !== 3" class="btn3 ml-2 px-4">
                选中
              </div>
              <div @click="inputParmar(item)" v-if="index === 3" class="btn3 ml-2 px-4">
                查询
              </div>
            </div>
          </div>

          <div class="w-full flex justify-center mt-4">
            <div class="btn-submit-noradius"
                style="
                    background-color: #072499;
                    padding: 5px 10px;
                    width: 100px;
                    font-size: 18px;
                    text-align: center;
                    cursor: pointer;
                    "
                @click="submitParmar()"
            >
              保存阈值
            </div>
          </div>


        </div>

      </a-modal>


      <!-- 查看详情 -->
      <a-modal
          v-model:visible="visibleView"
          :closable="false"
          :footer="null"
          :bodyStyle="{ background: '#020F46' }"
          style="top: 30%"
          width="1200px"
          :maskClosable="false"
      >
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibleView = false">
              <close-outlined style="font-size: 20px"/>
            </div>
          </div>

          <div class="text-center w-full" style="font-size: 24px">
            {{ feedbackViewForm.title }}
          </div>

          <div class="my-2">诊断报告</div>

          <a-table
              style="width: 100%;"
              size="small"
              :row-class-name="getRowClassName"
              :columns="columnsReport"
              :data-source="feedbackViewForm.reports"
              :pagination="false"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'degree'">
                {{ feedbackGradeList.find((p: any) => p.value === text)?.label }}
              </template>
            </template>

          </a-table>

          <div class="my-2">诊断调整</div>
          <div>
            <a-radio-group v-model:value="feedbackViewForm.index" @change="selectSensorFault1">
              <a-radio style="margin-top: 10px" v-for="(item, index) in feedbackViewForm.sensorFaults" :key="index"
                       :value="index">
                <div class="flex items-center">
                  <div>测点：</div>
                  <div>{{ item.sensorPlace }},</div>
                  <div>故障指标：</div>
                  <div>{{ item.faultName }},</div>
                  <div class="mx-4">{{ item.faultFunction }}</div>
                  <div class="flex items-center flex-wrap">
                    <a-tag v-for="(f, index2) in item.originThresholdList" :key="index2" color="green">
                        <span>{{ f.label }}:</span>
                        <span>{{ f.value }}</span>
                    </a-tag>
                  </div>
                </div>
              </a-radio>

            </a-radio-group>
          </div>

          <div class="mt-4 flex justify-center">

            <div class="flex ml-2">
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '1' }"
                  :style="{
                        background:
                        feedbackSearch.type == '1' ? '#072498' : '',
                        }"
                  style="border-radius: 5px 0 0 5px"
                  @click="(feedbackSearch.type = '1'), selectSensorFaultView(feedbackViewForm.index)"
              >
                近一周
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '2' }"
                  :style="{
                        background:
                        feedbackSearch.type == '2' ? '#072498' : '',
                        }"
                  @click="(feedbackSearch.type = '2'), selectSensorFaultView(feedbackViewForm.index)"
              >
                近一月
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '3' }"
                  :style="{
                        background:
                        feedbackSearch.type == '3' ? '#072498' : '',
                        }"
                  @click="(feedbackSearch.type = '3'), selectSensorFaultView(feedbackViewForm.index)"
              >
                近两月
              </div>
              <div
                  class="btn2 px-3 py-1 theme-datesetect"
                  :style="{
                        background:
                        feedbackSearch.type == '0' ? '#072498' : '',
                        }"
                  :class="{ 'text-white theme-datesetected': feedbackSearch.type == '0' }"
                  @click="feedbackSearch.type = '0'"
                  style="border-radius: 0 5px 5px 0"
              >
                自定义
              </div>

              <div class="ml-2" v-if="feedbackSearch.type == '0'">
                <a-range-picker
                    v-model:value="feedbackSearch.date"
                    :disabled-date="disabledDate2"
                    @calendarChange="feedbackSearch"
                />
              </div>

              <div @click="selectSensorFaultView(feedbackViewForm.index)" class="btn3 ml-2 theme-datesetected">
                查询
              </div>

            </div>

          </div>

          <div class="mt-4">
            <div id="feedback2" style="height: 340px" class="w-full"></div>
          </div>


        </div>

      </a-modal>

    </div>
  </a-config-provider>
</template>


<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { SelectProps } from "ant-design-vue";
import { icons } from "../../common/icons";
import Icon from "@ant-design/icons-vue";
import { v4 as uuidv4 } from "uuid";
import { message, FormInstance } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
import {
  transformDate,
  getGroupListByFactory,
  transformDate2,
  getRealFactoryIdList,
  getGroupName,
  getFactoryName
} from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { Dayjs } from "dayjs";
import * as echarts from "echarts";

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang();

let searchModel: any = ref({
  skip: 1,
  take: 20,
  groupId: null,
  machineId: null
})
let groupList: any = ref([]);
let machineList: any = ref([]);
let statusList = [
  { label: '已反馈', value: 1 },
  { label: '已调整', value: 2 },
  { label: '已确认', value: 3 },
  { label: '已关闭', value: 4 }
]
let feedbackGradeList = [
  { label: '准确', value: 0 },
  { label: '轻微', value: 1 },
  { label: '略轻微', value: 2 },
  { label: '严重', value: 3 },
  { label: '略严重', value: 4 },
]
let loading: any = ref(false)
let columns = [
  {
    title: getLanguage('设备名称'),
    dataIndex: "machineName",
  },
  {
    title: getLanguage('所属工厂'),
    dataIndex: "factoryName",
  },
  {
    title: getLanguage('设备区域'),
    dataIndex: "groupName",
  },
  {
    title: getLanguage('反馈时间'),
    dataIndex: "date",
  },
  {
    title: getLanguage('报告严重程度'),
    dataIndex: "gradeName",
  },
  {
    title: getLanguage('故障模式'),
    dataIndex: "faultEvents",
  },
  {
    title: getLanguage('诊断描述'),
    dataIndex: "exportContent",
  },
  {
    title: getLanguage('反馈内容'),
    dataIndex: "degree",
  },
  {
    title: getLanguage('已调整内容'),
    dataIndex: "sonId",
  },
  {
    title: getLanguage('处理状态'),
    dataIndex: "status",
  },
  {
    title: getLanguage('操作'),
    dataIndex: "act",
  },
]
let dataList: any = ref([])

let visible: any = ref(false)
let visibleView: any = ref(false)
let columnsReport = [
  {
    title: '报告时间',
    dataIndex: "date",
  },
  {
    title: '严重程度',
    dataIndex: "gradeName",
  },
  {
    title: '故障模式',
    dataIndex: "faultEvents",
  },
  {
    title: '诊断描述',
    dataIndex: "exportContent",
  },
  {
    title: '客户反馈',
    dataIndex: "degree",
  },
  {
    title: '客户反馈原因',
    dataIndex: "reason",
  },
]
let feedbackForm: any = ref({
  sensorFaults: [{}]
})
let sensorFault: any = ref({})
let feedbackSearch: any = ref({
  type: 1
})

let paramList: any = ref([])

let chartDataList: any = ref({
  xData: [],
  yDatas: []
})
let myChart: any = null
let myChartView: any = null
let chartSelectlenged: any = {}

onMounted(() => {
  init()
})

const init = () => {
  getGroupList()
  getData()
}

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
        (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

// 重置
const reset = () => {
  searchModel.value = {
    skip: 1,
    take: 20,
    groupId: null,
    machineId: null,
    date: null,
    status: null
  }
}

// 查询
const search = () => {
  searchModel.value.skip = 1
  getData()
}

const getData = async () => {
  dataList.value = []
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      machineId: searchModel.value.machineId,
      groupId: searchModel.value.groupId,
      status: searchModel.value.status,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  let result = await request.get('/api/report-feedbacks', config)
  let gradeList = [
    { value: -1, label: "健康", color: '#61c08f' },
    { value: 0, label: "可用", color: '#d2de49' },
    { value: 1, label: "警戒", color: '#cd5f3b' },
    { value: 2, label: "故障", color: '#921e37' },
  ];
  if (result?.data?.list?.length > 0) {
    result.data.list.map((d: any) => {
      d.machineName = d.machine?.machineName
      d.groupName = getGroupName(d.groupId)
      d.factoryName = getFactoryName(d.factoryId)
      d.gradeName = gradeList.find((p: any) => p.value === d?.report?.expertReport?.grade)?.label
      d.exportContent = d.report?.content
      let faults = []
      if (d?.report?.expertReport?.diagnosisDetails?.length > 0) {
        faults = d.report.expertReport.diagnosisDetails.map((d: any) => d.faultEvent)
      }
      faults = [...new Set(faults)]
      d.faultEvents = faults.toString()
    })

    dataList.value = result.data.list
  }

}

// 处理
const dealWith = (data: any) => {
  myChart && myChart.clear()
  let ev = JSON.parse(JSON.stringify(data))
  if (ev?.sensorFaults?.length > 0) {
    ev.sensorFaults.map((d: any) => {
      let labels: string[] = [];
      if (d.originThresholds) {
        labels = Object.keys(d.originThresholds)
      }
      let originThresholdList: any = []
      if (labels?.length) {
        originThresholdList = labels.map((p: any) => ({
          label: p,
          value: d.originThresholds[p]
        }))
      }
      d.originThresholdList = originThresholdList
    })


  }

  feedbackForm.value = {
    machineId: ev.machineId,
    sensorId: ev.sensorId,
    id: ev.id,
    title: `${ev.factoryName}/${ev.groupName}/${ev.machineName}`,
    reports: [
      {
        date: ev.date,
        gradeName: ev.gradeName,
        faultEvents: ev.faultEvents,
        exportContent: ev.exportContent,
        degree: ev.degree,
        reason: ev.reason,
      }
    ],
    sensorFaults: ev?.sensorFaults || [],
    degree: ev.degree
  }
  feedbackSearch.value.type = 1
  feedbackSearch.value.date = null
  visible.value = true
  if (ev?.sensorFaults?.length) {
    feedbackForm.value.index = 0
    feedbackSearch.value.type = 1
    selectSensorFault1({ target: { value: 0 } })
  }
}

const selectSensorFault1 = (ev: any) => {
  let index = ev.target.value
  paramList.value = []
  chartDataList.value = {
    xData: [],
    yDatas: []
  }
  selectSensorFault(index)
}

let searchSave: any = ref({})
const selectSensorFault = async (index: number, id: any = 'feedback1') => {
  if (index==null) return;
  let data = feedbackForm.value.sensorFaults[index]
  sensorFault.value = data

  let startDate = "";
  let endDate: any = "";
  if (feedbackSearch.value.type == 0) {
    if (
        feedbackSearch.value.date &&
        feedbackSearch.value.date.length === 2
    ) {
      startDate = transformDate(feedbackSearch.value.date[0]);
      endDate = transformDate(feedbackSearch.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (feedbackSearch.value.type == 2) {
      num = 30;
    }
    if (feedbackSearch.value.type == 3) {
      num = 60;
    }
    startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
  }

  let singleExpertFaultNames = ["T8", "T14", "T15", "T16", "T17", "T19", "T20", "T21", "T22", "T23", "T30", "T35", "T39", "T40", "T48", "T49", "T51", "T54", "T62", "T63"];
  let isSingle = false
  if (data.scoreName.includes('S')) {
    isSingle = true
  } else {
    if (singleExpertFaultNames.find((p: any) => p === data.scoreName)) {
      isSingle = true
    } else {
      isSingle = false
    }
  }

  let varianceList = [
    { value: 0, variance: 0 },
    { value: 1, variance: -10 },
    { value: 2, variance: -5 },
    { value: 3, variance: 10 },
    { value: 4, variance: 5 },
  ]

  let degree = feedbackForm.value.degree || feedbackViewForm.value.degree

  let search = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)],
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"],
    variance: varianceList.find((p: any) => p.value === degree)?.variance || 0,
    isSingle: isSingle
  }

  let result = await request.post('/scoreModel/score/multi-threshold', search)
  // params_rms 均方根  params_vari绝对值
  if (result?.data?.result?.length > 0) {
    let pData = result.data.result[0]
    for (let i in pData) {
      let d = pData[i]
      if (i === 'params_rms' || i === 'params_vari') {
        for (let i2 in d) {
          d[i2] = Number(d[i2].toFixed(2))
        }
      }
    }

    let objectToArray = (obj: any) => {
      return Object.keys(obj).map(function (key) {
        return {
          label: key,
          value: obj[key]
        };
      });
    }
    let afterParmar = null
    if (paramList.value?.length > 0) {
      afterParmar = paramList.value[3]
    }
    paramList.value = [
      {
        title: '原阈值',
        params: data.originThresholds,
        paramList: objectToArray(data.originThresholds)
      },
      {
        title: '基于平均绝对误差阈值',
        params: pData['params_vari'],
        paramList: objectToArray(pData['params_vari'])
      },
      {
        title: '基于均方根误差阈值',
        params: pData['params_rms'],
        paramList: objectToArray(pData['params_rms'])
      }
    ]
    if (afterParmar) {
      paramList.value.push(afterParmar)
    } else {
      paramList.value.push({
        title: '新阈值',
        params: data.originThresholds,
        paramList: objectToArray(data.originThresholds)
      })
      sensorFault.value.adjustThresholds = data.originThresholds
    }
  }

  // 原阈值数据
  let search1 = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
  }
  searchSave.value = JSON.parse(JSON.stringify(search1))

  let result1 = await getThroldData(search1)

  if (!result1?.length) {
    return message.warning('该测点该时间段暂无分数曲线！')
  }
  result1 = result1.reverse()
  search1.paramArray = paramList.value[1]?.params
  let result2 = await getThroldData(search1)
  result2 = result2.reverse()
  search1.paramArray = paramList.value[2]?.params
  let result3 = await getThroldData(search1)
  result3 = result3.reverse()

  let xData = result1.map((p: any) => p.date)

  let parmarDataInfo = null
  if (chartDataList.value.yDatas?.length > 0) {
    parmarDataInfo = chartDataList.value.yDatas[3]
  }
  chartDataList.value = {
    xData,
    yDatas: [
      {
        title: '调整前趋势',
        yData: result1.map((p: any) => p[data.scoreName])
      },
      {
        title: '基于平均绝对误差阈值趋势',
        yData: result2.map((p: any) => p[data.scoreName])
      },
      {
        title: '基于均方根误差趋势',
        yData: result3.map((p: any) => p[data.scoreName])
      },
    ]
  }
  if (parmarDataInfo) {
    chartDataList.value.yDatas.push(parmarDataInfo)
  } else {
    chartDataList.value.yDatas.push({
      title: '调整后趋势',
      yData: []
    })
  }

  chartSelectlenged = {
    '调整前趋势': true,
    '基于平均绝对误差阈值趋势': true,
    '基于均方根误差趋势': true,
    '调整后趋势': true
  }
  initChart(id)


}

// 选中
const selectParmar = (ev: any) => {
  paramList.value[3].params = ev.params
  paramList.value[3].paramList = ev.paramList
  getAfterData(ev.params)
}

// 输入阈值查询
const inputParmar = async (ev: any) => {
  let paramArray: any = {}
  ev.paramList.map((d: any) => {
    paramArray[d.label] = d.value
  })
  getAfterData(paramArray)
}

// 获取调整后的趋势
const getAfterData = async (paramArray: any) => {
  let search = searchSave.value
  search.paramArray = paramArray
  sensorFault.value.adjustThresholds = paramArray
  let result = await getThroldData(search)
  chartDataList.value.yDatas[3].yData = result.map((p: any) => p[search.funcTag])
  initChart('feedback1')
}


// 绘制趋势图
const initChart = (id: any) => {
  let { xData, yDatas } = chartDataList.value
  const ec = echarts as any;
  if (!document.getElementById(id)) return;
  myChart = ec.init(document.getElementById(id));

  let min = 0,
      max = 100,
      colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  yDatas.map((item: any, index: any) => {
    LengData.push(item.title);
    DataInfo.push({
      name: item.title,
      type: "line",
      data: item.yData,
      smooth: false,
      symbolSize: 3,
      connectNulls: true,
    });
  });

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(chartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChart && myChart.clear();
    myChart && myChart.setOption(option, true);
  }

  myChart
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        chartSelectlenged = params.selected;
        Init(chartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChart && myChart?.clear();
          myChart && myChart?.setOption(option, true);
        }
      });


}


// 获取阈值分数数据
const getThroldData = async (search: any) => {
  let result = await request.post('/scoreModel/score/trim-threshold', search)
  return result?.data?.result || []
}

// 提交阈值
const submitParmar = async () => {
  let feedbackFormInfo = JSON.parse(JSON.stringify(feedbackForm.value))
  let sensorFaults = feedbackFormInfo.sensorFaults
  sensorFaults.map((d: any) => {
    delete d.originThresholdList
  })

  let submit = {
    id: feedbackForm.value.id,
    sensorFaults,
  }

  let result = await request.post('/api/report-feedbacks/adjust', submit)
  if (result?.status === 200) {
    message.success('操作成功!')
    visible.value = false
    getData()
  } else {
    message.warning('操作失败!')
  }
}


// 查看详情
let chartViewList: any = ref([])
let feedbackViewForm: any = ref({})

const view = (data: any) => {
  console.log('data', data)
  myChartView && myChartView.clear()
  let ev = JSON.parse(JSON.stringify(data))
  if (ev?.sensorFaults?.length > 0) {
    ev.sensorFaults.map((d: any) => {
      let labels = Object.keys(d.originThresholds)
      let originThresholdList: any = []
      if (labels?.length) {
        originThresholdList = labels.map((p: any) => ({
          label: p,
          value: d.originThresholds[p]
        }))
      }
      d.originThresholdList = originThresholdList
    })
  }
  feedbackForm.value = {}
  feedbackViewForm.value = {
    machineId: ev.machineId,
    sensorId: ev.sensorId,
    id: ev.id,
    title: `${ev.factoryName}/${ev.groupName}/${ev.machineName}`,
    reports: [
      {
        date: ev.date,
        gradeName: ev.gradeName,
        faultEvents: ev.faultEvents,
        exportContent: ev.exportContent,
        degree: ev.degree,
        reason: ev.reason,
      }
    ],
    sensorFaults: ev?.sensorFaults || [],
    degree: ev.degree
  }
  feedbackSearch.value.type = 1
  feedbackSearch.value.date = null
  visibleView.value = true
  if (ev?.sensorFaults?.length) {
    feedbackViewForm.value.index = 0
    feedbackSearch.value.type = 1
    selectSensorFault2({ target: { value: 0 } })
  }
}

const selectSensorFault2 = (ev: any) => {
  let index = ev.target.value
  paramList.value = []
  chartDataList.value = {
    xData: [],
    yDatas: []
  }
  selectSensorFaultView(index)
}

const selectSensorFaultView = async (index: any) => {
  let data = feedbackViewForm.value.sensorFaults[index]
  let startDate = "";
  let endDate: any = "";
  if (feedbackSearch.value.type == 0) {
    if (
        feedbackSearch.value.date &&
        feedbackSearch.value.date.length === 2
    ) {
      startDate = transformDate(feedbackSearch.value.date[0]);
      endDate = transformDate(feedbackSearch.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (feedbackSearch.value.type == 2) {
      num = 30;
    }
    if (feedbackSearch.value.type == 3) {
      num = 60;
    }
    startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
  }


  let search = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
  }
  let result1 = await getThroldData(search)
  search.paramArray = data.adjustThresholds
  let result2 = await getThroldData(search)


  chartSelectlenged = {
    '调整前趋势': true,
    '调整后趋势': true
  }

  let xData = result1.map((p: any) => p.date)
  chartDataList.value = {
    xData,
    yDatas: [
      {
        title: '调整前趋势',
        yData: result1.map((p: any) => p[data.scoreName])
      },
      {
        title: '调整后趋势',
        yData: result2.map((p: any) => p[data.scoreName])
      },
    ]
  }

  initChart('feedback2')
}


const disabledDate2 = (current: Dayjs) => {
  if (!feedbackSearch.value.date || (feedbackSearch.value.date as any).length === 0) {
    return false;
  }
  const tooLate = feedbackSearch.value.date[0] && current.diff(feedbackSearch.value.date[0], "days") > 180;
  const tooEarly = feedbackSearch.value.date[1] && feedbackSearch.value.date[1].diff(current, "days") > 180;
  return tooEarly || tooLate;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};
</script>

<style lang="less">
.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff
}

.btn2 {
  border: 1px solid #0d53b7;
  cursor: pointer;
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

</style>
