 <template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full box1 p-3 whiteBg">
        

        <a-form
            layout="inline"
            autocomplete="off"
        >
            <a-form-item
            :label="langObj['数据类型']"
            >
                <a-select
                    ref="select"
                    v-model:value="searchModel.type"
                    style="width: 250px"
                    mode="multiple"
                    @change="selectType"
                    :placeholder="langObj['请选择']"
                >
                    <a-select-option v-for="(item, index) in typeList" :key="index" :value="item">{{ item }}</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item
            :label="langObj['指标']"
            >
                <div>
                    <a-tree-select
                        style="width: 300px"
                        show-search
                        class="flex-1 ti"
                        v-model:value="searchModel.quota"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :placeholder="langObj['请选择']"
                        allow-clear
                        multiple
                        :tree-data="quotaList"
                    >
                    </a-tree-select>
                </div>
                
            </a-form-item>

            <a-form-item
            :label="langObj['时间']"
            >
                <a-range-picker style="width: 300px;margin-left: 20px;" v-model:value="searchModel.date" :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
            </a-form-item>

            <div @click="getData" class="btn1 cursor-pointer btn1Bg" >{{langObj['查询']}}</div>
        </a-form>

        <a-spin :spinning="loadding">
        <div id="chart5" class="w-full " style="height: 500px"></div>
    </a-spin>

    </div>
</a-config-provider>
</template>
<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { transformDate2, transformDate } from "../../../common/tools";
import { Dayjs } from 'dayjs';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()

const props:any = defineProps({
    sensorInfo: null,
});
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo

watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);


let searchModel:any = ref({
    date: null,
    type: [langObj.value['高频加速度']],
    quota: [],
})

let typeList = [langObj.value['高频加速度'], langObj.value['温度'], langObj.value['低频加速度X'], langObj.value['低频加速度Y'], langObj.value['低频加速度Z'], langObj.value['速度X'], langObj.value['速度Y'], langObj.value['速度Z']]
let quotaList:any = ref([])
let quotaExpertList:any = []
let quotaListOne:any = [
    {
      title: langObj.value['振动指标'],
      value: langObj.value['振动指标'],
      disabled: false,
      children: [{ title: langObj.value['振动指标-均方根'], value: langObj.value['振动指标-Trms']}]
    },
    {
        title: langObj.value['专业指标'],
        value: langObj.value['专业指标'],
        disabled: false,
        children: []
    }
]
let quotaListMany:any = [
    {
      title: langObj.value['振动指标'],
      value: langObj.value['振动指标'],
      disabled: false,
      children: [
        { title: langObj.value['振动指标-0~0.3KHz均方根'], value: langObj.value['振动指标-Trms_300'],   },
        { title: langObj.value['振动指标-0~1KHz均方根'], value: langObj.value['振动指标-Trms_1000'],  },
        { title: langObj.value['振动指标-1K~10KHz均方根'], value: langObj.value['振动指标-Trms_1000_10000'],  },
        { title: langObj.value['振动指标-10K~25.6KHz均方根'], value: langObj.value['振动指标-Trms_10000_25600'],  },
        { title: langObj.value['振动指标-1K~25.6KHz均方根'], value: langObj.value['振动指标-Trms_1000_25600'],  },
        { title: langObj.value['振动指标-0~25.6KHz均方根'], value: langObj.value['振动指标-Trms'],  },
        { title: langObj.value['振动指标-优化峭度01'], value: langObj.value['振动指标-TLinKurt'] , },
        { title: langObj.value['振动指标-平稳冲击烈度'], value: langObj.value['振动指标-TImpactIntensity'],  },
        { title: langObj.value['振动指标-线性峭度02'], value: langObj.value['振动指标-Tlin_kurt'],  },
        { title: langObj.value['振动指标-峭度因子'], value: langObj.value['振动指标-Tkurt'],  },
        { title: langObj.value['振动指标-峰峰值'], value: langObj.value['振动指标-Tff' ], },
        { title: langObj.value['振动指标-优化峰值'], value: langObj.value['振动指标-TAccelerationPeak'],  },
        { title: langObj.value['振动指标-优化偏度'], value: langObj.value['振动指标-Tlin_bias'],  },
        { title: langObj.value['振动指标-速度均方根'], value: langObj.value['振动指标-TVelocityRMS'],  },
        { title: langObj.value['振动指标-频率集中度'], value: langObj.value['振动指标-Tcon'],  },
        { title: langObj.value['振动指标-0.3~1KHz均方根'], value: langObj.value['振动指标-Trms_300_1000'],  },
        { title: langObj.value['振动指标-1K~3KHz均方根'], value: langObj.value['振动指标-Trms_1000_3000'],  },
        { title: langObj.value['振动指标-3K~6KHz均方根'], value: langObj.value['振动指标-Trms_3000_6000'] , },
        { title: langObj.value['振动指标-6K~12KHz均方根'], value: langObj.value['振动指标-Trms_6000_12000'] , },
        { title: langObj.value['振动指标-12000~25600Hz均方根'], value: langObj.value['振动指标-Trms_12000_25600'] , },
        { title: langObj.value['振动指标-6K~10KHz均方根'], value: langObj.value['振动指标-Trms_6000_10000'] , },
        { title: langObj.value['振动指标-优化均方根'], value: langObj.value['振动指标-TAccelerationRMS'],  },
        { title: langObj.value['振动指标-偏度因子'], value: langObj.value['振动指标-Tbias'] , },
        { title: langObj.value['振动指标-裕度因子'], value: langObj.value['振动指标-Tmargin'] , },
        { title: langObj.value['振动指标-峰值指标'], value: langObj.value['振动指标-Tpp'] , },
        { title: langObj.value['振动指标-包络信息熵'], value: langObj.value['振动指标-Tent_e'] , },
        { title: langObj.value['振动指标-包络幅值峭度'], value: langObj.value['振动指标-Tenv_kurt'] , },
        { title: langObj.value['振动指标-脉冲因子'], value: langObj.value['振动指标-Tpulse'],  },
        { title: langObj.value['振动指标-波形因子'], value: langObj.value['振动指标-Twave'],  },
        { title: langObj.value['振动指标-均值频率'], value: langObj.value['振动指标-TF9'], },
        { title: langObj.value['振动指标-主频带位置'], value: langObj.value['振动指标-Tmainf'],  },
        { title: langObj.value['振动指标-时域信息熵'], value: langObj.value['振动指标-Tent_t'],  },
        { title: langObj.value['振动指标-频域信息熵'], value: langObj.value['振动指标-Tent_f'],  },
        { title: langObj.value['振动指标-电流指标'], value: langObj.value['振动指标-Tcurrent'],  },
      ],
    },
    {
      title: langObj.value['专业指标'],
      value: langObj.value['专业指标'],
      disabled: false,
      children: [],
    },
];
let myChart:any
let dataList:any = []
onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value || !sensorInfo.value.id) return
    console.log('sensorInfo.value', sensorInfo.value)
    searchModel.value = {
        date: null,
        type: [langObj.value['高频加速度']],
        quota: [],
    }
    myChart && myChart.clear()
    getQuotaData()
}

// 获取专业指标
const getQuotaData = async() => {
    let quotaSearch = {
        params: { machineId: sensorInfo.value.machineId},
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get("/api/machines/quota/names", quotaSearch)
    quotaExpertList = []
    if(result.data) {
        quotaExpertList = result.data.map( (d:any) => ({value: langObj.value['专业指标']+'-' + d.value, title: langObj.value['专业指标']+'-' + d.value, name: d.name}) )
    }
    quotaListMany[1].children = quotaExpertList
    selectType([langObj.value['高频加速度']])
}

// 选择数据类型
const selectType = (ev:any) => {
    console.log('ev', ev)
    if(ev && ev.length) {
        for(let item of ev) {
            if(item !== langObj.value['高频加速度']) {
                quotaList.value = JSON.parse(JSON.stringify(quotaListOne))
                searchModel.value.quota = [langObj.value['振动指标-Trms']]
                return
            }
        }
    }
    quotaList.value = JSON.parse(JSON.stringify(quotaListMany))

    console.log('quotaList.value ', quotaList.value )
}

const loadding = ref(false)

const getData = () => {
    if(loadding.value)
    {
        return
    }
    loadding.value = true
    myChart && myChart.clear()
    if(searchModel.value.date && searchModel.value.date.length === 2) {
        searchModel.value.begin = transformDate(searchModel.value.date[0])
        searchModel.value.end = transformDate(searchModel.value.date[1])
    }else {
        let end = transformDate(new Date())
        let begin = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30)
        searchModel.value.begin = transformDate(begin)
        searchModel.value.end = end 
    }

    // 查看是否为均方根
    let searchType = false
    for(let item of searchModel.value.type) {
        if(item !== langObj.value['高频加速度']) {
            searchType = true
            break
        }
    }

    dataList = []
    if(searchType) {
        getDataQuota1()
    } else {
        getDataQuota2()
    }
}

// 获取均方根数据
const getDataQuota1 = async() => {
    let search = searchModel.value


    let searchA = { 
        sensorId: sensorInfo.value.id,
        type:searchModel.value.type,
        begin: search.begin,
        end: search.end,
    }
    let result = await request.post('/api/external/sensors/quota', searchA) 

    console.log('result', result)
    result && result.data &&  result.data.length && result.data.map((data:any) => {
        dataList.push({
            sensorId: sensorInfo.value.id,
            title: sensorInfo.value?.sensorPlace + '-' + data.type,
            date: data.date,
            value: data.value,
        })
    })

    initChart()
    loadding.value = false
}

// 获取通用或者专业指标分数
const getDataQuota2 = async() => {
    for(let quota of searchModel.value.quota) {
        let list = quota.split('-')
        if(list && list.length === 2) {
            if(list[0] === langObj.value['振动指标']) {
                let search = {
                    params: { 
                        begin: searchModel.value.begin,
                        end: searchModel.value.end,
                        sensorId: sensorInfo.value.id,
                        col: list[1],
                    },
                    headers: {
                        requestId: uuidv4(),
                    },
                }
                
                let result = await request.get('/api/scores/hisdata', search)
                if(result && result.data) {
                    dataList.push({
                        sensorId: sensorInfo.value.id,
                        title: sensorInfo.value?.sensorPlace + '-'+langObj.value['振动指标']+'-' + list[1],
                        date: result.data.date,
                        value: result.data.value,
                    })
                }
            }

            if(list[0] === langObj.value['专业指标']) {
                let search = {
                    params: { 
                        begin: searchModel.value.begin,
                        end: searchModel.value.end,
                        sensorId: sensorInfo.value.id,
                        col: list[1],
                    },
                    headers: {
                        requestId: uuidv4(),
                    },
                }

                let result = await request.get('/api/scores/expert/quote', search)
                if(result && result.data) {
                    dataList.push({
                        sensorId: sensorInfo.value.id,
                        title: sensorInfo.value?.sensorPlace + '-'+langObj.value['专业指标']+'-' + list[1],
                        date: result.data.date,
                        value: result.data.value,
                    })
                }
            }
        }
    }
    initChart()
    loadding.value = false
}

const initChart = () => {
    let series: any[] = [];
    let date: any[] = [];
    dataList.map((item:any) => {
        item.data = item.date.map((p:any, i:any) => [p, item.value[i]])
        date = date.concat(item.date);

        series.push({
            name: item.title,
            data: item.data,
            type: 'line',
            yAxisIndex: 0,
            smooth: true,
            lineStyle: {
            width: 1,
            type: 'solid', //'dotted'虚线 'solid'实线
            },
            emphasis: {
            lineStyle: {
                width: 1,
            },
            },
            symbolSize: 1,
        });
    })

    date = [...new Set(date)];
    date.sort(function (m, n) {
      if (m < n) return -1;
      else if (m > n) return 1;
      else return 0;
    });

    if(!document.getElementById("chart5")) return
    const ec = echarts as any;
    myChart = ec.init(document.getElementById("chart5"));
    myChart.clear()
    myChart.setOption({
        legend: {
            data: [],
        },
        grid: {
            left: '3%',
            right: '2%',
            bottom: '10%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: date,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              showMaxLabel: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.3
              }
            },
        },
        yAxis: {
            type: 'value'
        },
        dataZoom: [{ type: 'inside' }],
        series: series,
    });


}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


</script>

<style lang="less" scoped>
/deep/ .ant-picker {
  background-color: #07249933 !important ;
}

.box1 {
    border: 1px solid #0d53b7cc;
    // background: #07249933;
    background: url("@/assets/screen/p_left_bodyb_bg.png") no-repeat;
    background-size: 100% 100%;
}

.btn1 {
    padding: 5px 10px;
    height: 31px;
    color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
    border: 0px
}

/deep/ .ant-select-multiple .ant-select-selection-item {
    background: #07249933;
}


</style>