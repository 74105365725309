import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = { class: "w-full flex items-center" }
const _hoisted_4 = { style: {"width":"35%"} }
const _hoisted_5 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_6 = { style: {"width":"35%"} }
const _hoisted_7 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_8 = { style: {"width":"30%","word-wrap":"break-word"} }
const _hoisted_9 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_10 = { class: "w-full flex" }
const _hoisted_11 = {
  class: "mr-2",
  style: {"font-size":"12px","opacity":"0.6"}
}

import { onMounted, reactive, ref, watch } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import { v4 as uuidv4 } from "uuid";
import { TresCanvas } from '@tresjs/core'
import { CameraControls, Html } from '@tresjs/cientos'
import { HTMLProps } from '@tresjs/cientos/dist/core/misc/html/HTML.vue'

import d3dModel from './d3d-model.vue'

interface Props {
  machineId: string,
  config3d: any,
  showTag?: boolean,
  width?: number,
  height?: number
}

interface MarkPointType {
  id: number
  x: number
  y: number
  z: number
  linePosition: string
  bind: string // sensorId
  label: string
  [index: string]: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'd3d-viewer',
  props: {
    machineId: { default: '' },
    config3d: { default: { d3dMarks: [] } },
    showTag: { type: Boolean, default: true },
    width: { default: 1200 },
    height: { default: 600 }
  },
  setup(__props: any) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

const props = __props

onMounted(async () => {
  markPoints.value = props.config3d?.d3dMarks || []
  modelPath.value = props.config3d?.picture || ""
})

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: "可用",
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: "警戒",
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: "故障",
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const htmlState = reactive<HTMLProps>({
  wrapperClass: 'd3d-mark-box',
  as: 'div',
  sprite: true,
  transform: true,
  distanceFactor: 5,
  center: true,
  zIndexRange: [999, 0], // 1000是modal的默认zIndex，这里超过1000会遮挡modal
})

let modelPath = ref<string>("")
let modelSize = ref<number>(1.5)
let showCanvas = ref<boolean>(false)

//type MarkLinePosition = "center" | "left" | "right"

let markPoints = ref<MarkPointType[]>([]);

const load3d = async () => {
  if (props.config3d.d3dMarks?.length) {
    await get3DData()
  }
  if (props.config3d.d3d?.modelSize) {
    modelSize.value = props.config3d.d3d.modelSize
  }
    showCanvas.value = true
}

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: props.machineId,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    markPoints.value.map((d: any) => {
      if (d.bind) {
        let sensorId = d.bind;
        let sensorInfo = result.data.sensors.find(
          (p: any) => p.id === sensorId
        );
        if (sensorInfo) {
          d.sensorPlace = sensorInfo.sensorPlace;
        } else {
          d.sensorPlace = "";
        }

        let info = result.data.quotas.find(
          (p: any) => p.sensorId === sensorId
        );
        if (info) {
          if (info.values.length) {
            let sz = info.values.find((p: any) => p.type === "速度Z");
            d.sz = sz ? sz.value.toFixed(2) : "0";
            let hz = info.values.find((p: any) => p.type === "高频加速度");
            d.hz = hz ? hz.value.toFixed(2) : "0";
            let temp = info.values.find((p: any) => p.type === "温度");
            d.temp = temp ? temp.value.toFixed(2) : "0";
          }
          d.date = info.date
        }
        let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
          (p: any) => p.id === sensorId
        );
        if (stableInfo) {
          delete stableInfo.id;
          let list = Object.values(stableInfo).sort();
          let score: any = 100;
          if (list && list.length) {
            score = list[0];
          }

          if (score >= 80 && score <= 100) {
            d.condition = 0;
          }
          if (score >= 60 && score < 80) {
            d.condition = 1;
          }
          if (score >= 30 && score < 60) {
            d.condition = 3;
          }
          if (score >= 0 && score < 30) {
            d.condition = 6;
          }
        }
      }
    });
  }
};

watch(() => props.machineId, async () => {
  if (props.machineId) {
    await load3d()
  } else {
    markPoints.value = []
    modelPath.value = ""
  }
}, { immediate: true })


return (_ctx: any,_cache: any) => {
  const _component_TresPerspectiveCamera = _resolveComponent("TresPerspectiveCamera")!
  const _component_TresAmbientLight = _resolveComponent("TresAmbientLight")!
  const _component_TresDirectionalLight = _resolveComponent("TresDirectionalLight")!
  const _component_TresMesh = _resolveComponent("TresMesh")!
  const _component_TresGroup = _resolveComponent("TresGroup")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{"margin":"auto","padding":"0","position":"relative","z-index":"0"}, { width: props.width + 'px', height: props.height + 'px' }])
  }, [
    (_unref(showCanvas))
      ? (_openBlock(), _createBlock(_unref(TresCanvas), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_TresPerspectiveCamera, {
              position: [0, 20, 50],
              near: 0.1,
              far: 10000
            }),
            _createVNode(_unref(CameraControls)),
            _createVNode(_component_TresAmbientLight, {
              color: "#ffffff",
              intensity: 1
            }),
            _createVNode(_component_TresDirectionalLight, {
              color: "#ffffff",
              position: [300, 300, 250],
              intensity: 6
            }),
            _createVNode(_component_TresGroup, { position: [0, -1, 0] }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(d3dModel, {
                      "model-path": _unref(modelPath),
                      "camera-fov": 14,
                      "model-size": _unref(modelSize)
                    }, null, 8, ["model-path", "model-size"])
                  ]),
                  _: 1
                })),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(markPoints), (point, index) => {
                  return (_openBlock(), _createBlock(_component_TresMesh, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Html), _mergeProps({ ref_for: true }, htmlState, {
                        position: [point.x, point.y, point.z]
                      }), {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass(["d3d-mark-line text-white", { 'd3d-mark-line-left': point.linePosition === 'left', 'd3d-mark-line-right': point.linePosition === 'right' }])
                          }, null, 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["d3d-mark-label", { 'd3d-mark-label-left': point.linePosition === 'left', 'd3d-mark-label-right': point.linePosition === 'right' }])
                          }, [
                            (point.bind)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "d3_bq",
                                  style: _normalizeStyle([{
                'border-color': getColor(point.condition),
                'box-shadow': getShadow(point.condition),
              }, {"width":"225px","height":"100px"}])
                                }, [
                                  _createElementVNode("div", _hoisted_1, [
                                    _createElementVNode("div", _hoisted_2, [
                                      _createElementVNode("div", null, _toDisplayString(point.sensorPlace), 1),
                                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{
                      'background-color': getColor2(point.condition),
                    }, {"width":"15px","height":"15px","border-radius":"50%"}])
                                      }, null, 4)
                                    ]),
                                    _createElementVNode("div", _hoisted_3, [
                                      _createElementVNode("div", _hoisted_4, [
                                        _createElementVNode("div", null, _toDisplayString(point.hz) + "m/s²", 1),
                                        _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(langObj)['振动加速度']), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("div", null, _toDisplayString(point.sz) + "mm/s", 1),
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(langObj)['振动速度']), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("div", null, _toDisplayString(point.temp) + "℃", 1),
                                        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)['温度']), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_10, [
                                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                      _createElementVNode("div", _hoisted_11, _toDisplayString(point.date), 1)
                                    ])
                                  ])
                                ], 4))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]),
                        _: 2
                      }, 1040, ["position"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 4))
}
}

})