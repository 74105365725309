import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/screen/p_on_bg.png'
import _imports_1 from '@/assets/screen/p_off_bg.png'
import _imports_2 from '@/assets/screen/p_monitor_bg.png'
import _imports_3 from '@/assets/screen/p_xj_bg.png'
import _imports_4 from '@/assets/screen/p_wg_bg.png'
import _imports_5 from '@/assets/screen/p_zan.png'
import _imports_6 from '@/assets/screen/p_line_right.png'
import _imports_7 from '@/assets/screen/p_zd_bg.png'
import _imports_8 from '@/assets/screen/p_mx_bg.png'
import _imports_9 from '@/assets/screen/p_line_left.png'
import _imports_10 from '@/assets/screen/p_week_bg.png'
import _imports_11 from '@/assets/screen/p_gz_bg.png'
import _imports_12 from '@/assets/screen/map_mark.png'
import _imports_13 from '@/assets/zhenduan.png'
import _imports_14 from '@/assets/weixiu.png'
import _imports_15 from '@/assets/jiancha.png'
import _imports_16 from '@/assets/menxian.png'
import _imports_17 from '@/assets/screen/p_company_bg.png'
import _imports_18 from '@/assets/screen/p_factory_bg.png'
import _imports_19 from '@/assets/screen/p_qu_bg.png'


const _hoisted_1 = { class: "w-full px-3 theme-bg theme-clique theme-common" }
const _hoisted_2 = { class: "theme-bg-img" }
const _hoisted_3 = { class: "p-tit" }
const _hoisted_4 = { class: "p-text-color" }
const _hoisted_5 = {
  class: "w-full flex center_box",
  style: {"margin-top":"-2%"}
}
const _hoisted_6 = { class: "flex flex-col box-size" }
const _hoisted_7 = { class: "w-full state_wrap conditon_state" }
const _hoisted_8 = { class: "title flex items-center" }
const _hoisted_9 = { class: "p-text-color2 ml-10" }
const _hoisted_10 = {
  class: "w-full flex items-center bg_wrap justify-center",
  style: {"padding":"2% 0"}
}
const _hoisted_11 = { style: {"width":"60%","height":"18vh","margin-top":"-10%","position":"relative"} }
const _hoisted_12 = { class: "pie_tex_wrap" }
const _hoisted_13 = { style: {"margin":"0 auto"} }
const _hoisted_14 = { class: "ml-2" }
const _hoisted_15 = { class: "rate_wrap" }
const _hoisted_16 = { class: "ml-2" }
const _hoisted_17 = { class: "rate_wrap" }
const _hoisted_18 = { class: "ml-2" }
const _hoisted_19 = { class: "rate_wrap" }
const _hoisted_20 = { class: "ml-2" }
const _hoisted_21 = { class: "rate_wrap" }
const _hoisted_22 = { class: "w-full state_wrap monitor_state mt-5" }
const _hoisted_23 = { class: "title flex items-center" }
const _hoisted_24 = { class: "p-text-color2 ml-10" }
const _hoisted_25 = { class: "w-full flex items-center bg_wrap" }
const _hoisted_26 = { class: "moitor_clip cursor-pointer" }
const _hoisted_27 = { class: "off_text" }
const _hoisted_28 = { class: "moitor_clip" }
const _hoisted_29 = { class: "monitor_text" }
const _hoisted_30 = {
  style: {"display":"none"},
  class: "w-full state_wrap state_total mt-5"
}
const _hoisted_31 = { class: "title flex items-center" }
const _hoisted_32 = { class: "p-text-color2 ml-10" }
const _hoisted_33 = { class: "w-full bg_wrap" }
const _hoisted_34 = {
  class: "flex items-center",
  style: {"justify-content":"center","padding-bottom":"2%","cursor":"pointer"}
}
const _hoisted_35 = { class: "ml-1" }
const _hoisted_36 = { class: "ml-1" }
const _hoisted_37 = { class: "ml-1" }
const _hoisted_38 = { class: "w-full state_wrap yj_state mt-5" }
const _hoisted_39 = { class: "title flex items-center" }
const _hoisted_40 = { class: "p-text-color2 ml-10" }
const _hoisted_41 = { class: "w-full items-center bg_wrap" }
const _hoisted_42 = { class: "cgq_wrap flex items-center" }
const _hoisted_43 = { class: "cgq_clip img_clip_wrap" }
const _hoisted_44 = { class: "mt-2" }
const _hoisted_45 = { class: "flex-1" }
const _hoisted_46 = { class: "w-full flex" }
const _hoisted_47 = { class: "text total_text" }
const _hoisted_48 = { class: "text low_text" }
const _hoisted_49 = { class: "text normal_text" }
const _hoisted_50 = { class: "cgq_wrap flex items-center" }
const _hoisted_51 = { class: "cgq_clip img_clip_wrap" }
const _hoisted_52 = { class: "mt-2" }
const _hoisted_53 = { class: "flex-1" }
const _hoisted_54 = { class: "w-full flex" }
const _hoisted_55 = { class: "text total_text" }
const _hoisted_56 = { class: "text low_text" }
const _hoisted_57 = { class: "text normal_text" }
const _hoisted_58 = { class: "flex-1 center_center_box" }
const _hoisted_59 = {
  class: "w-full flex",
  style: {"justify-content":"center"}
}
const _hoisted_60 = { class: "title2" }
const _hoisted_61 = { class: "flex center_text" }
const _hoisted_62 = {
  key: 0,
  style: {"width":"1.7vw"},
  src: _imports_5
}
const _hoisted_63 = { class: "center_text_b flex" }
const _hoisted_64 = { style: {"background":"#fff"} }
const _hoisted_65 = { class: "top_bg flex" }
const _hoisted_66 = { class: "text" }
const _hoisted_67 = { class: "flex text_two" }
const _hoisted_68 = { class: "top_bg top_bg2 flex" }
const _hoisted_69 = { class: "text" }
const _hoisted_70 = { class: "flex text_two" }
const _hoisted_71 = { class: "top_bg flex" }
const _hoisted_72 = { class: "text" }
const _hoisted_73 = { class: "flex text_two" }
const _hoisted_74 = { class: "ab_wrap right_bottom" }
const _hoisted_75 = { class: "top_bg flex" }
const _hoisted_76 = { class: "text flex" }
const _hoisted_77 = { style: {"background":"#fff"} }
const _hoisted_78 = { class: "flex text_two" }
const _hoisted_79 = { class: "w-full flex" }
const _hoisted_80 = { style: {"position":"relative","width":"66%","margin":"0 auto"} }
const _hoisted_81 = ["src"]
const _hoisted_82 = { class: "flex flex-col box-size" }
const _hoisted_83 = { class: "w-full state_wrap zd_total" }
const _hoisted_84 = { class: "title flex items-center" }
const _hoisted_85 = { class: "p-text-color2 ml-10" }
const _hoisted_86 = { class: "w-full bg_wrap" }
const _hoisted_87 = { class: "flex items-center tl_wrap" }
const _hoisted_88 = { class: "ml-1" }
const _hoisted_89 = { class: "ml-1" }
const _hoisted_90 = { class: "ml-1" }
const _hoisted_91 = { style: {"width":"100%","height":"100%"} }
const _hoisted_92 = ["id"]
const _hoisted_93 = { class: "w-full state_wrap gz_type_total mt-5" }
const _hoisted_94 = { class: "title flex items-center" }
const _hoisted_95 = { class: "p-text-color2 ml-10" }
const _hoisted_96 = {
  class: "w-full bg_wrap",
  style: {"cursor":"pointer"}
}
const _hoisted_97 = { class: "bubble-chart" }
const _hoisted_98 = ["onClick"]
const _hoisted_99 = { style: {"background":"#fff"} }
const _hoisted_100 = { class: "text-split" }
const _hoisted_101 = { class: "w-full state_wrap state_total mt-5" }
const _hoisted_102 = { class: "title flex items-center" }
const _hoisted_103 = { class: "p-text-color2 ml-10" }
const _hoisted_104 = { class: "w-full bg_wrap" }
const _hoisted_105 = {
  class: "flex items-center",
  style: {"justify-content":"center","padding-bottom":"2%","cursor":"pointer"}
}
const _hoisted_106 = { class: "ml-1" }
const _hoisted_107 = { class: "ml-1" }
const _hoisted_108 = { style: {"width":"100%","height":"100%"} }
const _hoisted_109 = ["id"]
const _hoisted_110 = { style: {"width":"100%","height":"100%"} }
const _hoisted_111 = ["id"]
const _hoisted_112 = { style: {"color":"#fff"} }
const _hoisted_113 = { class: "w-full flex" }
const _hoisted_114 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_115 = { class: "flex items-center" }
const _hoisted_116 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_117 = { key: 0 }
const _hoisted_118 = { key: 1 }
const _hoisted_119 = { key: 2 }
const _hoisted_120 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_121 = {
  key: 5,
  class: "ml-1"
}
const _hoisted_122 = { style: {"color":"#fff"} }
const _hoisted_123 = { class: "w-full flex" }
const _hoisted_124 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_125 = { class: "flex w-full" }
const _hoisted_126 = {
  class: "box machine_wrap",
  style: {"height":"70vh","overflow-y":"auto","min-width":"250px","width":"15%"}
}
const _hoisted_127 = ["onClick"]
const _hoisted_128 = { class: "flex-1 ml-4" }
const _hoisted_129 = { class: "flex items-center" }
const _hoisted_130 = { style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"} }
const _hoisted_131 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_132 = { key: 0 }
const _hoisted_133 = { key: 1 }
const _hoisted_134 = { key: 2 }
const _hoisted_135 = { key: 3 }
const _hoisted_136 = { class: "ml-2" }
const _hoisted_137 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_138 = { class: "ml-2" }
const _hoisted_139 = { key: 0 }
const _hoisted_140 = { key: 1 }
const _hoisted_141 = ["onClick"]
const _hoisted_142 = ["onClick"]
const _hoisted_143 = ["onClick"]
const _hoisted_144 = { style: {"color":"#fff"} }
const _hoisted_145 = { class: "w-full flex" }
const _hoisted_146 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_147 = { style: {"color":"#fff"} }
const _hoisted_148 = { class: "w-full flex" }
const _hoisted_149 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_150 = { class: "w-full flex items-center justify-center" }
const _hoisted_151 = { class: "flex items-center" }
const _hoisted_152 = { class: "flex items-center" }
const _hoisted_153 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_154 = { key: 0 }
const _hoisted_155 = { key: 1 }
const _hoisted_156 = { key: 2 }
const _hoisted_157 = { key: 3 }
const _hoisted_158 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_159 = { class: "ml-2" }
const _hoisted_160 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_161 = { class: "ml-2" }
const _hoisted_162 = { style: {"color":"#fff"} }
const _hoisted_163 = { class: "w-full flex" }
const _hoisted_164 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_165 = { class: "flex items-center" }
const _hoisted_166 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_167 = {
  key: 0,
  class: "w-full flex items-center justify-items-center"
}
const _hoisted_168 = { key: 1 }
const _hoisted_169 = { style: {"color":"#fff"} }
const _hoisted_170 = { class: "w-full flex" }
const _hoisted_171 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_172 = { class: "flex items-center" }
const _hoisted_173 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_174 = { key: 0 }
const _hoisted_175 = { key: 1 }
const _hoisted_176 = { key: 2 }
const _hoisted_177 = ["onClick"]
const _hoisted_178 = { style: {"color":"#fff"} }
const _hoisted_179 = { class: "w-full flex" }
const _hoisted_180 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_181 = { class: "flex items-center" }
const _hoisted_182 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_183 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_184 = { key: 0 }
const _hoisted_185 = { key: 1 }
const _hoisted_186 = { key: 2 }
const _hoisted_187 = { key: 3 }
const _hoisted_188 = { class: "ml-2" }
const _hoisted_189 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_190 = { class: "ml-2" }
const _hoisted_191 = { key: 0 }
const _hoisted_192 = { key: 1 }
const _hoisted_193 = ["onClick"]
const _hoisted_194 = ["onClick"]
const _hoisted_195 = { style: {"color":"#fff"} }
const _hoisted_196 = { class: "w-full flex" }
const _hoisted_197 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_198 = { class: "flex items-center" }
const _hoisted_199 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_200 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_201 = { key: 0 }
const _hoisted_202 = { key: 1 }
const _hoisted_203 = { key: 2 }
const _hoisted_204 = { key: 3 }
const _hoisted_205 = { class: "ml-2" }
const _hoisted_206 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_207 = { class: "ml-2" }
const _hoisted_208 = { key: 0 }
const _hoisted_209 = { key: 1 }
const _hoisted_210 = ["onClick"]
const _hoisted_211 = ["onClick"]
const _hoisted_212 = ["onClick"]
const _hoisted_213 = { style: {"color":"#fff"} }
const _hoisted_214 = { class: "w-full flex" }
const _hoisted_215 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_216 = { class: "flex items-center" }
const _hoisted_217 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_218 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_219 = { key: 0 }
const _hoisted_220 = { key: 1 }
const _hoisted_221 = { key: 2 }
const _hoisted_222 = { key: 3 }
const _hoisted_223 = { class: "ml-2" }
const _hoisted_224 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_225 = { class: "ml-2" }
const _hoisted_226 = { key: 0 }
const _hoisted_227 = { key: 1 }
const _hoisted_228 = ["onClick"]
const _hoisted_229 = ["onClick"]
const _hoisted_230 = ["onClick"]
const _hoisted_231 = { style: {"color":"#fff"} }
const _hoisted_232 = { class: "w-full flex" }
const _hoisted_233 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_234 = { class: "w-full p-tit-bottom" }
const _hoisted_235 = { style: {"width":"47%","margin-left":"26.5%","margin-top":"-3%"} }
const _hoisted_236 = ["onClick"]
const _hoisted_237 = {
  key: 0,
  src: _imports_17
}
const _hoisted_238 = {
  key: 1,
  src: _imports_18
}
const _hoisted_239 = {
  key: 2,
  src: _imports_19
}
const _hoisted_240 = { class: "fac_text" }

import {computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
    import { useRouter } from "vue-router";
    import request from "../../common/request";
    import { v4 as uuidv4 } from "uuid";
    import * as echarts from "echarts";
    import enUS from "ant-design-vue/es/locale/en_US";
    import zhCN from "ant-design-vue/es/locale/zh_CN";
    import {
        transformDate,
        getFactoryName,
        getGroupName,
        transformDate2,
        transformDate3,
        getGroupListByFactory,
        getRealFactoryIdList
    } from "../../common/tools";
    import MapImg from "../../assets/map.png";
    import { langList } from "../../common/lang";
    import { getRealFactoryIdListById } from "../../common/tools";
    import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
    import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
    import { getLanguage } from "../../common/translate"
    import { Dayjs } from 'dayjs';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.css'; // 确保引入样式文件
    import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
    import My3DPie from "./pie3d.vue"
    
export default /*@__PURE__*/_defineComponent({
  __name: 'clique2',
  setup(__props) {

    SwiperCore.use([Navigation, Pagination, Autoplay]);
    
    const router = useRouter();
    let factory_id = ''
    let bottom_select_facId = ref('') // 底部选择的工厂Id
    let bottom_select_groupId = ref('')//底部选择的区域id
    let stopLabel:any = ref('停机')
    let isShowRate:any = ref(false)
    
    let totalTime: any = ref(null) //系统运行时间
    let weightedAverage: any = ref(0)    //全厂运行设备健康分数
    let factoryList: any = ref([])
    let factoryListBottom :any = ref([])//底部工厂数组
    let groupListBottom :any = ref([])//底部区域数组
    const factoryListLen:any = ref(1)
    let groupList: any = ref([])     //区域
    let machineList: any = ref([])   //
    let sensorList: any = ref([])   //
    
    const swiperOptions = {
          slidesPerView: 3, // 一行显示 3 个幻灯片
          spaceBetween: 20, // 幻灯片之间的间距
          centeredSlides: true, // 将当前幻灯片居中
          navigation: true, // 启用导航按钮
          pagination: { clickable: true }, // 启用分页器
          on: {
            click: (swiper: any, event: MouseEvent) => {
              const clickedIndex = swiper.clickedIndex;
              if (clickedIndex !== undefined) {
                swiper.slideTo(clickedIndex); // 点击后滑动到该幻灯片
              }
            },
          },
    };
    let autoplay = ref({
        delay: 10 * 60 * 1000, // 10 分钟（单位：毫秒）
        disableOnInteraction: false, // 用户操作后是否停止自动轮播
    })
    let autoplay2 = ref({
        delay: 60 * 1000, 
        disableOnInteraction: false, // 用户操作后是否停止自动轮播
    })
    let speed = ref(1000)
    // Swiper 实例
    let swiperInstance: any = null;
    const activeIndex = ref(0); // 当前激活的幻灯片索引
    const onSwiper = (swiper: any) => {
        console.log(swiper,"swiperswiperswiperswiperswiper1111")
        swiperInstance = swiper;
    };
    
    // 幻灯片切换时更新激活索引
    const onSlideChange = () => {
        if (swiperInstance) {
        activeIndex.value = swiperInstance.realIndex;
        selectFac(activeIndex.value )
        }
    };
    
    
    // Swiper 实例
    let swiperInstance2: any = null;
    const activeIndex2 = ref(0); // 当前激活的幻灯片索引
    const onSwiper2 = (swiper: any) => {
       
        swiperInstance2 = swiper;
    };
    
    // 幻灯片切换时更新激活索引
    const onSlideChange2 = () => {
        if (swiperInstance2) {
        activeIndex2.value = swiperInstance2.realIndex;
        selectFac(activeIndex2.value )
        }
    };
    // 点击幻灯片时
    const onSlideClick2 = (index: number) => {
        // activeIndex2.value=index;
        selectFac(index)
        
        // if (swiperInstance2) {
        //     if(activeIndex2.value==index){ 
        //         return
        //     }else{
        //         if(index-activeIndex2.value==1){
        //             console.log(111111111)
        //             swiperInstance2.slideNext()
        //         }else if(index-activeIndex2.value==-1){
        //             swiperInstance2.slidePrev()
        //         }else if(index==0){
        //             swiperInstance2.slideNext()
        //          }
        //         else if(index==factoryListBottom.value.length-1){
        //             swiperInstance2.slidePrev()
        //         }
        //         else {
        //             swiperInstance2.slideNext()
        //         }
        //     }
        //     selectFac(index)
        //   }
    };
    
        
    
    
        // setInterval(()=>{
           
        //     if(activeIndex2.value==1){
        //         activeIndex2.value--
        //     }
        //     if(activeIndex2.value==0){
        //         activeIndex2.value++
        //     }
        //     onSlideClick2(activeIndex2.value)
         
        // },1000)
    
     
       
    
    
    
    
    
    // 点击幻灯片时滑动到中间
    const onSlideClick = (index: number) => {
        if (swiperInstance) {
            if(activeIndex.value==index){
                return
            }else{
                if(index-activeIndex.value==1){
                    swiperInstance.slideNext()
                }else if(index-activeIndex.value==-1){
                    swiperInstance.slidePrev()
                }else if(index==0){
                    swiperInstance.slideNext()
                 }
                else if(index==factoryListBottom.value.length-1){
                    swiperInstance.slidePrev()
                }
                else {
                    swiperInstance.slideNext()
                }
            }
            // selectFac(index)
          }
      
    };
    
    
    // 传入数据生成 option
    const optionsData = ref([
    // { name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
    // { name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
    // { name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
    // { name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
    ])//3d饼图数据
    const onChange = (current: number) => {
          console.log(current);
    };
    let conditionSelectFac = ref('')    //设备健康状态统计 工厂选择
    let conditionChartData: any = ref([
            { name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
            { name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
            { name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
            { name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
        ])
    let machinesAllV :any = ref(0)
    
    let statusSelectFac = ref('')    //设备运行状态统计 工厂选择
    let statusChartData: any = ref([      //设备运行状态统计 数据
        { label: '运行', num: 0, color: '#06DEF5' },
        { label: '停机', num: 0, color: '#ADADAD' },
        { label: '测点', num: 0, color: '#ADADAD' },
    ])
    let handleStatusList:any=ref([
        { label: '未处理', value: 2 },
        { label: '已处理', value: 3 },
    ])
    
    
    // 设备报警数量统计时间
    let machineWarningTime = ref(1)
    let machineWarningFac = ref('')
    
    
    // 各厂报警数量排行
    let factoryWarningTime = ref(1)
    let factoryWarningChartData: any = ref([])
    
    // 智能报警统计
    let warningTrendTime = ref(1)
    let warningTrendFac = ref('')
    let warningBarChartData: any = ref([])
    let warningBarChartDataSplit:any=ref([])
    
    let factory_name: any = ref('')
    let factory_info: any = ref({})
    
    
    let langObj: any = ref({})
    let language: any = ref('Chinese')
    let theme: any = ref('blue')
    const getLang = () => {
        theme.value = localStorage.getItem('theme') || 'blue'
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()
    
    let facPic = ref('')
    let localeList:any = ref([])
    
    onMounted(() => {
        getLang()
        init()
    })
    let isFacType = ref(0) //0 集团 1分厂 2区域
    const init =async () => {
        factory_id = localStorage.getItem('factory_id') || ''
        bottom_select_facId.value = factory_id
        // isShowGroup = localStorage.getItem('isShowGroup') || 'false'
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        if (!bottom_select_facId.value) return
        if(bottom_select_facId.value === '616bb0a6e731767afe63723a' || bottom_select_facId.value === '63bfaa5b4e5403690c2f098c' || bottom_select_facId.value === '63e5dcac4e5403690c2f0fe0') {
            stopLabel.value = '待机'
            isShowRate.value = true
        }
        conditionSelectFac.value = bottom_select_facId.value
        statusSelectFac.value = bottom_select_facId.value
        machineWarningFac.value = bottom_select_facId.value
        warningTrendFac.value = bottom_select_facId.value
        await loadFactory()
        // setTimeout(() => {
        //     swiperInstance.slidePrev()
        // }, 5000);
    }
    // 获取工厂
    const loadFactory = async() => {
        localeList.value = []
      
        factoryList.value = []
        const config = {
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get("/api/factories", config)
        if(result.data && result.data.length) {
            // 演示账号
            factoryList.value = result.data.filter((p:any) => p._id === bottom_select_facId.value || p.parentId === bottom_select_facId.value)
            if(factoryList.value.length==1){
                 isFacType.value= 1;
                 let groupListBottomc =factoryList.value.concat(factoryList.value[0].groups) 
                    groupListBottom.value = JSON.parse(JSON.stringify(groupListBottomc))  ;
                    for(let i =0;i<groupListBottom.value.length;i++){
                        groupListBottom.value[i].selected = false;
                        if(groupListBottom.value[i].factoryName){
                            groupListBottom.value[i].selected = true;
                        }
                    }
                    factoryListLen.value=Math.ceil((groupListBottomc.length)/3)
                    factoryListBottom.value = JSON.parse(JSON.stringify(groupListBottom.value));
            }else{
                isFacType.value = 0;
                for(let i =0;i<factoryList.value.length;i++){
                    factoryList.value[i].selected = false;
                    if(!factoryList.value[i].parentId){
                        factoryList.value[i].selected = true;
                    }
                }
                let factoryListBottomc = []
                factoryListBottomc = JSON.parse(JSON.stringify(factoryList.value))
                factoryListBottom.value =factoryListBottomc;
                factoryListLen.value=Math.ceil((factoryList.value.length)/3)
                // factoryListBottom.value = splitArrayIntoSubarrays(factoryListBottomc, 3);
            }
            console.log(factoryListBottom.value,"factoryListBottomfactoryListBottom")
    
          
    
            //获取运行时间
            totalTime.value = 0
            let facL = factoryList.value.filter((p: any) => p.parentId === bottom_select_facId.value)
            if (facL && facL.length) {
                let time: any = 0
                facL.map((d: any) => {
                    let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                    let currentTime: any = new Date(); // 当前时间
                    let time2 = Math.floor(
                        (currentTime - targetTime) / (1000 * 60 * 60)
                    );
                    if (time2 > time) {
                        time = time2
                    }
                })
                totalTime.value = time
    
            } else {
                let info = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
                if (info) {
                    let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                    let currentTime: any = new Date(); // 当前时间
                    totalTime.value = Math.floor(
                        (currentTime - targetTime) / (1000 * 60 * 60)
                    );
                } else {
                    totalTime.value = 0
                }
    
            }
            factoryInfo()
           
        }
    
    }
    //获取工厂信息
    const factoryInfo = async() => {
        let groups:any = []
        let config: any = {
                params: {
                    factoryId: bottom_select_facId.value
                },
                headers: {
                    requestId: uuidv4(),
                },
            };
            let reslut = await request.get('/api/factories/info', config)
            if(reslut?.data) {
                if(reslut?.data?.picture) {
                    facPic.value = reslut.data.picture.url
                } else {
                    facPic.value = MapImg
                }
    
                let facInfo = reslut.data
                if(facInfo) {
                    if(facInfo?.position?.length > 0) {
                        let list:any = []
                        setTimeout(() => {
                            facInfo?.position?.map((d:any) => {
                                let left = 0
                                let right = 0
                                let div:any = document.getElementById('facImg');
                                let width = div.offsetWidth || 0;
                                let height = div.offsetHeight || 0;
                                left = width * (d&&d[0] / 100) - 25
                                right = height * (d&&d[1] / 100) - 25
                                list.push([left, right])
                            }) 
    
                            localeList.value = list
    
                        }, 500);
                    }
    
                    if(!facInfo.position) {
                        facInfo.position = []
                    }
    
                    factory_name.value = facInfo.factoryName
                    factory_info.value = facInfo
                } else {
                    factory_name.value = ''
                }
               
                factoryList.value.forEach((element: any) => {
                element.groups = element.groups.map((t: any) => ({
                    ...t,
                    Name: element.factoryName + "-" + t.name,
                }));
                groups = groups.concat(element.groups);
            });
             groupList.value = groups;
            
                if(bottom_select_groupId.value){
                    let groups1 = groups.find((p:any) => p.sonID == bottom_select_groupId.value);
                    factory_name.value = groups1.name
                    getMain([groups1]);
    
                }else{
                    getMain(groups);
                }
            
                
            }
    
    }
    const  splitArrayIntoSubarrays=(arr:any, size:any)=> {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => {
            const end = Math.min((i + 1) * size, arr.length); // 使用Math.min来避免越界
            return arr.slice(i * size, end);
        });
    }
    // 获取区域列表
    const getMain = async (groups: any) => {
        const config = {
            params: {
                factoryIds: getRealFactoryIdListById(bottom_select_facId.value) 
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result = await request.get("/api/external/machines/workstatus", config)
    
        weightedAverage.value = 0
        // groupList.value = [];
       
       
        
        machineList.value = [];
        sensorList.value = [];
    
        if (result && result.data) {
            let weightAll = 0
            let count = 0
            groups.forEach((item2: any) => {
                item2.machines = result.data.filter(
                    (t2: any) => t2.groupId == item2.sonID
                );
             
                item2.machines.map((d: any) => {
                    sensorList.value = sensorList.value.concat(d.sensors)
                    if (!d.workStatus) {
                        d.workStatus = {
                            condition: 0,
                            wkStatus: 0
                        }
                    }
                    if (!d.workStableStatus) {
                        d.workStableStatus = {
                            condition: 0,
                            wkStatus: 0
                        }
                    }
                    if(!d.workStableStatus.condition) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                })
                machineList.value = machineList.value.concat(item2.machines)
    
                // groupList.value.push(item2)
            })
            weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
        }
    
    
        setTimeout(() => {
            getConditionChartData() //健康状态统计
            getYjStateBarChartData()//硬件状态统计
            getStatusChartData() //设备监测统计
            getStateBarChartData()//状态排行
            // getFactoryWarningChartData()
            getwarningBarChartData()//智能诊断报警统计
            // getZnZdReport()
            getZdWarnData()
            getDataWeek()
            getMxWarnData()
            getMachineTypeData()//设别类型统计
            getwarningTrendChartData2(1) //设备报警趋势
        }, 500);
    
    }
    
    
              
    
    //诊断报告查看详情
    let visibleDR: any = ref(false);
    let reportDRId: any = ref("");
    let mrDref: any = ref();
    const viewReport = (ev: any) => {
      if (ev.reportIds.length) {
        visibleDR.value = true;
        reportDRId.value = ev.reportIds[0];
        setTimeout(() => {
          mrDref.value.show(reportDRId.value);
        }, 500);
      }
      // router.push('/report/list')
    };
    
    const cancelDR = () => {
      visibleDR.value = false;
    };
    
    //检修报告
    let jxVisible:any = ref(false);
    let mrD = ref()
    const detailsJx = (ev: any) => {
        jxVisible.value = true
        setTimeout(() => {
            mrD.value.show(ev.repairReportId)
        }, 1000);
    }
    
    const cancelJx = (ev: any) => {
            jxVisible.value = false
    }
    
    // 查看已关闭
    let formClose: any = ref({
      closeCause: "",
    });
    let visibledel2 = ref(false);
    const viewClose = (ev: any) => {
      formClose.value.closeCause = ev.closeCause;
      visibledel2.value = true;
    };
    
    // 获取设备健康状态统计/设备运行状态统计 数据
    const getConditionChartData = () => { 
        let n1 = 0, n2 = 0, n3 = 0, n4 = 0;
        let r1 = '0.00', r2 = '0.00', r3 = '0.00', r4 = '0.00';
        let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
        if (facList.length) {
            facList.map((f: any) => {
                f.groups && f.groups.map((g: any) => {
                    g.machines && g.machines.map((m: any) => {
                        if (m.workStableStatus.condition === 0) {
                            n1++
                        }
                        if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                            n2++
                        }
                        if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                            n3++
                        }
                        if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                            n4++
                        }
                    })
                })
            })
        } else {
            let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
            if(bottom_select_groupId.value){
               let group =  f?.groups && f.groups.find((p: any)=>p.sonID == bottom_select_groupId.value);
                group?.machines?.map((m: any) => {
                    if (!m.workStableStatus.condition) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
    
            }else{
                f?.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (!m.workStableStatus.condition) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
            }
            
        }
       
        let machinesAll = (n1+n2+n3+n4)*1;
        machinesAllV.value = 0;
        if(machinesAll>0){
            machinesAllV.value = machinesAll
            r1 = ((n1/machinesAll)*100).toFixed(2)
            r2 = ((n2/machinesAll)*100).toFixed(2)
            r3 = ((n3/machinesAll)*100).toFixed(2)
            r4 = ((n4/machinesAll)*100).toFixed(2)
            
        }else{
            machinesAllV.value = 0
        }
        conditionChartData.value = [
                {name: '健康', value: n1,rate:r1, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
                {name: '可用', value: n2,rate:r2, itemStyle: {opacity: 0.8,color: '#d2de49'}},
                {name: '警戒', value: n3,rate:r3, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
                {name: '故障', value: n4,rate:r4, itemStyle: {opacity: 0.8,color: '#921e37'}}
        ]
       
        optionsData.value= JSON.parse(JSON.stringify(conditionChartData.value))
        
       
       
        // if((n1 + n2 + n3 + n4) >0) {
        //     availabilityRatio.value = (((n1 + n2) / (n1 + n2 + n3 + n4))*100).toFixed(2)
        // } else {
        //     availabilityRatio.value = '0.00'
        // }
       
        
        // initConditionChart()
    }
     
    
    
    
    // 获取设备监测统计 数据
    const getStatusChartData = () => {
        let s1 = 0, s2 = 0 , s3=0;
        let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === statusSelectFac.value)
        if (facList.length) {
            facList.map((f: any) => {
                f.groups && f.groups.map((g: any) => {
                    g.machines && g.machines.map((m: any) => {
                        if (m.workStatus.wkStatus) {
                            s1++
                        } else {
                            s2++
                        }
                    })
                })
            })
        } else {
            let f = factoryList.value.find((p: any) => p._id === statusSelectFac.value)
    
            if(bottom_select_groupId.value){
               let group =  f?.groups && f.groups.find((p: any)=>p.sonID == bottom_select_groupId.value);
              
               group.machines && group.machines.map((m: any) => {
                 s3 = s3 + m?.sensors?.length
                
            
                        if (m.workStatus.wkStatus) {
                            s1++
                        } else {
                            s2++
                        }
                    })
                
               
    
            }else{
                f?.groups && f.groups.map((g: any) => {
                    g.machines && g.machines.map((m: any) => {
                        s3 = s3 + m?.sensors?.length
                        if (m.workStatus.wkStatus) {
                            s1++
                        } else {
                            s2++
                        }
                    })
                })
            }
    
    
    
           
        }
    
        statusChartData.value = [
            { label: '运行', num: s1, color: '#06DEF5' },
            { label: '停机', num: s2, color: '#ADADAD' },
            { label: '测点', num: s3, color: '#ADADAD' },
        ]
    
       
    }
    
    let yjStateData = ref({
        sensorTotal:0,
        sensorLowNum:0,
        sensorNormalNum:0,
        dasToatal:0,
        dasOnlineNum:0,
        dasOffLineNum:0
    })
    //获取硬件状态统计数据
    const getYjStateBarChartData = async () => {
        
    
        // let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
        // let factoryIds = []
        // if (facList && facList.length) {
        //     factoryIds = facList.map((p: any) => p._id)
        // } else {
        //     factoryIds = [machineWarningFac.value]
        // }
    
        let config: any = {
            params: {
                factoryId:machineWarningFac.value,
                begin:"",
                end:""
            },
    
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get('/api/external/hardware/infos', config)
    
    
        // yjStateData.value = []
        let sensorPowerHisData :any = ref([])
        let dasOnlineHisData :any = ref([])
        let name1=['低电量','正常']
        let name2=['离线','在线']
        if (result && result.data) {
            yjStateData.value.sensorLowNum = 0;
            yjStateData.value.sensorNormalNum = 0;
            yjStateData.value.dasOffLineNum = 0;
            yjStateData.value.dasOnlineNum = 0
            if( result.data?.sensorPowerHis?.length>0){
                yjStateData.value.sensorLowNum = result.data?.sensorPowerHis[0].low?.length ;
                yjStateData.value.sensorNormalNum = result.data?.sensorPowerHis[0].normal?.length;
            }
            if( result.data?.dasOnlineHis?.length>0){
                yjStateData.value.dasOffLineNum = result.data?.dasOnlineHis[0].offline?.length ;
                yjStateData.value.dasOnlineNum = result.data?.dasOnlineHis[0].online?.length;
            }
    
           
            let sensorPowerHis = result.data?.sensorPowerHis?.reverse();//传感器
            let dasOnlineHis = result.data.dasOnlineHis?.reverse() //采集单元
            yjStateData.value.sensorTotal = result.data.sensorTotal;
            sensorPowerHis.map((d: any) => {
               
               
                sensorPowerHisData.value.push({
                    date: d.date,
                    low:d.low?.length,
                    normal:d.normal?.length,
                })
            })
           
            yjStateData.value.dasToatal = result.data.dasTotal;
           
            dasOnlineHis.map((d: any) => {
               
                dasOnlineHisData.value.push({
                    date: d.date,
                    low:d.offline?.length,
                    normal:d.online?.length,
                })
            })
           
    
            setTimeout(()=>{
                initYjStatusChart(sensorPowerHisData.value, name1,'chartB1')
            },200)   
            setTimeout(()=>{
                initYjStatusChart(dasOnlineHisData.value, name2,'chartB2')
            },200)   
    
    
    
        }
    
    }
    // 绘制硬件状态统计图
    const initYjStatusChart = (data:any,name:any,id:any) => {
       
        if (!document.getElementById(id)) return
        let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
        let xData: any = []
        xData = data?.map((d: any) => d.date.substring(5, 10))
        let statusListLow = data?.map((d: any) => d.low)
        let statusListNormal = data?.map((d: any) => d.normal)
        let option = {
            grid: {
                top: '4%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                show: false,
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: xData,
                    axisLabel: {
                        color: theme.value=='white'?'#333':"#ffffff",
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel:{
                        show: false, // 是否显示标签
                    },
                    splitLine: {    //网格线
                        lineStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: false //隐藏或显示
                    },
                }
            ],
            series: [
                
                {
                    name: langObj.value[name[1]],
                    type: 'line',
                   
                    emphasis: {
                        focus: 'series'
                    },
                    data: statusListNormal ,
                    areaStyle:{
                        color: {
                            type: 'linear', // 线性渐变
                            x: 0, y: 0, x2: 0, y2: 1, // 渐变方向和范围
                            colorStops: [
                                { offset: 0, color: 'rgba(45, 205, 255, 0.3)' }, // 0%处的颜色
                                { offset: 1, color: 'rgba(45, 205, 255, 0.3)' }  // 100%处的颜色
                            ]
                        }
                    },
                    itemStyle: {
                        
                        color:  '#3AAB85'
                    }
                },
                {
                    name: langObj.value[name[0]],
                    type: 'line',
                    
                    emphasis: {
                        focus: 'series'
                    },
                    data: statusListLow,
                    itemStyle: {
                        color:'#D14E4F'
                    },
                    areaStyle:{
                        color: {
                            type: 'linear', // 线性渐变
                            x: 0, y: 0, x2: 0, y2: 1, // 渐变方向和范围
                            colorStops: [
                                { offset: 0, color: 'rgba(45, 205, 255, 0.3)' }, // 0%处的颜色
                                { offset: 1, color: 'rgba(45, 205, 255, 0.3)' }  // 100%处的颜色
                            ]
                        }
                    },
                },
            ]
        };
    
        myChart && myChart.setOption(option)
    }
    
    //设备趋势报警
    let warningTrendTimeD = ref(1)
    // let warningTrendFac = ref('')
    let warningTrendChartData: any = ref([])
    // 获取设备报警趋势数据
    // let visibleChartE = ref(true)
    const getwarningTrendChartData2 = async (type:any) => {
        warningTrendTimeD.value  = type;
        let time = new Date()
        let endDate = transformDate(time)
        let startDate: any = ''
        let year = time.getFullYear()
        let month = time.getMonth() + 1
        let week = time.getDay()
        //本月
        if (warningTrendTimeD.value === 1) {
            let m = month > 9 ? month : '0' + month
            startDate = `${year}/${m}/01 00:00:00`
        }
        // 本周
        if (warningTrendTimeD.value === 2) {
            let num = week - 1
            let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
            let y = date1.getFullYear()
            let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
            let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
            startDate = `${y}/${m}/${d} 00:00:00`
        }
    
        let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
        let factoryIds = []
        if (facList && facList.length) {
            factoryIds = facList.map((p: any) => p._id)
        } else {
            factoryIds = [warningTrendFac.value]
        }
    
        let config: any = {
            params: {
                skip: 1,
                groupId:bottom_select_groupId.value,
                take: 1000000,
                factoryIds,
                begin: startDate,
                end: endDate,
            },
    
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get('/api/workflows', config)
        warningTrendChartData.value = []
    
        if (result && result.data && result.data.list) {
            result.data.list.reverse()
    
            let dateList = result.data.list.map((d: any) => d.date.substring(0, 10))
            dateList = [... new Set(dateList)]
    
            dateList.map((d: any) => {
                let list = result.data.list.filter((p: any) => p.date.includes(d))
    
                let statusList1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
                let statusList2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
                let statusList3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length
    
                warningTrendChartData.value.push({
                    date: d,
                    statusList1,
                    statusList2,
                    statusList3,
                })
            })
    
    
        }
    
        // visibleChartE.value = false
        // visibleChartE.value = true
    
        setTimeout(() => {
            initWarningTrendChartD()
        }, 200);
    
    }
    
    // 绘制设备报警趋势图
    const initWarningTrendChartD = () => {
        if (!document.getElementById("chartD")) return
        let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
        // myChart.clear()
        let colors: any = ['#DDD246', '#CB6A34', '#B81212']
        let xData: any = []
    
        xData = warningTrendChartData.value.map((d: any) => d.date.substring(5, 10))
        let statusList1 = warningTrendChartData.value.map((d: any) => d.statusList1)
        let statusList2 = warningTrendChartData.value.map((d: any) => d.statusList2)
        let statusList3 = warningTrendChartData.value.map((d: any) => d.statusList3)
    
        let option = {
            // color: colors,
            tooltip: {
                hideDelay: 100,
                transitionDuration: 0,
                animation: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                }
            },
            grid: {
                top: '3%',
                left: '3%',
                right: '5%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                trigger: 'axis'
            },
            dataZoom: [{ type: 'inside' }],
            legend: {
                show: false,
            },
            xAxis: [
                {
                    show: true,
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: xData,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            opacity: 0.3
                        }
                    },
                    axisLabel: {
                        color: theme.value=='white'?'#333':"#ffffff",
                        fontSize: 12,
                        showMaxLabel: true
                    },
                }
            ],
            yAxis: {
                type: 'value',
                scale: true,
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            },
    
            series: [
                {
                    name: langObj.value['可用报警'],
                    type: 'line',
                    data: statusList1,
                    itemStyle: {
                        color: '#d2de49'
                    }
                },
                {
                    name: langObj.value['警戒报警'],
                    type: 'line',
                    data: statusList2,
                    itemStyle: {
                        color: '#cd5f3b'
                    }
                },
                {
                    name: langObj.value['故障报警'],
                    type: 'line',
                    data: statusList3,
                    itemStyle: {
                        color: '#921e37'
                    }
                }
            ]
        };
    
        myChart && myChart.setOption(option)
    }
    
    
    
    
    
    
    
    
    
    // 获取厂区状态排行
    let visibleChartC = ref(true)
    let statePhData:any = ref([])
    let faultTme :any = ref(0)
    
    
    const selectMachineList = (condition: any) => {
        resetMachine()
        machineModelSearch.value.factoryId = conditionSelectFac.value;
        machineVisible.value = true
        machineModelSearch.value.groupId = bottom_select_groupId.value;
        getDataMachine()
    }
    
    const getStateBarChartData = async () => {
        
    
        // let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
        // let factoryIds = []
        // if (facList && facList.length) {
        //     factoryIds = facList.map((p: any) => p._id)
        // } else {
        //     factoryIds = [machineWarningFac.value]
        // }
    
        let config: any = {
            params: {
                factoryId:machineWarningFac.value,
            },
    
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get('/api/external/machines/fault-interval-times', config)
    
        // 1平均故障间隔，2运行设备健康分数
    
        statePhData.value = []
        faultTme.value = 0;
        let statePhData2 :any = ref([])
        let fault_time_all :any = ref(0)
        let repairCount:any = ref(1)
       
        if (result && result.data) {
            if(isFacType.value==0){
                if(result.data.factories?.length>0){
                    getData(result.data.factories)
                }
                
            }
            if(isFacType.value==1){
                let groups = result.data?.groups?.filter((p:any)=>p.factoryId == bottom_select_facId.value) || []
                if(groups.length>0){
                    getData(groups)
                }
               
            }
            if(isFacType.value==2){
                let machines = result.data?.machines?.filter((p:any)=>p.groupId == bottom_select_groupId.value) || []
                if(machines.length>0){
                    getData(machines)
                }
                
            }
        }
        function getData(data:any){
            data?.map((d: any) => {
            
                let statusList1 = d?.repairReportCount>0? (d.onlineHours/d.repairReportCount).toFixed(2) : d.onlineHours?.toFixed(2);
                let statusList2 = d.score
                fault_time_all.value  += d.onlineHours;
                repairCount.value +=  d?.repairReportCount
                statePhData2.value.push({
                    name:isFacType.value==0? d.factoryName : isFacType.value==1? d.groupName : d.machineName,
                    statusList1,
                    statusList2,
                })
            })
            if(repairCount.value?.length>0){
                faultTme.value = (fault_time_all.value/repairCount.value).toFixed(2);
            }else{
                faultTme.value = fault_time_all.value?.toFixed(2); 
            }
            
        }
        statePhData.value = splitArrayIntoSubarrays(statePhData2.value,4)
       for(let i=0;i<statePhData.value.length;i++){
            setTimeout(()=>{
                initMachineWarningChart(statePhData.value[i],'chartC'+i)
            },200)     
       } 
    }
    
    // 绘制区域设备状态排行
    const initMachineWarningChart = (statePhData: any, id: any) => {
        // if (!document.getElementById(id)) return
        // let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
        let xData: any = []
    
        xData = statePhData.map((d: any) => d.name)
        let statusList1 = statePhData.map((d: any) => d.statusList1)
        let statusList2 = statePhData.map((d: any) => d.statusList2)
       
        let option = {
            grid: {
                top: '4%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                containLabel: true
            },
            tooltip: {
                show:true,
                hideDelay: 100,
                transitionDuration: 0,
                animation: false,
                trigger: 'axis',
                confine: false, // 默认是false，设为true会限制在图表区域内
                z: 9999, // 确保层级最高
                appendToBody: true, // 可选，确保 Tooltip 能溢出
                axisPointer: {
                    type: 'cross',
                }
            },
            legend: {
                show: false,
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: xData,
                    axisLabel: {
                        color: theme.value=='white'?'#333':"#ffffff",
                        fontSize: 12,
                        showMaxLabel: true,
                        formatter: (value: string) => {
                            // 截断过长的文字
                            if (value.length > 5) {
                            return value.slice(0, 5) + '...';
                            }
                            return value;
                    },
                    },
                    
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {    //网格线
                        lineStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: true //隐藏或显示
                    },
                }
            ],
             series: [
                {
                    name: langObj.value['平均故障间隔'],
                    type: 'bar',
                    // barGap:"10px",
                    barWidth:20,
                    emphasis: {
                        focus: 'series'
                    },
                    data: statusList1,
                    itemStyle: {
                        barBorderRadius:[5,5,0,0],
                        color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                         offset: 0, color: 'rgba(45, 205, 255, 0.3)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(45, 205, 255, 1)' // 100% 处的颜色
                        }])
                    },
                   
                },
                {
                    name: langObj.value['运行设备健康分数'],
                    type: 'bar',
                    barWidth:20,
                    // barGap:"10px",
                    emphasis: {
                        focus: 'series'
                    },
                    data: statusList2,
                    itemStyle: {
                        barBorderRadius:[5,5,0,0],
                        color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                         offset: 0, color: 'rgba(28, 196, 139, 0.1)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(28, 196, 139, 1)' // 100% 处的颜色
                        }])
                    }
                }
            ]
        };
        // myChart && myChart.setOption(option)
        let myChart = null;
        const list = document.getElementsByClassName(id);
          for (let jj = 0; jj < list.length; jj++) {
            const element = list[jj];
             myChart = echarts.init(element);
             myChart.clear();
             myChart.setOption(option);
          }
    
          myChart&&myChart.on('click',  (params:any)=> {
                console.log(params);
            });
        
        
    
            
    }
    
    // 获取各厂报警数量排行数据
    let visibleChartD = ref(true)
    const getFactoryWarningChartData = async () => {
        let time = new Date()
        let endDate = transformDate(time)
        let startDate: any = ''
        let year = time.getFullYear()
        let month = time.getMonth() + 1
        let week = time.getDay()
    
        //本年
        if (factoryWarningTime.value === 1) {
            startDate = `${year}/01/01 00:00:00`
        }
        //本季度
        if (factoryWarningTime.value === 2) {
            let m: any = 0
            if (month >= 1 && month <= 3) {
                m = 1
            }
            if (month >= 4 && month <= 6) {
                m = 4
            }
            if (month >= 7 && month <= 9) {
                m = 7
            }
            if (month >= 10 && month <= 12) {
                m = 10
            }
            m = m > 9 ? m : '0' + m
            startDate = `${year}/${m}/01 00:00:00`
        }
        //本月
        if (factoryWarningTime.value === 3) {
            let m = month > 9 ? month : '0' + month
            startDate = `${year}/${m}/01 00:00:00`
        }
        // 本周
        if (factoryWarningTime.value === 4) {
            let num = week - 1
            let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
            let y = date1.getFullYear()
            let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
            let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
            startDate = `${y}/${m}/${d} 00:00:00`
        }
    
        // let factoryIds = factoryList.value.map((p:any) => p._id)
    
        let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
        let factoryIds = []
        if (facList && facList.length) {
            factoryIds = facList.map((p: any) => p._id)
        } else {
            factoryIds = [machineWarningFac.value]
        }
    
    
        let config: any = {
            params: {
                skip: 1,
                take: 1000000,
                factoryIds,
                begin: startDate,
                end: endDate,
            },
    
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get('/api/workflows', config)
        factoryWarningChartData.value = []
    
        if (result && result.data) {
            let facIds = [... new Set(result.data.list.map((p: any) => p.factoryId))]
            facIds.map((d: any) => {
                let list = result.data.list.filter((p: any) => p.factoryId === d)
                let condition1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
                let condition2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
                let condition3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length
    
                // factoryWarningChartData.value.push([
                //     { label: '可用报警', num: condition1, color: '#DDD246' },
                //     { label: '警戒报警', num: condition2, color: '#CB6A34' },
                //     { label: '故障报警', num: condition3, color: '#B81212' },
                // ])
    
                factoryWarningChartData.value.push({
                    factoryName: factoryList.value.find((p: any) => p._id === d)?.factoryName,
                    data: [condition1, condition2, condition3],
                })
            })
        }
    
        visibleChartD.value = false
        visibleChartD.value = true
    
        setTimeout(() => {
            initFactoryWarningChart()
        }, 200);
    
    }
    
    // 绘制各厂报警数量排行图
    const initFactoryWarningChart = () => {
        if (!document.getElementById("chartD")) return
        let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
        let list1: any = [], list2: any = [], list3: any = [], list4: any = []
        factoryWarningChartData.value.map((d: any) => {
            list1.push(d.factoryName)
            list2.push(d.data[0])
            list3.push(d.data[1])
            list4.push(d.data[2])
        })
    
        let option = {
            grid: {
                top: '4%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                show: false,
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: list1,
                    axisLabel: {
                        color: theme.value=='white'?'#333':"#ffffff",
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {    //网格线
                        lineStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: true //隐藏或显示
                    },
                }
            ],
            series: [
                {
                    name: langObj.value['可用'],
                    type: 'bar',
                    barGap: 0,
                    emphasis: {
                        focus: 'series'
                    },
                    data: list2,
                    itemStyle: {
                        color: '#d2de49'
                    }
                },
                {
                    name: langObj.value['警戒'],
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: list3,
                    itemStyle: {
                        color: '#cd5f3b'
                    }
                },
                {
                    name: langObj.value['故障'],
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: list4,
                    itemStyle: {
                        color: '#921e37'
                    }
                },
            ]
        };
    
    
        myChart && myChart.setOption(option)
    }
    
    // 获取智能诊断报警统计
    let visibleChartE = ref(true)
    const getwarningBarChartData = async () => {
        let time = new Date()
        let endDate = transformDate(time)
        let startDate: any = ''
        let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
        let factoryIds = []
        if (facList && facList.length) {
            factoryIds = facList.map((p: any) => p._id)
        } else {
            factoryIds = [warningTrendFac.value]
        }
    
        let config: any = {
            params: {
                factoryId : warningTrendFac.value
            },
    
            headers: {
                requestId: uuidv4(),
            },
        };
    
        let result = await request.get('/api/external/workflows/alarm-statistics', config)
        warningBarChartData.value = []
        if (result && result.data) {
            result.data.reverse()
            result.data.map((d: any) => {
                let statusList1 = d.alertCount
                let statusList2 = d.alarmCount
                let statusList3 = d.dangerCount
                warningBarChartData.value.push({
                    date: d.date,
                    statusList1,
                    statusList2,
                    statusList3,
                })
            })
        }
        // visibleChartE.value = false
        // visibleChartE.value = true
        warningBarChartDataSplit.value = splitArrayIntoSubarrays(warningBarChartData.value,5)
       for(let i=0;i<warningBarChartDataSplit.value.length;i++){
            setTimeout(()=>{
                initWarningTrendChart(warningBarChartDataSplit.value[i],'chartE'+i)
            },200)     
       } 
    }
    
    
    // 获取智能诊断报警统计
    const initWarningTrendChart = (warningBarChartData:any,id:any) => {
        // if (!document.getElementById(id)) return
        // let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
        // myChart.clear()
        let colors: any = ['#DDD246', '#CB6A34', '#B81212']
        let xData: any = []
    
        xData = warningBarChartData.map((d: any) => d.date).slice(0,warningBarChartData.length)
        let statusList1 = warningBarChartData.map((d: any) => d.statusList1)
        let statusList2 = warningBarChartData.map((d: any) => d.statusList2)
        let statusList3 = warningBarChartData.map((d: any) => d.statusList3)
        let option = {
            tooltip: {
                show:true,
                hideDelay: 100,
                transitionDuration: 0,
                animation: false,
                trigger: 'axis',
                appendToBody: true, // 可选，确保 Tooltip 能溢出
                axisPointer: {
                    type: 'cross',
                }
            },
            grid: {
                top: '3%',
                left: '3%',
                right: '5%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                trigger: 'axis'
            },
            dataZoom: [{ type: 'inside' }],
            legend: {
                show: false,
            },
            xAxis: [
                {
                    show: true,
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: xData,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            opacity: 0.3
                        }
                    },
                    axisLabel: {
                        color: theme.value=='white'?'#333':"#ffffff",
                        fontSize: 12,
                        showMaxLabel: true,
                    },
                }
            ],
            yAxis: {
                type: 'value',
                scale: true,
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            },
    
            series: [
                {
                    name: langObj.value['可用报警'],
                    type: 'bar',
                    data: statusList1,
                    barWidth:15,
                    itemStyle: {
                        barBorderRadius:[5,5,0,0],
                        color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                         offset: 0, color: 'rgba(221, 210, 70, 0.1)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(221, 210, 70, 1)' // 100% 处的颜色
                        }])
                    }
                },
                {
                    name: langObj.value['警戒报警'],
                    type: 'bar',
                    barWidth:15,
                    data: statusList2,
                    itemStyle:  {
                        barBorderRadius:[5,5,0,0],
                        color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                         offset: 0, color: 'rgba(203, 67, 52, 0.1)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(203, 67, 52, 1)' // 100% 处的颜色
                        }])
                    }
                },
                {
                    name: langObj.value['故障报警'],
                    type: 'bar',
                    barWidth:15,
                    data: statusList3,
                    itemStyle:  {
                        barBorderRadius:[5,5,0,0],
                        color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                         offset: 0, color: 'rgba(158, 3, 4, 0.1)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(158, 3, 4, 1)' // 100% 处的颜色
                        }])
                    }
                }
            ]
        };
    
        // myChart && myChart.setOption(option)
        const list = document.getElementsByClassName(id);
          for (let jj = 0; jj < list.length; jj++) {
            const element = list[jj];
            let myChart = echarts.init(element);
            myChart.clear();
            myChart.setOption(option);
          }
    
         
    
    }
    
    //硬件状态统计弹窗
    let yjVisible = ref(false);
    let typeSelectYj = ref(1)
    let yjModelSearch:any = ref({
        groupId: null,
        machine:"",
        
        status:"",
        dasCode:"",
        total:0,
        skip:1,
        take:10
    })
    const paginationYj = computed(() => ({
      total: yjModelSearch.value.total,
      current: yjModelSearch.value.skip,
      pageSize: yjModelSearch.value.take,
    }));
    
    let machineListYjModel = ref([])
    
    const filterOptionMachineListYj = (input: string, option: any) => {
        return option.name.indexOf(input) >= 0;
    };
    // 选择区域
    const selectYjGroup = (ev:any) => {
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        yjModelSearch.value.machine = ''
        if(ev) {
            machineListYjModel.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
        } else {
            machineListYjModel.value = []
        }
    }
    // 选择设备
    let sensorList2 = ref([])
    const selectYjMachine = async(ev:any) => {
        yjModelSearch.value.sensor = ''
        if(ev) {
            sensorList2.value = await getSensorList(ev)
        } else {
            sensorList2.value = []
        }
    }
    const filterOptionSensorListYj = (input: string, option: any) => {
        return option.sensorPlace.indexOf(input) >= 0;
    };
    const getSensorList = async (machine: any) => {
      
        let config: any = {
            params: {
                machineId: machine,
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result = await request.get("/api/sensors", config);
        if(result && result.data){
            return result.data
        }
    };
    
    let cgqListData =ref([])//传感器列表数据
    let cjListData = ref([])//采集单元列表
    const scqStatusList = ref([
        {label:'在线',value:1,color:"#61c08f"},
        {label:'离线',value:0,color:"#FF0000"},
    ])
    let cgqState:any = ref(null)
    const handleTableChangeYj = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      yjModelSearch.value.skip = pagination.current
      yjModelSearch.value.take = pagination.pageSize;
      if(typeSelectYj.value==1){
            getCgqListData()
        }else{
            getCjListData()
        }
    };
    
    const sjStateList =ref([
        {label:'延迟',value:true,color:"#FF0000"},
    ])
    
    const yjStateColumns = [
        {
            title: getLanguage('区域', language.value),
            dataIndex: "groupName",
        },
        
        {
            title: getLanguage('设备名称', language.value),
            dataIndex: "machineName",
        },
        {
            title: getLanguage('测点', language.value),
            dataIndex: "sensorPlace",
        },
        {
            title: getLanguage('传感器SN号', language.value),
            dataIndex: "sensorSn",
        },
       
        {
            title: getLanguage('传感器类型', language.value),
            dataIndex: "wireless",                                                      
        },
         {
            title: getLanguage('数据延迟', language.value),
            dataIndex: "index_delay",
        },
        
        // {
        //     title: getLanguage('波形数据延迟', language.value),
        //     dataIndex: "bx",
        // },
        // {
        //     title: getLanguage('指标数据延迟', language.value),
        //     dataIndex: "zb",
        // },
        {
            title: getLanguage('电压', language.value),
            dataIndex: "peak_vol",
        },
        {
            title: getLanguage('电量', language.value)+"(%)",
            dataIndex: "bat_vol",
        }
        
    ];
    const yjCjStateColumns = [
        {
            title: getLanguage('数采器编码', language.value),
            dataIndex: "config_code",
        },
        
        {
            title: getLanguage('数采器类型', language.value),
            dataIndex: "config_type_name",
        },
        {
            title: getLanguage('数采器状态', language.value),
            dataIndex: "config_status",
        },
       
        {
            title: getLanguage('数据延时', language.value),
            dataIndex: "delay",                                                      
        },
         {
            title: getLanguage('最后通讯时间', language.value),
            dataIndex: "config_last_online",
        },
        // {
        //     title: getLanguage('波形数据延迟', language.value),
        //     dataIndex: "bx",
        // },
        // {
        //     title: getLanguage('指标数据延迟', language.value),
        //     dataIndex: "zb",
        // },
        
    ];
    const selelctTypeYj = (type:any) => {
        typeSelectYj.value = type;
        yjModelSearch.value.skip = 1;
      
        cgqListData.value=[]
        cjListData.value=[]
        if(typeSelectYj.value==1){
            getCgqListData()
        }else{
            getCjListData()
        }
    }
    const selectYjState = (type:any,state:any) => {
        yjVisible.value = true;
        yjModelSearch.value.groupId = bottom_select_groupId.value;
        typeSelectYj.value = type;
        cgqState.value = state
       
    
        if(typeSelectYj.value==1){
            cgqListData.value=[]
            yjModelSearch.value.skip = 1;
            getCgqListData()
        }else{
            cjListData.value=[];
            yjModelSearch.value.skip = 1;
            yjModelSearch.value.status = state;
            getCjListData()
        }
        
       
    }
    const reseYjSearch =()=>{
            yjModelSearch.value={
                groupId: null,
                machine:"",
                status:"",
                dasCode:"",
                total:0,
                skip:1,
                take:10
        }
        if(typeSelectYj.value==1){
            cgqListData.value=[]
            yjModelSearch.value.skip = 1;
            getCgqListData()
        }else{
            cjListData.value=[]
            yjModelSearch.value.skip = 1;
            getCjListData()
        }
    }
    //获取硬件传感器列表
    const getCgqListData = async(state:any) => {
        let config: any = {
            params: {
                groupId:yjModelSearch.value.groupId,
                machineId: yjModelSearch.value.machine,
                isBatteryNormal:cgqState.value,
                skip:yjModelSearch.value.skip,
                take:yjModelSearch.value.take,
               
                factoryId:bottom_select_facId.value,
               
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let  result = await request.get('/api/external/hardware/sensor/list', config)
        if(result && result.status === 200) {
            cgqListData.value=result?.data?.list;
            yjModelSearch.value.total = result?.data?.count
        }
       
    }
    
    //获取采集单元列表
    const getCjListData = async() => {
       
       
        let config: any = {
            params: {
                factoryId:bottom_select_facId.value,
                status:yjModelSearch.value.status,
                dasCode:yjModelSearch.value.dasCode,
                skip:yjModelSearch.value.skip,
                take:yjModelSearch.value.take,
                // grade:znZdReportSearch.value.grade,
            },
            headers: {
                requestId: uuidv4(),
            },
        };
       
       let result = await request.get('/api/external/hardware/das/list', config)
       if(result && result.status === 200) {
            cjListData.value=result.data.list;
            // znZdReportData.value = result.data.list
            yjModelSearch.value.total = result.data.count
        }
    }
    
    //智能诊断报告记录
    let znZdReportVisible = ref(false);
    let machineListZdReport = ref([])
    let znZdReportSearch:any = ref({
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
        total: 0,
        skip: 1,
        take: 10,
        sort: 'date',
        grade:null,
        order: -1
    })
    const filterOptionZdReport = (input: string, option: any) => {
        return option.name.indexOf(input) >= 0;
    };
    // 选择区域
    const selectGroup = (ev:any) => {
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        znZdReportSearch.value.machineId = ''
        if(ev) {
            machineListZdReport.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
        } else {
            machineListZdReport.value = []
        }
    }
    
    const znZdReportColumns = [
    {
            title: getLanguage('报告时间', language.value),
            dataIndex: "date",
        },
        {
            title: getLanguage('区域', language.value),
            dataIndex: "groupName",
        },
        
        {
            title: getLanguage('设备名称', language.value),
            dataIndex: "machineName",
        },
        // {
        //     title: getLanguage('测点', language.value),
        //     dataIndex: "point",
        // },
       
        {
            title: langObj.value['严重程度'],
            dataIndex: 'gradeText',
        },
        {
            title: langObj.value['故障模式'],
            dataIndex: 'faultEvents',
        },
        {
            title: langObj.value['诊断描述'],
            dataIndex: 'content',
        },
       
        {
            title: getLanguage('操作', language.value),
            dataIndex: "act",
        }
        
    ];
    let znZdReportData = ref([])
    const selectZnZdReport = (type:any) => {
        resetZnZdReport()
        znZdReportVisible.value = true;
        getZnZdReport()
    }
    const dates = ref<any>();
    
    const disabledDate = (current: Dayjs) => {
        if (!dates.value || (dates.value as any).length === 0) {
            return false;
        }
        const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
        const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
        return tooEarly || tooLate;
    };
    const onCalendarChange = (val: any) => {
        dates.value = val;
    };
    let gradeList = [
      { value: -1, label: "健康", color: '#61c08f' },
      { value: 0, label: "可用", color: '#d2de49' },
      { value: 1, label: "警戒", color: '#cd5f3b' },
      { value: 2, label: "故障", color: '#921e37' },
    ];
    
    const pagination = computed(() => ({
      total: znZdReportSearch.value.total,
      current: znZdReportSearch.value.skip,
      pageSize: znZdReportSearch.value.take,
    }));
    
    const resetZnZdReport = () => {
        znZdReportSearch.value = {
            groupId: null,
            machineId: null,
            keyword: '',
            date: null,
            total: 0,
            skip: 1,
            take: 10,
            sort: 'date',
            grade:null,
            order: -1
        }
        getZnZdReport()
    }
    const handleTableChangeZdReport = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      if(Object.keys(sorter).length) { 
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znZdReportSearch.value.sort = sorter.field
        znZdReportSearch.value.order = order
      }
      // 处理分页数据
      znZdReportSearch.value.skip = pagination.current
      znZdReportSearch.value.take = pagination.pageSize
      getZnZdReport();
    };
    //获取智能诊断报告
    const getZnZdReport = async() => {
        let config: any = {
            params: {
                skip: znZdReportSearch.value.skip,
                take: znZdReportSearch.value.take,
                type: [1],
                keyword: znZdReportSearch.value.keyword,
                sort: znZdReportSearch.value.sort,
                order: znZdReportSearch.value.order,
                groupId:znZdReportSearch.value.groupId,
                machineId: znZdReportSearch.value.machineId,
                factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
                grade:znZdReportSearch.value.grade,
            },
            headers: {
                requestId: uuidv4(),
            },
        };
    
        if (znZdReportSearch.value.date && znZdReportSearch.value.date.length === 2) {
            config.params.begin = transformDate2(new Date(znZdReportSearch.value.date[0]), 1);
            config.params.end = transformDate2(new Date(znZdReportSearch.value.date[1]), 2);
        }
    
        let result = await request.get('/api/external/reports', config)
    
        if(result && result.status === 200) {
            let member = JSON.parse(localStorage.getItem('memberInfo') || '')
            result.data.list.forEach((element: any) => {
                if (element.expertReport) {
                element.expertReport.faultCause = element.expertReport.faultCause
                    ? element.expertReport.faultCause.replace(/\n/g, "<br>")
                    : "";
                element.gradeText = element.expertReport.grade
    
                let faultEvents = ''
                element.expertReport.diagnosisDetails.forEach((item2: any, index2:any) => {
                    var grade = gradeList.find((t) => t.value == item2.grade);
                    item2.gradeText = grade ? grade.label : "";
                    faultEvents = faultEvents + item2.faultEvent
                });
    
                element.faultEvents = faultEvents
                }
                if (element.machines && element.machines[0]) {
                element.machineName = element.machines[0].machineName;
                // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
                element.groupName = getGroupName(element.machines[0]?.groupId)
                }
            });
    
            znZdReportData.value = result.data.list
            znZdReportSearch.value.total = result.data.count
        }
    }
    const goZdReport = () =>{
        router.push('/intelligent-diagnosis-manage/listReport')
    }
    
    //智能周报记录
    let znWeekReportVisible = ref(false);
    let typeListWeek = [
        {label: '周报', value: 3},
        {label: '月报', value: 4},
    ]
    let weekData = ref([])
    let znWeekReportSearch:any = ref({
        date: null,
        type: null,
        total: 0,
        skip: 1,
        take: 10,
        sort: 'date',
        order: -1,
        groupId:"",
        type:3
    })
    const paginationWeek = computed(() => ({
      total: znWeekReportSearch.value.total,
      current: znWeekReportSearch.value.skip,
      pageSize: znWeekReportSearch.value.take,
    }));
    
    const znWeekReportColumns = [
    {
            title: langObj.value['报告周期'],
            dataIndex: 'cycle',
        },
        {
            title: langObj.value['报告标题'],
            dataIndex: 'title',
        },
        {
            title: langObj.value['报告类型'],
            dataIndex: 'type',
        },
        {
            title: langObj.value['健康设备']+'（台）',
            dataIndex: 'count1',
        },
        {
            title: langObj.value['可用设备']+'（台）',
            dataIndex: 'count2',
        },
        {
            title: langObj.value['警戒设备']+'（台）',
            dataIndex: 'count3',
        },
        {
            title: langObj.value['故障设备']+'（台）',
            dataIndex: 'count4',
        },
        {
            title: langObj.value['分析周期'],
            dataIndex: 'cycle2',
        },
        {
            title: langObj.value['报告时间'],
            dataIndex: 'date',
        },
        {
            title: langObj.value['操作'],
            dataIndex: 'act',
        },
        
       
        
    ];
    const selectZnWeekReport = () => {
        resetWeek()
        znWeekReportVisible.value = true;
        getDataWeek()
    }
    
    const detailsWeek = (ev:any) => {
        router.push('/report/mwDetail/' + ev.id)
    }
    
    
    
    const handleTableChangeWeek = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      if(Object.keys(sorter).length) { 
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znWeekReportSearch.value.sort = sorter.field
        znWeekReportSearch.value.order = order
      }
      // 处理分页数据
      znWeekReportSearch.value.skip = pagination.current
      znWeekReportSearch.value.take = pagination.pageSize
      getDataWeek();
    };
    
    const getDataWeek = async() => {
        let type = znWeekReportSearch.value.type ? [znWeekReportSearch.value.type] : [3, 4]
        if(bottom_select_groupId.value){
            znWeekReportSearch.value.groupId = bottom_select_groupId.value
         }else{
            znWeekReportSearch.value.groupId = ''
         }
        let config: any = {
            params: {
                skip: znWeekReportSearch.value.skip,
                take: znWeekReportSearch.value.take,
                type,
                sort: znWeekReportSearch.value.sort,
                order: znWeekReportSearch.value.order,
                factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
                groupId:znWeekReportSearch.value.groupId
            },
            headers: {
                requestId: uuidv4(),
            },
        };
       
        if (znWeekReportSearch.value.date && znWeekReportSearch.value.date.length === 2) {
            config.params.begin = transformDate2(new Date(znWeekReportSearch.value.date[0]), 1);
            config.params.end = transformDate2(new Date(znWeekReportSearch.value.date[1]), 2);
        }
    
        let result = await request.get('/api/external/reports', config)
       
    
        if(result && result.status === 200) {
            result.data.list.map((d:any) => {
                let count1 = 0, count2 = 0, count3 = 0, count4 = 0
                d.weekReport && d.weekReport.map((i:any) => {
                    if(i.status === '故障') {
                        count4 ++
                    }
                    if(i.status === '警戒') {
                        count3 ++
                    }
                    if(i.status === '可用') {
                        count2 ++
                    }
                    if(i.status === '健康') {
                        count1 ++
                    }
                })
                d.count1 = count1
                d.count2 = count2
                d.count3 = count3
                d.count4 = count4
    
                if(language.value === 'English') {
                    d.title = d.title.replace('周报', 'weekly report')
                }
            })
    
    
            weekData.value = result.data.list
            znWeekReportSearch.value.total = result.data.count
        }
    }
    const getDate = (date: any) => {
      date = new Date(date)
      let year = date.getFullYear()
      let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
      let w = date.getDay(),
        d = date.getDate();
      if (w == 0) {
        w = 7;
      }
    
      if(language.value == 'Chinese') {
        return year + '年' + month + '月' + ' ' + '第' + Math.ceil((d + 6 - w) / 7) + '周'
      } else {
        let monList = [
            {label: 'January', value: '01'},
            {label: 'February', value: '02'},
            {label: 'March', value: '03'},
            {label: 'April', value: '04'},
            {label: 'May', value: '05'},
            {label: 'June', value: '06'},
            {label: 'July', value: '07'},
            {label: 'August', value: '08'},
            {label: 'January', value: '09'},
            {label: 'October', value: '10'},
            {label: 'November', value: '11'},
            {label: 'December', value: '12'},
        ]
        return monList.find((p:any) => p.value == month)?.label + ' ' + year + ',' + 'Week' + Math.ceil((d + 6 - w) / 7)
      }
    
      
    }
    
    const getDate2 = (ev:any, type:any) => {
        let day = type === 3 ? 7 : 30
        let date = new Date(ev).getTime() - (1000 * 60 * 60 * 24 * day)
        let begin = transformDate3(new Date(date))
        return begin + ' ~ ' + transformDate3(ev)
    }
    const resetWeek = () => {
        znWeekReportSearch.value = {
            ...znWeekReportSearch.value,
            date: null,
            type: 3,
            groupId:""
        }
        // getDataWeek()
    }
    
    //智能门限报警记录
    let znMxWarnVisible = ref(false);
    
    let mxWarnData = ref([])
    let znMxWarnSearch:any = ref({
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:4,
        total:0,
    })
    const paginationMxWarn = computed(() => ({
      total: znMxWarnSearch.value.total,
      current: znMxWarnSearch.value.skip,
      pageSize: znMxWarnSearch.value.take,
    }));
     
    
    const znMxWarnColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
      },
      {
        title: langObj.value["报警类型"],
        dataIndex: "type",
      },
      {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
      },
      {
        title: langObj.value["设备"],
        dataIndex: "machineName",
      },
      {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
      },
       
      {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
      },
     {
            title: getLanguage('操作', language.value),
            dataIndex: "act",
        }
        
    ];
    const selectZnMxWarn = (type:any) => {
        resetMxWarn()
        znMxWarnVisible.value = true
        getMxWarnData()
    }
    
    let machineListMxWarn = ref([])
    // 选择区域
    const selectGroupMxWarn = (ev:any) => {
       
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        znZdWarnSearch.value.machineId = ''
        if(ev) {
            machineListMxWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
        } else {
            machineListMxWarn.value = []
        }
    }
    const getMxWarnData = async () => {
        
      let config: any = {
        params: {
          skip: znMxWarnSearch.value.skip,
          take: znMxWarnSearch.value.take,
          groupId: znMxWarnSearch.value.groupId,
          machineId: znMxWarnSearch.value.machineId,
          type: znMxWarnSearch.value.type,
          handleStatus: znMxWarnSearch.value.handleStatus,
          expertCertification: znMxWarnSearch.value.expertCertification,
          factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
          requestId: uuidv4(),
        },
      };
    
     
    
      if (znMxWarnSearch.value.date && znMxWarnSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
          new Date(znMxWarnSearch.value.date[0]),
          1
        );
        config.params.end = transformDate2(new Date(znMxWarnSearch.value.date[1]), 2);
      }
      
      if (znMxWarnSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
          (p: any) => p.value == znMxWarnSearch.value.condition
        )?.realValue;
        
      }
    
      let result = await request.get("/api/workflows", config);
      if (result) {
        mxWarnData.value = result.data.list;
        znMxWarnSearch.value.total = result.data.total;
      }
    };
    
    const resetMxWarn = () => {
        znMxWarnSearch.value={
            groupId: null,
            machineId: null,
            date: null,
            skip: 1,
            take: 10,
            condition: "",
            handleStatus: null,
            expertCertification: null,
            type:4
        }
        // getMxWarnData()
    }
    const handleTableChangeMxWarn = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znMxWarnSearch.value.sort = sorter.field;
        znMxWarnSearch.value.order = order;
      }
      // 处理分页数据
      znMxWarnSearch.value.skip = pagination.current;
      znMxWarnSearch.value.take = pagination.pageSize;
      getZdWarnData();
    };
    
    
    
    //智能诊断报警记录
    let znZdWarnVisible = ref(false);
    let typeList = [
      { label: "智能诊断", value: 1 },
      // {label: '维修记录', value: 2},
      // {label: '设备检查', value: 3},
      { label: "门限报警", value: 4 },
    ];
    let zdWarnData = ref([])
    let znZdWarnSearch:any = ref({
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:1
    })
    const paginationZdWarn = computed(() => ({
      total: znZdWarnSearch.value.total,
      current: znZdWarnSearch.value.skip,
      pageSize: znZdWarnSearch.value.take,
    }));
     
    
    const znZdWarnColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
      },
      {
        title: langObj.value["报警类型"],
        dataIndex: "type",
      },
      {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
      },
      {
        title: langObj.value["设备"],
        dataIndex: "machineName",
      },
      {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
      },
       
      {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
      },
     {
            title: getLanguage('操作', language.value),
            dataIndex: "act",
        }
        
    ];
    const selectZnZdWarn = (type:any) => {
        znZdWarnVisible.value = true
        resetZdWarn()
        znZdWarnSearch.value.condition = type;
        znZdWarnSearch.value.groupId = bottom_select_groupId.value;
        getZdWarnData()
    }  
    let machineListZdWarn = ref([])
    // 选择区域
    const selectGroupZdWarn = (ev:any) => {
       
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        znZdWarnSearch.value.machineId = ''
        if(ev) {
            machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
        } else {
            machineListZdWarn.value = []
        }
    }
    const getZdWarnData = async () => {
        // if(bottom_select_groupId.value){
        //      znZdWarnSearch.value.groupId = bottom_select_groupId.value
        // }else{
        //     znZdWarnSearch.value.groupId = ""
        // }
    
      let config: any = {
        params: {
          skip: znZdWarnSearch.value.skip,
          take: znZdWarnSearch.value.take,
          groupId: znZdWarnSearch.value.groupId,
          machineId: znZdWarnSearch.value.machineId,
          type: znZdWarnSearch.value.type,
          handleStatus: znZdWarnSearch.value.handleStatus,
          expertCertification: znZdWarnSearch.value.expertCertification,
          factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
          requestId: uuidv4(),
        },
      };
     
     
    
      if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
          new Date(znZdWarnSearch.value.date[0]),
          1
        );
        config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
      }
       
      if (znZdWarnSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
          (p: any) => p.value == znZdWarnSearch.value.condition
        )?.realValue;
        
      }
    
      let result = await request.get("/api/workflows", config);
      if (result) {
        zdWarnData.value = result.data.list;
        znZdWarnSearch.value.total = result.data.total;
      }
    };
    
    const resetZdWarn = () => {
        znZdWarnSearch.value={
            groupId: null,
            machineId: null,
            date: null,
            skip: 1,
            take: 10,
            condition: "",
            handleStatus: null,
            expertCertification: null,
            type:1
        }
        // getZdWarnData()
    }
    const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        znZdWarnSearch.value.sort = sorter.field;
        znZdWarnSearch.value.order = order;
      }
      // 处理分页数据
      znZdWarnSearch.value.skip = pagination.current;
      znZdWarnSearch.value.take = pagination.pageSize;
    
      getZdWarnData();
    };
    
    
    //设备报警趋势弹窗
    let warnTrendVisible = ref(false);
    let warnTrendData = ref([])
    let warnTrendSearch:any = ref({
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        typeList:[1,4]
    })
    const paginationWarnTrend = computed(() => ({
      total: warnTrendSearch.value.total,
      current: warnTrendSearch.value.skip,
      pageSize: warnTrendSearch.value.take,
    }));
     
    
    const warnTrendColumns = [
    {
        title: langObj.value["报警时间"],
        dataIndex: "date",
      },
      {
        title: langObj.value["报警类型"],
        dataIndex: "type",
      },
      {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
      },
      {
        title: langObj.value["设备"],
        dataIndex: "machineName",
      },
      {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
      },
       
      {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
      },
     {
            title: getLanguage('操作', language.value),
            dataIndex: "act",
        }
        
    ];
    const selectWarnTrend = (type:any) => {
        warnTrendVisible.value = true
        resetWarnTrend()
        warnTrendSearch.value.condition = type;
        warnTrendSearch.value.groupId = bottom_select_groupId.value;
        getWarnTrendData()
    }  
    
    // 选择区域
    const selectGroupWarnTrend = (ev:any) => {
       
        let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
        warnTrendSearch.value.machineId = ''
        if(ev) {
            machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
        } else {
            machineListZdWarn.value = []
        }
    }
    const getWarnTrendData = async () => {
       
    
      let config: any = {
        params: {
          skip: warnTrendSearch.value.skip,
          take: warnTrendSearch.value.take,
          groupId: warnTrendSearch.value.groupId,
          machineId: warnTrendSearch.value.machineId,
          typeList:warnTrendSearch.value.typeList,
          handleStatus: warnTrendSearch.value.handleStatus,
          expertCertification: warnTrendSearch.value.expertCertification,
          factoryIds: getRealFactoryIdListById(bottom_select_facId.value),
        },
        headers: {
          requestId: uuidv4(),
        },
      };
     
     
    
      if (warnTrendSearch.value.date && warnTrendSearch.value.date.length === 2) {
        config.params.begin = transformDate2(
          new Date(warnTrendSearch.value.date[0]),
          1
        );
        config.params.end = transformDate2(new Date(warnTrendSearch.value.date[1]), 2);
      }
       
      if (warnTrendSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
          (p: any) => p.value == warnTrendSearch.value.condition
        )?.realValue;
        
      }
    
      let result = await request.get("/api/workflows", config);
      if (result) {
        warnTrendData.value = result.data.list;
        warnTrendSearch.value.total = result.data.total;
      }
    };
    
    const resetWarnTrend = () => {
        warnTrendSearch.value={
            groupId: null,
            machineId: null,
            date: null,
            skip: 1,
            take: 10,
            condition: "",
            handleStatus: null,
            expertCertification: null,
            typeList:[1,4]
        }
        // getWarnTrendData()
    }
    const handleTableChangeWarnTrend = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        warnTrendSearch.value.sort = sorter.field;
        warnTrendSearch.value.order = order;
      }
      // 处理分页数据
      warnTrendSearch.value.skip = pagination.current;
      warnTrendSearch.value.take = pagination.pageSize;
    
      getWarnTrendData();
    };
    
    
    
    
    //设备类型统计
    const bubbles :any=ref([
        { id: 1, x: 0, y: 0,w:7,h:7,class:"anUp",text1:"转子不平衡",text2:20,text3:"条报警", },
        { id: 2, x: 7.2, y: 2.5,w:4.4,h:4.4,class:"anDown",text1:"转子不平衡",text2:20,text3:"条报警"},
        { id: 3, x: 7.2, y: 0.5,w:3,h:3,class:"anLeft",text1:"部件碰摩",text2:20,text3:"条"},
        { id: 4, x: 10.5, y: 6,w:3,h:3,class:"anRight",text1:"部件碰摩",text2:20,text3:"条"},
        { id: 5, x: 12, y: 0,w:3,h:3,class:"anUp2",text1:"部件碰摩",text2:20,text3:"条"},
        { id: 6, x: 16, y: 2.8,w:4.4,h:4.4,class:"anDown",text1:"转子不平衡",text2:20,text3:"条报警"},
        // { id: 6, x: 10.2, y: 2.5,w:3,h:3,class:"anRight",text1:"部件碰摩",text2:20,text3:"条"},
    ])
    const  bubbleStyle=(bubble:any)=> {
          return {
            width:`${bubble.w}vw`,
            height:`${bubble.h}vw`,
            left: `${bubble.x}vw`,
            bottom: `${bubble.y}vw`, // 使用bottom而非top是因为我们的动画是向上移动的，所以需要从底部开始计算位置。
          };
        }
    //获取类型数据
    let machineTypeDataSplit :any=ref([])
    let machineTypeData=ref([])
    const getMachineTypeData = async() => {
        let config: any = {
            params: {
                factoryId:bottom_select_facId.value,
                groupId:bottom_select_groupId.value
               
            },
            headers: {
                requestId: uuidv4(),
            },
        };
       
       let result = await request.get('/api/external/machine-type/alarm-statistics', config)
       if(result && result.status === 200) {
        machineTypeData.value = result.data;
        machineTypeDataSplit.value = splitArrayIntoSubarrays(result.data,7)
        machineTypeDataSplit.value.map((item:any)=>{
                item.map((item2:any,index:any)=>{
                    item2.id=index,
                    item2.x=bubbles.value[index].x,
                    item2.y=bubbles.value[index].y,
                    item2.w=bubbles.value[index].w,
                    item2.h=bubbles.value[index].h,
                    item2.class=bubbles.value[index].class,
                    item2.text1=item2.secondCategory,
                    item2.text1_spli=item2.secondCategory?.length>4?item2.secondCategory?.substring(0,4)+"..." : item2.secondCategory,
                    item2.text2=item2.faultsTotal,
                    item2.text3=bubbles.value[index].text3
                })
            })
        }
    }
    
    
    
    
    //设备类型列表弹窗
    let machineTypeVisible = ref(false)
    let machineTypeList = ref([])
    let machineTypeIndex = ref(0)
    let machineTypeId = ref(null)
    let machineTypeSearch :any = ref({
        total:0,
        skip:1,
        take:10,
        groupId:null,
        machineId:null,
        date:null,
        handleStatus:null,
        condition:null
    
    
    })
    
    const handleTableChangeMachineType = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      machineTypeSearch.value.skip = pagination.current
      machineTypeSearch.value.take = pagination.pageSize
     
      getMachineTypeListData();
    
    };
    
    
    const paginationMachineType = computed(() => ({
      total: machineTypeSearch.value.total,
      current: machineTypeSearch.value.skip,
      pageSize: machineTypeSearch.value.take,
    }));
    
    const resetMachineTypeSearch = ()=>{
        machineTypeSearch.value={
            total:0,
            skip:1,
            take:10,
            groupId:null,
            machineId:null,
            date:null,
            handleStatus:null,
            condition:null
        }
        getMachineTypeListData()
    }
    
    
    
    const machineTypeColumns = [
     {
        title: langObj.value["报警时间"],
        dataIndex: "date",
      },
      {
        title: langObj.value["报警类型"],
        dataIndex: "type",
      },
      {
        title: langObj.value["严重程度"],
        dataIndex: "condition",
      },
      {
        title: langObj.value["设备"],
        dataIndex: "machineName",
      },
      {
        title: langObj.value["报警内容"],
        dataIndex: "diagnosticNotes",
      },
       
      {
        title: langObj.value["处理状态"],
        dataIndex: "handleStatus",
      },
      {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
      }
    ];
    
    //获取machineType列表数据
    const getMachineTypeListData = async() => {
        let config: any = {
            params: {
                factoryId:bottom_select_facId.value,
                groupId:machineTypeSearch.value.groupId,
                skip:machineTypeSearch.value.skip,
                take:machineTypeSearch.value.take,
                machineId:machineTypeSearch.value.machineId,
                begin:null,
                end:null,
                handleStatus:machineTypeSearch.value.handleStatus,
                conditionList:[],
                machineTypeId:machineTypeId.value
            },
            headers: {
                requestId: uuidv4(),
            },
        };
    
        
        if (machineTypeSearch.value.date && machineTypeSearch.value.date.length === 2) {
            config.params.begin = transformDate2(
                new Date(machineTypeSearch.value.date[0]),
            1
            );
            config.params.end = transformDate2(new Date(machineTypeSearch.value.date[1]), 2);
        }
        
        if (machineTypeSearch.value.condition) {
            config.params.conditionList = conditionLists.find(
            (p: any) => p.value == machineTypeSearch.value.condition
            )?.realValue;
            
        }
       
       let result = await request.get('/api/external/machine-type/alarms', config)
       if(result && result.status === 200) {
            machineTypeList.value = result.data.list;
            machineTypeSearch.value.total = result.data.total;
        }
    }
    
    
    
    
    
    const selectMachineType = (id:any) =>{
        machineTypeVisible.value = true;
        machineTypeSearch.value.groupId = bottom_select_groupId.value;
        if(id){
            machineTypeId.value =id;
        }else{
            machineTypeId.value = machineTypeData.value&&machineTypeData.value[0]?.machineTypeId;
        }
       
        getMachineTypeListData()
        // machineTypeList.value = machineTypeData.value[machineTypeIndex.value].faults;
    }
    const selectMachineTypeList = (item:any,index) =>{
        machineTypeId.value = item.machineTypeId;
        getMachineTypeListData()
    }
    
    
    
    
    
    
    
    
    // 设备列表弹框
    let machineModelSearch:any = ref({
        condition: null,
        status: null,
        groupId: null,
        factoryId: '',
        keyWord: '',
        skip:1,
        take:10,
        total:0,
    
    })
    let machineVisible = ref(false)
    let machineDataList: any = ref([])
    
    const viewMachine = (type:any)=>{
        if(type==1){
            router.push('/dashboard/machine')
        }else{
            router.push('/diagnosisN/overview')
        }
     
    }
    
    
    
    const handleTableChangeMachineList = (pagination: any, filters: any, sorter: any) => {
      // 处理分页数据
      machineModelSearch.value.skip = pagination.current
      machineModelSearch.value.take = pagination.pageSize
    
    };
    
    
    const paginationMachineList = computed(() => ({
      total: machineModelSearch.value.total,
      current: machineModelSearch.value.skip,
      pageSize: machineModelSearch.value.take,
    }));
    
    
    
    
    let conditionList = [
        { label: "健康", value: 0, color: "#61c08f" },
        { label: "可用", value: 1, color: "#d2de49" },
        { label: "可用", value: 2, color: "#d2de49" },
        { label: "警戒", value: 3, color: "#cd5f3b" },
        { label: "警戒", value: 4, color: "#cd5f3b" },
        { label: "警戒", value: 5, color: "#cd5f3b" },
        { label: "故障", value: 6, color: "#921e37" },
        { label: "故障", value: 7, color: "#921e37" },
    ];
    
    let conditionLists = [
        { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
        { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
        { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
        { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
    ];
    
    let statusList = [
        { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
        { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
    ];
    
    let whetherList = [
        { label: "是", value: true },
        { label: "否", value: false },
    ];
    
    const machineColumns = [
        {
            title: getLanguage('设备编号', language.value),
            dataIndex: "machineCode",
        },
        {
            title: getLanguage('设备名称', language.value),
            dataIndex: "machineName",
        },
        {
            title: getLanguage('所属工厂', language.value),
            dataIndex: "factoryId",
        },
        {
            title: getLanguage('设备区域', language.value),
            dataIndex: "groupId",
        },
        {
            title: getLanguage('设备位置', language.value),
            dataIndex: "position",
        },
        // {
        //     title: getLanguage('设备厂家', language.value),
        //     dataIndex: "supplier",
        // },
        // {
        //     title: getLanguage('设备型号', language.value),
        //     dataIndex: "model",
        // },
        {
            title: getLanguage('设备类型', language.value),
            dataIndex: "machineTypeAll",
        },
        {
            title: getLanguage('设备SN号', language.value),
            dataIndex: "sn",
        },
        {
            title: getLanguage('巡检序号', language.value),
            dataIndex: "sort",
        },
        // {
        //     title: getLanguage('设备描述', language.value),
        //     dataIndex: "remark",
        // },
        {
            title: getLanguage('健康状态', language.value),
            dataIndex: "condition",
        },
        {
            title: getLanguage('运行状态', language.value),
            dataIndex: "status",
        },
        {
            title: getLanguage('设备运行时间', language.value) + '(' + langObj.value['小时'] + ')',
            dataIndex: "onlineHours",
        },
        {
            title: getLanguage('检修次数', language.value),
            dataIndex: "repairReportsCount",
        },
        {
            title: getLanguage('平均故障间隔时间', language.value) + '(' + langObj.value['小时'] + ')',
            dataIndex: "faultIntervalTimes",
        },
        {
        title: "操作",
        dataIndex: "act",
        },
    ];
    
    // 选择区域
    const selectGroupMachine = (ev:any) => {
        // if(ev){
        //     machineModelSearch.value.groupId = ev;
        // }else{
        //     machineModelSearch.value.groupId = bottom_select_groupId.value
        // }
    }
    
    
    const selectMachineCondition = (condition: any) => {
        resetMachine()
        machineModelSearch.value.condition = condition
        machineModelSearch.value.factoryId = conditionSelectFac.value;
        machineVisible.value = true
        machineModelSearch.value.groupId = bottom_select_groupId.value;
        getDataMachine()
    }
    
    
    const selectMachineStatus = (status: any) => {
        resetMachine()
        machineModelSearch.value.status = status
        machineModelSearch.value.factoryId = statusSelectFac.value
        machineModelSearch.value.groupId = bottom_select_groupId.value;
        machineVisible.value = true
        getDataMachine()
    }
    
    const resetMachine = () => {
        machineModelSearch.value = {
            condition: null,
            status: null,
            groupId: null,
            factoryId: '',
            keyWord: '',
        }
    }
    
    const getDataMachine = async () => {
        // if(bottom_select_groupId.value){
        //     machineModelSearch.value.groupId = bottom_select_groupId.value
        // }else{
        //     machineModelSearch.value.groupId=machineModelSearch.value.groupId
        // }
    
        let config: any = {
            params: {
                groupId: machineModelSearch.value.groupId,
                status: machineModelSearch.value.status,
                factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
                keyWord: machineModelSearch.value.keyWord,
                mode: [1],
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        
    
        if (machineModelSearch.value.condition) {
            config.params.conditionList = conditionLists.find(
                (p: any) => p.value === machineModelSearch.value.condition
            )?.realValue;
        }
    
        let result = await request("/api/machines", config);
    
        if (result && result.data) {
            machineDataList.value = result.data
            machineTypeSearch.value.total = result.data.count;
        } else {
            machineDataList.value = []
        }
    }
    
    // 获取设备健康状态
    const getMachienCondition = (ev: any) => {
        if (ev && ev.workStatus && ev.workStatus.stableCondition) {
            return conditionList.find(
                (p: any) => p.value === ev.workStatus.stableCondition
            );
        } else {
            return conditionList[0];
        }
    };
    
    // 获取设备运行状态
    const getMachienStatus = (ev: any) => {
        if(ev.workStatus?.quotaWkStatus === true) {
            return statusList[0]
        } else if(ev.workStatus?.quotaWkStatus === false) {
            return statusList[1];
        } else if(ev.workStatus?.scoreWkStatus) {
            return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
        } else {
            return statusList[1];
        }
    };
    
    const getRowClassName = (record: any, index: any) => {
        // 根据索引设置不同的类名
        return index % 2 === 0 ? "even-row" : "odd-row";
    };
    
    
    
    // 设备报警列表弹窗
    let warnVisible = ref(false)
    
    const selectWarningStatus = (condition: any) => {
    
        // warnVisible.value = true
    }
    //底部选择工厂
    const selectFac = (index:any)=>{
        activeIndex2.value = index;
        bottom_select_groupId.value=''
        if(factoryList.value.length>1){
            for(let i =0;i<factoryList.value.length;i++){
             factoryList.value[i].selected = false
            }
            factoryListBottom.value = JSON.parse(JSON.stringify(factoryList.value))
            // factoryListBottom.value = splitArrayIntoSubarrays(factoryList.value, 3);
            factoryListBottom.value[index].selected = true;
           
            factory_name.value = factoryListBottom.value[index].factoryName;
            bottom_select_facId.value = factoryListBottom.value[index]._id;
            if(!factoryListBottom.value[index]?.parentId){
                isFacType.value = 0//集团
            }else{
                isFacType.value = 1;//分厂
            }
        }else{
            
            for(let i =0;i<groupListBottom.value.length;i++){
                    groupListBottom.value[i].selected = false;  
                }
                factoryListBottom.value = JSON.parse(JSON.stringify(groupListBottom.value));
                factoryListBottom.value[index].selected = true;
                factory_name.value = factoryListBottom.value[index].factoryName || factoryListBottom.value[index].name;
                
                bottom_select_facId.value = factoryListBottom.value[index]._id;
               
    
            if(!factoryListBottom.value[index]?._id){
                isFacType.value = 2 //此刻选择是区域
                bottom_select_groupId.value =factoryListBottom.value[index].sonID;
                factory_name.value =  factoryListBottom.value[index].name;
            }else{
                isFacType.value = 1 //此刻选择是分厂;
                bottom_select_facId.value = factoryListBottom.value[index]._id
                factory_name.value = factoryListBottom.value[index].factoryName;
            }
        
        }
       
        if(bottom_select_groupId.value){
            znMxWarnSearch.value.groupId = bottom_select_groupId.value;
            znZdWarnSearch.value.groupId = bottom_select_groupId.value;
            machineModelSearch.value.groupId = bottom_select_groupId.value
           
        }else{
            znMxWarnSearch.value.groupId = null;
            znZdWarnSearch.value.groupId = null;
            
        }
       
        if(!bottom_select_facId.value){
            bottom_select_facId.value=factory_id;
        }
        conditionSelectFac.value = bottom_select_facId.value
        statusSelectFac.value = bottom_select_facId.value
        machineWarningFac.value = bottom_select_facId.value
        warningTrendFac.value = bottom_select_facId.value
        
        
       
        
    
        setTimeout(() => {
            factoryInfo()
            // getConditionChartData()
            // getStatusChartData()
            // getStateBarChartData()//状态排行
            // getFactoryWarningChartData()
            // getwarningBarChartData()//智能诊断报警统计
            // // getZnZdReport()
            // getZdWarnData()
            // getDataWeek()
            // getMxWarnData()
        }, 100);
    
    }
    
    
    
    
return (_ctx: any,_cache: any) => {
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(factory_name)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[84] || (_cache[84] = _createElementVNode("div", null, null, -1)),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)['健康状态统计']), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_unref(machinesAllV)), 1),
                        _createElementVNode("span", null, _toDisplayString(_unref(langObj)['台']), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)['设备总数']), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(My3DPie, {
                        style: {"z-index":"2"},
                        optionsData: optionsData.value
                      }, null, 8, ["optionsData"])
                    ]),
                    _cache[85] || (_cache[85] = _createElementVNode("div", { class: "pie_bg_wrap" }, null, -1))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer tl_state_wrap",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMachineCondition(1)))
                    }, [
                      _cache[86] || (_cache[86] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                      _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[0].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, "(" + _toDisplayString(_unref(getLanguage)('健康率',_unref(language).value)) + _toDisplayString(_unref(conditionChartData)[0].rate) + "%)", 1),
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMachineCondition(2)))
                    }, [
                      _cache[87] || (_cache[87] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[1].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, "(" + _toDisplayString(_unref(getLanguage)('可用率',_unref(language).value)) + _toDisplayString(_unref(conditionChartData)[1].rate) + "%)", 1),
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMachineCondition(3)))
                    }, [
                      _cache[88] || (_cache[88] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[2].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, "(" + _toDisplayString(_unref(getLanguage)('警戒率',_unref(language).value)) + _toDisplayString(_unref(conditionChartData)[2].rate) + "%)", 1),
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (selectMachineCondition(4)))
                    }, [
                      _cache[89] || (_cache[89] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                      _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[3].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_21, "(" + _toDisplayString(_unref(getLanguage)('故障率',_unref(language).value)) + _toDisplayString(_unref(conditionChartData)[3].rate) + "%)", 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _cache[90] || (_cache[90] = _createElementVNode("div", null, null, -1)),
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)['设备监测统计']), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", {
                    class: "moitor_clip cursor-pointer",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (selectMachineStatus(1)))
                  }, [
                    _cache[91] || (_cache[91] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                    _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('运行设备数',_unref(language))), 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[0].num), 1),
                      _createTextVNode(_toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (selectMachineStatus(0)))
                    }),
                    _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(getLanguage)('停机设备数',_unref(language))), 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[1].num), 1),
                      _createTextVNode(_toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _cache[92] || (_cache[92] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(getLanguage)('监测测点数',_unref(language))), 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[2].num), 1),
                      _createTextVNode(_toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(langObj)['设备报警趋势']), 1),
                  _cache[93] || (_cache[93] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createVNode(_component_a_dropdown, null, {
                    overlay: _withCtx(() => [
                      _createVNode(_component_a_menu, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_menu_item, {
                            key: "2",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (getwarningTrendChartData2(2)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)["本周"]), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_menu_item, {
                            key: "1",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (getwarningTrendChartData2(1)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)["本月"]), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "text-gray-50 flex items-center p-text-right",
                        onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.machineStatusTime==2?_unref(langObj)["本周"]:_unref(langObj)["本月"]) + " ", 1),
                        _createVNode(_component_CaretDownOutlined, { style: {"font-size":"16px","padding-left":"5px"} })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", {
                      class: "flex items-center ml-4 tl_text",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (selectWarnTrend(2)))
                    }, [
                      _cache[94] || (_cache[94] = _createElementVNode("div", { style: {"background-color":"#d2de49"} }, null, -1)),
                      _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(langObj)['可用报警']), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "flex items-center ml-4 tl_text",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (selectWarnTrend(3)))
                    }, [
                      _cache[95] || (_cache[95] = _createElementVNode("div", { style: {"background-color":"#cd5f3b"} }, null, -1)),
                      _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(langObj)['警戒报警']), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "flex items-center ml-4 tl_text",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (selectWarnTrend(4)))
                    }, [
                      _cache[96] || (_cache[96] = _createElementVNode("div", { style: {"background-color":"#921e37"} }, null, -1)),
                      _createElementVNode("div", _hoisted_37, _toDisplayString(_unref(langObj)['故障报警']), 1)
                    ])
                  ]),
                  _cache[97] || (_cache[97] = _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      id: "chartD",
                      style: {"width":"100%","height":"16vh"}
                    })
                  ], -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _cache[98] || (_cache[98] = _createElementVNode("div", null, null, -1)),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(langObj)['硬件状态统计']), 1)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                      _cache[99] || (_cache[99] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                      _createElementVNode("div", _hoisted_44, _toDisplayString(_unref(getLanguage)('传感器', _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", {
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (selectYjState(1,null))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(yjStateData).sensorTotal), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('总数', _unref(language))), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[13] || (_cache[13] = ($event: any) => (selectYjState(1,false))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_48, _toDisplayString(_unref(yjStateData).sensorLowNum), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('低电量', _unref(language))), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[14] || (_cache[14] = ($event: any) => (selectYjState(1,true))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(yjStateData).sensorNormalNum), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('正常', _unref(language))), 1)
                        ])
                      ]),
                      _cache[100] || (_cache[100] = _createElementVNode("div", {
                        class: "charts_wrap",
                        id: "chartB1",
                        style: {"height":"5vh","width":"90%","margin-left":"5%"}
                      }, null, -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", _hoisted_51, [
                      _cache[101] || (_cache[101] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
                      _createElementVNode("div", _hoisted_52, _toDisplayString(_unref(getLanguage)('采集单元', _unref(language))), 1)
                    ]),
                    _createElementVNode("div", _hoisted_53, [
                      _createElementVNode("div", _hoisted_54, [
                        _createElementVNode("div", {
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (selectYjState(2,null))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_55, _toDisplayString(_unref(yjStateData).dasToatal), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('总数', _unref(language))), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (selectYjState(2,0))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(yjStateData).dasOffLineNum), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('离线', _unref(language))), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[17] || (_cache[17] = ($event: any) => (selectYjState(2,1))),
                          class: "cgq_clip"
                        }, [
                          _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(yjStateData).dasOnlineNum), 1),
                          _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('在线', _unref(language))), 1)
                        ])
                      ]),
                      _cache[102] || (_cache[102] = _createElementVNode("div", {
                        class: "charts_wrap",
                        id: "chartB2",
                        style: {"height":"5vh"}
                      }, null, -1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", null, _toDisplayString(_unref(weightedAverage)), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('分', _unref(language))), 1),
                    (_unref(weightedAverage)>=80)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_62))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("div", null, _toDisplayString(_unref(isFacType)==0?  _unref(getLanguage)('集团运行设备健康度', _unref(language)): _unref(isFacType)==1?_unref(getLanguage)('全厂运行设备健康度', _unref(language)):_unref(getLanguage)('区域运行设备健康度', _unref(language))), 1),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_64, [
                          _createElementVNode("div", null, _toDisplayString(_unref(langObj)['针对运行中的设备, 状态得分的加权平均数计算为健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分']), 1)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_question_circle_outlined, { class: "ml-2 center_wh" })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", {
                  onClick: selectZnZdWarn,
                  class: "ab_wrap left_top pointer"
                }, [
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("div", _hoisted_66, _toDisplayString(_unref(getLanguage)('智能诊断报警', _unref(language))), 1),
                    _createElementVNode("div", _hoisted_67, [
                      _createElementVNode("div", null, _toDisplayString(_unref(znZdWarnSearch).total), 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('次', _unref(language))), 1)
                    ])
                  ]),
                  _cache[103] || (_cache[103] = _createElementVNode("img", {
                    class: "center_bg",
                    src: _imports_6
                  }, null, -1)),
                  _cache[104] || (_cache[104] = _createElementVNode("img", {
                    class: "bottom_bg",
                    src: _imports_7
                  }, null, -1))
                ]),
                _createElementVNode("div", {
                  onClick: selectZnMxWarn,
                  class: "ab_wrap left_bottom pointer"
                }, [
                  _createElementVNode("div", _hoisted_68, [
                    _createElementVNode("div", _hoisted_69, _toDisplayString(_unref(getLanguage)('智能门限报警', _unref(language))), 1),
                    _createElementVNode("div", _hoisted_70, [
                      _createElementVNode("div", null, _toDisplayString(_unref(znMxWarnSearch).total), 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('次', _unref(language))), 1)
                    ])
                  ]),
                  _cache[105] || (_cache[105] = _createElementVNode("img", {
                    class: "center_bg",
                    src: _imports_6
                  }, null, -1)),
                  _cache[106] || (_cache[106] = _createElementVNode("img", {
                    class: "bottom_bg",
                    src: _imports_8
                  }, null, -1))
                ]),
                _createElementVNode("div", {
                  onClick: selectZnWeekReport,
                  class: "ab_wrap right_top pointer"
                }, [
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("div", _hoisted_72, _toDisplayString(_unref(getLanguage)('智能周报', _unref(language))), 1),
                    _createElementVNode("div", _hoisted_73, [
                      _createElementVNode("div", null, _toDisplayString(_unref(znWeekReportSearch).total), 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('份', _unref(language))), 1)
                    ])
                  ]),
                  _cache[107] || (_cache[107] = _createElementVNode("img", {
                    class: "center_bg",
                    src: _imports_9
                  }, null, -1)),
                  _cache[108] || (_cache[108] = _createElementVNode("img", {
                    class: "bottom_bg",
                    src: _imports_10
                  }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_74, [
                  _createElementVNode("div", _hoisted_75, [
                    _createElementVNode("div", _hoisted_76, [
                      _createTextVNode(_toDisplayString(_unref(getLanguage)('平均故障间隔', _unref(language))) + " ", 1),
                      _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", _hoisted_77, [
                            _createElementVNode("div", null, _toDisplayString(_unref(langObj)['平均故障间隔=所有设备总运行时长/（所有设备总检修次数-可用检修次数）']), 1)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_question_circle_outlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_78, [
                      _createElementVNode("div", null, _toDisplayString(_unref(faultTme)>0?(_unref(faultTme)/24)?.toFixed(1): 0.0), 1),
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('天', _unref(language))), 1)
                    ])
                  ]),
                  _cache[109] || (_cache[109] = _createElementVNode("img", {
                    class: "center_bg",
                    src: _imports_9
                  }, null, -1)),
                  _cache[110] || (_cache[110] = _createElementVNode("img", {
                    class: "bottom_bg",
                    src: _imports_11
                  }, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_79, [
                _createElementVNode("div", _hoisted_80, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(localeList), (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      style: _normalizeStyle([{"position":"absolute","color":"red"}, {'left': item[0] + 'px', 'top': item[1]+'px'}])
                    }, _cache[111] || (_cache[111] = [
                      _createElementVNode("img", {
                        style: {"width":"50px"},
                        src: _imports_12,
                        alt: ""
                      }, null, -1)
                    ]), 4))
                  }), 128)),
                  _createElementVNode("img", {
                    id: "facImg",
                    style: {"width":"100%"},
                    src: _unref(facPic),
                    alt: ""
                  }, null, 8, _hoisted_81)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_82, [
              _createElementVNode("div", _hoisted_83, [
                _createElementVNode("div", _hoisted_84, [
                  _createElementVNode("div", _hoisted_85, _toDisplayString(_unref(langObj)['智能诊断报警统计']), 1),
                  _cache[112] || (_cache[112] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_86, [
                  _createElementVNode("div", _hoisted_87, [
                    _createElementVNode("div", {
                      style: {"cursor":"pointer"},
                      class: "flex items-center tl_text",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (selectZnZdWarn(2)))
                    }, [
                      _cache[113] || (_cache[113] = _createElementVNode("div", { style: {"background-color":"#d2de49"} }, null, -1)),
                      _createElementVNode("div", _hoisted_88, _toDisplayString(_unref(langObj)['可用报警']), 1)
                    ]),
                    _createElementVNode("div", {
                      style: {"cursor":"pointer"},
                      class: "flex items-center ml-2 tl_text",
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (selectZnZdWarn(3)))
                    }, [
                      _cache[114] || (_cache[114] = _createElementVNode("div", { style: {"background-color":"#cd5f3b"} }, null, -1)),
                      _createElementVNode("div", _hoisted_89, _toDisplayString(_unref(langObj)['警戒报警']), 1)
                    ]),
                    _createElementVNode("div", {
                      style: {"cursor":"pointer"},
                      class: "flex items-center ml-2 tl_text",
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (selectZnZdWarn(4)))
                    }, [
                      _cache[115] || (_cache[115] = _createElementVNode("div", { style: {"background-color":"#921e37"} }, null, -1)),
                      _createElementVNode("div", _hoisted_90, _toDisplayString(_unref(langObj)['故障报警']), 1)
                    ])
                  ]),
                  _createVNode(_unref(Swiper), {
                    style: {"height":"16.3vh"},
                    class: "swiper-zd",
                    slidesPerView: 1,
                    spaceBetween: 0,
                    autoplay: _unref(autoplay2),
                    speed: _unref(speed),
                    loop: true,
                    pagination: { clickable: true }
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(warningBarChartDataSplit), (item, index) => {
                        return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_91, [
                              _createElementVNode("div", {
                                class: _normalizeClass('chartE'+index),
                                id: 'chartE'+index,
                                style: {"width":"100%","height":"16vh"}
                              }, null, 10, _hoisted_92)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["autoplay", "speed"])
                ])
              ]),
              _createElementVNode("div", _hoisted_93, [
                _createElementVNode("div", _hoisted_94, [
                  _createElementVNode("div", _hoisted_95, _toDisplayString(_unref(langObj)['设备类型报警统计']), 1),
                  _cache[116] || (_cache[116] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_96, [
                  _createVNode(_unref(Swiper), {
                    class: "machine-type-swiper",
                    slidesPerView: 1,
                    spaceBetween: 0,
                    autoplay: _unref(autoplay2),
                    loop: true,
                    direction: "vertical",
                    speed: _unref(speed)
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypeDataSplit), (item, index) => {
                        return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_97, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypeDataSplit)[index], (bubble) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  onClick: ($event: any) => (selectMachineType(bubble.machineTypeId)),
                                  key: bubble.id,
                                  style: _normalizeStyle(bubbleStyle(bubble)),
                                  class: _normalizeClass(["bubble", bubble.class])
                                }, [
                                  _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                                    title: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_99, [
                                        _createElementVNode("div", null, _toDisplayString(bubble.text1), 1)
                                      ])
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_100, _toDisplayString(bubble.text1_spli), 1),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("span", null, _toDisplayString(bubble.text2), 1),
                                          _createTextVNode(_toDisplayString(bubble.text3), 1)
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ], 14, _hoisted_98))
                              }), 128))
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["autoplay", "speed"])
                ])
              ]),
              _createElementVNode("div", _hoisted_101, [
                _createElementVNode("div", _hoisted_102, [
                  _createElementVNode("div", _hoisted_103, _toDisplayString(_unref(isFacType)==2? _unref(langObj)['区域设备状态排行'] : _unref(langObj)['厂区设备状态排行']), 1),
                  _cache[117] || (_cache[117] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_104, [
                  _createElementVNode("div", _hoisted_105, [
                    _createElementVNode("div", {
                      class: "flex items-center tl_text",
                      onClick: selectMachineList
                    }, [
                      _cache[118] || (_cache[118] = _createElementVNode("div", { style: {"background-color":"#00D5FF"} }, null, -1)),
                      _createElementVNode("div", _hoisted_106, _toDisplayString(_unref(langObj)['平均故障间隔']) + "(" + _toDisplayString(_unref(langObj)['小时']) + ")", 1)
                    ]),
                    _createElementVNode("div", {
                      class: "flex items-center ml-4 tl_text",
                      onClick: selectMachineList
                    }, [
                      _cache[119] || (_cache[119] = _createElementVNode("div", { style: {"background-color":"#1CC48B"} }, null, -1)),
                      _createElementVNode("div", _hoisted_107, _toDisplayString(_unref(langObj)['运行设备健康分数']) + "(" + _toDisplayString(_unref(langObj)['分']) + ")", 1)
                    ])
                  ]),
                  (_unref(statePhData).length==1)
                    ? (_openBlock(), _createBlock(_unref(Swiper), {
                        key: 0,
                        style: {"height":"16.5vh"},
                        slidesPerView: 1,
                        spaceBetween: 0,
                        autoplay: _unref(autoplay2),
                        speed: _unref(speed),
                        touchStartPreventDefault: false
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statePhData), (item, index) => {
                            return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_108, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass('chartC'+index),
                                    id: 'chartC'+index,
                                    style: {"width":"100%","height":"16vh"}
                                  }, null, 10, _hoisted_109)
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["autoplay", "speed"]))
                    : _createCommentVNode("", true),
                  (_unref(statePhData).length>1)
                    ? (_openBlock(), _createBlock(_unref(Swiper), {
                        key: 1,
                        style: {"height":"16.5vh"},
                        slidesPerView: 1,
                        spaceBetween: 0,
                        autoplay: _unref(autoplay2),
                        speed: _unref(speed),
                        loop: true,
                        pagination: { clickable: true }
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statePhData), (item, index) => {
                            return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_110, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass('chartC'+index),
                                    id: 'chartC'+index,
                                    style: {"width":"100%","height":"16vh"}
                                  }, null, 10, _hoisted_111)
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["autoplay", "speed"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createVNode(_component_a_modal, {
              visible: _unref(machineVisible),
              "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => (_isRef(machineVisible) ? (machineVisible).value = $event : machineVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_112, [
                  _createElementVNode("div", _hoisted_113, [
                    _cache[120] || (_cache[120] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (_isRef(machineVisible) //@ts-ignore
 ? machineVisible.value = false : machineVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_114, _toDisplayString(_unref(getLanguage)('设备列表', _unref(language))), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(machineModelSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备区域', _unref(language)),
                        name: "groupId",
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            onChange: selectGroupMachine,
                            value: _unref(machineModelSearch).groupId,
                            "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_unref(machineModelSearch).groupId) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.sonID
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('健康状态', _unref(language)),
                        name: "condition"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(machineModelSearch).condition,
                            "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_unref(machineModelSearch).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择健康状态', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('运行状态', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(machineModelSearch).status,
                            "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(machineModelSearch).status) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择运行状态', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('搜索', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineModelSearch).keyWord,
                            "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(machineModelSearch).keyWord) = $event)),
                            placeholder: _unref(getLanguage)('请输入设备名称/编号', _unref(language))
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_115, [
                            _createElementVNode("div", {
                              onClick: resetMachine,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getDataMachine,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_116, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      columns: machineColumns,
                      "data-source": _unref(machineDataList),
                      onChange: handleTableChangeMachineList,
                      pagination: paginationMachineList.value
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'factoryId')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_117, _toDisplayString(_unref(getFactoryName)(text)), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'groupId')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_118, _toDisplayString(_unref(getGroupName)(text)), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'experiment')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_119, _toDisplayString(_unref(getLanguage)(_unref(whetherList).find((p) => p.value === text)?.label, _unref(language))), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'condition')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_120, [
                              _createElementVNode("div", {
                                style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getMachienCondition(record)?.color }])
                              }, null, 4),
                              _createElementVNode("div", {
                                class: "ml-1",
                                style: _normalizeStyle({ color: getMachienCondition(record)?.color })
                              }, _toDisplayString(_unref(getLanguage)(getMachienCondition(record)?.label, _unref(language))), 5)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'status')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 4,
                              class: "ml-1",
                              style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                            }, _toDisplayString(_unref(getLanguage)(getMachienStatus(record)?.label, _unref(language))), 5))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'onlineHours')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_121, _toDisplayString(record?.workStatus?.onlineHours?.toFixed(2) || 0), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'faultIntervalTimes')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 6,
                              class: "ml-1",
                              style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                            }, _toDisplayString(record?.workStatus?.faultIntervalTimes?.toFixed(2) || 0), 5))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                              _createElementVNode("a", {
                                onClick: _cache[26] || (_cache[26] = ($event: any) => (viewMachine(1)))
                              }, _toDisplayString(_unref(langObj)["健康总览"]), 1),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              _createElementVNode("a", {
                                onClick: _cache[27] || (_cache[27] = ($event: any) => (viewMachine(2)))
                              }, _toDisplayString(_unref(langObj)["健康诊断"]), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(machineTypeVisible),
              "onUpdate:visible": _cache[35] || (_cache[35] = ($event: any) => (_isRef(machineTypeVisible) ? (machineTypeVisible).value = $event : machineTypeVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "90%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_122, [
                  _createElementVNode("div", _hoisted_123, [
                    _cache[121] || (_cache[121] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => (_isRef(machineTypeVisible) //@ts-ignore
 ? machineTypeVisible.value = false : machineTypeVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_124, _toDisplayString(_unref(getLanguage)('设备类型列表', _unref(language))), 1),
                  _createElementVNode("div", _hoisted_125, [
                    _createElementVNode("div", _hoisted_126, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypeData), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          onClick: ($event: any) => (selectMachineTypeList(item,index)),
                          class: _normalizeClass([_unref(machineTypeId)===item.machineTypeId?'machine-type-selected':'', "machine-type"])
                        }, _toDisplayString(item.firstCategory) + "-" + _toDisplayString(item.secondCategory), 11, _hoisted_127))
                      }), 256))
                    ]),
                    _createElementVNode("div", _hoisted_128, [
                      _createVNode(_component_a_form, {
                        model: _unref(machineTypeSearch),
                        name: "horizontal_login",
                        layout: "inline",
                        autocomplete: "off"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('设备区域', _unref(language)),
                            name: "groupId",
                            allowClear: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                onChange: selectGroupZdWarn,
                                value: _unref(machineTypeSearch).groupId,
                                "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_unref(machineTypeSearch).groupId) = $event)),
                                style: {"width":"220px"},
                                allowClear: "",
                                placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.sonID
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(langObj)['设备'],
                            name: "status"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(machineTypeSearch).machineId,
                                "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_unref(machineTypeSearch).machineId) = $event)),
                                style: {"width":"220px"},
                                placeholder: _unref(langObj)['请选择设备'],
                                options: _unref(machineListZdWarn),
                                "filter-option": filterOptionZdReport,
                                "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                                allowClear: "",
                                "show-search": ""
                              }, null, 8, ["value", "placeholder", "options"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('报警时间', _unref(language)),
                            name: "status"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_range_picker, {
                                value: _unref(machineTypeSearch).date,
                                "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_unref(machineTypeSearch).date) = $event)),
                                "disabled-date": disabledDate,
                                onCalendarChange: onCalendarChange
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('处理状态', _unref(language)),
                            name: "status"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(machineTypeSearch).handleStatus,
                                "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_unref(machineTypeSearch).handleStatus) = $event)),
                                style: {"width":"220px"},
                                allowClear: "",
                                placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('严重程度', _unref(language)),
                            name: "condition"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(machineTypeSearch).condition,
                                "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_unref(machineTypeSearch).condition) = $event)),
                                style: {"width":"220px"},
                                allowClear: "",
                                placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_129, [
                                _createElementVNode("div", {
                                  onClick: resetMachineTypeSearch,
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                                _createElementVNode("div", {
                                  onClick: getMachineTypeListData,
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model"]),
                      _createElementVNode("div", _hoisted_130, [
                        _createVNode(_component_a_table, {
                          style: {"width":"100%"},
                          size: "small",
                          "row-class-name": getRowClassName,
                          columns: machineTypeColumns,
                          "data-source": _unref(machineTypeList),
                          onChange: handleTableChangeMachineType,
                          pagination: paginationMachineType.value
                        }, {
                          bodyCell: _withCtx(({ column, text, record }) => [
                            (column.dataIndex === 'type')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_131, [
                                  (text === 1)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_132, _cache[122] || (_cache[122] = [
                                        _createElementVNode("img", {
                                          style: {"width":"20px"},
                                          src: _imports_13,
                                          alt: ""
                                        }, null, -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  (text === 2)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_133, _cache[123] || (_cache[123] = [
                                        _createElementVNode("img", {
                                          style: {"width":"20px"},
                                          src: _imports_14,
                                          alt: ""
                                        }, null, -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  (text === 3)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_134, _cache[124] || (_cache[124] = [
                                        _createElementVNode("img", {
                                          style: {"width":"20px"},
                                          src: _imports_15,
                                          alt: ""
                                        }, null, -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  (text === 4)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_135, _cache[125] || (_cache[125] = [
                                        _createElementVNode("img", {
                                          style: {"width":"20px"},
                                          src: _imports_16,
                                          alt: ""
                                        }, null, -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_136, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'condition')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_137, [
                                  _createElementVNode("div", {
                                    style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                                            background: _unref(conditionList).find((p) => p.value === text)
                                                                ?.color,
                                                            }])
                                  }, null, 4),
                                  _createElementVNode("div", _hoisted_138, _toDisplayString(_unref(langObj)[
                                                                _unref(conditionList).find((p) => p.value === text)?.label
                                                                ]), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'handleStatus')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                  (text === 3)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_139, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                                    : _createCommentVNode("", true),
                                  (text !== 3)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_140, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                                ? _unref(langObj)[
                                                                _unref(handleStatusList).find((p) => p.value === text)?.label
                                                                ]
                                                                : _unref(langObj)["未处理"]), 1))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'act')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                  (record?.reportIds?.length>0)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        onClick: ($event: any) => (viewReport(record))
                                      }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_141))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_a_divider, { type: "vertical" }),
                                  (record?.repairReportId)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        onClick: ($event: any) => (detailsJx(record))
                                      }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_142))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_a_divider, { type: "vertical" }),
                                  (record.handleStatus === 3)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        onClick: ($event: any) => (viewClose(record))
                                      }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_143))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["data-source", "pagination"])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(warnVisible),
              "onUpdate:visible": _cache[37] || (_cache[37] = ($event: any) => (_isRef(warnVisible) ? (warnVisible).value = $event : warnVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"30%"},
              width: "1400px",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_144, [
                  _createElementVNode("div", _hoisted_145, [
                    _cache[126] || (_cache[126] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[36] || (_cache[36] = ($event: any) => (_isRef(warnVisible) //@ts-ignore
 ? warnVisible.value = false : warnVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_146, _toDisplayString(_unref(langObj)['报警记录']), 1),
                  _cache[127] || (_cache[127] = _createElementVNode("div", { class: "w-full" }, null, -1))
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(yjVisible),
              "onUpdate:visible": _cache[46] || (_cache[46] = ($event: any) => (_isRef(yjVisible) ? (yjVisible).value = $event : yjVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_147, [
                  _createElementVNode("div", _hoisted_148, [
                    _cache[128] || (_cache[128] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[38] || (_cache[38] = ($event: any) => (_isRef(yjVisible) //@ts-ignore
 ? yjVisible.value = false : yjVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_149, _toDisplayString(_unref(getLanguage)('硬件状态统计', _unref(language))), 1),
                  _createElementVNode("div", _hoisted_150, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn1 theme_equipment_btn", _unref(typeSelectYj)===1?'theme_equipment_btn_select':'']),
                      style: _normalizeStyle({'background': _unref(typeSelectYj) === 1 ? '#072499' : ''}),
                      onClick: _cache[39] || (_cache[39] = ($event: any) => (selelctTypeYj(1)))
                    }, _toDisplayString(_unref(langObj)['传感器']), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn1 theme_equipment_btn", _unref(typeSelectYj)===2?'theme_equipment_btn_select':'']),
                      style: _normalizeStyle({'background': _unref(typeSelectYj) === 2 ? '#072499' : ''}),
                      onClick: _cache[40] || (_cache[40] = ($event: any) => (selelctTypeYj(2)))
                    }, _toDisplayString(_unref(langObj)['采集单元']), 7)
                  ]),
                  (_unref(typeSelectYj)==1)
                    ? (_openBlock(), _createBlock(_component_a_form, {
                        key: 0,
                        model: _unref(yjModelSearch),
                        name: "horizontal_login",
                        layout: "inline",
                        autocomplete: "off"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('设备区域', _unref(language)),
                            name: "groupId",
                            allowClear: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(yjModelSearch).groupId,
                                "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_unref(yjModelSearch).groupId) = $event)),
                                style: {"width":"220px"},
                                placeholder: _unref(langObj)['请选择设备区域'],
                                onChange: selectYjGroup
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.sonID
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(langObj)['设备'],
                            name: "machine"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                onChange: selectYjMachine,
                                value: _unref(yjModelSearch).machine,
                                "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_unref(yjModelSearch).machine) = $event)),
                                style: {"width":"220px"},
                                placeholder: _unref(langObj)['请选择设备'],
                                options: _unref(machineListYjModel),
                                "filter-option": filterOptionMachineListYj,
                                "field-names": { label: 'name', value: 'id', options: 'machineListYjModel' },
                                allowClear: "",
                                "show-search": ""
                              }, null, 8, ["value", "placeholder", "options"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_151, [
                                _createElementVNode("div", {
                                  onClick: reseYjSearch,
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                                _createElementVNode("div", {
                                  onClick: _cache[43] || (_cache[43] = ($event: any) => (getCgqListData(null))),
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model"]))
                    : _createCommentVNode("", true),
                  (_unref(typeSelectYj)==2)
                    ? (_openBlock(), _createBlock(_component_a_form, {
                        key: 1,
                        model: _unref(yjModelSearch),
                        name: "horizontal_login",
                        layout: "inline",
                        autocomplete: "off"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('数采器状态', _unref(language)),
                            name: "status"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(yjModelSearch).status,
                                "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => ((_unref(yjModelSearch).status) = $event)),
                                style: {"width":"220px"},
                                allowClear: "",
                                placeholder: _unref(getLanguage)('请选择数采器状态', _unref(language))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scqStatusList.value, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('搜索', _unref(language)),
                            name: "status"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(yjModelSearch).dasCode,
                                "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_unref(yjModelSearch).dasCode) = $event)),
                                placeholder: _unref(getLanguage)('请输入数采器编号搜索', _unref(language))
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_152, [
                                _createElementVNode("div", {
                                  onClick: reseYjSearch,
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                                _createElementVNode("div", {
                                  onClick: getCjListData,
                                  class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                                  style: {"background-color":"#072499","color":"#fff"}
                                }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_153, [
                    (_unref(typeSelectYj)==1)
                      ? (_openBlock(), _createBlock(_component_a_table, {
                          key: 0,
                          style: {"width":"100%"},
                          size: "small",
                          "row-class-name": getRowClassName,
                          columns: yjStateColumns,
                          "data-source": _unref(cgqListData),
                          pagination: paginationYj.value,
                          onChange: handleTableChangeYj
                        }, {
                          bodyCell: _withCtx(({ column, text, record }) => [
                            (column.dataIndex === 'groupId')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_154, _toDisplayString(_unref(getGroupName)(text)), 1))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'config_type')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_155, _toDisplayString(text), 1))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'index_delay')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_156, _toDisplayString(text?_unref(langObj)['是']:_unref(langObj)['否']), 1))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'wireless')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_157, _toDisplayString(text?_unref(langObj)['无线']:_unref(langObj)['有线']), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["data-source", "pagination"]))
                      : _createCommentVNode("", true),
                    (_unref(typeSelectYj)==2)
                      ? (_openBlock(), _createBlock(_component_a_table, {
                          key: 1,
                          style: {"width":"100%"},
                          size: "small",
                          "row-class-name": getRowClassName,
                          columns: yjCjStateColumns,
                          "data-source": _unref(cjListData),
                          pagination: paginationYj.value,
                          onChange: handleTableChangeYj
                        }, {
                          bodyCell: _withCtx(({ column, text, record }) => [
                            (column.dataIndex === 'config_status')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_158, [
                                  _createElementVNode("div", {
                                    style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                                background: scqStatusList.value.find((p) => p.value === text)
                                                    ?.color,
                                                }])
                                  }, null, 4),
                                  _createElementVNode("div", _hoisted_159, _toDisplayString(_unref(langObj)[scqStatusList.value.find((p) => p.value === text)?.label]), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (column.dataIndex === 'delay')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_160, [
                                  _createElementVNode("div", {
                                    style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {background: sjStateList.value.find((p) => p.value === text)
                                                    ?.color,
                                                }])
                                  }, null, 4),
                                  _createElementVNode("div", _hoisted_161, _toDisplayString(_unref(langObj)[sjStateList.value.find((p) => p.value === text)?.label]), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["data-source", "pagination"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(znZdReportVisible),
              "onUpdate:visible": _cache[53] || (_cache[53] = ($event: any) => (_isRef(znZdReportVisible) ? (znZdReportVisible).value = $event : znZdReportVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_162, [
                  _createElementVNode("div", _hoisted_163, [
                    _cache[129] || (_cache[129] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[47] || (_cache[47] = ($event: any) => (_isRef(znZdReportVisible) //@ts-ignore
 ? znZdReportVisible.value = false : znZdReportVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_164, _toDisplayString(_unref(getLanguage)('智能诊断报告记录', _unref(language))), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(znZdReportSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备区域', _unref(language)),
                        name: "groupId",
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            onChange: selectGroup,
                            value: _unref(znZdReportSearch).groupId,
                            "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => ((_unref(znZdReportSearch).groupId) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.sonID
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znZdReportSearch).machineId,
                            "onUpdate:value": _cache[49] || (_cache[49] = ($event: any) => ((_unref(znZdReportSearch).machineId) = $event)),
                            style: {"width":"220px"},
                            placeholder: _unref(langObj)['请选择设备'],
                            options: _unref(machineListZdReport),
                            "filter-option": filterOptionZdReport,
                            "field-names": { label: 'name', value: 'id', options: 'machineListZdReport' },
                            allowClear: "",
                            "show-search": ""
                          }, null, 8, ["value", "placeholder", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('报告时间', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(znZdReportSearch).date,
                            "onUpdate:value": _cache[50] || (_cache[50] = ($event: any) => ((_unref(znZdReportSearch).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('严重程度', _unref(language)),
                        name: "condition"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(machineModelSearch).condition,
                            "onUpdate:value": _cache[51] || (_cache[51] = ($event: any) => ((_unref(machineModelSearch).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('搜索', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(machineModelSearch).keyWord,
                            "onUpdate:value": _cache[52] || (_cache[52] = ($event: any) => ((_unref(machineModelSearch).keyWord) = $event)),
                            placeholder: _unref(getLanguage)('请输入关键词搜索', _unref(language))
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_165, [
                            _createElementVNode("div", {
                              onClick: resetZnZdReport,
                              class: "box px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getZnZdReport,
                              class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_166, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      columns: znZdReportColumns,
                      "data-source": _unref(znZdReportData),
                      pagination: pagination.value,
                      onChange: handleTableChangeZdReport
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'gradeText')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_167, [
                              _createElementVNode("div", {
                                style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%","margin-right":"5px"}, {'background':  _unref(gradeList).find(p => p.value === text)?.color}])
                              }, null, 4),
                              _createElementVNode("span", null, _toDisplayString(_unref(langObj)[_unref(gradeList).find(p => p.value === text)?.label ]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'faultEvents')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_168, _toDisplayString(_unref(langObj)[text] || text), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              onClick: goZdReport
                            }, _toDisplayString(_unref(langObj)["诊断报告"]), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(znWeekReportVisible),
              "onUpdate:visible": _cache[57] || (_cache[57] = ($event: any) => (_isRef(znWeekReportVisible) ? (znWeekReportVisible).value = $event : znWeekReportVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_169, [
                  _createElementVNode("div", _hoisted_170, [
                    _cache[130] || (_cache[130] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[54] || (_cache[54] = ($event: any) => (_isRef(znWeekReportVisible) //@ts-ignore
 ? znWeekReportVisible.value = false : znWeekReportVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_171, _toDisplayString(_unref(getLanguage)('智能周报记录', _unref(language))), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(znWeekReportSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('报告时间', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(znWeekReportSearch).date,
                            "onUpdate:value": _cache[55] || (_cache[55] = ($event: any) => ((_unref(znWeekReportSearch).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('报告类型', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znWeekReportSearch).type,
                            "onUpdate:value": _cache[56] || (_cache[56] = ($event: any) => ((_unref(znWeekReportSearch).type) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择报告类型', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeListWeek), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_172, [
                            _createElementVNode("div", {
                              onClick: resetWeek,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getDataWeek,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_173, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      columns: znWeekReportColumns,
                      "data-source": _unref(weekData),
                      pagination: paginationWeek.value,
                      onChange: handleTableChangeWeek
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'cycle')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_174, _toDisplayString(getDate(record.date)), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'cycle2')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_175, _toDisplayString(getDate2(record.date, record.type)), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'type')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_176, _toDisplayString(text == 3 ? _unref(langObj)['周报'] : _unref(langObj)['月报']), 1))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 3,
                              onClick: ($event: any) => (detailsWeek(record)),
                              style: {},
                              class: "theme-table-link"
                            }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_177))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(znMxWarnVisible),
              "onUpdate:visible": _cache[64] || (_cache[64] = ($event: any) => (_isRef(znMxWarnVisible) ? (znMxWarnVisible).value = $event : znMxWarnVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_178, [
                  _createElementVNode("div", _hoisted_179, [
                    _cache[131] || (_cache[131] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[58] || (_cache[58] = ($event: any) => (_isRef(znMxWarnVisible) //@ts-ignore
 ? znMxWarnVisible.value = false : znMxWarnVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_180, _toDisplayString(_unref(getLanguage)('智能门限报警记录', _unref(language))), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(znMxWarnSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备区域', _unref(language)),
                        name: "groupId",
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            onChange: selectGroupMxWarn,
                            value: _unref(znMxWarnSearch).groupId,
                            "onUpdate:value": _cache[59] || (_cache[59] = ($event: any) => ((_unref(znMxWarnSearch).groupId) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.sonID
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znMxWarnSearch).machineId,
                            "onUpdate:value": _cache[60] || (_cache[60] = ($event: any) => ((_unref(znMxWarnSearch).machineId) = $event)),
                            style: {"width":"220px"},
                            placeholder: _unref(langObj)['请选择设备'],
                            options: _unref(machineListMxWarn),
                            "filter-option": filterOptionZdReport,
                            "field-names": { label: 'name', value: 'id', options: 'machineListMxWarn' },
                            allowClear: "",
                            "show-search": ""
                          }, null, 8, ["value", "placeholder", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('报警时间', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(znMxWarnSearch).date,
                            "onUpdate:value": _cache[61] || (_cache[61] = ($event: any) => ((_unref(znMxWarnSearch).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('处理状态', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znMxWarnSearch).status,
                            "onUpdate:value": _cache[62] || (_cache[62] = ($event: any) => ((_unref(znMxWarnSearch).status) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('严重程度', _unref(language)),
                        name: "condition"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znMxWarnSearch).condition,
                            "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_unref(znMxWarnSearch).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_181, [
                            _createElementVNode("div", {
                              onClick: resetMxWarn,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getMxWarnData,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_182, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      "data-source": _unref(mxWarnData),
                      pagination: paginationMxWarn.value,
                      onChange: handleTableChangeMxWarn,
                      columns: znMxWarnColumns
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'type')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_183, [
                              (text === 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_184, _cache[132] || (_cache[132] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_13,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_185, _cache[133] || (_cache[133] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_14,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_186, _cache[134] || (_cache[134] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_15,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 4)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_187, _cache[135] || (_cache[135] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_16,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_188, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'condition')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_189, [
                              _createElementVNode("div", {
                                style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                                background: _unref(conditionList).find((p) => p.value === text)
                                                    ?.color,
                                                }])
                              }, null, 4),
                              _createElementVNode("div", _hoisted_190, _toDisplayString(_unref(langObj)[
                                                    _unref(conditionList).find((p) => p.value === text)?.label
                                                    ]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'handleStatus')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_191, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                                : _createCommentVNode("", true),
                              (text !== 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_192, _toDisplayString(_unref(handleStatusList).find((p) => p.value == text)
                                                    ? _unref(langObj)[
                                                    _unref(handleStatusList).find((p) => p.value == text)?.label
                                                    ]
                                                    : _unref(langObj)["未处理"]), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (record?.repairReportId)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    onClick: ($event: any) => (detailsJx(record))
                                  }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_193))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              (record.handleStatus === 3)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    onClick: ($event: any) => (viewClose(record))
                                  }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_194))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(znZdWarnVisible),
              "onUpdate:visible": _cache[71] || (_cache[71] = ($event: any) => (_isRef(znZdWarnVisible) ? (znZdWarnVisible).value = $event : znZdWarnVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_195, [
                  _createElementVNode("div", _hoisted_196, [
                    _cache[136] || (_cache[136] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[65] || (_cache[65] = ($event: any) => (_isRef(znZdWarnVisible) //@ts-ignore
 ? znZdWarnVisible.value = false : znZdWarnVisible = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_197, _toDisplayString(_unref(getLanguage)('智能诊断报警记录', _unref(language))), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(znZdWarnSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备区域', _unref(language)),
                        name: "groupId",
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            onChange: selectGroupZdWarn,
                            value: _unref(znZdWarnSearch).groupId,
                            "onUpdate:value": _cache[66] || (_cache[66] = ($event: any) => ((_unref(znZdWarnSearch).groupId) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.sonID
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znZdWarnSearch).machineId,
                            "onUpdate:value": _cache[67] || (_cache[67] = ($event: any) => ((_unref(znZdWarnSearch).machineId) = $event)),
                            style: {"width":"220px"},
                            placeholder: _unref(langObj)['请选择设备'],
                            options: _unref(machineListZdWarn),
                            "filter-option": filterOptionZdReport,
                            "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                            allowClear: "",
                            "show-search": ""
                          }, null, 8, ["value", "placeholder", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['报警时间'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(znZdWarnSearch).date,
                            "onUpdate:value": _cache[68] || (_cache[68] = ($event: any) => ((_unref(znZdWarnSearch).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('处理状态', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znZdWarnSearch).status,
                            "onUpdate:value": _cache[69] || (_cache[69] = ($event: any) => ((_unref(znZdWarnSearch).status) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('严重程度', _unref(language)),
                        name: "condition"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(znZdWarnSearch).condition,
                            "onUpdate:value": _cache[70] || (_cache[70] = ($event: any) => ((_unref(znZdWarnSearch).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_198, [
                            _createElementVNode("div", {
                              onClick: resetZdWarn,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getZdWarnData,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_199, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      columns: znZdWarnColumns,
                      "data-source": _unref(zdWarnData),
                      pagination: paginationZdWarn.value,
                      onChange: handleTableChangeZdWarn
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'type')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_200, [
                              (text === 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_201, _cache[137] || (_cache[137] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_13,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_202, _cache[138] || (_cache[138] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_14,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_203, _cache[139] || (_cache[139] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_15,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 4)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_204, _cache[140] || (_cache[140] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_16,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_205, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'condition')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_206, [
                              _createElementVNode("div", {
                                style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                                background: _unref(conditionList).find((p) => p.value === text)
                                                    ?.color,
                                                }])
                              }, null, 4),
                              _createElementVNode("div", _hoisted_207, _toDisplayString(_unref(langObj)[
                                                    _unref(conditionList).find((p) => p.value === text)?.label
                                                    ]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'handleStatus')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_208, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                                : _createCommentVNode("", true),
                              (text !== 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_209, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                    ? _unref(langObj)[
                                                    _unref(handleStatusList).find((p) => p.value === text)?.label
                                                    ]
                                                    : _unref(langObj)["未处理"]), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (record?.reportIds?.length>0)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    onClick: ($event: any) => (viewReport(record))
                                  }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_210))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              (record?.repairReportId)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    onClick: ($event: any) => (detailsJx(record))
                                  }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_211))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              (record.handleStatus === 3)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 2,
                                    onClick: ($event: any) => (viewClose(record))
                                  }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_212))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(warnTrendVisible),
              "onUpdate:visible": _cache[78] || (_cache[78] = ($event: any) => (_isRef(warnTrendVisible) ? (warnTrendVisible).value = $event : warnTrendVisible = $event)),
              closable: false,
              footer: null,
              style: {"top":"10%"},
              width: "80%",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_213, [
                  _createElementVNode("div", _hoisted_214, [
                    _cache[141] || (_cache[141] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[72] || (_cache[72] = ($event: any) => (_isRef(warnTrendVisible) //@ts-ignore
 ? warnTrendVisible.value = false : warnTrendVisible  = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_215, _toDisplayString(_unref(langObj)['报警记录']), 1),
                  _createVNode(_component_a_form, {
                    model: _unref(znZdWarnSearch),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备区域', _unref(language)),
                        name: "groupId",
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            onChange: selectGroupWarnTrend,
                            value: _unref(warnTrendSearch).groupId,
                            "onUpdate:value": _cache[73] || (_cache[73] = ($event: any) => ((_unref(warnTrendSearch).groupId) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.sonID
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(warnTrendSearch).machineId,
                            "onUpdate:value": _cache[74] || (_cache[74] = ($event: any) => ((_unref(warnTrendSearch).machineId) = $event)),
                            style: {"width":"220px"},
                            placeholder: _unref(langObj)['请选择设备'],
                            options: _unref(machineListZdWarn),
                            "filter-option": filterOptionZdReport,
                            "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                            allowClear: "",
                            "show-search": ""
                          }, null, 8, ["value", "placeholder", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['报警时间'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(warnTrendSearch).date,
                            "onUpdate:value": _cache[75] || (_cache[75] = ($event: any) => ((_unref(warnTrendSearch).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('处理状态', _unref(language)),
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(warnTrendSearch).status,
                            "onUpdate:value": _cache[76] || (_cache[76] = ($event: any) => ((_unref(warnTrendSearch).status) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('严重程度', _unref(language)),
                        name: "condition"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(warnTrendSearch).condition,
                            "onUpdate:value": _cache[77] || (_cache[77] = ($event: any) => ((_unref(warnTrendSearch).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_216, [
                            _createElementVNode("div", {
                              onClick: resetWarnTrend,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                            _createElementVNode("div", {
                              onClick: getWarnTrendData,
                              class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499","color":"#fff"}
                            }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"]),
                  _createElementVNode("div", _hoisted_217, [
                    _createVNode(_component_a_table, {
                      style: {"width":"100%"},
                      size: "small",
                      "row-class-name": getRowClassName,
                      columns: warnTrendColumns,
                      "data-source": _unref(warnTrendData),
                      pagination: paginationWarnTrend.value,
                      onChange: handleTableChangeWarnTrend
                    }, {
                      bodyCell: _withCtx(({ column, text, record }) => [
                        (column.dataIndex === 'type')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_218, [
                              (text === 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_219, _cache[142] || (_cache[142] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_13,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_220, _cache[143] || (_cache[143] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_14,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_221, _cache[144] || (_cache[144] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_15,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (text === 4)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_222, _cache[145] || (_cache[145] = [
                                    _createElementVNode("img", {
                                      style: {"width":"20px"},
                                      src: _imports_16,
                                      alt: ""
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_223, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'condition')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_224, [
                              _createElementVNode("div", {
                                style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                                background: _unref(conditionList).find((p) => p.value === text)
                                                    ?.color,
                                                }])
                              }, null, 4),
                              _createElementVNode("div", _hoisted_225, _toDisplayString(_unref(langObj)[
                                                    _unref(conditionList).find((p) => p.value === text)?.label
                                                    ]), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'handleStatus')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (text === 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_226, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                                : _createCommentVNode("", true),
                              (text !== 3)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_227, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                    ? _unref(langObj)[
                                                    _unref(handleStatusList).find((p) => p.value === text)?.label
                                                    ]
                                                    : _unref(langObj)["未处理"]), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true),
                        (column.dataIndex === 'act')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (record?.reportIds?.length>0)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    onClick: ($event: any) => (viewReport(record))
                                  }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_228))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              (record?.repairReportId)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    onClick: ($event: any) => (detailsJx(record))
                                  }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_229))
                                : _createCommentVNode("", true),
                              _createVNode(_component_a_divider, { type: "vertical" }),
                              (record.handleStatus === 3)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 2,
                                    onClick: ($event: any) => (viewClose(record))
                                  }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_230))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data-source", "pagination"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              width: "50%",
              closable: false,
              footer: null,
              visible: _unref(jxVisible),
              "onUpdate:visible": _cache[79] || (_cache[79] = ($event: any) => (_isRef(jxVisible) ? (jxVisible).value = $event : jxVisible = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(maintenanceReportDetails, {
                  ref_key: "mrD",
                  ref: mrD,
                  onCancel: cancelJx
                }, null, 512)
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              width: "50%",
              closable: false,
              footer: null,
              visible: _unref(visibleDR),
              "onUpdate:visible": _cache[80] || (_cache[80] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(DiagnosticReportDetails, {
                  ref_key: "mrDref",
                  ref: mrDref,
                  onCancel: cancelDR
                }, null, 512)
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_a_modal, {
              visible: _unref(visibledel2),
              "onUpdate:visible": _cache[83] || (_cache[83] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
              closable: false,
              footer: null,
              bodyStyle: { background: '#020F46' },
              style: {"top":"30%"},
              width: "1200px",
              maskClosable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_231, [
                  _createElementVNode("div", _hoisted_232, [
                    _cache[146] || (_cache[146] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      class: "cursor-pointer",
                      onClick: _cache[81] || (_cache[81] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
                    }, [
                      _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_233, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
                  _createVNode(_component_a_form, {
                    ref: "formRef",
                    name: "advanced_search",
                    class: "ant-advanced-search-form",
                    style: {"margin-top":"20px"},
                    model: _unref(formClose)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, { gutter: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, {
                                name: "closeCause",
                                label: "关闭原因",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    value: _unref(formClose).closeCause,
                                    "onUpdate:value": _cache[82] || (_cache[82] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                                    placeholder: "",
                                    rows: 4,
                                    readonly: ""
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"])
                ])
              ]),
              _: 1
            }, 8, ["visible"])
          ]),
          _createElementVNode("div", _hoisted_234, [
            _createElementVNode("div", _hoisted_235, [
              _createVNode(_unref(Swiper), {
                class: "swiper_con1",
                slidesPerView: 3,
                spaceBetween: 0,
                navigation: true,
                autoplay: _unref(autoplay),
                loop: true,
                initialSlide: 3,
                onSwiper: onSwiper,
                onSlideChange: onSlideChange,
                centeredSlides: true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryListBottom), (item, index) => {
                    return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(["fac_wrap", {'selected': activeIndex.value === index}]),
                          onClick: ($event: any) => (onSlideClick(index))
                        }, [
                          (!item.parentId && item.factoryName )
                            ? (_openBlock(), _createElementBlock("img", _hoisted_237))
                            : _createCommentVNode("", true),
                          (item.parentId)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_238))
                            : _createCommentVNode("", true),
                          (!item.factoryName)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_239))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_240, _toDisplayString(item.factoryName?item.factoryName:item.name), 1)
                        ], 10, _hoisted_236)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["autoplay"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})