<template>
    <div style="padding: 16px 16px;    height: calc(100vh - 100px);
    background-color: rgb(0, 10, 50);
    overflow-y: hidden;
    padding-bottom: 30px;color: #fff" class="theme-common">
        <div class="w-full flex items-center justify-center">
            <div class="btn1 theme_equipment_btn" :style="{'background': menuSelect === 1 ? '#072499' : ''}" :class="menuSelect===1?'theme_equipment_btn_select':''" @click="selelctMenu(1)">{{langObj['列表显示']}}</div>
            <div class="btn1 theme_equipment_btn" :style="{'background': menuSelect === 2 ? '#072499' : ''}" :class="menuSelect===2?'theme_equipment_btn_select':''" @click="selelctMenu(2)">{{langObj['台账显示']}}</div>
        </div>

        <div class="mt-4 w-full">
           <listComponent v-if="menuSelect === 1"></listComponent> 
           <infoComponent v-if="menuSelect === 2"></infoComponent> 
        </div>
    
    </div>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
// import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import listComponent from "./list.vue";
import infoComponent from "./info.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()


let menuSelect = ref(1)     //1: 列表显示  2:台账显示

const selelctMenu = (ev:any) => {
    menuSelect.value = ev
}

</script>


<style lang="less" scoped>

.btn1 {
    padding: 3px 10px;
    border-radius: 2px;
    border: 1px solid #072499;
    cursor: pointer;
}

</style>
