import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bule_list_page theme-common" }
const _hoisted_2 = { class: "search_wrap" }
const _hoisted_3 = {
  key: 0,
  class: "w-full fac-box p-3 ml-4"
}
const _hoisted_4 = {
  key: 2,
  class: "w-full flex"
}
const _hoisted_5 = { style: {"width":"180px"} }
const _hoisted_6 = {
  key: 1,
  class: "w-full fac-box p-3 ml-4"
}
const _hoisted_7 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_8 = { class: "ml-2" }
const _hoisted_9 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_10 = { class: "ml-2" }
const _hoisted_11 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_12 = { class: "ml-2" }
const _hoisted_13 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_14 = { class: "ml-2" }
const _hoisted_15 = {
  key: 4,
  class: "flex items-center"
}
const _hoisted_16 = { class: "ml-2" }
const _hoisted_17 = {
  key: 5,
  class: "flex items-center"
}
const _hoisted_18 = { class: "ml-2" }

import {
  onMounted,
  ref,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";
import pushRuleDriverComponent from "./push-rule-driver.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'push-rule',
  setup(__props) {

const router = useRouter();

let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
};
getLang();

const getMessageType = (t) => {
  switch (t) {
    case 4:
      return getLanguage("智能诊断报警", language.value);
    case 5:
      return getLanguage("设备门限报警", language.value);
  }
};

const getMembers = async (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId, take: 10000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/members", config);
  oMembers.value = res?.data?.list
  return res?.data?.list || [];
};

let pushTypes = ref([
  {
    code: 0,
    name: "短信",
  },
  {
    code: 1,
    name: "微信公众号",
  },
]);
let searchFac = ref('');

let oMembers = ref([]);


let columns = ref<any[]>([
  {
    title: "工厂",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "group",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineIds",
    align: "center"
  },
  {
    title: "操作",
    dataIndex: "act",
    with: '200px',
    align: "center"
  }
]);

const filterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

let dataList = ref<any>([]);

let _selectedFactoryId: string = "";
let factoryList = ref<any>();
let treeData: any = ref([]);
let allFactory = ref([]);

let factory_id:any = ref()
let factory_Name:any = ref()
let groupList:any = ref([]);
let machineMatch:any = ref([]);
const machinePushConfigs = ref<any>();


const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      factoryFilter()
    }
  });
};


const factoryFilter = () => {
  let factoryF = JSON.parse(JSON.stringify(allFactory.value)) || []
  var list1 = factoryF.map((t: any) => ({
    key: t._id,
    title: t.factoryName,
    value: t._id,
    label: t.factoryName,
    parentId: t.parentId,
    id: t._id,
    type: "factory",
  }));
  let factory: any = [];
  let parentFs = list1.filter((p: any) => !p.parentId);
  parentFs.map((d: any) => {
    let sonFactory = list1.filter((p: any) => p.parentId === d.id);
    if (sonFactory && sonFactory.length) {
      d.children = sonFactory;
    }
    factory.push(d);
  });

  let list = JSON.parse(JSON.stringify(factory));
  factoryList.value = [];
  if (searchFac.value) {
    list.map((d: any) => {
      if (d.children && d.children.length) {
        let list1 = d.children.filter((p: any) =>
          p.label.includes(searchFac.value)
        );
        if (list1.length) {
          d.children = list1;
          factoryList.value.push(d);
        } else {
          if (d.label.includes(searchFac.value)) {
            factoryList.value.push(d);
          }
        }
      } else {
        if (d.label.includes(searchFac.value)) {
          factoryList.value.push(d);
        }
      }
    });
  } else {
    factoryList.value = list;
  }
  treeData.value = factoryList.value;
}


const selectTree = async (ev: any, info: any) => {
  if (ev && ev.length && info.node.type == "factory") {
    var cutFactory:any = allFactory.value.find((t:any) => t._id == info.node.id);
    console.log('cutFactory', cutFactory)
    if (cutFactory) {
      groupList.value = cutFactory.groups.map((p: any) => ({ label: p.name, value: p.sonID }))
      factory_id.value = cutFactory._id;
      factory_Name.value = cutFactory.factoryName;
      await getMembers(cutFactory._id);
      await loadMachine();
      search();
    } else {
      factory_id.value = "";
    }
  }
};

const loadMachine = async () => {
  let config = {
    params: { factoryId: factory_id.value },
    headers: {
      requestId: uuidv4(),
    },
  }
  let res = await request.get("/api/machines", config)
  console.log('res2222222222222', res)
  machineMatch.value = res?.data || []
};


const search = () => {
  const config = {
    params: {
      factoryId: factory_id.value
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  request.get("/api/push-config", config).then((res: any) => {
    if (res && res.data) {
      machinePushConfigs.value = res.data.filter((t: any) => t.machineIds && t.machineIds.length > 0);
    }
  });
}

let formStateRef:any = ref()
const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
});
let formInfo:any = ref({})
class PushItem {
  messageType = 4;
  platforms: number[] = [];
  members: string[] = [];
  level1Members: string[] = [];
  level2Members: string[] = [];
  level3Members: string[] = [];

  constructor(messageType: number) {
    this.messageType = messageType;
  }
}
let machinesList:any = ref([])
let pushColumns = [
  {
    title: "报警类型",
    dataIndex: "messageType",
  },
  {
    title: "接收人",
    dataIndex: "members",
  },
  {
    title: "可用接收人",
    dataIndex: "level1Members",
  },
  {
    title: "警戒接收人",
    dataIndex: "level2Members",
  },
  {
    title: "故障接收人",
    dataIndex: "level3Members",
  },
  {
    title: "推送平台",
    dataIndex: "platforms",
  },
];
const messageTypes = [
  { type: 4, label: "智能诊断报警", group: "machine" },
  { type: 5, label: "设备门限报警", group: "machine" },
  { type: 6, label: "采集卡网关异常报警", group: "factory" },
  { type: 7, label: "传感器异常报警", group: "factory" }
]

const memberFilterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const onAdd = () => {
  modalState.title = "新增";
  formStateRef.value?.clearValidate();

  formInfo = ref<any>({
    id: 0,
    factoryId: factory_id.value,
    factoryName: factory_Name.value,
    machineIds: [],
    groupId: "",
    group: {},
    pushItems: [new PushItem(4), new PushItem(5)]
  });

  modalState.visible = true;
};

// 保存
const onSaveMachinePushConfigs = () => {
  formStateRef.value
    .validate()
    .then(() => {
      formInfo.value = {
        ...formInfo.value
      }
      if (!formInfo.value.factoryName) {
        formInfo.value.factoryName = factory_Name.value
      }
      if (formInfo.value.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        let body = formInfo.value;

        request.put("/api/push-config", body, config)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };

        request.post("/api/push-config", formInfo.value, config)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};



const clearValidate = (name: any) => {
  formStateRef.value?.clearValidate(name);
};

const selectGroup = async (ev: any) => {
  let groupInfo = groupList.value.find((p: any) => p.value === ev)
  if (groupInfo) {
    formInfo.value.group = {
      sonID: groupInfo.value,
      name: groupInfo.label,
    };
    let config = {
      params: { factoryId: factory_id.value, groupId: ev },
      headers: {
        requestId: uuidv4(),
      },
    }
    let res = await request.get("/api/machines", config)
    console.log('res', res)
    machinesList.value = (res?.data || []).map((p: any) => ({ label: p.machineName, value: p.id }))
  }
}


const onEdit = (val: any) => {
  modalState.visible = true;
  modalState.title = "编辑";

  setTimeout(() => {
    formInfo.value = JSON.parse(JSON.stringify(val));
    formStateRef.value.clearValidate();
    if (formInfo.value.groupId) {
      //   machinesList.value = groupMachineOptionsMap.get(formInfo.value.groupId) || [];
      selectGroup(formInfo.value.groupId)
    }
    if (!formInfo.value.pushItems) {
      formInfo.value.pushItems = [];
    }
    for (let type of messageTypes) {
      if (type.group === "machine" && formInfo.value.pushItems.findIndex((t: any) => t.messageType === type.type) < 0) {
        formInfo.value.pushItems.push(new PushItem(type.type))
      }
    }
  }, 500);
};


const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/push-config", config).then((res) => {
    if (res) {
      message.success("操作成功");
      search();
    }
  });
};



const getPlatforms = () => {
  request.get("/api/push-config/platforms").then((res: any) => {
    if (res?.data) {
      pushTypes.value = res.data || [];
    }
  });
}



onMounted(async () => {
  await loadFactory();
  getPlatforms()
  //新注册用户，未经过引导的需弹窗进行引导
  if (!getMemberOnboarding()) {
    await selectTree([treeData.value[0].id], { node: treeData.value[0] });
    setTimeout(() => {
      highlightStep(getPopover(".pushruleDriver", "8/9 报警推送", "针对智能诊断报警和门限报警，可配置接收人和推送平台。（系统提供微信公众号和短信推送平台）", "推送配置", "bottom", "center", nextRoute, pushruleDriver));
    }, 400)
  }
});

let pushRuleDriverVisible = ref(false);
let editRindex = ref("auto");
//新用户引导报警推送配置按钮回调
const pushruleDriver = () => {
  editRindex = 1000000001;
  pushRuleDriverVisible.value = true;
};
const cancel = () => {
  pushRuleDriverVisible.value = false;
}
//新用户引导报警推送下一步按钮回调
const nextRoute = () => {
  router.push("/report/list");
  return;
};
const savePushRuleDriver = async (ev: any) => {
  pushRuleDriverVisible.value = false;
  await selectTree([treeData.value[0].id], { node: treeData.value[0] });
}

return (_ctx: any,_cache: any) => {
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input, {
        value: _unref(searchFac),
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchFac) ? (searchFac).value = $event : searchFac = $event)),
        placeholder: "请输入工厂名称搜索"
      }, {
        suffix: _withCtx(() => [
          _createVNode(_component_search_outlined, {
            style: {"cursor":"pointer"},
            onClick: factoryFilter
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _createVNode(_component_a_layout_content, {
      style: {"padding":"0 20px"},
      class: "flex box"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tree, {
          style: {"height":"80vh","overflow":"auto","padding":"10px 0","width":"300px"},
          class: "draggable-tree",
          "block-node": "",
          "tree-data": _unref(treeData),
          onSelect: selectTree
        }, null, 8, ["tree-data"]),
        (_unref(factory_id))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "freqx_formTitle" }, "设备报警", -1)),
              _createVNode(_component_a_button, {
                onClick: onAdd,
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_plus_outlined),
                  _cache[7] || (_cache[7] = _createTextVNode(" 新增 "))
                ]),
                _: 1
              }),
              _createVNode(_component_a_table, {
                emptyText: '暂无数据',
                bordered: "",
                columns: _unref(columns),
                "data-source": machinePushConfigs.value,
                pagination: false,
                loading: _ctx.loading,
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'group')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(record.group.name || record.group.Name), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'machineIds')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(text, (item, index) => {
                        return (_openBlock(), _createElementBlock("span", { key: index }, [
                          (_unref(machineMatch).find((t) => t.id == item))
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 0,
                                color: "#2db7f5"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(machineMatch).find((t) => t.id == item)?.machineName), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_a_popconfirm, {
                            title: "确定删除此条数据？",
                            "ok-text": "确定",
                            "cancel-text": "取消",
                            onConfirm: ($event: any) => (deleteThis(record))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                type: "primary",
                                danger: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_delete_outlined),
                                  _cache[8] || (_cache[8] = _createTextVNode(" 删除 "))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["onConfirm"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            class: "plft",
                            onClick: ($event: any) => (onEdit(record))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_edit_outlined),
                              _cache[9] || (_cache[9] = _createTextVNode(" 编辑 "))
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source", "loading"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_a_alert, {
                message: "请选择工厂",
                type: "warning"
              })
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      width: 1300,
      visible: modalState.visible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((modalState.visible) = $event)),
      title: modalState.title,
      maskClosable: false,
      "confirm-loading": modalState.confirmLoading,
      onOk: onSaveMachinePushConfigs,
      "z-index": _unref(editRindex),
      "onUpdate:zIndex": _cache[6] || (_cache[6] = ($event: any) => (_isRef(editRindex) ? (editRindex).value = $event : editRindex = $event))
    }, {
      default: _withCtx(() => [
        _cache[13] || (_cache[13] = _createElementVNode("div", {
          class: "freqx_formTitle",
          style: {"color":"#fff"}
        }, "基本信息", -1)),
        _createVNode(_component_a_form, {
          model: _unref(formInfo),
          name: "basic",
          autocomplete: "off",
          layout: "inline",
          ref_key: "formStateRef",
          ref: formStateRef,
          "label-col": { style: { width: '120px' } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "区域",
              name: "groupId",
              rules: [{ required: true, message: '请选择' }],
              onChange: _cache[2] || (_cache[2] = ($event: any) => (clearValidate('groupId')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(formInfo).groupId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formInfo).groupId) = $event)),
                  options: _unref(groupList),
                  placeholder: "请选择",
                  style: {"min-width":"150px"},
                  onSelect: selectGroup
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "设备",
              name: "machineIds",
              rules: [{ required: true, message: '请选择' }],
              onChange: _cache[4] || (_cache[4] = ($event: any) => (clearValidate('machines')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(formInfo).machineIds,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formInfo).machineIds) = $event)),
                  style: {"width":"380px"},
                  options: _unref(machinesList),
                  placeholder: "请选择",
                  mode: "multiple",
                  "max-tag-count": 5,
                  "max-tag-text-length": 6
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _cache[14] || (_cache[14] = _createElementVNode("div", {
          class: "freqx_formTitle",
          style: {"color":"#fff"}
        }, "推送配置", -1)),
        _createVNode(_component_a_table, {
          bordered: "",
          columns: _unref(pushColumns),
          dataSource: _unref(formInfo).pushItems,
          pagination: false
        }, {
          bodyCell: _withCtx(({ column, record, text, index }) => [
            (column.dataIndex === 'messageType')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(getMessageType(Number(text))), 1)
                ]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'members')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_a_select, {
                      mode: "multiple",
                      value: record.members,
                      "onUpdate:value": ($event: any) => ((record.members) = $event),
                      style: {"width":"150px"},
                      placeholder: "请选择接收人",
                      filterOption: memberFilterOption
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oMembers), (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.nickName || item.userName), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'level1Members')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_a_select, {
                      mode: "multiple",
                      value: record.level1Members,
                      "onUpdate:value": ($event: any) => ((record.level1Members) = $event),
                      style: {"width":"150px"},
                      placeholder: "请选择接收人",
                      filterOption: memberFilterOption
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oMembers), (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.nickName || item.userName), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'level2Members')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_a_select, {
                      mode: "multiple",
                      value: record.level2Members,
                      "onUpdate:value": ($event: any) => ((record.level2Members) = $event),
                      style: {"width":"150px"},
                      placeholder: "请选择接收人",
                      filterOption: memberFilterOption
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oMembers), (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.nickName || item.userName), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'level3Members')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_a_select, {
                      mode: "multiple",
                      value: record.level3Members,
                      "onUpdate:value": ($event: any) => ((record.level3Members) = $event),
                      style: {"width":"150px"},
                      placeholder: "请选择接收人",
                      filterOption: memberFilterOption
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oMembers), (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.nickName || item.userName), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'platforms')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_a_select, {
                      mode: "multiple",
                      value: record.platforms,
                      "onUpdate:value": ($event: any) => ((record.platforms) = $event),
                      style: {"width":"200px"},
                      placeholder: "请选择推送平台"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pushTypes), (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item.code,
                            value: item.code
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["columns", "dataSource"])
      ]),
      _: 1
    }, 8, ["visible", "title", "confirm-loading", "z-index"]),
    (_unref(pushRuleDriverVisible))
      ? (_openBlock(), _createBlock(pushRuleDriverComponent, {
          key: 0,
          onSavePushRuleDriver: savePushRuleDriver,
          onCancel: cancel,
          visible: _unref(pushRuleDriverVisible),
          editRindex: _unref(editRindex)
        }, null, 8, ["visible", "editRindex"]))
      : _createCommentVNode("", true)
  ]))
}
}

})