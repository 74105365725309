import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"margin-left":"8px","border-bottom":"2px solid #0d53b7cc"} }
const _hoisted_2 = { class: "text-lg font-extrabold" }
const _hoisted_3 = { class: "ms-2" }

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Drawer as ADrawer } from 'ant-design-vue'
import ChatLayout from './chat-layout.vue'
import { getLanguage } from "../../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'chat-float-button',
  setup(__props) {

const position = ref({
  x: 20,
  y: 120
})
const isDragging = ref(false)
const hasMoved = ref(false)
const dragOffset = ref({
  x: 0,
  y: 0
})
const drawerVisible = ref(false)
const startPosition = ref({ x: 0, y: 0 })

function handleClick(event: Event) {
  // Only show drawer if we didn't drag the button
  if (!hasMoved.value) {
    showDrawer(event)
  }
  // Reset for next interaction
  hasMoved.value = false
}

function showDrawer(event: Event) {
  drawerVisible.value = true
  event.stopPropagation()
}

function closeDrawer() {
  drawerVisible.value = false
}

function startDrag(event: MouseEvent | TouchEvent) {
  isDragging.value = true
  hasMoved.value = false

  const pageX = 'touches' in event ? event.touches[0].pageX : event.pageX
  const pageY = 'touches' in event ? event.touches[0].pageY : event.pageY

  // Store the starting position for movement detection
  startPosition.value = { x: pageX, y: pageY }

  // For right-based positioning, calculate offset considering window width
  const windowWidth = window.innerWidth
  dragOffset.value.x = (windowWidth - pageX) - position.value.x
  dragOffset.value.y = pageY - position.value.y

  event.preventDefault()
}

function onDrag(event: MouseEvent | TouchEvent) {
  if (!isDragging.value) return

  const pageX = 'touches' in event ? event.touches[0].pageX : event.pageX
  const pageY = 'touches' in event ? event.touches[0].pageY : event.pageY

  // Check if the pointer has moved more than a threshold to consider it a drag
  const moveThreshold = 5
  if (!hasMoved.value &&
    (Math.abs(pageX - startPosition.value.x) > moveThreshold ||
      Math.abs(pageY - startPosition.value.y) > moveThreshold)) {
    hasMoved.value = true
  }

  // Calculate new position based on right distance from screen edge
  const buttonSize = 60 // Approximate button size
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  // Calculate the right position (distance from right edge)
  let newRightX = windowWidth - pageX - dragOffset.value.x
  let newY = pageY - dragOffset.value.y

  // Apply boundary limitations
  newRightX = Math.max(0, Math.min(newRightX, windowWidth - buttonSize))
  newY = Math.max(0, Math.min(newY, windowHeight - buttonSize))

  position.value.x = newRightX
  position.value.y = newY

  localStorage.setItem('chatButtonPosition', JSON.stringify(position.value))
}

function stopDrag() {
  isDragging.value = false
  // We don't reset hasMoved here, to allow the click handler to check it
}

onMounted(() => {
  document.addEventListener('mousemove', onDrag)
  document.addEventListener('mouseup', stopDrag)
  document.addEventListener('touchmove', onDrag)
  document.addEventListener('touchend', stopDrag)

  let positionStr = localStorage.getItem('chatButtonPosition')
  if (positionStr) {
    try {
      position.value = JSON.parse(positionStr)
    } catch (e) {
      console.error('chatButtonPostion parse failed', e)
    }
  }
})

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', onDrag)
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('touchmove', onDrag)
  document.removeEventListener('touchend', stopDrag)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!drawerVisible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "chat-floating-button",
          style: _normalizeStyle({ right: position.value.x + 'px', top: position.value.y + 'px' }),
          onMousedown: startDrag,
          onTouchstart: startDrag,
          onClick: handleClick
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "chat-button-content" }, [
              _createElementVNode("span")
            ], -1))
          ])
        ], 36))
      : _createCommentVNode("", true),
    _createVNode(_unref(ADrawer), {
      width: 1100,
      placement: "right",
      class: "text-primary chat-drawer",
      style: {"padding-top":"0"},
      visible: drawerVisible.value,
      onClose: closeDrawer,
      closable: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(getLanguage)('天机智能AI')), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(getLanguage)('天机大模型面向工业设备管理者，拥有设备维护、故障诊断、波形分析等多种知识库，旨在为用户提供全面、专业的解答和使用建议！')), 1)
        ]),
        _createVNode(ChatLayout)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})