import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"color":"#fff"} }
const _hoisted_6 = { class: "w-full flex" }
const _hoisted_7 = { class: "flex items-center mt-4" }
const _hoisted_8 = { class: "flex-1 text-center" }
const _hoisted_9 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_10 = {
  key: 2,
  class: "flex justify-center mt-4"
}

import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "@/common/lang";
import * as echarts from "echarts";
import { transformDate, getGroupListByFacID, getGroupName } from "../../common/tools";

export default /*@__PURE__*/_defineComponent({
  __name: 'threshold-feedback',
  setup(__props) {

let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
}
getLang()

let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
})
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
  showSizeChanger: true,
}));


let factoryAll:any = ref({})
let factoryList:any = ref({})
let groupSearchList:any = ref([])
let machineSearchList:any = ref([])
let dataList:any = ref([])
let visible:any = ref(false)

const columns = [
    {
        title: "设备名称",
        dataIndex: "machineName",
        align: "center"
    },
    {
        title: "所属工厂",
        dataIndex: "factoryName",
        align: "center"
    },
    {
        title: "设备区域",
        dataIndex: "groupName",
        align: "center"
    },
    {
        title: "调整时间",
        dataIndex: "adjustDate",
        align: "center"
    },
    {
        title: "确认时间",
        dataIndex: "confirmDate",
        align: "center"
    },
    {
        title: "调整程度",
        dataIndex: "adjustDegree",
        align: "center"
    },
    {
        title: "调整范围",
        dataIndex: "adjustRange",
        align: "center"
    },
    {
        title: "确认状态",
        dataIndex: "status",
        align: "center"
    },
    {
        title: "调整人",
        dataIndex: "adjustMember",
        align: "center"
    },
    {
        title: "确认人",
        dataIndex: "confirmMember",
        align: "center"
    },
    {
        title: "操作",
        dataIndex: "act",
        align: "center"
    },
]


const reportColumns = [
    {
        title: '报告时间',
        dataIndex: "date",
    },
    {
        title: '严重程度',
        dataIndex: "condition",
    },
    {
        title: '故障模式',
        dataIndex: "falult",
    },
    {
        title: '诊断描述',
        dataIndex: "diagnosticNotes",
    },
    {
        title: '调整后状态',
        dataIndex: "adjustCondition",
    },
]

const scoreColumns = [
    {
        title: '调整时间',
        dataIndex: "adjustDate",
    },
    {
        title: '调整范围',
        dataIndex: "adjustRange",
    },
    {
        title: '调整',
        dataIndex: "adjustDegree",
    },
    {
        title: '调整原因',
        dataIndex: "adjustReason",
    }
]
const confirmColumns = [
    {
        title: '确认时间',
        dataIndex: "confirmDate",
    },
    {
        title: '确认状态',
        dataIndex: "status",
    },
    {
        title: '原因',
        dataIndex: "confirmReason",
    },
]
const confirmColumns2 = [
    {
        title: '确认状态',
        dataIndex: "status",
    },
    {
        title: '原因',
        dataIndex: "confirmReason",
    },
]

let adjustDegreeList = [
    {label: '准确', value: 0},
    {label: '轻微', value: 1},
    {label: '略轻微', value: 2},
    {label: '严重', value: 7},
    {label: '略严重', value: 8},
]
let statusList = [
    {label: '待确认', value: 1},
    {label: '无需确认', value: 2},
    {label: '已确认', value: 3},
    {label: '取消调整', value: 4},
]
let statusList2 = [
    {label: '确认', value: 3},
    {label: '取消调整', value: 4},
]
let adjustRangeList = [
    {label: '调整设备整体分数', value: 'machine'},
    {label: '调整选中报告的测点指标分数', value: "report-sensor"},
]
let conditionList = [
    {label: '健康', value: 0},
    {label: '可用', value: 1},
    {label: '可用', value: 2},
    {label: '警戒', value: 3},
    {label: '警戒', value: 4},
    {label: '警戒', value: 5},
    {label: '故障', value: 6},
    {label: '故障', value: 7},
]

onMounted(() => {
  init()
})

const init = () => {
  getFactoryList()
  search()
}

// 获取工厂列表
const getFactoryList = async () => {
    let result = await request.get('/api/factories')
    if (result && result.data) {
        factoryAll.value = result.data
        let parentIds = result.data.map((p: any) => p.parentId)
        parentIds = [...new Set(parentIds)]
        let list = result.data.filter((p: any) => !parentIds.includes(p._id))
        let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
        list = list.filter((p: any) => memberInfo.factoryIds.includes(p._id))
        factoryList.value = list
    }
}

// 获取查询设备列表
const selectFactorySearch = async (ev: any) => {
  if (ev) {
    groupSearchList.value = getGroupListByFacID(ev)
  } else {
    groupSearchList.value = []
  }
  searchModel.value.groupId = null
  searchModel.value.machineId = null
}

// 选择查询区域
const selectGroupSearch = async (ev: any) => {
    if (ev) {
        const config = {
            params: {
                factoryId: searchModel.value.factoryId,
                groupId: searchModel.value.groupId,
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let result = await request.get('/api/machines', config)
        if (result && result.data) {
            machineSearchList.value = result.data
        } else {
            machineSearchList.value = []
        }
    } else {
        machineSearchList.value = []
    }
}

const reset = () => {
    searchModel.value = {
        skip: 1,
        take: 20,
        total: searchModel.value.total,
        factoryId: null,
        groupId: null,
        machineId: null,
        adjustDate: null,
        confirmDate: null,
        adjustDegree: null,
        status: null,
    }
}

const search = async() => {
    if (searchModel.value.adjustDegree && searchModel.value.adjustDegree.length === 2) {
        searchModel.value.adjustDateBegin = transformDate(searchModel.value.adjustDegree[0])
        searchModel.value.adjustDateEnd = transformDate(searchModel.value.adjustDegree[1])
    } else {
        searchModel.value.adjustDateBegin = ''
        searchModel.value.adjustDateEnd = ''
    }
    if (searchModel.value.confirmDate && searchModel.value.confirmDate.length === 2) {
        searchModel.value.confirmDateBegin = transformDate(searchModel.value.confirmDate[0])
        searchModel.value.confirmDateEnd = transformDate(searchModel.value.confirmDate[1])
    } else {
        searchModel.value.confirmDateBegin = ''
        searchModel.value.confirmDateEnd = ''
    }


    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            factoryId: searchModel.value.factoryId,
            groupId: searchModel.value.groupId,
            machineId: searchModel.value.machineId,
            adjustDateBegin: searchModel.value.adjustDateBegin,
            adjustDateEnd: searchModel.value.adjustDateEnd,
            confirmDateBegin: searchModel.value.confirmDateBegin,
            confirmDateEnd: searchModel.value.confirmDateEnd,
            adjustDegree: searchModel.value.adjustDegree,
            status: searchModel.value.status,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/threshold-feedbacks', config)
    dataList.value = result?.data?.list || []
}

const handleTableChange = (ev: any, a: any, sorter: any) => {
    searchModel.value = {
        ...searchModel.value,
        skip: ev.current,
        take: ev.pageSize,
    }
    search()
}

// 确认
let viewForm:any = ref({
    reports: []
})
let viewSearch:any = ref({
    sensorId: '',
})
let sensorListView:any = ref([])
let viewAdjustList:any = ref([])
let faultChartSelectlengedView:any = {}
let healthChartSelectlengedView:any = {}
let myChartE:any = null
let myChartF:any = null
let viewAdjustData:any = ref([])
let viewConfirmData:any = ref([])
let isSubmit:any = ref(false)
let faultName:any = []
let faultNameII:any = []
const sure = async(ev:any, isSub:any) => {
    let faultnamesSearch = {
        params: { machineId: ev.machineId },
        headers: {
        requestId: uuidv4(),
        },
    };
    let result2 = await request.get("/api/machines/faultnames", faultnamesSearch);
    if (result2 && result2.data) {
        faultName = result2.data.faultNames;
        faultNameII = result2.data.faultNameExpects;
    }

    isSubmit.value = isSub
    let config: any = {
        params: {
            machineId: ev.machineId,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors/search", config);
    let sensorListAll = result.data || [];


    viewForm.value = ev
    if(!viewForm.value?.reports) {
        viewForm.value.reports = []
    }
    viewAdjustData.value = [{
        adjustDate: ev.adjustDate,
        adjustRange: ev.adjustRange,
        adjustDegree: ev.adjustDegree,
        adjustReason: ev.adjustReason,
    }]
    viewConfirmData.value = [{
        confirmDate: ev.confirmDate,
        status: 3,
        confirmReason: ev.confirmReason,
    }]
    let isDevAll = ev.adjustRange === 'machine' ? true : false
    let detailInfos = ev?.adjustDetails || []
    let machineScores = ev?.machineScores || []
    viewAdjustList.value = []
    

    let sensorIds = [... new Set(detailInfos.map((d:any) => d.sensorId))]

    if(isDevAll) {
        sensorListView.value = [
            {sensorPlace: '全设备测点', id: '' }
        ]
        viewSearch.value.sensorId = ''
    } else {
        sensorListView.value = []
        viewSearch.value.sensorId = sensorIds[0]
    }

    // sensorListView.value = []
    // viewSearch.value.sensorId = sensorIds[0]
    sensorIds.map((d:any) => {
        if(isDevAll) {
            let faults:any = []
            faultName.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });
            faultNameII.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });

            let dateList = machineScores.map((p:any) => p.date)
            faults.map((f:any) => {
                f.dateList = dateList
                f.data = []
            })
            machineScores.map((d:any) => {
                faults.map((f:any) => {
                    f.data.push([d.date, d[f.column]])
                })
            })
            let obj = {
                sensorId: '',
                dataList: faults
            }
            viewAdjustList.value.push(obj)
        } 


        let sensorInfo = sensorListAll.find((p:any) => p.id == d)
        sensorListView.value.push(sensorInfo)
        let detailInfoSens = detailInfos.filter((p:any) => p.sensorId == d)
        let dataList:any = []
        detailInfoSens.map((s:any) => {
            let dates = s.result?.date || []
            let datas = s.result?.[s.funcTag] || []
            let data = []
            for (let index = 0; index < dates.length; index++) {
                let date = dates[index]
                let data2 = datas[index]
                data.push([date.slice(5, 19), data2])
            }

            let obj1 = {
                column: s.funcTag,
                title: s.faultName,
                dateList: s.result?.date || [],
                data
            }

           
            dataList.push(obj1)
        })
        let obj = {
            sensorId: d,
            dataList
        }

        viewAdjustList.value.push(obj)
    })

    getChartDataView()


    visible.value = true
}

// 选择测点
const selectSensor3 = (ev:any) => {
    getChartDataView()
}

const getChartDataView = () => {
    let adjustListInfo = viewAdjustList.value.find((p:any) => p.sensorId == viewSearch.value.sensorId)

    let dataList = adjustListInfo.dataList || []
    let fault = dataList.filter((p:any) => p.column.includes('S'))
    let faultII = dataList.filter((p:any) => p.column.includes('T'))
    let faultNames = fault.map((p:any) => p.title)
    let faultNameIIs = faultII.map((p:any) => p.title)
    healthChartSelectlengedView = {}
    faultChartSelectlengedView = {}
    faultNames.map((d:any) => {
        faultChartSelectlengedView[d] = true
    })
    faultNameIIs.map((d:any) => {
        healthChartSelectlengedView[d] = true
    })

    setTimeout(() => {
        initChartE(faultII)
        initChartF(fault)
    }, 1000);
}


const initChartE = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartE")) return;
    myChartE = ec.init(document.getElementById("optimizationChartE"));
    myChartE.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(item.title);
            DataInfo.push({
                name: item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 16,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(healthChartSelectlengedView, DataInfo);

    
    if (option && typeof option === "object") {
        myChartE && myChartE.clear();
        myChartE && myChartE.setOption(option, true);
    }

    myChartE
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlengedView = params.selected;
        Init(healthChartSelectlengedView, DataInfo);
        if (option && typeof option === "object") {
            myChartE?.clear();
            myChartE?.setOption(option, true);
        }
    });
}

const initChartF = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartF")) return;
    myChartF = ec.init(document.getElementById("optimizationChartF"));
    myChartF.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(item.title);
            DataInfo.push({
                name: item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 16,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(faultChartSelectlengedView, DataInfo);

    
    if (option && typeof option === "object") {
        myChartF && myChartF.clear();
        myChartF && myChartF.setOption(option, true);
    }

    myChartF
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlengedView = params.selected;
        Init(faultChartSelectlengedView, DataInfo);
        if (option && typeof option === "object") {
            myChartF?.clear();
            myChartF?.setOption(option, true);
        }
    });
}


const submit = async() => {
    let submitForm = {
        id: viewForm.value.id,
        status: viewConfirmData.value[0].status,
        confirmReason: viewConfirmData.value[0].confirmReason,
    }
    if(!submitForm.status) {
        return message.warning('请选择确认状态后提交!')
    }

    let result = await request.post('/api/threshold-feedbacks/confirm', submitForm)
    if(result?.data) {
        message.success('操作成功!')
        visible.value = false
        search()
    } else {
        message.warning('操作失败!')
    }
}











// 获取faultNames
const getFaultNames = (ev:any) => {
    let list = ev.map((p:any) => p.faultName)
    return list.join(",")
}
const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { type: "flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              order: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  layout: "inline",
                  style: {"padding":"0 20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { label: "项目" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _unref(searchModel).factoryId,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).factoryId) = $event)),
                          style: {"width":"200px"},
                          dropdownMatchSelectWidth: false,
                          onChange: selectFactorySearch,
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: index,
                                value: item._id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.factoryName), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "区域" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _unref(searchModel).groupId,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                          style: {"width":"200px"},
                          dropdownMatchSelectWidth: false,
                          onChange: selectGroupSearch,
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupSearchList), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: index,
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "设备" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _unref(searchModel).machineId,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                          style: {"width":"200px"},
                          dropdownMatchSelectWidth: false,
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineSearchList), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: index,
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.machineName), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "调整时间" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(searchModel).adjustDate,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).adjustDate) = $event)),
                          style: {"width":"400px"},
                          "show-time": "",
                          format: "YYYY/MM/DD HH:mm:ss",
                          allowClear: ""
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "确认时间" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(searchModel).confirmDate,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).confirmDate) = $event)),
                          style: {"width":"400px"},
                          "show-time": "",
                          format: "YYYY/MM/DD HH:mm:ss",
                          allowClear: ""
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "调整程度" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _unref(searchModel).adjustDegree,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(searchModel).adjustDegree) = $event)),
                          style: {"width":"200px"},
                          dropdownMatchSelectWidth: false,
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(adjustDegreeList), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: index,
                                value: item.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, { label: "确认状态" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _unref(searchModel).status,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).status) = $event)),
                          style: {"width":"200px"},
                          dropdownMatchSelectWidth: false,
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: index,
                                value: item.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (reset()))
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("重置")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_button, {
                          class: "ml-4",
                          type: "primary",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(searchModel).skip = 1, search()))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("查询")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_table, {
                    bordered: "",
                    emptyText: '暂无数据',
                    columns: columns,
                    "data-source": _unref(dataList),
                    pagination: pagination.value,
                    onChange: handleTableChange,
                    size: "small",
                    "row-class-name": getRowClassName
                  }, {
                    bodyCell: _withCtx(({ column, text, record, index }) => [
                      (column.dataIndex === 'adjustRange')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_unref(adjustRangeList).find(p => p.value === text )?.label), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'adjustDegree')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_unref(adjustDegreeList).find(p => p.value === text )?.label), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'status')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(_unref(statusList).find(p => p.value === text )?.label), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'act')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            (record.status == 2 || record.status == 3 || record.status == 4)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  onClick: ($event: any) => (sure(record,false))
                                }, "查看详情", 8, _hoisted_3))
                              : _createCommentVNode("", true),
                            (record.status == 1)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  onClick: ($event: any) => (sure(record,true))
                                }, "确认", 8, _hoisted_4))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["data-source", "pagination"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_isRef(visible) //@ts-ignore
 ? visible.value = false : visible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", {
            class: "text-center w-full",
            style: {"font-size":"24px"}
          }, " 确认分数调整 ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _cache[15] || (_cache[15] = _createElementVNode("div", null, "诊断趋势", -1)),
            _createElementVNode("div", _hoisted_8, "数据集： " + _toDisplayString(_unref(viewForm).startDate) + " - " + _toDisplayString(_unref(viewForm).endDate), 1),
            _createVNode(_component_a_select, {
              ref: "select",
              value: _unref(viewSearch).sensorId,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(viewSearch).sensorId) = $event)),
              style: {"min-width":"150px"},
              onChange: selectSensor3
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListView), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex mt-4" }, [
            _createElementVNode("div", { class: "flex-1" }, [
              _createElementVNode("div", { class: "text-center" }, "健康指标诊断分数趋势图"),
              _createElementVNode("div", {
                class: "w-full h-96",
                id: "optimizationChartE"
              })
            ]),
            _createElementVNode("div", { class: "flex-1 ml-4" }, [
              _createElementVNode("div", { class: "text-center" }, "振动指标诊断分数趋势图"),
              _createElementVNode("div", {
                class: "w-full h-96",
                id: "optimizationChartF"
              })
            ])
          ], -1)),
          _cache[18] || (_cache[18] = _createElementVNode("div", null, "诊断报告", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: reportColumns,
              "data-source": _unref(viewForm).reports,
              pagination: false,
              bordered: ""
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_unref(conditionList).find(p => p.value === text)?.label), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'falult')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(getFaultNames(record.sensorFaults)), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'adjustCondition')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_unref(conditionList).find(p => p.value === text)?.label), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source"])
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "mt-4" }, "调整模型", -1)),
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: scoreColumns,
            "data-source": _unref(viewAdjustData),
            pagination: false,
            bordered: ""
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'adjustRange')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(adjustRangeList).find(p => p.value===text)?.label), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'adjustDegree')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_unref(adjustDegreeList).find(p => p.value===text)?.label), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source"]),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mt-4" }, "确认", -1)),
          (!_unref(isSubmit))
            ? (_openBlock(), _createBlock(_component_a_table, {
                key: 0,
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: confirmColumns,
                "data-source": _unref(viewConfirmData),
                pagination: false,
                bordered: ""
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(statusList).find(p => p.value===text)?.label), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"]))
            : _createCommentVNode("", true),
          (_unref(isSubmit))
            ? (_openBlock(), _createBlock(_component_a_table, {
                key: 1,
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: confirmColumns2,
                "data-source": _unref(viewConfirmData),
                pagination: false,
                bordered: ""
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 0,
                        ref: "select",
                        value: record.status,
                        "onUpdate:value": ($event: any) => ((record.status) = $event),
                        style: {"min-width":"150px"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList2), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value", "onUpdate:value"]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'confirmReason')
                    ? (_openBlock(), _createBlock(_component_a_input, {
                        key: 1,
                        value: record.confirmReason,
                        "onUpdate:value": ($event: any) => ((record.confirmReason) = $event)
                      }, null, 8, ["value", "onUpdate:value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"]))
            : _createCommentVNode("", true),
          (_unref(isSubmit))
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "btn3 ml-2",
                  onClick: submit
                }, " 提交 ")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})