import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cardHead" }
const _hoisted_2 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_3 = {
  key: 0,
  class: "box1"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = {
  key: 0,
  class: "box"
}
const _hoisted_8 = { class: "flex justify-center mt-4 mb-4" }
const _hoisted_9 = {
  class: "main",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { style: {"max-height":"600px","overflow-y":"scroll"} }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_index_outlined = _resolveComponent("node-index-outlined")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.locale === 'en' ? _ctx.enUS : _ctx.zhCN
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_card, { bordered: false }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_node_index_outlined),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.threshold.sensorPlace), 1)
            ])
          ]),
          extra: _withCtx(() => [
            _createVNode(_component_a_radio_group, { size: "default" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_button, { value: "a" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getHistories && _ctx.getHistories(...args)))
                    }, "阈值修改历史"),
                    (_ctx.historyVisible)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("a", {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.historyVisible = false))
                          }, "关闭"),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyList, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", null, _toDisplayString(item.createAt), 1),
                                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                _createElementVNode("a", {
                                  onClick: ($event: any) => (_ctx.restore(item))
                                }, "还原", 8, _hoisted_5)
                              ]),
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", null, _toDisplayString(item.createMember), 1),
                                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                _createElementVNode("div", null, _toDisplayString(item.featureType == "Expect" ? '专业指标' : '') + " " + _toDisplayString(item.featureType == "Common" ? '振动指标' : '') + " " + _toDisplayString(item.featureType == "Other" ? '其他' : ''), 1)
                              ]),
                              _createElementVNode("div", null, "调整原因: " + _toDisplayString(item.reason), 1),
                              _cache[16] || (_cache[16] = _createElementVNode("div", {
                                class: "mb-4",
                                style: {"height":"1px","background":"gray"}
                              }, null, -1))
                            ]))
                          }), 128)),
                          _createVNode(_component_a_pagination, {
                            onChange: _ctx.changeSkip,
                            current: _ctx.searchModel.skip,
                            "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchModel.skip) = $event)),
                            total: _ctx.searchModel.total,
                            defaultPageSize: _ctx.searchModel.take,
                            "show-less-items": ""
                          }, null, 8, ["onChange", "current", "total", "defaultPageSize"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio_button, {
                  value: "b",
                  onClick: _ctx.add
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['新增']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 0,
                      value: "c",
                      onClick: _ctx.qX
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['取消']), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 1,
                      value: "c",
                      disabled: !(_ctx.dataSource && _ctx.dataSource.length),
                      onClick: _ctx.enableEdit
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['编辑']), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isEdit)
                  ? (_openBlock(), _createBlock(_component_a_radio_button, {
                      key: 2,
                      style: {"position":"relative"},
                      value: "c"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reason = '', _ctx.reasonVisible = true))
                        }, _toDisplayString(_ctx.langObj['保存']), 1),
                        (_ctx.reasonVisible)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _cache[17] || (_cache[17] = _createTextVNode(" 请输入修改原因： ")),
                              _createVNode(_component_a_textarea, {
                                value: _ctx.reason,
                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reason) = $event)),
                                rows: 3
                              }, null, 8, ["value"]),
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", {
                                  class: "btn3",
                                  style: {"padding":"0 10px"},
                                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.reasonCancel && _ctx.reasonCancel(...args)))
                                }, " 取消 "),
                                _createElementVNode("div", {
                                  class: "btn3 ml-2",
                                  style: {"padding":"0 10px"},
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saveConfig(null)))
                                }, " 提交 ")
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_radio_button, {
                  value: "d",
                  onClick: _ctx.copy
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['复制']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_radio_button, {
                  value: "d",
                  onClick: _ctx.pasteOverlay
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.langObj['粘贴']), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_popconfirm, {
                  title: _ctx.langObj['确定删除所选数据么'] + '?',
                  "ok-text": _ctx.langObj['确定'],
                  "cancel-text": _ctx.langObj['取消'],
                  onConfirm: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deleteThis()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_button, { value: "d" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.langObj['删除']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title", "ok-text", "cancel-text"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.formState.activeKey,
          "onUpdate:activeKey": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.activeKey) = $event)),
          onChange: _ctx.tabsChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "Expect",
              tab: _ctx.langObj['专业指标'],
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "Common",
              tab: _ctx.langObj['振动指标'],
              disabled: _ctx.isEdit,
              "force-render": ""
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "Other",
              tab: _ctx.langObj['其他'],
              disabled: _ctx.isEdit
            }, null, 8, ["tab", "disabled"])
          ]),
          _: 1
        }, 8, ["activeKey", "onChange"]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(), _createBlock(_component_a_table, {
            bordered: "",
            emptyText: '暂无数据',
            "row-selection": {
          selectedRowKeys: _ctx.mainSelectedRowKeys,
          onChange: _ctx.onMainSelectChange,
        },
            key: _ctx.formState.activeKey + 'tb',
            columns: _ctx.columns,
            "data-source": _ctx.dataSource,
            pagination: false,
            onChange: _ctx.onMainSelectChange
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'scores')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.isEdit)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: item.faultId + (item.name || item.scoreName)
                            }, [
                              (!((item.name || item.scoreName).indexOf('Vexp') > -1))
                                ? (_openBlock(), _createBlock(_component_a_tag, {
                                    color: "green",
                                    key: item.faultId + (item.name || item.scoreName)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name || item.scoreName) + ":" + _toDisplayString(item.scoreValue), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_a_form, { layout: "inline" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: item.faultId + (item.name || item.scoreName)
                                }, [
                                  (!((item.name || item.scoreName).indexOf('Vexp') > -1))
                                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                                        key: item.faultId + (item.name || item.scoreName),
                                        label: item.name || item.scoreName,
                                        name: item.name || item.scoreName,
                                        required: item.required
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(), _createBlock(_component_a_input_number, {
                                            key: item.faultId + (item.name || item.scoreName),
                                            step: 0.01,
                                            value: item.scoreValue,
                                            "onUpdate:value": ($event: any) => ((item.scoreValue) = $event),
                                            placeholder: _ctx.langObj['请输入']
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                        ]),
                                        _: 2
                                      }, 1032, ["label", "name", "required"]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["row-selection", "columns", "data-source", "onChange"]))
        ]),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: _ctx.modalState.visible,
          "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalState.visible) = $event)),
          title: _ctx.modalState.title,
          "confirm-loading": _ctx.modalState.confirmLoading,
          onOk: _ctx.handleOk
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_a_form_item, {
                  label: _ctx.langObj['细分故障'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      style: {"width":"220px"},
                      placeholder: _ctx.langObj['请输入关键词搜索'],
                      value: _ctx.modalState.segmentFaultV,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalState.segmentFaultV) = $event))
                    }, null, 8, ["placeholder", "value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", {
                        onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args))),
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_ctx.langObj['重置']), 1),
                      _createElementVNode("div", {
                        onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_ctx.langObj['查询']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_a_table, {
                class: "modal-table",
                bordered: "",
                "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
          },
                columns: _ctx.columnsZB,
                pagination: false,
                "data-source": _ctx.displayData,
                onChange: _ctx.handleTableChange
              }, null, 8, ["row-selection", "columns", "data-source", "onChange"])
            ])
          ]),
          _: 1
        }, 8, ["visible", "title", "confirm-loading", "onOk"]),
        _createVNode(_component_a_modal, {
          visible: _ctx.modalState.open,
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modalState.open) = $event)),
          width: "500px",
          title: "提示"
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_a_button, {
              key: "back",
              onClick: _ctx.copyLone
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("复制单个模块")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              key: "submit",
              type: "primary",
              onClick: _ctx.copyAll
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("复制全部")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _cache[20] || (_cache[20] = _createElementVNode("p", { style: {"color":"#fff"} }, "请选择复制的内容", -1))
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}