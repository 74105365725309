<template>
  <primitive v-if="modelPath && scene" :object="scene" @context-menu="onMarkPoint" />
</template>

<script lang="ts" setup>
import { useTresContext } from '@tresjs/core'
import { useLoader } from '@tresjs/core'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';

import { Box3, Scene, Vector3 } from 'three'

let props = withDefaults(defineProps<{
  modelPath: string,
  modelSize?: number,
  cameraFov?: number
}>(), {
  modelPath: '',
  modelSize: 2,
  cameraFov: 15
});

let emits = defineEmits(['context-menu'])

const { camera } = useTresContext()
const setModelSize = (sceneV: Scene | null) => {
  if (!sceneV) return;
  sceneV.updateMatrixWorld();
  const box = new Box3().setFromObject(sceneV);
  let vector3 = new Vector3();
  const size = box.getSize(vector3);
  const center = box.getCenter(vector3);
  // 计算缩放比例
  const maxSize = Math.max(size.x, size.y, size.z);

  let targetSize = props.modelSize; // 目标大小

  //const scale = targetSize / (maxSize > 1 ? maxSize : 0.5);
  const scale = targetSize / maxSize;
  sceneV.scale.set(scale, scale, scale);
  // 设置模型位置
  sceneV.position.sub(center.multiplyScalar(scale));
}

let model: any = await useLoader(GLTFLoader, props.modelPath)
let scene: Scene = model.scene;
setModelSize(scene)


// 设置相机位置
camera.value?.position.set(0, 20, 50);
(camera.value as any).fov = props.cameraFov;
(camera.value as any).far = 10000;
(camera.value as any).near = 0.1;
// 设置相机坐标系
(camera.value as any)?.updateProjectionMatrix();


let timer: any = null
function debounce(fn: any, delay = 1000) {
  if (timer != null) {
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(fn, delay)
}

const onMarkPoint = (ev: any) => {
  if (!ev) return;

  // 每次变更模型文件，都会导致多绑定一个右键事件（疑似tres的 bug）,所以这里做了一个防抖处理
  debounce(() => {
    emits('context-menu', ev)
  }, 200)
}

</script>

<style lang="less" scoped></style>