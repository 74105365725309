import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "w-full theme-common whiteBg",
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000a32","overflow":"hidden","padding-bottom":"30px"}
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = {
  class: "flex-1 flex ml-4",
  style: {"color":"#fff"}
}
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "w-full flex items-center" }
const _hoisted_6 = { class: "btn1 btn1-select" }
const _hoisted_7 = {
  class: "w-full flex px-4 mt-2 whiteBg",
  style: {"border":"1px solid #4a5983","background":"#07249933"}
}
const _hoisted_8 = { class: "w-full flex mt-2" }
const _hoisted_9 = { style: {"min-width":"250px"} }
const _hoisted_10 = { class: "flex-1 whiteBg" }
const _hoisted_11 = { class: "flex-1 content ml-2" }
const _hoisted_12 = { class: "w-full flex items-center" }
const _hoisted_13 = { class: "flex items-center mt-4" }
const _hoisted_14 = { class: "flex ml-2" }
const _hoisted_15 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_16 = {
  class: "w-full flex items-center mt-2",
  style: {"justify-content":"center"}
}
const _hoisted_17 = { class: "flex-1 flex mt-4 content ml-2" }
const _hoisted_18 = {
  key: 0,
  class: "flex"
}
const _hoisted_19 = { class: "flex-1 ms-2 pb-2" }
const _hoisted_20 = { class: "mt-4" }

import {
  onMounted,
  onUnmounted,
  ref
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate2
} from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";
import GroupModel from "@/components/model/group-model.vue";
import AlarmItem from "@/components/system/common/AlarmItem.vue";
import GroupAlarmItem from "@/components/system/common/GroupAlarmItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'group',
  setup(__props) {

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();


const redirectToSimpleModel = () => {
  router.push("simple-model");
}

let sensorInfo: any = ref({});

let isGroupConfirmed = ref(false);
let onClickConfirmGroup = () => {
  if (isGroupConfirmed.value) {
    isGroupConfirmed.value = false;
    return;
  }

  if (cbxSimpleDataType.value.length == 2) {
    isGroupConfirmed.value = true;
    reInit();
  } else {
    message.warning("请选择两个数据类型!");
  }
};

let reInit = async () => {
};

const getPointColor = (healthState: number) => {
  if (healthState == 0) {
    return "lightgreen";
  } else if (healthState == 1) {
    return "yellow";
  } else if (healthState == 2) {
    return "orange";
  } else if (healthState == 3) {
    return "red";
  } else {
    return "white";
  }
};

let customPoints: { key: string, data: number[], grade: number }[] = [];
const clearCustomPoints = () => {
  customPoints.length = 0;
}

let pointToAdd = ref({
  xValue: null,
  yValue: null,
  healthState: null,
});
let onClickAddPoint = () => {
  if (!pointToAdd.value.xValue) {
    message.warn("请输入X轴值")
    return;
  }
  if (!pointToAdd.value.yValue) {
    message.warn("请输入Y轴值")
    return;
  }
  if (pointToAdd.value.healthState == null) {
    message.warn("请选择健康状态")
    return;
  }
  const x = Number(pointToAdd.value.xValue);
  const y = Number(pointToAdd.value.yValue);
  const idx = customPoints.findIndex(val => val.key == `${x}-${y}`);
  if (idx != -1 && customPoints[idx].grade != pointToAdd.value.healthState) {
    //数据点已存在
    customPoints[idx].grade = Number(pointToAdd.value.healthState);
    chartDataArr.push(
        {
          value: [x, y],
          itemStyle: {
            color: getPointColor(pointToAdd.value.healthState)
          }
        }
    );
  } else {
    customPoints.push({ key: `${x}-${y}`, data: [x, y], grade: pointStateToUpdate.value });
    chartDataArr.push(
        {
          value: [x, y],
          itemStyle: {
            color: getPointColor(pointToAdd.value.healthState)
          }
        }
    );
  }
  updateChart();
};

let pointStateToUpdate = ref(-1);
let onClickUpdatePointState = () => {
  if (selectedChartDataIndexArr.length == 0) {
    message.warn("请选择要修改的数据点")
    return;
  }
  const heathState = pointStateToUpdate.value;
  selectedChartDataIndexArr.forEach(val => {
    let item = chartDataArr[val];
    const x = item.value[0];
    const y = item.value[1];

    item.itemStyle.color = getPointColor(pointStateToUpdate.value);
    const idx = customPoints.findIndex(val => val.key == `${x}-${y}`);
    if (idx != -1 && customPoints[idx].grade != heathState) {
      //数据点已存在
      customPoints[idx].grade = heathState;
    } else {
      customPoints.push({ key: `${x}-${y}`, data: [x, y], grade: heathState });
    }
  })
  updateChart();
}

let compareDataType: any = ref([]);

let cbxSimpleDataType: any = ref([]);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

const getQuotaGroupAlarmConfig = async () => {
  if (!sensorInfo.value) return;
  let result = await request.get("/api/sensors/quota-group-alarm?sensorId=" + sensorInfo.value.id);
  if (result?.data) {
    sensorInfo.value.table = result.data;
  } else {
    sensorInfo.value.table = [];
  }
};

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadDataTypes();
    if (result.data.dataType) {
      compareDataType.value = dataTypes.value.filter((p: any) =>
          result.data.dataType.includes(p.name)
      );
    } else {
      compareDataType.value = [];
    }

    await getQuotaGroupAlarmConfig();
    myChart.clear();
  }
};

onMounted(() => {
  loadDataTypes();
  (echarts as any).dispose(document.getElementById("chart1"));
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

let myChart: any = null;
let selectedChartDataIndexArr: number[] = []; // 已选择的数据点
let tempBrushedChargeDataIndexArr: number[] = []; // 缓存框选的数据点
// 雷达图绘制
const updateChart = () => {
  selectedChartDataIndexArr.length = 0;
  myChart.on('selectchanged', function (params: any) {
    if (params.selected && params.selected.length > 0) {
      //console.log("selectchanged", params.selected[0].dataIndex)
      selectedChartDataIndexArr = params.selected[0].dataIndex;
    } else {
      selectedChartDataIndexArr.length = 0;
    }
    //console.log('chart select changed', params);
  });

  myChart.on('brushSelected', function (params: any) {
    let brushedIndexes: number[] = [];
    let brushed = [];
    let brushComponent = params.batch[0];
    //console.log("brushSelected", params)
    for (let sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      let rawIndices = brushComponent.selected[sIdx].dataIndex;
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
      brushedIndexes.push(...rawIndices);
    }
    if (brushedIndexes.length > 0) {
      // 框选
      //将框选的数据点添加到待修改健康状态数组中
      selectedChartDataIndexArr.push(...brushedIndexes);
      selectedChartDataIndexArr = [...new Set(selectedChartDataIndexArr)]; // 去重

      tempBrushedChargeDataIndexArr = [...brushedIndexes];
      myChart.dispatchAction({
        type: 'select',
        seriesIndex: 0,
        dataIndex: selectedChartDataIndexArr
      });
    } else {
      // 取消框选
      myChart.dispatchAction({
        type: 'unselect',
        seriesIndex: 0,
        dataIndex: tempBrushedChargeDataIndexArr
      });
      for (let i = 0; i < tempBrushedChargeDataIndexArr.length; i++) {
        // 从待修改健康状态的数组中移除取消框选的元素
        const item = tempBrushedChargeDataIndexArr[i];
        selectedChartDataIndexArr.splice(selectedChartDataIndexArr.indexOf(item), 1);
      }
      tempBrushedChargeDataIndexArr.length = 0;
    }
  });

  myChart.clear();

  let selectedDataTypes = cbxSimpleDataType.value.sort().map((val: number) => {
    return dataTypes.value.find((type: any) => val === type.code);
  });
  let xName = selectedDataTypes[0].name;
  if (selectedDataTypes[0].unit) {
    xName += `(${selectedDataTypes[0].unit})`;
  }
  let yName = selectedDataTypes[1].name;
  if (selectedDataTypes[1].unit) {
    yName += `(${selectedDataTypes[1].unit})`;
  }

  const option = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any) {
        let result = "";
        if (params.data && params.data.value && params.data.value.length == 2) {
          result = `${params.data.value[0]}, ${params.data.value[1]}`
        }
        return result;
      }
    },
    toolbox: {
      right: 200,
      iconStyle: {
        color: "white",
        borderColor: "white",
        borderWidth: 2
      }
    },
    dataZoom: {
      type: 'inside'
    },
    brush: {
      toolbox: ['rect', 'clear'],
      xAxisIndex: 0,
      throttleType: 'debounce',
      throttleDelay: 500,
      outOfBrush: {
        colorAlpha: 0.6
      }
    },
    xAxis: [{
      name: xName,
      nameLocation: "center",
      nameTextStyle: {
        padding: [5, 0, 0, 0]
      },
      axisLine: { show: false },
      splitLine: { show: false }
    }],
    yAxis: {
      name: yName
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 7,
        data: chartDataArr,
        selectedMode: "multiple",
        select: {
          itemStyle: {
            borderWidth: 1.5,
            borderColor: "cyan",
            borderType: "solid"
          }
        }
      }
    ],
  };

  option && myChart.setOption(option);
};

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

function getQueryDate(): { begin: string, end: string } {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = date;
  }
  let begin = (dateForm.begin = transformDate2(beginDate, 1)) || "";
  let end = (dateForm.end = transformDate2(endDate, 2)) || "";
  return { begin: begin, end: end };
}

let chartDataArr: any[] = [];
let quotaSearch = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return
  }

  let dateQuery = getQueryDate()

  let selectedDataCodes = cbxSimpleDataType.value.sort();
  let result = await request.post("/api/sensors-hardware/data", {
    begin: dateQuery.begin,
    end: dateQuery.end,
    sensorId: sensorInfo.value.id,
    codes: selectedDataCodes,
  });

  chartDataArr.length = 0;
  let xDateValueMap: Map<number, number> = new Map();
  let yDateValueMap: Map<number, number> = new Map();
  let oneMinuteInMilliseconds = 60000;
  if (result.data && result.data.list) {
    for (let i = 0; i < result.data.list.length; i++) {
      let item = result.data.list[i];
      // 时间戳精确到分钟
      let dateKey = Math.round(item.date / oneMinuteInMilliseconds) * oneMinuteInMilliseconds;
      if (item.code === selectedDataCodes[0]) {
        if (!xDateValueMap.has(dateKey)) {
          xDateValueMap.set(dateKey, item.value);
        }
      } else if (item.code === selectedDataCodes[1]) {
        if (!yDateValueMap.has(dateKey)) {
          yDateValueMap.set(dateKey, item.value);
        }
      }
    }

    Array.from(xDateValueMap.keys()).forEach((key: number) => {
      if (yDateValueMap.has(key)) {
        chartDataArr.push(({
          value: [xDateValueMap.get(key), yDateValueMap.get(key)],
          itemStyle: {
            color: "white",
          }
        }));
      }
    });

  }

  updateChart();
  clearCustomPoints();
};

let dateForm: any = {
  begin: "",
  end: "",
};

let isTraining = ref(false);
const onClickTrainModel = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return;
  }

  let dateQuery = getQueryDate();
  let selectedDataCodes: number[] = cbxSimpleDataType.value.sort();

  isTraining.value = true;

  let postBody = {
    saveModel: false,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as (any[])
      }
    ],
    dataTypes: selectedDataCodes.map(val => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }

  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
  } catch (err) {
    throw err;
  } finally {
    isTraining.value = false;
  }

  chartDataArr.length = 0;
  if (result.data) {
    message.success("训练模型成功!")
    let length = result.data.code_1.length > result.data.code_2.length ? result.data.code_2.length : result.data.code_1.length;
    for (let i = 0; i < length; i++) {
      chartDataArr.push({
        value: [result.data.code_1[i], result.data.code_2[i]],
        itemStyle: {
          color: getPointColor(result.data.label[i]),
        }
      });
    }
    updateChart();
    //clearCustomPoints();
  }


};

let isApplyModelLoading = ref(false);
const onClickApplyModel = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return;
  }

  let dateQuery = getQueryDate();
  let selectedDataCodes = cbxSimpleDataType.value.sort() as number[];

  isApplyModelLoading.value = true;

  let postBody = {
    saveModel: true,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as any[]
      }
    ],
    dataTypes: selectedDataCodes.map((val) => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }

  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
    if (!result.data) return;
  } catch (err) {
    isApplyModelLoading.value = false;
    throw err;
  }

  let modelFilePath: string = result.data;
  let body = {
    dataTypes: cbxSimpleDataType.value.sort(),
    machineId: sensorInfo.value.machineId,
    machineType: sensorInfo.value.machineTypeId,
    sensorIds: [sensorInfo.value.id],
    modelFile: modelFilePath,
    begin: dateQuery.begin,
    end: dateQuery.end,
    values: customPoints.map(val => {
      return {
        data: [val.data[0], val.data[1]],
        status: val.grade
      }
    }),
  };

  try {
    result = await request.put("/api/quota/machine-group-model", body);
    if (result) {
      message.success("应用模型成功!");
    }
  } catch (err) {
    isApplyModelLoading.value = false;
    throw err;
  }

  // 保存组合值报警规则, 假如不否存在该组合的报警规则
  if (!sensorInfo.table || sensorInfo.table.findIndex((t: any) => t.flag === selectedDataCodes.join(".")) === -1) {
    let configBody = {
      sensorId: sensorInfo.value.id,
      list: [{ dataTypes: selectedDataCodes }]
    }
    try {
      result = await request.put("/api/sensors/quota-group-alarm", configBody)
      if (result) {
        message.success("保存组合值报警规则成功!")
        await getQuotaGroupAlarmConfig();
      }
    } catch (err) {
    }
  }
  isApplyModelLoading.value = false;
};

let isSaveModelLoading = ref(false);
let onClickSaveModel = async () => {
  let dateQuery = getQueryDate();

  isSaveModelLoading.value = true;

  let selectedDataCodes = cbxSimpleDataType.value.sort();

  let postBody = {
    saveModel: true,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as any[]
      }
    ],
    dataTypes: selectedDataCodes.map((val: number) => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }
  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
    if (!result.data) return;
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }

  let modelFilePath: string = result.data;

  let body = {
    dataTypes: selectedDataCodes,
    machineType: sensorInfo.value.machineTypeId,
    modelFile: modelFilePath,
    sensorIds: [sensorInfo.value.id],
    begin: dateQuery.begin,
    end: dateQuery.end,
    values: customPoints.map(val => {
      return {
        data: [val.data[0], val.data[1]],
        status: val.grade
      }
    }),
  };
  try {
    let result = await request.post("/api/quota/group-model", body);
    if (result) {
      message.success("保存模板成功!");
    }
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }
};

let onSelectDataType = async () => {
  if (cbxSimpleDataType.value.length >= 3) {
    cbxSimpleDataType.value.splice(0, 1);
  }

  isGroupConfirmed.value = false;
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

var selectMenu = (type: any) => {
  menuSelect.value = type;
};

let dataTypes: any = ref([]);
let isLoadingDataTypes: boolean = false;
let loadDataTypes = async () => {
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  while (isLoadingDataTypes) {
    await sleep(10);
  }
  if (dataTypes.value.length > 0) {
    return;
  }
  let result = await request.post("/api/data-type", { tags: [10] });
  isLoadingDataTypes = false;
  if (result.data) {
    dataTypes.value = result.data;
  }
};


return (_ctx: any,_cache: any) => {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "btn1",
              style: {"border-right":"none"},
              onClick: redirectToSimpleModel
            }, _toDisplayString(_unref(langObj)["单值"]) + "单值 ", 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)["组合值"]) + "组合值", 1),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, {
                borderBottom: _unref(menuSelect) === 1 ? '2px solid #00FFF4' : '',
              }]),
              class: "py-3 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(selectMenu)(1)))
            }, _toDisplayString(_unref(langObj)["配置模型"] || "配置模型"), 5),
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 2 ? '2px solid #00FFF4' : '' }]),
              class: "py-3 ml-12 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(selectMenu)(2)))
            }, _toDisplayString(_unref(langObj)["模型管理"] || "模型管理"), 5),
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 3 ? '2px solid #00FFF4' : '' }]),
              class: "py-3 ml-12 cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(selectMenu)(3)))
            }, _toDisplayString(_unref(langObj)["组合值报警规则"] || "组合报警规则"), 5)
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"width":"250px","height":"calc(100vh - 240px)"}, { top: _unref(scrollTop) }])
                }, [
                  _createVNode(machineListLeftComponent, {
                    onSelectSensor: selectSensor,
                    type: 2
                  })
                ], 4)
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createVNode(_component_a_checkbox_group, {
                      name: "radioGroup",
                      value: _unref(cbxSimpleDataType),
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(cbxSimpleDataType) ? (cbxSimpleDataType).value = $event : cbxSimpleDataType = $event)),
                      onChange: _unref(onSelectDataType),
                      disabled: _unref(isGroupConfirmed)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(compareDataType), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_checkbox, {
                            value: item.code,
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.name]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "onChange", "disabled"]),
                    _createElementVNode("div", {
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(onClickConfirmGroup) && _unref(onClickConfirmGroup)(...args))),
                      class: "btn3 ml-2"
                    }, _toDisplayString(_unref(isGroupConfirmed) ? "取消组合" : "组合选中值"), 1),
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '1' }]),
                        style: _normalizeStyle([{
                          background:
                            _unref(simpleSearchModel).type == '1' ? '#072498' : '',
                        }, {"border-radius":"5px 0 0 5px"}]),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(changeSimpleDateType)('1')))
                      }, _toDisplayString(_unref(langObj)["近一天"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '2' }]),
                        style: _normalizeStyle({
                          background:
                            _unref(simpleSearchModel).type == '2' ? '#072498' : '',
                        }),
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(changeSimpleDateType)('2')))
                      }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '3' }]),
                        style: _normalizeStyle({
                          background:
                            _unref(simpleSearchModel).type == '3' ? '#072498' : '',
                        }),
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(changeSimpleDateType)('3')))
                      }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '0' }]),
                        style: _normalizeStyle([{
                          background:
                            _unref(simpleSearchModel).type == '0' ? '#072498' : '',
                        }, {"border-radius":"0 5px 5px 0"}]),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(simpleSearchModel).type = '0'))
                      }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                    ]),
                    (_unref(simpleSearchModel).type == '0')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(simpleSearchModel).date,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(simpleSearchModel).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_unref(quotaSearch) && _unref(quotaSearch)(...args))),
                      class: "btn3 ml-2"
                    }, _toDisplayString(_unref(langObj)["查询"]), 1),
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_a_badge, {
                      color: "lightGreen",
                      text: "健康"
                    }),
                    _createVNode(_component_a_badge, {
                      color: "yellow",
                      text: "可用",
                      class: "ml-2"
                    }),
                    _createVNode(_component_a_badge, {
                      color: "orange",
                      text: "故障",
                      class: "ml-2"
                    }),
                    _createVNode(_component_a_badge, {
                      color: "red",
                      text: "警戒",
                      class: "ml-2"
                    })
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("div", { class: "w-full flex items-center" }, [
                    _createElementVNode("div", {
                      id: "chart1",
                      style: {"width":"100%","height":"322px"}
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createElementVNode("div", null, [
                    _createVNode(_component_a_form, {
                      name: "basic",
                      autocomplete: "off"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_row, { gutter: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 3 }),
                            _createVNode(_component_a_col, { span: 3 }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createElementVNode("span", { style: {"color":"#fff"} }, "添加数据点", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: 'X轴',
                                  name: "pointX"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _unref(pointToAdd).xValue,
                                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(pointToAdd).xValue) = $event)),
                                      type: "number",
                                      min: "0"
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: 'Y轴',
                                  name: "pointY"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _unref(pointToAdd).yValue,
                                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(pointToAdd).yValue) = $event)),
                                      type: "number",
                                      min: "0"
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: '健康状态',
                                  name: "healthState"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      style: {"width":"90px"},
                                      value: _unref(pointToAdd).healthState,
                                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(pointToAdd).healthState) = $event)),
                                      placeholder: _unref(langObj)['请选择']
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select_option, { value: 0 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(langObj)["健康"]), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: 1 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(langObj)["可用"]), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: 2 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(langObj)["警戒"]), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: 3 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(langObj)["故障"]), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  onClick: _unref(onClickAddPoint),
                                  class: "btn3 ml-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["确定添加"]), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, {
                          gutter: 24,
                          class: "mb-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 3 }),
                            _createVNode(_component_a_col, { span: 3 }, {
                              default: _withCtx(() => _cache[23] || (_cache[23] = [
                                _createElementVNode("span", { style: {"color":"#fff"} }, "修改数据点", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio_group, {
                                  name: "radioGroup",
                                  value: _unref(pointStateToUpdate),
                                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (_isRef(pointStateToUpdate) ? (pointStateToUpdate).value = $event : pointStateToUpdate = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio, { value: 0 }, {
                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                        _createTextVNode("健康")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: 1 }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createTextVNode("可用")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: 2 }, {
                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                        _createTextVNode("警戒")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: 3 }, {
                                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                                        _createTextVNode("故障")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: -1 }, {
                                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                                        _createTextVNode("无效")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  class: "btn3",
                                  onClick: _unref(onClickUpdatePointState)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["确定修改"]), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, {
                          gutter: 24,
                          class: "mb-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 6 }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  class: "btn3",
                                  loading: _unref(isTraining),
                                  onClick: onClickTrainModel
                                }, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode(" 训练模型 ")
                                  ])),
                                  _: 1
                                }, 8, ["loading"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  loading: _unref(isApplyModelLoading),
                                  class: "btn3",
                                  onClick: onClickApplyModel
                                }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode("应用模型 ")
                                  ])),
                                  _: 1
                                }, 8, ["loading"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  class: "btn3",
                                  loading: _unref(isSaveModelLoading),
                                  onClick: _unref(onClickSaveModel)
                                }, {
                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                    _createTextVNode(" 保存为模板 ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "onClick"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 4 })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    false
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[32] || (_cache[32] = [
                          _createElementVNode("div", { class: "flex-1" }, null, -1),
                          _createElementVNode("div", { class: "flex-1" }, null, -1)
                        ])))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ])
              ], 512), [
                [_vShow, _unref(menuSelect)==1]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_19, [
                (_openBlock(), _createBlock(GroupAlarmItem, {
                  sensor: _unref(sensorInfo),
                  key: _unref(sensorInfo).id,
                  "machine-id": _unref(sensorInfo).machineId,
                  onSearch: ()=>{}
                }, null, 8, ["sensor", "machine-id"]))
              ], 512), [
                [_vShow, _unref(menuSelect)==3]
              ])
            ])
          ], 512), [
            [_vShow, _unref(menuSelect) == 1||_unref(menuSelect)==3]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_20, [
            _createVNode(GroupModel, { "data-types": _unref(dataTypes) }, null, 8, ["data-types"])
          ], 512), [
            [_vShow, _unref(menuSelect) == 2]
          ])
        ])
      ])
    ])
  ]))
}
}

})