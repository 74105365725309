<template>
  <div
      class="w-full theme-common whiteBg"
      style="
      padding: 16px 16px;
      height: calc(100vh - 100px);
      background-color: rgb(0, 10, 50);
      overflow: hidden;
      padding-bottom: 30px;
      background-color: #000a32;
    "
  >
    <div class="w-full h-full flex">
      <!-- 右侧内容 -->
      <div class="flex-1 flex ml-4" style="color: #fff">
        <!-- 设备内容 -->
        <div class="flex-1">
          <div class="w-full flex items-center">
            <div class="flex-1"></div>
            <div class="btn1" style="border-right: none" @click="redirectToSimpleModel">
              {{ langObj["单值"] }}单值
            </div>
            <div class="btn1 btn1-select">{{ langObj["组合值"] }}组合值</div>
            <div class="flex-1"></div>
          </div>

          <div
              class="w-full flex px-4 mt-2 whiteBg"
              style="border: 1px solid #4a5983; background: #07249933"
          >
            <div
                style="font-size: 18px"
                class="py-3 cursor-pointer"
                @click="selectMenu(1)"
                :style="{
                borderBottom: menuSelect === 1 ? '2px solid #00FFF4' : '',
              }"
            >
              {{ langObj["配置模型"] || "配置模型" }}
            </div>

            <div style="font-size: 18px"
                 class="py-3 ml-12 cursor-pointer"
                 @click="selectMenu(2)"
                 :style="{ borderBottom: menuSelect === 2 ? '2px solid #00FFF4' : '' }">
              {{ langObj["模型管理"] || "模型管理" }}
            </div>

            <div
                style="font-size: 18px"
                class="py-3 ml-12 cursor-pointer"
                @click="selectMenu(3)"
                :style="{ borderBottom: menuSelect === 3 ? '2px solid #00FFF4' : '' }">
              {{ langObj["组合值报警规则"] || "组合报警规则" }}
            </div>
          </div>
          <div v-show="menuSelect == 1||menuSelect==3">
            <div class="w-full flex mt-2">
              <!-- 左侧设备列表 -->
              <div style="min-width: 250px">
                <div style="width: 250px;height:calc(100vh - 240px)" :style="{ top: scrollTop }">
                  <machineListLeftComponent
                      @selectSensor="selectSensor"
                      :type="2"
                  >
                  </machineListLeftComponent>
                </div>
              </div>

              <div v-show="menuSelect==1" class="flex-1 whiteBg">
                <div class="flex-1 content ml-2">
                  <div class="w-full flex items-center">
                    <div class="flex-1"></div>
                    <a-checkbox-group
                        name="radioGroup"
                        v-model:value="cbxSimpleDataType"
                        @change="onSelectDataType"
                        :disabled="isGroupConfirmed"
                    >
                      <a-checkbox
                          :value="item.code"
                          :key="index"
                          v-for="(item, index) in compareDataType"
                      >{{ langObj[item.name] }}
                      </a-checkbox>
                    </a-checkbox-group>

                    <div @click="onClickConfirmGroup" class="btn3 ml-2">
                      {{ isGroupConfirmed ? "取消组合" : "组合选中值" }}
                    </div>
                    <div class="flex-1"></div>
                  </div>
                  <div class="flex items-center mt-4">
                    <div class="flex-1"></div>
                    <div class="flex ml-2">
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '1' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '1' ? '#072498' : '',
                        }"
                          style="border-radius: 5px 0 0 5px"
                          @click="changeSimpleDateType('1')"
                      >
                        {{ langObj["近一天"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '2' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '2' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('2')"
                      >
                        {{ langObj["近一周"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '3' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '3' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('3')"
                      >
                        {{ langObj["近一月"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :style="{
                          background:
                            simpleSearchModel.type == '0' ? '#072498' : '',
                        }"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '0' }"
                          @click="simpleSearchModel.type = '0'"
                          style="border-radius: 0 5px 5px 0"
                      >
                        {{ langObj["自定义"] }}
                      </div>
                    </div>

                    <div class="ml-2" v-if="simpleSearchModel.type == '0'">
                      <a-range-picker
                          v-model:value="simpleSearchModel.date"
                          :disabled-date="disabledDate"
                          @calendarChange="onCalendarChange"
                      />
                    </div>

                    <!--
                    <a-button
                        type="primary"
                        @click="simpleSubmit"
                        :disabled="isGroupConfirmed"
                    >{{ langObj["查询"] }}查询
                    </a-button
                    >
                    -->

                    <div @click="quotaSearch" class="btn3 ml-2">
                      {{ langObj["查询"] }}
                    </div>

                    <div class="flex-1"></div>
                  </div>
                  <div
                      class="w-full flex items-center mt-2"
                      style="justify-content: center"
                  >
                    <a-badge color="lightGreen" text="健康"/>
                    <a-badge color="yellow" text="可用" class="ml-2"/>
                    <a-badge color="orange" text="故障" class="ml-2"/>
                    <a-badge color="red" text="警戒" class="ml-2"/>
                  </div>
                  <div class="w-full flex items-center">
                    <div id="chart1" style="width: 100%; height: 322px"></div>
                  </div>
                </div>

                <div class="flex-1 flex mt-4 content ml-2">
                  <div class="flex-1"></div>
                  <div>
                    <a-form name="basic" autocomplete="off">
                      <a-row :gutter="24">
                        <a-col :span="3"></a-col>
                        <a-col :span="3">
                          <span style="color: #fff">添加数据点</span>
                        </a-col>
                        <a-col :span="4">
                          <a-form-item :label="'X轴'" name="pointX">
                            <a-input
                                v-model:value="pointToAdd.xValue"
                                type="number"
                                min="0"/>
                          </a-form-item>
                        </a-col>
                        <a-col :span="4">
                          <a-form-item :label="'Y轴'" name="pointY">
                            <a-input
                                v-model:value="pointToAdd.yValue"
                                type="number"
                                min="0"/>
                          </a-form-item>
                        </a-col>
                        <a-col :span="4">
                          <a-form-item :label="'健康状态'" name="healthState">
                            <a-select
                                style="width: 90px"
                                v-model:value="pointToAdd.healthState"
                                :placeholder="langObj['请选择']">
                              <!--
                              <a-select-option :value="-1">
                                请选择
                              </a-select-option>
                                -->
                              <a-select-option :value="0">{{ langObj["健康"] }}</a-select-option>
                              <a-select-option :value="1">{{ langObj["可用"] }}</a-select-option>
                              <a-select-option :value="2">{{ langObj["警戒"] }}</a-select-option>
                              <a-select-option :value="3">{{ langObj["故障"] }}</a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>
                        <a-col :span="4">
                          <a-button type="primary" @click="onClickAddPoint" class="btn3 ml-2">
                            {{ langObj["确定添加"] }}
                          </a-button>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" class="mb-4">
                        <a-col :span="3"></a-col>
                        <a-col :span="3">
                          <span style="color: #fff">修改数据点</span></a-col
                        >
                        <a-col :span="12">
                          <a-radio-group
                              name="radioGroup"
                              v-model:value="pointStateToUpdate">
                            <a-radio :value="0">健康</a-radio>
                            <a-radio :value="1">可用</a-radio>
                            <a-radio :value="2">警戒</a-radio>
                            <a-radio :value="3">故障</a-radio>
                            <a-radio :value="-1">无效</a-radio>
                          </a-radio-group>
                        </a-col>

                        <a-col :span="4">
                          <a-button type="primary" class="btn3" @click="onClickUpdatePointState"
                          >{{ langObj["确定修改"] }}
                          </a-button
                          >
                        </a-col
                        >
                      </a-row>
                      <a-row :gutter="24" class="mb-4">
                        <a-col :span="6"></a-col>
                        <a-col :span="4">
                          <a-button type="primary" class="btn3" :loading="isTraining" @click="onClickTrainModel">
                            训练模型
                          </a-button>
                        </a-col>
                        <a-col :span="4">
                          <a-button type="primary" :loading="isApplyModelLoading" class="btn3"
                                    @click="onClickApplyModel">应用模型
                          </a-button>
                        </a-col>
                        <a-col :span="4">
                          <a-button type="primary" class="btn3" :loading="isSaveModelLoading" @click="onClickSaveModel">
                            保存为模板
                          </a-button>
                        </a-col>

                        <a-col :span="4"></a-col>
                      </a-row>
                    </a-form>

                    <div class="flex" v-if="false">
                      <div class="flex-1"></div>

                      <div class="flex-1"></div>
                    </div>
                  </div>
                  <div class="flex-1"></div>
                </div>
              </div>
              <div v-show="menuSelect==3" class="flex-1 ms-2 pb-2">
                <group-alarm-item :sensor="sensorInfo" :key="sensorInfo.id" :machine-id="sensorInfo.machineId"
                                  @search="()=>{}"></group-alarm-item>
              </div>
            </div>
            <!-- 雷达图 -->
          </div>

          <div v-show="menuSelect == 2" class="mt-4">
            <group-model :data-types="dataTypes"></group-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  ref
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate2
} from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";
import GroupModel from "@/components/model/group-model.vue";
import AlarmItem from "@/components/system/common/AlarmItem.vue";
import GroupAlarmItem from "@/components/system/common/GroupAlarmItem.vue";

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();


const redirectToSimpleModel = () => {
  router.push("simple-model");
}

let sensorInfo: any = ref({});

let isGroupConfirmed = ref(false);
let onClickConfirmGroup = () => {
  if (isGroupConfirmed.value) {
    isGroupConfirmed.value = false;
    return;
  }

  if (cbxSimpleDataType.value.length == 2) {
    isGroupConfirmed.value = true;
    reInit();
  } else {
    message.warning("请选择两个数据类型!");
  }
};

let reInit = async () => {
};

const getPointColor = (healthState: number) => {
  if (healthState == 0) {
    return "lightgreen";
  } else if (healthState == 1) {
    return "yellow";
  } else if (healthState == 2) {
    return "orange";
  } else if (healthState == 3) {
    return "red";
  } else {
    return "white";
  }
};

let customPoints: { key: string, data: number[], grade: number }[] = [];
const clearCustomPoints = () => {
  customPoints.length = 0;
}

let pointToAdd = ref({
  xValue: null,
  yValue: null,
  healthState: null,
});
let onClickAddPoint = () => {
  if (!pointToAdd.value.xValue) {
    message.warn("请输入X轴值")
    return;
  }
  if (!pointToAdd.value.yValue) {
    message.warn("请输入Y轴值")
    return;
  }
  if (pointToAdd.value.healthState == null) {
    message.warn("请选择健康状态")
    return;
  }
  const x = Number(pointToAdd.value.xValue);
  const y = Number(pointToAdd.value.yValue);
  const idx = customPoints.findIndex(val => val.key == `${x}-${y}`);
  if (idx != -1 && customPoints[idx].grade != pointToAdd.value.healthState) {
    //数据点已存在
    customPoints[idx].grade = Number(pointToAdd.value.healthState);
    chartDataArr.push(
        {
          value: [x, y],
          itemStyle: {
            color: getPointColor(pointToAdd.value.healthState)
          }
        }
    );
  } else {
    customPoints.push({ key: `${x}-${y}`, data: [x, y], grade: pointStateToUpdate.value });
    chartDataArr.push(
        {
          value: [x, y],
          itemStyle: {
            color: getPointColor(pointToAdd.value.healthState)
          }
        }
    );
  }
  updateChart();
};

let pointStateToUpdate = ref(-1);
let onClickUpdatePointState = () => {
  if (selectedChartDataIndexArr.length == 0) {
    message.warn("请选择要修改的数据点")
    return;
  }
  const heathState = pointStateToUpdate.value;
  selectedChartDataIndexArr.forEach(val => {
    let item = chartDataArr[val];
    const x = item.value[0];
    const y = item.value[1];

    item.itemStyle.color = getPointColor(pointStateToUpdate.value);
    const idx = customPoints.findIndex(val => val.key == `${x}-${y}`);
    if (idx != -1 && customPoints[idx].grade != heathState) {
      //数据点已存在
      customPoints[idx].grade = heathState;
    } else {
      customPoints.push({ key: `${x}-${y}`, data: [x, y], grade: heathState });
    }
  })
  updateChart();
}

let compareDataType: any = ref([]);

let cbxSimpleDataType: any = ref([]);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

const getQuotaGroupAlarmConfig = async () => {
  if (!sensorInfo.value) return;
  let result = await request.get("/api/sensors/quota-group-alarm?sensorId=" + sensorInfo.value.id);
  if (result?.data) {
    sensorInfo.value.table = result.data;
  } else {
    sensorInfo.value.table = [];
  }
};

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadDataTypes();
    if (result.data.dataType) {
      compareDataType.value = dataTypes.value.filter((p: any) =>
          result.data.dataType.includes(p.name)
      );
    } else {
      compareDataType.value = [];
    }

    await getQuotaGroupAlarmConfig();
    myChart.clear();
  }
};

onMounted(() => {
  loadDataTypes();
  (echarts as any).dispose(document.getElementById("chart1"));
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

let myChart: any = null;
let selectedChartDataIndexArr: number[] = []; // 已选择的数据点
let tempBrushedChargeDataIndexArr: number[] = []; // 缓存框选的数据点
// 雷达图绘制
const updateChart = () => {
  selectedChartDataIndexArr.length = 0;
  myChart.on('selectchanged', function (params: any) {
    if (params.selected && params.selected.length > 0) {
      //console.log("selectchanged", params.selected[0].dataIndex)
      selectedChartDataIndexArr = params.selected[0].dataIndex;
    } else {
      selectedChartDataIndexArr.length = 0;
    }
    //console.log('chart select changed', params);
  });

  myChart.on('brushSelected', function (params: any) {
    let brushedIndexes: number[] = [];
    let brushed = [];
    let brushComponent = params.batch[0];
    //console.log("brushSelected", params)
    for (let sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      let rawIndices = brushComponent.selected[sIdx].dataIndex;
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
      brushedIndexes.push(...rawIndices);
    }
    if (brushedIndexes.length > 0) {
      // 框选
      //将框选的数据点添加到待修改健康状态数组中
      selectedChartDataIndexArr.push(...brushedIndexes);
      selectedChartDataIndexArr = [...new Set(selectedChartDataIndexArr)]; // 去重

      tempBrushedChargeDataIndexArr = [...brushedIndexes];
      myChart.dispatchAction({
        type: 'select',
        seriesIndex: 0,
        dataIndex: selectedChartDataIndexArr
      });
    } else {
      // 取消框选
      myChart.dispatchAction({
        type: 'unselect',
        seriesIndex: 0,
        dataIndex: tempBrushedChargeDataIndexArr
      });
      for (let i = 0; i < tempBrushedChargeDataIndexArr.length; i++) {
        // 从待修改健康状态的数组中移除取消框选的元素
        const item = tempBrushedChargeDataIndexArr[i];
        selectedChartDataIndexArr.splice(selectedChartDataIndexArr.indexOf(item), 1);
      }
      tempBrushedChargeDataIndexArr.length = 0;
    }
  });

  myChart.clear();

  let selectedDataTypes = cbxSimpleDataType.value.sort().map((val: number) => {
    return dataTypes.value.find((type: any) => val === type.code);
  });
  let xName = selectedDataTypes[0].name;
  if (selectedDataTypes[0].unit) {
    xName += `(${selectedDataTypes[0].unit})`;
  }
  let yName = selectedDataTypes[1].name;
  if (selectedDataTypes[1].unit) {
    yName += `(${selectedDataTypes[1].unit})`;
  }

  const option = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any) {
        let result = "";
        if (params.data && params.data.value && params.data.value.length == 2) {
          result = `${params.data.value[0]}, ${params.data.value[1]}`
        }
        return result;
      }
    },
    toolbox: {
      right: 200,
      iconStyle: {
        color: "white",
        borderColor: "white",
        borderWidth: 2
      }
    },
    dataZoom: {
      type: 'inside'
    },
    brush: {
      toolbox: ['rect', 'clear'],
      xAxisIndex: 0,
      throttleType: 'debounce',
      throttleDelay: 500,
      outOfBrush: {
        colorAlpha: 0.6
      }
    },
    xAxis: [{
      name: xName,
      nameLocation: "center",
      nameTextStyle: {
        padding: [5, 0, 0, 0]
      },
      axisLine: { show: false },
      splitLine: { show: false }
    }],
    yAxis: {
      name: yName
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 7,
        data: chartDataArr,
        selectedMode: "multiple",
        select: {
          itemStyle: {
            borderWidth: 1.5,
            borderColor: "cyan",
            borderType: "solid"
          }
        }
      }
    ],
  };

  option && myChart.setOption(option);
};

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

function getQueryDate(): { begin: string, end: string } {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = date;
  }
  let begin = (dateForm.begin = transformDate2(beginDate, 1)) || "";
  let end = (dateForm.end = transformDate2(endDate, 2)) || "";
  return { begin: begin, end: end };
}

let chartDataArr: any[] = [];
let quotaSearch = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return
  }

  let dateQuery = getQueryDate()

  let selectedDataCodes = cbxSimpleDataType.value.sort();
  let result = await request.post("/api/sensors-hardware/data", {
    begin: dateQuery.begin,
    end: dateQuery.end,
    sensorId: sensorInfo.value.id,
    codes: selectedDataCodes,
  });

  chartDataArr.length = 0;
  let xDateValueMap: Map<number, number> = new Map();
  let yDateValueMap: Map<number, number> = new Map();
  let oneMinuteInMilliseconds = 60000;
  if (result.data && result.data.list) {
    for (let i = 0; i < result.data.list.length; i++) {
      let item = result.data.list[i];
      // 时间戳精确到分钟
      let dateKey = Math.round(item.date / oneMinuteInMilliseconds) * oneMinuteInMilliseconds;
      if (item.code === selectedDataCodes[0]) {
        if (!xDateValueMap.has(dateKey)) {
          xDateValueMap.set(dateKey, item.value);
        }
      } else if (item.code === selectedDataCodes[1]) {
        if (!yDateValueMap.has(dateKey)) {
          yDateValueMap.set(dateKey, item.value);
        }
      }
    }

    Array.from(xDateValueMap.keys()).forEach((key: number) => {
      if (yDateValueMap.has(key)) {
        chartDataArr.push(({
          value: [xDateValueMap.get(key), yDateValueMap.get(key)],
          itemStyle: {
            color: "white",
          }
        }));
      }
    });

  }

  updateChart();
  clearCustomPoints();
};

let dateForm: any = {
  begin: "",
  end: "",
};

let isTraining = ref(false);
const onClickTrainModel = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return;
  }

  let dateQuery = getQueryDate();
  let selectedDataCodes: number[] = cbxSimpleDataType.value.sort();

  isTraining.value = true;

  let postBody = {
    saveModel: false,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as (any[])
      }
    ],
    dataTypes: selectedDataCodes.map(val => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }

  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
  } catch (err) {
    throw err;
  } finally {
    isTraining.value = false;
  }

  chartDataArr.length = 0;
  if (result.data) {
    message.success("训练模型成功!")
    let length = result.data.code_1.length > result.data.code_2.length ? result.data.code_2.length : result.data.code_1.length;
    for (let i = 0; i < length; i++) {
      chartDataArr.push({
        value: [result.data.code_1[i], result.data.code_2[i]],
        itemStyle: {
          color: getPointColor(result.data.label[i]),
        }
      });
    }
    updateChart();
    //clearCustomPoints();
  }


};

let isApplyModelLoading = ref(false);
const onClickApplyModel = async () => {
  if (!isGroupConfirmed.value) {
    message.warn("请先组合选中的数据类型");
    return;
  }

  let dateQuery = getQueryDate();
  let selectedDataCodes = cbxSimpleDataType.value.sort() as number[];

  isApplyModelLoading.value = true;

  let postBody = {
    saveModel: true,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as any[]
      }
    ],
    dataTypes: selectedDataCodes.map((val) => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }

  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
    if (!result.data) return;
  } catch (err) {
    isApplyModelLoading.value = false;
    throw err;
  }

  let modelFilePath: string = result.data;
  let body = {
    dataTypes: cbxSimpleDataType.value.sort(),
    machineId: sensorInfo.value.machineId,
    machineType: sensorInfo.value.machineTypeId,
    sensorIds: [sensorInfo.value.id],
    modelFile: modelFilePath,
    begin: dateQuery.begin,
    end: dateQuery.end,
    values: customPoints.map(val => {
      return {
        data: [val.data[0], val.data[1]],
        status: val.grade
      }
    }),
  };

  try {
    result = await request.put("/api/quota/machine-group-model", body);
    if (result) {
      message.success("应用模型成功!");
    }
  } catch (err) {
    isApplyModelLoading.value = false;
    throw err;
  }

  // 保存组合值报警规则, 假如不否存在该组合的报警规则
  if (!sensorInfo.table || sensorInfo.table.findIndex((t: any) => t.flag === selectedDataCodes.join(".")) === -1) {
    let configBody = {
      sensorId: sensorInfo.value.id,
      list: [{ dataTypes: selectedDataCodes }]
    }
    try {
      result = await request.put("/api/sensors/quota-group-alarm", configBody)
      if (result) {
        message.success("保存组合值报警规则成功!")
        await getQuotaGroupAlarmConfig();
      }
    } catch (err) {
    }
  }
  isApplyModelLoading.value = false;
};

let isSaveModelLoading = ref(false);
let onClickSaveModel = async () => {
  let dateQuery = getQueryDate();

  isSaveModelLoading.value = true;

  let selectedDataCodes = cbxSimpleDataType.value.sort();

  let postBody = {
    saveModel: true,
    values: [
      {
        begin: dateQuery.begin,
        end: dateQuery.end,
        tags: [] as any[]
      }
    ],
    dataTypes: selectedDataCodes.map((val: number) => {
      return { sensor: sensorInfo.value.id, code: [val] }
    }),
  };
  if (customPoints && customPoints.length > 0) {
    postBody.values[0].tags = customPoints;
  }
  let result;
  try {
    result = await request.post("/model/alarm/norm/model", postBody);
    if (!result.data) return;
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }

  let modelFilePath: string = result.data;

  let body = {
    dataTypes: selectedDataCodes,
    machineType: sensorInfo.value.machineTypeId,
    modelFile: modelFilePath,
    sensorIds: [sensorInfo.value.id],
    begin: dateQuery.begin,
    end: dateQuery.end,
    values: customPoints.map(val => {
      return {
        data: [val.data[0], val.data[1]],
        status: val.grade
      }
    }),
  };
  try {
    let result = await request.post("/api/quota/group-model", body);
    if (result) {
      message.success("保存模板成功!");
    }
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }
};

let onSelectDataType = async () => {
  if (cbxSimpleDataType.value.length >= 3) {
    cbxSimpleDataType.value.splice(0, 1);
  }

  isGroupConfirmed.value = false;
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

var selectMenu = (type: any) => {
  menuSelect.value = type;
};

let dataTypes: any = ref([]);
let isLoadingDataTypes: boolean = false;
let loadDataTypes = async () => {
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  while (isLoadingDataTypes) {
    await sleep(10);
  }
  if (dataTypes.value.length > 0) {
    return;
  }
  let result = await request.post("/api/data-type", { tags: [10] });
  isLoadingDataTypes = false;
  if (result.data) {
    dataTypes.value = result.data;
  }
};

</script>

<style lang="less" scoped>
.sjz {
  margin-left: 4rem;
  margin-right: 4rem;
  width: calc(100% - 8rem);
  height: 20px;
  background: #999;
  padding: 0;
}

/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-switch {
  background-color: #07259a;
}

/deep/ .ant-switch-checked {
  background-color: #07259a !important;
}

/deep/ .ant-picker {
  background-color: #021768;
  border: 1px solid #0d53b7;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.5;
}

/deep/ .ant-checkbox-disabled + span {
  color: #fff;
}

/deep/ .ant-badge + .ml-2 {
  margin-left: 0.5rem;
}

/deep/ .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

/deep/ .ant-badge-status-text {
  color: #fff;
}

:deep(::-webkit-scrollbar) {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
  width: 100%;
  height: calc(100vh - 310px);
}

.machine-select {
  border-radius: 2px;
  background: #072499;
}

.content {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.btn1 {
  border: 1px solid #0d53b7;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn1-select {
  background: #072499;
  padding: 5px 10px;
  cursor: pointer;
}

.btn2 {
  border: 1px solid #0d53b7;
  cursor: pointer;
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size20 {
  font-size: 20px;
}

.size18 {
  font-size: 18px;
}

.size12 {
  font-size: 12px;
}

.imgShow {
  max-width: 15px;
  width: 15px;
  height: 15px;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #0d53b7cc !important;
}

/deep/ .ant-table-thead {
  border: #072499 !important
}

/deep/ .ant-table-tbody > tr > td {
  border-right: 0 solid #fff !important;
}

/deep/ .ant-table-thead .ant-table-cell {
  background: #03155C !important;
  border-right: 0 !important;
}

/deep/ table {
  border-top: 0 solid #0d53b7cc !important
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

:deep(::-webkit-scrollbar) {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0 solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}

</style>
