import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cardHead" }
const _hoisted_2 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_3 = {
  class: "main whiteBg",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_index_outlined = _resolveComponent("node-index-outlined")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, {
      class: "whiteBg",
      bordered: false
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_node_index_outlined),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dataSource.sensorPlace), 1)
        ])
      ]),
      extra: _withCtx(() => [
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.copy
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getLanguage('复制')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.pasteOverlay
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getLanguage('粘贴')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.editSave
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getLanguage('保存')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        bordered: "",
        emptyText: '暂无数据',
        columns: _ctx.columns,
        "data-source": _ctx.dataSource.table,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.dataIndex === 'dataType')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.getLanguage(text)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'enable')
            ? (_openBlock(), _createBlock(_component_a_switch, {
                key: 1,
                checked: record.enable,
                "onUpdate:checked": ($event: any) => ((record.enable) = $event),
                "checked-children": _ctx.getLanguage('开'),
                "un-checked-children": _ctx.getLanguage('关')
              }, null, 8, ["checked", "onUpdate:checked", "checked-children", "un-checked-children"]))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level1Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level1RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level1RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔内累计')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level1LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level1LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔大于阈值')) + ": ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level1Threshold,
                  "onUpdate:value": ($event: any) => ((record.level1Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('触发报警')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('天')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('次')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level2Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level2RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level2RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔内累计')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level2LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level2LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔大于阈值')) + ": ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level2Threshold,
                  "onUpdate:value": ($event: any) => ((record.level2Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('触发报警')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('天')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('次')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level3Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level3RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level3RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔内累计')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level3LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level3LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('笔大于阈值')) + ": ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level3Threshold,
                  "onUpdate:value": ($event: any) => ((record.level3Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('触发报警')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('天')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('次')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level4Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createTextVNode(_toDisplayString(_ctx.getLanguage('阈值')) + ": ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level4Threshold,
                  "onUpdate:value": ($event: any) => ((record.level4Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[0] || (_cache[0] = _createTextVNode(" , ")),
                _createVNode(_component_a_input_number, {
                  value: record.level4IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level4IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('天')) + " ", 1),
                _createVNode(_component_a_input_number, {
                  value: record.level4IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level4IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.getLanguage('次')), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data-source"])
    ])
  ]))
}