import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = ["onClick"]

import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { TableProps } from "ant-design-vue";
import { message } from "ant-design-vue";
import { getGroupListByFacID, getFactoryName, getGroupName } from "../../common/tools";


export default /*@__PURE__*/_defineComponent({
  __name: 'machine-bind',
  setup(__props) {

let searchModel: any = ref({
  factoryId: '',
  machineId: '',
  groupId: '',
  skip: 1,
  take: 20
})
let total: any = ref(0)
let pagination = computed(() => ({
  total: total.value,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let dataList: any = ref([])
const columns = [
  {
    title: '项目',
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: '区域',
    dataIndex: "groupId",
    align: "center"
  },
  {
    title: '设备',
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: '诊断经理',
    dataIndex: "diagnosticManager",
    align: "center"
  },
  {
    title: '客户经理',
    dataIndex: "accountManager",
    align: "center"
  },
  {
    title: '诊断主管',
    dataIndex: "diagnosticSupervisor",
    align: "center"
  },
  {
    title: '客户主管',
    dataIndex: "accountSupervisor",
    align: "center"
  },
  {
    title: '操作',
    dataIndex: "act",
    align: "center"
  }
];

let factoryList: any = ref([])
let groupList: any = ref([])
let machineList: any = ref([])
let visible: any = ref(false)
let form: any = ref({
  machineId: '',
  factory: '',
  diagnosticManager: '',
  accountManager: '',
  accountSupervisor: '',
  diagnosticSupervisor: ''
})
let diagnosticManagerList: any = ref([])
let accountManagerList: any = ref([])

onMounted(() => {
  init()
})

const init = () => {
  getManagers()
  getFactoryList()
  search()
}
const filterOption = (input: string, option: any) => {

  return option.factoryName.indexOf(input) >= 0;
};
const filterOption2 = (input: string, option: any) => {

  return option.name.indexOf(input) >= 0;
};
const filterOption3 = (input: string, option: any) => {

  return option.machineName.indexOf(input) >= 0;
};
// 获取诊断/客户经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id
  let accountManagerRoleId = rolelist.find((p: any) => p.name === '客户支持')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }

  if (accountManagerRoleId) {
    let config2 = {
      params: {
        roleId: accountManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      accountManagerList.value = result2.data.list
    }
  }
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result?.data) {
    factoryList.value = result.data
  }
}

// 选择工厂
const selectFactory = (ev: any) => {
  if (ev) {
    groupList.value = getGroupListByFacID(ev)
  } else {
    groupList.value = []
    machineList.value = []
    searchModel.value.groupId = ''
    searchModel.value.machineId = ''
  }
}

// 选择区域
const selectGroup = async (ev: any) => {
  const config = {
    params: {
      factoryId: searchModel.value.factoryId,
      groupId: searchModel.value.groupId,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines", config)
  if (result?.data) {
    machineList.value = result.data
  }
}


const search = async () => {
  const config = {
    params: {
      factoryId: searchModel.value.factoryId,
      groupId: searchModel.value.groupId,
      machineId: searchModel.value.machineId,
      skip: searchModel.value.skip,
      take: searchModel.value.take
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/principal", config)

  if (result?.data?.list) {
    dataList.value = result.data.list
    total.value = result.data.total
  }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  search();
};

const edit = (ev: any) => {
  form.value = JSON.parse(JSON.stringify(ev))
  visible.value = true
}

const submit = async () => {

  let result = form.value.id ? await request.put('/api/principal', form.value) : await request.post('/api/principal', form.value)
  if (result?.status == 200) {
    message.success('操作成功')
    visible.value = false
    search()
  } else {
    message.warning('操作失败')
  }
}


const getManager = (ev: any, type: any) => {
  if (type == 1) {
    return diagnosticManagerList.value.find((p: any) => p._id === ev)?.nickName
  }
  if (type == 2) {
    return accountManagerList.value.find((p: any) => p._id === ev)?.nickName
  }
}


return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "inline" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "工厂" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  onChange: selectFactory,
                  allowClear: "",
                  showSearch: true,
                  value: _unref(searchModel).factoryId,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).factoryId) = $event)),
                  style: {"width":"250px"},
                  optionFilterProp: "label"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id,
                        label: item.factoryName
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.factoryName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "区域" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  onChange: selectGroup,
                  allowClear: "",
                  options: _unref(groupList),
                  "field-names": { label: 'name', value: 'id', options: 'groupList' },
                  "filter-option": filterOption2,
                  "show-search": "",
                  value: _unref(searchModel).groupId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                  style: {"width":"250px"}
                }, null, 8, ["options", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "设备" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  value: _unref(searchModel).machineId,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                  options: _unref(machineList),
                  "show-search": "",
                  "field-names": { label: 'machineName', value: 'id', options: 'machineList' },
                  "filter-option": filterOption3,
                  style: {"width":"250px"}
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: search
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("筛选")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_table, {
            bordered: "",
            emptyText: "暂无数据",
            columns: columns,
            "data-source": _unref(dataList),
            pagination: _unref(pagination),
            onChange: handleTableChange,
            size: "small"
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'factoryId')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(getFactoryName)(text)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'groupId')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_unref(getGroupName)(text)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'diagnosticManager')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(getManager(text, 1)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'accountManager')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createTextVNode(_toDisplayString(getManager(text, 2)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'diagnosticSupervisor')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    _createTextVNode(_toDisplayString(getManager(text, 1)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'accountSupervisor')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                    _createTextVNode(_toDisplayString(getManager(record.accountSupervisor, 2)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'act')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 6,
                    onClick: ($event: any) => (edit(record))
                  }, "编辑", 8, _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "pagination"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      width: 850,
      maskClosable: false,
      visible: _unref(visible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      title: "编辑",
      onOk: submit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _unref(form),
          autocomplete: "off",
          "label-col": { style: { width: '150px' } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "设备名称",
              name: "machineName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(form).machineName,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(form).machineName) = $event)),
                  readonly: ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "诊断经理",
              name: "diagnosticManager",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  showSearch: true,
                  value: _unref(form).diagnosticManager,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(form).diagnosticManager) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(diagnosticManagerList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nickName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "客户经理",
              name: "accountManager",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  showSearch: true,
                  value: _unref(form).accountManager,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(form).accountManager) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accountManagerList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nickName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "诊断主管",
              name: "diagnosticSupervisor"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  showSearch: true,
                  value: _unref(form).diagnosticSupervisor,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).diagnosticSupervisor) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(diagnosticManagerList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nickName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "客户主管",
              name: "accountSupervisor",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  showSearch: true,
                  value: _unref(form).accountSupervisor,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).accountSupervisor) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accountManagerList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nickName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})