<template>
    <div class="w-full theme-common" style="
        padding: 16px 16px;
        height: calc(100vh - 100px);
        background-color: rgb(0, 10, 50);
        overflow-y: scroll;
        padding-bottom: 30px;
        background-color: #000a32;
      ">
        <div class="w-full h-full flex">
            <!-- 左侧设备列表 -->
            <div style="min-width: 12%; margin-top: 31px">
                <div style="width: 12%; position: fixed"
                    :style="{ top: scrollTop }">
                    <machineListLeftComponent @selectMachine="selectMachine"
                        :type="1" :mode="2">
                    </machineListLeftComponent>
                </div>
            </div>

            <!-- 中间内容 -->
            <div class="flex-1 flex ml-4" style="color: #fff">
                <!-- 设备内容 -->
                <div class="flex-1">


                    <!-- 设备健康事件时间轴 -->
                    <div class="content mt-4 theme-bg">
                        <div class="w-full flex items-center flex-wrap">
                            <div class="size20">{{ langObj["设备健康事件时间轴"] }}</div>
                            <div class="flex items-center ml-4 cursor-pointer opt8"
                                style="font-size: 14px">
                                <div @click="histrendSearchModel.isShow = false"
                                    class="flex items-center"
                                    v-if="histrendSearchModel.isShow">
                                    <div>{{ langObj["收起"] }}</div>
                                    <caret-up-outlined class="ml-1" />
                                </div>

                                <div @click="histrendSearchModel.isShow = true"
                                    class="flex items-center"
                                    v-if="!histrendSearchModel.isShow">
                                    <div>{{ langObj["展开"] }}</div>
                                    <caret-down-outlined class="ml-1" />
                                </div>
                            </div>
                            <div class="flex-1"></div>
                            <div class="flex items-center"
                                v-show="histrendSearchModel.isShow">
                                <!-- <a-switch
                    v-model:checked="fiveRefresh"
                    @change="refresh($event)"
                    :checked-children="langObj['开启']"
                    :un-checked-children="langObj['关闭']"
                  /> -->
                                <!-- <span class="ml-1">{{ langObj["自动刷新"] }}</span> -->

                                <div class="flex ml-2">
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '1' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '1' ? '#072498' : '',
                                        }" style="border-radius: 5px 0 0 5px"
                                        @click="(histrendSearchModel.type = '1'), getHistrendData()">
                                        {{ langObj["近一周"] }}
                                    </div>
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '2' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '2' ? '#072498' : '',
                                        }" @click="(histrendSearchModel.type = '2'), getHistrendData()">
                                        {{ langObj["近一月"] }}
                                    </div>
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '3' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '3' ? '#072498' : '',
                                        }" @click="(histrendSearchModel.type = '3'), getHistrendData()">
                                        {{ langObj["近两月"] }}
                                    </div>
                                    <div class="btn2 px-3 py-1" :style="{
                                        background:
                                            histrendSearchModel.type == '0' ? '#072498' : '',
                                    }" :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '0' }"
                                        @click="histrendSearchModel.type = '0'"
                                        style="border-radius: 0 5px 5px 0">
                                        {{ langObj["自定义"] }}
                                    </div>
                                </div>

                                <div class="ml-2"
                                    v-if="histrendSearchModel.type == '0'">
                                    <a-range-picker
                                        v-model:value="histrendSearchModel.date"
                                        :disabled-date="disabledDate2"
                                        @calendarChange="onCalendarChange" />
                                </div>
                            </div>
                        </div>

                        <div class="w-full mt-4"
                            v-show="histrendSearchModel.isShow">


                            <div class="progress-box flex w-full  items-center"
                                style="height: 20px;">

                                <div v-for="(item1, index1) in timeLineList"
                                    :key="index1"
                                    :style="{ background: getReportCondition(item1)?.color, width: item1.width }"
                                    style="height: 20px;">
                                </div>
                            </div>
                            <div class="time_wrap flex mt-2"
                                style="justify-content: space-between;">
                                <text>{{ inspectionBase.beginDate || "--"
                                    }}</text>
                                <text>{{ machineInfo.workStatus?.scoreDate ||
                                    "--" }}</text>
                            </div>





                        </div>
                    </div>

                    <!-- 巡检分数趋势 -->
                    <div class="content mt-4 theme-bg">
                        <div class="w-full flex items-center flex-wrap">
                            <div class="size20">{{ langObj["巡检分数趋势"] }}</div>
                            <div class="flex items-center ml-4 cursor-pointer opt8"
                                style="font-size: 14px">
                                <div @click="histrendSearchModel.isShow = false"
                                    class="flex items-center"
                                    v-if="histrendSearchModel.isShow">
                                    <div>{{ langObj["收起"] }}</div>
                                    <caret-up-outlined class="ml-1" />
                                </div>

                                <div @click="histrendSearchModel.isShow = true"
                                    class="flex items-center"
                                    v-if="!histrendSearchModel.isShow">
                                    <div>{{ langObj["展开"] }}</div>
                                    <caret-down-outlined class="ml-1" />
                                </div>
                            </div>
                            <div class="flex-1"></div>
                            <div class="flex items-center"
                                v-show="histrendSearchModel.isShow">

                                <div class="flex ml-2">
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '1' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '1' ? '#072498' : '',
                                        }" style="border-radius: 5px 0 0 5px"
                                        @click="(histrendSearchModel.type = '1'), getHistrendData()">
                                        {{ langObj["近一周"] }}
                                    </div>
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '2' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '2' ? '#072498' : '',
                                        }" @click="(histrendSearchModel.type = '2'), getHistrendData()">
                                        {{ langObj["近一月"] }}
                                    </div>
                                    <div class="btn2 px-3 py-1"
                                        :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '3' }"
                                        :style="{
                                            background:
                                                histrendSearchModel.type == '3' ? '#072498' : '',
                                        }" @click="(histrendSearchModel.type = '3'), getHistrendData()">
                                        {{ langObj["近两月"] }}
                                    </div>
                                    <!-- <div
                      class="btn2 px-3 py-1"
                      :style="{
                        background:
                          histrendSearchModel.type == '0' ? '#072498' : '',
                      }"
                      :class="{ 'text-white theme-datesetected': histrendSearchModel.type == '0' }"
                      @click="histrendSearchModel.type = '0'"
                      style="border-radius: 0 5px 5px 0"
                    >
                      {{ langObj["自定义"] }}
                    </div> -->
                                </div>

                                <!-- <div class="ml-2" v-if="histrendSearchModel.type == '0'">
                    <a-range-picker
                      v-model:value="histrendSearchModel.date"
                      :disabled-date="disabledDate2"
                      @calendarChange="onCalendarChange"
                    />
                  </div> -->

                                <a-select ref="select"
                                    v-model:value="histrendSearchModel.reportType"
                                    style="min-width: 150px"
                                    @change="getHistrendData">
                                    <a-select-option
                                        v-for="(item, index) in reportTypes"
                                        :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>

                                <a-select ref="select"
                                    v-model:value="histrendSearchModel.sensorId"
                                    style="min-width: 150px"
                                    @change="getHistrendData">
                                    <a-select-option
                                        v-for="(item, index) in sensorListAll"
                                        :key="index" :value="item.id">
                                        {{ item.sensorPlace }}
                                    </a-select-option>
                                </a-select>


                            </div>
                        </div>

                        <div class="w-full" v-show="histrendSearchModel.isShow">



                            <div id="chartCover" style="height: 340px"
                                class="w-full mt-4"></div>


                        </div>
                    </div>

                    <div class="content mt-4 theme-bg">
                        <a-form :model="searchModel" name="horizontal_login"
                            layout="inline" autocomplete="off">

                            <a-form-item :label="langObj['巡检日期']" name="date">
                                <a-range-picker v-model:value="searchModel.date"
                                    :disabled-date="disabledDate"
                                    @calendarChange="onCalendarChange" />
                            </a-form-item>
                            <a-form-item :label="langObj['巡检人']"
                                name="userNick">
                                <a-input style="width: 120px;"
                                    v-model:value="searchModel.userNick"
                                    :placeholder="langObj['请输入']" />
                            </a-form-item>


                            <a-form-item>
                                <div class="flex items-center">
                                    <div @click="reset"
                                        class="border px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                                        {{ langObj['重置'] }}
                                    </div>

                                    <div @click="getData"
                                        class="border px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                        style="background-color: #072499">
                                        {{ langObj['查询'] }}
                                    </div>



                                </div>
                            </a-form-item>
                        </a-form>

                        <div>
                            <a-table style="width: 100%" size="small"
                                :row-class-name="getRowClassName"
                                :columns="inspectionColumns"
                                :data-source="data.list"
                                :pagination="pagination"
                                @change="handleTableChange">
                                <template
                                    #bodyCell="{ column, text, record, index }">


                                    <template
                                        v-if="column.dataIndex === 'groupId'">
                                        <span>{{ getGroupName(text) }}</span>
                                    </template>

                                    <template
                                        v-if="column.dataIndex === 'type'">
                                        <span>{{ getReportType(record) }}</span>
                                    </template>
                                    <template
                                        v-if="column.dataIndex === 'sdjfg'">
                                        <span>{{ getValue2(record.wkstatus, 107,
                                            "Z") }}</span>
                                    </template>


                                    <template
                                        v-if="column.dataIndex === 'condition'">
                                        <div class="flex items-center">
                                            <div style="width: 14px; height: 14px; border-radius: 50%"
                                                :style="{ background: getReportCondition(record)?.color }">
                                            </div>
                                            <div class="ml-1"
                                                :style="{ color: getReportCondition(record)?.color }">
                                                {{
                                                    langObj[getReportCondition(record)?.label]
                                                }}
                                            </div>
                                        </div>
                                    </template>



                                    <template v-if="column.dataIndex === 'act'">
                                        <a @click="getReportInfo(record, index)"
                                            class="theme-table-link"> {{
                                                langObj['查看报告'] }}</a>



                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>


                </div>

                <!-- 报告 -->
                <div style="width: 700px; margin-top: 16px;min-height: 2000px;"
                    class="content ml-4 theme-bg">
                    <!-- <div class="size20 ">{{ langObj["巡检报告"] }}</div> -->
                    <reportInfo :reportIdObj="inspectionBase.reportIdObj">
                    </reportInfo>
                </div>
            </div>
        </div>


    </div>
</template>

<script lang="ts" setup>
import {
    onBeforeUnmount,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
    computed,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import reportInfo from "./reportInfo.vue";
import {
    transformDate,
    transformDate2,
    getGroupName,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "./machine-list-left-inspection.vue";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import pictureListComponent from "../components/picture-list.vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem("language") || "Chinese";
    langObj.value = langList[language.value];
};
getLang();

let picList: any = ref([]);

let machine_id = ref("");
let machineInfo: any = ref({});
let sensorList: any = ref([]);
let sensorListAll: any = ref([]);
let selectSensorRandar = ref("");
let orderC = ref();
let percentCur: any = ref(0),
    percentCur2: any = ref(0),
    percentCur3: any = ref(0),
    percentCur4: any = ref(0);

let conditionList = [
    { label: "健康", value: [0], color: "#61c08f" },
    { label: "可用", value: [1, 2], color: "#d2de49" },
    { label: "警戒", value: [3, 4, 5], color: "#cd5f3b" },
    { label: "故障", value: [6, 7], color: "#921e37" },
];
let conditionLists = [
    { label: "健康", value: 1, realValue: [0] },
    { label: "可用", value: 2, realValue: [1, 2] },
    { label: "警戒", value: 3, realValue: [3, 4, 5] },
    { label: "故障", value: 4, realValue: [6, 7] },
];
let conditionList2 = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];
let reportTypes = [
    { label: langObj.value["智能诊断报告"], value: 1 },
    { label: langObj.value["基础巡检报告"], value: 2 },
];

let statusList = [
    { label: "停机", value: 0, color: "#ADADAD" },
    { label: "运行", value: 1, color: "#4EFAEE" },
];

let scrollTop = ref("120px");


const selectMachine = (ev: any) => {
    orderC.value && orderC.value.closeAll();
    histrendSearchModel.value.isShow = true;
    sensorSearchModel.value.isShow = true;
    selectSensorRandar.value = "";
    histrendSearchModel.value.sensorId = "";
    machine_id.value = ev;
    selectedRowIndex.value=0
    setTimeout(() => {
        // search()
        init();
    }, 200);
};
let data = ref([]);
let searchModel: any = ref({
    skip: 1,
    take: 10,
    condition: null,
    status: null,
    date: null,
    total: 0,
    type: null,
    userNick: ""
});
const inspectionColumns = [
    {
        title: langObj.value["巡检日期"],
        dataIndex: "date",
    },

    {
        title: langObj.value["巡检人"],
        dataIndex: "userNick",
    },

    {
        title: langObj.value["速度均方根"] + '(mm/s)',
        dataIndex: "sdjfg",
    },

    {
        title: langObj.value["测点"],
        dataIndex: "position",
    },

    {
        title: langObj.value["巡检报告类型"],
        dataIndex: "type",
    },
    {
        title: langObj.value["巡检状态"],
        dataIndex: "condition",
    },
    {
        title: langObj.value["操作"],
        dataIndex: "act",
    },


];
let inspectionBase: any = ref({
    reportIdObj: {},
    beginDate: null
})

const init = () => {
   
    myChartC && myChartC.clear();
  
    histrendSearchModel.value = {
        type: 1,
        date: null,
        sensorId: "",
        isShow: true,
        reportType: 1
    };
    sensorSearchModel.value = {
        date: null,
        type: "1",
        isShow: true,
    };
    statusSearchModel.value = {
        date: null,
        type: "1",
        isShow: true,
    };

    picList.value = [];
    search()
    getMachineInfo();
};
const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
}));
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    search();

};
const search = () => {
    // searchModel.value.skip = 1;
    getData();
};
const getData = () => {
    getReportList()
};
const getReportInfo = (info: any,index:any) => {
    inspectionBase.value.reportIdObj = info;
    selectedRowIndex.value = index;
};
//获取报告列表
const getReportList = async () => {
    data.value = [];
    let config: any = {
        params: {
            factoryId: localStorage.getItem("factory_id"),
            skip: searchModel.value.skip,
            condition: searchModel.value.condition,
            userNick: searchModel.value.userNick,
            take: searchModel.value.take,
            sort: "date",
            order: -1,
            machineId: machine_id.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }
    let result = await request.get("/api/external/inspection", config);

    if (result && result.status === 200) {
        data.value = result.data;
        if(result.data.list.length>0){
           
            
            getReportInfo(result.data.list[0],0)
        }else{
            getReportInfo({},0)
        }
       
           
        
        searchModel.value.total = result.data.count;
    }
};
// 获取报告健康状态
const getReportCondition = (ev: any) => {
    if (ev && ev.condition) {
        return conditionList2.find(
            (p: any) => p.value === ev.condition
        );
    } else {
        return conditionList2[0];
    }
};

// 获取报告类型
const getReportType = (ev: any) => {
    if (ev && ev.type) {
        return reportTypes.find(
            (p: any) => p.value === ev.type
        ).label;
    } else {
        return reportTypes[0].label;
    }
};
//获取测点指标
const getValue2 = (wkstatus, code, Z) => {
    if (!wkstatus) {
        return '--'
    }
    let value = wkstatus.find((p: any) => p.code == code && p.coordinate == Z)?.value || 0
    return Number(value) && !isNaN(Number(value)) ? Number(value).toFixed(2) : 0
};

// 重置
const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        keyWord: "",
        date: null,
        userNick: "",
        type: null,
        condition: null,
        status: null,

    };
    search()
};
const handleEvent = (e: any) => {
    if (e.target.localName == "body") {
        if (e.srcElement.scrollTop >= 120) {
            scrollTop.value = "5px";
        } else {
            scrollTop.value = "120px";
        }
    }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
    getLang();
    // getReportList()
    window.removeEventListener("scroll", handleEvent, true);
});

// 获取设备详情
const getMachineInfo = async () => {
    let config: any = {
        params: {
            id: machine_id.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/inspection/machine/info", config);

    if (result) {
        machineInfo.value = result.data;
        if (
            machineInfo.value &&
            machineInfo.value.workStatus &&
            machineInfo.value.workStatus.scoreCondition
        ) {
            machineInfo.value.condition = conditionList.find((p: any) =>
                p.value.includes(machineInfo.value.workStatus.scoreCondition)
            );
        } else {
            console.log(conditionList)
            machineInfo.value.condition = conditionList[0];
        }

        if (
            machineInfo.value &&
            machineInfo.value.workStatus &&
            machineInfo.value.workStatus.scoreCondition
        ) {
            machineInfo.value.conditionC = conditionList.find((p: any) =>
                p.value.includes(machineInfo.value.workStatus.scoreCondition)
            );
        } else {
            machineInfo.value.conditionC = conditionList[0];
        }

        if (
            machineInfo.value &&
            machineInfo.value.workStatus &&
            machineInfo.value.workStatus.scoreWkStatus
        ) {
            machineInfo.value.status = statusList.find(
                (p: any) => p.value === machineInfo.value.workStatus.scoreWkStatus
            );
        } else {
            machineInfo.value.status = statusList[0];
        }
    }
    if (machineInfo.value.pictures?.length > 0) {
        picList.value.push({
            name: "设备",
            url: machineInfo.value.pictures[0].url,
        });
    }
    getSensorList();
    // getFaultName();

    getHistrendData();
    // getReportList()
    // getMachineWKstatus();
};

// 获取测点列表\
let picListAll: any = ref([]);
const getSensorList = async () => {
    let senSearch = {
        params: { machineId: machine_id.value },
        headers: {
            requestId: uuidv4(),
        },
    };

    let senList = await request.get("/api/sensors", senSearch);
    senList.data &&
        senList.data.map((d: any) => {
            if (d.pictures) {
                d.pictures.map((p: any) => {
                    picList.value.push({
                        name: d.sensorPlace,
                        url: p.url,
                    });
                });
            }
        });

    picListAll.value = picList.value;
    sensorList.value = senList.data;
    sensorListAll.value = [
        { sensorPlace: langObj.value["全设备测点"], url: "", id: "" },
    ].concat(senList.data);

};




// 分数趋势图
// 五分钟自动刷新
let intervalTimer: any = null;
let fiveRefresh: any = ref(null);
let histrendSearchModel: any = ref({
    type: 1,
    date: null,
    sensorId: "",
    isShow: true,

});
let myChartC: any = null;

let faultChartSelectlenged: any = {};


const refresh = (ev: any) => {
    if (ev) {
        intervalTimer = setInterval(() => {
            getHistrendData();
        }, 1000 * 60 * 5);
    } else {
        clearInterval(intervalTimer);
    }
};

onBeforeUnmount(() => {
    clearInterval(intervalTimer);
});
let faultNameList: any = ref([])
let scoreList: any = ref([])

// 获取趋势图
const getHistrendData = async () => {
    let beginDate: any = "";
    let endDate: any = "";
    let machine_date = machineInfo.value.workStatus?.scoreDate;
    if (histrendSearchModel.value.type == "0") {
        if (
            histrendSearchModel.value.date &&
            histrendSearchModel.value.date.length === 2
        ) {
            let start = histrendSearchModel.value.date[0];
            let end = histrendSearchModel.value.date[1];
            beginDate = start.$d;
            endDate = end.$d;
        } else {
            message.warn("请选择时间");
            return;
        }
    } else {
        let time = new Date(machine_date);
        let date = time.getTime();
        if (histrendSearchModel.value.type == "1") {
            beginDate = date - 1000 * 60 * 60 * 24 * 7;
        }
        if (histrendSearchModel.value.type == "2") {
            beginDate = date - 1000 * 60 * 60 * 24 * 30;
        }
        if (histrendSearchModel.value.type == "3") {
            beginDate = date - 1000 * 60 * 60 * 24 * 60;
        }
        endDate = machine_date;
    }

    let begin = transformDate2(beginDate, 1);
    let end = transformDate2(endDate, 2);
    if (machine_date) {
        inspectionBase.value.beginDate = begin;
    } else {
        inspectionBase.value.beginDate = "--";
    }

    getTimeLineData(begin, end);


    const config1: any = {
    params: {
      machineId: machine_id.value
    },
    headers: {
      requestId: uuidv4(),
    },

  };

    const config = {
        params: {
            machineId: machine_id.value,
            begin,
            end,
            sensorId: ""
        },
        headers: {
            requestId: uuidv4(),
        },
    };


    if (histrendSearchModel.value.sensorId) {
        config.params.sensorId = histrendSearchModel.value.sensorId;

    }
    let result = await request.get("/api/external/inspection/machines/score-expert", config);
    let result2 = await request.get("/api/external/inspection/score/faultNames", config1);
    if(result2){
        faultNameList.value = result2.data.faultNameExpects || []
    }

    if (result) {
       
        scoreList.value = result.data.list || []
    }
    // if (faultNameList.value.length == 0) {
    //     return false
    // }
    // if (scoreList.value.length == 0) {
    //     return false
    // }

    scoreList.value.map((item: any) => {

        faultNameList.value.map((fault: any) => {
            if (!fault.data) {
                fault.data = []
            }
            if (!fault.dateList) {
                fault.dateList = []
            }

            fault.dateList.push(item.date)
            let date = item.date.slice(5, 19)
            fault.data.push([date, item[fault.column] || null])

        })
    })

    initTrendChart()


};

const initTrendChart = () => {

    let data = JSON.parse(JSON.stringify(faultNameList.value))
    console.log(data,"datadatadata")
    let xData = data && data.length > 0 && data[0].data ? data[0].data?.map((p: any) => p[0]) : [];
    // let xData = data && data.length ? data[0].data.map((p: any) => p[0]) : [];
    if (!document.getElementById("chartCover")) return
     myChartC = echarts.init(document.getElementById("chartCover"));
     myChartC && myChartC.clear()

    data.map((item: any) => {
        faultChartSelectlenged[langObj.value[item.title] || item.title] = true;
    });
    let min = 0,
        max = 100,
        colors: any = ref([]);
    let option: any = null;
    let LengData: any[] = [],
        SelectedData,
        YaData, SeriesData, DataInfo: any = ref([]);
    colors.value = ['rgb(87,147,243)', 'rgb(255,165,0)', 'rgb(22,58,255)', 'rgb(0,255,0)', 'rgb(255,255,255)',
        'rgb(255,51,51)', 'rgb(0,255,255)',
        'rgb(0,128,0)', "rgb(32, 157, 179)", 'rgb(120, 32, 179)', 'rgb(214, 55, 188)'
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.value.push({
                name: item.title,
                type: 'line',
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
                column: item.column
            })
        }
    })


    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        for (let n = 0; n < LengData.length; n++) {
            const ydata = Datas[n].data;
            let max = Math.max(...ydata) / 0.8 / 5;
            if (max > 0 && max < 0.5) {
                max = 1;
            }
            const ymax = Math.round(max) * 5;

            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                    type: "value",
                    scale: true,
                    min: 0,
                    max: 100,
                    splitNumber: 5,
                    splitLine: {
                        lineStyle: {
                            color: [
                                "rgb(117, 116, 116)",
                                "rgb(117, 116, 116)",
                                "rgb(117, 116, 116)",
                                "rgba(250, 60, 88, 1)",
                                "rgba(254, 178, 7, 1)",
                                "rgb(117, 116, 116)",
                            ],
                        },
                    },
                    position: "left",
                    offset: "-10",
                    axisLine: {
                        lineStyle: {
                            color: theme.value=='white'? '#333':"#fff",
                        },
                    },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
        }


        if (YaData.length == 0) {
            YaData = [{ type: "value" }];
        }
        option = {
            color: colors.value,
            tooltip: {
                hideDelay: 100,
                transitionDuration: 0,
                animation: false,
                trigger: 'axis',
                // positon: ['100%', '0%'],
                confine: true,
                axisPointer: {
                    type: 'cross',
                }
            },
            grid: {
                right: "5%",
                left: "7%",
            },
            toolbox: {
                trigger: 'axis'
            },
            dataZoom: [{ type: "inside" }],

            legend: {
                data: LengData,
                selected: SelectedData,
                textStyle: {
                    color:  theme.value=='white'? '#333':"#fff",
                },
            },
            xAxis: [{
                show: true,
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xData,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.3
                    }
                },
                axisLabel: {
                    color:  theme.value=='white'? '#333':"#fff",
                    fontSize: 12,
                    showMaxLabel: true
                },
            }],
            yAxis: [{
                type: 'value',
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                        color: ['rgb(117, 116, 116)', 'rgb(117, 116, 116)',
                            'rgb(117, 116, 116)', 'rgba(250, 60, 88, 1)',
                            'rgba(254, 178, 7, 1)', 'rgb(117, 116, 116)'
                        ]
                    }
                },
                position: 'left',
                offset: '-5',
                axisLabel: {
                    margin: 10
                },
                axisLine: {
                    lineStyle: {
                        color:  theme.value=='white'? '#333':"#fff"
                    }
                },
            }],
            series: SeriesData
        };
        console.log(SeriesData, "SeriesData")
        console.log(option, "option")
        // myChartC&&myChartC.setOption(option);

    }
    Init(faultChartSelectlenged, DataInfo.value);
    if (option && typeof option === "object") {
        myChartC && myChartC.clear();
        myChartC && myChartC.setOption(option, true);
    }

    myChartC
        .off("legendselectchanged")
        .on("legendselectchanged", function (params: { selected: any }) {
            // 得到当前的图例显示隐藏状态分别有哪些
            faultChartSelectlenged = params.selected;


            Init(faultChartSelectlenged, DataInfo.value);
            if (option && typeof option === "object") {
                myChartC?.clear();
                myChartC?.setOption(option, true);
            }
        });


};





// 运行时间趋势
let statusSearchModel: any = ref({
    date: null,
    type: "1",
    isShow: true,
});


// 测点趋势
let sensorSearchModel: any = ref({
    date: null,
    type: "1",
    isShow: true,
});





// 获取时间轴数据
// getTimeLineData(begin, end)
let timeLineList: any = ref([]);
const getTimeLineData = async (begin: any, end: any) => {
    let stableList: any = [];
    let warnSearch = {
        params: {
            begin,
            end,
            machineId: machine_id.value,
            skip: 1,
            take: 5000,
            factoryId: localStorage.getItem("factory_id"),
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let conditionListA = [
        { value: 1, valueL: [0], color: "#61c08f" },
        { value: 2, valueL: [1, 2], color: "#d2de49" },
        { value: 3, valueL: [3, 4, 5], color: "#cd5f3b" },
        { value: 4, valueL: [6, 7], color: "#921e37" },
    ];

    let res = await request.get("/api/external/inspection", warnSearch);
    let orderList: any = [];
    if (res && res.data) {
        orderList = res.data.list.reverse();
        orderList.map((w: any) => {
            w.width = (100 / orderList.length) + '%'
        })
        timeLineList.value = JSON.parse(JSON.stringify(orderList));

    }


};

const selectedRowIndex:any = ref(0);
const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  let className = ""
  if (selectedRowIndex.value == index) {
    className += "active-table"
  }else{
    if (index % 2 === 0) {
    className = "even-row"
  } else {
    className = "odd-row"
  }
  }
  
  return className
};


// const rowClick = (record:any, index:any) => {
//   return {
//     onclick: (e:any) => {
//       selectedRowIndex.value = index;
//     //   getReportInfo(record)

//     }
//   }

// }

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
    return tooEarly || tooLate;
};

const disabledDate2 = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 60;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 60;
    return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
    dates.value = val;
};
</script>

<style lang="less" scoped>
.border {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff;
}

.sjz {
    margin-left: 4rem;
    margin-right: 4rem;
    width: calc(100% - 8rem);
    height: 20px;
    background: #999;
    padding: 0;
}

/deep/ .ant-progress-inner {
    border-radius: 0;
}

/deep/ .ant-progress-success-bg,
.ant-progress-bg {
    border-radius: 0;
}

/deep/ .ant-progress-inner {
    background-color: transparent;
}

/deep/ .ant-input-affix-wrapper {
    background: #021768;
    border: 1px solid #0d53b7cc;
    color: #fff;
}

/deep/ .ant-input {
    background: #021768;
    color: #fff;
}

/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165d;
    border: 1px solid #0d53b7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165d;
    border: 1px solid #0d53b7;
    color: #fff;
}

/deep/ .ant-switch {
    background-color: #07259a;
}

/deep/ .ant-switch-checked {
    background-color: #07259a !important;
}

/deep/ .ant-picker {
    background-color: #021768;
    border: 1px solid #0d53b7;
}

/deep/ .ant-picker-suffix {
    color: #fff;
    opacity: 0.5;
}

/deep/ .ant-table {
    background: #020f46;
    color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background: transparent;
}

/deep/ .ant-empty-normal {
    color: #fff;
}

/deep/ .ant-descriptions-item-content {
    color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
    border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
    background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
    background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
    background: #07249933 !important;
}

.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
    width: 250px;
    padding: 20px 10px;
}

.machine-select {
    border-radius: 2px;
    background: #072499;
}

.content {
    border: 1px solid #0d53b7cc;
    // background: #07249933;
    background: url("@/assets/screen/p_left_bodyb_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px 10px;
}

.btn1 {
    border: 1px solid #0d53b7;
    border-radius: 2px;
    padding: 5px 10px;
    cursor: pointer;
}

.btn1-select {
    background: #072499;
    padding: 5px 10px;
    cursor: pointer;
}

.btn2 {
    border: 1px solid #0d53b7;
    cursor: pointer;
}

.btn3 {
    border: 1px solid #0d53b7;
    background-color: #072498;
    padding: 0.25rem 0.75rem;
    cursor: pointer;
}

.opt6 {
    opacity: 0.6;
}

.opt8 {
    opacity: 0.8;
}

.size20 {
    font-size: 20px;
}

.size18 {
    font-size: 18px;
}

.size12 {
    font-size: 12px;
}

.imgShow {
    max-width: 15px;
    width: 15px;
    height: 15px;
}
</style>