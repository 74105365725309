import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "main" }

import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { SelectProps } from "ant-design-vue";
import { icons } from "../../common/icons";
import Icon from "@ant-design/icons-vue";
import { v4 as uuidv4 } from "uuid";
import { message, FormInstance } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
const router = useRouter();
let loading = ref(false);
const formRef = ref();

const formState = reactive({
  roleName: "",
  skip: 1,
  take: 10,
});

const visible = ref<boolean>(false);

const hide = () => {
  visible.value = false;
};
const selectIcon = (iconUrl: any) => {
  formMenu.value.icon = iconUrl;
};
const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
  roleName: "",
  meun: [],
});
let formMenu = ref({
  title: "",
  code: "",
  parentId: "0",
  route: "",
  sort: 1,
  status: true,
  icon: "",
  type: 1,
  id: "",
});
const formStateRef = ref<any>();
let tableData = ref([]);
const columns = [
  {
    title: langObj.value["菜单名称"],
    dataIndex: "title",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["菜单Code"],
    dataIndex: "code",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["菜单路由"],
    dataIndex: "route",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "id",
    align: "center"
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  formMenu.value = {
    title: "",
    code: "",
    parentId: "0",
    route: "",
    sort: 1,
    status: true,
    icon: "",
    type: 1,
    id: "",
  };
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);
};

// 保存
const handleOk = () => {
  formStateRef.value.validate().then((result: any) => {
    if (formMenu.value.id) {
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/menus/info", formMenu.value, config).then((res) => {
        if (res) {
          modalState.visible = false;
          message.success("操作成功");
          search();
        }
      });
    } else {
      const config = {
        params: formMenu.value,
        headers: {
          requestId: uuidv4(),
        },
      };
      request.post("/api/menus", formMenu.value, config).then((res) => {
        if (res) {
          modalState.visible = false;
          message.success("操作成功");
          search();
        }
      });
    }
  }).catch((err: any) => {

    console.log(err); //失败后执行

  });
};

const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/menus/info", config).then((res) => {
    if (res) {
      message.success("操作成功");
      search();
    } else {
    }
  });
};


const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  formMenu.value = JSON.parse(JSON.stringify(val));
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500)

};

// 查询
const search = () => {
  loading.value = true;
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/menus", config).then((res: any) => {
    if (res) {
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      tableData.value = oneLevel;
      options.value = res.data
        .filter((t: any) => t.parentId == 0)
        .map((t: any) => ({
          value: t.id,
          label: langObj.value[t.title] || t.title,
        }));
      options.value.unshift({ value: "0", label: "--"+ langObj.value['一级菜单'] +"--" });
      loading.value = false;
    }
  });
};
// 在组件加载完成后执行一些操作
onMounted(() => {
  search();
});

const filterOption = (input: string, option: any) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const options = ref([{ value: "0", label: "--一级菜单--" }]);

return (_ctx: any,_cache: any) => {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: add
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['新增']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_table, {
                bordered: "",
                columns: columns,
                "row-key": (record) => record.id,
                dataSource: _unref(tableData),
                pagination: false,
                loading: _unref(loading),
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'icon')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        text
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(icons)[text] + '-outlined'), {
                              key: 0,
                              style: {"font-size":"30px"}
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'id')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_a_popconfirm, {
                          title: _unref(langObj)['确定删除此条数据么']+'?',
                          "ok-text": _unref(langObj)['确定'],
                          "cancel-text": _unref(langObj)['取消'],
                          onConfirm: ($event: any) => (deleteThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined),
                                _createTextVNode(_toDisplayString(_unref(langObj)['删除']), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "plft",
                          onClick: ($event: any) => (editThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_edit_outlined),
                            _createTextVNode(_toDisplayString(_unref(langObj)['编辑']), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["row-key", "dataSource", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 850,
          visible: modalState.visible,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((modalState.visible) = $event)),
          title: modalState.title,
          "ok-text": _unref(langObj)['确定'],
          "cancel-text": _unref(langObj)['取消'],
          "confirm-loading": modalState.confirmLoading,
          maskClosable: false,
          onOk: handleOk
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(formMenu),
              ref_key: "formStateRef",
              ref: formStateRef,
              name: "basic",
              autocomplete: "off",
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['上级菜单'],
                  name: "parentId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(formMenu).parentId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formMenu).parentId) = $event)),
                      "show-search": "",
                      placeholder: "",
                      options: options.value,
                      "filter-option": filterOption
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单名称'],
                  name: "title",
                  rules: [
          { required: true, message: _unref(langObj)['请输入菜单名称']+'!', trigger: 'blur' },
        ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMenu).title,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formMenu).title) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单Code'],
                  name: "code",
                  rules: [
          { required: true, message: _unref(langObj)['请输入菜单Code']+'!', trigger: 'blur' },
        ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMenu).code,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formMenu).code) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单类型'],
                  name: "type"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _unref(formMenu).type,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formMenu).type) = $event)),
                      name: "radioGroup"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['按钮']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: 2 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['链接']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                (_unref(formMenu).type == 2)
                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                      key: 0,
                      label: _unref(langObj)['路由地址'],
                      name: "route",
                      rules: [
          { required: true, message: _unref(langObj)['请输入路由地址']+'!', trigger: 'blur' },
        ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _unref(formMenu).route,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formMenu).route) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["label", "rules"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单排序'],
                  name: "sort"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      id: "inputNumber",
                      value: _unref(formMenu).sort,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formMenu).sort) = $event)),
                      min: 1,
                      style: {"width":"100%"}
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单状态'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _unref(formMenu).status,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formMenu).status) = $event)),
                      name: "radioGroup"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['开启']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['禁用']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title", "ok-text", "cancel-text", "confirm-loading"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})