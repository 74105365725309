<template>
    <div class="mt-4">
        <div class="w-full flex justify-center">
            <div style="width: 600px;"> 
                <a-steps size="small" :current="current">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
            </div>
        </div>

        <div v-if="current == 0">
            <div class="mt-4" style="font-size: 18px;">{{ getLanguage('监测设备类别') }}:</div>
            <a-form ref="macForm" :model="machineForm" layout="inline" autocomplete="off" class="w-full"
                :label-col="{ style: { width: '180px', wordWrap: 'break-word' } }">
                <a-row class="w-full" :gutter="24">
                    <a-col :span="12">
                        <a-form-item :label="getLanguage('监测类别')" name="mode" required>
                        <a-checkbox-group v-model:value="machineForm.mode">
                            <a-checkbox :value="1">{{ langObj['在线监测'] }}</a-checkbox>
                            <a-checkbox :value="2">{{ langObj['巡检'] }}</a-checkbox>
                        </a-checkbox-group>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div class="mt-4" style="font-size: 18px;">{{ getLanguage('设备基础信息') }}:</div>
            <a-form ref="macForm" :model="machineForm" layout="inline" autocomplete="off" class="w-full"
                :label-col="{ style: { width: '180px', wordWrap: 'break-word' } }">
                <a-row class="w-full" :gutter="24">
                    <a-col :span="8">
                        <a-form-item :label="langObj['设备区域']" name="groupId" required>
                        <a-select v-model:value="machineForm.groupId" :placeholder="langObj['请选择设备区域']" @change="selectGroupM"
                            :dropdownClassName="dropdownClassName">
                            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                            {{ item.name }}
                            </a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备名称']" name="machineName" required>
                        <a-input v-model:value="machineForm.machineName" :placeholder="langObj['请输入设备名称']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备类型']" name="type" required>
                        <a-cascader style="max-width:236px" v-model:value="machineForm.type" :options="machineTypeList"
                            :placeholder="langObj['请选择设备类型']" :dropdownClassName="dropdownClassName" @change="selectMachineType" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['巡检序号']" name="sort" :required="true">
                        <a-input-number :controls="false" style="width: 100%" v-model:value="machineForm.sort"
                            :placeholder="langObj['请输入']" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备编号']" name="machineCode">
                        <a-input v-model:value="machineForm.machineCode" :placeholder="langObj['请输入设备编号']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备型号']" name="model">
                        <a-input v-model:value="machineForm.model" :placeholder="langObj['请输入设备型号']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备位置']" name="position">
                        <a-input v-model:value="machineForm.position" :placeholder="langObj['请输入设备位置']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备厂家']" name="supplier">
                        <a-input v-model:value="machineForm.supplier" :placeholder="langObj['请输入设备厂家']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备SN号']" name="sn">
                        <a-input v-model:value="machineForm.sn" :placeholder="langObj['请输入设备SN号']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备功率']" name="sn">
                        <a-input v-model:value="machineForm.machinePower" :placeholder="langObj['请输入设备功率']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备ID']" name="sn">
                        <a-input v-model:value="machineForm.params.ysshId" :placeholder="langObj['请输入设备ID']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备位号']" name="sn">
                        <a-input v-model:value="machineForm.bitNumber" :placeholder="langObj['请输入设备位号']"></a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item :label="langObj['设备执行人']" name="sn">
                        <a-input v-model:value="machineForm.params.ysshExecutor" :placeholder="langObj['请输入设备执行人']"></a-input>
                        </a-form-item>
                    </a-col>
        
                    <a-col :span="24">
                        <a-form-item :label="langObj['设备描述']" name="remark">
                        <a-textarea v-model:value="machineForm.remark" placeholder="请输入" :rows="4" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="24">
                        <a-form-item :label="langObj['设备照片']">
                        <a-upload v-model:file-list="machineFileList" list-type="picture-card" @preview="handlePreview"
                            :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="6">
                            <div v-if="!machineFileList || machineFileList.length < 6">
                            <plus-outlined />
                            <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                            </div>
                        </a-upload>
                        </a-form-item>
                    </a-col>

                    <a-col :span="24">
                        <a-form-item :label="getLanguage('设备3D图')" name="3D">
                            <a-radio-group v-model:value="machineForm.machineTypeDetails.pictureUrl">
                                <a-radio v-for="(item,index) in machineType3Ds" :value="item.url">
                                    <a-image height="100px" :src="item.url" />
                                    <div ml-2>{{ item.remark }}</div>
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>


                    <div class="w-full flex justify-center mt-5">
                        <div v-if="current !== 0" @click="previous()" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                            {{ getLanguage('上一步') }}
                        </div>

                        <div @click="submit1" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                            {{ getLanguage('保存') }}
                        </div>

                        <div v-if="isShowNext" @click="submit1(false)" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                            {{ getLanguage('下一步') }}
                        </div>
                    </div>

                </a-row>
            </a-form>
        </div>

        <div v-if="current == 1">
            <!-- <div v-if="!isEdit" class="mt-4" style="font-size: 18px;">{{ getLanguage('测点选择') }}:</div>
            <a-form v-if="!isEdit" :model="sensorForm">
                <a-col :span="24">
                    <a-form-item :label="getLanguage('选择测点')" required>
                        <div class="flex">
                        <a-tree-select v-model:value="selectSensorList" style="width: 100%" tree-checkable
                            :tree-data="sensorTreeData" :dropdownClassName="dropdownClassName" />
                        <a-button @click="selectSensor">{{ getLanguage('确认选择') }}</a-button>

                        </div>
                    </a-form-item>
                </a-col>
            </a-form> -->
            <div class="mt-4 flex items-center" style="font-size: 18px;">{{ getLanguage('测点信息') }}:
                <div @click="addSensor()" style="font-size: 14px;" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('新建测点') }}
                </div>
            </div>
            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="sensorColumns"
            :data-source="sensorList" :pagination="false">
                <template #headerCell="{ column }">
                    <template v-if="column.dataIndex === 'sensorSn'">
                        <span style="color: red">*</span><span>{{ langObj["传感器SN"] }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'dasCode'">
                        <span v-if="isRequiredDas()" style="color: red">*</span><span>{{ getLanguage("数采器编号") }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'isUpdateParameter'">
                        <div class="flex items-center">
                            {{ getLanguage('更新模型') }}
                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        <div>{{ getLanguage('选择“是”：系统会根据选择的测点更新绑定的模型配置；选择“否”：系统只会修改安装位置名称，不会更新模型配置。') }}</div>
                                    </div>
                                </template>
                                <question-circle-outlined class="ml-2 center_wh" />
                            </a-tooltip>
                        </div>
                    </template>
                </template>
                <template #bodyCell="{ column, text, record, index }">
                    <template v-if="column.dataIndex === 'sensorPlaceSelect'">
                        <a-tree-select
                            v-if="record.isAutoCreated"
                            v-model:value="record.sensorPlaceSelect"
                            show-search
                            style="min-width: 300px"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            allow-clear
                            :tree-data="sensorTreeData"
                            tree-node-filter-prop="label"
                            @change="selectSensorPlace($event, record)"
                            :dropdownClassName="dropdownClassName"
                        >
                            <template #title="{ value: val, label }">
                            <b v-if="val === 'parent 1-1'" style="color: #08c">sss</b>
                            <template v-else>{{ label }}</template>
                            </template>
                        </a-tree-select>
                        <div v-if="!record.isAutoCreated">
                            {{ text }}
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'sensorSn'">
                        <a-input v-model:value="record.sensorSn" :placeholder="langObj['请输入测点SN号']"></a-input>
                    </template>

                    <template v-if="column.dataIndex === 'dasCode'">
                        <a-input :disabled="isDisableDas(record)" v-model:value="record.dasCode" :placeholder="langObj['请输入测点SN号']"></a-input>
                    </template>

                    <template v-if="column.dataIndex === 'sort'">
                        <a-select style="width: 50px" v-model:value="record.sort"
                            :dropdownClassName="dropdownClassName">
                            <a-select-option v-for="(item, index) in sortList" :key="index" :value="item">{{ item }}</a-select-option>
                        </a-select>
                    </template>
                    
                    <template v-if="column.dataIndex === 'sensorGroup'">
                        <div class="flex items-center">
                            Z:
                            <a-select style="width: 100px" v-model:value="record.sensorGroupNameZ"
                                :dropdownClassName="dropdownClassName">
                                <a-select-option v-for="(item, index) in directionList" :key="index" :value="item">{{ getLanguage(item) }}</a-select-option>
                            </a-select>
                            <div v-if="!isSingleAxis(record)" class="flex items-center">
                                X:
                                <a-select style="width: 100px" v-model:value="record.sensorGroupNameX"
                                    :dropdownClassName="dropdownClassName">
                                    <a-select-option v-for="(item, index) in directionList" :key="index" :value="item">{{ getLanguage(item) }}</a-select-option>
                                </a-select>
                                Y:
                                <a-select style="width: 100px" v-model:value="record.sensorGroupNameY"
                                    :dropdownClassName="dropdownClassName">
                                    <a-select-option v-for="(item, index) in directionList" :key="index" :value="item">{{ getLanguage(item) }}</a-select-option>
                                </a-select>
                            </div>
                           
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'disable'">
                        <a-switch style="width: 60px" v-model:checked="record.disable" :checked-children="langObj['禁用']"
                        :un-checked-children="langObj['启用']" />
                    </template>

                    <template v-if="column.dataIndex === 'isUpdateParameter'">
                        <a-radio-group v-model:value="record.isUpdateParameter" >
                            <a-radio :value="true">{{ getLanguage('是') }}</a-radio>
                            <a-radio :value="false">{{ getLanguage('否') }}</a-radio>
                        </a-radio-group>
                    </template>

                    <template v-if="column.dataIndex === 'act'">
                        <a @click="delSensor(index)">{{ langObj['删除'] }}</a>
                    </template>

                </template>
            </a-table>

            <div class="w-full flex justify-center mt-5">
                <div @click="previous()" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('上一步') }}
                </div>

                <div @click="submit2(false)" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('保存') }}
                </div>

                <div @click="submit2(true)" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('下一步') }}
                </div>
            </div>

        </div>

        <div v-if="current == 2">
            <div class="mt-4" style="font-size: 18px;">{{ getLanguage('设备提资信息') }}:</div>
            <div class="flex items-center mt-4">
                {{ getLanguage('设备是否频繁启停') }}: 
                <a-radio-group style="margin-left: 20px" v-model:value="isFrequentStart">
                  <a-radio :value="true">{{ getLanguage('是') }}</a-radio>
                  <a-radio :value="false">{{ getLanguage('否') }}</a-radio>
                </a-radio-group>
            </div>
            <div class="mt-4 part" v-for="(item, index) in partsList" :key="index">
                <div class="w-full flex items-center">
                    <div>{{ getLanguage('部件') }}:</div>
                    <div>{{ item?.partName }}</div>
                    <div class="ml-2">({{ getLanguage('请完善部件信息') }})</div>
                </div>

                <div class="mt-2 ml-8" style="border-left: 2px solid gray">
                    <div class="w-full flex items-center flex-wrap table1 ml-8">
                    <div style="width: 200px">
                        <div class="w-full text-center mb-2">{{ getLanguage('额定转速') }}(rpm)<span style="color: red">*</span>
                        </div>
                        <div class="mt-2">
                        <a-input-number @change="changeRs($event, item)" style="width: 200px" v-model:value="item.params.Rated_speed" :controls="false" />
                        </div>
                    </div>

                    <!-- <div style="width: 200px" class="ml-4">
                        <div class="w-full text-center mb-2">{{ getLanguage('额定功率') }}(kw)<span style="color: red">*</span></div>
                        <div class="mt-2" >
                        <a-input-number style="width: 200px" v-model:value="item.params.PowerFre" :controls="false" />
                        </div>
                    </div> -->

                    <div style="width: 200px" class="ml-4">
                        <div class="w-full text-center mb-2">{{ getLanguage('转速范围') }}<span style="color: red">*</span></div>
                        <div class="mt-2 flex">
                        <a-input-number style="width: 90px" v-model:value="item.params.startFre" :controls="false" />
                        <div class="mx-1">——</div>
                        <a-input-number style="width: 90px" v-model:value="item.params.stopFre" :controls="false" />
                        </div>
                    </div>
                    <template v-if="true">
                        <div v-for="(item2, index2) in item.segments.columns" :key="index2" style="width: 200px" class="ml-4">
                        <div class="w-full text-center mb-2">{{ getLanguage(item2) }}<span style="color: red">*</span></div>
                        <div class="mt-2 flex">
                            <a-select v-model:value="item.segments['data' + index2]" style="width: 200px"
                            @change="selectPartParmar(item.segments, index2)"  :dropdownClassName="dropdownClassName">
                            <a-select-option v-for="(s, sIndex) in item.segments['columnsData' + index2]" :key="sIndex"
                                :value="s">{{ s }}</a-select-option>
                            </a-select>
                        </div>
                        </div>
                    </template>
                    </div>

                    <div class="mt-4" v-for="(structure, index2) in item.structures" :key="index2">
                    <div class="flex items-center">
                        <div>—</div>
                        <div class="ml-2">{{ getLanguage('结构') }}: </div>
                        <div class="ml-1">{{ getLanguage(structure.machineStructureName) }}</div>
                        <div v-if="structure.sensorPlace">——</div>
                        <div v-if="structure.sensorPlace">{{ getLanguage(structure.sensorPlace) }}</div>
                        <div class="ml-2">({{ getLanguage('请完善结构信息，若该结构信息不全，将不会显示该测点的健康指标') }})</div>
                    </div>

                    <div class="w-full flex items-center flex-wrap table1 ml-8 mt-2">
                        <div v-for="(parmar, index3) in structure.parmarList" :key="index3" style="width: 200px"
                        :class="{ 'ml-4': index3 !== 0 }">
                        <div class="w-full text-center mb-2">{{ getLanguage(parmar.label) }}</div>
                        <div class="mt-2">
                            <div v-if="parmar.type === 'select'">
                            <a-select v-model:value="parmar.value" style="width: 200px" :options="parmar.option"
                                :showSearch="true" :dropdownClassName="dropdownClassName"></a-select>
                            </div>
                            <div v-else-if="parmar.type === 'string'">
                            <a-input style="width: 200px" v-model:value="parmar.value" />
                            </div>
                            <div v-else>
                            <a-input-number style="width: 200px" v-model:value="parmar.value" :controls="false" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>




                </div>



            </div>

            <div class="w-full flex justify-center mt-5">
                <div @click="previous()" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('上一步') }}
                </div>

                <div @click="isSubmitV = false, submit3()" class="ml-4 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('保存') }}
                </div>
            </div>


        </div>
       
    </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  createVNode
} from "vue";
import { getLanguage } from "../../../common/translate";
import { langList } from "../../../common/lang";
import { v4 as uuidv4 } from "uuid";
import request from "../../../common/request";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../../common/tools";
import { message } from "ant-design-vue";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';



let dropdownClassName: any = ref("");
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
const current = ref<number>(0);
const steps = [
  {
    title: getLanguage('填写设备基础信息') ,
  },
  {
    title: getLanguage('填写测点信息'),
  },
  {
    title: getLanguage('填写设备提资信息'),
  },
];

let machineForm:any = ref({
    params: {},
    mode: [],
    machineTypeDetails: {},
})
let groupList:any = ref([])
let machineFileList:any = ref([])
let machineTypeList:any = ref([])
let machineTypeListAll:any = ref([])
let machineType3Ds:any = ref([])
let macForm:any = ref()
let isShowNext = computed(() => {
  return machineForm.value.mode.includes(1)
})

let sensorForm:any = ref({

})

let categoryAll:any = ref([])
let options:any = ref([])
let structureList:any = ref([])
let bearingParameters: any = ref([])
let isEdit:any = ref(false)

const props: any = defineProps({
    machineFormEdit: null,
    fileList: null,
    dropdownClassName: null,
    isEdit: null,
});

watch(
  () => props.dropdownClassName,
  (newValue, oldValue) => {
    dropdownClassName.value = newValue;
  }
);

if(props.machineFormEdit) {
    machineForm.value = {...props.machineFormEdit };
}
if(props.fileList?.length) {
    machineFileList.value = props.fileList
}
if(props.dropdownClassName) {
    dropdownClassName.value = props.dropdownClassName
}
isEdit.value = props.isEdit

onMounted(() => {
    init()
})

const init = async() => {
    getGroupList();
    await getMachineType()
    getSearch3()
    getStructureList()
    getBearingParameter()
    getStep1()
}

const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;
  }
};

// 获取智能诊断巡检配置
const getSearch3 = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [...new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [...new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [...new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options.value = list
}

// 获取结构信息
const getStructureList = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-structures/all", config);
  if (result?.data) {
    structureList.value = result.data;
  }
}

// 获取轴承型号
const getBearingParameter = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/bearing-parameters/all', config)
  if (result?.data?.length) {
    bearingParameters.value = result.data.map((d: any) => ({
      label: d.bearingModel,
      value: d.bearingModel,
    }))
  }
}

// 第一步，设备基础信息
const getStep1 = () => {
    current.value = 0
    if(machineForm.value.machineTypeId) {
        let mtInfo = machineTypeListAll.value.find((p:any) => machineForm.value.machineTypeId === p.id )
        machineType3Ds.value = mtInfo?.pictures || []
    }
}

const isRequiredDas = () => {
    let r = true
    sensorList.value.map((d:any) => {
        if(!d.sensorSn.startsWith('63')) {
            r = false
        }
    })
    return !r
}


const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

const selectMachineType = (ev:any) => {
    if(ev?.length === 3) {
        let mtInfo = machineTypeListAll.value.find((p:any) => p.FirstCategory===ev[0] && p.SecondCategory===ev[1] && p.ThirdCategory===ev[2] )
        machineType3Ds.value = mtInfo?.pictures || []
        if(machineType3Ds.value?.length) {
            machineForm.value.machineTypeDetails.pictureUrl = machineType3Ds.value[0].url
        }
    } else {
        machineForm.value.pictureUrl = ''
        machineType3Ds.value = []
    }

}

// 上一步
const previous = () => {
    current.value --
}

// 下一步
const next = () => {
    if(current.value == 0) {
        if(!machineForm.value.machineTypeId ) {
            return message.warning("请选择设备类型后进行下一步");
        } else {
            getStep2()
        }
    }
    current.value ++
}

// 保存设备基础信息
const submit1 = (isClose:any=true) => {
    if(!machineForm.value.mode.length) {
        return message.warning('请选择检测类别')
    }
    macForm.value?.clearValidate();
    macForm.value.validate().then(async (res: any) => {
        let form = JSON.parse(JSON.stringify(machineForm.value));
        if (machineFileList.value && machineFileList.value.length) {
            form.pictures = machineFileList.value.map((p: any) => ({
            url: p.url,
            type: 1,
            }));
        }

        if (form.type && form.type.length === 3) {
            let info = machineTypeListAll.value.find(
            (p: any) =>
                p.FirstCategory === form.type[0] &&
                p.SecondCategory === form.type[1] &&
                p.ThirdCategory === form.type[2]
            );
            form.machineTypeId = info.id;
            machineForm.value.machineTypeId = info.id
        } else {
            form.machineTypeId = null;
        }
        delete form.type;
        let result = form.id
            ? await request.put("/api/machines/info", form)
            : await request.post("/api/machines", form);
        if (result && result.status === 200) {
            message.success("操作成功");
            if(!form.id) {
                machineForm.value.id = result.data;
            }


            if(isClose) {
                emits("close", result.data);
            } else {
                emits("resetData");
                next()
            }
            await getMember();
        } else {
            message.warning("操作失败,请联系管理员!");
        }



    })
}

const getMember = async () => {
  let result2 = await request.get("/api/members/info");
  localStorage.setItem("memberInfo", JSON.stringify(result2.data));
};

const emits = defineEmits(["close", 'resetData']);


let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};


// 第二步填写测点信息
let selectSensorList:any = ref([])
let sensorTreeData:any = ref([])
let sensorList:any = ref([])
let sortList:any = ref([])
let directionList:any = ['水平径向','轴向', '竖直径向']
let powerEtcList:any = ref([])
let speedList:any = ref([])
const sensorColumns:any = [
    {
        title: langObj.value["安装位置"],
        dataIndex: "sensorPlaceSelect",
    },
    {
        title: langObj.value["传感器SN"],
        dataIndex: "sensorSn",
    },
    {
        title: getLanguage("数采器编号"),
        dataIndex: "dasCode",
    },
    {
        title: getLanguage("显示顺序"),
        dataIndex: "sort",
    },
    {
        title: getLanguage("轴向名称"),
        dataIndex: "sensorGroup",
    },
    // {
    //     title: langObj.value["设备类型"],
    //     dataIndex: "type",
    // },
    // {
    //     title: langObj.value["设备功率"],
    //     dataIndex: "national",
    // },
    // {
    //     title: getLanguage('智能诊断巡检配置'),
    //     dataIndex: "inspectionSubId",
    // },
    // {
    //     title: getLanguage('转速范围'),
    //     dataIndex: "defaultSpeed",
    // },
    {
        title: langObj.value["是否启用"],
        dataIndex: "disable",
    },
    {
        title: getLanguage('更新模型'),
        dataIndex: "isUpdateParameter",
    },
    {
        title: langObj.value["操作"],
        dataIndex: "act",
    },
]
const getStep2 = () => {
    console.log('machineForm.value.machineTypeId', machineForm.value.machineTypeId)
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.id === machineForm.value.machineTypeId)
    // 获取测点列表
    let sensorList1 = machineTypeInfo?.sensorTemplate?.sensorList || []
    sensorTreeData.value = []

    sensorList1.map((d: any) => {
        let first: any = {
          label: d.name || machineTypeInfo.ThirdCategory,
          value: d.uniqueId,
          selectable: false,
          children: []
        }
        if (d.sensors?.length > 0) {
          d.sensors.map((s: any) => {
            let second = {
              label: s.sensorPlace,
              value: d.uniqueId + '-' + s.sensorPlace,
              selectable: true
            }
            first.children.push(second)
          })
        }
        sensorTreeData.value.push(first)
    })

    if(machineForm.value?.machineTypeDetails?.sensorList?.length > 0) {
        sensorList.value = machineForm.value.machineTypeDetails.sensorList
    }

    console.log('sensorList.value', sensorList.value)
    sensorList.value.map(async(d: any, index:any) => {
        if(d.isAutoCreated) {
            d.sensorPlaceSelect = d.partUniqueId + '-' + d.sensorPlace
        }
        d.isUpdateParameter = false
    })
}

// 新建测点
// isUpdateParameter
const addSensor = () => {
    sensorList.value.push({
        sensorPlaceSelect: '',
        sensorSn: '',
        sort: sensorList.value.length + 1,
        isUpdateParameter: false,
        isAutoCreated: true
    })
    sortList.value = Array.from({ length: sensorList.value.length }, (_, index) => index + 1)
}

const selectSensorPlace = (ev:any, record:any) => {
    console.log('ev', ev)
    console.log('record', record)
    record.partUniqueId = ev.split('-')[0]
    record.sensorPlace = ev.split('-')[1]
    record.isUpdateParameter = true
}

// 确认选择
const selectSensor = (ev:any) => {
    if (!selectSensorList.value?.length) return message.warning('请先选择测点！')
    let sensorList_select = selectSensorList.value.map((d: any) => {
        return {
            partUniqueId: d.split('-')[0],
            sensorPlace: d.split('-')[1]
        }
    })
    sensorList.value = sensorList_select
    sensorList.value.map((d:any, index:any) => {
        d.sort = index+1
        d.sensorSn = ''
    })
    sortList.value = Array.from({ length: sensorList_select.length }, (_, index) => index + 1)
}

// 测点选择设备类型
const changeThirdTypeId = async (ev: any, index:any) => {
    if (ev && ev.length === 3) {
        let info = machineTypeListAll.value.find(
        (p: any) =>
            p.FirstCategory === ev[0] &&
            p.SecondCategory === ev[1] &&
            p.ThirdCategory === ev[2]
        );
        sensorList.value[index].machineTypeId = info.id;

        let config = {
        params: { id: info.id },
        headers: {
            requestId: uuidv4(),
        },
        };

        let result = await request.get(
            "/api/sensors/machine-types/power-etc",
            config
        );
        if (!result?.data) {
            sensorList.value[index].powerEtcList = [];
            sensorList.value[index].national = null;
            return;
        }

        sensorList.value[index].powerEtcList = result.data;
        if (result.data.length > 0) {
            sensorList.value[index].national = result.data[0].powerEtc;
        } else {
            sensorList.value[index].national = null;
        }
    } else {
        sensorList.value[index].powerEtcList = []
        sensorList.value[index].national = null;
    }
};

// 选择巡检设备细分
const changeISub = (ev: any, index:any) => {
  if (ev?.length === 3) {
    sensorList.value[index].speedList = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    sensorList.value[index].speedList = []
    sensorList.value[index].defaultSpeed = null
  }
}

// 删除测点
const delSensor = (index:any) => {
    sensorList.value.splice(index, 1)
}

// 保存
const submit2 = async (isNext:any = false) => {
    if (!sensorList.value.length) return message.warning('请先选择测点！')

    for(let i in sensorList.value) {
        let d = sensorList.value[i]
        if(!d.sensorSn.startsWith('63') &&!d.dasCode) return message.warning('请先填写数采器编号！')
    }

    let submit = {
        id: machineForm.value.id,
        machineTypeDetails: {
            sensorList: sensorList.value
        }
    }

    let result = await request.post("/api/machines/machine-type-details/sensors", submit);
    if(result?.status == 200) {
        message.success("操作成功");
        if(isNext) {
            emits("resetData");
            current.value ++
            getStep3()
        }
    } else {
        message.warning("操作失败,请联系管理员!");
    }

}

// 判断是否为单轴
const isSingleAxis = (record: any) => {
    return record.sensorSn.startsWith('73')
}

// 判断数采器是否可以输入
const isDisableDas = (record: any) => {
    if(record.sensorSn.startsWith('63')) {
        record.dasCode = ''
    }
    return record.sensorSn.startsWith('63')
}


// 第三步，设备提资信息
let isFrequentStart:any = ref(false)
let partsList:any = ref([])
let parts_old:any = ref([])
const getStep3 = async() => {    
    let sensorList_select = JSON.parse(JSON.stringify(sensorList.value))
    let uniqueIds = [... new Set(sensorList_select.map((d: any) => d.partUniqueId))]
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.id === machineForm.value.machineTypeId)
    let sensorList_template = machineTypeInfo?.sensorTemplate?.sensorList || []
    let parts: any = []


    for (let i in uniqueIds) {
        let d = uniqueIds[i]
        let structures: any = []
        let partInfo = sensorList_template.find((p: any) => p.uniqueId === d)
        if (partInfo?.sensors?.length > 0) {
            // 获取细分列表
            let config: any = {
                params: {
                    machineTypeId: machineTypeInfo.id,
                    partUniqueId: machineTypeInfo.isPart ? '' : d,
                    take: 999
                },
                headers: {
                    requestId: uuidv4(),
                },
            };
            let result = await request.get('/api/machine-type/segments/list', config)
            let resultList = result?.data?.list || []
            let tableTitles: any = []
            resultList.map((r: any) => {
                tableTitles = tableTitles.concat(Object.keys(r?.columns || {}))
            })
            let firstColumnsData: any = []
            if (tableTitles?.length > 0) {
                resultList.map((t: any) => {
                firstColumnsData.push(t?.columns[tableTitles[0]])
                })
            }
            let segments = {
                columns: [... new Set(tableTitles)],
                dataList: resultList,
                columnsData0: [... new Set(firstColumnsData)],
            }

            let sensors_part = partInfo.sensors.filter((p: any) => sensorList_select.find((s: any) => s.sensorPlace === p.sensorPlace))

            sensors_part.map((s: any) => {
                // structureList.value
                s.machineStructure.map((m: any) => {
                let structureInfo = structureList.value.find((p: any) => p.id === m)
                // 轴承齿轮需要绑定测点
                if (m === "672c55e3e19a0d0e17e653b7" || m === "672c55e3e19a0d0e17e653b9" || m === "672c55e3e19a0d0e17e653ba") {
                    structures.push({
                    sensorPlace: s.sensorPlace,
                    machineStructureId: m,
                    machineStructureName: structureInfo?.name,
                    parmarList: getStructureParmar(m)
                    })
                } else {
                    if (!structures.find((p: any) => p.machineStructureId === m)) {
                    structures.push({
                        sensorPlace: '',
                        machineStructureId: m,
                        machineStructureName: structureInfo?.name,
                        parmarList: getStructureParmar(m)
                    })
                    }
                }


                })
            })

            parts.push({
                partUniqueId: d,
                partName: partInfo.name,
                machineTypeSegmentId: null,
                params: {
                    Rated_speed: null,
                    PowerFre: null,
                    startFre: null,
                    stopFre: null,
                },
                structures,
                segments,
            })

            if(machineForm.value?.machineTypeDetails?.parts) {
                parts_old.value = machineForm.value.machineTypeDetails.parts
            }
            parts.map((part:any) => {
                let part_old = null
                if(parts_old.value?.length) {
                    part_old = parts_old.value.find((p:any) => p.partUniqueId === part.partUniqueId)
                }
                if(part_old) {
                    part.params = part_old.params
                    part.disable = true

                    part.structures.map((structure: any) => {
                        let structure_old = part_old.structures.find((p: any) => p.sensorPlace === structure.sensorPlace && p.machineStructureId === structure.machineStructureId)
                        structure.parmarList && structure.parmarList.map((parmar: any) => {
                        if (structure_old?.params && structure_old?.params[parmar.key]) {
                            parmar.value = structure_old?.params[parmar.key]
                        }
                        })
                    })
                }
                if(part_old?.machineTypeSegmentId) {
                    // parts.segments
                    part.segments.id = part_old.machineTypeSegmentId
                    let dataInfo = part.segments.dataList.find((p:any) => p.id === part.segments.id)
                    if(dataInfo) {
                        part.segments.columns.map((c:any, i:any) => {
                            part.segments['data'+i] =  dataInfo.columns[c]
                            if(i > 0) {
                                let dataListS = part.segments.dataList.filter((p: any) => p.columns[part.segments.columns[i-1]])
                                let columnsData:any = []
                                dataListS.map((t: any) => {
                                    columnsData.push(t?.columns[part.segments.columns[i]])
                                })
                                part.segments['columnsData'+i] = [... new Set(columnsData)]
                            }
                        })
                    }
                    
                }   
            })


        }
    }
    partsList.value = parts

}

// 输入额定转速
const changeRs = (ev:any, part:any) => {
    console.log('ev', ev)
    console.log('part', part)
    part?.structures?.length && part.structures.map((d:any) => {
        if(d.machineStructureName == "柱塞") {
            d.parmarList.map((p:any) => {
                if(p.key== 'fr_Min') {
                    p.value = Number((ev/60).toFixed(2))
                }
                if(p.key== 'fr_Max') {
                    p.value = Number((ev/60).toFixed(2))
                }
            })
        }
        if(d.machineStructureName == "电机") {
            d.parmarList.map((p:any) => {
                if(p.key== 'Rated_speed') {
                    p.value = Number((ev/60).toFixed(2))
                }
            })
        }
    })
}

// 获取不同结构的参数
const getStructureParmar = (id: any) => {
  let list = [
    {
      id: "672c55e3e19a0d0e17e653b6",
      name: '叶轮',
      parmar: [
        { label: '叶轮级数', value: 1, key: 'Impeller_series' },
        { label: '叶轮数目', value: null, key: 'BladeNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bc",
      name: '电机',
      parmar: [
        { label: '额定转频', value: null, key: 'Rated_speed' },
        { label: '额定电源频率', value: 50, key: 'PowerFre' },
        { label: '电机极对数', value: null, key: 'p' },
        { label: '转子条数', value: null, key: 'rot_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b7",
      name: '轴承',
      parmar: [
        { label: '轴承类型', value: null, key: 'type', type: 'select', option: [{ label: '滚动轴承', value: 1 }, { label: '滑动轴承', value: 2 }] },
        // {label: '轴承参数id', value: null, key: 'bearingParameterId', type: 'string'},
        { label: '轴承型号', value: null, key: 'bearingModel', type: 'select', option: bearingParameters.value },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b9",
      name: '行星齿轮',
      parmar: [
        { label: '太阳轮齿数', value: null, key: 'Zs', },
        { label: '一倍啮合频率', value: null, key: 'MeshFre' },
        { label: '行星轮个数', value: null, key: 'PLanetary' },
        { label: '齿圈齿数', value: null, key: 'RingTeeth' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653ba",
      name: '定轴齿轮',
      parmar: [
        { label: '主动齿轮齿数', value: null, key: 'GeaTeethNum', },
        { label: '从动齿轮齿数', value: null, key: 'GeaOutTeethNum' },
        { label: '提取啮合频率谐波阶数', value: 5, key: 'mesh_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b8",
      name: '柱塞',
      parmar: [
        { label: '柱塞数目', value: null, key: 'z', },
        { label: '柱塞泵滤波参数', value: 2000, key: 'wps' },
        { label: '柱塞泵滤波参数', value: 3000, key: 'wss' },
        { label: '柱塞泵转频上限', value: null, key: 'fr_Min' },
        { label: '柱塞泵转频下限', value: null, key: 'fr_Max' },
        { label: '轴柱塞泵个数', value: null, key: 'PlungerNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bb",
      name: '螺杆',
      parmar: [
        { label: '螺杆阳转子齿数', value: null, key: 'ScrTeethNum', },
        { label: '螺杆阴转子齿数', value: null, key: 'ScrOutTeethNum' }
      ]
    },

  ]
  return list.find((p: any) => p.id === id)?.parmar || []
}

// 选择细分内容
const selectPartParmar = (segments: any, index: any) => {
    if (index + 1 >= segments.columns.length) {
        let dataListC = JSON.parse(JSON.stringify(segments.dataList))
        for (let i in segments.columns) {
            let c = segments.columns[i]
            dataListC = dataListC.filter((p: any) => {
                return p.columns[c] === segments['data' + i]
            })
        }
        if (dataListC?.length) {
            segments.id = dataListC[0].id
        } else {
            message.warning(getLanguage('暂无选择的细分， 请重新选择'))
        }
        return
    }
    let column = segments.columns[index + 1]

    let columnsData: any = []

    let dataList1 = JSON.parse(JSON.stringify(segments.dataList))
    for(let i = 0; i <= index; i++) {
        let column1 = segments.columns[i]
        dataList1 = dataList1.filter((p:any) => p.columns[column1] == segments['data'+i])
    }

    let dataListS = dataList1.filter((p: any) => p.columns[segments.columns[index]])
    dataListS.map((t: any) => {
        columnsData.push(t?.columns[column])
    })
    columnsData = [ ... new Set(columnsData)]
    segments['columnsData' + (index + 1)] = columnsData

    for(let i = segments.columns.length; i > index; i--) {
        if(segments['data'+i]) {
            segments['data'+i] = null
        }
    }
}

// 提交设备提资信息
let isSubmitV: any = ref(false)
const submit3 = async() => {
    let parts = JSON.parse(JSON.stringify(partsList.value))
    let isComplete = true
    let isCompleteMsg = ''
    let isSub = true
    

    parts.map((d: any) => {
        if(!d.params?.Rated_speed && d.params?.Rated_speed !== 0) {
            isComplete = false
            isCompleteMsg = '请输入额定转速后提交!'
        }
        if(!d.params?.startFre && d.params?.startFre !== 0) {
            isComplete = false
            isCompleteMsg = '请输入转速范围后提交!'
        }
        if(!d.params?.stopFre && d.params?.stopFre !== 0) {
            isComplete = false
            isCompleteMsg = '请输入转速范围后提交!'
        }

        d.machineTypeSegmentId = d.segments.id
        delete d.segments
        d.structures.map((s: any) => {
            let parmarList = s.parmarList
            delete s.parmarList
            let parmar: any = {}
            parmarList.map((p: any) => {
                parmar[p.key] = p.value
                if (!p.value && p.value !== 0) {
                    if(s.machineStructureName == "轴承" && p.key === 'bearingModel') {
                        let type = parmarList.find((p:any) => p.key = 'type')
                        if(type.value !== 2) {
                            isSub = false
                        }
                    } else {
                        isSub = false
                    }
                }
            })
            s.params = parmar
        })
    })

    if(!isComplete) {
        return message.warning(isCompleteMsg)
    }
    if (!isSub && !isSubmitV.value) {
        Modal.confirm({
            title: '',
            icon: createVNode(ExclamationCircleOutlined),
            content: createVNode('div', { style: 'color:#fff;' }, '有部件或结构信息未填写完整，对应的健康指标将不会显示，您确认要提交吗?'),
            onOk() {
                isSubmitV.value = true
                submit3()
            },
            onCancel() {
            },
            style: { color: '#fff' },
            zIndex: 1000000003
        });
        return
    }

    let submit = {
        id:  machineForm.value.id,
        machineTypeDetails: {
            isFrequentStart: isFrequentStart.value,
            parts: parts
        }
    }

    let result = await request.post('/api/machines/machine-type-details', submit)
    if(result?.status == 200) {
        message.success('提交成功!')
        emits("close", result.data);
    }
}


const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

</script>

<style lang="less">
.part {
    .table1 {
        border: 1px solid #0d53b7cc;
        width: 97%;
        padding: 5px 5px;

        .header {}
    }
}
</style>