<template>
  <div style="color: #fff">
    <div class="w-full flex items-center">
      <div class="flex-1"></div>
      <!-- 关闭按钮
      <div class="cursor-pointer" @click="visibleEdit = false">
        <close-outlined style="font-size: 20px" />
      </div>
      -->
    </div>
    <div class="text-center w-full" style="font-size: 24px">{{ langObj['总貌图'] }}</div>

    <div class="d3d-main">
      <div id="container" class="rotateThemeBg" style="padding:0;position:relative;">
        <div style="position: fixed;z-index: 1;margin-left: 10px;">
          <span class="gh3d">
            <a-upload :custom-request="customRequest3D" :before-upload="beforeUpload" :auto-upload="false" accept=".glb,.gltf" :max-count="1">
              <button id="repart" class="lipButton">
                <div>
                  <SwapOutlined style="font-size: 22px" />
                </div>
                {{ langObj['更换3D图'] }}(.glb/.gltf)
              </button>
            </a-upload>
          </span>
          <div class="d3d-tips">{{ langObj['左键按住可拖动，右键添加标记'] }}</div>
        </div>

        <!-- 场景画布 -->
        <TresCanvas v-if="showCanvas && modelPath">
          <TresPerspectiveCamera />
          <!-- 轨道控制器 -->
          <OrbitControls />
          <!-- 环境光 -->
          <TresAmbientLight color="#ffffff" :intensity="1" />
          <TresDirectionalLight color="#ffffff" :position="[300, 300, 250]" :intensity="6" />

          <!-- 模型 -->
          <TresGroup>
            <TresGridHelper :scale="3" />

            <Suspense>
              <d3d-model :model-path="modelPath" :model-size="modelSize" @context-menu="onMarkPoint"></d3d-model>
            </Suspense>

            <template v-for="(point, index) in markPoints" :key="index">
              <TresMesh>
                <Html v-bind="htmlState" :position="[point.x, point.y, point.z]">
                <div class="d3d-mark-line text-white" :class="{ 'd3d-mark-line-left': point.linePosition === 'left', 'd3d-mark-line-right': point.linePosition === 'right' }"></div>
                <div class="d3d-mark-label" :class="{ 'd3d-mark-label-left': point.linePosition === 'left', 'd3d-mark-label-right': point.linePosition === 'right' }">
                  <div class="d3_bq" style="width: 225px; height: 50px; border-color: #0D53B7; box-shadow: #0D53B7; ">
                    <div class="w-full " style="padding: 5px 10px">
                      <div class="w-full flex ">
                        <div class="flex-1"></div>
                        <div>{{ point.id }}: {{ point.label }}</div>
                        <div class="flex-1"></div>
                      </div>
                    </div>
                  </div>
                </div>

                </Html>
              </TresMesh>
            </template>

          </TresGroup>
        </TresCanvas>
      </div>

      <div class="m_r">
        <div class="list">
          <div class="item">
            <span style="line-height:30px" class="me-1">{{ langObj["模型尺寸"] || "模型尺寸" }}: </span>
            <a-input-number id="inputNumber" v-model:value="modelSize" :min="0.1" :step="0.5" :max="10" />
          </div>
          <div class="item" v-for="(item, index) in markPoints" :key="index">
            <div class="lleft">{{ item.id }}</div>
            <div class="ms-2">
              <a-select v-model:value="item.bind" @change="onChangeSensor($event, item)" style="width: 200px" :options="sensorOptions"></a-select>
            </div>
            <div class="ms-1 me-1">
              <a-select v-model:value="item.linePosition" style="width: 100px" :options="linePositionOptions"></a-select>
            </div>
            <div class="licon" @click="deleteTag(index)">
              <DeleteOutlined />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-center mt-5">
      <div @click="onClickClose" class="btn-default py-1 px-6">
        {{ langObj['关闭'] }}
      </div>
      <div @click="save3D" class="btn-default py-1 px-6 ms-6">{{ langObj['保存'] }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup async>
import { onMounted, reactive, ref, withDefaults, defineProps, defineEmits, watch } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import { v4 as uuidv4 } from "uuid";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

import { TresCanvas } from '@tresjs/core'
import { OrbitControls, Html } from '@tresjs/cientos'

import type { UploadProps } from 'ant-design-vue';
import { message } from "ant-design-vue";

import d3dModel from './d3d-model.vue'

const emits = defineEmits(['success', 'close'])

interface Props {
  machineId: string
}

const props = withDefaults(defineProps<Props>(), {
  machineId: ''
})

let showCanvas = ref(false)
onMounted(async () => {
  // 延迟显示canvas, 否则modal中模型的context-menu事件不会触发
  // 怀疑跟canvas的渲染时计算画布size有关, 待modal显示后再计算canvas能确保成功
  setTimeout(() => {
    showCanvas.value = true
  }, 100);
})

const htmlState = reactive({
  wrapperClass: 'd3d-mark-box',
  as: 'div',
  sprite: true,
  transform: true,
  distanceFactor: 5,
  center: true
})

let customRequest3D = async (options: any) => {
  const { onSuccess, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    }
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    modelPath.value = "";
    setTimeout(() => {
      modelPath.value = res.data;
    }, 100);
    markPoints.value = [];
  }
};

const beforeUpload: UploadProps['beforeUpload'] = file => {
  if (file.size / 1024 / 1024 > 100) {
    message.error('文件大小不能超过100M');
    return false
  }
  return true;
};

let config_3d: any = ref({
  d3dMarks: [],
});
let modelSize = ref(2);

let modelPath = ref<string>("")

let timer2: any = null
watch(() => modelSize.value, (val) => {
  if (modelSize.value > 0) {
    showCanvas.value = false
    if (!timer2) {
      timer2 = setTimeout(() => {
        showCanvas.value = true
        timer2 = null
      }, 10);
    }
  }
}, { immediate: true })

type MarkLinePosition = "center" | "left" | "right"

class MarkPoint {
  id: number;
  x: number;
  y: number;
  z: number;
  linePosition: string;
  bind: string = ""; // sensorId
  label: string = "标签";

  constructor(id: number, x: number, y: number, z: number, linePosition: MarkLinePosition = "center") {
    this.id = id;
    this.x = x;
    this.y = y;
    this.z = z;
    this.linePosition = linePosition;
  }
}

const linePositionOptions = [
  { label: "中间", value: "center" },
  { label: "左侧", value: "left" },
  { label: "右侧", value: "right" },
]

let markPoints = ref<MarkPoint[]>([]);

let timer: any = null
function debounce(fn: any, delay = 1000) {
  if (timer != null) {
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(fn, delay)
}

const onMarkPoint = (ev: any) => {
  if (!ev) return;

  // 每次变更模型文件，都会导致多绑定一个右键事件（疑似tres的 bug）,所以这里做了一个防抖处理
  debounce(() => {
    let x = ev.point.x;
    let y = ev.point.y;
    let z = ev.point.z;
    let linePosition: MarkLinePosition = "center";
    if (markPoints.value.length % 3 === 0) {
      linePosition = "left"
    } else if (markPoints.value.length % 3 === 2) {
      linePosition = "right"
    }
    markPoints.value.push(new MarkPoint(markPoints.value.length + 1, x, y, z, linePosition));
  }, 200)
}

const load3d = async () => {
  config_3d.value = { d3dMarks: [] };
  let config: any = {
    params: {
      machineId: props.machineId
    },
    headers: {
      requestId: uuidv4()
    },
  };
  let result = await request.get("/api/machine-3D", config);

  config_3d.value = result.data;

  if (result?.data && result.data.type === "3d") {
    config_3d.value = result.data;
    modelPath.value = result.data.picture;
    markPoints.value = result.data.d3dMarks || [];
    if (config_3d.value.d3d?.modelSize) {
      modelSize.value = config_3d.value.d3d.modelSize
    }
  } else {
    modelPath.value = ""
    markPoints.value = []
  }
}

const save3D = async () => {
  if (markPoints.value.filter((t: any) => !t.bind).length > 1) {
    message.warning("请设置每个标点内容");
    return;
  }

  var postData = {
    id: config_3d.value?.id,
    type: "3d",
    machineId: props.machineId,
    picture: modelPath.value,
    d3dMarks: markPoints.value,
    d3d: {
      modelSize: modelSize.value
    }
  };
  // 保存
  if (postData.id) {
    var result = await request.put("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      emits('success')
    }
  } else {
    result = await request.post("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      emits('success')
    }
  }
}

const deleteTag = (index: any) => {
  let tempArr = []
  let alreadyHit = false
  for (let i = 0; i < markPoints.value.length; i++) {
    if (i === index) {
      alreadyHit = true
    }

    // 不能从中间index删除元素，只能删除最后一个元素, 将index后面的元素值复制给前一个元素，保留index对象的引用
    // 否则标签顺序会出错

    if (alreadyHit) {
      if (i !== markPoints.value.length - 1) {
        markPoints.value[i + 1].id = markPoints.value[i + 1].id - 1
        Object.assign(markPoints.value[i], markPoints.value[i + 1])

        tempArr.push(markPoints.value[i])
      }
    } else {
      tempArr.push(markPoints.value[i])
    }
  }

  markPoints.value = tempArr
};

const onChangeSensor = (sensorId: string, point: MarkPoint) => {
  let sensor = sensorOptions.value.find((t: any) => t.value === sensorId);
  point.label = sensor.label;
}

let sensorOptions = ref<any[]>([]);
const getSensors = async () => {
  sensorOptions.value = []
  if (!props.machineId) return
  let config: any = {
    params: {
      machineId: props.machineId
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    // 总貌图选项
    for (let sensor of result.data) {
      sensorOptions.value.push({
        label: sensor.sensorPlace,
        value: sensor.id
      });
    }
  }
};

const onClickClose = () => {
  emits('close');
}

watch(() => props.machineId, async () => {
  if (props.machineId) {
    await getSensors();
    await load3d()
  } else {
    sensorOptions.value = []
    markPoints.value = []
    modelPath.value = ""
    config_3d.value = { d3dMarks: [] };
  }
}, { immediate: true })

</script>

<style lang="less" scoped>
.d3d-tips {
  margin-top: 20px;
}

.d3d-main {
  position: relative;
  width: 1200px;
  display: flex;
}

.m_r {
  width: 500px;
  height: 600px;
  overflow-y: auto;

  .list {
    width: 300px;
    margin-left: 30px;

    .item {
      margin-top: 10px;
      display: flex;

      .lleft {
        width: 50px;
        line-height: 30px;
        text-align: center;
      }

      .licon {
        width: 50px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.d3d-mark-box>div:first-of-type {
  position: relative !important;
}

.d3d-mark-box {
  user-select: none;
  pointer-events: none !important;

  #inner {
    user-select: none;
    pointer-events: none !important;
  }

  .d3d-mark-line {
    width: 15px;
    height: 15px;
    background-color: #e5e7eb;
    border-radius: 50%;
    border: 5px solid #072499;

    /* 添加斜线效果 */
    &::before {
      content: ' ';
      position: relative;
      top: -151px;
      left: -148px;
      width: 300px;
      height: 2px;
      display: block;
      transform: rotate(90deg);
      background-color: #e5e7eb;
    }
  }

  .d3d-mark-line-left::before {
    top: -155px;
    left: -446px;
    width: 500px;
    transform: rotate(218deg);
  }

  .d3d-mark-line-right::before {
    top: -203px;
    left: -91px;
    width: 500px;
    transform: rotate(128deg);
  }

  .d3d-mark-label {
    color: white;
    position: fixed;
    top: -300px;
    left: -110px;
    font-size: 20px;
    border: 0 transparent;
  }

  .d3d-mark-label-left {
    top: -329px !important;
    left: -560px !important;
  }

  .d3d-mark-label-right {
    top: -420px !important;
    left: 250px !important;
  }
}
</style>