import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "px-5" }
const _hoisted_2 = { class: "w-full flex items-center mt-4" }
const _hoisted_3 = { class: "flex items-center event-btns" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = ["onUpdate:modelValue", "onChange"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  class: "flex items-center",
  style: {"color":"#000"}
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }

import request from "../../../common/request";
import { transformDate, getGroupListByFacID, getGroupName } from "../../../common/tools";
import { computed, defineComponent, onMounted } from "vue";
import { ref } from "vue";
import { message } from "ant-design-vue";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import ThresholdWarningList from "@/components/diagnosis/threshold-warning-list.vue";

import { langList } from "@/common/lang";

export default /*@__PURE__*/_defineComponent({
  __name: 'ThresholdAlarm',
  setup(__props) {

let language: any = ref('Chinese')
let langObj: any = ref({})
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let thresholdWarningListRef = ref<any>(null)

let factoryAll: any = ref([])
let factoryList: any = ref([])
let groupSearchList: any = ref([])
let machineSearchList: any = ref([])
let diagnosticManagerList: any = ref([])

let searchModel: any = ref({
  skip: 1,
  take: 20,
  total: 0
})
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
  showSizeChanger: true,
}));
let dataList: any = ref([])


let selectTag = ref(0)  //0: 待审核 1: 已审核

let loading: any = ref(false)
let bindMachineList: any = ref([])

const columns = [
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: "测点",
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: "当前状态",
    dataIndex: "condition",
    align: "center"
  },
  {
    title: "报警时间",
    dataIndex: "date",
    align: "center"
  },
  {
    title: "报警内容",
    dataIndex: "diagnosticNotes",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: langObj.value["是否前台显示"],
    dataIndex: "display",
    align: "center"
  },
  {
    title: "诊断经理",
    dataIndex: "diagnosticName",
    align: "center"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    align: "center"
  },
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "选择",
    dataIndex: "select",
    align: "center"
  },
  {
    title: "操作",
    dataIndex: "act",
    align: "center"
  },
]

let conditionList = [
  { label: '健康', value: 0 },
  { label: '可用上', value: 1 },
  { label: '可用下', value: 2 },
  { label: '警戒上', value: 3 },
  { label: '警戒中', value: 4 },
  { label: '警戒下', value: 5 },
  { label: '故障上', value: 6 },
  { label: '故障下', value: 7 },
  { label: ' ', value: -1 },
]

let statusList = [
  { label: '未审核', value: 0 },
  { label: '已审核', value: 3 },
]

let currentConditionList = [
  { label: "可用", value: 1, realValue: [1, 2] },
  { label: "警戒及以下", value: 2, realValue: [3, 4, 5, 6, 7] },
  { label: "不限", value: null, realValue: null },
]

let isCommit: any = ref(true)
let visibleEdit: any = ref(false)
let subLoading: any = ref(false)
let editForm: any = ref({})

let internalNotesList: any = ref([
  '开关机异常',
  '偶然冲击',
  '工况干扰',
  '数据清洗',
  '阈值问题',
  '通用指标不合理',
  '通用指标有问题',
  '专业指标不合理',
  '转频提取错误',
  '特征提取错误',
  '信号处理',
  '硬件采集问题',
  '硬件位置异常',
  '程序错误',
])

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

let addIString: any = ref('')
let addIVisible = ref(false)  //新增诊断判断内部备注

let viewForm: any = ref({})
let visibleView: any = ref(false)
const viewColumns = [
  {
    title: "项目",
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: "测点",
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "condition",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    align: "center"
  },
  {
    title: "是否前台显示",
    dataIndex: "display",
    align: "center"
  },

]
const filterOption = (input: string, option: any) => {

return option.factoryName.indexOf(input) >= 0;
};
const filterOption2 = (input: string, option: any) => {

return option.name.indexOf(input) >= 0;
};
const filterOption3 = (input: string, option: any) => {

return option.machineName.indexOf(input) >= 0;
};
let switchList = [
  { label: '是', value: true },
  { label: '否', value: false },
]

const dates = ref<any>();
const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return record.indexMD % 2 === 0 ? "odd-row" : "even-row";
};

onMounted(() => {
  init()
})

const init = () => {
  getBindMachineList()
  getManagers()
  getFactoryList()
  search()
}

// 获取绑定的设备
const getBindMachineList = async () => {
  let result = await request.get("/api/principal/all")

  bindMachineList.value = result?.data || []

}

// 获取诊断/客户经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result && result.data) {
    factoryAll.value = result.data
    let parentIds = result.data.map((p: any) => p.parentId)
    parentIds = [...new Set(parentIds)]
    let list = result.data.filter((p: any) => !parentIds.includes(p._id))
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    list = list.filter((p: any) => memberInfo.factoryIds.includes(p._id))
    factoryList.value = list
  }
}

// 获取查询设备列表
const selectFactorySearch = async (ev: any) => {
  if (ev) {
    groupSearchList.value = getGroupListByFacID(ev)
  } else {
    groupSearchList.value = []
  }
  searchModel.value.groupId = null
  searchModel.value.machineId = null
}

// 选择查询区域
const selectGroupSearch = async (ev: any) => {
  if (ev) {
    const config = {
      params: {
        factoryId: searchModel.value.factoryId,
        groupId: searchModel.value.groupId,
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.get('/api/machines', config)
    if (result && result.data) {
      machineSearchList.value = result.data
    } else {
      machineSearchList.value = []
    }
  } else {
    machineSearchList.value = []
  }
}

const onCalendarChange = (val: any) => {
  dates.value = val;
};


const search = () => {
  searchModel.value.skip = 1
  if (selectTag.value === 2) {
    thresholdWarningListRef.value.search()
  } else {
    getData()
  }
}

// 获取列表数据
const getData = async () => {
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    searchModel.value.begin = transformDate(searchModel.value.date[0])
    searchModel.value.end = transformDate(searchModel.value.date[1])
  } else {
    searchModel.value.begin = ''
    searchModel.value.end = ''
  }

  const config = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      factoryId: searchModel.value.factoryId,
      machineId: searchModel.value.machineId,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      sorter: searchModel.value.sorter,
      diagnosticManager: searchModel.value.diagnosticManager,
      type: 4,
      status: selectTag.value === 0 ? 0 : 3,
    } as any,
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = currentConditionList.find((p: any) => p.value === searchModel.value.condition)?.realValue || []
  }

  let result = await request.get('/api/workflows/info', config)

  if (result && result.data) {
    dataList.value = result.data.list
    let indexMD = 0
    let dataMD = ''
    dataList.value.map((d: any) => {
      d.groupName = getGroupName(d.groupId)
      let md = d.machineId + d.date
      if (dataMD === md) {
        d.indexMD = indexMD
      } else {
        indexMD++
        d.indexMD = indexMD
        dataMD = md
      }
    })
    // searchModel.value.skip = 1
    searchModel.value.total = result.data.total
  }


}

const handleTableChange = (ev: any, a: any, sorter: any) => {
  searchModel.value = {
    ...searchModel.value,
    skip: ev.current,
    take: ev.pageSize,
  }
  if (sorter.order && sorter.field) {
    searchModel.value.sorter = sorter.order === 'descend' ? false : true
  }
  getData()
}


const tagSelect = (ev: any) => {
  searchModel.value.skip = 1
  selectTag.value = ev
  if (selectTag.value == 0 || selectTag.value == 1) {
    search()
  }
}


// 选择验证
const verifySelect = (ev: any) => {
  if (ev.isClick) {
    let list = dataList.value.filter((p: any) => p.isClick)
    let machineIds = [...new Set(list.map((p: any) => p.machineId))]
    if (machineIds?.length > 1) {
      isCommit.value = true
      message.warning('请选择相同设备的数据！')
      return
    }
    if (!list?.length) {
      isCommit.value = true
      return
    }

    list.map((d: any) => {
      let list1 = dataList.value.filter((p: any) => p.date === d.date && p.machineId === d.machineId)
      list1.map((d: any) => { d.isClick = true })
    })
  }

  let list2 = dataList.value.filter((p: any) => p.isClick)

  for (let item of list2) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择严重程度！')
      return
    }
  }
  isCommit.value = false
}

// 选择严重程度
const verifySure = () => {
  let list = dataList.value.filter((p: any) => p.isClick)

  for (let item of list) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择严重程度！')
      return
    }
  }
  isCommit.value = false
}

// 确认提交
const sure = () => {
  let list = dataList.value.filter((p: any) => p.isClick)
  let diagnosticNotes: any = []
  list.map((d: any) => {
    if (d.diagnosticNotes) {
      diagnosticNotes.push(d.diagnosticNotes)
    }
  })
  editForm.value.diagnosticNotes = diagnosticNotes.join(';')
  editForm.value.display = false
  visibleEdit.value = true
}

// 添加诊断判断内部备注
const addinternalNote = () => {
  addIString.value = ''
  addIVisible.value = true
}

const submitAddInternalNote = () => {
  internalNotesList.value.push(addIString.value)
  editForm.value.internalNotes = addIString.value
  addIVisible.value = false
}

// 保存任务处理
const editSubmit = async () => {
  subLoading.value = true
  let list = JSON.parse(JSON.stringify(dataList.value.filter((p: any) => p.isClick)))
  list.map((d: any) => {
    delete d.isClick
    d.status = 3
  })

  let submitForm = {
    display: editForm.value.display,
    diagnosticNotes: editForm.value.diagnosticNotes,
    internalNotes: editForm.value.internalNotes,
    workflowInfos: list,
  }

  let result = await request.put('/api/workflows/threshold/info', submitForm)
  subLoading.value = false

  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visibleEdit.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('操作失败, 请联系管理员!')
  }

}


// 取消选择
const cancelClick = () => {
  dataList.value.map((d: any) => { d.isClick = false })
  isCommit.value = true
}

// 删除
const deleteOrder = async (ev: any) => {
  let config = {
    params: { id: ev.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.delete('/api/workflows/info', config)
  if (result && result.data) {
    setTimeout(() => {
      search()
      message.success('删除成功')
    }, 1000);
  } else {
    message.success('删除失败')
  }
}

// 查看
const viewDetail = async (ev: any) => {
  viewForm.value = ref({
    list: [{
      factoryId: '',
      machineName: '',
      sensorPlace: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: null,
    }],
    diagnosticNotes: '',
    internalNotes: '',
    reportId: '',
  })
  visibleView.value = true

  const config = {
    params: {
      id: ev.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/workflows/infos', config)
  if (result && result.data) {
    let info = result.data
    viewForm.value = {
      list: [{
        factoryId: ev.factoryId,
        machineName: info.machineName,
        sensorPlace: info.sensorPlace,
        scoreName: info.scoreName,
        date: info.date,
        condition: info.condition,
        expertDiagnosisResult: info.expertDiagnosisResult,
        expertProcess: info.expertProcess,
        display: info.display,
        groupName: ev.groupName,
        status: ev.status
      }],
      diagnosticNotes: info.diagnosticNotes,
      internalNotes: info.internalNotes,
    }
  }

}

const getDiagnosticName = (ev: any) => {
  let name = ''
  let bmInfo = bindMachineList.value.find((p: any) => p.machineId === ev)
  if (bmInfo) {
    let user = diagnosticManagerList.value.find((p: any) => p._id === bmInfo.diagnosticManager)
    name = user?.nickName || ''
  }
  return name
}


return (_ctx: any,_cache: any) => {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, { layout: "inline" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "项目" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _unref(searchModel).factoryId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).factoryId) = $event)),
              style: {"width":"200px"},
              dropdownMatchSelectWidth: false,
              onChange: selectFactorySearch,
              options: _unref(factoryList),
              "field-names": { label: 'factoryName', value: '_id', options: 'factoryList' },
              "filter-option": filterOption,
              "show-search": "",
              allowClear: "",
              placeholder: "请选择项目"
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "区域" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _unref(searchModel).groupId,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
              style: {"width":"200px"},
              dropdownMatchSelectWidth: false,
              options: _unref(groupSearchList),
              "field-names": { label: 'name', value: 'id', options: 'groupSearchList' },
              "filter-option": filterOption2,
              "show-search": "",
              onChange: selectGroupSearch,
              allowClear: "",
              placeholder: "请选择"
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "设备" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _unref(searchModel).machineId,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
              style: {"width":"200px"},
              dropdownMatchSelectWidth: false,
              options: _unref(machineSearchList),
              "field-names": { label: 'machineName', value: 'id', options: 'machineSearchList' },
              "filter-option": filterOption3,
              "show-search": "",
              allowClear: "",
              placeholder: "请选择设备"
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "发生时间" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_range_picker, {
              value: _unref(searchModel).date,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).date) = $event)),
              style: {"width":"400px"},
              "show-time": "",
              format: "YYYY/MM/DD HH:mm:ss",
              allowClear: "",
              "disabled-date": disabledDate,
              onCalendarChange: onCalendarChange
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "严重程度" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              allowClear: "",
              value: _unref(searchModel).condition,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).condition) = $event)),
              style: {"width":"160px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentConditionList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "诊断经理" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              allowClear: "",
              showSearch: true,
              value: _unref(searchModel).diagnosticManager,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(searchModel).diagnosticManager) = $event)),
              style: {"width":"180px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(diagnosticManagerList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item._id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.nickName), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (search()))
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("查询")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: {"margin-left":"50px","font-size":"16px","padding":"0 10px 5px 10px","cursor":"pointer"},
        class: _normalizeClass({ 'select-tag': _unref(selectTag) == 0 }),
        onClick: _cache[7] || (_cache[7] = ($event: any) => (tagSelect(0)))
      }, " 待审核 ", 2),
      _createElementVNode("div", {
        style: {"margin-left":"50px","font-size":"16px","padding":"0 10px 5px 10px","cursor":"pointer"},
        class: _normalizeClass({ 'select-tag': _unref(selectTag) == 1 }),
        onClick: _cache[8] || (_cache[8] = ($event: any) => (tagSelect(1)))
      }, " 已审核 ", 2),
      _createElementVNode("div", {
        style: {"margin-left":"50px","font-size":"16px","padding":"0 10px 5px 10px","cursor":"pointer"},
        class: _normalizeClass({ 'select-tag': _unref(selectTag) == 2 }),
        onClick: _cache[9] || (_cache[9] = ($event: any) => (tagSelect(2)))
      }, " 无需看护报警 ", 2),
      _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_button, {
          onClick: sure,
          disabled: _unref(isCommit),
          class: "btn-submit"
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode("确认提交")
          ])),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_a_button, {
          onClick: cancelClick,
          class: "ml-2 btn-reset"
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [
            _createTextVNode("取消选择")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_unref(selectTag) === 2)
        ? (_openBlock(), _createBlock(ThresholdWarningList, {
            key: 0,
            ref_key: "thresholdWarningListRef",
            ref: thresholdWarningListRef,
            query: _unref(searchModel)
          }, null, 8, ["query"]))
        : _createCommentVNode("", true),
      (_unref(selectTag) === 1 || _unref(selectTag) === 0)
        ? (_openBlock(), _createBlock(_component_a_table, {
            key: 1,
            bordered: "",
            emptyText: '暂无数据',
            columns: columns,
            "data-source": _unref(dataList),
            pagination: pagination.value,
            loading: _unref(loading),
            onChange: handleTableChange,
            size: "small",
            "row-class-name": getRowClassName
          }, {
            bodyCell: _withCtx(({ column, text, record, index }) => [
              (column.dataIndex === 'factoryName')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(factoryAll).find(p => p._id ===
              record.factoryId)?.factoryName), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'condition')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_unref(conditionList).find(p => p.value === text)?.label), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'expertDiagnosisResult')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (record.status !== 3)
                      ? (_openBlock(), _createBlock(_component_a_select, {
                          key: 0,
                          value: record.expertDiagnosisResult,
                          "onUpdate:value": ($event: any) => ((record.expertDiagnosisResult) = $event),
                          style: {"width":"100%"},
                          options: _unref(conditionList),
                          onChange: _cache[10] || (_cache[10] = ($event: any) => (verifySure()))
                        }, null, 8, ["value", "onUpdate:value", "options"]))
                      : _createCommentVNode("", true),
                    (record.status === 3)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(conditionList).find(p => p.value ===
                record.expertDiagnosisResult)?.label), 1))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'status')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createTextVNode(_toDisplayString(_unref(statusList).find(p => p.value === record.status)?.label), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'display')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    (record.display == true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(langObj)["是"]), 1))
                      : _createCommentVNode("", true),
                    (record.display == false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(langObj)["否"]), 1))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'diagnosticName')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(getDiagnosticName(record.machineId)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'select')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                    (!record.cancel && record.status !== 3)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          type: "checkbox",
                          style: {"width":"16px","height":"16px","cursor":"pointer"},
                          "onUpdate:modelValue": ($event: any) => ((record.isClick) = $event),
                          onChange: ($event: any) => (verifySelect(record))
                        }, null, 40, _hoisted_9)), [
                          [_vModelCheckbox, record.isClick]
                        ])
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'act')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (viewDetail(record))
                    }, "查看", 8, _hoisted_10),
                    _createVNode(_component_a_divider, { type: "vertical" }),
                    _createVNode(_component_a_popconfirm, {
                      title: "确认删除",
                      onConfirm: ($event: any) => (deleteOrder(record))
                    }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createElementVNode("a", null, "删除", -1)
                      ])),
                      _: 2
                    }, 1032, ["onConfirm"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "pagination", "loading"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_modal, {
      width: 1300,
      visible: _unref(visibleEdit),
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => (_isRef(visibleEdit) ? (visibleEdit).value = $event : visibleEdit = $event)),
      title: "诊断处理",
      footer: null,
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          style: {"margin-top":"20px"},
          model: _unref(editForm),
          name: "basic",
          "label-col": { span: 4 },
          "wrapper-col": { span: 20 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "诊断判断内部备注",
              name: "internalNotes",
              rules: [{ required: true, message: '请填写' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(editForm).internalNotes,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(editForm).internalNotes) = $event)),
                  style: {"width":"100%"},
                  options: _unref(internalNotesList).map(item => ({ value: item }))
                }, {
                  dropdownRender: _withCtx(({ menuNode: menu }) => [
                    _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                    _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                    _createElementVNode("div", {
                      style: {"padding":"4px 8px","cursor":"pointer"},
                      onMousedown: _cache[11] || (_cache[11] = e => e.preventDefault()),
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (addinternalNote()))
                    }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_plus_outlined),
                        _cache[27] || (_cache[27] = _createTextVNode(" 添加 "))
                      ])
                    ], 32)
                  ]),
                  _: 1
                }, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "诊断判断备注",
              name: "diagnosticNotes",
              rules: [{ required: true, message: '请填写诊断判断备注' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _unref(editForm).diagnosticNotes,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(editForm).diagnosticNotes) = $event)),
                  placeholder: "",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "是否前台显示",
              name: "display"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _unref(editForm).display,
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(editForm).display) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: true }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createTextVNode("是")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: false }, {
                      default: _withCtx(() => _cache[29] || (_cache[29] = [
                        _createTextVNode("否")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: editSubmit,
                  loading: _unref(subLoading)
                }, {
                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createTextVNode("保存任务处理")
                  ])),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: 1300,
      visible: _unref(visibleView),
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => (_isRef(visibleView) ? (visibleView).value = $event : visibleView = $event)),
      title: "查看",
      footer: null,
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          ref: "viewTable",
          columns: viewColumns,
          "data-source": _unref(viewForm).list,
          pagination: false
        }, {
          bodyCell: _withCtx(({ column, record, text }) => [
            (column.dataIndex === 'factoryId')
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(factoryAll).find(p => p._id ===
              record.factoryId)?.factoryName), 1))
              : _createCommentVNode("", true),
            (column.dataIndex === 'condition')
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(conditionList).find(p => p.value === text)?.label), 1))
              : _createCommentVNode("", true),
            (column.dataIndex === 'expertDiagnosisResult')
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(conditionList).find(p => p.value === text)?.label), 1))
              : _createCommentVNode("", true),
            (column.dataIndex === 'display')
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_unref(switchList).find(p => p.value === text)?.label), 1))
              : _createCommentVNode("", true),
            (column.dataIndex === 'status')
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(statusList).find(p => p.value === text)?.label), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data-source"]),
        _createVNode(_component_a_form, {
          style: {"margin-top":"20px"},
          model: _unref(viewForm),
          name: "basic",
          "label-col": { span: 4 },
          "wrapper-col": { span: 20 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "诊断判断内部备注",
              name: "internalNotes"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  readonly: "",
                  value: _unref(viewForm).internalNotes,
                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(viewForm).internalNotes) = $event)),
                  placeholder: " ",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "诊断判断备注",
              name: "diagnosticNotes"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  readonly: "",
                  value: _unref(viewForm).diagnosticNotes,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(viewForm).diagnosticNotes) = $event)),
                  placeholder: " ",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: 400,
      maskClosable: false,
      visible: _unref(addIVisible),
      "onUpdate:visible": _cache[21] || (_cache[21] = ($event: any) => (_isRef(addIVisible) ? (addIVisible).value = $event : addIVisible = $event)),
      title: "新增诊断判断内部备注",
      onOk: submitAddInternalNote
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input, {
          style: {"width":"350px"},
          value: _unref(addIString),
          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => (_isRef(addIString) ? (addIString).value = $event : addIString = $event))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})