import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { style: {"color":"#fff"} }
const _hoisted_2 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_3 = { class: "d3d-main" }
const _hoisted_4 = {
  id: "container",
  class: "rotateThemeBg",
  style: {"padding":"0","position":"relative"}
}
const _hoisted_5 = { style: {"position":"fixed","z-index":"1","margin-left":"10px"} }
const _hoisted_6 = { class: "gh3d" }
const _hoisted_7 = {
  id: "repart",
  class: "lipButton"
}
const _hoisted_8 = { class: "d3d-tips" }
const _hoisted_9 = {
  class: "d3_bq",
  style: {"width":"225px","height":"50px","border-color":"#0D53B7","box-shadow":"#0D53B7"}
}
const _hoisted_10 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_11 = { class: "w-full flex" }
const _hoisted_12 = { class: "m_r" }
const _hoisted_13 = { class: "list" }
const _hoisted_14 = { class: "item" }
const _hoisted_15 = {
  style: {"line-height":"30px"},
  class: "me-1"
}
const _hoisted_16 = { class: "lleft" }
const _hoisted_17 = { class: "ms-2" }
const _hoisted_18 = { class: "ms-1 me-1" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "w-full flex justify-center mt-5" }

import { onMounted, reactive, ref, watch } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import { v4 as uuidv4 } from "uuid";
import { TresCanvas } from '@tresjs/core'
import { OrbitControls, Html } from '@tresjs/cientos'

import type { UploadProps } from 'ant-design-vue';
import { message } from "ant-design-vue";

import d3dModel from './d3d-model.vue'

interface Props {
  machineId: string
}

type MarkLinePosition = "center" | "left" | "right"


export default /*@__PURE__*/_defineComponent({
  __name: 'd3d-edit',
  props: {
    machineId: { default: '' }
  },
  emits: ['success', 'close'],
  setup(__props: any, { emit: __emit }) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

const emits = __emit

const props = __props

let showCanvas = ref(false)
onMounted(async () => {
  // 延迟显示canvas, 否则modal中模型的context-menu事件不会触发
  // 怀疑跟canvas的渲染时计算画布size有关, 待modal显示后再计算canvas能确保成功
  setTimeout(() => {
    showCanvas.value = true
  }, 100);
})

const htmlState = reactive({
  wrapperClass: 'd3d-mark-box',
  as: 'div',
  sprite: true,
  transform: true,
  distanceFactor: 5,
  center: true
})

let customRequest3D = async (options: any) => {
  const { onSuccess, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    }
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    modelPath.value = "";
    setTimeout(() => {
      modelPath.value = res.data;
    }, 100);
    markPoints.value = [];
  }
};

const beforeUpload: UploadProps['beforeUpload'] = file => {
  if (file.size / 1024 / 1024 > 100) {
    message.error('文件大小不能超过100M');
    return false
  }
  return true;
};

let config_3d: any = ref({
  d3dMarks: [],
});
let modelSize = ref(2);

let modelPath = ref<string>("")

let timer2: any = null
watch(() => modelSize.value, (val) => {
  if (modelSize.value > 0) {
    showCanvas.value = false
    if (!timer2) {
      timer2 = setTimeout(() => {
        showCanvas.value = true
        timer2 = null
      }, 10);
    }
  }
}, { immediate: true })

class MarkPoint {
  id: number;
  x: number;
  y: number;
  z: number;
  linePosition: string;
  bind: string = ""; // sensorId
  label: string = "标签";

  constructor(id: number, x: number, y: number, z: number, linePosition: MarkLinePosition = "center") {
    this.id = id;
    this.x = x;
    this.y = y;
    this.z = z;
    this.linePosition = linePosition;
  }
}

const linePositionOptions = [
  { label: "中间", value: "center" },
  { label: "左侧", value: "left" },
  { label: "右侧", value: "right" },
]

let markPoints = ref<MarkPoint[]>([]);

let timer: any = null
function debounce(fn: any, delay = 1000) {
  if (timer != null) {
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(fn, delay)
}

const onMarkPoint = (ev: any) => {
  if (!ev) return;

  // 每次变更模型文件，都会导致多绑定一个右键事件（疑似tres的 bug）,所以这里做了一个防抖处理
  debounce(() => {
    let x = ev.point.x;
    let y = ev.point.y;
    let z = ev.point.z;
    let linePosition: MarkLinePosition = "center";
    if (markPoints.value.length % 3 === 0) {
      linePosition = "left"
    } else if (markPoints.value.length % 3 === 2) {
      linePosition = "right"
    }
    markPoints.value.push(new MarkPoint(markPoints.value.length + 1, x, y, z, linePosition));
  }, 200)
}

const load3d = async () => {
  config_3d.value = { d3dMarks: [] };
  let config: any = {
    params: {
      machineId: props.machineId
    },
    headers: {
      requestId: uuidv4()
    },
  };
  let result = await request.get("/api/machine-3D", config);

  config_3d.value = result.data;

  if (result?.data && result.data.type === "3d") {
    config_3d.value = result.data;
    modelPath.value = result.data.picture;
    markPoints.value = result.data.d3dMarks || [];
    if (config_3d.value.d3d?.modelSize) {
      modelSize.value = config_3d.value.d3d.modelSize
    }
  } else {
    modelPath.value = ""
    markPoints.value = []
  }
}

const save3D = async () => {
  if (markPoints.value.filter((t: any) => !t.bind).length > 1) {
    message.warning("请设置每个标点内容");
    return;
  }

  var postData = {
    id: config_3d.value?.id,
    type: "3d",
    machineId: props.machineId,
    picture: modelPath.value,
    d3dMarks: markPoints.value,
    d3d: {
      modelSize: modelSize.value
    }
  };
  // 保存
  if (postData.id) {
    var result = await request.put("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      emits('success')
    }
  } else {
    result = await request.post("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      emits('success')
    }
  }
}

const deleteTag = (index: any) => {
  let tempArr = []
  let alreadyHit = false
  for (let i = 0; i < markPoints.value.length; i++) {
    if (i === index) {
      alreadyHit = true
    }

    // 不能从中间index删除元素，只能删除最后一个元素, 将index后面的元素值复制给前一个元素，保留index对象的引用
    // 否则标签顺序会出错

    if (alreadyHit) {
      if (i !== markPoints.value.length - 1) {
        markPoints.value[i + 1].id = markPoints.value[i + 1].id - 1
        Object.assign(markPoints.value[i], markPoints.value[i + 1])

        tempArr.push(markPoints.value[i])
      }
    } else {
      tempArr.push(markPoints.value[i])
    }
  }

  markPoints.value = tempArr
};

const onChangeSensor = (sensorId: string, point: MarkPoint) => {
  let sensor = sensorOptions.value.find((t: any) => t.value === sensorId);
  point.label = sensor.label;
}

let sensorOptions = ref<any[]>([]);
const getSensors = async () => {
  sensorOptions.value = []
  if (!props.machineId) return
  let config: any = {
    params: {
      machineId: props.machineId
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    // 总貌图选项
    for (let sensor of result.data) {
      sensorOptions.value.push({
        label: sensor.sensorPlace,
        value: sensor.id
      });
    }
  }
};

const onClickClose = () => {
  emits('close');
}

watch(() => props.machineId, async () => {
  if (props.machineId) {
    await getSensors();
    await load3d()
  } else {
    sensorOptions.value = []
    markPoints.value = []
    modelPath.value = ""
    config_3d.value = { d3dMarks: [] };
  }
}, { immediate: true })


return (_ctx: any,_cache: any) => {
  const _component_SwapOutlined = _resolveComponent("SwapOutlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_TresPerspectiveCamera = _resolveComponent("TresPerspectiveCamera")!
  const _component_TresAmbientLight = _resolveComponent("TresAmbientLight")!
  const _component_TresDirectionalLight = _resolveComponent("TresDirectionalLight")!
  const _component_TresGridHelper = _resolveComponent("TresGridHelper")!
  const _component_TresMesh = _resolveComponent("TresMesh")!
  const _component_TresGroup = _resolveComponent("TresGroup")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full flex items-center" }, [
      _createElementVNode("div", { class: "flex-1" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(langObj)['总貌图']), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_a_upload, {
              "custom-request": _unref(customRequest3D),
              "before-upload": beforeUpload,
              "auto-upload": false,
              accept: ".glb,.gltf",
              "max-count": 1
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_SwapOutlined, { style: {"font-size":"22px"} })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_unref(langObj)['更换3D图']) + "(.glb/.gltf) ", 1)
                ])
              ]),
              _: 1
            }, 8, ["custom-request"])
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)['左键按住可拖动，右键添加标记']), 1)
        ]),
        (_unref(showCanvas) && _unref(modelPath))
          ? (_openBlock(), _createBlock(_unref(TresCanvas), { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_TresPerspectiveCamera),
                _createVNode(_unref(OrbitControls)),
                _createVNode(_component_TresAmbientLight, {
                  color: "#ffffff",
                  intensity: 1
                }),
                _createVNode(_component_TresDirectionalLight, {
                  color: "#ffffff",
                  position: [300, 300, 250],
                  intensity: 6
                }),
                _createVNode(_component_TresGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_TresGridHelper, { scale: 3 }),
                    (_openBlock(), _createBlock(_Suspense, null, {
                      default: _withCtx(() => [
                        _createVNode(d3dModel, {
                          "model-path": _unref(modelPath),
                          "model-size": _unref(modelSize),
                          onContextMenu: onMarkPoint
                        }, null, 8, ["model-path", "model-size"])
                      ]),
                      _: 1
                    })),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(markPoints), (point, index) => {
                      return (_openBlock(), _createBlock(_component_TresMesh, { key: index }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Html), _mergeProps({ ref_for: true }, htmlState, {
                            position: [point.x, point.y, point.z]
                          }), {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["d3d-mark-line text-white", { 'd3d-mark-line-left': point.linePosition === 'left', 'd3d-mark-line-right': point.linePosition === 'right' }])
                              }, null, 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["d3d-mark-label", { 'd3d-mark-label-left': point.linePosition === 'left', 'd3d-mark-label-right': point.linePosition === 'right' }])
                              }, [
                                _createElementVNode("div", _hoisted_9, [
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("div", _hoisted_11, [
                                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                      _createElementVNode("div", null, _toDisplayString(point.id) + ": " + _toDisplayString(point.label), 1),
                                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                                    ])
                                  ])
                                ])
                              ], 2)
                            ]),
                            _: 2
                          }, 1040, ["position"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(langObj)["模型尺寸"] || "模型尺寸") + ": ", 1),
            _createVNode(_component_a_input_number, {
              id: "inputNumber",
              value: _unref(modelSize),
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(modelSize) ? (modelSize).value = $event : modelSize = $event)),
              min: 0.1,
              step: 0.5,
              max: 10
            }, null, 8, ["value"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(markPoints), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item",
              key: index
            }, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(item.id), 1),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_a_select, {
                  value: item.bind,
                  "onUpdate:value": ($event: any) => ((item.bind) = $event),
                  onChange: ($event: any) => (onChangeSensor($event, item)),
                  style: {"width":"200px"},
                  options: _unref(sensorOptions)
                }, null, 8, ["value", "onUpdate:value", "onChange", "options"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_a_select, {
                  value: item.linePosition,
                  "onUpdate:value": ($event: any) => ((item.linePosition) = $event),
                  style: {"width":"100px"},
                  options: linePositionOptions
                }, null, 8, ["value", "onUpdate:value"])
              ]),
              _createElementVNode("div", {
                class: "licon",
                onClick: ($event: any) => (deleteTag(index))
              }, [
                _createVNode(_component_DeleteOutlined)
              ], 8, _hoisted_19)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", {
        onClick: onClickClose,
        class: "btn-default py-1 px-6"
      }, _toDisplayString(_unref(langObj)['关闭']), 1),
      _createElementVNode("div", {
        onClick: save3D,
        class: "btn-default py-1 px-6 ms-6"
      }, _toDisplayString(_unref(langObj)['保存']), 1)
    ])
  ]))
}
}

})