<template>
  <div class="chat-input-wrapper">
    <div class="chat-toolbar-container">
      <a-button type="link" :size="'small'" @click="startNewChat">
        <plus-outlined />
        {{ getLanguage('新建对话') }}
      </a-button>
    </div>
    <div class="input-container" :class="{ disabled }">
      <textarea
                ref="messageTextarea"
                class="message-input"
                v-model="messageText"
                rows="1"
                @keydown="handleKeyDown"
                @input="autoResize"
                :disabled="disabled"
                :placeholder="getLanguage('请输入问题，Ctrl+Enter 换行，Enter 发送')">
      </textarea>
      <button @click="sendMessage" class="send-button text-primary" :disabled="disabled">
        <send-outlined rotate="-45" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { getLanguage } from "../../../common/translate";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['send', 'newChat']);

const messageText = ref<string>('');
const messageTextarea = ref<HTMLTextAreaElement | null>(null);

const startNewChat = (): void => {
  emit('newChat');
};

const sendMessage = (): void => {
  if (props.disabled || messageText.value.trim() === '') return;

  emit('send', messageText.value);
  messageText.value = '';

  // Reset textarea height after sending message
  if (messageTextarea.value) {
    messageTextarea.value.style.height = 'auto';
  }
};

const handleKeyDown = (event: KeyboardEvent): void => {
  if (props.disabled) return;

  // Add line break with Ctrl+Enter
  if (event.key === 'Enter' && event.ctrlKey) {
    event.preventDefault();
    const cursorPos = (event.target as HTMLTextAreaElement).selectionStart;
    const textBeforeCursor = messageText.value.substring(0, cursorPos);
    const textAfterCursor = messageText.value.substring(cursorPos);
    messageText.value = textBeforeCursor + '\n' + textAfterCursor;

    autoResize();
    // Set cursor position after the new line
    setTimeout(() => {
      const textArea = event.target as HTMLTextAreaElement;
      textArea.selectionStart = textArea.selectionEnd = cursorPos + 1;
    }, 0);
  }
  // Send message with plain Enter key
  else if (event.key === 'Enter' && !event.ctrlKey) {
    event.preventDefault();
    sendMessage();
  }
};

const autoResize = (): void => {
  if (!messageTextarea.value) return;

  // Reset height to auto first to get accurate scrollHeight
  messageTextarea.value.style.height = 'auto';

  let lines = messageText.value.split('\n').length;
  let height = 40 + 21 * (lines - 1);

  // Set new height based on content
  messageTextarea.value.style.height = `${height}px`;
};

onMounted((): void => {
  // Initialize textarea height
  autoResize();
});
</script>

<style scoped lang="less">
.chat-input-wrapper {
  display: flex;
  flex-direction: column;
}

.chat-toolbar-container {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0;
}

.input-container {
  display: flex;
  background-color: #041767;
  border-radius: 8px;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    
    .message-input, .send-button {
      cursor: not-allowed;
    }
  }
}

.message-input {
  flex-grow: 1;
  border-radius: 8px;
  padding: 8px 10px;
  background-color: transparent;
  outline: none;
  resize: none;
  min-height: 40px;
  /* Single line height */
  overflow-y: auto;
  line-height: 1.5;
  transition: height 0.1s ease;
  color: inherit;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &:disabled {
    cursor: not-allowed;
  }
}

.send-button {
  border: none;
  font-size: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
</style>
