import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat-container text-primary" }

import { ref, onMounted, watch, onUnmounted } from 'vue';
import ChatInput from './chat-input.vue';
import ChatMessages from './chat-messages.vue';
import request from '@/common/request';
import { ChatMessage, ChatHistoryItem } from './chat.types';
import { message } from 'ant-design-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'chat-panel-common',
  props: {
    chat: {}
  },
  emits: ['update-chat', 'new-chat'],
  setup(__props: any, { emit: __emit }) {

function debounce<T extends (...args: any[]) => any>(fn: T, delay: number) {
  let timeoutId: number | null = null;
  return function (this: any, ...args: Parameters<T>) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
      timeoutId = null;
    }, delay);
  };
}

let aiProfile: any;

const props = __props;

let currentChat = props.chat;

const emit = __emit;

const messages = ref<ChatMessage[]>([]);

const initMessages = async () => {
  messages.value = [
    { type: 'bot', content: aiProfile.prologue as string, time: null }
  ];
};

const handleChatHistoryChange = debounce(async (newChat: ChatHistoryItem | null) => {
  if (!aiProfile) {
    await getAiProfile();
  }

  if (!newChat) {
    // Handle case where chat history is cleared
    messages.value = [];
    return;
  }

  if (newChat?.id !== currentChat?.id) {
    // Handle chat history change, e.g., load messages for the new chat
    // Switched chat;

    currentChat = newChat;

    if (!newChat.chatId) {
      initMessages();
      return;
    }

    let res = await request.get(`/api/p-chat/app/chat/${newChat.chatId}/chat_record/`)
    if (res?.data?.length) {
      let array: ChatMessage[] = [];
      array.push({ type: 'bot', content: aiProfile.prologue as string, time: null })
      for (let item of res.data) {
        array.push({ type: 'user', content: item.problem_text as string, time: new Date(item.create_time) })
        array.push({ type: 'bot', content: item.answer_text as string, time: new Date(item.update_time) })
        messages.value = array
      }
    } else {
      initMessages()
    }
  }
}, 300);

// Watch for changes in chatHistory prop with debounce
watch(() => props.chat, (newItem) => {
  handleChatHistoryChange(newItem as ChatHistoryItem);
}, { deep: true });

const onNewChat = async () => {
  //initMessages();
  emit('new-chat');
};

let loadingDots = '...'
let loadingTimer: number | null = null;

const startLoadingAnimation = () => {
  let dotsCount = 0
  loadingTimer = window.setInterval(() => {
    dotsCount = ((dotsCount + 1) % 4);
    loadingDots = '.'.repeat(dotsCount);
    let msg = messages.value[messages.value.length - 1];
    if (msg?.isAwnserPlaceholder) {
      msg.content = `回答中${loadingDots}`
    }
  }, 500);
};

const stopLoadingAnimation = () => {
  if (loadingTimer) {
    clearInterval(loadingTimer);
    loadingTimer = null;
  }
  let msg = messages.value[messages.value.length - 1];
  if (msg?.isAwnserPlaceholder) {
    messages.value.pop();
  }
};

const onMessageSend = async (messageContent: string) => {
  messageContent = messageContent?.trim();
  if (!messageContent) {
    return;
  }

  const sentMessage: ChatMessage = {
    type: 'user',
    content: messageContent,
    time: new Date()
  };

  messages.value.push(sentMessage);

  startLoadingAnimation();
  const loadingMessage: ChatMessage = {
    type: 'bot',
    content: `回答中${loadingDots}`,
    time: new Date(),
    isAwnserPlaceholder: true
  };
  messages.value.push(loadingMessage);

  let chatId = currentChat?.chatId
  let isNewChat = false;
  if (!chatId) {
    // 新对话
    let res = await request.get('/api/p-chat/app/chat/open');
    if (!res?.data) {
      console.error('无法创建新对话');
      // Remove loading message if error
      stopLoadingAnimation();
      return;
    }
    chatId = res.data
    isNewChat = true;
  }

  try {
    const response = await request.post(`/api/p-chat/chat_message/${chatId}`, {
      message: messageContent,
      re_chat: false
    }, { responseType: 'stream', adapter: 'fetch' });

    const reader = response.data.getReader();
    const decoder = new TextDecoder();

    let awnser = '';
    const startTime = Date.now();
    const timeoutLimit = 2 * 60 * 1000; // in milliseconds

    while (true) {
      if (Date.now() - startTime > timeoutLimit) {
        console.warn('Response streaming timed out after 3 minutes');
        break;
      }

      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split('\n').filter(Boolean);

      for (const line of lines) {
        try {
          const tempData = JSON.parse(line.replace("data:", ""));
          awnser += tempData.content;
          //console.log("stream", tempData);
        } catch (error) {
          console.error('Error parsing update:', error);
        }
      }
    }

    stopLoadingAnimation();

    // Create message with empty content initially
    const awnserMessage: ChatMessage = {
      type: 'bot',
      content: '',
      time: new Date()
    };
    messages.value.push(awnserMessage);

    // Typewriter effect with improved reactivity
    const fullAnswer = awnser;
    let currentIndex = 0;
    const typingSpeed = 20; // milliseconds per character
    
    const typeNextChar = () => {
      if (currentIndex < fullAnswer.length) {
        // Use Vue's reactivity to ensure UI updates
        // Creating a new string forces Vue to detect the change
        messages.value = [...messages.value.slice(0, -1), {
          ...awnserMessage,
          content: fullAnswer.substring(0, currentIndex + 1)
        }];
        currentIndex++;
        setTimeout(typeNextChar, typingSpeed);
      }
    };
    
    typeNextChar(); // Start the typing animation

    if (isNewChat) {
      // 新建对话历史记录
      let abstract = messageContent;
      if (abstract.length > 10) {
        abstract = abstract.substring(0, 10) + "..."
      }
      let data = {
        chatId: chatId,
        abstract: abstract
      };
      request.post(`/api/chat`, data).then((res) => {
        if (currentChat && res.data) {
          currentChat.abstract = abstract
          currentChat.id = res.data.id!;
          currentChat.chatId = chatId!;
          emit('update-chat', currentChat);
        }
      })
    }
  } catch (error) {
    stopLoadingAnimation();
    throw error;
  } finally {
    stopLoadingAnimation();
  }
};

const getAiProfile = async () => {
  let res = await request.get('/api/p-chat/profile')
  aiProfile = res.data
}

onMounted(async () => {
  //await getAiProfile();
});

onUnmounted(() => {
  stopLoadingAnimation();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ChatMessages, { messages: messages.value }, null, 8, ["messages"]),
    _createVNode(ChatInput, {
      onSend: onMessageSend,
      onNewChat: onNewChat
    })
  ]))
}
}

})