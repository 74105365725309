import request from './request';
import { setMemberStore, memberStore } from './state';
import { inject } from 'vue'
import { useRoute } from "vue-router";
export function authGuard(to: any, from: any, next: any) {
    let member: any = memberStore.member;
    const openOAuthU = getQueryVariable('openOAuth')
    const openOAuth = openOAuthU || inject('openOAuth');
    const client_id = inject('client_id');
    const OAuthUrl = inject('OAuthUrl');

    // 尝试从 URL 中获取 token
    let token = to.query.token
    let cachedToken = sessionStorage.getItem('token') || localStorage.getItem('token') || "";
    if (token && token !== cachedToken) {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("token", token);
        sessionStorage.setItem("token", token);
        member = null;
        setMemberStore(null)
    } else {
        token = cachedToken;
    }

    if (member || token) {
        if (!member) {
            request.get('/api/members/info').then((res) => {
                if (res && res.data) {
                    setMemberStore(res.data);
                    localStorage.setItem("memberInfo", JSON.stringify(res.data));
                    if (res.data?.isRegistered && !res.data.factoryId) {
                        // 注册的用户，没有创建工厂时，跳到登录页面，让他登录一下触发创建工厂
                        next('/login');
                        return
                    }

                    let redirectUri = to.query.redirect_uri;
                    if (redirectUri) {
                        redirectUri = decodeURIComponent(redirectUri)
                        window.location.href = redirectUri;
                        return;
                    }
                    next(to.path);
                } else {
                    var code = getQueryVariable('code')
                    if (openOAuth || code) {// OAuth2.0 授权模式
                        if (code) {
                            const currentUrl = window.location.origin + '/';
                            const config = {
                                params: { code: code, redirect_uri: currentUrl },
                                headers: {
                                },
                            };
                            // 验证code
                            request.get('/api/accesstoken', config).then((res) => {
                                if (res && res.data) {
                                    localStorage.setItem("token", res.data);
                                    sessionStorage.setItem("token", res.data);
                                    setMemberStore(null)
                                    next('/');
                                } else {
                                    next('/login');
                                }
                            })
                        } else {
                            // 获取当前 URL
                            const currentUrl = window.location.origin + '/';
                            // 对 URL 进行转义
                            const redirect_uri = encodeURIComponent(currentUrl);
                            window.location.href = OAuthUrl + '?client_id=' + client_id + "&response_type=code&redirect_uri=" + redirect_uri// 跳转登录授权
                            // next(); // 跳转登录
                        }
                    } else {
                        next('/login'); // 跳转登录
                    }
                }
            })
        } else {
            member.menuIds = member?.menus.map((t: any) => (t.id))
            member.factoryIds = member?.factories.map((t: any) => (t.id))
            next();
        }
    } else {
        var code = getQueryVariable('code')
        if (openOAuth || code) {// OAuth2.0 授权模式
            if (code) {
                const currentUrl = window.location.origin + '/';
                const config = {
                    params: { code: code, redirect_uri: currentUrl },
                    headers: {
                    },
                };
                // 验证code
                request.get('/api/accesstoken', config).then((res) => {
                    if (res) {
                        localStorage.setItem("token", res.data);
                        sessionStorage.setItem("token", res.data);
                        setMemberStore(null)
                        next('/');
                    } else {
                        next('/login');
                    }
                })
            } else {
                // 获取当前 URL
                const currentUrl = window.location.origin + '/';
                // 对 URL 进行转义
                const redirect_uri = encodeURIComponent(currentUrl);
                window.location.href = OAuthUrl + '?client_id=' + client_id + "&response_type=code&redirect_uri=" + redirect_uri// 跳转登录授权
                // next(); // 跳转登录
            }
        } else {
            next('/login'); // 跳转登录
        }

    }
}

function getQueryVariable(variable: any) {
    if (window.location.href.indexOf("?") < 0 || window.location.href.indexOf(variable) < 0) {
        return "";
    }
    //获取当前URL中的查询字符串部分（即问号后面的部分）
    var query = window.location.href.split('?')[1];
    //把查询字符串按照&符号分割成数组
    var vars = query.split("&");
    //遍历数组中的每个元素
    for (var i = 0; i < vars.length; i++) {
        //把当前元素按照=符号分割成键和值
        var pair = vars[i].split("=");
        //如果当前元素的键等于参数名，则返回对应的值
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return "";
}
