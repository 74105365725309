import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat-input-wrapper" }
const _hoisted_2 = { class: "chat-toolbar-container" }
const _hoisted_3 = ["disabled", "placeholder"]
const _hoisted_4 = ["disabled"]

import { ref, onMounted } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { getLanguage } from "../../../common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'chat-input',
  props: {
  disabled: {
    type: Boolean,
    default: false
  }
},
  emits: ['send', 'newChat'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const messageText = ref<string>('');
const messageTextarea = ref<HTMLTextAreaElement | null>(null);

const startNewChat = (): void => {
  emit('newChat');
};

const sendMessage = (): void => {
  if (props.disabled || messageText.value.trim() === '') return;

  emit('send', messageText.value);
  messageText.value = '';

  // Reset textarea height after sending message
  if (messageTextarea.value) {
    messageTextarea.value.style.height = 'auto';
  }
};

const handleKeyDown = (event: KeyboardEvent): void => {
  if (props.disabled) return;

  // Add line break with Ctrl+Enter
  if (event.key === 'Enter' && event.ctrlKey) {
    event.preventDefault();
    const cursorPos = (event.target as HTMLTextAreaElement).selectionStart;
    const textBeforeCursor = messageText.value.substring(0, cursorPos);
    const textAfterCursor = messageText.value.substring(cursorPos);
    messageText.value = textBeforeCursor + '\n' + textAfterCursor;

    autoResize();
    // Set cursor position after the new line
    setTimeout(() => {
      const textArea = event.target as HTMLTextAreaElement;
      textArea.selectionStart = textArea.selectionEnd = cursorPos + 1;
    }, 0);
  }
  // Send message with plain Enter key
  else if (event.key === 'Enter' && !event.ctrlKey) {
    event.preventDefault();
    sendMessage();
  }
};

const autoResize = (): void => {
  if (!messageTextarea.value) return;

  // Reset height to auto first to get accurate scrollHeight
  messageTextarea.value.style.height = 'auto';

  let lines = messageText.value.split('\n').length;
  let height = 40 + 21 * (lines - 1);

  // Set new height based on content
  messageTextarea.value.style.height = `${height}px`;
};

onMounted((): void => {
  // Initialize textarea height
  autoResize();
});

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_send_outlined = _resolveComponent("send-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button, {
        type: "link",
        size: 'small',
        onClick: startNewChat
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(PlusOutlined)),
          _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('新建对话')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["input-container", { disabled: __props.disabled }])
    }, [
      _withDirectives(_createElementVNode("textarea", {
        ref_key: "messageTextarea",
        ref: messageTextarea,
        class: "message-input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((messageText).value = $event)),
        rows: "1",
        onKeydown: handleKeyDown,
        onInput: autoResize,
        disabled: __props.disabled,
        placeholder: _unref(getLanguage)('请输入问题，Ctrl+Enter 换行，Enter 发送')
      }, "      ", 40, _hoisted_3), [
        [_vModelText, messageText.value]
      ]),
      _createElementVNode("button", {
        onClick: sendMessage,
        class: "send-button text-primary",
        disabled: __props.disabled
      }, [
        _createVNode(_component_send_outlined, { rotate: "-45" })
      ], 8, _hoisted_4)
    ], 2)
  ]))
}
}

})