<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="bule_list_page theme-common">
      <a-layout-content style="padding: 0 20px">
        <a-form layout="inline">
          <a-form-item :label="langObj['工厂']">
            <a-select v-model:value="formState.factoryId" style="min-width: 200px;" :options="factoryList"
              :placeholder="langObj['选择工厂']" :filter-option="filterOption" show-search allowClear>
              <!-- <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item.id"> {{
                  item.label
                }}
              </a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item :label="langObj['角色名']">
            <a-input v-model:value="formState.keyWork" placeholder="" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="search">{{ langObj['搜索'] }}</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add" class="addRoleDriver">
              <template #icon>
                <plus-outlined />
              </template>
              {{ langObj['新增'] }}
            </a-button>
          </a-form-item>
        </a-form>
        <div class="main">
          <a-table bordered :emptyText="langObj['暂无数据']" :columns="columns" :data-source="dataSource"
            :pagination="pagination" :loading="loading" @change="handleTableChange" size="small">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'menuIds'">
                <span v-for="(item, index) in text" :key="index">
                  <a-tag color="#2db7f5" >
                    {{ getMenuNames(item) }}
                  </a-tag>
                </span>
              </template>
              <template v-if="column.dataIndex === 'id'">
                <a-popconfirm :title="langObj['确定删除此条数据么'] + '?'" :ok-text="langObj['确定']" :cancel-text="langObj['取消']"
                  @confirm="deleteThis(record)">
                  <a-button type="primary" danger>
                    <delete-outlined />
                    {{ langObj['删除'] }}
                  </a-button>
                </a-popconfirm>
                <a-button type="primary" class="plft" @click="editThis(record)">
                  <edit-outlined />
                  {{ langObj['编辑'] }}
                </a-button>
              </template>
            </template>
          </a-table>
        </div>
      </a-layout-content>
      <a-modal :width="850" :maskClosable="false" v-model:visible="modalState.visible" :title="modalState.title"
        :cancelText="langObj['关闭']" :okText="langObj['确定']" :confirm-loading="modalState.confirmLoading" @ok="handleOk"
        v-model:z-index="editRindex">
        <a-form :model="modalForm" ref="formStateRef" name="basic" autocomplete="off"
          :label-col="{ style: { width: '150px' } }">
          <a-form-item :label="langObj['工厂']" name="factoryId" @change="clearValidate('factoryId')" :rules="[
            { required: true, message: langObj['请选择工厂'], trigger: 'blur' },
          ]">
            <a-select v-model:value="modalForm.factoryId" style="width: 100%" :options="factoryList"
              :placeholder="langObj['选择工厂']" :filter-option="filterOption2" show-search allowClear
              :dropdownClassName="dropdownClassName">
              <!-- <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item.id"> {{
                  item.label
                }}
              </a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item :label="langObj['角色名称']" name="name" @change="clearValidate('name')" :rules="[
            { required: true, message: langObj['请输入角色名称'], trigger: 'blur' },
          ]">
            <a-input v-model:value="modalForm.name" :placeholder="langObj['输入角色名称']" />
          </a-form-item>
          <a-form-item :label="langObj['菜单']" name="menuIds" @change="clearValidate('menuIds')"
            :rules="[{ required: true, message: langObj['请选择菜单'], trigger: 'blur' }]">
            <a-tree v-model:checkedKeys="modalForm.menuIds" checkable :tree-data="menuList">
              <template #title="{ title, key }">
                {{ langObj[title] || getLanguage(title) }}
              </template>
            </a-tree>
          </a-form-item>
          <a-form-item :label="langObj['工厂权限']" name="menuIds" @change="clearValidate('menuIds')"
            :rules="[{ required: true, message: langObj['请选择工厂权限'], trigger: 'blur' }]">
            <a-tree v-model:checkedKeys="modalForm.powerIds" checkable :tree-data="factoryList" @check="selectTree">
              <template #title="{ title, key }">
                {{ title }}
              </template>
            </a-tree>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "@/common/lang";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";

import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang();
const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { getLanguage } from "@/common/translate";

operateLogs('访问角色管理');
/**操作日志记录 */
const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
  factoryId: '',
  total: 0,
});

const modalState = reactive({
  visible: false,
  title: langObj.value["新增"],
  confirmLoading: false,
});

let modalForm = reactive({
  name: "",
  menuIds: [],
  machineIds: [],
  factoryIds: [],
  groupIds: [],
  id: 0,
  factoryId: '',
  powerIds: [],
});

let total: any = ref(0)
let pagination = computed(() => ({
  total: formState.total,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
const columns = [
  {
    title: langObj.value["工厂"],
    dataIndex: "factoryName",
    width: "15%",
    align: "center",
  },
  {
    title: langObj.value["角色名"],
    dataIndex: "name",
    width: "15%",
    align: "center",
  },
  {
    title: langObj.value["菜单"],
    dataIndex: "menuIds",
    width: "55%",
    align: "center",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "id",
    align: "center",
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  modalForm = reactive({
    name: "",
    menuIds: [],
    machineIds: [],
    factoryIds: [],
    groupIds: [],
    id: 0,
    factoryId: '',
    powerIds: [],
  });
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);

};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/roles/info", config).then((res) => {
    if (res) {
      operateLogs('删除角色，角色名称：' + val.name + ",id:" + val.id);
      message.success("操作成功");
      search();
    } else {
    }
  });
};
const formStateRef = ref<any>();

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  modalForm = reactive(JSON.parse(JSON.stringify(val)));

  modalForm.menuIds = modalForm.menuIds ?? [];
  let index = modalForm.menuIds?.length ? modalForm.menuIds.length - 1 : -1

  for (let i = index; i >= 0; i--) {
    const element = modalForm.menuIds[i];
    let parentInfo = menuList.value.find((p: any) => p.id === element)
    if (parentInfo) {
      modalForm.menuIds.splice(i, 1)
    }
  }

  modalForm.powerIds = val.groupIds
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};
const checkedNodes = ref<any>([])
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes
};
const filterOption = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
const filterOption2 = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let form = JSON.parse(JSON.stringify(modalForm))
      let groupsList: any = []
      allFactory.value.map((d: any) => {
        // groupsList = groupsList.concat(d.groups)
        if (d?.groups?.length > 0) {
          groupsList = groupsList.concat(d.groups.map((p: any) => ({
            name: p.name,
            sonID: p.sonID,
            factoryId: d._id,
            factoryParentId: d.parentId,
          })))
        }
      })

      let factoryIds: any = []
      let groupIds: any = []

      form.powerIds.map((d: any) => {
        let info = groupsList.find((p: any) => p.sonID === d)
        if (info) {
          groupIds.push(d)
          factoryIds.push(info.factoryId)
          if (info.factoryParentId) {
            factoryIds.push(info.factoryParentId)
          }
        }
      })

      factoryIds = [...new Set(factoryIds)]
      groupIds = [...new Set(groupIds)]

      form.factoryIds = factoryIds
      form.groupIds = groupIds

      let menuIds: any = []
      form.menuIds.map((d: any) => {
        menuIds.push(d)
        let p = menuListAll.value.find((p: any) => p.id === d)
        if (p?.parentId !== '0') {
          menuIds.push(p?.parentId)
        }
      })

      form.menuIds = [...new Set(menuIds)]

      if (form.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/roles/info", form, config).then((res) => {
          if (res) {
            operateLogs('修改角色，角色名称：' + form.roleName + ",id:" + form.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/roles", form, config).then((res) => {
          if (res) {
            operateLogs('添加角色，角色名称：' + form.roleName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current
  formState.take = pagination.pageSize
  search();
};
// 查询
const search = () => {
  const config = {
    params: {
      skip: formState.skip,
      take: formState.take,
      keyword: formState.keyWork,
      factoryId: formState.factoryId
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/roles", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.total = res.data.count
      // total.vlaue = res.data.count
    }
  });
};
search();
let menuList = ref<any>();
let menuListAll = ref<any>();
let menuListNotLevel = ref<any>([]);
const getMenu = () => {
  request.get("/api/menus").then((res: any) => {
    if (res) {
      menuListAll.value = res.data;
      res.data = res.data.map((t: any) => ({
        ...t,
        key: t.id,
      }));
      menuListNotLevel.value = res.data;
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      menuList.value = oneLevel;
    }
  });
};
getMenu();

//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();

  //新注册用户，未经过引导的需弹窗进行引导
  if (!getMemberOnboarding()) {
    highlightStep(getPopover(".addRoleDriver", "3/9 新建角色", "在“角色管理”中，点击新增可创建角色，角色可勾选需要的系统权限。该功能主要用于管理系统账号角色权限。", "新建角色", "right", "start", nextRoute, addRole));
  }
});

let factoryList = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups)
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: 'factory',
        children: t.groups.map((group: any) => ({
          type: 'group',
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryList.value = factory;
      // loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let config = {
    params: { factoryIds: factoryIds },
    headers: {
      requestId: requestId,
    },
  }
  let res = await request.get("/api/machines", config)

  factoryList.value.forEach((element: any) => {
    allMachine.value = res.data.map((t: any) => t.id)
    element.children.forEach((sonFact: any) => {
      if (sonFact.type == 'factory' && sonFact.children) {
        sonFact.children.forEach((groupFact: any) => {
          if (groupFact.type == 'group') {
            groupFact.children = res.data.filter((t: any) => t.groupId == groupFact.value).map((t: any) => ({
              type: 'machine',
              ...t,
              value: t.id,
              label: t.machineName,
              title: t.machineName,
              key: t.id,
            }));

          }
        });
      } else if (sonFact.type == 'group') {
        sonFact.children = res.data.filter((t: any) => t.groupId == sonFact.value).map((t: any) => ({
          type: 'machine',
          ...t,
          value: t.id,
          label: t.machineName,
          title: t.machineName,
          key: t.id,
        }));

      }
    });

  });
};


const getMenuNames = (id:any) => {
  let menu = menuListNotLevel.value.find((p:any) => p.key === id)?.title
  return langObj.value[menu] || getLanguage(menu)
}


//新用户引导新建角色按钮回调
let editRindex = ref("auto");
let dropdownClassName = ref("");
const addRole = () => {
  editRindex = 1000000001;
  dropdownClassName = "dropdownStyleDriver";
  add();
};
//新用户引导新建角色下一步按钮回调
const nextRoute = () => {
  router.push("/system/member");
  return;
};
</script>
<style scoped>
.main {
  margin-top: 30px;
}
</style>
