<template>
  <div>

    <a-layout-content style="padding: 0 20px" class="flex theme-common">
      <div >
        <div class="search_wrap">
          <a-input v-model:value="searchFac" placeholder="请输入工厂名称搜索">
          <template #suffix>
            <search-outlined style="cursor: pointer;" @click="factoryFilter" />
          </template>
        </a-input>
        </div>
       
        <a-tree style="height: 80vh; overflow: auto; padding: 10px 0" class="draggable-tree" block-node
          :tree-data="treeData" @select="selectTree" />
      </div>


      <div class="w-full fac-box p-3 ml-4">
        <a-form :model="formInfo" name="basic" autocomplete="off" layout="inline"
          :label-col="{ style: { width: '150px' } }" style="color: #fff">
          <div class="w-full flex items-center">
            <div class="freqx_formTitle" style="width: 400px">
              {{ langObj["周报配置"] }}
            </div>
          </div>

          <!-- <div class="w-full">
            <a-tabs v-model:activeKey="activeKey" @change="tabChange">
              <a-tab-pane :key="key" v-for="(item, key) in factoryList">
                <template #tab>
                  <span>
                    <bank-outlined />
                    {{ item.factoryName }}
                  </span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div> -->

          <div class="code-box-meta markdown">
            <div class="code-box-title" style="background: #000a32">
              {{ langObj["报告生成策略"] }}
            </div>
            <div class="code-box-description">
              <a-form-item :label="langObj['是否生成报告']" name="alarmTactics">
                <span class="flex">
                  <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.enabled">
                    <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                    <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                  </a-radio-group>
                </span>
              </a-form-item>
            </div>
            <div class="code-box-description">
              <a-form-item :label="getLanguage('是否需要登录查看')" name="alarmTactics">
                <span class="flex">
                  <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.isLogin">
                    <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                    <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                  </a-radio-group>
                </span>
              </a-form-item>
            </div>
            <!-- <div class="code-box-description">
              <a-form-item label="" name="deadline" style="color: #fff">
                {{ langObj["每月第"] }}&nbsp;&nbsp;<a-input-number :min="1" :max="28"
                  :disabled="!formInfo.reportRule.enabled"
                  v-model:value="formInfo.reportRule.dayOfMonth" />&nbsp;&nbsp;{{ langObj["天生成月报"] }}
              </a-form-item>
            </div> -->
            

            <div class="code-box-description">
              <a-form-item label="" name="deadline" style="color: #fff">
                {{ langObj["每周第"] }}&nbsp;&nbsp;
                <a-checkbox-group v-model:value="formInfo.reportRule.dayOfWeek" name="checkboxgroup"
                  :options="dayOfWeeks" />
                &nbsp;&nbsp;{{ langObj["天生成周报"] }}
              </a-form-item>
            </div>
            <div class="code-box-description">
              <a-form-item label="" name="deadline" style="color: #fff">
                {{ langObj["第"] }}&nbsp;&nbsp;<a-input-number :min="0" max="23" :disabled="!formInfo.reportRule.enabled"
                  v-model:value="formInfo.reportRule.dayOfHour" />&nbsp;&nbsp;{{ langObj["小时生成报告"] }}
              </a-form-item>
            </div>
          </div>
        </a-form>
        <a-form :model="formInfo" name="basic" autocomplete="off" layout="inline"
          :label-col="{ style: { width: '150px' } }" style="color: #fff">
          <div class="w-full flex items-center">
            <div class="freqx_formTitle" style="width: 400px">
              {{ langObj["月报配置"] }}
            </div>
          </div>

          <!-- <div class="w-full">
            <a-tabs v-model:activeKey="activeKey" @change="tabChange">
              <a-tab-pane :key="key" v-for="(item, key) in factoryList">
                <template #tab>
                  <span>
                    <bank-outlined />
                    {{ item.factoryName }}
                  </span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div> -->

          <div class="code-box-meta markdown">
            <div class="code-box-title" style="background: #000a32">
              {{ langObj["报告生成策略"] }}
            </div>
            <div class="code-box-description">
              <a-form-item :label="langObj['是否生成报告']" name="alarmTactics">
                <span class="flex">
                  <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.enabledOfMonth">
                    <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                    <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                  </a-radio-group>
                </span>
              </a-form-item>
            </div>
            <div class="code-box-description">
              <a-form-item :label="getLanguage('是否需要登录查看')" name="alarmTactics">
                <span class="flex">
                  <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.isLoginOfMonth">
                    <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                    <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                  </a-radio-group>
                </span>
              </a-form-item>
            </div>
            <!-- <div class="code-box-description">
              <a-form-item label="" name="deadline" style="color: #fff">
                {{ langObj["每月第"] }}&nbsp;&nbsp;<a-input-number :min="1" :max="28"
                  :disabled="!formInfo.reportRule.enabled"
                  v-model:value="formInfo.reportRule.dayOfMonth" />&nbsp;&nbsp;{{ langObj["天生成月报"] }}
              </a-form-item>
            </div> -->
            <div class="code-box-description flex" >
                <a-form-item label="" name="deadline" style="color: #fff">
                  {{ langObj["每月第"] }}&nbsp;&nbsp;<a-input-number :min="1" :max="31"
                    :disabled="!formInfo.reportRule.enabledOfMonth"
                    v-model:value="formInfo.reportRule.dayOfMonth" />&nbsp;&nbsp;{{ langObj["天生成月报"] }}
                </a-form-item>
                <a-form-item :label="getLanguage('月报生成范围')" name="alarmTactics">
                  <span class="flex">
                    <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.rangeOfMonth">
                      <a-radio :value="1">{{ getLanguage("上月月报")}}</a-radio>
                      <a-radio :value="2">{{ getLanguage("本月月报" )}}</a-radio>
                    </a-radio-group>
                  </span>
                </a-form-item>
              </div>
            <div class="code-box-description">
              <a-form-item label="" name="deadline" style="color: #fff">
                {{ langObj["第"] }}&nbsp;&nbsp;<a-input-number :min="0" max="23" :disabled="!formInfo.reportRule.enabledOfMonth"
                  v-model:value="formInfo.reportRule.dayOfMonthHour " />&nbsp;&nbsp;{{ langObj["小时生成报告"] }}
              </a-form-item>
            </div>
          </div>
        </a-form>
        <a-button type="primary" @click="handleOk">{{
          langObj["保存"]
          }}</a-button>
      </div>
    </a-layout-content>
  </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { onMounted } from "vue";
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";
let langObj: any = ref({});
let language: any = ref("Chinese");
/**操作日志记录 */
var activeKey = ref<any>(0);
let facForm = ref([]);

let factoryListAll: any = ref([]);
let searchFac = ref('');


const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};

operateLogs("访问基础配置");

let factory_id = "";
getLang();

let dayOfWeeks: any = ref([
  { label: getLanguage("星期日", language.value), value: 0 },
  { label: getLanguage("星期一", language.value), value: 1 },
  { label: getLanguage("星期二", language.value), value: 2 },
  { label: getLanguage("星期三", language.value), value: 3 },
  { label: getLanguage("星期四", language.value), value: 4 },
  { label: getLanguage("星期五", language.value), value: 5 },
  { label: getLanguage("星期六", language.value), value: 6 },
]);

// 保存
const handleOk = () => {
  if (!factory_id) {
    message.warning("请选择工厂后提交");
    return;
  }
  formInfo.value.factoryId = factory_id;
  console.log(formInfo.value)
  request.post("/api/factories/setting", formInfo.value).then((res) => {
    if (res) {
      operateLogs("修改基础配置");
      message.success(langObj.value["操作成功"]);
      search(formInfo.value.factoryId);
    }
  });
};

const tabChange = () => {
  var selectFact = factoryList.value[activeKey.value];
  factory_id = selectFact._id;
  search(selectFact._id);
};

const handleTableChange = (val: any) => { };
// 查询
const search = (id: any) => {
  formInfo.value = {
    reportRule: {
      enabled: true,
      dayOfMonth: 0,
      dayOfWeek: [],
      dayOfHour: 0,
      rangeOfMonth:1
    },
  };

  const config = {
    params: { id: id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res && res.data) {
      if (res.data.reportRule?.dayOfWeek) {
        if (typeof res.data.reportRule.dayOfWeek == "number") {
          res.data.reportRule.dayOfWeek = [res.data.reportRule.dayOfWeek];
        }
        if(!res.data.reportRule.rangeOfMonth){
          res.data.reportRule.rangeOfMonth=1
        }
       
       
      } else {
        res.data.reportRule = {
          enabled: true,
          dayOfMonth: 0,
          dayOfWeek: [],
          dayOfHour: 0,
          rangeOfMonth:1
        };
      }
     
      formInfo.value = { ...res.data };
      
    }
  });
};

let formInfo = ref<any>({
  reportRule: {
    enabled: true,
    dayOfMonth: 0,
    dayOfWeek: [],
    dayOfHour: 0,
    rangeOfMonth:null
  },
});
let factoryList = ref<any>();
let treeData: any = ref([]);

const loadFactory = () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      factoryListAll.value = JSON.parse(JSON.stringify(res.data));
      factoryFilter()
      // let tree: any = [];

      // let list1 = res.data.filter((t: any) => t.parentId);

      // let list2 = res.data.filter((t: any) => !t.parentId);

      // list2.map((d: any) => {
      //   let first = {
      //     title: d.factoryName,
      //     key: d._id,
      //     children: [],
      //   };
      //   let list3 = list1.filter((p: any) => p.parentId == d._id);
      //   if (list3 && list3.length) {
      //     first.children = list3.map((p: any) => ({
      //       title: p.factoryName,
      //       key: p._id,
      //     }));
      //   }
      //   tree.push(first);
      // });

      // treeData.value = tree;
    }
  });
};

const factoryFilter = () => {
  let factoryF = JSON.parse(JSON.stringify(factoryListAll.value)) || []
  var list1 = factoryF.map((t: any) => ({
    key: t._id,
    title: t.factoryName,
    value: t._id,
    label: t.factoryName,
    parentId: t.parentId,
    id: t._id,
    type: "factory",
  }));
  let factory: any = [];
  let parentFs = list1.filter((p: any) => !p.parentId);
  parentFs.map((d: any) => {
    let sonFactory = list1.filter((p: any) => p.parentId === d.id);
    if (sonFactory && sonFactory.length) {
      d.children = sonFactory;
    }
    factory.push(d);
  });

  let list = JSON.parse(JSON.stringify(factory));
  factoryList.value = [];
  if (searchFac.value) {
    list.map((d: any) => {
      if (d.children && d.children.length) {
        let list1 = d.children.filter((p: any) =>
          p.label.includes(searchFac.value)
        );
        if (list1.length) {
          d.children = list1;
          factoryList.value.push(d);
        } else {
          if (d.label.includes(searchFac.value)) {
            factoryList.value.push(d);
          }
        }
      } else {
        if (d.label.includes(searchFac.value)) {
          factoryList.value.push(d);
        }
      }
    });
  } else {
    factoryList.value = list;
  }
  treeData.value = factoryList.value;
}


const selectTree = (ev: any) => {
  if (ev && ev.length) {
    factory_id = ev[0];
    search(ev[0]);
  }
};

onMounted(() => {
  loadFactory();
});
</script>
<style scoped>
.code-box-description {
  padding-bottom: 0;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.bigFont {
  font-size: 22px;
  font-weight: 700;
}

.freqx_tips {
  margin-top: 4px;
}

.facInfo {
  border: 1px solid gray;
}

.fac-box {
  border: 1px solid rgba(128, 128, 128, 0.384);
}

.search_wrap {
  width: 300px;
  box-sizing: border-box;
  margin: 0px 0px 10px 0;
}
/deep/ .ant-input {
  border: none !important;
}
</style>
