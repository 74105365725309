import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef } from "vue"

const _hoisted_1 = {
  class: "w-full theme-common",
  style: {"padding":"16px 16px","min-height":"calc(100vh - 100px)","background-color":"#000a32","overflow-y":"scroll","padding-bottom":"30px"}
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = { style: {"min-width":"250px","margin-top":"31px"} }
const _hoisted_4 = { class: "flex-1 content theme-bg whiteBg ml-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "w-full flex items-center" }
const _hoisted_7 = { class: "flex-1 flex justify-center items-center" }
const _hoisted_8 = { class: "flex ml-2" }
const _hoisted_9 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_10 = { class: "flex mt-4" }
const _hoisted_11 = { class: "flex-1" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartA"
}
const _hoisted_14 = { class: "flex-1 ml-4" }
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartB"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "w-full flex items-center" }
const _hoisted_19 = { class: "flex-1 flex justify-center items-center" }
const _hoisted_20 = { class: "flex ml-2" }
const _hoisted_21 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_22 = { class: "flex mt-4" }
const _hoisted_23 = { class: "flex-1" }
const _hoisted_24 = { class: "text-center" }
const _hoisted_25 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartC"
}
const _hoisted_26 = { class: "flex-1 ml-4" }
const _hoisted_27 = { class: "text-center" }
const _hoisted_28 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartD"
}
const _hoisted_29 = { class: "mt-4" }
const _hoisted_30 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_31 = { class: "mt-4" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "text-center mt-2" }
const _hoisted_34 = {
  style: {"color":"red"},
  class: "text-center"
}
const _hoisted_35 = { class: "flex justify-center" }
const _hoisted_36 = {
  class: "content theme-bg whiteBg ml-4",
  style: {"width":"310px","position":"relative"}
}
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "flex items-center" }
const _hoisted_39 = { class: "ml-2" }
const _hoisted_40 = { class: "mt-2" }
const _hoisted_41 = {
  style: {"opacity":"0.6"},
  class: "flex mt-2"
}
const _hoisted_42 = { class: "ml-2" }
const _hoisted_43 = {
  key: 0,
  class: "box2 p-4",
  style: {"position":"absolute","left":"-1100px","width":"1100px","top":"0"}
}
const _hoisted_44 = { class: "w-full flex" }
const _hoisted_45 = {
  class: "flex-1 text-center",
  style: {"font-size":"18px"}
}
const _hoisted_46 = { class: "flex items-center mt-4" }
const _hoisted_47 = { class: "flex-1 text-center" }
const _hoisted_48 = { class: "flex mt-4" }
const _hoisted_49 = { class: "flex-1" }
const _hoisted_50 = { class: "text-center" }
const _hoisted_51 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartE"
}
const _hoisted_52 = { class: "flex-1 ml-4" }
const _hoisted_53 = { class: "text-center" }
const _hoisted_54 = {
  key: 0,
  class: "w-full h-96",
  id: "optimizationChartF"
}
const _hoisted_55 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_56 = { class: "mt-4" }
const _hoisted_57 = { class: "mt-4" }

import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate,
  transformDate2,
  getGroupName,
  inIframe
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import OrderListComponent from "./orderList.vue";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import pictureListComponent from "../components/picture-list.vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";
import { Item } from "ant-design-vue/lib/menu";

export default /*@__PURE__*/_defineComponent({
  __name: 'modelOptimization',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let scrollTop = ref("120px");
let iframe = ref(false);
iframe.value = inIframe();
if (iframe.value) {
  scrollTop.value = '10px'
}
let machine_id:any = ref('')
let machineInfo:any = ref({})
let sensorList:any = ref([])
let sensorListAll:any = ref([])

let searchModel:any = ref({
    sensorId: '',
    type: 1,
    date: null,
    startData: '',
    endDate: '',
})
let faultName:any = []
let faultNameII:any = []
let healthChartSelectlenged: any = {};
let faultChartSelectlenged: any = {};
let myChartA:any = null;
let myChartB:any = null;


let healthChartSelectlengedAdj:any = {}
let faultChartSelectlengedAdj:any = {}
let myChartC:any = null;
let myChartD:any = null;

let reportList:any = ref([])
let selectedRowKeys:any = ref([])

let isAdjust:any = ref(false)
let adjustList:any = ref([])
let sensorListAdj:any = ref([]);
let isSubmit:any = ref(false)

let faultList:any = ref([])
let form:any = ref({
    adjustDetails: [],
    adjustRange: null,
    adjustDegree: null,
    adjustReason: null,
    relatedReports: [],
})

const reportColumns = [
    {
        title: getLanguage('报告时间'),
        dataIndex: "date",
    },
    {
        title: getLanguage('严重程度'),
        dataIndex: "condition",
    },
    {
        title: getLanguage('故障模式'),
        dataIndex: "falult",
    },
    {
        title: getLanguage('诊断描述'),
        dataIndex: "diagnosticNotes",
    },
    {
        title: getLanguage('调整后状态'),
        dataIndex: "adjustCondition",
    },
]
const scoreColumns = [
    {
        title: getLanguage('调整范围'),
        dataIndex: "adjustRange",
    },
    {
        title: getLanguage('调整'),
        dataIndex: "adjustDegree",
    },
    {
        title: getLanguage('调整原因'),
        dataIndex: "adjustReason",
    },
    {
        title: getLanguage('操作'),
        dataIndex: "act",
    }
]
const scoreColumns2 = [
    {
        title: getLanguage('调整时间'),
        dataIndex: "adjustDate",
    },
    {
        title: getLanguage('调整范围'),
        dataIndex: "adjustRange",
    },
    {
        title: getLanguage('调整'),
        dataIndex: "adjustDegree",
    },
    {
        title: getLanguage('调整原因'),
        dataIndex: "adjustReason",
    }
]
const confirmColumns = [
    {
        title: getLanguage('确认时间'),
        dataIndex: "confirmDate",
    },
    {
        title: getLanguage('确认状态'),
        dataIndex: "status",
    },
    {
        title: getLanguage('原因'),
        dataIndex: "confirmReason",
    },
]
let adjustData = ref([
    {
        adjustRange: 'machine',
        adjustDegree: null,
        adjustReason: '',
    }
])

let conditionList = [
    {label: '健康', value: 0},
    {label: '可用', value: 1},
    {label: '可用', value: 2},
    {label: '警戒', value: 3},
    {label: '警戒', value: 4},
    {label: '警戒', value: 5},
    {label: '故障', value: 6},
    {label: '故障', value: 7},
]
let adjustRangeList = [
    {label: '调整设备整体分数', value: 'machine'},
    {label: '调整选中报告的测点指标分数', value: "report-sensor"},
]
let adjustDegreeList = [
    {label: '准确', value: 0},
    {label: '轻微', value: 1},
    {label: '略轻微', value: 2},
    {label: '严重', value: 7},
    {label: '略严重', value: 8},
]
let varianceList = [
    { value: 0, variance: 0 },
    { value: 1, variance: -10 },
    { value: 2, variance: -5 },
    { value: 7, variance: 10 },
    { value: 8, variance: 5 },
]

let statusList = [
    {label: '待确认', value: 1, color: 'rgb(210, 222, 73)'},
    {label: '无需确认', value: 2, color: 'rgb(97, 192, 143)'},
    {label: '已确认', value: 3, color: 'rgb(97, 192, 143)'},
    {label: '取消调整', value: 4, color: 'gray'},
]

let isShowChart = ref(true)

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});


onMounted(() => {
    getFaultList()
})

// 获取所有指标
const getFaultList = async() => {
    let result = await request.post('/api/faultinfos')
    faultList.value = result?.data || []
}

// 选择设备
const selectMachine = async(ev:any) => {
    machine_id.value = ev
    let config: any = {
        params: {
            machineId: machine_id.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/machines/info", config);
    machineInfo.value = result?.data || {};

    getSensorList()
    getFaultName()
    getBeekList()
}

// 获取测点列表
const getSensorList = async() => {
    let config: any = {
        params: {
            machineId: machine_id.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors/search", config);
    sensorList.value = result.data || [];
    sensorListAll.value = [{sensorPlace: '全设备测点', id: ''}].concat(sensorList.value)

}

// 获取指标数据
const getFaultName = async () => {
    let faultnamesSearch = {
        params: { machineId: machine_id.value },
        headers: {
        requestId: uuidv4(),
        },
    };
    let result2 = await request.get("/api/machines/faultnames", faultnamesSearch);
    if (result2 && result2.data) {
        faultName = result2.data.faultNames;
        faultNameII = result2.data.faultNameExpects;
    }
    getChartData()
};

// 选择测点
const selectSensor = (ev:any) => {
    getChartData()
}

// 获取分数数据
const getChartData = async() => {
    isAdjust.value = false
    isSubmit.value = false
    faultName.map((item: any) => {
        healthChartSelectlenged[langObj.value[item.title] || item.title] = true;
    });

    faultNameII.map((item: any) => {
        faultChartSelectlenged[langObj.value[item.title] || item.title] = true;
    });

    let beginDate: any = "";
    let endDate: any = "";

    let machine_date = machineInfo.value.workStatus?.scoreDate;
    if (searchModel.value.type == "0") {
        if (
            searchModel.value.date &&
            searchModel.value.date.length === 2
        ) {
        let start = searchModel.value.date[0];
        let end = searchModel.value.date[1];
        beginDate = start.$d;
        endDate = end.$d;
        } else {
        message.warn("请选择时间");
        return;
        }
    } else {
        let time = new Date(machine_date);
        let date = time.getTime();
        if (searchModel.value.type == "1") {
        beginDate = date - 1000 * 60 * 60 * 24 * 7;
        }
        if (searchModel.value.type == "2") {
        beginDate = date - 1000 * 60 * 60 * 24 * 30;
        }
        if (searchModel.value.type == "3") {
        beginDate = date - 1000 * 60 * 60 * 24 * 60;
        }
        endDate = machine_date;
    }
    let begin = transformDate2(beginDate, 1);
    let end = transformDate2(endDate, 2);
    searchModel.value.startDate = begin
    searchModel.value.endDate = end
    getReportList()
    let search = {
        params: {
            begin,
            end,
            sensorId: "",
            machineId: machine_id.value,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    // 获取全设备数据(开关机数据)
    let scoreA = await request.get("/api/external/machines/score-full", search);
    let scoreAll = scoreA.data;
    let scoreExpertAll = scoreA.data;


    if (searchModel.value.sensorId) {
        search.params.sensorId = searchModel.value.sensorId;
        let scoreA = await request.get("/api/external/machines/score-full", search);
        scoreAll = scoreA.data;
    }

    faultName.map((d: any) => {
        d.data = [];
        d.dateList = [];
    });
    faultNameII.map((d: any) => {
        d.data = [];
        d.dateList = [];
    });

    scoreAll.map((item: any) => {
        faultName.map((fault: any) => {
            if (item[fault?.column] != null) {
                fault.dateList.push(item.date);
                let date = item.date.slice(5, 19);
                fault.data.push([date, item[fault.column] || null]);
            }
        });

        for (let fault of faultNameII) {
            if (item[fault?.column] != null) {
                fault.dateList.push(item.date);
                let date = item.date.slice(5, 19);
                fault.data.push([date, item[fault?.column] || null]);
            }
        }
    });
    isShowChart.value = false
    setTimeout(() => {
        isShowChart.value = true
    },200)
    setTimeout(() => {
        myChartA = null
        myChartB = null
        initChartA();
        initChartB();
    }, 1000);
    
}

const initChartA = () => {
    let data = faultNameII;
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartA")) return;
    myChartA = ec.init(document.getElementById("optimizationChartA"));
    myChartA.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });


    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
            top:"25%"
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            }
           
            },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 10,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }
    Init(faultChartSelectlenged, DataInfo);

    if (option && typeof option === "object") {
        myChartA && myChartA.clear();
        myChartA && myChartA.setOption(option, true);
    }

    myChartA
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlenged = params.selected;
        Init(faultChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
            myChartA?.clear();
            myChartA?.setOption(option, true);
        }
    });






}

const initChartB = () => {
    let data = faultName;
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartB")) return;
    myChartB = ec.init(document.getElementById("optimizationChartB"));
    myChartB.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
            top:"25%"
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 10,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(healthChartSelectlenged, DataInfo);

    
    if (option && typeof option === "object") {
        myChartB && myChartB.clear();
        myChartB && myChartB.setOption(option, true);
    }

    myChartB
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlenged = params.selected;
        Init(faultChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
            myChartB?.clear();
            myChartB?.setOption(option, true);
        }
    });






}

// 获取报告列表
const getReportList = async() => {
    let search = {
        params: { 
            machineId: machine_id.value,
            startDate: searchModel.value.startDate,
            // startDate: '2024/03/04 23:59:59',
            endDate: searchModel.value.endDate,
        },
        headers: {
        requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/threshold-feedbacks/reports', search)
    reportList.value = result?.data || []
    let sortTwo = (a:any, b:any) => {
        if(a.date < b.date) {
            return 1
        } else {
            return -1
        }
    }
    reportList.value.sort((a:any, b:any) => {return sortTwo(a, b)})
}

//复选框选择
const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 预览调整
let isLoading:any = ref(false)
const adjust = async(ev:any) => {
    form.value.adjustRange = ev.adjustRange
    form.value.adjustDegree = ev.adjustDegree
    form.value.adjustReason = ev.adjustReason
    if(!ev.adjustDegree && ev.adjustDegree !== 0) {
        return message.warning('请选择调整后预览!')
    }
    if(!ev.adjustReason) {
        return message.warning('请输入调整原因后预览!')
    }


    let list:any = []
    // 调整设备整体分数
    if(ev.adjustRange === 'machine') {

        sensorList.value.map((s:any) => {
            faultName.map((f:any) => {
                let faultInfo = faultList.value.find((p:any) => p.scoreName === f.column)
                let isSingle = false
                let singleExpertFaultNames = ["T8", "T14", "T15", "T16", "T17", "T19", "T20", "T21", "T22", "T23", "T30", "T35", "T39", "T40", "T48", "T49", "T51", "T54", "T62", "T63"];
                if (f.column.includes('S')) {
                    isSingle = true
                } else {
                    if (singleExpertFaultNames.find((p: any) => p === f.column)) {
                    isSingle = true
                    } else {
                    isSingle = false
                    }
                }


                let obj =  {
                    "machineId": machine_id.value,
                    "sensorId": s.id,
                    "dateArray": [
                        searchModel.value.startDate,
                        searchModel.value.endDate
                    ],
                    "variance": varianceList.find((p: any) => p.value === ev.adjustDegree)?.variance || 0,
                    "tarFunc": faultInfo?.faultFunction,
                    "funcTag": f.column,
                    faultName: f.title,
                    isSingle
                }
                list.push(obj)
            })
            faultNameII.map((f:any) => {
                let faultInfo = faultList.value.find((p:any) => p.scoreName === f.column)
                let isSingle = false
                let singleExpertFaultNames = ["T8", "T14", "T15", "T16", "T17", "T19", "T20", "T21", "T22", "T23", "T30", "T35", "T39", "T40", "T48", "T49", "T51", "T54", "T62", "T63"];
                if (f.column.includes('S')) {
                    isSingle = true
                } else {
                    if (singleExpertFaultNames.find((p: any) => p === f.column)) {
                    isSingle = true
                    } else {
                    isSingle = false
                    }
                }


                let obj =  {
                    "machineId": machine_id.value,
                    "sensorId": s.id,
                    "dateArray": [
                        searchModel.value.startDate,
                        searchModel.value.endDate
                    ],
                    "variance": varianceList.find((p: any) => p.value === ev.adjustDegree)?.variance || 0,
                    "tarFunc": faultInfo?.faultFunction,
                    "funcTag": f.column,
                    faultName: f.title,
                    isSingle
                }
                list.push(obj)
            })
        })

    }
    // 调整选中报告的测点指标分数
    if(ev.adjustRange === 'report-sensor') {
        if(!selectedRowKeys.value?.length) return message.warning('请选择报告后预览调整！')
        form.value.relatedReports = []
        selectedRowKeys.value.map((d:any) => {
            let reportInfo = reportList.value.find((p:any) => p.reportId === d)
            form.value.relatedReports.push(reportInfo)
            reportInfo?.sensorFaults?.length && reportInfo.sensorFaults.map((s:any) => {
                let isSingle = false
                let singleExpertFaultNames = ["T8", "T14", "T15", "T16", "T17", "T19", "T20", "T21", "T22", "T23", "T30", "T35", "T39", "T40", "T48", "T49", "T51", "T54", "T62", "T63"];
                if (s.scoreName.includes('S')) {
                    isSingle = true
                } else {
                    if (singleExpertFaultNames.find((p: any) => p === s.scoreName)) {
                    isSingle = true
                    } else {
                    isSingle = false
                    }
                }
                let obj =  {
                    "machineId": machine_id.value,
                    "sensorId": s.sensorId,
                    "dateArray": [
                        searchModel.value.startDate,
                        searchModel.value.endDate
                    ],
                    "variance": varianceList.find((p: any) => p.value === ev.adjustDegree)?.variance || 0,
                    "tarFunc": s.faultFunction,
                    "funcTag": s.scoreName,
                    faultName: s.faultName,
                    isSingle
                }
                list.push(obj)
            })
        })

    }

    isSubmit.value = true

    let isDevAll = ev.adjustRange === 'machine' ? true : false
    isLoading.value = true
    let result = await request.post('/scoreModel/score/sensors-threshold', {detailInfos: list, isDevAll})
    isLoading.value = false
    let detailInfos = result?.data?.detailInfos || []
    let machineScores = result?.data?.machineScores || []

    form.value.adjustDetails = detailInfos
    form.value.machineScores = machineScores

    adjustList.value = []

   
    let sensorIds = [... new Set(detailInfos.map((d:any) => d.sensorId))]
    
    if(isDevAll) {
        sensorListAdj.value = [
            {sensorPlace: '全设备测点', id: '' }
        ]
        searchModel.value.sensorIdAdj = ''
    } else {
        sensorListAdj.value = []
        searchModel.value.sensorIdAdj = sensorIds[0]
    }

    
    sensorIds.map((d:any) => {
        if(isDevAll) {
            let faults:any = []
            faultName.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });
            faultNameII.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });

            let dateList = machineScores.map((p:any) => p.date)
            faults.map((f:any) => {
                f.dateList = dateList
                f.data = []
            })
            machineScores.map((d:any) => {
                faults.map((f:any) => {
                    f.data.push([d.date, d[f.column]])
                })
            })
            let obj = {
                sensorId: '',
                dataList: faults
            }
            adjustList.value.push(obj)
        } 


        let sensorInfo = sensorListAll.value.find((p:any) => p.id == d)
        sensorListAdj.value.push(sensorInfo)
        let detailInfoSens = detailInfos.filter((p:any) => p.sensorId == d)
        let xData = []
        let dataList:any = []
        detailInfoSens.map((s:any) => {
            let dates = s.result.date || []
            let datas = s.result[s.funcTag] || []
            let data = []
            for (let index = 0; index < dates.length; index++) {
                let date = dates[index]
                let data2 = datas[index]
                data.push([date.slice(5, 19), data2])
            }

            let obj1 = {
                column: s.funcTag,
                title: s.faultName,
                dateList: s.result.date || [],
                data
            }

           
            dataList.push(obj1)
        })
        let obj = {
            sensorId: d,
            dataList
        }

        adjustList.value.push(obj)
    })

    reportList.value.map((d:any) => {
        let date = d.date
        date = "2025/02/26 22:00:00"
        let conditionList:any = []
        if(d?.sensorFaults?.length) {
            d.sensorFaults.map((d:any) => {
                let adjustInfo = adjustList.value.find((p:any) => p.sensorId === d.sensorId)
                if(adjustInfo) {
                    let dataInfo = adjustInfo.dataList.find((p:any) => p.column === d.scoreName)
                    if(dataInfo) {
                        let index = dataInfo.dateList.indexOf(date)
                        if(index >= 0) {
                            let data1 = dataInfo.data[index]
                            conditionList.push(data1[1])
                        }
                    }
                }
                
            })
        }
        let minValue = Math.min(...conditionList)
        let adjustCondition = d.condition
        if(minValue >= 80) {
            adjustCondition = 0
        } else if(minValue >= 60 && minValue < 80) {
            adjustCondition = 1
        } else if(minValue >= 30 && minValue < 60) {
            adjustCondition = 3
        } else if(minValue >= 0 && minValue < 30) {
            adjustCondition = 6
        } 
        d.adjustCondition = adjustCondition
    })



    isAdjust.value = true
    getChartDataAdj()
}

// 选择测点
const selectSensor2 = (ev:any) => {
    getChartDataAdj()
}

const getChartDataAdj = () => {
    let adjustListInfo = adjustList.value.find((p:any) => p.sensorId == searchModel.value.sensorIdAdj)
    let dataList = adjustListInfo.dataList || []
    let fault = dataList.filter((p:any) => p.column.includes('S'))
    let faultII = dataList.filter((p:any) => p.column.includes('T'))
    let faultNames = fault.map((p:any) => p.title)
    let faultNameIIs = faultII.map((p:any) => p.title)
    healthChartSelectlengedAdj = {}
    faultChartSelectlengedAdj = {}
    faultNames.map((d:any) => {
        faultChartSelectlengedAdj[langObj.value[d] || d] = true
    })
    faultNameIIs.map((d:any) => {
        healthChartSelectlengedAdj[langObj.value[d] || d] = true
    })

     isShowChart.value = false
    setTimeout(() => {
        isShowChart.value = true
    },200)

    setTimeout(() => {
        initChartC(faultII)
        initChartD(fault)
    }, 1000);
}

const initChartC = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartC")) return;
    myChartC = ec.init(document.getElementById("optimizationChartC"));
    myChartC.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
            top:"25%"
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 10,
                showMaxLabel: false,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(healthChartSelectlengedAdj, DataInfo);

    
    if (option && typeof option === "object") {
        myChartC && myChartC.clear();
        myChartC && myChartC.setOption(option, true);
    }

    myChartC
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlengedAdj = params.selected;
        Init(healthChartSelectlengedAdj, DataInfo);
        if (option && typeof option === "object") {
            myChartC?.clear();
            myChartC?.setOption(option, true);
        }
    });
}

const initChartD = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartD")) return;
    myChartD = ec.init(document.getElementById("optimizationChartD"));
    myChartD.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
            top:"25%"
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 10,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(faultChartSelectlengedAdj, DataInfo);

    
    if (option && typeof option === "object") {
        myChartD && myChartD.clear();
        myChartD && myChartD.setOption(option, true);
    }

    myChartD
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlengedAdj = params.selected;
        Init(faultChartSelectlengedAdj, DataInfo);
        if (option && typeof option === "object") {
            myChartD?.clear();
            myChartD?.setOption(option, true);
        }
    });
}

// 提交
const submit = async() => {
    if(!isSubmit) {
        return message.warning('请先预览调整后提交!')
    }
    let subForm = JSON.parse(JSON.stringify(form.value))
    if(subForm.adjustRange === 'report-sensor') {
        if(!selectedRowKeys.value.length) {
            return message.warning('请选择报告后提交！')
        } else {
            function arraysEqual(arr1:any, arr2:any) {
                return new Set(arr1).size === new Set(arr2).size && Array.from(new Set(arr1)).every((value) => new Set(arr2).has(value));
            }
            let arr = subForm.relatedReports.map((p:any) => p.reportId)
            let r = arraysEqual(arr, selectedRowKeys.value)
            if(!r) {
                return message.warning('选择的报告与预览的报告不同，请重新预览后提交！')
            }
        }
    }

    subForm.machineId = machine_id.value
    subForm.startDate = searchModel.value.startDate
    subForm.endDate = searchModel.value.endDate

    let result = await request.post('/api/threshold-feedbacks', subForm)
    if(result?.data) {
        message.success('提交成功！')
        getBeekList()
    } else {
        message.warning('提交失败！')
    }
}



// 获取反馈列表
let feedList:any = ref([])
const getBeekList = async() => {
    let config: any = {
        params: {
            machineId: machine_id.value,
            skip: 1,
            take: 7,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/threshold-feedbacks', config)
    feedList.value = result?.data?.list || []
}

// 查看详情
let visible:any = ref(false)
let viewForm:any = ref({
    reports: []
})
let viewSearch:any = ref({
    sensorId: '',
})
let sensorListView:any = ref([])
let viewAdjustList:any = ref([])
let faultChartSelectlengedView:any = {}
let healthChartSelectlengedView:any = {}
let myChartE:any = null
let myChartF:any = null
let viewAdjustData:any = ref([])
let viewConfirmData:any = ref([])
let isShowChart2:any = ref(true)
const view = (ev:any) => {
    viewForm.value = ev
    visible.value = true
    if(!viewForm.value?.reports) {
        viewForm.value.reports = []
    }
    viewAdjustData.value = [{
        adjustDate: ev.adjustDate,
        adjustRange: ev.adjustRange,
        adjustDegree: ev.adjustDegree,
        adjustReason: ev.adjustReason,
    }]
    viewConfirmData.value = [{
        confirmDate: ev.confirmDate,
        status: ev.status,
        confirmReason: ev.confirmReason,
    }]
    let isDevAll = ev.adjustRange === 'machine' ? true : false
    let detailInfos = ev?.adjustDetails || []
    let machineScores = ev?.machineScores || []
    viewAdjustList.value = []

    let sensorIds = [... new Set(detailInfos.map((d:any) => d.sensorId))]
    if(isDevAll) {
        sensorListView.value = [
            {sensorPlace: '全设备测点', id: '' }
        ]
        viewSearch.value.sensorId = ''
    } else {
        sensorListView.value = []
        viewSearch.value.sensorId = sensorIds[0]
    }



    // sensorListView.value = []
    // viewSearch.value.sensorId = sensorIds[0]
    sensorIds.map((d:any) => {
        if(isDevAll) {
            let faults:any = []
            faultName.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });
            faultNameII.map((d: any) => {
                faults.push({
                    column: d.column,
                    title: d.title
                })
            });

            let dateList = machineScores.map((p:any) => p.date)
            faults.map((f:any) => {
                f.dateList = dateList
                f.data = []
            })
            machineScores.map((d:any) => {
                faults.map((f:any) => {
                    f.data.push([d.date, d[f.column]])
                })
            })
            let obj = {
                sensorId: '',
                dataList: faults
            }
            viewAdjustList.value.push(obj)
        } 


        let sensorInfo = sensorListAll.value.find((p:any) => p.id == d)
        sensorListView.value.push(sensorInfo)
        let detailInfoSens = detailInfos.filter((p:any) => p.sensorId == d)
        let dataList:any = []
        detailInfoSens.map((s:any) => {
            let dates = s.result?.date || []
            let datas = s.result?.[s.funcTag] || []
            let data = []
            for (let index = 0; index < dates.length; index++) {
                let date = dates[index]
                let data2 = datas[index]
                data.push([date.slice(5, 19), data2])
            }

            let obj1 = {
                column: s.funcTag,
                title: s.faultName,
                dateList: s.result?.date || [],
                data
            }

           
            dataList.push(obj1)
        })
        let obj = {
            sensorId: d,
            dataList
        }

        viewAdjustList.value.push(obj)
    })

    getChartDataView()
}

const selectSensor3 = (ev:any) => {
    getChartDataView()
}

const getChartDataView = () => {
    let adjustListInfo = viewAdjustList.value.find((p:any) => p.sensorId == viewSearch.value.sensorId)

    let dataList = adjustListInfo.dataList || []
    let fault = dataList.filter((p:any) => p.column.includes('S'))
    let faultII = dataList.filter((p:any) => p.column.includes('T'))
    let faultNames = fault.map((p:any) => p.title)
    let faultNameIIs = faultII.map((p:any) => p.title)
    healthChartSelectlengedView = {}
    faultChartSelectlengedView = {}
    faultNames.map((d:any) => {
        faultChartSelectlengedView[langObj.value[d] || d] = true
    })
    faultNameIIs.map((d:any) => {
        healthChartSelectlengedView[langObj.value[d] || d] = true
    })
    isShowChart2.value = false
    setTimeout(() => {
        isShowChart2.value = true
    },200)

    setTimeout(() => {
        initChartE(faultII)
        initChartF(fault)
    }, 1000);
}

const initChartE = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartE")) return;
    myChartE = ec.init(document.getElementById("optimizationChartE"));
    myChartE.resize();
    myChartE.clear()

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 10,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(healthChartSelectlengedView, DataInfo);

    
    if (option && typeof option === "object") {
        myChartE && myChartE.clear();
        myChartE && myChartE.setOption(option, true);
    }

    myChartE
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlengedView = params.selected;
        Init(healthChartSelectlengedView, DataInfo);
        if (option && typeof option === "object") {
            myChartE?.clear();
            myChartE?.setOption(option, true);
        }
    });
}

const initChartF = (data:any) => {
    let maxLengthIndex = 0;
    let currentLength = 0;
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.data.length > currentLength) {
            currentLength = item.data.length;
            maxLengthIndex = i;
        }
    }

    let xData = data && data.length ? data[maxLengthIndex].data.map((p: any) => p[0]) : [];
    const ec = echarts as any;
    if (!document.getElementById("optimizationChartF")) return;
    myChartF = ec.init(document.getElementById("optimizationChartF"));
    myChartF.resize();

    let colors: any[] = [];
    let option: any = null;
    let LengData: any[] = [],
        SelectedData = {},
        YaData,
        SeriesData,
        DataInfo: any[] = [];
    colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
    ];

    data.map((item: any, index: any) => {
        if (item.data && item.data.length) {
            LengData.push(langObj.value[item.title] || item.title);
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: "line",
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true,
            });
        }
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {
            // 如果该图例状态为false时，则跳过 不push
            if (sel[LengData[n]]) {
                YaData.push({
                type: "value",
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                    lineStyle: {
                    color: [
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgb(117, 116, 116)",
                        "rgba(250, 60, 88, 1)",
                        "rgba(254, 178, 7, 1)",
                        "rgb(117, 116, 116)",
                    ],
                    },
                },
                position: "left",
                offset: "-10",
                axisLine: {
                    lineStyle: {
                    color: theme.value == 'white' ? '#333' : "#fff"
                    },
                },
                });
            } else {
                Datas[n].data = [];
            }
            Datas[n].yAxisIndex = 0;
            SeriesData.push(Datas[n]);
            }
            if (YaData.length == 0) {
            YaData = [{ type: "value" }];
            }
            option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: theme.value == 'white' ? '#333' : "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: theme.value == 'white' ? '#333' : "#fff",
                fontSize: 16,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",

                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: theme.value == 'white' ? '#333' : "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }

    Init(faultChartSelectlengedView, DataInfo);

    
    if (option && typeof option === "object") {
        myChartF && myChartF.clear();
        myChartF && myChartF.setOption(option, true);
    }

    myChartF
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlengedView = params.selected;
        Init(faultChartSelectlengedView, DataInfo);
        if (option && typeof option === "object") {
            myChartF?.clear();
            myChartF?.setOption(option, true);
        }
    });
}


// 获取faultNames
const getFaultNames = (ev:any) => {
    let list = ev.map((p:any) => p.faultName)
    return list.join(",")
}
const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_close_outlined = _resolveComponent("close-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: _normalizeStyle([{"width":"250px","position":"fixed","height":"calc(100vh - 132px)"}, { top: _unref(scrollTop) }])
        }, [
          _createVNode(machineListLeftComponent, {
            onSelectMachine: selectMachine,
            type: 1,
            mode: 1
          })
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_unref(isAdjust))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('诊断趋势')), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('请选择数据集')) + "：", 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 1 }]),
                      style: _normalizeStyle([{
                                    background:
                                    _unref(searchModel).type == 1 ? '#072498' : '',
                                    }, {"border-radius":"5px 0 0 5px"}]),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (
                                    (_unref(searchModel).type = 1), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 2 }]),
                      style: _normalizeStyle({
                                    background:
                                    _unref(searchModel).type == 2 ? '#072498' : '',
                                    }),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                                    (_unref(searchModel).type = 2), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 3 }]),
                      style: _normalizeStyle({
                                    background:
                                    _unref(searchModel).type == 3 ? '#072498' : '',
                                    }),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (
                                    (_unref(searchModel).type = 3), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近两月"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 0 }]),
                      style: _normalizeStyle([{
                                    background:
                                    _unref(searchModel).type == 0 ? '#072498' : '',
                                    }, {"border-radius":"0 5px 5px 0"}]),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(searchModel).type = 0))
                    }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                  ]),
                  (_unref(searchModel).type == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(searchModel).date,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).date) = $event))
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (getChartData())),
                    class: "btn3 ml-2"
                  }, _toDisplayString(_unref(langObj)["确认"]), 1)
                ]),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(searchModel).sensorId,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).sensorId) = $event)),
                  style: {"min-width":"150px"},
                  onChange: selectSensor
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListAll), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)["健康指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(langObj)["振动指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(isAdjust))
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('诊断趋势')), 1),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('请选择数据集')) + "：", 1),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 1 }]),
                      style: _normalizeStyle([{
                                    background:
                                    _unref(searchModel).type == 1 ? '#072498' : '',
                                    }, {"border-radius":"5px 0 0 5px"}]),
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (
                                    (_unref(searchModel).type = 1), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 2 }]),
                      style: _normalizeStyle({
                                    background:
                                    _unref(searchModel).type == 2 ? '#072498' : '',
                                    }),
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (
                                    (_unref(searchModel).type = 2), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 3 }]),
                      style: _normalizeStyle({
                                    background:
                                    _unref(searchModel).type == 3 ? '#072498' : '',
                                    }),
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (
                                    (_unref(searchModel).type = 3), getChartData()
                                    ))
                    }, _toDisplayString(_unref(langObj)["近两月"]), 7),
                    _createElementVNode("div", {
                      class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(searchModel).type == 0 }]),
                      style: _normalizeStyle([{
                                    background:
                                    _unref(searchModel).type == 0 ? '#072498' : '',
                                    }, {"border-radius":"0 5px 5px 0"}]),
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(searchModel).type = 0))
                    }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                  ]),
                  (_unref(searchModel).type == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(searchModel).date,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(searchModel).date) = $event))
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (getChartData())),
                    class: "btn3 ml-2"
                  }, _toDisplayString(_unref(langObj)["确认"]), 1)
                ]),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(searchModel).sensorIdAdj,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(searchModel).sensorIdAdj) = $event)),
                  style: {"min-width":"150px"},
                  onChange: selectSensor2
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListAdj), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)["健康指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(langObj)["振动指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(getLanguage)('诊断报告')), 1),
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: reportColumns,
            "data-source": _unref(reportList),
            "row-selection": {
                        selectedRowKeys: _unref(selectedRowKeys),
                        onChange: onSelectChange,
                    },
            pagination: false,
            rowKey: "reportId",
            bordered: "",
            scroll: { y: 390 }
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'condition')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(conditionList).find(p => p.value === text)?.label)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'falult')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_unref(langObj)[getFaultNames(record.sensorFaults)] || getFaultNames(record.sensorFaults)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.dataIndex === 'adjustCondition')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(conditionList).find(p => p.value === text)?.label)), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "row-selection"])
        ]),
        _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(getLanguage)('调整模型')), 1),
        _createVNode(_component_a_table, {
          style: {"width":"100%"},
          size: "small",
          "row-class-name": getRowClassName,
          columns: scoreColumns,
          "data-source": _unref(adjustData),
          pagination: false,
          bordered: ""
        }, {
          bodyCell: _withCtx(({ column, text, record }) => [
            (column.dataIndex === 'adjustRange')
              ? (_openBlock(), _createBlock(_component_a_radio_group, {
                  key: 0,
                  value: record.adjustRange,
                  "onUpdate:value": ($event: any) => ((record.adjustRange) = $event)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(adjustRangeList), (item, index2) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        key: index2,
                        value: item.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)(item.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value"]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'adjustDegree')
              ? (_openBlock(), _createBlock(_component_a_select, {
                  key: 1,
                  ref: "select",
                  value: record.adjustDegree,
                  "onUpdate:value": ($event: any) => ((record.adjustDegree) = $event),
                  style: {"min-width":"150px"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(adjustDegreeList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)(item.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value"]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'adjustReason')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 2,
                  value: record.adjustReason,
                  "onUpdate:value": ($event: any) => ((record.adjustReason) = $event)
                }, null, 8, ["value", "onUpdate:value"]))
              : _createCommentVNode("", true),
            (column.dataIndex === 'act')
              ? (_openBlock(), _createBlock(_component_a_spin, {
                  key: 3,
                  spinning: _unref(isLoading)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (adjust(record))
                    }, _toDisplayString(_unref(getLanguage)('预览调整')), 9, _hoisted_32)
                  ]),
                  _: 2
                }, 1032, ["spinning"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data-source"]),
        _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(getLanguage)('提交后，当前报告状态不变，后续生成的分数会根据选择的新增的规则生成！')), 1),
        _createElementVNode("div", _hoisted_34, _toDisplayString(_unref(getLanguage)('当前数据集')) + "：" + _toDisplayString(_unref(searchModel).startDate) + " - " + _toDisplayString(_unref(searchModel).endDate) + "，" + _toDisplayString(_unref(getLanguage)('新分数规则会根据选中的数据集生成')), 1),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", {
            class: "btn3 ml-2",
            onClick: submit
          }, _toDisplayString(_unref(langObj)["提交"]), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _createTextVNode(_toDisplayString(_unref(getLanguage)('模型调整历史')) + " ", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedList), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (view(item)),
            class: "w-full mt-2 cursor-pointer workBg",
            key: index
          }, [
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('调整程度')) + ":", 1),
              _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(getLanguage)(_unref(adjustDegreeList).find(p => p.value === item.adjustDegree)?.label)), 1),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "box1",
                style: _normalizeStyle({color: _unref(statusList).find(p => p.value === item.status)?.color})
              }, _toDisplayString(_unref(getLanguage)(_unref(statusList).find(p => p.value === item.status)?.label)), 5)
            ]),
            _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(getLanguage)('调整范围')) + ": " + _toDisplayString(_unref(getLanguage)(_unref(adjustRangeList).find(p => p.value === item.adjustRange)?.label)), 1),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('调整')) + "： " + _toDisplayString(item.adjustMember), 1),
              _createElementVNode("div", _hoisted_42, _toDisplayString(item.adjustDate), 1)
            ])
          ], 8, _hoisted_37))
        }), 128)),
        (_unref(visible))
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(getLanguage)('分数调整历史')), 1),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(visible) //@ts-ignore
 ? visible.value = false : visible=false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('诊断趋势')), 1),
                _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(getLanguage)('数据集')) + "： " + _toDisplayString(_unref(viewForm).startDate) + " - " + _toDisplayString(_unref(viewForm).endDate), 1),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(viewSearch).sensorId,
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(viewSearch).sensorId) = $event)),
                  style: {"min-width":"150px"},
                  onChange: selectSensor3
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorListView), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, _toDisplayString(_unref(langObj)["健康指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart2))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_51))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(langObj)["振动指标诊断分数趋势图"]), 1),
                  (_unref(isShowChart2))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_54))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('诊断报告')), 1),
              _createElementVNode("div", _hoisted_55, [
                _createVNode(_component_a_table, {
                  style: {"width":"100%"},
                  size: "small",
                  "row-class-name": getRowClassName,
                  columns: reportColumns,
                  "data-source": _unref(viewForm).reports,
                  pagination: false,
                  bordered: ""
                }, {
                  bodyCell: _withCtx(({ column, text, record }) => [
                    (column.dataIndex === 'condition')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(conditionList).find(p => p.value === text)?.label)), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    (column.dataIndex === 'falult')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(langObj)[getFaultNames(record.sensorFaults)] || getFaultNames(record.sensorFaults)), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    (column.dataIndex === 'adjustCondition')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(conditionList).find(p => p.value === text)?.label)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["data-source"])
              ]),
              _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(getLanguage)('调整模型')), 1),
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: scoreColumns2,
                "data-source": _unref(viewAdjustData),
                pagination: false,
                bordered: ""
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'adjustRange')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(adjustRangeList).find(p => p.value===text)?.label)), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'adjustDegree')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(adjustDegreeList).find(p => p.value===text)?.label)), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"]),
              _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(getLanguage)('确认')), 1),
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: confirmColumns,
                "data-source": _unref(viewConfirmData),
                pagination: false,
                bordered: ""
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)(_unref(statusList).find(p => p.value===text)?.label)), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})