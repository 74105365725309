import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  style: {"width":"350px"}
}
const _hoisted_3 = {
  class: "w-full mt-2 flex",
  style: {"justify-content":"center"}
}
const _hoisted_4 = { class: "mt-1 w-full flex items-center" }
const _hoisted_5 = { class: "flex-1 flex items-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "w-full"
}
const _hoisted_8 = {
  class: "w-full mt-2 flex",
  style: {"justify-content":"center"}
}
const _hoisted_9 = { class: "mt-1 w-full flex items-center" }
const _hoisted_10 = { class: "flex-1 flex items-center" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 2,
  class: "mt-4 flex items-center"
}
const _hoisted_13 = {
  class: "flex-1 flex",
  style: {"justify-content":"center","height":"180px"}
}
const _hoisted_14 = {
  key: 3,
  class: "picBox mt-4 flex items-center"
}
const _hoisted_15 = { class: "flex-1" }
const _hoisted_16 = {
  class: "flex",
  style: {"justify-content":"center","width":"70%","height":"14vh","margin":"0 auto"}
}
const _hoisted_17 = { style: {"text-align":"center"} }
const _hoisted_18 = { class: "page" }

import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";


export default /*@__PURE__*/_defineComponent({
  __name: 'picture-list',
  props: {
  type: null,
  picList: [],
},
  setup(__props) {

let type = ref(1);
let picList: any = ref([]);

const props: any = __props;

type.value = props.type;
picList.value = props.picList;
let selectPic = ref("");
let selectName = ref("");
let selectIndex = 0;

onMounted(() => {
  init();
});

watch(
  () => props.picList,
  (newValue, oldValue) => {

    picList.value = props.picList;
    setTimeout(() => {
      init();
    }, 200);
  }
);

const init = async () => {
  if (!picList.value) return;
  selectPic.value = "";
  picList.value = picList.value.filter((p: any) => p.url);
 
  if (picList.value && picList.value.length) {
    selectPic.value = picList.value[0].url;
    selectName.value = picList.value[0].name;
    selectIndex = 0;
  }

};

const switchover = (ev: any) => {
  //true下一个  false上一个
  let length = picList.value.length;
  if (length == 0) {
    return;
  }
  if (ev) {
    if (selectIndex < length - 1) {
      selectIndex = selectIndex + 1;
    } else {
      selectIndex = 0;
    }
  } else {
    if (selectIndex == 0) {
      selectIndex = length - 1;
    } else {
      selectIndex = selectIndex - 1;
    }
  }
  selectPic.value = picList.value[selectIndex].url;
  selectName.value = picList.value[selectIndex].name;
};

return (_ctx: any,_cache: any) => {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_left_outlined = _resolveComponent("left-outlined")!
  const _component_right_outlined = _resolveComponent("right-outlined")!

  return (_unref(picList)?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(type) === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_unref(selectPic))
                  ? (_openBlock(), _createBlock(_component_a_image, {
                      key: 0,
                      style: {"max-width":"400px","max-height":"180px"},
                      src: _unref(selectPic),
                      alt: ""
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (switchover(false)))
                }, [
                  _createVNode(_component_left_outlined, { class: "opt8 size20" })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(picList), (item, index) => {
                    return (_openBlock(), _createElementBlock("img", {
                      class: "flex-1",
                      key: index,
                      style: {"height":"48px","max-width":"48px"},
                      src: item.url,
                      alt: ""
                    }, null, 8, _hoisted_6))
                  }), 128))
                ]),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (switchover(true)))
                }, [
                  _createVNode(_component_right_outlined, { class: "opt8 size20" })
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(type) === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_unref(selectPic))
                  ? (_openBlock(), _createBlock(_component_a_image, {
                      key: 0,
                      style: {"max-width":"400px","max-height":"180px"},
                      src: _unref(selectPic),
                      alt: ""
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (switchover(false))),
                  class: "cursor-pointer"
                }, [
                  _createVNode(_component_left_outlined, { class: "opt8 size20" })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(picList), (item, index) => {
                    return (_openBlock(), _createElementBlock("img", {
                      class: "flex-1 ml-1",
                      key: index,
                      style: {"height":"48px","max-width":"48px"},
                      src: item.url,
                      alt: ""
                    }, null, 8, _hoisted_11))
                  }), 128))
                ]),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (switchover(false))),
                  class: "cursor-pointer"
                }, [
                  _createVNode(_component_right_outlined, { class: "opt8 size20" })
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(type) === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (switchover(false)))
              }, [
                _createVNode(_component_left_outlined, { style: {"font-size":"35px","opacity":"0.5"} })
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_a_image, {
                  class: "pic",
                  src: _unref(selectPic),
                  alt: "",
                  style: {"max-width":"400px","max-height":"180px"}
                }, null, 8, ["src"])
              ]),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (switchover(true)))
              }, [
                _createVNode(_component_right_outlined, { style: {"font-size":"35px","opacity":"0.5"} })
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(type) === 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", {
                class: "cursor-pointer",
                style: {"margin-left":"2%"},
                onClick: _cache[6] || (_cache[6] = ($event: any) => (switchover(false)))
              }, [
                _createVNode(_component_left_outlined, { style: {"font-size":"1.2vw","opacity":"0.5"} })
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_a_image, {
                    src: _unref(selectPic),
                    alt: "",
                    style: {"max-width":"100%","max-height":"100%"}
                  }, null, 8, ["src"])
                ]),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(selectName)), 1)
              ]),
              _createElementVNode("div", {
                class: "cursor-pointer",
                style: {"margin-right":"2%"},
                onClick: _cache[7] || (_cache[7] = ($event: any) => (switchover(true)))
              }, [
                _createVNode(_component_right_outlined, { style: {"font-size":"1.2vw","opacity":"0.5"} })
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", null, _toDisplayString(_unref(selectIndex) + 1) + " / " + _toDisplayString(_unref(picList).length), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})