import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef } from "vue"

const _hoisted_1 = {
  class: "w-full theme-common",
  style: {"padding":"16px 16px 30px 16px","height":"calc(100vh - 100px)","overflow-y":"scroll","background-color":"#000a32"}
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = {
  class: "flex-1 flex ml-4",
  style: {"color":"#fff"}
}
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex items-center"
}
const _hoisted_6 = { class: "btn1 btn1-select" }
const _hoisted_7 = {
  class: "w-full flex px-4 mt-2 theme-bg theme-box-title",
  style: {"border":"1px solid #4a5983","background":"#07249933"}
}
const _hoisted_8 = { class: "w-full flex mt-2 theme-bg" }
const _hoisted_9 = { style: {"min-width":"250px"} }
const _hoisted_10 = { class: "flex-1" }
const _hoisted_11 = { class: "flex-1 content ml-2 theme-bg" }
const _hoisted_12 = { class: "w-full flex items-center" }
const _hoisted_13 = { class: "flex items-center mt-4" }
const _hoisted_14 = { class: "flex ml-2" }
const _hoisted_15 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_16 = { class: "mt-4 content ml-2 theme-bg theme-font-black-color theme-zdy-black" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { style: {"color":"#fff"} }
const _hoisted_26 = {
  key: 0,
  style: {"color":"#fff"}
}
const _hoisted_27 = {
  key: 1,
  class: "ms-2 text-red-600"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 1,
  class: "ms-2 text-red-600"
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 1,
  class: "ms-2 text-red-600"
}
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 0,
  class: "flex"
}
const _hoisted_38 = { class: "flex-1 ms-2 pb-2 box-bg pl-2 pt-2 pr-2" }
const _hoisted_39 = { class: "mt-2" }

import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate2, } from "@/common/tools";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";
import SimpleModel from "@/components/model/simple-model.vue";
import AlarmItem from "@/components/system/common/AlarmItem.vue";
import { getLanguage } from "@/common/translate";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
interface SmartThresholdPrompt {
  level1Prompt: string;
  level2Prompt: string;
  level3Prompt: string;
}
interface AreaOptions {
  // x轴值分段数组
  xtp0Arr: number[];
  xtp1Arr: number[];
  xtp2Arr: number[];
  xtp3Arr: number[];

  // 四大区间点数
  ytp0: number;
  ytp1: number;
  ytp2: number;
  ytp3: number;

  threshold1: number;
  threshold2: number;
  threshold3: number;
}

interface AreaStatistics {
  // 区域空间百分比
  healthAreaPercent: number | null;
  availableAreaPercent: number | null;
  warningAreaPercent: number | null;
  faultAreaPercent: number | null;

  // 点数百分比
  healthPointsPercent: number;
  availablePointsPercent: number;
  warningPointsPercent: number;
  faultPointsPercent: number;

  // 区间起始索引
  availableThresholdStartIndex: number;
  warningThresholdStartIndex: number;
  faultThresholdStartIndex: number;

  // 各区间点数统计
  ytp0: number;
  ytp1: number;
  ytp2: number;
  ytp3: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'simple',
  setup(__props) {

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const redirectToGroupModel = () => {
  router.push("group-model");
}

let sensorInfo: any = ref({});

class ThresholdItem {
  level1Threshold: number | null;
  level1RangeCount: number | null;
  level1LimitCount: number | null;
  level1IntervalDays: number | null = 7;
  level1IntervalTimes: number | null = 1;
  level2Threshold: number | null;
  level2RangeCount: number | null;
  level2LimitCount: number | null;
  level2IntervalDays: number | null = 7;
  level2IntervalTimes: number | null = 1;
  level3Threshold: number | null;
  level3RangeCount: number | null;
  level3LimitCount: number | null;
  level3IntervalDays: number | null = 7;
  level3IntervalTimes: number | null = 1;
  level4Threshold: number | null;
  level4IntervalDays: number | null = 7;
  level4IntervalTimes: number | null = 1;
}

let form: any = ref(new ThresholdItem());

let smartThresholdPrompt = ref<SmartThresholdPrompt>({
  level1Prompt: "1111",
  level2Prompt: "2222",
  level3Prompt: "3333",
});

let isLoadingPrompt = ref(false);

let thresholdChangeTimeout: any = null; // 防抖
watch([() => form.value.level1Threshold, () => form.value.level2Threshold, () => form.value.level3Threshold,
() => form.value.level1RangeCount, () => form.value.level2RangeCount, () => form.value.level3RangeCount],
  ([val, val2, val3, val4, val5, val6], [old, old2, old3, old4, old5, old6]) => {

    // input变更时, 生成累计笔数提示信息, 生成的阈值不回写到input里

    if (!val4 && !val5 && !val6) return; // 必须填写一个阈值
    if (!val && !val2 && !val3) return;

    let changedCount = 0;
    if (val != old) changedCount++;
    if (val2 != old2) changedCount++;
    if (val3 != old3) changedCount++;
    if (val4 != old4) changedCount++;
    if (val5 != old5) changedCount++;
    if (val6 != old6) changedCount++;
    if (changedCount > 1) return;

    if (thresholdChangeTimeout) {
      clearTimeout(thresholdChangeTimeout)
    }

    thresholdChangeTimeout = setTimeout(async () => {
      isLoadingPrompt.value = true;
      let data = await doGenerateThreshold(null)
      let prompts = [];
      if (data && data.length >= 4) {
        prompts = data[3];
      }
      //SetGeneratedThreshold(data as any[]);
      setThresholdPrompt(prompts as any[]);
    }, 1500)
  });

let thresholdInputChangeTimeout: any = null; // 防抖
const onChangeThresholdInput = (value: any, level = 1) => {
  // 更新分割线位置

  if (level !== 1 && level !== 2 && level !== 3) return;

  if (thresholdInputChangeTimeout) {
    clearTimeout(thresholdInputChangeTimeout)
  }

  thresholdInputChangeTimeout = setTimeout(async () => {
    // 找到当前阈值对应的x轴索引
    let xIndex = xData.findIndex((p) => p >= value);
    if (xIndex == -1 && value > xData[xData.length - 1]) {
      xIndex = xData.length - 1;
    } else if (xIndex === 0 && value < xData[0]) {
      xIndex = -1;
    }

    // 找到当前阈值对应的x轴像素
    let xMovedPixel = myChart.convertToPixel({ xAxisId: "x0" }, xIndex);

    if (level === 1) {
      if ((warningThresholdStartIndex > -1 && xIndex >= warningThresholdStartIndex)
        || (faultThresholdStartIndex > -1 && xIndex >= faultThresholdStartIndex)) {
        message.warn("可用阈值必须小于警戒阈值和故障阈值")
        updateSplitLine();
        return;
      }
      availableThresholdStartIndex = xIndex;
      availableThresholdSplitPixel = xMovedPixel + halfPixel;
    } else if (level === 2) {
      if ((faultThresholdStartIndex > -1 && xIndex >= faultThresholdStartIndex)
        || (availableThresholdStartIndex > -1 && xIndex <= availableThresholdStartIndex)) {
        message.warn("警戒阈值必须大于可用阈值，且小于故障阈值")
        updateSplitLine();
        return;
      }
      warningThresholdStartIndex = xIndex;
      warningThresholdSplitPixel = xMovedPixel + halfPixel;
    } else {
      if ((availableThresholdStartIndex > -1 && xIndex <= availableThresholdStartIndex)
        || warningThresholdStartIndex > -1 && xIndex <= warningThresholdStartIndex) {
        message.warn("故障阈值必须大于警戒阈值和可用阈值")
        updateSplitLine();
        return;
      }
      faultThresholdStartIndex = xIndex;
      faultThresholdSplitPixel = xMovedPixel + halfPixel;
    }

    updateChartSeries(null);
    updateSplitLine();
  }, 1500)
}

let compareDataType: any = ref([]);

/** 初始阈值(标准/参考阈值) */
let standardThreshold: any = ref(new ThresholdItem());

/** 当前阈值/配置的阈值 */
let currentThreshold: any = ref(new ThresholdItem());

/** 智能统计/模板阈值 */
let smartThreshold: any = ref(new ThresholdItem());

let rdoSimpleDataType = ref(0);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadDataTypes();
    if (result.data.dataType) {
      compareDataType.value = dataTypes.value.filter((p: any) =>
        result.data.dataType.includes(p.name)
      );
    } else {
      compareDataType.value = [];
    }

    await getQuotaAlarmConfig();
    await selectDataType();
  }
};

onMounted(() => {
  loadDataTypes();
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

// 四大区间起始索引
let availableThresholdStartIndex: number = -1;
let warningThresholdStartIndex: number = -1;
let faultThresholdStartIndex: number = -1;

// 区间分割线像素
let availableThresholdSplitPixel: number;
let warningThresholdSplitPixel: number;
let faultThresholdSplitPixel: number;
let halfPixel: number;

// 四大区间百分比
let healthAreaPercent: number | null = null;
let availableAreaPercent: number | null = null;
let warningAreaPercent: number | null = null;
let faultAreaPercent: number | null = null;

let healthPointsPercent: number = 0;
let availablePointsPercent: number = 0;
let warningPointsPercent: number = 0;
let faultPointsPercent: number = 0;

let xData: number[] = [];
let yData: number[] = [];
let myChart: any = null;
const updateChart = (quotaMap: Map<number, number> | null,
  threshold1: number = 0, threshold2: number = 0, threshold3: number = 0) => {
  if (!quotaMap || quotaMap.size == 0) {
    myChart.clear();
    return;
  }

  xData = [];
  yData = [];

  myChart.clear();

  // 若沒有传递阈值参数，使用表单里的阈值
  if (!threshold1 && !threshold2 && !threshold3) {
    threshold1 = Number(form.value.level1Threshold);
    threshold2 = Number(form.value.level2Threshold);
    threshold3 = Number(form.value.level3Threshold);
  }

  let hasThreshold: boolean = threshold1 > 0 && threshold2 > 0 && threshold3 > 0;

  if (threshold1 > 0 && (
    threshold1 >= (threshold2 ?? 0) ||
    threshold1 >= (threshold2 ?? 0))) {
    //可用报警阈值必须小于警戒报警阈值和故障报警阈值
    hasThreshold = false;
  }

  if (threshold2 > 0 && (threshold2 >= (threshold3 ?? 0))) {
    //警戒报警阈值必须小于故障报警阈值;
    hasThreshold = false;
  }

  // 四大区间起始索引
  availableThresholdStartIndex = -1;
  warningThresholdStartIndex = -1;
  faultThresholdStartIndex = -1;

  // 四大区间百分比
  healthAreaPercent = null;
  availableAreaPercent = null;
  warningAreaPercent = null;
  faultAreaPercent = null;

  healthPointsPercent = 0;
  availablePointsPercent = 0;
  warningPointsPercent = 0;
  faultPointsPercent = 0;


  // x轴值分段数组
  let xtp0Arr: number[] = [];
  let xtp1Arr: number[] = [];
  let xtp2Arr: number[] = [];
  let xtp3Arr: number[] = [];

  // 四大区间点数
  let ytp0: number = 0;
  let ytp1: number = 0;
  let ytp2: number = 0;
  let ytp3: number = 0;

  for (let [key, value] of quotaMap) {
    xData.push(key);
    yData.push(value);

    // 根据阈值将x轴数据分为四个区间数组
    if (hasThreshold) {
      if (key <= threshold1) {
        xtp0Arr.push(key);
        ytp0 += value;
      } else if (key <= threshold2) {
        xtp1Arr.push(key);
        ytp1 += value;
      } else if (key <= threshold3) {
        xtp2Arr.push(key);
        ytp2 += value;
      } else {
        xtp3Arr.push(key);
        ytp3 += value;
      }
    }
  }


  let xName = "数据数值";
  if (selectedDataType.unit) {
    xName += `(${selectedDataType.unit})`;
  }

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any) {
        let result = "";
        if (params[0].componentIndex > 0) {
          params.forEach((item: any) => {
            result += item.marker + item.name + ": " + "<br/>";
          });
        } else {
          params.forEach((item: any) => {
            result += item.marker + item.name + ": " + item.value + "<br/>";
          });
        }

        return result;
      },
    },
    grid: [
      {
        id: "gridBar",
        show: true,
        top: 70,
        //bottom: 101,
        left: 50,
        right: 30,
      },
      {
        id: 'gridAreaLabel',
        show: true,
        height: 50,
        top: 10,
        bottom: 40,
        left: 50,
        right: 30,
        borderWidth: 0,
      }
    ],
    xAxis: [
      {
        // 主柱状图
        type: "category",
        id: "x0",
        name: xName,
        nameLocation: "center",
        nameTextStyle: {
          padding: [15, 0, 0, 0],
        },
        data: xData,
        gridIndex: 0,
        zLevel: 1
      },
      {
        // 阈值分块
        type: 'category',
        id: "xCategory",
        show: false,
        gridIndex: 1,
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        tooltip: { show: true }
        //zLevel: 1,
      }
    ],
    yAxis: [
      {
        type: "value",
        name: "数据点数",
        gridIndex: 0,
      },
      {
        type: "value",
        gridIndex: 1,
        axisLabel: { show: false },
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false }
      }
    ],
    //series: series
  };

  option && myChart.setOption(option);

  updateChartSeries({
    xtp0Arr, xtp1Arr, xtp2Arr, xtp3Arr,
    ytp0, ytp1, ytp2, ytp3
  } as AreaOptions);

  let a = myChart.convertToPixel({ xAxisIndex: 0, gridIndex: 0, }, 0);
  let b = myChart.convertToPixel({ xAxisIndex: 0, gridIndex: 0, }, 1);
  halfPixel = (b - a) / 2;
  let initPixel = 5;

  if (availableThresholdStartIndex < 0) {
    availableThresholdSplitPixel = initPixel;
  } else {
    availableThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ xAxisId: "x0" }, availableThresholdStartIndex) + halfPixel);
  }

  if (warningThresholdStartIndex < 0) {
    warningThresholdSplitPixel = initPixel + 3;
  } else {
    warningThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ xAxisId: "x0" }, warningThresholdStartIndex) + halfPixel);
  }

  if (faultThresholdStartIndex < 0) {
    faultThresholdSplitPixel = initPixel + 6;
  } else {
    faultThresholdSplitPixel = Math.ceil(myChart.convertToPixel({ xAxisId: "x0" }, faultThresholdStartIndex) + halfPixel);
  }

  if (warningThresholdStartIndex === faultThresholdStartIndex) {
    warningThresholdSplitPixel = faultThresholdSplitPixel - 3;
  }
  if (availableThresholdStartIndex === warningThresholdStartIndex) {
    availableThresholdSplitPixel = warningThresholdSplitPixel - 3;
  }

  //console.log("availableThresholdStartIndex", availableThresholdStartIndex);
  //console.log("warningThresholdStartIndex", warningThresholdStartIndex);
  //console.log("faultThresholdStartIndex", faultThresholdStartIndex);
  //console.log("availableThresholdSplitPixel", availableThresholdSplitPixel);
  //console.log("warningThresholdSplitPixel", warningThresholdSplitPixel);
  //console.log("faultThresholdSplitPixel", faultThresholdSplitPixel);

  updateSplitLine();
};

function calculateAreaStatistics(
  options: AreaOptions | null,
  threshold1: number,
  threshold2: number,
  threshold3: number,
  quotaMap: Map<number, number>): AreaStatistics {

  let xtp0Arr = options?.xtp0Arr || [];
  let xtp1Arr = options?.xtp1Arr || [];
  let xtp2Arr = options?.xtp2Arr || [];
  let xtp3Arr = options?.xtp3Arr || [];
  let sumDataLength = xtp0Arr.length + xtp1Arr.length + xtp2Arr.length + xtp3Arr.length;

  let ytp0 = options?.ytp0 || 0;
  let ytp1 = options?.ytp1 || 0;
  let ytp2 = options?.ytp2 || 0;
  let ytp3 = options?.ytp3 || 0;

  const hasThreshold = threshold1 > 0 && threshold2 > 0 && threshold3 > 0;

  // 根据阈值分组统计
  if (options == null && hasThreshold) {
    for (let [key, value] of quotaMap) {
      if (key <= threshold1) {
        xtp0Arr.push(key);
        ytp0 += value;
      } else if (key <= threshold2) {
        xtp1Arr.push(key);
        ytp1 += value;
      } else if (key <= threshold3) {
        xtp2Arr.push(key);
        ytp2 += value;
      } else {
        xtp3Arr.push(key);
        ytp3 += value;
      }
    }
  }

  const totalPoints = ytp0 + ytp1 + ytp2 + ytp3;
  const totalLength = xData.length;

  let result: AreaStatistics = {
    healthAreaPercent: null,
    availableAreaPercent: null,
    warningAreaPercent: null,
    faultAreaPercent: null,
    healthPointsPercent: 0,
    availablePointsPercent: 0,
    warningPointsPercent: 0,
    faultPointsPercent: 0,
    availableThresholdStartIndex: -1,
    warningThresholdStartIndex: -1,
    faultThresholdStartIndex: -1,
    ytp0, ytp1, ytp2, ytp3
  };

  if (xtp0Arr.length > 0 || xtp1Arr.length > 0 || xtp2Arr.length > 0 || xtp3Arr.length > 0) {
    result.faultThresholdStartIndex = (xtp0Arr.length + xtp1Arr.length + xtp2Arr.length) - 1;
    result.faultAreaPercent = Number(((xtp3Arr.length / totalLength) * 100).toFixed(1));
    result.faultPointsPercent = Number(((ytp3 / totalPoints) * 100).toFixed(1));
  } else {
    faultThresholdStartIndex = sumDataLength - 1
  }

  if (xtp0Arr.length > 0 || xtp1Arr.length > 0) {
    result.warningThresholdStartIndex = (xtp0Arr.length + xtp1Arr.length) - 1;
    result.warningAreaPercent = Number(((xtp2Arr.length / totalLength) * 100).toFixed(1));
    result.warningPointsPercent = Number(((ytp2 / totalPoints) * 100).toFixed(1));
  } else {
    warningThresholdStartIndex = sumDataLength - 1
  }

  if (xtp0Arr.length > 0 || xtp1Arr.length > 0) {
    result.availableThresholdStartIndex = xtp0Arr.length - 1
    result.availableAreaPercent = Number(((xtp1Arr.length / xData.length) * 100).toFixed(1));
    result.availablePointsPercent = Number(((ytp1 / totalPoints) * 100).toFixed(1));
  } else {
    availableThresholdStartIndex = sumDataLength - 1
  }

  if (xtp0Arr.length > 0) {
    result.healthAreaPercent = Number(((xtp0Arr.length / totalLength) * 100).toFixed(1));
    result.healthPointsPercent = Number(((ytp0 / totalPoints) * 100).toFixed(1));
  }

  console.log("stats", result)
  return result;
}

function pushSeries(areaWidthPercent: number, pointsPercent: number, point: number, name: string, series: any[] = []) {
  if (areaWidthPercent <= 0) return;
  series.push({
    type: "bar",
    xAxisIndex: 1,
    yAxisIndex: 1,
    data: [{ name: name + "\r\n" + `(点数：${point}、占比：${pointsPercent}%)`, value: 1 }],
    itemStyle: { color: "transparent" },
    barGap: 0,
    barWidth: areaWidthPercent + "%",
    label: {
      show: true,
      position: "inside",
      formatter: "{b}\n"
    }
  });
}

function updateChartSeries(options: AreaOptions | null) {

  let statistics: AreaStatistics = calculateAreaStatistics(
    options,
    Number(form.value.level1Threshold),
    Number(form.value.level2Threshold),
    Number(form.value.level3Threshold),
    currentQuotaMap);

  // 更新全局索引变量
  availableThresholdStartIndex = statistics.availableThresholdStartIndex;
  warningThresholdStartIndex = statistics.warningThresholdStartIndex;
  faultThresholdStartIndex = statistics.faultThresholdStartIndex;

  let series = [
    {
      data: yData,
      type: "bar",
      itemStyle: {
        color: theme.value == 'white' ? "#274CE0" : "#00FFF4",
      },
      label: {
        show: false,
        position: "inside",
      },
    }
  ];

  pushSeries(statistics.healthAreaPercent ?? 0, statistics.healthPointsPercent, statistics.ytp0, '健康区间', series);
  pushSeries(statistics.availableAreaPercent ?? 0, statistics.availablePointsPercent, statistics.ytp1, '可用区间', series);
  pushSeries(statistics.warningAreaPercent ?? 0, statistics.warningPointsPercent, statistics.ytp2, '警戒区间', series);
  pushSeries(statistics.faultAreaPercent ?? 0, statistics.faultPointsPercent, statistics.ytp3, '故障区间', series);

  const option = {
    series: series
  };

  option && myChart.setOption(option);
}

function updateSplitLine() {
  let height = myChart.getHeight();
  let lineShape = {
    x1: 0,
    x2: 0,
    y1: 70,
    y2: height - 70
  };


  let gOptions = {
    graphic: [
      {
        id: "available",
        type: 'line',
        position: [availableThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "yellow",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: 'move',
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 1)
      },
      {
        id: "warning",
        type: 'line',
        position: [warningThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "orange",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: 'move',
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 2)
      },
      {
        id: "fault",
        type: 'line',
        position: [faultThresholdSplitPixel, 0],
        z: 100,
        shape: lineShape,
        style: {
          stroke: "red",
          lineWidth: 2,
          lineDash: "dashed"
        },
        cursor: "move",
        draggable: "horizontal",
        ondragend: (arg: any) => onSplitLineDragend(arg, 3)
      },
    ]
  };
  myChart.setOption(gOptions);
}

function onSplitLineDragend(arg: any, level: number) {
  if (level !== 1 && level !== 2 && level !== 3) return;
  // 当前x轴位置
  // console.log("target xPixel", arg.offsetX)
  let xIndex = myChart.convertFromPixel({ xAxisId: "x0" }, arg.offsetX)
  //console.log("xIndex", xIndex);
  // 落点index对应的x值为新阈值
  let xMovedPixel = myChart.convertToPixel({ xAxisId: "x0" }, xIndex);

  if (level === 1) {
    if ((warningThresholdStartIndex > -1 && xIndex >= warningThresholdStartIndex)
      || (faultThresholdStartIndex > -1 && xIndex >= faultThresholdStartIndex)) {
      message.warn("可用阈值必须小于警戒阈值和故障阈值")
      updateSplitLine();
      return;
    }
    availableThresholdStartIndex = xIndex;
    availableThresholdSplitPixel = xMovedPixel + halfPixel;
  } else if (level === 2) {
    if ((faultThresholdStartIndex > -1 && xIndex >= faultThresholdStartIndex)
      || (availableThresholdStartIndex > -1 && xIndex <= availableThresholdStartIndex)) {
      message.warn("警戒阈值必须大于可用阈值，且小于故障阈值")
      updateSplitLine();
      return;
    }
    warningThresholdStartIndex = xIndex;
    warningThresholdSplitPixel = xMovedPixel + halfPixel;
  } else {
    if ((availableThresholdStartIndex > -1 && xIndex <= availableThresholdStartIndex)
      || warningThresholdStartIndex > -1 && xIndex <= warningThresholdStartIndex) {
      message.warn("故障阈值必须大于警戒阈值和可用阈值")
      updateSplitLine();
      return;
    }
    faultThresholdStartIndex = xIndex;
    faultThresholdSplitPixel = xMovedPixel + halfPixel;
  }

  form.value[`level${level}Threshold`] = xData[xIndex];
  //console.log("fixed xPixel", xPixel)
  updateChartSeries(null);
  updateSplitLine();
}

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

function compareNumbers(a: { value: any }, b: { value: any }) {
  return a.value - b.value;
}
let currentQuotaMap: Map<number, number> = new Map<number, number>();
let quotaSearch = async () => {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
      simpleSearchModel.value.date &&
      simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 1;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    endDate = date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  form.value = new ThresholdItem()
  form.value.level4Threshold = 999;

  let result = await request.post("/api/sensors-hardware/data", {
    begin,
    end,
    sensorId: sensorInfo.value.id,
    codes: [rdoSimpleDataType.value],
  });
  currentQuotaMap.clear();

  if (result && result.data) {

    // 统计每个 value 值的个数
    result.data.list.sort(compareNumbers).forEach((obj: any) => {
      let value = parseFloat(parseFloat(obj.value).toFixed(2));
      let currentCount = currentQuotaMap.get(value) || 0;
      currentQuotaMap.set(value, currentCount + 1);
    });

    updateChart(currentQuotaMap);
  } else {
    updateChart(null);
  }

  let data = await doGenerateThreshold();
  if (data && data.length >= 3) {
    SetGeneratedSmartThreshold(data as any[]);
  }
};

let onClickSelectThresholds = (threshold: ThresholdItem) => {
  Object.assign(form.value, threshold);
  updateChart(currentQuotaMap)
}

let isApplyingThreshold = ref(false);
let onClickApplyThreshold = async () => {
  let body = {
    level1Threshold: Number(form.value.level1Threshold),
    level1RangeCount: Number(form.value.level1RangeCount),
    level1LimitCount: Number(form.value.level1LimitCount),
    level2Threshold: Number(form.value.level2Threshold),
    level2RangeCount: Number(form.value.level2RangeCount),
    level2LimitCount: Number(form.value.level2LimitCount),
    level3Threshold: Number(form.value.level3Threshold),
    level3RangeCount: Number(form.value.level3RangeCount),
    level3LimitCount: Number(form.value.level3LimitCount),
    level4Threshold: Number(form.value.level4Threshold),
    machineType: sensorInfo.value.machineTypeId,
    dataType: rdoSimpleDataType.value,
    sensorIds: [sensorInfo.value.id],
  };

  let dataType = dataTypes.value.find((p: any) => p.code == rdoSimpleDataType.value);
  if (!dataType) {
    message.warn("请选择数据类型")
    return;
  }

  // 检查阈值范围是否合法
  if (!body.level1RangeCount || !body.level1LimitCount ||
    !body.level2RangeCount || !body.level2LimitCount ||
    !body.level3RangeCount || !body.level3LimitCount) {
    message.warn("笔数必须大于0");
    return;
  }
  if (!body.level1Threshold || !body.level2Threshold || !body.level3Threshold || !body.level4Threshold) {
    message.warn("阈值必须大于0");
    return;
  }

  if (body.level1Threshold > 0 && (
    body.level1Threshold >= (body.level2Threshold ?? 0) ||
    body.level1Threshold >= (body.level3Threshold ?? 0))) {
    message.warn("可用报警阈值必须小于警戒报警阈值和故障报警阈值");
    return;
  }

  if (body.level2Threshold > 0 && (body.level2Threshold >= (body.level3Threshold ?? 0))) {
    message.warn("警戒报警阈值必须小于故障报警阈值");
    return;
  }

  if (body.level3Threshold > 0 && (body.level3Threshold) >= (body.level4Threshold ?? 0)) {
    message.warn("故障报警阈值必须小于熔断报警阈值");
    return;
  }

  isApplyingThreshold.value = true;
  let result;
  try {
    result = await request.post("/api/quota/simple-model", body);
  } catch (err) {
  }
  if (result?.status == 200) {
    message.success("保存模板成功!");
    //await loadSensorSimpleModel();
  }

  let config = Object.assign({
    dataType: dataType.name,
    machineId: sensorInfo.value.machineId,
    sensorId: sensorInfo.value.id,
    sensorPlace: sensorInfo.value.sensorPlace
  }, form.value);
  try {
    await request.put("/api/sensors/quota-alarm", { list: [config] })
  } catch (err) {
  } finally {
    isApplyingThreshold.value = false;
  }

  if (result?.status == 200) {
    message.success("保存报警规则成功!");
    await selectQuotaAlarmConfig(dataType)
  }
};

function setThresholdPrompt(promptArray: ({
  param: number,
  count: number,
  sum: number
} | string)[], maxThreshold = 0) {
  if (promptArray && promptArray.length > 0) {
    let prefix = form.value.level1RangeCount + "笔内：";
    let level1Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level1Threshold)
      .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
      .join('；');
    form.value.level1Prompt = prefix + level1Prompt;

    let level2Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level2Threshold)
      .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
      .join('；');
    form.value.level2Prompt = prefix + level2Prompt;

    let level3Prompt = promptArray.filter((val: any) => parseFloat(val.param.toFixed(5)) === form.value.level3Threshold)
      .map((val: any) => `累计${val.count}笔出现${val.sum}次`)
      .join('；');
    form.value.level3Prompt = prefix + level3Prompt;

    let geLevel4Threshold = parseFloat(parseFloat((promptArray[4] as string)).toFixed(5));
    form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  } else {
    form.value.level1Prompt = "";
    form.value.level2Prompt = "";
    form.value.level3Prompt = "";
  }

  if (maxThreshold) {
    let geLevel4Threshold = parseFloat(parseFloat(maxThreshold.toString()).toFixed(5));
    form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  } else {
    form.value.level4Prompt = ""
  }
}

function SetGeneratedSmartThreshold(thresholdArr: any[]) {
  if (thresholdArr && thresholdArr.length > 0) {
    let geLevel1Threshold = parseFloat(parseFloat(thresholdArr[0]).toFixed(5));
    let geLevel2Threshold = parseFloat(parseFloat(thresholdArr[1]).toFixed(5));
    let geLevel3Threshold = parseFloat(parseFloat(thresholdArr[2]).toFixed(5));
    let geLevel4Threshold = parseFloat(parseFloat(thresholdArr[4]).toFixed(5));

    smartThreshold.value.level1Threshold = geLevel1Threshold
    smartThreshold.value.level2Threshold = geLevel2Threshold
    smartThreshold.value.level3Threshold = geLevel3Threshold
    smartThreshold.value.level4Threshold = geLevel4Threshold > geLevel3Threshold ? geLevel4Threshold : 999;

    smartThreshold.value.level1RangeCount = 10;
    smartThreshold.value.level1LimitCount = 1;
    smartThreshold.value.level2RangeCount = 10;
    smartThreshold.value.level2LimitCount = 1;
    smartThreshold.value.level3RangeCount = 10;
    smartThreshold.value.level3LimitCount = 1;

    if (smartThreshold.value.level1Threshold
      && smartThreshold.value.level2Threshold
      && smartThreshold.value.level3Threshold) {
      let statistics: AreaStatistics = calculateAreaStatistics(
        null,
        geLevel1Threshold,
        geLevel2Threshold,
        geLevel3Threshold,
        currentQuotaMap);

      smartThresholdPrompt.value.level1Prompt = `(低于该阈值占比：${statistics.healthPointsPercent}%)`;
      smartThresholdPrompt.value.level2Prompt = `(低于该阈值占比：${statistics.availablePointsPercent}%)`;
      smartThresholdPrompt.value.level3Prompt = `(低于该阈值占比：${statistics.warningPointsPercent}%)`;
    } else {
      smartThresholdPrompt.value.level1Prompt = "";
      smartThresholdPrompt.value.level2Prompt = "";
      smartThresholdPrompt.value.level3Prompt = "";
    }
    //form.value.level4Prompt = `历史统计：最高值${geLevel4Threshold}`;
  }
}

let doGenerateThreshold = async (thresholds: number[] | null = null): Promise<any> => {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
      simpleSearchModel.value.date &&
      simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  let winSize: number[];
  let params: number[] = [];
  if (thresholds !== null) {
    params = thresholds;
  } else if (form.value.level1Threshold && form.value.level2Threshold && form.value.level3Threshold) {
    params = [form.value.level1Threshold, form.value.level2Threshold, form.value.level3Threshold];
  }

  form.value.level1RangeCount = form.value.level1RangeCount || 10;
  form.value.level2RangeCount = form.value.level2RangeCount || 10;
  form.value.level3RangeCount = form.value.level3RangeCount || 10;

  //winSize = [form.value.level1RangeCount, form.value.level2RangeCount, form.value.level3RangeCount];
  winSize = [10, 10, 10]

  let result: any;
  try {
    result = await request.post("/model/alarm/norm", {
      values: [{ begin, end }],
      dataTypes: [
        {
          sensor: sensorInfo.value.id,
          code: [rdoSimpleDataType.value],
        },
      ],
      winSize: winSize,
      params: params
    });
  } catch (err) {
    throw err;
  } finally {
    isLoadingPrompt.value = false;
  }

  return result?.data;
}

let selectedDataType: { code: number, value: number, name: string, unit: string };
let selectDataType = async () => {
  if (rdoSimpleDataType.value > 0) {
    let type: any = dataTypes.value.find(
      (p: any) => p.code == rdoSimpleDataType.value
    );
    form.value = new ThresholdItem();
    if (type) {
      selectedDataType = type;
      await selectQuotaAlarmConfig(type);
      await quotaSearch();
    } else {
      updateChart(null);
    }
  }
};

const getQuotaAlarmConfig = async () => {
  if (!sensorInfo.value) return;
  let result = await request.get("/api/sensors/quota-alarm/info?sensorId=" + sensorInfo.value.id);
  if (result?.data) {
    sensorInfo.value.table = result.data;
  } else {
    sensorInfo.value.table = [];
  }
};

const selectQuotaAlarmConfig = async (dataType: any) => {
  if (!sensorInfo.value.table) {
    standardThreshold.value = new ThresholdItem();
    currentThreshold.value = new ThresholdItem();
    return;
  }

  let configItem = sensorInfo.value.table.find((p: any) => p.dataType == dataType.name);
  if (!configItem) {
    currentThreshold.value = new ThresholdItem();
    standardThreshold.value = new ThresholdItem();
    return;
  }

  Object.assign(currentThreshold.value, configItem)

  if (configItem?.initial) {
    Object.assign(standardThreshold.value, configItem.initial)
  } else {
    standardThreshold.value = new ThresholdItem();
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const selectMenu = (type: any) => {
  menuSelect.value = type;
  if (type == 3) {
    getQuotaAlarmConfig().catch(() => { });
  }
};

let dataTypes: any = ref([]);

let isLoadingDataTypes = false;
let loadDataTypes = async () => {
  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  while (isLoadingDataTypes) {
    await sleep(10);
  }
  if (dataTypes.value.length > 0) {
    return;
  }
  let result = await request.post("/api/data-type", { tags: [10] });
  isLoadingDataTypes = false;
  if (result.data) {
    dataTypes.value = result.data;
  }
};


return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              false
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)["单值"]) + "单值", 1),
                    _createElementVNode("div", {
                      class: "btn1",
                      onClick: redirectToGroupModel
                    }, _toDisplayString(_unref(langObj)["组合值"]) + "组合值", 1),
                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 1 ? '2px solid #00FFF4' : '' }]),
                  class: _normalizeClass(["py-3 cursor-pointer", _unref(menuSelect) === 1 ? 'txt-select' : '']),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
                }, _toDisplayString(_unref(getLanguage)("生成阈值")), 7),
                _createElementVNode("div", {
                  style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 2 ? '2px solid #00FFF4' : '' }]),
                  class: _normalizeClass(["py-3 ml-12 cursor-pointer", _unref(menuSelect) === 2 ? 'txt-select' : '']),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
                }, _toDisplayString(_unref(getLanguage)("阈值模板管理")), 7),
                _createElementVNode("div", {
                  style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 3 ? '2px solid #00FFF4' : '' }]),
                  class: _normalizeClass(["py-3 ml-12 cursor-pointer", _unref(menuSelect) === 3 ? 'txt-select' : '']),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMenu(3)))
                }, _toDisplayString(_unref(getLanguage)("单值报警规则")), 7)
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      style: _normalizeStyle([{"width":"250px","height":"calc(100vh - 195px)"}, { top: _unref(scrollTop) }])
                    }, [
                      _createVNode(machineListLeftComponent, {
                        onSelectSensor: selectSensor,
                        type: 2
                      })
                    ], 4)
                  ], 512), [
                    [_vShow, _unref(menuSelect) == 1 || _unref(menuSelect) == 3]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(rdoSimpleDataType),
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(rdoSimpleDataType) ? (rdoSimpleDataType).value = $event : rdoSimpleDataType = $event)),
                          onChange: _unref(selectDataType)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(compareDataType), (item, index) => {
                              return (_openBlock(), _createBlock(_component_a_radio, {
                                value: item.code,
                                key: index
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(langObj)[item.name]), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "onChange"]),
                        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '1' }]),
                            style: _normalizeStyle([{
                            background:
                              _unref(simpleSearchModel).type == '1' ? '#072498' : '',
                          }, {"border-radius":"5px 0 0 5px"}]),
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(changeSimpleDateType)('1')))
                          }, _toDisplayString(_unref(langObj)["近一天"]), 7),
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '2' }]),
                            style: _normalizeStyle({
                            background:
                              _unref(simpleSearchModel).type == '2' ? '#072498' : '',
                          }),
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(changeSimpleDateType)('2')))
                          }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '3' }]),
                            style: _normalizeStyle({
                            background:
                              _unref(simpleSearchModel).type == '3' ? '#072498' : '',
                          }),
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(changeSimpleDateType)('3')))
                          }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '0' }]),
                            style: _normalizeStyle([{
                        background:
                          _unref(simpleSearchModel).type == '0' ? '#072498' : '',
                      }, {"border-radius":"0 5px 5px 0"}]),
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(simpleSearchModel).type = '0'))
                          }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                        ]),
                        (_unref(simpleSearchModel).type == '0')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createVNode(_component_a_range_picker, {
                                value: _unref(simpleSearchModel).date,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(simpleSearchModel).date) = $event)),
                                "disabled-date": disabledDate,
                                onCalendarChange: onCalendarChange
                              }, null, 8, ["value"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_unref(quotaSearch) && _unref(quotaSearch)(...args))),
                          class: "btn3 ml-2 btn-submit-noradius"
                        }, _toDisplayString(_unref(langObj)["查询"]), 1),
                        _cache[31] || (_cache[31] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                      ]),
                      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "w-full flex items-center" }, [
                        _createElementVNode("div", {
                          id: "chart1",
                          style: {"width":"100%","height":"322px"}
                        })
                      ], -1))
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[39] || (_cache[39] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", null, [
                        _createVNode(_component_a_form, {
                          model: _unref(form),
                          ref: "formStateRef",
                          name: "basic",
                          autocomplete: "off"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, {
                              gutter: 24,
                              class: "mb-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 2 }),
                                _createVNode(_component_a_col, {
                                  span: 6,
                                  class: "text-white"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('可用报警')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, {
                                  span: 6,
                                  class: "text-white"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('警戒报警')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, {
                                  span: 6,
                                  class: "text-white"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('故障报警')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, {
                                  span: 3,
                                  class: "text-white"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('熔断报警')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, {
                              gutter: 24,
                              class: "mb-2 text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('初始阈值')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(standardThreshold).level1Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(standardThreshold).level1RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(standardThreshold).level1LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(standardThreshold).level1Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(standardThreshold).level2Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(standardThreshold).level2RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(standardThreshold).level2LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(standardThreshold).level2Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(standardThreshold).level3Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(standardThreshold).level3RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(standardThreshold).level3LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(standardThreshold).level3Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    (_unref(standardThreshold).level4Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(standardThreshold).level4Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                (_unref(standardThreshold).level1Threshold && _unref(standardThreshold).level2Threshold && _unref(standardThreshold).level3Threshold)
                                  ? (_openBlock(), _createBlock(_component_a_col, {
                                      key: 0,
                                      span: 2
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_button, {
                                          type: "primary",
                                          class: "btn3",
                                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(onClickSelectThresholds)(_unref(standardThreshold))))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(getLanguage)('选中')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, {
                              gutter: 24,
                              class: "mb-2 text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('当前阈值')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(currentThreshold).level1Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(currentThreshold).level1RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(currentThreshold).level1LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(currentThreshold).level1Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(currentThreshold).level2Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(currentThreshold).level2RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(currentThreshold).level2LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(currentThreshold).level2Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(currentThreshold).level3Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_unref(getLanguage)('笔数')) + "：" + _toDisplayString(_unref(currentThreshold).level3RangeCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔内累计')) + _toDisplayString(_unref(currentThreshold).level3LimitCount ?? 0) + " " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "：" + _toDisplayString(_unref(currentThreshold).level3Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    (_unref(currentThreshold).level4Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(currentThreshold).level4Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                (_unref(currentThreshold).level1Threshold && _unref(currentThreshold).level2Threshold && _unref(currentThreshold).level3Threshold)
                                  ? (_openBlock(), _createBlock(_component_a_col, {
                                      key: 0,
                                      span: 2
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_button, {
                                          type: "primary",
                                          class: "btn3",
                                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_unref(onClickSelectThresholds)(_unref(currentThreshold))))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(getLanguage)('选中')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, {
                              gutter: 24,
                              class: "mb-2 text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(getLanguage)('智能统计')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(smartThreshold).level1Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(smartThreshold).level1Threshold), 1))
                                      : _createCommentVNode("", true),
                                    (_unref(smartThresholdPrompt).level1Prompt)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_unref(smartThresholdPrompt).level1Prompt), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(smartThreshold).level2Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(smartThreshold).level2Threshold), 1))
                                      : _createCommentVNode("", true),
                                    (_unref(smartThresholdPrompt).level2Prompt)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(smartThresholdPrompt).level2Prompt), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    (_unref(smartThreshold).level3Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(smartThreshold).level3Threshold), 1))
                                      : _createCommentVNode("", true),
                                    (_unref(smartThresholdPrompt).level3Prompt)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_unref(smartThresholdPrompt).level3Prompt), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, {
                                  span: 2,
                                  class: "text-white"
                                }, {
                                  default: _withCtx(() => [
                                    (_unref(smartThreshold).level4Threshold != null)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_unref(getLanguage)('阈值')) + "：" + _toDisplayString(_unref(smartThreshold).level4Threshold), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                (_unref(smartThreshold).level1Threshold && _unref(smartThreshold).level2Threshold && _unref(smartThreshold).level3Threshold)
                                  ? (_openBlock(), _createBlock(_component_a_col, {
                                      key: 0,
                                      span: 2
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_button, {
                                          type: "primary",
                                          class: "btn3",
                                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_unref(onClickSelectThresholds)(_unref(smartThreshold))))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(getLanguage)('选中')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, {
                              gutter: 24,
                              class: "mb-2 text-white"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 2 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('新阈值')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('笔数')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level1RangeCount,
                                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(form).level1RangeCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔内累计')) + " ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level1LimitCount,
                                      "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(form).level1LimitCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w50",
                                      value: _unref(form).level1Threshold,
                                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(form).level1Threshold) = $event)),
                                      type: "number",
                                      min: "0",
                                      controls: false,
                                      size: "small",
                                      onChange: _cache[16] || (_cache[16] = ($event: any) => (onChangeThresholdInput($event)))
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('笔数')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level2RangeCount,
                                      "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(form).level2RangeCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔内累计')) + " ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level2LimitCount,
                                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(form).level2LimitCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w50",
                                      value: _unref(form).level2Threshold,
                                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(form).level2Threshold) = $event)),
                                      type: "number",
                                      min: "0",
                                      controls: false,
                                      size: "small",
                                      onChange: _cache[20] || (_cache[20] = ($event: any) => (onChangeThresholdInput($event, 2)))
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 6 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(getLanguage)('笔数')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level3RangeCount,
                                      "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_unref(form).level3RangeCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔内累计')) + " ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w30",
                                      value: _unref(form).level3LimitCount,
                                      "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_unref(form).level3LimitCount) = $event)),
                                      type: "number",
                                      min: "0",
                                      step: "1",
                                      controls: false,
                                      size: "small"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔大于阈值')) + "： ", 1),
                                    _createVNode(_component_a_input_number, {
                                      class: "w50",
                                      value: _unref(form).level3Threshold,
                                      "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_unref(form).level3Threshold) = $event)),
                                      type: "number",
                                      min: "0",
                                      controls: false,
                                      size: "small",
                                      onChange: _cache[24] || (_cache[24] = ($event: any) => (onChangeThresholdInput($event, 3)))
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 3 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, [
                                      _createTextVNode(_toDisplayString(_unref(getLanguage)('阈值')) + "： ", 1),
                                      _createVNode(_component_a_input_number, {
                                        class: "w80",
                                        value: _unref(form).level4Threshold,
                                        "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(form).level4Threshold) = $event)),
                                        type: "number",
                                        min: "0",
                                        controls: false,
                                        size: "small"
                                      }, null, 8, ["value"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_spin, { spinning: _unref(isLoadingPrompt) }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_row, {
                                  gutter: 24,
                                  class: "mb-2 text-red-600"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_col, { span: 2 }),
                                    _createVNode(_component_a_col, { span: 6 }, {
                                      default: _withCtx(() => [
                                        (_unref(form).level1Prompt)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(form).level1Prompt), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_col, { span: 6 }, {
                                      default: _withCtx(() => [
                                        (_unref(form).level2Prompt)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_unref(form).level2Prompt), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_col, { span: 6 }, {
                                      default: _withCtx(() => [
                                        (_unref(form).level3Prompt)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_unref(form).level3Prompt), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_col, { span: 3 }, {
                                      default: _withCtx(() => [
                                        (_unref(form).level4Prompt)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_unref(form).level4Prompt), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["spinning"]),
                            _createVNode(_component_a_row, { class: "text-white flex mb-2" }, {
                              default: _withCtx(() => [
                                _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", null, _toDisplayString(_unref(getLanguage)('阈值设置规则：在只存在健康标签的情况下，基于数据驱动获取可用状态的阈值，参考振动相对标准，警戒状态阈值为可用阈值*2，故障状态阈值边界为警戒阈值*2')), 1)
                                ]),
                                _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, { class: "text-white flex" }, {
                              default: _withCtx(() => [
                                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    class: "ml-2 btn3",
                                    loading: _unref(isApplyingThreshold),
                                    onClick: _unref(onClickApplyThreshold)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(getLanguage)('应用阈值及笔数')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["loading", "onClick"])
                                ]),
                                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["model"]),
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                              _cache[37] || (_cache[37] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                class: "btn3",
                                onClick: _unref(onClickApplyThreshold)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(langObj)["保存"]), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]),
                              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                    ])
                  ], 512), [
                    [_vShow, _unref(menuSelect) == 1]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_38, [
                    (_openBlock(), _createBlock(AlarmItem, {
                      sensor: _unref(sensorInfo),
                      key: _unref(sensorInfo).id,
                      machineId: _unref(sensorInfo).machineId,
                      onSearch: () => { }
                    }, null, 8, ["sensor", "machineId"]))
                  ], 512), [
                    [_vShow, _unref(menuSelect) == 3]
                  ])
                ])
              ], 512), [
                [_vShow, _unref(menuSelect) == 1 || _unref(menuSelect) == 3]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_39, [
                _createVNode(SimpleModel, { "data-types": _unref(dataTypes) }, null, 8, ["data-types"])
              ], 512), [
                [_vShow, _unref(menuSelect) == 2]
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})