<template>
  <a-config-provider :locale="locale === 'en' ? enUS : zhCN">
    <div>
      <a-card :bordered="false">
        <template #title>
          <div class="cardHead">
            <node-index-outlined />
            <span style="position: relative; top: 4px; left: 5px">{{
              threshold.sensorPlace
              }}</span>
          </div>
        </template>
        <template #extra>
          <a-radio-group size="default">
            <a-radio-button value="a">
              <div @click="getHistories">阈值修改历史</div>
              <div class="box1" v-if="historyVisible">
                <a @click="historyVisible = false">关闭</a>
                <div v-for="(item, index) in historyList" :key="index">
                  <div class="flex items-center">
                    <div>{{ item.createAt }}</div>
                    <div class="flex-1"></div>
                    <a @click="restore(item)">还原</a>
                  </div>
                  <div class="flex items-center">
                    <div>{{ item.createMember }}</div>
                    <div class="flex-1"></div>
                    <div>
                      {{ item.featureType == "Expect" ? '专业指标' : '' }}
                      {{ item.featureType == "Common" ? '振动指标' : '' }}
                      {{ item.featureType == "Other" ? '其他' : '' }}
                    </div>
                  </div>
                  <div>调整原因: {{ item.reason }}</div>
                  <div class="mb-4" style="height: 1px; background: gray;"></div>
                </div>

                <a-pagination @change="changeSkip" v-model:current="searchModel.skip" :total="searchModel.total" :defaultPageSize="searchModel.take" show-less-items />


              </div>
            </a-radio-button>
            <a-radio-button value="b" @click="add">{{ langObj['新增'] }}</a-radio-button>
            <a-radio-button value="c" v-if="isEdit" @click="qX">{{ langObj['取消'] }}</a-radio-button>
            <a-radio-button value="c" v-if="!isEdit" :disabled="!(dataSource && dataSource.length)" @click="enableEdit">{{ langObj['编辑'] }}</a-radio-button>
            <a-radio-button style="position: relative;" value="c" v-if="isEdit">
              <div @click="reason = '', reasonVisible = true">{{ langObj['保存'] }}</div>
              <div class="box" v-if="reasonVisible">
                请输入修改原因：
                <a-textarea v-model:value="reason" :rows="3" />
                <div class="flex justify-center mt-4 mb-4">
                  <div class="btn3" style="padding: 0 10px" @click="reasonCancel">
                    取消
                  </div>
                  <div class="btn3 ml-2" style="padding: 0 10px" @click="saveConfig(null)">
                    提交
                  </div>
                </div>
              </div>
            </a-radio-button>
            <a-radio-button value="d" @click="copy">{{ langObj['复制'] }}</a-radio-button>
            <a-radio-button value="d" @click="pasteOverlay">{{ langObj['粘贴'] }}</a-radio-button>
            <!-- <a-radio-button value="d" @click="pasteAppend"
            >粘贴追加</a-radio-button
          > -->
            <a-popconfirm :title="langObj['确定删除所选数据么'] + '?'" :ok-text="langObj['确定']" :cancel-text="langObj['取消']"
                          @confirm="deleteThis()">
              <a-radio-button value="d">{{ langObj['删除'] }}</a-radio-button>
            </a-popconfirm>


            <!-- <a-radio-button value="d" @click="deleteThis">{{langObj['删除']}}</a-radio-button> -->
          </a-radio-group>
        </template>
      </a-card>
      <a-tabs v-model:activeKey="formState.activeKey" @change="tabsChange">
        <a-tab-pane key="Expect" :tab="langObj['专业指标']" :disabled="isEdit"></a-tab-pane>
        <a-tab-pane key="Common" :tab="langObj['振动指标']" :disabled="isEdit" force-render></a-tab-pane>
        <a-tab-pane key="Other" :tab="langObj['其他']" :disabled="isEdit"></a-tab-pane>
      </a-tabs>
      <div class="main" style="max-height: 1450px; overflow-y: scroll">

        <a-table bordered :emptyText="'暂无数据'" :row-selection="{
          selectedRowKeys: mainSelectedRowKeys,
          onChange: onMainSelectChange,
        }" :key="formState.activeKey + 'tb'" :columns="columns" :data-source="dataSource" :pagination="false" @change="onMainSelectChange">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'scores'">
              <div v-if="!isEdit">
                <span v-for="item in record.scores" :key="item.faultId + (item.name || item.scoreName)">
                  <a-tag v-if="!((item.name || item.scoreName).indexOf('Vexp') > -1)" color="green"
                         :key="item.faultId + (item.name || item.scoreName)">{{ item.name || item.scoreName }}:{{
                          item.scoreValue }}</a-tag>
                </span>

              </div>
              <div v-else>
                <a-form layout="inline">
                  <span v-for="item in record.scores" :key="item.faultId + (item.name || item.scoreName)">
                    <a-form-item v-if="!((item.name || item.scoreName).indexOf('Vexp') > -1)"
                                 :key="item.faultId + (item.name || item.scoreName)" :label="item.name || item.scoreName"
                                 :name="item.name || item.scoreName" :required="item.required">
                      <a-input-number :key="item.faultId + (item.name || item.scoreName)" :step="0.01"
                                      v-model:value="item.scoreValue" :placeholder="langObj['请输入']" />
                    </a-form-item></span>
                </a-form>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <a-modal :width="850" :maskClosable="false" v-model:visible="modalState.visible" :title="modalState.title" :confirm-loading="modalState.confirmLoading" @ok="handleOk">
        <div style="max-height: 600px; overflow-y: scroll">

          <div class="flex">
            <a-form-item :label="langObj['细分故障']" name="status">
              <a-input style="width: 220px" :placeholder="langObj['请输入关键词搜索']" v-model:value="modalState.segmentFaultV" />

            </a-form-item>
            <a-form-item>
              <div class="flex items-center">
                <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">{{ langObj['重置'] }}</div>

                <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499;">{{ langObj['查询'] }}</div>

              </div>
            </a-form-item>
          </div>


          <a-table class="modal-table" bordered :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columnsZB" :pagination="false" :data-source="displayData" @change="handleTableChange" />
        </div>
      </a-modal>

      <a-modal v-model:visible="modalState.open" width="500px" title="提示">
        <p style="color: #fff;">请选择复制的内容</p>
        <template #footer>
          <a-button key="back" @click="copyLone">复制单个模块</a-button>
          <a-button key="submit" type="primary" @click="copyAll">复制全部</a-button>
        </template>
      </a-modal>
    </div>
  </a-config-provider>
</template>
<script lang="ts">
import request from "../../../common/request";
import { langList } from "../../../common/lang";
import { defineComponent, watch } from "vue";
import { ref, reactive, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import type { TableProps } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { locale } from "moment";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";


export default defineComponent({
  props: {
    threshold: {
      type: Object,
      default: () => ({}),
    },
    baseModel: {
      type: Array,
      default: () => [],
    },


  },
  computed: {
    displayData(): any {
      const { current, pageSize } = this.pagination;
      const start = (current - 1) * pageSize;
      const end = start + pageSize;
      console.log(this.pagination);
      // return this.baseModelSource.slice(start, end);
      return this.searchList.slice(start, end);

    },
  },

  watch: {
    threshold: {
      handler(value) {
        debugger
        this.dataSource.value = this.$props.threshold.thresholds[
          this.formState.activeKey
        ].map((t: any) => ({ ...t, key: t.faultId || t.id }));
      },
      deep: true, //
    },

    baseModel: {
      handler(value) {
        debugger
        this.baseModelSource.value = value.filter(
          (t: any) => t.featureType == this.formState.activeKey
        );
        this.pagination.total = this.baseModelSource.value.length;

      },
      deep: true, //
    },
  },
  setup(props, { emit }) {
    watch(() => props.threshold, (newVal, oldVal) => {
      console.log(props.threshold)
      dataSource.value = props.threshold.thresholds[
        formState.activeKey
      ].map((t: any) => ({ ...t, key: t.faultId || t.id }));
    })
    const sensorsSource = ref([]);



    let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
      language.value = localStorage.getItem('language') || 'Chinese'
      langObj.value = langList[language.value]
    }
    getLang()


    let dataSource = ref<any>();
    let baseModelSourceAllCommon2 = ref<any>();
    let baseModelSourceAllExpect = ref<any>();
    let baseModelSourceAllOther = ref<any>();
    const pagination = ref({
      total: 0,
      current: 1,
      pageSize: 10000,
    });
    const formState = reactive({
      activeKey: "Expect",
    });
    const columns = [
      {
        title: langObj.value["细分故障"],
        dataIndex: "segmentFault",

      },
      {
        title: langObj.value["计算方法"],
        dataIndex: "faultFunction",
      },
      {
        title: langObj.value["指标"],
        dataIndex: "scores",
      },
    ];
    const columnsZB = [
      {
        title: langObj.value["细分故障"],
        dataIndex: "segmentFault",
      },
      {
        title: langObj.value["计算方法"],
        dataIndex: "faultFunction",
      },
    ];

    const modalState = ref({
      visible: false,
      title: langObj.value["新增"],
      confirmLoading: false,
      roleName: "",
      meun: [],
      segmentFaultV: '',
      open: false
    });
    const filterOption = (input: string, option: any) => {

      return option.segmentFault.indexOf(input) >= 0;
    };
    // 新增
    const add = () => {
      selectedRowKeys.value = dataSource.value.map((t: any) => t.faultId);
      modalState.value.visible = true;
      search()
    };
    let baseModelSource = ref<any>();
    const tabsChange = () => {
      baseModelSource.value = props.baseModel.filter(
        (t: any) => t.featureType == formState.activeKey
      );
      pagination.value.total = baseModelSource.value.length;
      dataSource.value = props.threshold.thresholds[formState.activeKey].map(
        (t: any) => ({ ...t, key: t.faultId || t.id })
      );
    };

    const handleTableChange = (val: any) => {
      pagination.value = val;
    };
    let threshold = ref<any>();
    threshold.value = props.threshold;
    baseModelSource.value = props.baseModel.filter(
      (t: any) => t.featureType == "Expect"
    );
    pagination.value.total = baseModelSource.value.length;

    const selectedRowKeys = ref<any>();
    const selectedRows = ref<any>();
    const onSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      selectedRowKeys.value = selectedRowKeys2;
      selectedRows.value = selectedRows2;
    };
    // 添加确定
    const handleOk = () => {
      var selected = JSON.parse(JSON.stringify(selectedRows.value))
      dataSource.value = selected.concat(dataSource.value);
      dataSource.value = Array.from(
        dataSource.value
          .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
          .values()
      );

      modalState.value.visible = false;
      isEdit.value = true;
    };
    // 保存配置
    const saveConfig = async (type: any) => {

      if (!type) type = formState.activeKey

      var param: any = {
        sensorId: threshold.value.sensorId,
        featureType: type,
        faultFunctions: [],
        scoreNames: [],
      };
      // dataSource.value.forEach(async (item:any) => {
      //   param.scoreNames.push(item.scoreName);
      //   param.faultFunctions.push(item.faultFunction);
      //   param[item.scoreName + "_name"] = item.faultName;
      //   await item.scores.forEach(async (t:any) => {
      //     param[t.name] = t.scoreValue || t.default;
      //   });
      // });
      var flag = true;
      var msg = "";
      for (var i = 0; i < dataSource.value.length; i++) {
        var item: any = dataSource.value[i];
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        param[item.scoreName + "_name"] = item.faultName || item.segmentFault;
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          if ((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
            t.scoreValue = t.default
          }
          param[t.name || t.scoreName] = t.scoreValue || t.default || t.scoreValue;
          if (!t.scoreValue && t.scoreValue != 0) {
            msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
            flag = false;
            break;
          }
        }
      }
      if (!flag) {
        message.warning(msg);
        return;
      }
      console.log(param);
      param.reason = reason.value
      param.historyId = historyId.value
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/machines/threshold", param, config).then((res) => {
        if (res) {
          operateLogs('保存模型配置，传感器：' + threshold.value.sensorPlace);
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          reasonVisible.value = false
          historyId.value = ''
          emit("search");
        }
      });
    };

    const isEdit = ref(false);
    const enableEdit = () => {
      isEdit.value = true;
    };
    dataSource.value = threshold.value.thresholds[formState.activeKey].map(
      (t: any) => ({ ...t, key: t.faultId || t.id })
    );

    const mainSelectedRowKeys = ref<any>();
    const mainSelectedRows = ref<any>();
    const onMainSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      mainSelectedRowKeys.value = selectedRowKeys2;
      mainSelectedRows.value = selectedRows2;
      if (isAllSelect.value == false) {
        if (!mainSelectedRows.value || !mainSelectedRows.value.length) {
          message.warning(langObj.value["请选择复制的内容"]);
          return;
        }
        localStorage.removeItem("copyCommon2");
        localStorage.removeItem("copyExpect");
        localStorage.removeItem("copyOther");
        localStorage.setItem("copyType", formState.activeKey);
        localStorage.setItem("sensorId", threshold.value.sensorId);
        localStorage.setItem("copyRows", JSON.stringify(mainSelectedRows.value));
        message.success(langObj.value["复制成功"]);
      }

    };
    const copyInfo = ref();
    const copySensorId = ref();
    const copyType = ref();

    const qX = () => {
      historyId.value = ''
      emit("search");
      isEdit.value = false;
    };
    // 查询
    const searchList: any = ref([])
    const search = () => {
      let list = JSON.parse(JSON.stringify(baseModelSource.value))
      if (modalState.value.segmentFaultV) {
        searchList.value = list.filter((p: any) => p.segmentFault.includes(modalState.value.segmentFaultV))
      } else {
        searchList.value = list
      }

    };
    const reset = () => {
      modalState.value.segmentFaultV = ""
      search()

    };


    // 复制
    let isAllSelect: any = ref(null) //是否复制全部
    const copy = () => {
      modalState.value.open = true;
      isAllSelect.value = null;
    };
    const copyLone = () => {
      isAllSelect.value = false;
      if (!mainSelectedRows.value || !mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择复制的内容"]);
        modalState.value.open = false;
        return;
      }
      localStorage.removeItem("copyCommon2");
      localStorage.removeItem("copyExpect");
      localStorage.removeItem("copyOther");
      localStorage.setItem("copyType", formState.activeKey);
      localStorage.setItem("sensorId", threshold.value.sensorId);
      localStorage.setItem("copyRows", JSON.stringify(mainSelectedRows.value));
      message.success(langObj.value["复制成功"]);
      modalState.value.open = false;
    };
    const copyAll = () => {
      isAllSelect.value = true
      mainSelectedRows.value = []
      mainSelectedRowKeys.value = []
      localStorage.removeItem("copyRows")
      let allValueCommon2 = props.threshold?.thresholds['Common'] || []
      let allValueExpect = props.threshold?.thresholds['Expect'] || []
      let allValueOther = props.threshold?.thresholds['Other'] || []

      var flag1 = true;
      var flag2 = true;
      var flag3 = true;
      var msg = "";
      for (var i = 0; i < allValueCommon2.length; i++) {
        var item: any = allValueCommon2[i];
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          if ((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
            console.log('t', t)
            t.scoreValue = t.default
          }
          if (!t.scoreValue && t.scoreValue != 0) {
            msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
            flag1 = false;
            break;
          }
        }
      }
      for (var i = 0; i < allValueExpect.length; i++) {
        var item: any = allValueExpect[i];
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          if ((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
            console.log('t', t)
            t.scoreValue = t.default
          }
          if (!t.scoreValue && t.scoreValue != 0) {
            msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
            flag2 = false;
            break;
          }
        }
      }
      for (var i = 0; i < allValueOther.length; i++) {
        var item: any = allValueOther[i];
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          if ((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
            console.log('t', t)
            t.scoreValue = t.default
          }
          if (!t.scoreValue && t.scoreValue != 0) {
            msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
            flag3 = false;
            break;
          }
        }
      }
      if (!flag1 || !flag2 || !flag3) {
        message.warning(msg);
        return;
      }

      // localStorage.setItem("copyType", formState.activeKey);
      localStorage.setItem("sensorId", threshold.value.sensorId);
      localStorage.setItem("copyCommon2", JSON.stringify(allValueCommon2));
      localStorage.setItem("copyExpect", JSON.stringify(allValueExpect));
      localStorage.setItem("copyOther", JSON.stringify(allValueOther));
      message.success(langObj.value["复制成功"]);
      modalState.value.open = false;
    };
    // 粘贴覆盖
    const pasteOverlay = () => {
      if (localStorage.getItem("copyRows")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows") || "");
        copySensorId.value = localStorage.getItem("sensorId");
        copyType.value = localStorage.getItem("copyType");
        if (
          copySensorId.value == threshold.value.sensorId ||
          formState.activeKey != copyType.value
        ) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        dataSource.value = dataSource.value.concat(copyInfo.value);
        dataSource.value = Array.from(
          dataSource.value
            .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
            .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      }

      if (localStorage.getItem("copyCommon2")) {
        const copyInfoDataCommon = JSON.parse(localStorage.getItem("copyCommon2") || "");
        const copyInfoDataExpect = JSON.parse(localStorage.getItem("copyExpect") || "");
        const copyInfoDataOther = JSON.parse(localStorage.getItem("copyOther") || "");
        baseModelSourceAllCommon2.value = props.threshold?.thresholds['Common']?.map((t: any) => ({ ...t, key: t.faultId || t.id }));
        baseModelSourceAllExpect.value = props.threshold?.thresholds['Expect']?.map((t: any) => ({ ...t, key: t.faultId || t.id }))
        baseModelSourceAllOther.value = props.threshold?.thresholds['Other']?.map((t: any) => ({ ...t, key: t.faultId || t.id }))
        baseModelSourceAllCommon2.value = baseModelSourceAllCommon2.value.concat(copyInfoDataCommon)
        baseModelSourceAllExpect.value = baseModelSourceAllExpect.value.concat(copyInfoDataExpect)
        baseModelSourceAllOther.value = baseModelSourceAllOther.value.concat(copyInfoDataOther)
        if (baseModelSourceAllCommon2.value) {
          dataSource.value = baseModelSourceAllCommon2.value
          saveConfig('Common')
        }
        if (baseModelSourceAllExpect.value) {
          dataSource.value = baseModelSourceAllExpect.value
          saveConfig('Expect')
        }
        if (baseModelSourceAllOther.value) {
          dataSource.value = baseModelSourceAllOther.value
          saveConfig('Other')
        }


      }
      if (!localStorage.getItem("copyCommon2") && !localStorage.getItem("copyRows")) {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }

      // else {
      //   message.warning(langObj.value["没有复制的内容"]);
      //   return;
      // }
    };
    // 粘贴追加
    const pasteAppend = () => {
      if (localStorage.getItem("copyRows")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows") || "");
        copySensorId.value = localStorage.getItem("sensorId");
        copyType.value = localStorage.getItem("copyType");
        if (
          copySensorId.value == threshold.value.sensorId ||
          formState.activeKey != copyType.value
        ) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        dataSource.value = copyInfo.value.concat(dataSource.value);
        dataSource.value = Array.from(
          dataSource.value
            .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
            .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      } else {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }
    };
    // 删除
    const deleteThis = () => {
      if (!mainSelectedRows.value || !mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择内容"]);
        return;
      }
      var param: any = {
        sensorId: threshold.value.sensorId,
        featureType: formState.activeKey,
        faultFunctions: [],
        scoreNames: [],
        scores: [],
      };
      // dataSource.value.forEach(async (item:any) => {
      //   param.scoreNames.push(item.scoreName);
      //   param.faultFunctions.push(item.faultFunction);
      //   param[item.scoreName + "_name"] = item.faultName;
      //   await item.scores.forEach(async (t:any) => {
      //     param[t.name] = t.scoreValue || t.default;
      //   });
      // });
      for (var i = 0; i < mainSelectedRows.value.length; i++) {
        var item: any = mainSelectedRows.value[i];
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        for (var j = 0; j < item.scores.length; j++) {
          var t = item.scores[j];
          param.scores.push(t.name || t.scoreName);
        }
      }
      console.log(JSON.stringify(param));
      const config = {
        params: param,
        data: param,
        headers: {
          requestId: uuidv4(),
        },
      };
      request.delete("/api/machines/threshold", config).then((res) => {
        if (res) {
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          emit("search");
        }
      });
    };
    // 同步标准
    const TB = () => {
      const config = {
        data: { id: threshold.value.sensorId },
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/sensors/parameter/nationalsta", config.data, config).then((res: any) => {
        if (res.data) {
          message.success(langObj.value["操作成功"]);
        } else {
          message.warning(res.message || langObj.value['操作失败'])
        }
      });
    };
    let reason = ref('')
    let reasonVisible = ref(false)
    const reasonCancel = (event: any) => {
      event.stopPropagation();
      reasonVisible.value = false
    }

    // 获取阈值修改历史
    let historyList: any = ref([])
    let searchModel: any = ref({
      skip: 1,
      take: 5,
      total: 0
    })
    let historyVisible: any = ref(false)
    let historyId: any = ref('')
    const getHistories = async () => {
      let config = {
        params: {
          skip: searchModel.value.skip,
          take: searchModel.value.take,
          machineId: threshold.value.machineId,
          sensorId: threshold.value.sensorId,
          featureType: formState.activeKey,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      let result = await request.get("/api/machines/threshold/histories", config)
      historyList.value = result?.data?.list || []
      searchModel.value.total = result?.data?.count
      historyVisible.value = true
    }

    // 还原
    const restore = async (ev: any) => {
      historyId.value = ev.id
      let config = {
        params: {
          historyId: ev.id,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      let result = await request.get("/api/machines/threshold/histories/recover", config)
      dataSource.value = result?.data?.thresholds[ev.featureType]
      isEdit.value = true
      historyVisible.value = false
    }

    const changeSkip = (ev: any) => {
      searchModel.value.skip = ev
      getHistories()
    }

    return {
      getHistories,
      historyList,
      historyVisible,
      restore,
      searchModel,
      changeSkip,
      qX,
      copyInfo,
      TB,
      pasteAppend,
      deleteThis,
      pasteOverlay,
      copy,
      copyLone,
      copyAll,
      threshold,
      search,
      reset,
      filterOption,
      sensorsSource,
      dataSource,
      formState,
      columns,
      handleOk,
      handleTableChange,
      onMainSelectChange,
      modalState,
      add,
      columnsZB,
      pagination,
      baseModelSource,
      searchList,
      tabsChange,
      selectedRowKeys,
      onSelectChange,
      isEdit,
      enableEdit,
      saveConfig,
      mainSelectedRowKeys,
      langObj,
      reason,
      reasonVisible,
      reasonCancel,
    };
  },
});
</script>
<style scoped>
/deep/ .ant-card-head {
  padding-left: 0px;
}

.cardHead {
  color: #1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0px;
  font-size: 18px;
}

.main {
  margin-top: 30px;
}

.box1 {
  position: absolute;
  top: 35px;
  left: 0px;
  width: 237px;
  border: 1px solid #0d53b7cc;
  background-color: #020F46;
  padding: 0px 10px;
  z-index: 1000;
}
</style>
