import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/screen/cgq_warn_bg.png'
import _imports_2 from '@/assets/screen/cjdy_warn_bg.png'
import _imports_3 from '@/assets/menxian.png'


const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000a32","overflow-y":"scroll","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "w-full p-5" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_7 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { class: "ml-2" }

import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
  } from "vue";
  import { useRouter } from "vue-router";
  import request from "../../common/request";
  import {
    transformDate,
    transformDate2,
    getGroupListByFactory,
    getRealFactoryIdList,
    getGroupName
  } from "../../common/tools";
  import { v4 as uuidv4 } from "uuid";
  import { message } from "ant-design-vue";
  import DownLoadComponent from "../components/downLoad.vue";
  import { Dayjs } from "dayjs";
  import { langList } from "../../common/lang";
  import enUS from "ant-design-vue/es/locale/en_US";
  import zhCN from "ant-design-vue/es/locale/zh_CN";
  import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
  import { getMemberOnboarding } from "../../common/tools";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'message-list',
  setup(__props) {

  const router = useRouter();
  const getLang = () => {
    language.value = localStorage.getItem("language") || "Chinese";
    langObj.value = langList[language.value];
  };
  let typeList = [
    { label: "智能诊断", value: 4 },
    // {label: '维修记录', value: 2},
    // {label: '设备检查', value: 3},
    { label: "门限报警", value: 5 }, 
    { label: "传感器报警", value: 7 },
    { label: "采集单元报警", value: 6 },

  ];
  let langObj: any = ref({});
  let language: any = ref("Chinese");
  getLang();
  
  let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
  ];
  let conditionList2 = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用上", value: 1, color: "#d2de49" },
    { label: "可用下", value: 2, color: "#d2de49" },
    { label: "警戒上", value: 3, color: "#cd5f3b" },
    { label: "警戒中", value: 4, color: "#cd5f3b" },
    { label: "警戒下", value: 5, color: "#cd5f3b" },
    { label: "故障上", value: 6, color: "#921e37" },
    { label: "故障下", value: 7, color: "#921e37" },
  ];
  let conditionLists = [
    { label: "健康", value: 1, real: [0] },
    { label: "可用", value: 2, real: [1, 2] },
    { label: "警戒", value: 3, real: [3, 4, 5] },
    { label: "故障", value: 4, real: [6, 7] },
  ];
  let conditionAdd = [
    { label: "健康", value: 0 },
    { label: "可用", value: 1 },
    { label: "警戒", value: 3 },
    { label: "故障", value: 6 },
  ];
  let statusList = [
    { label: "未处理", value: 1 },
    { label: "已处理", value: 2 },
    { label: "已关闭", value: 3 },
  ];
  
  let expertCertificationList = [
    { value: null, label: "不限" },
    {
      value: true,
      label: "是",
    },
    { value: false, label: "否" },
  ];
  let warningColumns = [
    {
      title: langObj.value["消息时间"],
      dataIndex: "createAt",
    },
    {
      title: langObj.value["消息类型"],
      dataIndex: "type",
    },
    
  
    {
      title: langObj.value["消息内容"],
      dataIndex: "message",
    },
    {
      title: langObj.value["接收人"],
      dataIndex: "members",
    },
    // {
    //   title: langObj.value["推送平台"],
    //   dataIndex: "pushPlat",
    // },
    // {
    //   title: langObj.value["是否推送成功"],
    //   dataIndex: "isPushSuccess",
    // },
   
  
  
  ];
  const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
  }));
  let data: any = ref([]);
  let searchModel: any = ref({
    
    date: null,
    skip: 1,
    take: 10,
   
    handleStatus: null,
    expertCertification: null,
    type:null
  });
  let selectedRowKeys: any = ref();
  let groupList: any = ref([]);
  let formClose: any = ref({
    closeCause: "",
  });

  
  onMounted(() => {
    // getAdmin();
    // getCount();
    search();
    getLang();
  
    
  
  });
  
 
  
  
  const search = () => {
    searchModel.value.skip = 1;
    // getData();
    getMachineWarning()
  };
  
  const reset = () => {
    searchModel.value = {
      ...searchModel.value,
      date:null,
      handleStatus: null,
      type:null
    };
    search()
  };
 
  
 let machineWarningData :any=ref([])
 let warningTotal :any = ref(0)
  const getMachineWarning = async () => {
      let config: any = {
        params: {
          type:searchModel.value.type,
          skip:searchModel.value.skip,
          take:searchModel.value.take
          // status: 0
        },

        headers: {
          requestId: uuidv4(),
        },
      };
      if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }
     

      let result = await request.get("/api/sys-alarm/messages", config);
      if (result && result.status) {
        machineWarningData.value = result?.data?.list;
        searchModel.value.total = result.data.count;
      }
    };


 
  
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    // if (Object.keys(sorter).length) {
    //   var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    //   searchModel.value.sort = sorter.field;
    //   searchModel.value.order = order;
    // }
    // 处理分页数据
    searchModel.value.skip = pagination.current;
    searchModel.value.take = pagination.pageSize;
    getMachineWarning();
  };
  

  

  
  //复选框选择
  const onSelectChange = (ev: any) => {
    selectedRowKeys.value = ev;
  };
  
  
  
 
  
  // 查看已关闭
  let visibledel2 = ref(false);
  const viewClose = (ev: any) => {
    formClose.value.closeCause = ev.closeCause;
    visibledel2.value = true;
  };
  
  //查看报告
  let visibleDR: any = ref(false);
  let reportDRId: any = ref("");
  let mrDref: any = ref();
  const viewReport = (ev: any) => {
    if (ev.reportIds.length) {
      visibleDR.value = true;
      reportDRId.value = ev.reportIds[0];
      setTimeout(() => {
        mrDref.value.show(reportDRId.value);
      }, 500);
    } else {
      message.warning("暂无报告");
    }
    // router.push('/report/list')
  };
  
  const cancelDR = () => {
    visibleDR.value = false;
  };
  
  const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? "even-row" : "odd-row";
  };
  
  const dates = ref<any>();
  
  const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
      return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
    return tooEarly || tooLate;
  };
  
  const onCalendarChange = (val: any) => {
    dates.value = val;
  };
  
  
return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_form, {
                model: _unref(searchModel),
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['消息时间'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        value: _unref(searchModel).date,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).date) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['消息类型'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).type,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).type) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择消息类型']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          onClick: reset,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                        }, _toDisplayString(_unref(langObj)["重置"]), 1),
                        _createElementVNode("div", {
                          onClick: search,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                          style: {"background-color":"#072499"}
                        }, _toDisplayString(_unref(langObj)["查询"]), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(warningColumns),
                "data-source": _unref(machineWarningData),
                "row-selection": {
                  selectedRowKeys: _unref(selectedRowKeys),
                  onChange: onSelectChange,
                },
                pagination: pagination.value,
                onChange: handleTableChange,
                rowKey: "id"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_0,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 5)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_1,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 6)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[5] || (_cache[5] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_2,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 7)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[6] || (_cache[6] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_3,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'members')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(record.members, (item, index) => {
                        return (_openBlock(), _createElementBlock("span", null, _toDisplayString(item.nickName) + ",", 1))
                      }), 256))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source", "row-selection", "pagination"])
            ])
          ])
        ]),
        _createVNode(_component_a_drawer, {
          visible: _unref(visibleDR),
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event)),
          class: "custom-class",
          size: "large",
          closable: false,
          placement: "right"
        }, {
          title: _withCtx(() => _cache[7] || (_cache[7] = [])),
          default: _withCtx(() => [
            _createVNode(DiagnosticReportDetails, {
              ref_key: "mrDref",
              ref: mrDref,
              reportId: _unref(reportDRId),
              onCancel: cancelDR
            }, null, 8, ["reportId"])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})