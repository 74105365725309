import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/chat/chat-role-icon1.png'


const _hoisted_1 = { class: "chat-layout text-primary" }
const _hoisted_2 = {
  key: 0,
  class: "role-selector-tabs"
}
const _hoisted_3 = ["alt"]
const _hoisted_4 = {
  key: 1,
  class: "chat-history-content"
}
const _hoisted_5 = { class: "chat-history-group-header" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "chat-history-toggle" }
const _hoisted_9 = { class: "chat-content" }

import { ref, onMounted, computed, watch } from 'vue';
import ChatPanelCommon from './chat-panel-common.vue';
import ChatPanelDiagnostic from './chat-panel-dianostic.vue';
import ChatRoleSelector from './chat-role-selector.vue';
import { MenuFoldOutlined, MenuUnfoldOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import request from '@/common/request';
import dayjs from "dayjs";
import { ChatHistoryItem, ChatHistoryGroup } from './chat.types';
import { getLanguage } from "../../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'chat-layout',
  setup(__props) {

const showLeftPanel = ref<boolean>(true);
const showHistoryData = ref<boolean>(false);
const chatHistory = ref<ChatHistoryItem[]>([]);
const getNewChat = (): ChatHistoryItem => {
    let now = dayjs();
    return { id: "", title: getLanguage('新对话'), abstract: getLanguage('新对话'), date: now.format("YYYY/MM/DD"), createAt: now.format("YYYY/MM/DD HH:mm:ss") };
};

const selectedChat = ref<ChatHistoryItem | null>();
const currentRole = ref<'common' | 'diagnostic' | ''>('');

// Group chat history by date categories
const groupedChatHistory = computed<ChatHistoryGroup[]>(() => {
    const today = dayjs().format('YYYY/MM/DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY/MM/DD');

    const groups: ChatHistoryGroup[] = [
        { label: '', chats: [] },
        { label: getLanguage('昨天'), chats: [] },
        { label: getLanguage('7天内'), chats: [] }
    ];

    chatHistory.value.forEach(chat => {
        if (chat.date === today) {
            groups[0].chats.push(chat);
        } else if (chat.date === yesterday) {
            groups[1].chats.push(chat);
        } else {
            groups[2].chats.push(chat);
        }
    });

    return groups;
});

function toggleHistory(): void {
    showLeftPanel.value = !showLeftPanel.value;
}

function selectChat(chat: ChatHistoryItem): void {
    selectedChat.value = chat;
}

const getHistories = async (): Promise<void> => {
    let params = {
        skip: 1,
        take: 20,
        startDate: dayjs().subtract(7, 'day').format('YYYY/MM/DD'),
        endDate: dayjs().format('YYYY/MM/DD')
    }
    const response = await request.get('api/chat', { params });
    let newChat = getNewChat();
    if (response?.data?.list.length > 0) {
        chatHistory.value = [newChat, ...response.data.list];
    } else {
        chatHistory.value = [newChat];
    }
    selectChat(chatHistory.value[0]);
};

const onNewChat = (): void => {
    //console.log('chat-layout: new chat');
    if (!chatHistory.value[0].id) {
        // 新对话已存在
        selectedChat.value = chatHistory.value[0];
        return;
    }
    selectedChat.value = getNewChat();

    chatHistory.value = [selectedChat.value, ...chatHistory.value];
};

const onUpdateChat = (chat: ChatHistoryItem): void => {
    //console.log('chat-layout: update chat');
    let chatToUpdate = chatHistory.value[0];
    chatToUpdate.title = chat.abstract;
    chatToUpdate.abstract = chat.abstract;
    chatToUpdate.createAt = chat.createAt;
    chatToUpdate.date = chat.date;;
};

const onChatRoleChanged = (role: string): void => {
    currentRole.value = role as 'common' | 'diagnostic';
};

const deleteChat = async (chat: ChatHistoryItem): Promise<void> => {
    await request.delete(`api/chat?id=${chat.id}`);
    chatHistory.value = chatHistory.value.filter(item => item.id !== chat.id);
    if (selectedChat.value?.id === chat.id) {
        selectChat(chatHistory.value[0]);
    }
};

watch(currentRole, (newRole) => {
    showHistoryData.value = newRole === 'common';
});

onMounted(async () => {
    await getHistories();
});

return (_ctx: any,_cache: any) => {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["chat-left-panel", { 'chat-left-hidden': !showLeftPanel.value }])
    }, [
      (currentRole.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              class: _normalizeClass(["role-tab", { active: currentRole.value === 'common' }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (onChatRoleChanged('common')))
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: _unref(getLanguage)('维保专家图标')
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('维保专家')), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (showHistoryData.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedChatHistory.value, (group, groupIndex) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: groupIndex }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(group.label), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.chats, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id || index,
                    class: _normalizeClass(["chat-history-item", { 'selected': selectedChat.value?.id === item.id }])
                  }, [
                    _createElementVNode("div", {
                      class: "chat-history-item-content",
                      onClick: ($event: any) => (selectChat(item))
                    }, [
                      _createVNode(_component_a_typography_text, {
                        class: "text-primary",
                        style: {"width":"200px"},
                        ellipsis: {},
                        content: item.abstract
                      }, null, 8, ["content"])
                    ], 8, _hoisted_6),
                    (item.id)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "delete-btn",
                          onClick: ($event: any) => (deleteChat(item))
                        }, [
                          _createVNode(_unref(DeleteOutlined))
                        ], 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", { onClick: toggleHistory }, [
        (showLeftPanel.value)
          ? (_openBlock(), _createBlock(_unref(MenuFoldOutlined), { key: 0 }))
          : _createCommentVNode("", true),
        (!showLeftPanel.value)
          ? (_openBlock(), _createBlock(_unref(MenuUnfoldOutlined), { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["chat-panel", { 'full-width': !showLeftPanel.value }])
    }, [
      (!currentRole.value)
        ? (_openBlock(), _createBlock(ChatRoleSelector, {
            key: 0,
            onChatRoleChanged: onChatRoleChanged
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createVNode(ChatPanelCommon, {
          chat: selectedChat.value,
          onNewChat: onNewChat,
          onUpdateChat: onUpdateChat
        }, null, 8, ["chat"]), [
          [_vShow, currentRole.value === 'common']
        ]),
        _withDirectives(_createVNode(ChatPanelDiagnostic, null, null, 512), [
          [_vShow, currentRole.value === 'diagnostic']
        ])
      ])
    ], 2)
  ]))
}
}

})