<template>
    <div class="w-full mt-4">
        <div>
            <a-form
                :model="searchModel"
                name="horizontal_login"
                layout="inline"
                autocomplete="off"
            >
                <a-form-item
                    :label="langObj['提交时间']"
                    name="status"
                >
                    <a-range-picker v-model:value="searchModel.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                </a-form-item>

                <!-- <a-form-item
                    label="报告类型"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.type"
                        style="width: 220px"
                        allowClear
                    >
                        <a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value"> {{ item.label }} </a-select-option>
                    </a-select>
                </a-form-item> -->

                <a-form-item>
                    <div class="flex items-center">
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">{{langObj['重置']}}</div>

                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499;">{{langObj['查询']}}</div>

                        <div @click="ruleSet" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit WeekReportDriver" style="background-color: #072499;">{{langObj['月报配置']}}</div>
                    </div>
                </a-form-item>
                

            </a-form>
        </div>

        <div class="box mt-4" style="width: 100%;">
            <a-table 
            style="width: 100%;" 
            size="small" 
            :row-class-name="getRowClassName" 
            :columns="columns" 
            :data-source="data"
            :pagination="pagination"
            @change="handleTableChange"
            >
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'cycle'">
                        <span>{{ getDate(record.date) }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'cycle2'">
                        <span>{{ getReportDateRange(record.content) }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'type'">
                        <span>{{ text == 3 ? langObj['周报'] : langObj['月报'] }}</span>
                    </template>

                    <template v-if="column.dataIndex === 'act'">
                        <a @click="details(record)" style="" class="theme-table-link">{{langObj['查看详情']}}</a>
                    </template>
                </template>
            </a-table>
        </div>

        <MonthReportRuleSettingComponent
        @saveWeekReportRule="saveWeekReportRule"
        :visible="WeekReportRuleVisible"
        v-if="WeekReportRuleVisible" :editRindex="editRindex"
        ></MonthReportRuleSettingComponent>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {  transformDate2, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep,getPopover } from "../../common/registdriver";
import MonthReportRuleSettingComponent from "./MonthReportRuleSetting.vue";

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const router = useRouter();
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    date: null,
    type: [4],
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1
})
const columns = [
    {
        title: langObj.value['报告周期'],
        dataIndex: 'cycle',
    },
    {
        title: langObj.value['报告标题'],
        dataIndex: 'title',
    },
    {
        title: langObj.value['报告类型'],
        dataIndex: 'type',
    },
    {
        title: langObj.value['健康设备']+'（台）',
        dataIndex: 'count1',
    },
    {
        title: langObj.value['可用设备']+'（台）',
        dataIndex: 'count2',
    },
    {
        title: langObj.value['警戒设备']+'（台）',
        dataIndex: 'count3',
    },
    {
        title: langObj.value['故障设备']+'（台）',
        dataIndex: 'count4',
    },
    {
        title: langObj.value['分析周期'],
        dataIndex: 'cycle2',
    },
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let data:any = ref([])

onMounted(() => {
    init()

    //新注册用户，未经过引导的需弹窗进行引导
    // if(!getMemberOnboarding()){
    //     highlightStep(getPopover(".WeekReportDriver","9/9 周报配置","系统提供周报功能，系统会每周生成一周设备健康状态统计报告。针对该功能，可配置周报生成的日期。","周报配置","right","start",null,WeekReportRule,true));
    // }
})

const init = () => {
    search()
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        date: null,
        type: null,
    }
}

const getData = async() => {
    let type = searchModel.value.type ? [searchModel.value.type] : [4]
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            factoryIds: getRealFactoryIdList(),
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)
    

    if(result && result.status === 200) {
        result.data.list = result.data.list.filter((p:any)=>p.type==4)
        result.data.list.map((d:any) => {
            let count1 = 0, count2 = 0, count3 = 0, count4 = 0
            d.monthReport && d.monthReport.map((i:any) => {
                if(i.status === '故障') {
                    count4 ++
                }
                if(i.status === '警戒') {
                    count3 ++
                }
                if(i.status === '可用') {
                    count2 ++
                }
                if(i.status === '健康') {
                    count1 ++
                }
            })
            d.count1 = count1
            d.count2 = count2
            d.count3 = count3
            d.count4 = count4

            if(language.value === 'English') {
                d.title = d.title.replace('周报', 'weekly report')
            }
        })


        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const details = (ev:any) => {
    router.push('/report/mwDetail/' + ev.id)
}



const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};

const getDate = (date: any) => {
  date = new Date(date)
  let year = date.getFullYear()
  let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  let w = date.getDay(),
    d = date.getDate();
  if (w == 0) {
    w = 7;
  }

  if(language.value == 'Chinese') {
    return year + '年' + month + '月' + ' ' + '第' + Math.ceil((d + 6 - w) / 7) + '周'
  } else {
    let monList = [
        {label: 'January', value: '01'},
        {label: 'February', value: '02'},
        {label: 'March', value: '03'},
        {label: 'April', value: '04'},
        {label: 'May', value: '05'},
        {label: 'June', value: '06'},
        {label: 'July', value: '07'},
        {label: 'August', value: '08'},
        {label: 'January', value: '09'},
        {label: 'October', value: '10'},
        {label: 'November', value: '11'},
        {label: 'December', value: '12'},
    ]
    return monList.find((p:any) => p.value == month)?.label + ' ' + year + ',' + 'Week' + Math.ceil((d + 6 - w) / 7)
  }

  
}

const getReportDateRange = (content: string) => {
    if (!content) return "";
    // content = "主要针对2025.03.01-2025.03.25这1月的数据对设备健康状态进行分析。"
    // should return 	2025/02/23 ~ 2025/03/25
    let reg = /(\d{4}\.\d{2}\.\d{2})/g;
    let dateArr = content.match(reg);
    if (dateArr && dateArr.length == 2) {
        return dateArr[0].replace(/\./g, '/') + ' ~ ' + dateArr[1].replace(/\./g, '/');
    }
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

let WeekReportRuleVisible = ref(false);
let editRindex = ref("auto");
//新用户引导周报配置回调
const WeekReportRule =()=>{
    editRindex = 1000000001;
    WeekReportRuleVisible.value = true;
}
const saveWeekReportRule=(ev:any)=>{
    WeekReportRuleVisible.value = false;
}
const ruleSet=()=>{
    WeekReportRuleVisible.value = true;
}
</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table-tbody > tr > td {
    border-bottom: 0px solid #fff
}

/deep/ .ant-picker-input > input {
    color: #fff;
}

/deep/ .ant-picker-clear span {
    background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
    color: #000 !important;
}
/deep/ .anticon-close svg {
    color: #000 !important;
}

/deep/ .ant-select-clear span {
    background-color: #000;
}
</style>
