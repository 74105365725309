<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import dayjs from 'dayjs';
import { langList } from "@/common/lang";
import { getRealFactoryIdList, getGroupName } from "@/common/tools";
import { useRoute } from "vue-router"
import AddRepair from "@/components/warning-manage/add-repair.vue";

const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
let langObj: any = ref({})
let language: any = ref('Chinese')
getLang()

let typeList = [
  { label: '智能诊断', value: 1 },
  { label: '门限报警', value: 4 },
]

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

let warningColumns = [
  {
    title: langObj.value['报警时间'],
    dataIndex: 'date',
  },
  {
    title: langObj.value['报警类型'],
    dataIndex: 'type',
  },
  {
    title: langObj.value['严重程度'],
    dataIndex: 'condition',
  },
  {
    title: langObj.value['区域'],
    dataIndex: 'group',
  },
  {
    title: langObj.value['设备'],
    dataIndex: 'machineName',
  },
  {
    title: langObj.value['报警内容'],
    dataIndex: 'diagnosticNotes',
  }
]

let factory_id = localStorage.getItem('factory_id') || '';

const emit = defineEmits(['close', 'alarm']);

let enableAlarmPopup = ref(false);

const route = useRoute()

watch(route, (to) => {
  checkAndAlarm().catch(() => { });
}, { flush: 'pre', immediate: true, deep: true })

onMounted(async () => {
  let threeMonthAgo = dayjs().subtract(3, 'month');
  latestAlarmTime.value = threeMonthAgo.format('YYYY/MM/DD HH:mm:ss')
  startAlarmTime.value = latestAlarmTime.value;
  await getFactorySettings(factory_id);
  if (enableAlarmPopup.value) {
    setTimeout(() => {
      checkAndAlarm();
    }, 2000);
  }
});

async function checkAndAlarm() {
  if (!enableAlarmPopup.value) return;
  await fetchWorkflows();
  if (workflows.value.length > 0) alarm();
}

let latestAlarmTime = ref<string>(); // 最新报警起始时间(增量获取报警列表)
let startAlarmTime = ref<string>();  // 界面显示的报警起始时间

let timeout: any = null;

function alarm() {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (workflows.value?.length === 0) return;
    visibleRepairModal.value = false;
    emit("alarm")
    clearTimeout(timeout);
  }, 500);
}

function startAlarmJob() {
  setInterval(() => {
    if (workflows.value.length > 0) alarm();
  }, 8000);
}

function startFetchJob() {
  setInterval(() => {
    fetchWorkflows();
  }, 60000);
  setTimeout(async () => {
    await fetchWorkflows()
    alarm();
  }, 2000);
}


async function fetchWorkflows() {
  let list = await getWorkflows();

  workflows.value = list || [];

  //latestAlarmTime.value = dayjs().format('YYYY/MM/DD HH:mm:ss')
}

let workflows = ref<any[]>([]);
let total = computed(() => workflows.value.length);
const getWorkflows = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 1000,
      handleStatus: 1,
      factoryIds: getRealFactoryIdList(),
      typeList: [1]
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (latestAlarmTime.value) {
    config.params.begin = latestAlarmTime.value;
  }

  let result = await request.get('/api/workflows', config)
  return result?.data.list || [];
}

const getFactorySettings = async (factoryId: string) => {
  if (!factoryId) return
  const config = {
    params: { id: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/factories/setting", config)
  if (res?.data?.enableAlarmPopup) {
    enableAlarmPopup.value = res.data.enableAlarmPopup;
  }
};

let visibleRepairModal = ref(false);

function addRepairModal() {
  visibleRepairModal.value = true;
}

async function addRepairSuccess() {
  visibleRepairModal.value = false;
  await fetchWorkflows();
  if (workflows.value.length === 0) {
    emit('close');
  }
}

</script>

<template>
  <div class="box">
    <div class="w-full p-5 text-center">
      <span class="text-red-600 text-3xl">报警：自{{ startAlarmTime }}起，系统共报警{{
        total
      }}条（详情见下表），请尽快处理！</span>
    </div>
    <div class="w-full p-5">
      <div class="box mt-4" style="width: 100%;">
        <a-table style="width: 100%;" size="small" :columns="warningColumns" :data-source="workflows" :pagination="{}"
          rowKey="id">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'type'">
              <div class="flex items-center">
                <div v-if="text === 1"><img style="width: 20px;" src="@/assets/zhenduan.png" alt=""></div>
                <div v-if="text === 2"><img style="width: 20px;" src="@/assets/weixiu.png" alt=""></div>
                <div v-if="text === 3"><img style="width: 20px;" src="@/assets/jiancha.png" alt=""></div>
                <div v-if="text === 4"><img style="width: 20px;" src="@/assets/menxian.png" alt=""></div>
                <div class="ml-2"> {{ langObj[typeList.find(p => p.value === text)?.label || ""] }}</div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'group'">
              <div class="flex items-center">
                {{ getGroupName(record.groupId) }}
              </div>
            </template>


            <template v-if="column.dataIndex === 'condition'">
              <div class="flex items-center">
                <div style="width: 12px; height: 12px; border-radius: 50%;"
                  :style="{ 'background': conditionList.find((p: any) => p.value === text)?.color }"></div>
                <div class="ml-2">{{ langObj[conditionList.find((p: any) => p.value === text)?.label || ""] }}</div>
              </div>
            </template>

          </template>
        </a-table>
      </div>

      <div style="width: 100%;text-align: center" class="mt-4">
        <a-button type="primary" @click="addRepairModal">{{ langObj['添加检修记录'] }}</a-button>
      </div>
    </div>
  </div>

  <a-modal :width="1000" :maskClosable="false" :closable="false" :footer="null" v-model:visible="visibleRepairModal">
    <add-repair @close="visibleRepairModal = false" @success="addRepairSuccess" :warning-list="workflows"></add-repair>
  </a-modal>

</template>

<style scoped lang="less">
:deep(.ant-table-tbody > tr.ant-table-placeholder:hover > td) {
  background: transparent;
}
</style>
