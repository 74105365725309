import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/chat/chat-bot.png'


const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "chat-bot",
  class: "bot-avatar"
}
const _hoisted_3 = {
  key: 1,
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  class: "user-avatar"
}
const _hoisted_4 = { class: "chat-message-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "chat-message-time"
}

import { ref, watch, onMounted, nextTick } from 'vue';
import { ChatMessage } from './chat.types';
import { transformDate } from '@/common/tools';
import { marked } from 'marked';


export default /*@__PURE__*/_defineComponent({
  __name: 'chat-messages',
  props: {
  messages: {
    type: Array as () => ChatMessage[],
    required: true
  }
},
  setup(__props) {

const props = __props;

const messagesContainerRef = ref<HTMLElement | null>(null);

const scrollToBottom = () => {
  if (messagesContainerRef.value) {
    nextTick(() => {
      messagesContainerRef.value!.scrollTop = messagesContainerRef.value!.scrollHeight;
    });
  }
};

// Watch for changes to the messages array
watch(() => props.messages, () => {
  scrollToBottom();
}, { deep: true });

// Scroll to bottom on initial mount
onMounted(() => {
  scrollToBottom();
});

// Configure marked for safe rendering
marked.setOptions({
  breaks: true,
  gfm: true,
});

const renderMarkdown = (content: string) => {
  try {
    return marked(content);
  } catch (e) {
    return content;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "chat-messages-container",
    ref_key: "messagesContainerRef",
    ref: messagesContainerRef
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.messages, (message, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['message-wrapper', 'message-wrapper-' + message.type])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (message.type === 'bot')
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[0] || (_cache[0] = [
                _createElementVNode("path", { d: "M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" }, null, -1)
              ])))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "chat-message",
            innerHTML: renderMarkdown(message.content)
          }, null, 8, _hoisted_5),
          (message.type === 'bot')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(transformDate)(message.time)), 1))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ], 512))
}
}

})