import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/weixiu.png'
import _imports_2 from '@/assets/jiancha.png'
import _imports_3 from '@/assets/menxian.png'


const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  class: "w-full flex px-4 theme_equipment_tab theme-box-title whiteBg",
  style: {"border-bottom":"1px solid #4a5983"}
}
const _hoisted_3 = {
  class: "p-3 w-full whiteBg",
  style: {"height":"calc(100vh - 240px)","overflow-y":"scroll"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = {
  key: 6,
  class: "flex items-center"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }
const _hoisted_23 = { key: 3 }
const _hoisted_24 = { key: 4 }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = {
  key: 6,
  class: "flex items-center"
}
const _hoisted_27 = { style: {"background":"#fff"} }
const _hoisted_28 = { style: {"color":"#fff"} }
const _hoisted_29 = { class: "w-full flex" }
const _hoisted_30 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_31 = { class: "w-full mt-4" }
const _hoisted_32 = { class: "flex items-center" }
const _hoisted_33 = { class: "flex items-center" }
const _hoisted_34 = {
  class: "w-full",
  style: {"border":"1px solid #0d53b7cc"}
}
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "flex items-center" }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { style: {"margin-top":"8px","color":"#fff"} }
const _hoisted_39 = { class: "w-full flex justify-center mt-4" }
const _hoisted_40 = { style: {"color":"#fff"} }
const _hoisted_41 = { class: "w-full flex" }
const _hoisted_42 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_43 = { class: "w-full mt-4" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { style: {"margin-top":"8px","color":"#fff"} }
const _hoisted_46 = { class: "w-full flex justify-center mt-5" }
const _hoisted_47 = {
  key: 0,
  class: "w-full text-center mt-2",
  style: {"color":"#fff"}
}
const _hoisted_48 = { style: {"color":"#fff"} }
const _hoisted_49 = { class: "w-full flex" }
const _hoisted_50 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_51 = { style: {"color":"#fff"} }
const _hoisted_52 = { class: "w-full flex" }
const _hoisted_53 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_54 = { class: "flex items-center" }
const _hoisted_55 = { "ml-2": "" }
const _hoisted_56 = { class: "flex" }
const _hoisted_57 = { class: "w-full flex items-center" }
const _hoisted_58 = { class: "ml-2" }
const _hoisted_59 = {
  class: "mt-2 ml-8",
  style: {"border-left":"2px solid gray"}
}
const _hoisted_60 = { class: "w-full flex items-center flex-wrap table1 ml-8" }
const _hoisted_61 = { style: {"width":"200px"} }
const _hoisted_62 = { class: "w-full text-center mb-2" }
const _hoisted_63 = { class: "mt-2" }
const _hoisted_64 = {
  style: {"width":"200px"},
  class: "ml-4"
}
const _hoisted_65 = { class: "w-full text-center mb-2" }
const _hoisted_66 = { class: "mt-2 flex" }
const _hoisted_67 = { class: "w-full text-center mb-2" }
const _hoisted_68 = { class: "mt-2 flex" }
const _hoisted_69 = { class: "flex items-center" }
const _hoisted_70 = { class: "ml-2" }
const _hoisted_71 = { class: "ml-1" }
const _hoisted_72 = { key: 0 }
const _hoisted_73 = { key: 1 }
const _hoisted_74 = { class: "ml-2" }
const _hoisted_75 = { class: "w-full flex items-center flex-wrap table1 ml-8 mt-2" }
const _hoisted_76 = { class: "w-full text-center mb-2" }
const _hoisted_77 = { class: "mt-2" }
const _hoisted_78 = { key: 0 }
const _hoisted_79 = { key: 1 }
const _hoisted_80 = { key: 2 }
const _hoisted_81 = { class: "w-full flex justify-center mt-5" }
const _hoisted_82 = { style: {"color":"#fff"} }
const _hoisted_83 = { class: "w-full flex" }
const _hoisted_84 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_85 = { class: "w-full mt-4" }
const _hoisted_86 = { class: "w-full" }
const _hoisted_87 = { class: "flex items-center" }
const _hoisted_88 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_89 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_90 = { key: 0 }
const _hoisted_91 = { key: 1 }
const _hoisted_92 = { key: 2 }
const _hoisted_93 = { key: 3 }
const _hoisted_94 = { class: "ml-2" }
const _hoisted_95 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_96 = { class: "ml-2" }
const _hoisted_97 = { key: 2 }
const _hoisted_98 = ["onClick"]
const _hoisted_99 = ["onClick"]
const _hoisted_100 = { class: "w-full flex justify-center mt-5" }
const _hoisted_101 = { style: {"color":"#fff"} }
const _hoisted_102 = { class: "w-full flex" }
const _hoisted_103 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_104 = {
  class: "mt-5",
  style: {"font-size":"16px"}
}
const _hoisted_105 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_106 = {
  key: 0,
  class: "theme-table-link"
}
const _hoisted_107 = {
  class: "mt-10 mb-4",
  style: {"font-size":"16px"}
}
const _hoisted_108 = { class: "flex mt-4" }
const _hoisted_109 = { class: "w-full flex justify-center mt-5" }
const _hoisted_110 = ["src"]

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  createVNode
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";
import ImportMachine from "@/components/equipment/import-machine.vue";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

import AddMachineComponent from "./components/add-machine.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'list',
  setup(__props) {

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

let whetherList = [
  { label: "是", value: true },
  { label: "否", value: false },
];


const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let menuSelect = ref(1); //1: 设备列表  2：测点列表
let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
});
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];


let statusList = [
  { label: "运行", value: 1, color: "#0AF9F6" },
  { label: "停机", value: 0, color: "rgb(162, 166, 171)" },
];


let data = ref([]);

const machineColumns = [
  {
    title: langObj.value["设备编号"],
    dataIndex: "machineCode",
  },
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["所属工厂"],
    dataIndex: "factoryId",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["设备位置"],
    dataIndex: "position",
  },
  {
    title: langObj.value["设备厂家"],
    dataIndex: "supplier",
  },
  {
    title: langObj.value["设备型号"],
    dataIndex: "model",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeAll",
  },
  {
    title: getLanguage('是否配置提资信息'),
    dataIndex: "isUpgradeMachineType",
  },
  {
    title: langObj.value["设备类型缩写"],
    dataIndex: "machineType",
  },
  {
    title: langObj.value["设备SN号"],
    dataIndex: "sn",
  },
  {
    title: langObj.value["巡检序号"],
    dataIndex: "sort",
  },
  // {
  //   title: langObj.value["是否实验台"],
  //   dataIndex: "experiment",
  // },
  {
    title: langObj.value["设备描述"],
    dataIndex: "remark",
  },
  {
    title: getLanguage('监测类别', language.value),
    dataIndex: "mode",
  },
  {
    title: langObj.value["健康状态"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["运行状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const sensorColumns = [
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["数采器编码"],
    dataIndex: "dasCode",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  // {
  //   title: langObj.value["测点ID"],
  //   dataIndex: "id",
  // },
  {
    title: langObj.value["转频提取测点"],
    dataIndex: "fcSensorPlace",
  },
  {
    title: langObj.value["轴参数"],
    dataIndex: "parameter",
  },
  // {
  //   title: langObj.value["开机指标"],
  //   dataIndex: "threshold",
  // },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: getLanguage('智能采集有效期', language.value),
    dataIndex: "validity",
  },
  {
    title: getLanguage('智能诊断巡检配置', language.value),
    dataIndex: "inspectionSubs",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let groupList: any = ref([]);
let machineList: any = ref([]);
let machineTypeList: any = ref([]);
let thresholdList: any = ref([]);
let machineTypeListAll: any = ref([]);
let dataTypeList = ref<any>();
let isEditable: any = ref(false);

let options: any = ref([])
let categoryAll: any = ref([])

let isEdit:any = ref(false);

onMounted(() => {
  isEditable.value = true
  loadDatatype();
  getMachineType();
  getThresholdList()
  getGroupList();
  search();
  getSearch3()
  getBearingParameter()

  setTimeout(() => {
    //新注册用户，未经过引导的需弹窗进行引导
    if (!getMemberOnboarding()) {
      highlightStep(getPopover(".addMachineDriver", "5/9 新建设备", "点击新建设备，可创建需监测的设备信息，通过绑定设备类型，可自动生成测点及3D图等配置。", "新建设备", "right", "start", nextRoute, addMachineDriver));
    }
  }, 200)

});

// 获取轴承型号
let bearingParameters: any = ref([])
const getBearingParameter = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/bearing-parameters/all', config)
  if (result?.data?.length) {
    bearingParameters.value = result.data.map((d: any) => ({
      label: d.bearingModel,
      value: d.bearingModel,
    }))
  }
}

const getSearch3 = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [...new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [...new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [...new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options.value = list
}


const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: langObj.value[t.name] || t.name,
      }));
    }
  });
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;
  }
};

// 获取设备细分
const getThresholdList = async () => {
  let result = await request("/api/external/inspection/threshold");
  if (result && result.data) {
    // machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.firstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.firstCategory === d1)
            .map((p: any) => p.secondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.secondCategory === d2 && p.firstCategory === d1)
          .map((p: any) => p.thirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    thresholdList.value = list;

  }
};
//选择设备细分
const selectThreshold = (ev) => {
  macForm.value.thresholdArr = JSON.parse(JSON.stringify(ev))

}// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const search = () => {
  searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  if (menuSelect.value === 1) {
    getMachineList();
  }

  if (menuSelect.value === 2) {
    searchSensor();
  }
};

//获取设备列表
const getMachineList = async () => {
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      status: searchModel.value.status,
      factoryIds: getRealFactoryIdList(),
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.isUpgradeMachineType === true || searchModel.value.isUpgradeMachineType === false) {
    config.params.isUpgradeMachineType = searchModel.value.isUpgradeMachineType
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.realValue;
  }

  let result = await request("/api/machines", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.machineTypeId) {
        let info = machineTypeListAll.value.find(
          (p: any) => p.id === d.machineTypeId
        );
        if (info) {
          d.machineTypeAll = `${info.FirstCategory}/${info.SecondCategory}/${info.ThirdCategory}`;
        } else {
          d.machineTypeAll = ''
        }
      }
    });
    data.value = result.data;
  }
};

// 获取测点列表
const searchSensor = () => {
  // searchModel.value.groupId = groupList.value[0].id;
  // selectGroup(searchModel.value.groupId);
  // searchModel.value.machineId = machineList.value[0].id;
  getSensorList();
};

const getSensorList = async () => {
  // if (!searchModel.value.machineId) {
  //   return message.warning('请选择设备后查询')
  // }

  data.value = [];
  let config: any = {
    params: {
      factoryId:localStorage.getItem('factory_id'),
      groupId: searchModel.value.groupId,
      machineId: searchModel.value.machineId,
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
  let result = await request.get("/api/sensors/search", config);

  if (result && result.status === 200) {
    data.value = result.data;
  }
};

// 重置
const reset = () => {
  if (menuSelect.value === 1) {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      groupId: null,
      machineId: "",
      condition: null,
      status: null,
      sensorPlace: "",
    };
  } else {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      condition: null,
      status: null,
      groupId: null,
      machineId: "",
      sensorPlace: "",
    };
    // getData();
  }
};

const selectMenu = (ev: any) => {
  menuSelect.value = ev;
  data.value = [];
  reset();
  getData();
};

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
  if (ev && ev.workStatus && ev.workStatus.stableCondition) {
    return conditionList.find(
      (p: any) => p.value === ev.workStatus.stableCondition
    );
  } else {
    return conditionList[0];
  }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
  if (ev.workStatus?.quotaWkStatus) {
    return statusList[0]
  } else if(ev.workStatus?.quotaWkStatus === false) {
    return statusList[1];
  } else if (ev.workStatus?.scoreWkStatus) {
    return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
  } else {
    return statusList[1];
  }
}

// 获取监测类别
const getMode = (ev: any) => {
  if (ev?.length) {
    let list: any = []
    ev.map((d: any) => {
      if (d == 1) {
        list.push(getLanguage('在线监测', language.value))
      }
      if (d == 2) {
        list.push(getLanguage('巡检', language.value))
      }
    })
    return list.join('/')
  } else {
    return ''
  }
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

// 新增测点
let visibleAddSensor = ref(false);
let sensorForm: any = ref({
  sensorSn: null,
  sensorPlace: "",
  machineName: "",
  machineId: "",
  fcSensorId: "",
  fcSensorPlace: "",
  machineTypeId: "",
  national: "",
  remark: "",
  type: null,
  sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
  disable: false,
  onOff: null,
  quotaInterval: null,
  inspectionSubId: null,
  inspectionSubs: null,
  isDas: true,
  dasCode: "",
  sensorSn_OLD: null,
});
let powerEtcList: any = ref([]);
let selectMachineVisible = ref(false);
let selectSensorVisible = ref(false);
const sensorAddColumns = [
  {
    title: langObj.value["编号"],
    dataIndex: "sonId",
  },
  {
    title: langObj.value["轴向"],
    dataIndex: "coordinate",
  },
  {
    title: langObj.value["名称"],
    dataIndex: "sensorGroupName",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
const addSensor = () => {
  sensorForm.value = {
    sensorSn: null,
    sensorPlace: "",
    machineName: "",
    machineId: "",
    fcSensorId: "",
    fcSensorPlace: "",
    machineTypeId: "",
    national: null,
    remark: "",
    dataType: ['高频加速度', '低频加速度Z', '速度Z'],
    type: null,
    sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
    disable: false,
    onOff: null,
    quotaInterval: null,
    inspectionSubId: null,
    inspectionSubs: null,
    isDas: true,
    dasCode: "",
    sensorSn_OLD: null,
  };
  machineFileList.value = [];
  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};
let speedList: any = ref([])

// 选择设备
const selectMachineS = () => {
  selectMachineVisible.value = true;
};

const selectMachineSen = (ev: any) => {
  selectMachineVisible.value = false;
  if (ev) {
    sensorForm.value.machineId = ev.id;
    sensorForm.value.machineName = ev.machineName;
  } else {
    sensorForm.value.machineId = "";
    sensorForm.value.machineName = "";
  }
};

//选择转频提取测点
const selectSensorS = () => {
  if (!sensorForm.value.machineId) {
    message.warning('请选择所属设备后选择转频提取测点')
    return
  }

  selectSensorVisible.value = true;
};

const selectSensorSen = (ev: any) => {
  selectSensorVisible.value = false;
  if (ev) {
    sensorForm.value.fcSensorId = ev.id;
    sensorForm.value.fcSensorPlace = ev.sensorPlace;
  } else {
    sensorForm.value.fcSensorId = "";
    sensorForm.value.fcSensorPlace = "";
  }
};

// 选择类型
const changeThirdTypeId = async (ev: any) => {
  if (ev && ev.length === 3) {
    let info = machineTypeListAll.value.find(
      (p: any) =>
        p.FirstCategory === ev[0] &&
        p.SecondCategory === ev[1] &&
        p.ThirdCategory === ev[2]
    );
    sensorForm.value.machineTypeId = info.id;

    let config = {
      params: { id: info.id },
      headers: {
        requestId: uuidv4(),
      },
    };

    let result = await request.get(
      "/api/sensors/machine-types/power-etc",
      config
    );
    if (!result?.data) {
      powerEtcList.value = [];
      sensorForm.value.national = null;
      return;
    }

    powerEtcList.value = result.data;
    if (result.data.length > 0) {
      sensorForm.value.national = result.data[0].powerEtc;
    } else {
      sensorForm.value.national = null;
    }
  }
};

const addSensorParmar = () => {
  sensorForm.value.sensorGroup.push({ coordinate: "Y" });
};

const deleteSensorParmar = (ev: any) => {
  sensorForm.value.sensorGroup.splice(ev, 1);
};

// 选择巡检设备细分
const changeISub = (ev: any) => {
  if (ev?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    speedList.value = []
  }
}

// 编辑
const editSensor = async (ev: any) => {
  machineFileList.value = [];
  let evValue = JSON.parse(JSON.stringify(ev));
  sensorForm.value = evValue;
  sensorForm.value.sensorSn_OLD = sensorForm.value.sensorSn;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      sensorForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      sensorForm.value.type = []
    }

  }
  if (ev.machine) {
    sensorForm.value.machineName = ev.machine.machineName;
  }

  if (sensorForm.value.pictures) {
    sensorForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = sensorForm.value.pictures;
  }

  if (sensorForm.value?.inspectionSubs?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === sensorForm.value.inspectionSubs[0] && p.secondCategory === sensorForm.value.inspectionSubs[1] && p.thirdCategory === sensorForm.value.inspectionSubs[2])
  } else {
    speedList.value = []
  }

  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};

let sensorForm1 = ref();
const submitSensor = () => {
  sensorForm1.value?.clearValidate();
  sensorForm1.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(sensorForm.value));
      form.sensorGroup && form.sensorGroup.map((p: any) => {
        if (p.sonId) {
          p.sonId = p.sonId.trim()
        }
      })

      if (form.inspectionSubs?.length !== 3) {
        form.inspectionSubs = []
      }
      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 9,
        }));
      }

      let result: any = form.id
        ? await request.put("/api/sensors/info", form)
        : await request.post("/api/sensors", form);


      if (result && result.status) {
        message.success("操作成功");
        visibleAddSensor.value = false;
        getSensorList();
      } else {
        message.warning("操作失败");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 获取有效期
const getValidity = (ev: any) => {
  if (!ev) {
    return ''
  }

  let now = new Date().getTime()
  let end = new Date(ev).getTime()

  if (now >= end) {
    return '已过期'
  } else {
    let date = Math.round((end - now) / (1000 * 60 * 60 * 24))
    return date + '天'
  }
}

// 获取巡检设备细分
const getInspectionSubs = (ev: any) => {
  if (ev?.length !== 3) {
    return ''
  } else {
    return ev.join('/')
  }
}

// 新增设备
let visibleAddMachine = ref(false);
let machineForm: any = ref({
  machineCode: null,
  groupId: null,
  type: null,
  machineName: "",
  position: null,
  supplier: null,
  model: null,
  machineType: null,
  machineTypeId: null,
  thresholdIns: null,
  thresholdArr: [],
  sn: null,
  sort: null,
  experiment: false,
  remark: null,
  pictures: [],
  factoryId: "",
  parentFactoryId: "",
  params: {},
  bitNumber: null,
  machinePower: null,
  mode: [],
});
let macForm = ref();
const addMachine = () => {
  isEdit.value = false
  machineForm.value = null;
  visibleAddMachineNew2.value = true

  return
  machine_select_id.value = ''
  machineForm.value = {
    machineCode: null,
    groupId: null,
    type: null,
    machineName: "",
    position: null,
    supplier: null,
    model: null,
    machineType: null,
    machineTypeID: null,
    // thresholdIns:null,
    // thresholdArr:[],
    sn: null,
    sort: null,
    experiment: false,
    remark: null,
    pictures: [],
    factoryId: "",
    parentFactoryId: "",
    params: {},
    bitNumber: null,
    machinePower: null,
    mode: [],
  };
  machineFileList.value = [];
  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

const close = () => {
  visibleAddMachineNew2.value = false
  getData()
}

const resetData = () => {
  getData()
}

// 新建设备（新）
let machine_select_id: any = ref('')
let visibleAddMachineNew = ref(false);
let visibleAddMachineNew2 = ref(false);
let addMachineForm: any = ref({})
let pic3Ds: any = ref([])
let sensorTreeData: any = ref([]);
let selectSensorList: any = ref([]);
let structureList: any = ref([]) //结构列表
let isShowNext = computed(() => {
  return machineForm.value.mode.includes(1)
})
let addLoading: any = ref(false);

const getStructureList = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-structures/all", config);
  if (result?.data) {
    structureList.value = result.data;
  }
}
getStructureList()

// 新增的下一步
const addMachineNew = async () => {
  if (!machine_select_id.value) {
    macForm.value?.clearValidate();
    macForm.value
      .validate()
      .then(async (res: any) => {
        parts_old.value = null
        addLoading.value = true;
        let form = JSON.parse(JSON.stringify(machineForm.value));
        if (machineFileList.value && machineFileList.value.length) {
          form.pictures = machineFileList.value.map((p: any) => ({
            url: p.url,
            type: 1,
          }));
        }

        if (form.type && form.type.length === 3) {
          let info = machineTypeListAll.value.find(
            (p: any) =>
              p.FirstCategory === form.type[0] &&
              p.SecondCategory === form.type[1] &&
              p.ThirdCategory === form.type[2]
          );
          form.machineTypeId = info.id;
        } else {
          form.machineTypeId = null;
        }
        let type_save = form.type
        delete form.type;

        let result = await request.post("/api/machines", form);
        if (result && result.status === 200) {
          message.success("新增设备成功");
          machine_select_id.value = result.data
        } else {
          message.warning("操作失败,请联系管理员!");
        }

        addMachineForm.value = {
          type: null,
          machineType: null,
          machineTypeId: null,
          machineTypeDetails: {
            isFrequentStart: false,
            pictureUrl: null,
            sensorList: [],
            parts: []
          }
        }
        parts_old.value = null
        visibleAddMachineNew.value = true
        addLoading.value = false;

        // 已选择设备类型
        if (form.machineTypeId && type_save?.length === 3) {
          addMachineForm.value.type = type_save
          selectMT(type_save)
        }

      })
      .catch((err: any) => {
        console.log(err); //失败后执行
        message.warning('请填写必填项后进行下一步')
      });
  } else {
    addMachineForm.value = {
      type: null,
      machineType: null,
      machineTypeId: null,
      machineTypeDetails: {
        isFrequentStart: false,
        pictureUrl: null,
        sensorList: [],
        parts: []
      }
    }


    let form = JSON.parse(JSON.stringify(machineForm.value));
    let type_save = form.type
    if (type_save?.length === 3) {
      addMachineForm.value.type = type_save
      selectMT(type_save)
    }

    visibleAddMachineNew.value = true
  }
}

// 编辑的下一步
let parts_old: any = ref(null)
const addMachineNewNewtEdit = () => {

  macForm.value?.clearValidate();
  macForm.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(machineForm.value));
      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      let result = form.id
        ? await request.put("/api/machines/info", form)
        : await request.post("/api/machines", form);
      if (result && result.status === 200) {
        getData();

        parts_old.value = null
        let { id, type } = machineForm.value
        machine_select_id.value = id
        let machineTypeDetails = JSON.parse(JSON.stringify(machineForm.value.machineTypeDetails) || 'null')
        if (machineTypeDetails) {
          addMachineForm.value = {
            type: null,
            machineType: null,
            machineTypeId: null,
            machineTypeDetails: {
              isFrequentStart: machineTypeDetails.isFrequentStart,
              pictureUrl: machineTypeDetails.pictureUrl,
              sensorList: [],
              parts: []
            }
          }

          if (type?.length === 3) {
            addMachineForm.value.type = type
            selectMT(type)
          }
          if (machineTypeDetails.sensorList?.length) {
            selectSensorList.value = machineTypeDetails.sensorList.map((p: any) => p.partUniqueId + '-' + p.sensorPlace)
            parts_old.value = machineTypeDetails.parts
            selectSensor()
          }

        } else {
          addMachineForm.value = {
            type: null,
            machineType: null,
            machineTypeId: null,
            machineTypeDetails: {
              isFrequentStart: false,
              pictureUrl: null,
              sensorList: [],
              parts: []
            }
          }
        }

        visibleAddMachineNew.value = true


      } else {
        message.warning("操作失败,请联系管理员!");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });

}


// 选择设备类型
const selectMT = async (ev: any) => {
  if (ev?.length === 3) {
    sensorTreeData.value = []
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.FirstCategory === ev[0] && p.SecondCategory === ev[1] && p.ThirdCategory === ev[2])
    if (machineTypeInfo) {
      addMachineForm.value.machineType = machineTypeInfo.ThirdCategoryAbbreviation
      addMachineForm.value.machineTypeId = machineTypeInfo.id
      pic3Ds.value = machineTypeInfo.pictures || []
      // 获取测点列表
      let sensorList1 = machineTypeInfo?.sensorTemplate?.sensorList || []
      sensorList1.map((d: any) => {
        let first: any = {
          label: d.name || machineTypeInfo.ThirdCategory,
          value: d.uniqueId,
          selectable: false,
          children: []
        }
        if (d.sensors?.length > 0) {
          d.sensors.map((s: any) => {
            let second = {
              label: s.sensorPlace,
              value: d.uniqueId + '-' + s.sensorPlace,
              selectable: true
            }
            first.children.push(second)
          })
        }
        sensorTreeData.value.push(first)
      })
    }
  }
}

// 选择测点
const selectSensor = async () => {
  if (!addMachineForm.value.machineTypeId) return message.warning('请先选择设备类型！')
  if (!selectSensorList.value?.length) return message.warning('请先选择测点！')

  let sensorList_select = selectSensorList.value.map((d: any) => {
    return {
      partUniqueId: d.split('-')[0],
      sensorPlace: d.split('-')[1]
    }
  })
  addMachineForm.value.machineTypeDetails.sensorList = sensorList_select

  let uniqueIds = [... new Set(sensorList_select.map((d: any) => d.partUniqueId))]


  let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.id === addMachineForm.value.machineTypeId)
  let sensorList_template = machineTypeInfo?.sensorTemplate?.sensorList || []

  let parts: any = []


  for (let i in uniqueIds) {
    let d = uniqueIds[i]
    let structures: any = []
    let partInfo = sensorList_template.find((p: any) => p.uniqueId === d)
    if (partInfo?.sensors?.length > 0) {
      // 获取细分列表
      let config: any = {
        params: {
          machineTypeId: machineTypeInfo.id,
          partUniqueId: machineTypeInfo.isPart ? '' : d,
          take: 999
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      let result = await request.get('/api/machine-type/segments/list', config)
      let resultList = result?.data?.list || []
      let tableTitles: any = []
      resultList.map((r: any) => {
        tableTitles = tableTitles.concat(Object.keys(r?.columns || {}))
      })
      let firstColumnsData: any = []
      if (tableTitles?.length > 0) {
        resultList.map((t: any) => {
          firstColumnsData.push(t?.columns[tableTitles[0]])
        })
      }
      let segments = {
        columns: [... new Set(tableTitles)],
        dataList: resultList,
        columnsData0: [... new Set(firstColumnsData)],
      }

      let sensors_part = partInfo.sensors.filter((p: any) => sensorList_select.find((s: any) => s.sensorPlace === p.sensorPlace))

      sensors_part.map((s: any) => {
        // structureList.value
        s.machineStructure.map((m: any) => {
          let structureInfo = structureList.value.find((p: any) => p.id === m)
          // 轴承齿轮需要绑定测点
          if (m === "672c55e3e19a0d0e17e653b7" || m === "672c55e3e19a0d0e17e653b9" || m === "672c55e3e19a0d0e17e653ba") {
            structures.push({
              sensorPlace: s.sensorPlace,
              machineStructureId: m,
              machineStructureName: structureInfo?.name,
              parmarList: getStructureParmar(m)
            })
          } else {
            if (!structures.find((p: any) => p.machineStructureId === m)) {
              structures.push({
                sensorPlace: '',
                machineStructureId: m,
                machineStructureName: structureInfo?.name,
                parmarList: getStructureParmar(m)
              })
            }
          }


        })
      })

      parts.push({
        partUniqueId: d,
        partName: partInfo.name,
        machineTypeSegmentId: null,
        params: {
          Rated_speed: null,
          PowerFre: null,
          startFre: null,
          stopFre: null,
        },
        structures,
        segments,
      })

      parts.map((part: any) => {
        let part_old = null;
        if (parts_old.value) {
          part_old = parts_old.value?.find((p: any) => p.partUniqueId === part.partUniqueId);
        }

        if (part_old) {
          part.params = part_old.params
          part.disable = true

          part.structures.map((structure: any) => {
            let structure_old = part_old.structures.find((p: any) => p.sensorPlace === structure.sensorPlace && p.machineStructureId === structure.machineStructureId)
            structure.parmarList && structure.parmarList.map((parmar: any) => {
              if (structure_old?.params && structure_old?.params[parmar.key]) {
                parmar.value = structure_old?.params[parmar.key]
              }
            })
          })

        }
      })


    }
  }

  addMachineForm.value.machineTypeDetails.parts = parts

}

// 获取不同结构的参数
const getStructureParmar = (id: any) => {
  let list = [
    {
      id: "672c55e3e19a0d0e17e653b6",
      name: '叶轮',
      parmar: [
        { label: '叶轮级数', value: 1, key: 'Impeller_series' },
        { label: '叶轮数目', value: null, key: 'BladeNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bc",
      name: '电机',
      parmar: [
        { label: '额定转速', value: null, key: 'Rated_speed' },
        { label: '额定电源频率', value: 50, key: 'PowerFre' },
        { label: '电机极对数', value: null, key: 'p' },
        { label: '转子条数', value: null, key: 'rot_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b7",
      name: '轴承',
      parmar: [
        { label: '轴承类型', value: null, key: 'type', type: 'select', option: [{ label: '滚动轴承', value: 1 }, { label: '滑动轴承', value: 2 }] },
        // {label: '轴承参数id', value: null, key: 'bearingParameterId', type: 'string'},
        { label: '轴承型号', value: null, key: 'bearingModel', type: 'select', option: bearingParameters.value },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b9",
      name: '行星齿轮',
      parmar: [
        { label: '太阳轮齿数', value: null, key: 'Zs', },
        { label: '一倍啮合频率', value: null, key: 'MeshFre' },
        { label: '行星轮个数', value: null, key: 'PLanetary' },
        { label: '齿圈齿数', value: null, key: 'RingTeeth' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653ba",
      name: '定轴齿轮',
      parmar: [
        { label: '主动齿轮齿数', value: null, key: 'GeaTeethNum', },
        { label: '从动齿轮齿数', value: null, key: 'GeaOutTeethNum' },
        { label: '提取啮合频率谐波阶数', value: 5, key: 'mesh_n' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b8",
      name: '柱塞',
      parmar: [
        { label: '柱塞数目', value: null, key: 'z', },
        { label: '柱塞泵滤波参数', value: 2000, key: 'wps' },
        { label: '柱塞泵滤波参数', value: 3000, key: 'wss' },
        { label: '柱塞泵转频上限', value: null, key: 'fr_Min' },
        { label: '柱塞泵转频下限', value: null, key: 'fr_Max' },
        { label: '轴柱塞泵个数', value: null, key: 'PlungerNum' },
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bb",
      name: '螺杆',
      parmar: [
        { label: '螺杆阳转子齿数', value: null, key: 'ScrTeethNum', },
        { label: '螺杆阴转子齿数', value: null, key: 'ScrOutTeethNum' }
      ]
    },

  ]
  return list.find((p: any) => p.id === id)?.parmar || []
}

// 选择细分内容
const selectPartParmar = (segments: any, index: any) => {
  if (index + 1 >= segments.columns.length) {
    let dataListC = JSON.parse(JSON.stringify(segments.dataList))
    for (let i in segments.columns) {
      let c = segments.columns[i]
      dataListC = dataListC.filter((p: any) => p.columns[c] === segments['data' + i])
    }
    if (dataListC?.length) {
      segments.id = dataListC[0].id
    } else {
      message.warning(getLanguage('暂无选择的细分， 请重新选择'))
    }
    return
  }
  let column = segments.columns[index + 1]
  let columnsData: any = []
  let dataListS = segments.dataList.filter((p: any) => p.columns[segments.columns[index]])
  dataListS.map((t: any) => {
    columnsData.push(t?.columns[column])
  })
  segments['columnsData' + (index + 1)] = columnsData
}


// 提交提资信息
let isSubmitV: any = ref(false)
const submitMachineNew = async () => {
  if (!selectSensorList.value?.length) return message.warning('请先选择测点！')
  let isComplete = true
  let isCompleteMsg = ''
  let isSub = true
  let submit = JSON.parse(JSON.stringify(addMachineForm.value))
  console.log('submit', submit)
  submit.machineTypeDetails.parts.map((d: any) => {
    if(!d.params?.Rated_speed && d.params?.Rated_speed !== 0) {
      isComplete = false
      isCompleteMsg = '请输入额定转速后提交!'
    }
    if(!d.params?.startFre && d.params?.startFre !== 0) {
      isComplete = false
      isCompleteMsg = '请输入转速范围后提交!'
    }
    if(!d.params?.stopFre && d.params?.stopFre !== 0) {
      isComplete = false
      isCompleteMsg = '请输入转速范围后提交!'
    }

    d.machineTypeSegmentId = d.segments.id
    delete d.segments
    d.structures.map((s: any) => {
      let parmarList = s.parmarList
      delete s.parmarList
      let parmar: any = {}
      parmarList.map((p: any) => {
        parmar[p.key] = p.value
        if (!p.value && p.value !== 0) {
          isSub = false
        }
      })
      s.params = parmar
    })
  })

  if(!isComplete) {
    return message.warning(isCompleteMsg)
  }

  if (!isSub && !isSubmitV.value) {
    Modal.confirm({
      title: '',
      icon: createVNode(ExclamationCircleOutlined),
      content: createVNode('div', { style: 'color:#fff;' }, '有部件或结构信息未填写完整，对应的健康指标将不会显示，您确认要提交吗?'),
      onOk() {
        isSubmitV.value = true
        submitMachineNew()
      },
      onCancel() {
      },
      style: { color: '#fff' },
      zIndex: 1000000003
    });
    return
  }

  submit.id = machine_select_id.value

  let result = await request.post('/api/machines/machine-type-details', submit)
  if (result?.status == 200) {
    message.success('操作成功!')
    visibleAddMachineNew.value = false
    visibleAddMachine.value = false
  } else {
    message.warning('操作失败,请联系管理员!')
  }

}

let titleEdit = ref('')
const editMachine = async (ev: any) => {
  machine_select_id.value = ''
  machineFileList.value = [];
  let dataValue = JSON.parse(JSON.stringify(ev));
  if (!dataValue.params) {
    dataValue.params = {}
  }
  if (!dataValue.mode) {
    dataValue.mode = []
  }
  delete dataValue.machineTypeAll;
  machineForm.value = dataValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      machineForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      machineForm.value.type = null
    }
  }

  if (machineForm.value.pictures) {
    machineForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = machineForm.value.pictures;
  }

  // visibleAddMachine.value = true;
  // setTimeout(() => {
  //   macForm.value.clearValidate();
  // }, 200);
  isEdit.value = true
  titleEdit.value = "编辑设备";
  visibleAddMachineNew2.value = true;
};

let visibleImportMachine = ref(false);
const onImportMachineSuccess = () => {
  visibleImportMachine.value = false;
  getData();
};

const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

//设备图片
let machineFileList = ref<any>([]);

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

const submitMachine = () => {
  macForm.value?.clearValidate();
  macForm.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(machineForm.value));
      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      let result = form.id
        ? await request.put("/api/machines/info", form)
        : await request.post("/api/machines", form);
      if (result && result.status === 200) {
        message.success("操作成功");
        visibleAddMachine.value = false;
        await getMember();
        getData();
      } else {
        message.warning("操作失败,请联系管理员!");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 报警记录
let visibleWarning = ref(false);
let warningList: any = ref([]);
let warnSearchModel: any = ref({
  machineId: null,
  date: null,
  type: null,
  condition: null,
  skip: 1,
  take: 20,
  total: 0,
  keyWord: "",
});
const warmPagination = computed(() => ({
  total: warnSearchModel.value.total,
  current: warnSearchModel.value.skip,
  pageSize: warnSearchModel.value.take,
}));
const changeWarnTable = (pagination: any) => {
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getWarningList();
};
let warnTypeList = [
  { label: '智能诊断', value: 1 },
  { label: '门限报警', value: 4 },
]
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const resetWarn = () => {
  warnSearchModel.value = {
    ...warnSearchModel.value,
    date: null,
    type: null,
    condition: null,
  };
};

const openMachineWarining = (ev: any) => {
  warnSearchModel.value.machineId = ev.id;
  visibleWarning.value = true;
  warnSearchModel.value.skip = 1;
  resetWarn();
  getWarningList();
};

const getWarningList = async () => {
  warningList.value = [];
  let config: any = {
    params: {
      skip: warnSearchModel.value.skip,
      take: warnSearchModel.value.take,
      machineId: warnSearchModel.value.machineId,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === warnSearchModel.value.condition
    )?.realValue;
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warningList.value = result.data.list;
    warnSearchModel.value.total = result.data.total;
  }
};

//检修记录
let visibleRepair = ref(false);
let repairForm: any = ref({
  list: [],
});

let repairColumns = [
  {
    title: "报警时间",
    dataIndex: "id",
  },
  {
    title: "严重程度",
    dataIndex: "id",
  },
  {
    title: "故障描述",
    dataIndex: "id",
  },
];

let accuracyList = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 2 },
  { label: "故障", value: 3 },
  { label: "不确定", value: 4 },
];

const getRepairInfo = async (id: any) => {
  repairForm.value = { list: [] };

  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  if (result && result.data) {
    repairForm.value = {
      begin: result.data.repairReport.begin,
      end: result.data.repairReport.end,
      maintainMessage: result.data.repairReport.maintainMessage,
      maintainPerson: result.data.repairReport.maintainPerson,
      machineName: result.data.machines[0].machineName,
      accuracy: accuracyList.find(
        (p: any) => p.value === result.data.repairReport.accuracy
      )?.label,
    };
    if (result.data.machines.length) {
      repairForm.value.groupName = getGroupName(
        result.data.machines[0].groupId
      );
    }
  }
};

// 健康总览
const viewMachine = (ev: any) => {
  if (ev) {
    localStorage.setItem("machine_id", ev.id);
    router.push("/diagnosisN/overview");
  }
};

// 查看专家报告
let visibleReport = ref(false);
let reportId = ref("");
let mrDref = ref();
const viewReport = (ev: any) => {
  visibleReport.value = true;
  reportId.value = ev.reportIds[0];
  setTimeout(() => {
    mrDref.value.show(ev.reportIds[0]);
  }, 500);
};
const cancel = (ev: any) => {
  if (ev) {
    visibleReport.value = false;
    reportId.value = "";
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const initNodeInfo = () => {
  sensorForm1.value?.clearValidate();
  sensorForm1.value
    .validate()
    .then(async (res: any) => {
      let result = await request.post("/api/sensors/initNode", JSON.parse(JSON.stringify(sensorForm.value)));
      if (result && !!result.data && !!result.data.data) {//自研das
        var allnode = result.data.data;
        sensorForm.value.sensorGroup.length = 0;
        var is3 = [];
        if (!!sensorForm.value.dataType) {
          is3 = sensorForm.value.dataType.filter((p: any) => !!p && (p.indexOf("X") >= 0 || p.indexOf("Y") >= 0));//包含XY指标/波形时，判断为三轴，否则为单轴
        }
        var pnode = allnode.find(p => p.node_type == 10);
        if (!!is3 && is3.length > 0) {
          allnode.map(function (node) {
            if (node.node_type != 10) {
              var sgname = node.node_type == 21 ? "竖直径向" : node.node_type == 22 ? "轴向" : node.node_type == 23 ? "水平径向" : "";
              var cd = node.node_type == 21 ? "Z" : node.node_type == 22 ? "X" : node.node_type == 23 ? "Y" : "";
              sensorForm.value.sensorGroup.push({ coordinate: cd, sonId: node.id, sensorGroupName: sgname, node_name: pnode?.node_name + "/" + node.node_name });
            }
          });
        } else {
          var node = allnode.find(p => p.node_type == 21);
          if (node) {
            sensorForm.value.sensorGroup.push({ coordinate: "Z", sonId: node.id, sensorGroupName: "竖直径向", node_name: pnode?.node_name + "/" + node.node_name });
          }
        }
        sensorForm.value.sensorSn_OLD = sensorForm.value.sensorSn;
        message.success("三轴参数生成成功!");
      } else {
        message.warning("三轴参数生成失败，请联系管理员!");
      }
    })
    .catch((err: any) => {
      message.warning("请先填写必填项!");
    });
};
//新用户引导新建设备按钮回调
let editRindex: any = ref(1000);
let editRindex2: any = ref(1001);
let dropdownClassName: any = ref("");
const addMachineDriver = () => {
  editRindex = 1000000001;
  dropdownClassName = "dropdownStyleDriver";
  addMachine();
};
//新用户引导新建设备下一步按钮回调
const nextRoute = () => {
  if (data.value.length == 0) {
    message.warning("请先新建设备!");
    return;
  }
  selectMenu(2);
  setTimeout(() => {
    highlightStep(getPopover(".editSensorDriver", "6/9 编辑测点", "设备新建后，需在测点列表操作编辑安装的传感器SN，绑定成功后才可完成数据回传。", "编辑测点", "left", "center", nextRouteAlarm, editSensorDriver));
  }, 400)
};
//新用户引导编辑测点按钮回调
const editSensorDriver = () => {
  editRindex = 1000000001;
  editRindex2 = 1000000002;
  dropdownClassName = "dropdownStyleDriver";
  if (data.value.length > 0) {
    editSensor(data.value[0]);
  }
};
//新用户引导编辑测点下一步按钮回调
const nextRouteAlarm = () => {
  router.push("/warning-manage/warning-list");
  return;
};

const getMember = async () => {
  let result2 = await request.get("/api/members/info");
  localStorage.setItem("memberInfo", JSON.stringify(result2.data));
};

return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: {"font-size":"18px"},
        class: _normalizeClass(["py-3 cursor-pointer", _unref(menuSelect) === 1 ? 'theme_equipment_tab_select' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
      }, _toDisplayString(_unref(langObj)['设备列表']), 3),
      _createElementVNode("div", {
        style: {"font-size":"18px"},
        class: _normalizeClass(["py-3 ml-12 cursor-pointer", _unref(menuSelect) === 2 ? 'theme_equipment_tab_select' : '']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
      }, _toDisplayString(_unref(langObj)['测点列表']), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_unref(menuSelect) === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_a_form, {
              model: _unref(searchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备区域'],
                  name: "groupId",
                  allowClear: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).groupId,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择区域']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['健康状态'],
                  name: "condition"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).condition,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).condition) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择健康状态']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['运行状态'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).status,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).status) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择运行状态']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('是否配置提资信息'),
                  name: "isUpgradeMachineType"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).isUpgradeMachineType,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(searchModel).isUpgradeMachineType) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(whetherList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['搜索'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(searchModel).keyWord,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).keyWord) = $event)),
                      placeholder: _unref(langObj)['请输入设备名称/编号']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        onClick: reset,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_unref(langObj)['重置']), 1),
                      _createElementVNode("div", {
                        onClick: getData,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1),
                      (_unref(isEditable))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: addMachine,
                            class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit addMachineDriver",
                            style: {"background-color":"#072499"}
                          }, _toDisplayString(_unref(langObj)['新建设备']), 1))
                        : _createCommentVNode("", true),
                      (_unref(isEditable))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_isRef(visibleImportMachine) //@ts-ignore
 ? visibleImportMachine.value = true : visibleImportMachine = true)),
                            class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                            style: {"background-color":"#072499"}
                          }, _toDisplayString(_unref(getLanguage)('导入设备')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: machineColumns,
                "data-source": _unref(data),
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'machineTypeAll')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(getLanguage)(text, _unref(language))), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'isUpgradeMachineType')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(getLanguage)(_unref(whetherList).find((p) => p.value === text)?.label || '否')), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'mode')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(getMode(record.mode)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'factoryId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(getFactoryName)(text)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'groupId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(getGroupName)(text)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'experiment')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(whetherList).find((p) => p.value === text)?.label), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getMachienCondition(record)?.color }])
                        }, null, 4),
                        _createElementVNode("div", {
                          class: "ml-1",
                          style: _normalizeStyle({ color: getMachienCondition(record)?.color })
                        }, _toDisplayString(_unref(langObj)[getMachienCondition(record)?.label]), 5)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 7,
                        class: "ml-1",
                        style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                      }, _toDisplayString(_unref(langObj)[getMachienStatus(record)?.label]), 5))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                        (_unref(isEditable))
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (editMachine(record)),
                              class: "theme-table-link",
                              style: {"color":"#00fff4"}
                            }, _toDisplayString(_unref(langObj)['编辑']), 9, _hoisted_14))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_divider, { type: "vertical" }),
                        _createElementVNode("a", {
                          onClick: ($event: any) => (openMachineWarining(record)),
                          style: {"color":"#00fff4"},
                          class: "theme-table-link"
                        }, _toDisplayString(_unref(langObj)['报警记录']), 9, _hoisted_15),
                        _createVNode(_component_a_divider, { type: "vertical" }),
                        _createElementVNode("a", {
                          onClick: ($event: any) => (viewMachine(record)),
                          style: {"color":"#00fff4"},
                          class: "theme-table-link"
                        }, _toDisplayString(_unref(langObj)['健康诊断']), 9, _hoisted_16)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(menuSelect) === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode(_component_a_form, {
              model: _unref(searchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备区域'],
                  name: "groupId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).groupId,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                      style: {"width":"220px"},
                      onChange: selectGroup
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备'],
                  name: "machineId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).machineId,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                      style: {"width":"220px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _cache[73] || (_cache[73] = _createTextVNode(" 、 ")),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['测点位置'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      placeholder: _unref(langObj)['请输入测点位置'],
                      value: _unref(searchModel).keyWord,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(searchModel).keyWord) = $event))
                    }, null, 8, ["placeholder", "value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", {
                        onClick: reset,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_unref(langObj)['重置']), 1),
                      _createElementVNode("div", {
                        onClick: getSensorList,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1),
                      (_unref(isEditable))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: addSensor,
                            class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                            style: {"background-color":"#072499"}
                          }, _toDisplayString(_unref(langObj)['新建测点']), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: sensorColumns,
                "data-source": _unref(data)
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'groupId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(getGroupName)(record?.machine?.groupId)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'machineName')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(record?.machine?.machineName), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'hardware')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString((record?.hardware?.vol || record?.hardware?.vol == 0) ? record.hardware.vol + ' %' : ''), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'validity')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(getValidity(record.validityEnd)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'inspectionSubs')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(getInspectionSubs(record.inspectionSubs)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                        (_unref(isEditable))
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (editSensor(record)),
                              class: "theme-table-link editSensorDriver"
                            }, _toDisplayString(_unref(langObj)['编辑']), 9, _hoisted_25))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'parameter')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.sensorGroup, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                              title: _withCtx(() => [
                                _createElementVNode("div", _hoisted_27, [
                                  _createElementVNode("div", null, _toDisplayString(_unref(langObj)['传感器编号']) + ": " + _toDisplayString(item.sonId), 1),
                                  _createElementVNode("div", null, _toDisplayString(_unref(langObj)['安装坐标']) + ": " + _toDisplayString(item.coordinate), 1),
                                  _createElementVNode("div", null, _toDisplayString(_unref(langObj)['轴名称']) + ": " + _toDisplayString(_unref(langObj)[item.sensorGroupName]), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_a_tag, { color: "#2db7f5" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.coordinate), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_modal, {
      class: "whiteBg",
      visible: _unref(visibleAddSensor),
      "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => (_isRef(visibleAddSensor) ? (visibleAddSensor).value = $event : visibleAddSensor = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1300px",
      maskClosable: false,
      "z-index": _unref(editRindex),
      "onUpdate:zIndex": _cache[29] || (_cache[29] = ($event: any) => (_isRef(editRindex) ? (editRindex).value = $event : editRindex = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _cache[74] || (_cache[74] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_isRef(visibleAddSensor) //@ts-ignore
 ? visibleAddSensor.value = false : visibleAddSensor = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(sensorForm).id ? _unref(langObj)["编辑测点"] : _unref(langObj)["新建测点"]), 1),
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_a_form, {
              ref_key: "sensorForm1",
              ref: sensorForm1,
              model: _unref(sensorForm),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off",
              class: "w-full",
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['是否有网关'],
                          name: "isDas"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_switch, {
                              checked: _unref(sensorForm).isDas,
                              "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((_unref(sensorForm).isDas) = $event)),
                              "checked-children": _unref(langObj)['是'],
                              "un-checked-children": _unref(langObj)['否']
                            }, null, 8, ["checked", "checked-children", "un-checked-children"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['数采器编码'],
                          name: "dasCode",
                          required: _unref(sensorForm).isDas
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(sensorForm).dasCode,
                              "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(sensorForm).dasCode) = $event)),
                              placeholder: _unref(langObj)['请输入数采器编码']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label", "required"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['传感器SN'],
                          name: "sensorSn",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(sensorForm).sensorSn,
                              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(sensorForm).sensorSn) = $event)),
                              placeholder: _unref(langObj)['请输入传感器SN']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['安装位置'],
                          name: "sensorPlace",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(sensorForm).sensorPlace,
                              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(sensorForm).sensorPlace) = $event)),
                              placeholder: _unref(langObj)['请输入安装位置']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['所属设备'],
                          name: "machineName",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_a_input, {
                                value: _unref(sensorForm).machineName,
                                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(sensorForm).machineName) = $event)),
                                placeholder: _unref(langObj)['请选择所属设备'],
                                readonly: ""
                              }, null, 8, ["value", "placeholder"]),
                              _createElementVNode("div", {
                                onClick: selectMachineS,
                                class: "cursor-pointer btnTheme"
                              }, _toDisplayString(_unref(langObj)['选择']), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['转频提取'],
                          name: "fcSensorPlace"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_33, [
                              _createVNode(_component_a_input, {
                                value: _unref(sensorForm).fcSensorPlace,
                                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(sensorForm).fcSensorPlace) = $event)),
                                placeholder: _unref(langObj)['请选择转频提取测点'],
                                readonly: ""
                              }, null, 8, ["value", "placeholder"]),
                              _createElementVNode("div", {
                                onClick: selectSensorS,
                                class: "cursor-pointer btnTheme"
                              }, _toDisplayString(_unref(langObj)['选择']), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型'],
                          name: "type"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              style: {"max-width":"236px"},
                              onChange: changeThirdTypeId,
                              value: _unref(sensorForm).type,
                              "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(sensorForm).type) = $event)),
                              options: _unref(machineTypeList),
                              placeholder: _unref(langObj)['请选择设备类型'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['功率'],
                          name: "national"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              style: {"width":"244px"},
                              value: _unref(sensorForm).national,
                              "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(sensorForm).national) = $event)),
                              placeholder: _unref(langObj)['请选择功率'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(powerEtcList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.powerEtc
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.powerEtc), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['是否启用'],
                          name: "disable"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_switch, {
                              checked: _unref(sensorForm).disable,
                              "onUpdate:checked": _cache[20] || (_cache[20] = ($event: any) => ((_unref(sensorForm).disable) = $event)),
                              "checked-children": _unref(langObj)['禁用'],
                              "un-checked-children": _unref(langObj)['启用']
                            }, null, 8, ["checked", "checked-children", "un-checked-children"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(getLanguage)('智能诊断巡检配置', _unref(language)),
                          name: "inspectionSubId"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              onChange: changeISub,
                              value: _unref(sensorForm).inspectionSubs,
                              "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_unref(sensorForm).inspectionSubs) = $event)),
                              options: _unref(options),
                              dropdownClassName: _unref(dropdownClassName),
                              placeholder: ""
                            }, null, 8, ["value", "options", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(getLanguage)('转速范围', _unref(language)),
                          name: "defaultSpeed"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              style: {"width":"244px"},
                              value: _unref(sensorForm).defaultSpeed,
                              "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_unref(sensorForm).defaultSpeed) = $event)),
                              placeholder: _unref(langObj)['请选择'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(speedList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.speedRange), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(getLanguage)('测点排序', _unref(language)),
                          name: "sort"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_number, {
                              style: {"width":"225px"},
                              value: _unref(sensorForm).sort,
                              "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_unref(sensorForm).sort) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['数据类型']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _unref(sensorForm).dataType,
                              "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(sensorForm).dataType) = $event)),
                              mode: "multiple",
                              placeholder: _unref(langObj)['请选择数据类型'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataTypeList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['默认展示数据类型']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _unref(sensorForm).defaultDataType,
                              "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(sensorForm).defaultDataType) = $event)),
                              mode: "multiple",
                              placeholder: _unref(langObj)['请选择数据类型'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorForm).dataType, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['备注'],
                          name: "remark",
                          class: "w-full"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _unref(sensorForm).remark,
                              "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_unref(sensorForm).remark) = $event)),
                              "show-count": "",
                              maxlength: 200,
                              placeholder: _unref(langObj)['请输入备注'],
                              rows: 4
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['三轴参数'],
                          name: "sn",
                          class: "w-full"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_a_table, {
                                style: {"width":"100%"},
                                size: "small",
                                pagination: false,
                                locale: { emptyText: ' ' },
                                columns: sensorAddColumns,
                                "data-source": _unref(sensorForm).sensorGroup
                              }, {
                                bodyCell: _withCtx(({ column, text, index, record }) => [
                                  (column.dataIndex === 'act')
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        onClick: ($event: any) => (deleteSensorParmar(index)),
                                        class: "theme-table-link"
                                      }, _toDisplayString(_unref(langObj)['删除']), 9, _hoisted_35))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'sonId')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 1,
                                        value: record.sonId,
                                        "onUpdate:value": ($event: any) => ((record.sonId) = $event),
                                        placeholder: _unref(langObj)['请输入编号']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'coordinate')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 2,
                                        value: record.coordinate,
                                        "onUpdate:value": ($event: any) => ((record.coordinate) = $event),
                                        placeholder: _unref(langObj)['请输入安装坐标']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'sensorGroupName')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 3,
                                        value: record.sensorGroupName,
                                        "onUpdate:value": ($event: any) => ((record.sensorGroupName) = $event),
                                        placeholder: _unref(langObj)['请输入轴名称']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["data-source"]),
                              _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("div", {
                                  onClick: addSensorParmar,
                                  class: "ml-4 my-2 cursor-pointer btnTheme"
                                }, _toDisplayString(_unref(langObj)['添加']), 1),
                                _createElementVNode("div", {
                                  onClick: initNodeInfo,
                                  class: "ml-4 my-2 cursor-pointer btnTheme"
                                }, _toDisplayString(_unref(langObj)['生成三轴参数']), 1)
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['测点照片']
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload, {
                          "file-list": _unref(machineFileList),
                          "onUpdate:fileList": _cache[27] || (_cache[27] = ($event: any) => (_isRef(machineFileList) ? (machineFileList).value = $event : machineFileList = $event)),
                          "list-type": "picture-card",
                          onPreview: handlePreview,
                          "custom-request": _unref(customRequest),
                          "auto-upload": false,
                          accept: "image",
                          "max-count": 6
                        }, {
                          default: _withCtx(() => [
                            (!_unref(machineFileList) || _unref(machineFileList).length < 6)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                  _createVNode(_component_plus_outlined),
                                  _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(langObj)['上传']), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["file-list", "custom-request"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", {
                onClick: submitSensor,
                class: "btn-default py-1 px-6 cursor-pointer btn-submit"
              }, _toDisplayString(_unref(langObj)['提交']), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "z-index"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleAddMachine),
      "onUpdate:visible": _cache[49] || (_cache[49] = ($event: any) => (_isRef(visibleAddMachine) ? (visibleAddMachine).value = $event : visibleAddMachine = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1300px",
      maskClosable: false,
      "z-index": _unref(editRindex),
      "onUpdate:zIndex": _cache[50] || (_cache[50] = ($event: any) => (_isRef(editRindex) ? (editRindex).value = $event : editRindex = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _cache[75] || (_cache[75] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[30] || (_cache[30] = ($event: any) => (_isRef(visibleAddMachine) //@ts-ignore
 ? visibleAddMachine.value = false : visibleAddMachine = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(machineForm).id ? _unref(langObj)["编辑设备"] : _unref(langObj)["新建设备"]), 1),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_a_form, {
              ref_key: "macForm",
              ref: macForm,
              model: _unref(machineForm),
              layout: "inline",
              autocomplete: "off",
              class: "w-full",
              "label-col": { style: { width: '180px', wordWrap: 'break-word' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备区域'],
                          name: "groupId",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _unref(machineForm).groupId,
                              "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_unref(machineForm).groupId) = $event)),
                              placeholder: _unref(langObj)['请选择设备区域'],
                              onChange: selectGroupM,
                              dropdownClassName: _unref(dropdownClassName)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备编号'],
                          name: "machineCode"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineCode,
                              "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_unref(machineForm).machineCode) = $event)),
                              placeholder: _unref(langObj)['请输入设备编号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备名称'],
                          name: "machineName",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineName,
                              "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_unref(machineForm).machineName) = $event)),
                              placeholder: _unref(langObj)['请输入设备名称']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备位置'],
                          name: "position"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).position,
                              "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_unref(machineForm).position) = $event)),
                              placeholder: _unref(langObj)['请输入设备位置']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备厂家'],
                          name: "supplier"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).supplier,
                              "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_unref(machineForm).supplier) = $event)),
                              placeholder: _unref(langObj)['请输入设备厂家']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备型号'],
                          name: "model"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).model,
                              "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => ((_unref(machineForm).model) = $event)),
                              placeholder: _unref(langObj)['请输入设备型号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型'],
                          name: "type"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              style: {"max-width":"236px"},
                              value: _unref(machineForm).type,
                              "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => ((_unref(machineForm).type) = $event)),
                              options: _unref(machineTypeList),
                              placeholder: _unref(langObj)['请选择设备类型'],
                              dropdownClassName: _unref(dropdownClassName)
                            }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型缩写'],
                          name: "machineType"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineType,
                              "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_unref(machineForm).machineType) = $event)),
                              placeholder: _unref(langObj)['请输入设备类型缩写']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备SN号'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).sn,
                              "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => ((_unref(machineForm).sn) = $event)),
                              placeholder: _unref(langObj)['请输入设备SN号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['巡检序号'],
                          name: "sort",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_number, {
                              controls: false,
                              style: {"width":"100%"},
                              value: _unref(machineForm).sort,
                              "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_unref(machineForm).sort) = $event)),
                              placeholder: _unref(langObj)['请输入']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备位号'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).bitNumber,
                              "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_unref(machineForm).bitNumber) = $event)),
                              placeholder: _unref(langObj)['请输入设备位号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备功率'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machinePower,
                              "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_unref(machineForm).machinePower) = $event)),
                              placeholder: _unref(langObj)['请输入设备功率']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备ID'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).params.ysshId,
                              "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_unref(machineForm).params.ysshId) = $event)),
                              placeholder: _unref(langObj)['请输入设备ID']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备执行人'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).params.ysshExecutor,
                              "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => ((_unref(machineForm).params.ysshExecutor) = $event)),
                              placeholder: _unref(langObj)['请输入设备执行人']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      style: {"display":"none"},
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['智能诊断巡检配置'],
                          name: "threshold"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              onChange: selectThreshold,
                              style: {"max-width":"236px"},
                              value: _unref(machineForm).thresholdIns,
                              "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_unref(machineForm).thresholdIns) = $event)),
                              options: _unref(thresholdList),
                              placeholder: _unref(langObj)['请选择设备细分']
                            }, null, 8, ["value", "options", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['检测类别'],
                          name: "mode",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_checkbox_group, {
                              value: _unref(machineForm).mode,
                              "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_unref(machineForm).mode) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_checkbox, { value: 1 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['在线监测']), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_checkbox, { value: 2 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['巡检']), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备描述'],
                          name: "remark"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _unref(machineForm).remark,
                              "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_unref(machineForm).remark) = $event)),
                              placeholder: "请输入",
                              rows: 4
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备照片']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_upload, {
                              "file-list": _unref(machineFileList),
                              "onUpdate:fileList": _cache[48] || (_cache[48] = ($event: any) => (_isRef(machineFileList) ? (machineFileList).value = $event : machineFileList = $event)),
                              "list-type": "picture-card",
                              onPreview: handlePreview,
                              "custom-request": _unref(customRequest),
                              "auto-upload": false,
                              accept: "image",
                              "max-count": 6
                            }, {
                              default: _withCtx(() => [
                                (!_unref(machineFileList) || _unref(machineFileList).length < 6)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                      _createVNode(_component_plus_outlined),
                                      _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(langObj)['上传']), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["file-list", "custom-request"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { class: "w-full" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", {
                        onClick: submitMachine,
                        class: "btn-default py-1 px-6 cursor-pointer btn-submit"
                      }, _toDisplayString(_unref(langObj)['提交']), 1),
                      _createVNode(_component_a_spin, { spinning: _unref(addLoading) }, {
                        default: _withCtx(() => [
                          (_unref(isShowNext) && !_unref(machineForm).id)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onClick: addMachineNew,
                                class: "ml-8 btn-default py-1 px-6 cursor-pointer btn-submit"
                              }, _toDisplayString(_unref(getLanguage)('下一步')), 1))
                            : _createCommentVNode("", true),
                          (_unref(isShowNext) && _unref(machineForm).id)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                onClick: addMachineNewNewtEdit,
                                class: "ml-8 btn-default py-1 px-6 cursor-pointer btn-submit"
                              }, _toDisplayString(_unref(getLanguage)('下一步')), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["spinning"])
                    ]),
                    (_unref(isShowNext))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_47, _toDisplayString(_unref(getLanguage)("请点击'下一步'为设备配置参数，配置完成后系统才能生成对应的健康分数！若为收集到设备提资信息，请先点击'保存'!")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "z-index"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleAddMachineNew2),
      "onUpdate:visible": _cache[52] || (_cache[52] = ($event: any) => (_isRef(visibleAddMachineNew2) ? (visibleAddMachineNew2).value = $event : visibleAddMachineNew2 = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1500px",
      maskClosable: false,
      zIndex: _unref(editRindex) + 1,
      destroyOnClose: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, [
            _cache[76] || (_cache[76] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[51] || (_cache[51] = ($event: any) => (_isRef(visibleAddMachineNew2) //@ts-ignore
 ? visibleAddMachineNew2.value = false : visibleAddMachineNew2 = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_50, _toDisplayString(_unref(titleEdit) ? _unref(langObj)[_unref(titleEdit)] : _unref(langObj)["新建设备"]), 1),
          _createVNode(AddMachineComponent, {
            isEdit: _unref(isEdit),
            dropdownClassName: _unref(dropdownClassName),
            machineFormEdit: _unref(machineForm),
            fileList: _unref(machineFileList),
            title: _unref(titleEdit),
            onClose: close,
            onResetData: resetData
          }, null, 8, ["isEdit", "dropdownClassName", "machineFormEdit", "fileList", "title"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "zIndex"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleAddMachineNew),
      "onUpdate:visible": _cache[60] || (_cache[60] = ($event: any) => (_isRef(visibleAddMachineNew) ? (visibleAddMachineNew).value = $event : visibleAddMachineNew = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1300px",
      maskClosable: false,
      zIndex: _unref(editRindex) + 1
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _cache[77] || (_cache[77] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[53] || (_cache[53] = ($event: any) => (_isRef(visibleAddMachineNew) //@ts-ignore
 ? visibleAddMachineNew.value = false : visibleAddMachineNew = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(langObj)["新建设备"]), 1),
          _createVNode(_component_a_form, { model: _unref(addMachineForm) }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "machineTypeId",
                        label: _unref(getLanguage)('设备类型'),
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_cascader, {
                            style: {"max-width":"236px"},
                            value: _unref(addMachineForm).type,
                            "onUpdate:value": _cache[54] || (_cache[54] = ($event: any) => ((_unref(addMachineForm).type) = $event)),
                            options: _unref(machineTypeList),
                            placeholder: _unref(langObj)['请选择设备类型'],
                            onChange: selectMT,
                            dropdownClassName: _unref(dropdownClassName)
                          }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['设备类型缩写'],
                        name: "machineType"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _unref(addMachineForm).machineType,
                            "onUpdate:value": _cache[55] || (_cache[55] = ($event: any) => ((_unref(addMachineForm).machineType) = $event)),
                            placeholder: _unref(langObj)['请输入设备类型缩写']
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备是否频繁启停'),
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio_group, {
                            value: _unref(addMachineForm).machineTypeDetails.isFrequentStart,
                            "onUpdate:value": _cache[56] || (_cache[56] = ($event: any) => ((_unref(addMachineForm).machineTypeDetails.isFrequentStart) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio, { value: true }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(getLanguage)('是')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_radio, { value: false }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(getLanguage)('否')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('设备3D图')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(addMachineForm).machineTypeDetails.pictureUrl,
                            "onUpdate:value": _cache[57] || (_cache[57] = ($event: any) => ((_unref(addMachineForm).machineTypeDetails.pictureUrl) = $event)),
                            dropdownClassName: _unref(dropdownClassName)
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pic3Ds), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.url
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_54, [
                                      _createVNode(_component_a_image, {
                                        height: "30px",
                                        src: item.url
                                      }, null, 8, ["src"]),
                                      _createElementVNode("div", _hoisted_55, _toDisplayString(item.remark), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "dropdownClassName"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('选择测点'),
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_56, [
                            _createVNode(_component_a_tree_select, {
                              value: _unref(selectSensorList),
                              "onUpdate:value": _cache[58] || (_cache[58] = ($event: any) => (_isRef(selectSensorList) ? (selectSensorList).value = $event : selectSensorList = $event)),
                              style: {"width":"100%"},
                              "tree-checkable": "",
                              "tree-data": _unref(sensorTreeData),
                              dropdownClassName: _unref(dropdownClassName)
                            }, null, 8, ["value", "tree-data", "dropdownClassName"]),
                            _createVNode(_component_a_button, { onClick: selectSensor }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getLanguage)('确认选择')), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(addMachineForm).machineTypeDetails.parts, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mt-4 part",
              key: index
            }, [
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('部件')) + ":", 1),
                _createElementVNode("div", null, _toDisplayString(item?.partName), 1),
                _createElementVNode("div", _hoisted_58, "(" + _toDisplayString(_unref(getLanguage)('请完善部件信息')) + ")", 1)
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", _hoisted_62, [
                      _createTextVNode(_toDisplayString(_unref(getLanguage)('额定转速')) + "(rpm)", 1),
                      _cache[78] || (_cache[78] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createVNode(_component_a_input_number, {
                        style: {"width":"200px"},
                        value: item.params.Rated_speed,
                        "onUpdate:value": ($event: any) => ((item.params.Rated_speed) = $event),
                        controls: false
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_64, [
                    _createElementVNode("div", _hoisted_65, [
                      _createTextVNode(_toDisplayString(_unref(getLanguage)('转速范围')), 1),
                      _cache[79] || (_cache[79] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                      _createVNode(_component_a_input_number, {
                        style: {"width":"90px"},
                        value: item.params.startFre,
                        "onUpdate:value": ($event: any) => ((item.params.startFre) = $event),
                        controls: false
                      }, null, 8, ["value", "onUpdate:value"]),
                      _cache[80] || (_cache[80] = _createElementVNode("div", { class: "mx-1" }, "——", -1)),
                      _createVNode(_component_a_input_number, {
                        style: {"width":"90px"},
                        value: item.params.stopFre,
                        "onUpdate:value": ($event: any) => ((item.params.stopFre) = $event),
                        controls: false
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  (!item.disable)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.segments.columns, (item2, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index2,
                          style: {"width":"200px"},
                          class: "ml-4"
                        }, [
                          _createElementVNode("div", _hoisted_67, [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)(item2)), 1),
                            _cache[81] || (_cache[81] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                          ]),
                          _createElementVNode("div", _hoisted_68, [
                            _createVNode(_component_a_select, {
                              value: item.segments['data' + index2],
                              "onUpdate:value": ($event: any) => ((item.segments['data' + index2]) = $event),
                              style: {"width":"200px"},
                              onChange: ($event: any) => (selectPartParmar(item.segments, index2))
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.segments['columnsData' + index2], (s, sIndex) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: sIndex,
                                    value: s
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(s), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["value", "onUpdate:value", "onChange"])
                          ])
                        ]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.structures, (structure, index2) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "mt-4",
                    key: index2
                  }, [
                    _createElementVNode("div", _hoisted_69, [
                      _cache[82] || (_cache[82] = _createElementVNode("div", null, "—", -1)),
                      _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(getLanguage)('结构')) + ": ", 1),
                      _createElementVNode("div", _hoisted_71, _toDisplayString(_unref(getLanguage)(structure.machineStructureName)), 1),
                      (structure.sensorPlace)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_72, "——"))
                        : _createCommentVNode("", true),
                      (structure.sensorPlace)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_73, _toDisplayString(_unref(getLanguage)(structure.sensorPlace)), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_74, "(" + _toDisplayString(_unref(getLanguage)('请完善结构信息，若该结构信息不全，将不会显示该测点的健康指标')) + ")", 1)
                    ]),
                    _createElementVNode("div", _hoisted_75, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(structure.parmarList, (parmar, index3) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index3,
                          style: {"width":"200px"},
                          class: _normalizeClass({ 'ml-4': index3 !== 0 })
                        }, [
                          _createElementVNode("div", _hoisted_76, _toDisplayString(_unref(getLanguage)(parmar.label)), 1),
                          _createElementVNode("div", _hoisted_77, [
                            (parmar.type === 'select')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                  _createVNode(_component_a_select, {
                                    value: parmar.value,
                                    "onUpdate:value": ($event: any) => ((parmar.value) = $event),
                                    style: {"width":"200px"},
                                    options: parmar.option,
                                    showSearch: true
                                  }, null, 8, ["value", "onUpdate:value", "options"])
                                ]))
                              : (parmar.type === 'string')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                    _createVNode(_component_a_input, {
                                      style: {"width":"200px"},
                                      value: parmar.value,
                                      "onUpdate:value": ($event: any) => ((parmar.value) = $event)
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_80, [
                                    _createVNode(_component_a_input_number, {
                                      style: {"width":"200px"},
                                      value: parmar.value,
                                      "onUpdate:value": ($event: any) => ((parmar.value) = $event),
                                      controls: false
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]))
                          ])
                        ], 2))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          }), 128)),
          _createVNode(_component_a_form_item, { class: "w-full" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_81, [
                _createElementVNode("div", {
                  onClick: _cache[59] || (_cache[59] = ($event: any) => (_isRef(isSubmitV) //@ts-ignore
 ? isSubmitV.value = false : isSubmitV = false, submitMachineNew())),
                  class: "btn-default py-1 px-6 cursor-pointer btn-submit"
                }, _toDisplayString(_unref(langObj)['提交']), 1)
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible", "zIndex"]),
    _createVNode(_component_a_modal, {
      class: "whiteBg",
      visible: _unref(visibleWarning),
      "onUpdate:visible": _cache[67] || (_cache[67] = ($event: any) => (_isRef(visibleWarning) ? (visibleWarning).value = $event : visibleWarning = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1400px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_82, [
          _createElementVNode("div", _hoisted_83, [
            _cache[83] || (_cache[83] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[61] || (_cache[61] = ($event: any) => (_isRef(visibleWarning) //@ts-ignore
 ? visibleWarning.value = false : visibleWarning = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_84, _toDisplayString(_unref(langObj)['报警记录']), 1),
          _createElementVNode("div", _hoisted_85, [
            _createElementVNode("div", _hoisted_86, [
              _createVNode(_component_a_form, {
                model: _unref(searchModel),
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['报警时间'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        value: _unref(warnSearchModel).date,
                        "onUpdate:value": _cache[62] || (_cache[62] = ($event: any) => ((_unref(warnSearchModel).date) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['报警类型'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(warnSearchModel).type,
                        "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_unref(warnSearchModel).type) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择报警类型']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(warnTypeList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['严重程度'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(warnSearchModel).condition,
                        "onUpdate:value": _cache[64] || (_cache[64] = ($event: any) => ((_unref(warnSearchModel).condition) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择严重程度']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_87, [
                        _createElementVNode("div", {
                          onClick: resetWarn,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                        }, _toDisplayString(_unref(langObj)['重置']), 1),
                        _createElementVNode("div", {
                          onClick: _cache[65] || (_cache[65] = ($event: any) => ((_unref(warnSearchModel).skip = 1), getWarningList())),
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                          style: {"background-color":"#072499"}
                        }, _toDisplayString(_unref(langObj)['查询']), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createElementVNode("div", _hoisted_88, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(warningColumns),
                "data-source": _unref(warningList),
                pagination: warmPagination.value,
                onChange: changeWarnTable
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                        (text === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_90, _cache[84] || (_cache[84] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_0,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_91, _cache[85] || (_cache[85] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_1,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_92, _cache[86] || (_cache[86] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_2,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_93, _cache[87] || (_cache[87] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_3,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_94, _toDisplayString(_unref(langObj)[_unref(warnTypeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                      background: _unref(conditionList).find((p) => p.value === text)
                        ?.color,
                    }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_96, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_97))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (record.type === 1 && record.reportIds?.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (viewReport(record)),
                              class: "theme-table-link"
                            }, _toDisplayString(_unref(langObj)['诊断报告']), 9, _hoisted_98))
                          : _createCommentVNode("", true),
                        (record.type === 2 && record.reportIds?.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              onClick: ($event: any) => (viewReport(record)),
                              class: "theme-table-link"
                            }, _toDisplayString(_unref(langObj)['检修报告']), 9, _hoisted_99))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source", "pagination"])
            ])
          ]),
          _createElementVNode("div", _hoisted_100, [
            _createElementVNode("div", {
              onClick: _cache[66] || (_cache[66] = ($event: any) => (_isRef(visibleWarning) //@ts-ignore
 ? visibleWarning.value = false : visibleWarning = false)),
              class: "btn-default py-1 px-6"
            }, _toDisplayString(_unref(langObj)['关闭']), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleRepair),
      "onUpdate:visible": _cache[70] || (_cache[70] = ($event: any) => (_isRef(visibleRepair) ? (visibleRepair).value = $event : visibleRepair = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "800px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_101, [
          _createElementVNode("div", _hoisted_102, [
            _cache[88] || (_cache[88] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[68] || (_cache[68] = ($event: any) => (_isRef(visibleRepair) //@ts-ignore
 ? visibleRepair.value = false : visibleRepair = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_103, _toDisplayString(_unref(langObj)['检修记录']), 1),
          _createElementVNode("div", _hoisted_104, _toDisplayString(_unref(langObj)['故障信息']), 1),
          _createElementVNode("div", _hoisted_105, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: _unref(repairColumns),
              "data-source": _unref(repairForm).list
            }, {
              bodyCell: _withCtx(({ column, text }) => [
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_106, _toDisplayString(_unref(langObj)['编辑']), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["columns", "data-source"])
          ]),
          _createElementVNode("div", _hoisted_107, _toDisplayString(_unref(langObj)['故障详情']), 1),
          _createVNode(_component_a_descriptions, {
            column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 },
            labelStyle: { color: '#fff' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, {
                labelStyle: { color: '#fff' },
                label: "开始时间"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).begin), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "结束时间" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).end), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "设备名称" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).machineName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "设备区域" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).groupName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "检修人员" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).maintainPerson), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "现场设备状态" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(repairForm).accuracy), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", null, _toDisplayString(_unref(langObj)['检修内容']) + ":", 1),
          _createElementVNode("div", null, _toDisplayString(_unref(repairForm).maintainMessage), 1),
          _createElementVNode("div", _hoisted_108, [
            _createElementVNode("div", null, _toDisplayString(_unref(langObj)['检修照片']) + ":", 1)
          ]),
          _createElementVNode("div", _hoisted_109, [
            _createElementVNode("div", {
              onClick: _cache[69] || (_cache[69] = ($event: any) => (_isRef(visibleRepair) //@ts-ignore
 ? visibleRepair.value = false : visibleRepair = false)),
              class: "btn-default py-1 px-6"
            }, _toDisplayString(_unref(langObj)['关闭']), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_drawer, {
      visible: _unref(visibleReport),
      "onUpdate:visible": _cache[71] || (_cache[71] = ($event: any) => (_isRef(visibleReport) ? (visibleReport).value = $event : visibleReport = $event)),
      class: "custom-class",
      size: "large",
      closable: false,
      placement: "right"
    }, {
      title: _withCtx(() => _cache[89] || (_cache[89] = [])),
      default: _withCtx(() => [
        _createVNode(DiagnosticReportDetails, {
          ref_key: "mrDref",
          ref: mrDref,
          reportId: _unref(reportId),
          onCancel: cancel
        }, null, 8, ["reportId"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: previewVisible.value,
      title: "图片",
      footer: null,
      onCancel: handleCancel,
      zIndex: 1001
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "example",
          style: {"width":"100%"},
          src: previewImage.value
        }, null, 8, _hoisted_110)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleImportMachine),
      "onUpdate:visible": _cache[72] || (_cache[72] = ($event: any) => (_isRef(visibleImportMachine) ? (visibleImportMachine).value = $event : visibleImportMachine = $event)),
      title: _unref(getLanguage)('导入设备'),
      footer: null,
      style: {"top":"30%"},
      width: "1500px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createVNode(ImportMachine, {
          "machine-types": _unref(machineTypeListAll),
          onSuccess: onImportMachineSuccess
        }, null, 8, ["machine-types"])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    (_unref(selectMachineVisible))
      ? (_openBlock(), _createBlock(SelectMachineComponent, {
          key: 0,
          onSelectMachineSen: selectMachineSen,
          visible: _unref(selectMachineVisible),
          editRindex2: _unref(editRindex2)
        }, null, 8, ["visible", "editRindex2"]))
      : _createCommentVNode("", true),
    (_unref(selectSensorVisible))
      ? (_openBlock(), _createBlock(SelectSensorComponent, {
          key: 1,
          onSelectSensorSen: selectSensorSen,
          machineId: _unref(sensorForm).machineId,
          visible: _unref(selectSensorVisible),
          editRindex2: _unref(editRindex2)
        }, null, 8, ["machineId", "visible", "editRindex2"]))
      : _createCommentVNode("", true)
  ]))
}
}

})