<template>
  <div style="margin:auto;padding:0;position:relative;z-index: 0;" :style="{ width: props.width + 'px', height: props.height + 'px' }">
    <TresCanvas v-if="showCanvas">
      <!-- Your scene here -->
      <TresPerspectiveCamera :position="[0, 20, 50]" :near="0.1" :far="10000" />
      <CameraControls />
      <TresAmbientLight color="#ffffff" :intensity="1" />
      <TresDirectionalLight color="#ffffff" :position="[300, 300, 250]" :intensity="6" />

      <TresGroup :position="[0, -1, 0]">

        <Suspense>
          <d3d-model :model-path="modelPath" :camera-fov="14" :model-size="modelSize"></d3d-model>
        </Suspense>

        <template v-for="(point, index) in markPoints" :key="index">
          <TresMesh>
            <Html v-bind="htmlState" :position="[point.x, point.y, point.z]">
            <div class="d3d-mark-line text-white" :class="{ 'd3d-mark-line-left': point.linePosition === 'left', 'd3d-mark-line-right': point.linePosition === 'right' }"></div>
            <div class="d3d-mark-label" :class="{ 'd3d-mark-label-left': point.linePosition === 'left', 'd3d-mark-label-right': point.linePosition === 'right' }">
              <div class="d3_bq" v-if="point.bind" :style="{
                'border-color': getColor(point.condition),
                'box-shadow': getShadow(point.condition),
              }" style="width: 225px; height: 100px">
                <div class="w-full" style="padding: 5px 10px">
                  <div class="w-full flex">
                    <div>{{ point.sensorPlace }}</div>
                    <div class="flex-1"></div>
                    <div :style="{
                      'background-color': getColor2(point.condition),
                    }" style="width: 15px; height: 15px; border-radius: 50%"></div>
                  </div>
                  <div class="w-full flex items-center">
                    <div style="width: 35%">
                      <div>{{ point.hz }}m/s²</div>
                      <div style="font-size: 12px; opacity: 0.6">
                        {{ langObj['振动加速度'] }}
                      </div>
                    </div>
                    <div style="width: 35%">
                      <div>{{ point.sz }}mm/s</div>
                      <div style="font-size: 12px; opacity: 0.6">
                        {{ langObj['振动速度'] }}
                      </div>
                    </div>
                    <div style="width: 30%; word-wrap:break-word; ">
                      <div>{{ point.temp }}℃</div>
                      <div style="font-size: 12px; opacity: 0.6">{{ langObj['温度'] }}</div>
                    </div>
                  </div>
                  <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="mr-2" style="font-size: 12px; opacity: 0.6">
                      {{ point.date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </Html>
          </TresMesh>
        </template>

      </TresGroup>

    </TresCanvas>
  </div>
</template>

<script lang="ts" setup async>
import { onMounted, reactive, ref, withDefaults, defineProps, watch } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import { v4 as uuidv4 } from "uuid";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

import { TresCanvas } from '@tresjs/core'
import { CameraControls, Html } from '@tresjs/cientos'
import { HTMLProps } from '@tresjs/cientos/dist/core/misc/html/HTML.vue'

import d3dModel from './d3d-model.vue'

interface Props {
  machineId: string,
  config3d: any,
  showTag?: boolean,
  width?: number,
  height?: number
}

const props = withDefaults(defineProps<Props>(), {
  machineId: '',
  config3d: { d3dMarks: [] },
  showTag: true,
  width: 1200,
  height: 600
})

onMounted(async () => {
  markPoints.value = props.config3d?.d3dMarks || []
  modelPath.value = props.config3d?.picture || ""
})

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: "可用",
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: "警戒",
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: "故障",
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const htmlState = reactive<HTMLProps>({
  wrapperClass: 'd3d-mark-box',
  as: 'div',
  sprite: true,
  transform: true,
  distanceFactor: 5,
  center: true,
  zIndexRange: [999, 0], // 1000是modal的默认zIndex，这里超过1000会遮挡modal
})

let modelPath = ref<string>("")
let modelSize = ref<number>(1.5)
let showCanvas = ref<boolean>(false)

//type MarkLinePosition = "center" | "left" | "right"

interface MarkPointType {
  id: number
  x: number
  y: number
  z: number
  linePosition: string
  bind: string // sensorId
  label: string
  [index: string]: any
}

let markPoints = ref<MarkPointType[]>([]);

const load3d = async () => {
  if (props.config3d.d3dMarks?.length) {
    await get3DData()
  }
  if (props.config3d.d3d?.modelSize) {
    modelSize.value = props.config3d.d3d.modelSize
  }
    showCanvas.value = true
}

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: props.machineId,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    markPoints.value.map((d: any) => {
      if (d.bind) {
        let sensorId = d.bind;
        let sensorInfo = result.data.sensors.find(
          (p: any) => p.id === sensorId
        );
        if (sensorInfo) {
          d.sensorPlace = sensorInfo.sensorPlace;
        } else {
          d.sensorPlace = "";
        }

        let info = result.data.quotas.find(
          (p: any) => p.sensorId === sensorId
        );
        if (info) {
          if (info.values.length) {
            let sz = info.values.find((p: any) => p.type === "速度Z");
            d.sz = sz ? sz.value.toFixed(2) : "0";
            let hz = info.values.find((p: any) => p.type === "高频加速度");
            d.hz = hz ? hz.value.toFixed(2) : "0";
            let temp = info.values.find((p: any) => p.type === "温度");
            d.temp = temp ? temp.value.toFixed(2) : "0";
          }
          d.date = info.date
        }
        let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
          (p: any) => p.id === sensorId
        );
        if (stableInfo) {
          delete stableInfo.id;
          let list = Object.values(stableInfo).sort();
          let score: any = 100;
          if (list && list.length) {
            score = list[0];
          }

          if (score >= 80 && score <= 100) {
            d.condition = 0;
          }
          if (score >= 60 && score < 80) {
            d.condition = 1;
          }
          if (score >= 30 && score < 60) {
            d.condition = 3;
          }
          if (score >= 0 && score < 30) {
            d.condition = 6;
          }
        }
      }
    });
  }
};

watch(() => props.machineId, async () => {
  if (props.machineId) {
    await load3d()
  } else {
    markPoints.value = []
    modelPath.value = ""
  }
}, { immediate: true })

</script>

<style lang="less">
.d3d-mark-box>div:first-of-type {
  position: relative !important;
}

.d3d-mark-box {
  user-select: none;
  pointer-events: none !important;

  #inner {
    user-select: none;
    pointer-events: none !important;
  }

  .d3d-mark-line {
    width: 15px;
    height: 15px;
    background-color: #e5e7eb;
    border-radius: 50%;
    border: 5px solid #072499;

    /* 添加斜线效果 */
    &::before {
      content: ' ';
      position: relative;
      top: -151px;
      left: -148px;
      width: 300px;
      height: 2px;
      display: block;
      transform: rotate(90deg);
      background-color: #e5e7eb;
    }
  }

  .d3d-mark-line-left::before {
    top: -155px;
    left: -446px;
    width: 500px;
    transform: rotate(218deg);
  }

  .d3d-mark-line-right::before {
    top: -203px;
    left: -91px;
    width: 500px;
    transform: rotate(128deg);
  }

  .d3d-mark-label {
    position: relative;
    top: -400px;
    left: -150px;
    font-size: 16px;
    border: 0 transparent;
  }

  .d3d-mark-label-left {
    top: -329px !important;
    left: -560px !important;
  }

  .d3d-mark-label-right {
    top: -420px !important;
    left: 250px !important;
  }
}
</style>