<template>
  <div
      class="w-full theme-common"
      style="
      padding: 16px 16px;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      padding-bottom: 30px;
      background-color: #000a32;
    "
  >
    <div class="w-full h-full flex ">
      <!-- 右侧内容 -->
      <div class="flex-1 flex ml-4" style="color: #fff">
        <!-- 设备内容 -->
        <div class="flex-1 whiteBg">

          <div class="w-full flex px-4 mt-2"
               style="border: 1px solid #4a5983; background: #07249933">
            <div style="font-size: 18px"
                 class="py-3 cursor-pointer"
                 @click="selectMenu(1)"
                 :style="{ borderBottom: menuSelect === 1 ? '2px solid #00FFF4' : ''}">
              {{ langObj["配置模型"] }}配置模型
            </div>

            <div style="font-size: 18px"
                 class="py-3 ml-12 cursor-pointer"
                 @click="selectMenu(2)"
                 :style="{ borderBottom: menuSelect === 2 ? '2px solid #00FFF4' : ''}">
              {{ langObj["模型管理"] }}模型管理
            </div>
          </div>
          <div v-show="menuSelect == 1">
            <div class="w-full flex mt-2">
              <!-- 左侧设备列表 -->
              <div style="min-width: 250px">
                <div style="width: 250px;height: calc(100vh - 195px );" :style="{ top: scrollTop }">
                  <machineListLeftComponent
                      @selectSensor="selectSensor"
                      :type="2"
                  >
                  </machineListLeftComponent>
                </div>
              </div>

              <div class="flex-1">
                <div class="flex-1 content ml-2">
                  <div class="w-full flex">
                    <div class="flex-1"></div>
                    <span>健康指标：</span>
                    <a-radio-group
                        name="faultRadioGroup"
                        v-model:value="selectedFaultNameValue"
                        @change="onSelectFaultName">
                      <a-radio
                          :value="item.column"
                          :key="index"
                          v-for="(item, index) in faultNameExpects">{{ langObj[item.title] }}
                      </a-radio>
                    </a-radio-group>
                    <div class="flex-1"></div>
                  </div>

                  <div class="w-full flex mt-2">
                    <div class="flex-1"></div>
                    <span>振动指标：</span>
                    <a-radio-group
                        name="faultExpectRadioGroup"
                        v-model:value="selectedFaultNameValue"
                        @change="onSelectFaultName">
                      <a-radio
                          :value="item.column"
                          :key="index"
                          v-for="(item, index) in faultNames">{{ langObj[item.title] }}
                      </a-radio>
                    </a-radio-group>
                    <div class="flex-1"></div>
                  </div>

                  <div class="flex items-center mt-4">
                    <div class="flex-1"></div>
                    <div class="flex ml-2">
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '1' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '1' ? '#072498' : '',
                        }"
                          style="border-radius: 5px 0 0 5px"
                          @click="changeSimpleDateType('1')"
                      >
                        {{ langObj["近一天"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '2' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '2' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('2')"
                      >
                        {{ langObj["近一周"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '3' }"
                          :style="{
                          background:
                            simpleSearchModel.type == '3' ? '#072498' : '',
                        }"
                          @click="changeSimpleDateType('3')"
                      >
                        {{ langObj["近一月"] }}
                      </div>
                      <div
                          class="btn2 px-3 py-1"
                          :style="{
                          background:
                            simpleSearchModel.type == '0' ? '#072498' : '',
                        }"
                          :class="{ 'text-white theme-datesetected': simpleSearchModel.type == '0' }"
                          @click="simpleSearchModel.type = '0'"
                          style="border-radius: 0 5px 5px 0"
                      >
                        {{ langObj["自定义"] }}
                      </div>
                    </div>

                    <div class="ml-2" v-if="simpleSearchModel.type == '0'">
                      <a-range-picker
                          v-model:value="simpleSearchModel.date"
                          :disabled-date="disabledDate"
                          @calendarChange="onCalendarChange"
                      />
                    </div>

                    <div @click="quotaSearch" class="btn3 ml-2">
                      {{ langObj["查询"] }}
                    </div>

                    <div class="flex-1"></div>
                  </div>
                  <div class="w-full flex items-center mt-4">
                    <div id="chart1" style="width: 100%; height: 322px"></div>
                  </div>
                </div>

                <div class="mt-4 content ml-2">
                  <div class="w-full flex">
                    <div class="flex-1">

                      <a-form name="basic" autocomplete="off">
                        <a-row :gutter="24" class="text-white">
                          <a-col :span="4"></a-col>
                          <a-col :span="4">
                            <span>整体修改：</span>
                          </a-col>
                          <a-col :span="6">
                            <a-select
                                style="width: 90px"
                                v-model:value="form.wholeOffsetScoreOperator"
                                :placeholder="langObj['请选择']">
                              <a-select-option value="plus">整体加</a-select-option>
                              <a-select-option value="minus">整体减</a-select-option>
                            </a-select>
                          </a-col>
                          <a-col :span="5">
                            <a-form-item name="wholeOffsetScore">
                              <a-input
                                  v-model:value="form.wholeOffsetScore"
                                  type="number"
                                  min="0"/>
                            </a-form-item>
                          </a-col>
                          <a-col :span="5">
                            <a-button type="primary" :disabled="!allowAddWhole" @click="onClickAddWholeOffsetScore"
                                      class="btn3 ml-2">
                              {{ langObj["计算"] }}计算
                            </a-button>
                          </a-col>
                        </a-row>
                        <a-row :gutter="24" class="text-white">
                          <a-col :span="4"></a-col>
                          <a-col :span="4">
                            <span>单点修改：</span>
                          </a-col>
                          <a-col :span="6">
                            <a-select
                                style="width: 90px"
                                v-model:value="form.singleOffsetScoreOperator"
                                :placeholder="langObj['请选择']">
                              <a-select-option value="plus">单点加</a-select-option>
                              <a-select-option value="minus">单点减</a-select-option>
                            </a-select>
                          </a-col>
                          <a-col :span="5">
                            <a-form-item name="singleOffsetScore">
                              <a-input
                                  v-model:value="form.singleOffsetScore"
                                  type="number"
                                  min="0"/>
                            </a-form-item>
                          </a-col>
                          <a-col :span="5">
                            <a-button type="primary" :disabled="!allowAddSingle"
                                      @click="onClickAddSingleOffsetScore" class="btn3 ml-2">
                              {{ langObj["计算"] }}计算
                            </a-button>
                          </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mb-4">
                          <a-col :span="8"></a-col>
                          <a-col :span="16">
                            <a-button type="primary" class="btn3" :loading="isTraining" @click="onClickTrainModel">
                              {{ langObj["生成分数"] }}生成分数
                            </a-button>
                            <a-button type="primary" :loading="isApplyModelLoading" class="btn3 ms-2"
                                      @click="onClickApplyModel">{{ langObj["确认修改"] }}确认修改
                            </a-button>
                            <a-button type="primary" class="btn3 ms-2" :loading="isSaveModelLoading"
                                      @click="onClickSaveModel">
                              保存为模板
                            </a-button>
                          </a-col>

                        </a-row>
                      </a-form>
                    </div>
                    <div class="flex-1">
                      <a-radio-group
                          name="trainedParamGroupRadio"
                          v-model:value="selectedParamGroupIndex">
                        <a-row :gutter="24" class="mb-4">
                          <a-col :span="24">
                            <a-radio
                                style="margin-bottom:0.5rem"
                                :value="paramGroup.id"
                                :key="idxGroup"
                                v-for="(paramGroup, idxGroup) in trainedParamGroups">
                              <a-tag color="#2db7f5" v-for="label in paramGroup.paramLabels">{{ label }}</a-tag>
                            </a-radio>
                          </a-col>
                        </a-row>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 雷达图 -->
          </div>

          <div v-show="menuSelect == 2" class="mt-4">
            <a-row :gutter="24">
              <a-col :span="12">
                <div class="box w-full" style="width: 100%">
                  <div>
                    <a-form
                        :model="quotaSearch"
                        name="horizontal_login"
                        layout="inline"
                        autocomplete="off"
                    >
                      <a-form-item :label="langObj['时间']" name="date">
                        <a-range-picker style="width: 230px"
                                        v-model:value="tempModelQuery.date"
                                        :disabled-date="disabledDate"
                                        @calendarChange="onCalendarChange"
                        />
                      </a-form-item>

                      <a-form-item :label="langObj['设备类型']" name="machineType">
                        <a-select style="width: 250px"
                                  v-model:value="tempModelQuery.machineTypeId"
                                  allowClear
                                  :placeholder="langObj['请选择']"
                        >
                          <a-select-option
                              v-for="(item, index) in machineTypes"
                              :key="index"
                              :value="item.id"
                          >
                            {{
                              item.FirstCategory +
                              "/" +
                              item.SecondCategory +
                              "/" +
                              item.ThirdCategory
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>

                      <a-form-item :label="langObj['指标']" name="faultName">
                        <a-select style="width: 200px"
                                  v-model:value="tempModelQuery.faultName"
                                  allowClear
                                  :placeholder="langObj['请选择']"
                        >
                          <a-select-option
                              v-for="(item, index) in allFaultNames"
                              :key="index"
                              :value="item.column"
                          >
                            {{ langObj[item.title] }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>

                      <a-form-item>
                        <div class="flex items-center">
                          <div
                              @click="loadVibrationModelList"
                              class="btn3 px-5 py-1 fff cursor-pointer ml-4"
                              style="background-color: #072499;color:white"
                          >
                            {{ langObj["查询"] }}
                          </div>
                        </div>
                      </a-form-item>
                    </a-form>
                  </div>
                  <a-table
                      style="width: 100%;height: calc(100% - 70px);overflow-y: auto;"
                      size="small"
                      bordered
                      :row-class-name="getRowClassName"
                      :columns="tempColumns"
                      :data-source="data"
                      :pagination="false"
                  >
                    <template #bodyCell="{ column, text, record }">
                      <template v-if="column.dataIndex === 'act'">
                        <a
                            style="color: #00fff4 !important; margin-right: 5px"
                            @click="loadBingInfos(record)"
                        >
                          {{ langObj["查看"] }}</a
                        >

                        <a-popconfirm
                            :title="langObj['确定删除此条数据么'] + '?'"
                            :ok-text="langObj['确定']"
                            :cancel-text="langObj['取消']"
                            @confirm="deleteVibrationModel(record)"
                        >
                          <a class="theme-table-link">
                            {{ langObj["删除"] }}</a
                          >
                        </a-popconfirm>
                      </template>
                    </template>
                  </a-table>
                </div>
              </a-col
              >
              <a-col :span="6">
                <div class="w-full items-center box">
                  <div class="flex-1 text-center">未使用模板的设备</div>
                  <div
                      style="height: calc(100% - 10px);"
                      class="mt-2 overflow-y-auto"
                  >
                    <a-tree
                        v-model:checkedKeys="unUseSensorIds"
                        checkable
                        :tree-data="unUseSensors"
                        v-if="unUseSensors.length"
                        @check="selectUnUseSensor"
                        defaultExpandAll
                    >
                      <template #title="{ title, key }">
                        {{ title }}
                      </template>
                    </a-tree>
                  </div>
                  <div class="flex-1 text-center mt-2">
                    <a-button type="primary" class="btn3" @click="submitUnUseModel"
                    >使用模板
                    </a-button
                    >
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div class="w-full items-center box">
                  <div class="flex-1 text-center">已使用模板的设备</div>

                  <div
                      style="height: calc(100% - 10px);"
                      class="mt-2 overflow-y-auto"
                  >
                    <a-tree
                        v-model:checkedKeys="useSensorIds"
                        checkable
                        :tree-data="useSensors"
                        @check="selectUseSensor"
                        v-if="useSensors.length"
                        defaultExpandAll
                    >
                      <template #title="{ title, key }">
                        {{ title }}
                      </template>
                    </a-tree>
                  </div>
                  <div class="flex-1 text-center mt-2">
                    <a-button type="primary" class="btn3" @click="submitReplaceUseModel"
                    >替换模板
                    </a-button
                    >
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import request from "../../common/request";
import { transformDate2, } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const SCORE_OPERATOR = { plus: "plus", minus: "minus" };

let sensorInfo: any = ref({});

let form: any = ref({
  wholeOffsetScoreOperator: SCORE_OPERATOR.plus,
  wholeOffsetScore: null,
  singleOffsetScoreOperator: SCORE_OPERATOR.plus,
  singleOffsetScore: null,

  machineType: "",
  sensorIds: [],
});

let unUseSensorIds: any = ref([]);
let useSensorIds: any = ref([]);

//let unUseSensorIdsOld: any = ref([]);
let useSensorIdsOld: any = ref([]);

let unUseSensors: any = ref([]);
let useSensors: any = ref([]);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

let selectSimpleModel: any = ref({});

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadFaultNames(ev);
  }
};

onMounted(() => {
  loadMachineTypes();
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

let selectedChartDataIndex: number | null = null;
let selectedChartDataDate: string = "";
let myChart: any = null;
let xData: string[] = [];
let yData: number[] = [];
let modifiedYData: (number | null)[] = [];
let trainedYData: number[] = [];
const updateChart = (reDraw: boolean = true) => {
  myChart.on('selectchanged', function (params: any) {
    if (params.selected && params.selected.length > 0) {
      //console.log("selectchanged", params.selected[0].dataIndex)
      selectedChartDataIndex = params.selected[0].dataIndex[0] as number;
      selectedChartDataDate = scoreData[selectedChartDataIndex].date;
    } else {
      selectedChartDataIndex = null;
      selectedChartDataDate = "";
    }
    //console.log('chart select changed', params);
  });

  let unselectedIndex = selectedChartDataIndex;
  if (reDraw) {
    selectedChartDataIndex = null;
    selectedChartDataDate = "";
    xData = [];
    yData = [];
    modifiedYData = [];
    trainedYData = [];
    myChart.clear();

    for (let item of scoreData) {
      let score = item[selectedFaultNameValue.value];
      if (score == null || score < 0) continue

      xData.push(item.date);

      if (offsetState.wholeOffsetScore) {
        let modifiedScore = Number(offsetState.wholeOffsetScore) + Number(score);
        modifiedYData.push(modifiedScore);
      } else if (offsetState.singleOffsetScore && offsetState.singleOffsetScore.date === item.date) {
        let modifiedScore = Number(offsetState.singleOffsetScore.offsetScore) + Number(score);
        modifiedYData.push(modifiedScore);
        // select on chart
        selectedChartDataIndex = xData.length - 1;
        selectedChartDataDate = item.date;
      } else {
        modifiedYData.push(null);
      }

      yData.push(score);
    }
  } else {
    myChart.setOption({
      series: [
        {
          name: "分数",
          type: "line",
          data: yData,
          selectedMode: "single",
          select: {
            itemStyle: {
              borderWidth: 1.5,
              borderColor: "cyan",
              borderType: "solid"
            }
          }
        },
        {
          name: "训练分数",
          type: "line",
          data: trainedYData
        }
      ]
    });
  }

  if (selectedParamGroupIndex.value >= 0) {
    let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
    for (let item of paramGroup.scoreArr) {
      if (xData.includes(item.date)) {
        trainedYData.push(item.score);
      }
    }
  }

  let option = {
    tooltip: {
      hideDelay: 100,
      transitionDuration: 0,
      animation: false,
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      data: ['分数', '调整分数', '训练分数'],
      textStyle: {
        color: "#fff"
      }
    },
    grid: {
      right: "5%",
      left: "7%",
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: [
      {
        show: true,
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: xData,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.3,
          },
        },
        axisLabel: {
          color: "#fff",
          fontSize: 16,
          showMaxLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        min: 0,
        max: 100,
        splitNumber: 5,
        splitLine: {
          lineStyle: {
            color: [
              "rgb(117, 116, 116)",
              "rgb(117, 116, 116)",
              "rgb(117, 116, 116)",
              "rgba(250, 60, 88, 1)",
              "rgba(254, 178, 7, 1)",
              "rgb(117, 116, 116)",
            ],
          },
        },
        position: "left",
        offset: "-10",
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
      },
    ],
    series: [{
      name: "分数",
      type: "line",
      data: yData,
      selectedMode: "single",
      select: {
        itemStyle: {
          borderWidth: 1.5,
          borderColor: "cyan",
          borderType: "solid"
        }
      }
    } as { name: string, type: string, data: (number | null)[] },
      {
        name: "训练分数",
        type: "line",
        data: trainedYData,
      }],
  };
  option.series.push({
    name: "训练分数",
    type: "line",
    data: trainedYData,
  })

  option.series.push({
    name: "调整分数",
    type: "line",
    data: modifiedYData,
  })

  option && myChart.setOption(option);

  if (selectedChartDataIndex != null) {
    myChart.dispatchAction({
      type: 'select',
      seriesIndex: 0,
      dataIndex: [selectedChartDataIndex]
    });
  } else if (unselectedIndex != null) {
    myChart.dispatchAction({
      type: 'unselect',
      seriesIndex: 0,
      dataIndex: unselectedIndex
    });
  }
};

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

function getQueryDate(): { begin: string, end: string } {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return { begin: "", end: "" };
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    endDate = date;
  }
  let begin = (transformDate2(beginDate, 1)) || "";
  let end = (transformDate2(endDate, 2)) || "";
  return { begin: begin, end: end };
}

let scoreData: any[] = [];
let quotaSearch = async () => {
  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  clearTrainedData();
  let { begin, end } = getQueryDate();

  // todo: remove this
  //begin = '2024/08/01 00:00:00';
  //end = '2024/08/30 00:00:00';

  let result = await request.get("/api/external/machines/score-full", {
    params: {
      begin, end,
      sensorId: sensorInfo.value.id
    }
  });
  /*
  offsetState = {
    wholeOffsetScore: 0,
    singleOffsetScore: null
  }
   */
  scoreData.length = 0;

  if (result && result.data) {
    scoreData = result.data;
  }
  updateChart();
};

interface SingleOffsetItem {
  date: string;
  offsetScore: number;
  score: number;
}

interface OffsetState {
  wholeOffsetScore: number,
  singleOffsetScore: SingleOffsetItem | null,
}

let offsetState: OffsetState = {
  wholeOffsetScore: 0,
  singleOffsetScore: null
}

let allowAddSingle = ref(true);
let allowAddWhole = ref(true);
const onClickAddSingleOffsetScore = () => {
  if (!selectedChartDataDate || !selectedChartDataIndex) {
    message.warn("请选择单点");
    return;
  }
  if (form.value.singleOffsetScore == null) {
    message.warn("请输入分数");
    return;
  }
  let offsetScore = Number(form.value.singleOffsetScore);
  if (form.value.singleOffsetScoreOperator == SCORE_OPERATOR.minus && offsetScore > 0) {
    offsetScore = -offsetScore;
  } else if (form.value.singleOffsetScoreOperator == SCORE_OPERATOR.plus && offsetScore < 0) {
    offsetScore = -offsetScore;
  }

  offsetState.wholeOffsetScore = 0;
  form.value.wholeOffsetScore = 0;
  offsetState.singleOffsetScore = {
    date: selectedChartDataDate,
    offsetScore: offsetScore,
    score: scoreData[selectedChartDataIndex][selectedFaultNameValue.value]
  };
  offsetState.singleOffsetScore.score = scoreData[selectedChartDataIndex][selectedFaultNameValue.value];
  //console.log("selected x index", selectedChartDataIndex)
  //console.log("origin score", scoreData[selectedChartDataIndex][selectedFaultNameValue.value])
  //console.log('operate', form.value.singleOffsetScoreOperator)
  //console.log("offset score", offsetScore)
  //console.log("updated score", offsetState.singleOffsetScore)
  //offsetState.singleOffsetScore.finalScore = scoreData[selectedChartDataIndex][selectedFaultNameValue.value] + offsetScore;

  updateChart();
}

const onClickAddWholeOffsetScore = () => {
  if (form.value.wholeOffsetScore == null) {
    message.warn("请输入分数");
    return;
  }

  offsetState.wholeOffsetScore = Number(form.value.wholeOffsetScore);
  if (form.value.wholeOffsetScoreOperator == SCORE_OPERATOR.minus) {
    offsetState.wholeOffsetScore = -offsetState.wholeOffsetScore;
  }

  offsetState.singleOffsetScore = null;
  form.value.singleOffsetScore = 0;

  updateChart()
};

interface ParamGroup {
  id: number;
  faultName: string;
  isSingle: boolean,
  dateArr: string[],
  scores: number[],
  params: any,
  paramOptions: { label: string, value: number }[],
  paramLabels: string[],
  scoreArr: { date: string, score: number }[]
}

let trainedParamGroups = ref<ParamGroup[]>([]);
let selectedParamGroupIndex = ref<number>(-1);
watch(selectedParamGroupIndex, (val) => {
  if (val == -1) {
    trainedYData = [];
    return;
  }
  //console.log("selected param group", val)
  let paramGroup = trainedParamGroups.value[val];
  trainedYData.length = 0;
  if (!paramGroup) {
    return;
  }

  for (let i = 0; i < xData.length; i++) {
    let trainedScoreItem = paramGroup.scoreArr.find(t => t.date === xData[i]);
    if (trainedScoreItem) {
      trainedYData.push(trainedScoreItem.score);
    } else {
      trainedYData.push(yData[i]);
    }
  }
  updateChart(false);
  //console.log("trainedYData", trainedYData)
})
const updateTrainedData = (faultName: string, isSingle: boolean, dateArr: string[], resultArr: object[]) => {
  trainedParamGroups.value = [];
  if (resultArr.length == 0) {
    selectedParamGroupIndex.value = -1;
    return;
  }

  for (let i = 0; i < resultArr.length; i++) {
    let item = resultArr[i] as any;
    let scoreRes = item[faultName] as any;

    let paramGroup = {
      id: i,
      isSingle: isSingle,
      dateArr: dateArr,
      faultName: faultName,
      scores: [] as number[],
      paramOptions: [] as { label: string, value: number }[],
      paramLabels: [] as string[],
      scoreArr: [] as { date: string, score: number }[],
      params: null
    }
    if (item.params) {
      for (let paramKey in item.params) {
        let paramValue = Number(item.params[paramKey].toFixed(4));
        item.params[paramKey] = paramValue;
        paramGroup.paramOptions.push({ label: paramKey, value: paramValue })
        paramGroup.paramLabels.push(`${paramKey}:${paramValue}`)
      }
    }
    paramGroup.params = item.params

    paramGroup.scores = scoreRes;
    for (let i = 0; i < dateArr.length; i++) {
      let score = scoreRes[i];
      paramGroup.scoreArr.push({
        date: dateArr[i],
        score: score
      })
    }

    trainedParamGroups.value.push(paramGroup)
  }
  if (trainedParamGroups.value.length > 0) {
    selectedParamGroupIndex.value = 0;
  }
}

const clearTrainedData = () => {
  selectedParamGroupIndex.value = -1;
  trainedParamGroups.value = []
}

let isTraining = ref(false);
const onClickTrainModel = async () => {

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  if (offsetState.wholeOffsetScore == 0 && !offsetState.singleOffsetScore) {
    message.warn("请设置分数变化");
    return;
  }

  isTraining.value = true;
  let url: string;
  let faultInfo = allFaultNames.value.find((t: any) => t.column === selectedFaultNameValue.value);
  let params = {
    machineId: sensorInfo.value.machineId,
    sensorId: sensorInfo.value.id,
    srcScore: scoreData.map(t => t[selectedFaultNameValue.value]), //原始分数，[60,80]
    dateArray: scoreData.map(t => t.date),
    variance: 0, //分数变化
    tarFunc: faultInfo.function,  //目标函数，brg_D_05,S40_Score
    funcTag: faultInfo.column, //函数标签S39,T53
    trainIndex: 0,
    isSingle: false
  }
  if (offsetState.wholeOffsetScore) {
    // 多阈值指标
    url = "/scoreModel/score/multi-threshold"
    params.variance = offsetState.wholeOffsetScore;
    params.isSingle = false;
  } else {
    // 单阈值指标
    url = "/scoreModel/score/single-threshold"
    params.variance = offsetState.singleOffsetScore!.offsetScore;
    params.trainIndex = selectedChartDataIndex!;
    params.isSingle = true;
  }

  try {
    let result = await request.post(url, params);
    message.success("训练成功!");
    if (result?.data) {
      updateTrainedData(faultInfo.column, params.isSingle, result.data.dateArray, result.data.result || [])
    }
  } catch (err) {
    throw err;
  } finally {
    isTraining.value = false;
  }

}

let isApplyModelLoading = ref(false);
const onClickApplyModel = async () => {

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  if (!trainedParamGroups.value || trainedParamGroups.value.length == 0) {
    message.warn("请先训练模型参数");
    return
  }

  if (selectedParamGroupIndex.value == null || selectedParamGroupIndex.value < 0) {
    message.warn("请选择训练模型参数");
    return;
  }

  let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
  if (!paramGroup) {
    message.warn("请先训练模型参数");
    return;
  }

  isApplyModelLoading.value = true;

  let body = {
    sensorId: sensorInfo.value.id,
    isExpert: paramGroup.faultName[0] === "T",
    params: paramGroup.params,
  }

  try {
    let result = await request.patch("/api/machines/threshold", body);
    if (result && result.status === 200) {
      message.success("保存模型参数成功!");
    } else {
      message.error((result as any)?.message || "系统错误");
    }
  } catch (err) {
    throw err;
  } finally {
    isApplyModelLoading.value = false;
  }
};

let isSaveModelLoading = ref(false);
const onClickSaveModel = async () => {
  let dateQuery = getQueryDate();

  isSaveModelLoading.value = true;

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    isSaveModelLoading.value = false;
    return;
  }

  if (offsetState.wholeOffsetScore == 0 && !offsetState.singleOffsetScore) {
    message.warn("请设置加分");
    isSaveModelLoading.value = false;
    return;
  }

  let modelParams = null;
  if (selectedParamGroupIndex.value >= 0) {
    let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
    if (paramGroup) modelParams = paramGroup.params;
  }

  let faultInfo = allFaultNames.value.find((t: any) => t.column === selectedFaultNameValue.value);
  let body = {
    factoryId: sensorInfo.value.factoryId,
    machineId: sensorInfo.value.machineId,
    machineType: sensorInfo.value.machineTypeId,
    sensorIds: [sensorInfo.value.id],
    faultName: selectedFaultNameValue.value,
    faultTitle: faultInfo?.title,
    wholeOffsetScore: offsetState.wholeOffsetScore, // 整体加分
    singleOffsetScore: offsetState.singleOffsetScore, // 单点加分
    modelParams: modelParams,
    begin: dateQuery.begin,
    end: dateQuery.end,
  };
  try {
    let result = await request.post("/api/quota/vibration-model", body);
    if (result) {
      message.success("保存模板成功!");
    }
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }
}

let selectedFaultNameValue: any = ref('');
const onSelectFaultName = async (e: Event) => {
  //selectedFaultNameValue.value = (e?.target as any)?.value;
  //console.log('select fault name', e);
  let value = (e?.target as any)?.value;
  onChangeFaultName(value)
}

// 专业指标中的单指标集合
let singleExpertFaultNames = ["T8",
  "T14",
  "T15",
  "T16",
  "T17",
  "T18",
  "T19",
  "T20",
  "T21",
  "T22",
  "T23",
  "T30",
  "T35",
  "T39",
  "T40",
  "T48",
  "T49",
  "T51",
  "T54",
  "T62",
  "T63"];
const onChangeFaultName = async (value: string) => {
  if (!value) {
    allowAddSingle.value = false;
    allowAddWhole.value = false;
  }
  if (value && value[0] === "S" || singleExpertFaultNames.includes(value)) {
    allowAddSingle.value = true;
    allowAddWhole.value = false;
  } else {
    allowAddSingle.value = false;
    allowAddWhole.value = true;
  }
  await loadSensorVibrationModel();
  await quotaSearch();
}

const loadSensorVibrationModel = async () => {
  let config: any = {
    params: {
      skip: 1, take: 1,
      sensorId: sensorInfo.value.id,
      faultName: selectedFaultNameValue.value,
      factoryId: localStorage.getItem("factory_id"),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/vibration-model", config);
  if (result && result.data?.list?.length > 0) {
    let model = result.data.list[0];
    if (model.singleOffsetScore) {
      form.value.singleOffsetScore = Math.abs(model.singleOffsetScore.offsetScore || 0);
      form.value.singleOffsetScoreOperator = model.singleOffsetScore?.offsetScore < 0 ? SCORE_OPERATOR.minus : SCORE_OPERATOR.plus;
    } else {
      selectedChartDataIndex = 0;
      selectedChartDataDate = "";
    }
    form.value.wholeOffsetScore = Math.abs(model.wholeOffsetScore || 0);
    form.value.wholeOffsetScoreOperator = model.wholeOffsetScore < 0 ? SCORE_OPERATOR.minus : SCORE_OPERATOR.plus;
    offsetState = {
      wholeOffsetScore: model.wholeOffsetScore,
      singleOffsetScore: model.singleOffsetScore
    }
  } else {
    form.value.wholeOffsetScore = 0;
    form.value.singleOffsetScore = 0;
    offsetState = {
      wholeOffsetScore: 0,
      singleOffsetScore: null
    }
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const selectMenu = (type: any) => {
  menuSelect.value = type;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

let data = ref([]);

let selectUnUseSensor = () => {
};

let selectUseSensor = () => {
};

let machineTypes: any = ref([]);

class FaultNameItem {
  column: string
  title: string
}

const faultNames: any = ref(([] as FaultNameItem[]));
const faultNameExpects: any = ref(([] as FaultNameItem[]));
const allFaultNames: any = ref(([] as FaultNameItem[]));
let isLoadingFaultNames: boolean = false;
const loadFaultNames = async (sensorId: string) => {
  isLoadingFaultNames = true;
  let config: any = {
    params: { sensorId: sensorId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("api/sensors/faultnames", config);
  isLoadingFaultNames = false;
  if (result && result.data) {
    faultNames.value = result.data.faultNames || [];
    faultNameExpects.value = result.data.faultNameExpects || [];
    allFaultNames.value = [...faultNames.value, ...faultNameExpects.value];

    if (allFaultNames.value.findIndex((t: any) => t.column === selectedFaultNameValue.value) > -1) {
    } else {
      selectedFaultNameValue.value = allFaultNames.value[0].column;
    }
  } else {
    selectedFaultNameValue.value = null;
  }
  await onChangeFaultName(selectedFaultNameValue.value);
};

const tempColumns = [
  {
    title: "模板号",
    dataIndex: "version",
    width: "60px"
  },
  {
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeName",
  },
  {
    title: langObj.value["指标"],
    dataIndex: "faultTitle",
    minWidth: "130px"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let loadMachineTypes = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-type/all", config);
  if (result) {
    machineTypes.value = result.data;
  }
};


let tempModelQuery = ref({
  date: null,
  begin: null,
  end: null,
  machineTypeId: null,
  faultName: null,
});

let loadVibrationModelList = async () => {
  if (tempModelQuery.value.date) {
    tempModelQuery.value.begin = tempModelQuery.value.date[0].$d;
    tempModelQuery.value.end = tempModelQuery.value.date[1].$d;
  }

  let config: any = {
    params: {
      machineType: tempModelQuery.value.machineTypeId,
      faultName: tempModelQuery.value.faultName,
      factoryId: localStorage.getItem("factory_id"),
      begin: tempModelQuery.value.begin,
      end: tempModelQuery.value.end,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get<any>("/api/quota/vibration-model", config);
  if (result) {
    data.value = result.data || [];
  }
};

let deleteVibrationModel = async (record: any) => {
  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/quota/vibration-model", config).then((res) => {
    if (res) {
      message.success("操作成功");
      loadVibrationModelList();
    } else {
    }
  });
};

let loadBingInfos = async (record: any) => {
  selectSimpleModel.value = record;

  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/vibration-model/bind-infos", config);
  if (result) {
    const groupedData: any = {};
    const groupedDataUn: any = {};

    result.data.binds.map((item: any) => {
      if (!groupedData[item.groupId]) {
        groupedData[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedData[item.groupId].machines[item.machineId]) {
        groupedData[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedData[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    useSensorIds.value = result.data.binds.map((p: any) => p.sensorId);
    useSensorIdsOld.value = useSensorIds.value;

    const treeData = Object.keys(groupedData).map((groupId) => ({
      key: groupId,
      title: groupedData[groupId].groupName,
      checkable: false,

      children: Object.keys(groupedData[groupId].machines).map((machineId) => ({
        key: machineId,
        title: groupedData[groupId].machines[machineId].machineName,
        checkable: false,
        children: groupedData[groupId].machines[machineId].sensors,
      })),
    }));

    useSensors.value = treeData;

    result.data.unBinds?.map((item: any) => {
      if (!groupedDataUn[item.groupId]) {
        groupedDataUn[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedDataUn[item.groupId].machines[item.machineId]) {
        groupedDataUn[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedDataUn[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    const treeDataUn = Object.keys(groupedDataUn).map((groupId) => ({
      key: groupId,
      title: groupedDataUn[groupId].groupName,
      checkable: false,
      children: Object.keys(groupedDataUn[groupId].machines).map(
          (machineId) => ({
            key: machineId,
            checkable: false,
            title: groupedDataUn[groupId].machines[machineId].machineName,
            children: groupedDataUn[groupId].machines[machineId].sensors,
          })
      ),
    }));

    unUseSensorIds.value = [];
    unUseSensors.value = treeDataUn;
  }
};

let submitUnUseModel = async () => {
  if (unUseSensorIds.value.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/vibration-model",
        { id: selectSimpleModel.value.id, sensorIds: unUseSensorIds.value },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      await loadBingInfos(selectSimpleModel.value);
    }
  }
};

let submitReplaceUseModel = async () => {
  let diff = useSensorIds.value
      .concat(useSensorIdsOld.value)
      .filter(
          (item: any) =>
              !useSensorIds.value.includes(item) ||
              !useSensorIdsOld.value.includes(item)
      );
  if (diff.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/vibration-model/un-bind",
        { id: selectSimpleModel.value.id, sensorIds: diff },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      loadBingInfos(selectSimpleModel.value);
    }
  }
};
</script>


<style lang="less" scoped>
.sjz {
  margin-left: 4rem;
  margin-right: 4rem;
  width: calc(100% - 8rem);
  height: 20px;
  background: #999;
  padding: 0;
}

/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/
.ant-select-single.ant-select-sm:not(.ant-select-customize-input),
.ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-switch {
  background-color: #07259a;
}

/deep/ .ant-switch-checked {
  background-color: #07259a !important;
}

/deep/ .ant-picker {
  background-color: #021768;
  border: 1px solid #0d53b7;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.5;
}

/deep/ .ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #0d53b7cc !important;
}

/deep/ .ant-table-thead {
  border: #072499 !important
}

/deep/ .ant-table-tbody > tr > td {
  border-right: 0 solid #fff !important;
}

/deep/ table {
  border-top: 0 solid #0d53b7cc !important
}

/deep/ .ant-table-thead .ant-table-cell {
  background: #03155C !important;
  border-right: 0 !important;
}

/deep/ .ant-tree {
  background: none;
  color: white;
  height: 100%
}

/deep/ .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

/deep/ .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
  width: 100%;
  height: calc(100vh - 310px);
}

.machine-select {
  border-radius: 2px;
  background: #072499;
}

.content {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.btn1 {
  border: 1px solid #0d53b7;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn1-select {
  background: #072499;
  padding: 5px 10px;
  cursor: pointer;
}

.btn2 {
  border: 1px solid #0d53b7;
  cursor: pointer;
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size20 {
  font-size: 20px;
}

.size18 {
  font-size: 18px;
}

.size12 {
  font-size: 12px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w80 {
  width: 80px;
}

.imgShow {
  max-width: 15px;
  width: 15px;
  height: 15px;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0 solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}

/deep/ .ant-btn-primary[disabled] {
  color: grey;
  cursor: not-allowed;

  &:hover {
    background: #0d53b7;
  }
}
</style>
