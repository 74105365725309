import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "pdfCompany",
  class: "p-4 w-full tanchuang_bg",
  style: {"color":"#fff","background-color":"#020f46","border":"1px solid #0d53b7cc"}
}
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = {
  class: "text-center w-full",
  style: {"font-size":"18px"}
}
const _hoisted_4 = { class: "w-full flex items-center" }
const _hoisted_5 = {
  class: "text-center flex-1",
  style: {"font-size":"16px"}
}
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = {
  class: "mt-2 w-full",
  style: {"border":"1px solid #465488"}
}
const _hoisted_11 = { class: "w-full flex items-center p-2" }
const _hoisted_12 = {
  style: {"width":"10%"},
  class: "px-2"
}
const _hoisted_13 = {
  style: {"width":"25%"},
  class: "px-2"
}
const _hoisted_14 = {
  style: {"width":"20%"},
  class: "px-2"
}
const _hoisted_15 = {
  style: {"width":"25%"},
  class: "px-2"
}
const _hoisted_16 = {
  style: {"width":"20%"},
  class: "px-2"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  style: {"width":"10%"},
  class: "px-2"
}
const _hoisted_19 = {
  style: {"width":"25%"},
  class: "px-2"
}
const _hoisted_20 = {
  style: {"width":"20%"},
  class: "px-2"
}
const _hoisted_21 = {
  style: {"width":"25%"},
  class: "px-2"
}
const _hoisted_22 = {
  style: {"width":"20%"},
  class: "px-2"
}
const _hoisted_23 = {
  key: 0,
  class: "mt-2 w-full"
}
const _hoisted_24 = ["id"]
const _hoisted_25 = { class: "mt-2 flex" }
const _hoisted_26 = { class: "flex-1 bor1" }
const _hoisted_27 = {
  class: "w-full text-center",
  style: {"font-size":"12px"}
}
const _hoisted_28 = { class: "flex-1 bor1" }
const _hoisted_29 = {
  class: "w-full text-center",
  style: {"font-size":"12px"}
}
const _hoisted_30 = {
  key: 1,
  class: "mt-2 w-full"
}
const _hoisted_31 = { class: "w-full" }
const _hoisted_32 = { class: "w-full text-center" }

import request from "../../../common/request";
import { ref, reactive, toRaw } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getGroupName, getFactoryName } from "../../../common/tools";
import * as echarts from "echarts";
import { langList } from "../../../common/lang";
import htmlToPdf from '../../../common/htmlToPdf';
import { message } from 'ant-design-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DiagnosticReportDetails2',
  emits: ["cancel"],
  setup(__props, { expose: __expose, emit: __emit }) {

let reportInfo: any = ref({});
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
let gradeList = [
  { value: -1, label: "健康", color: "#61c08f", dec: "无需操作" },
  {
    value: 0,
    label: "可用",
    color: "#d2de49",
    dec: "发现异常特征，按照巡查计划现场查看设备状态",
  },
  {
    value: 1,
    label: "警戒",
    color: "#cd5f3b",
    dec: "发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划，适时维护设备",
  },
  {
    value: 2,
    label: "故障",
    color: "#921e37",
    dec: "设备故障严重，建议停机维修",
  },
];

let isShowChart = ref(false)

const show = async (id: any) => {
  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  if (result && result.data) {
    reportInfo.value = result.data;

    if (reportInfo.value.machines && reportInfo.value.machines.length) {
      reportInfo.value.factoryName = getFactoryName(
        reportInfo.value.machines[0].factoryId
      );
      reportInfo.value.groupName = getGroupName(
        reportInfo.value.machines[0].groupId
      );
      reportInfo.value.machineName = reportInfo.value.machines[0].machineName;

      reportInfo.value.expertReport?.diagnosisDetails &&
        reportInfo.value.expertReport?.diagnosisDetails.map((d: any) => {
          d.faultCauses = d.faultCause
            ? d.faultCause.replace("\r\n", "\n").split("\n")
            : [];
          d.faultSuggests = d.faultSuggest
            ? d.faultSuggest.replace("\r\n", "\n").split("\n")
            : [];
        });

      if(reportInfo.value?.expertReport?.sensorId) {
        getChartData()
      } else {
        isShowChart.value = false
      }

      setTimeout(() => {
        reportInfo.value.expertReport &&
          reportInfo.value.expertReport?.diagnosisDetails.map(
            (d: any, index: any) => {
              d.trends &&
                d.trends.map((item: any, i: any) => {
                  initChart(item, "chart" + index + i);
                });
            }
          );
      }, 1000);
    }
  }
};

const getChartData = async() => {
  let config: any = {
    params: {
      sensorId: reportInfo.value.expertReport.sensorId,
      date: reportInfo.value.date,
      type: 'HZ'
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/external/singles/info', config)
//   result.data =  {
//     url:  
// "https://source.freqx.com/singles/66260e2b6f2b8fa334bc48f6/20240708/null_662616946f2b8fa334bc4915_HZ_20240708_060000.Single"
//   }
  if(result?.data?.url) {
    isShowChart.value = true
    let fftResult = await request.post('/draw/api/fft', {url: [result.data.url]})
    let hhtResult = await request.post('/draw/api/hht', {url: [result.data.url]})
    if(fftResult?.data) {
      initChartPT(fftResult.data.axisX, fftResult.data.axisY, 'chartDD1')
    }
    if(hhtResult?.data) {
      initChartPT(hhtResult.data.axisX, hhtResult.data.axisY, 'chartDD2')
    }
  } else {
    isShowChart.value = false
  }
}

const initChartPT = (xData:any, yData:any, id:any) => {
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById(id));

  const option = {
    title: {
      left: "center",
      textStyle: {
        fontSize: 12,
        color: theme.value=='white'? "#333": "#ffffff",
      },
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: '6%',
      left: "6%",
      right: "6%",
      bottom: "6%",
      containLabel: true,
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: yData,
      },
    ],
  };
  myChart.setOption(option);

}

const initChart = (data: any, id: any) => {
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById(id));

  let markList: any[] = [];

  data.markLine?.data?.forEach((d: any) => {
    if (d.name == '开') {
      markList.push({
        name: langObj.value['开'],
        xAxis: d.xAxis,
        label: { show: true,  color: theme.value=='white'? "#333": "#ffffff", formatter: '{b}' },
        lineStyle: { color: 'green' },
      });
    }
    if (d.name == '关') {
      markList.push({
        name: langObj.value['关'],
        xAxis: d.xAxis,
        label: { show: true,  color: theme.value=='white'? "#333": "#ffffff", formatter: '{b}' },
        lineStyle: { color: 'rgb(173, 38, 64)' },
      });
    }
  });

  let titleT = ref('')
  if(!data.title) {data.title = ''}
  if (data.title.indexOf(":") != -1) {
    let titleArr = data.title.split(":")
    let title1 = langObj.value[titleArr[0]] || titleArr[0];
    let title2 = langObj.value[titleArr[1]] || titleArr[1];
    titleT.value = title1 + ":" + title2;
  } else if (data.title.indexOf("：") != -1) {
    let titleArr = data.title.split("：")
    let title1 = langObj.value[titleArr[0]] || titleArr[0];
    let title2 = langObj.value[titleArr[1]] || titleArr[1];
    titleT.value = title1 + ":" + title2;
  }


  const option = {
    title: {
      left: "center",
      textStyle: {
        fontSize: 12,
        color: theme.value=='white'? "#333": "#ffffff",
      },
      text: titleT.value,
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: "20%",
      left: "6%",
      right: "6%",
      bottom: "6%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data.date,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: data.value,
        markLine: {
          data: markList,
          silent: true,
        },
      },
    ],
  };
  myChart.setOption(option);
};

const cancel = () => {
  emits("cancel", true);
};

const emits = __emit;

__expose({
  show,
});
const resultReport = ref<any>({});

// 下载pdf
let loadingDown = ref(false)
const handleDown = async() => {
    loadingDown.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    let result = await htmlToPdf.getPdf('诊断报告', 'a4' );
    console.log('result', result)
    loadingDown.value = false
    if(result) {
        message.success('下载成功')
    } else {
        message.warning('下载失败，请重试')
    }

}



return (_ctx: any,_cache: any) => {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
      _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: cancel
      }, [
        _createVNode(_component_close_outlined, {
          class: "clsIco",
          style: {"font-size":"20px"}
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(reportInfo).factoryName) + " - " + _toDisplayString(_unref(reportInfo).groupName) + " - " + _toDisplayString(_unref(reportInfo).machineName) + " - " + _toDisplayString(_unref(langObj)['诊断报告']), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(langObj)['报告时间']) + ": " + _toDisplayString(_unref(reportInfo).date), 1),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleDown())),
          "nz-button": "",
          nzType: "text",
          class: "ml-2"
        }, [
          _createVNode(_component_download_outlined, { style: {"font-size":"18px"} })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, "1." + _toDisplayString(_unref(langObj)['设备信息']), 1),
      _createElementVNode("div", null, _toDisplayString(_unref(langObj)['诊断设备']) + ": " + _toDisplayString(_unref(reportInfo).factoryName) + " / " + _toDisplayString(_unref(reportInfo).groupName) + " / " + _toDisplayString(_unref(reportInfo).machineName) + " / " + _toDisplayString(_unref(langObj)['诊断报告']), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, "2." + _toDisplayString(_unref(langObj)['设备健康状态']), 1),
      (_unref(reportInfo).expertReport)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", {
              class: "mr-1",
              style: _normalizeStyle([{"width":"10px","height":"10px","border-radius":"50%"}, {
        background: _unref(gradeList).find(
          (p) => p.value === _unref(reportInfo).expertReport.grade
        )?.color,
      }])
            }, null, 4),
            _createElementVNode("span", null, _toDisplayString(_unref(langObj)[_unref(gradeList).find((p) => p.value === _unref(reportInfo).expertReport.grade)
          ?.label]), 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "：", -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(langObj)[_unref(gradeList).find((p) => p.value === _unref(reportInfo).expertReport.grade)?.dec]), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", null, "3." + _toDisplayString(_unref(langObj)['诊断结果']), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)['故障模式']), 1),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(langObj)['诊断结论']), 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['信号分析']), 1),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(langObj)['故障原因']), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)['建议及措施']), 1)
        ]),
        (_unref(reportInfo).expertReport && _unref(reportInfo).expertReport?.diagnosisDetails
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(reportInfo).expertReport.diagnosisDetails, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  style: {"border-top":"1px solid #465488"},
                  key: index,
                  class: "w-full flex items-center p-2"
                }, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)[item.faultEvent] || item.faultEvent), 1),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(item.diagnoseMessage), 1),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(item.signalAnalysis), 1),
                  _createElementVNode("div", _hoisted_21, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.faultCauses, (cause, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(cause), 1))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.faultSuggests, (suggest, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(suggest), 1))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_unref(reportInfo).expertReport && _unref(reportInfo).expertReport?.diagnosisDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(reportInfo).expertReport.diagnosisDetails, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.trends, (t, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createElementVNode("div", {
                    id: 'chart' + index + i,
                    style: {"margin-top":"10px","width":"100%","height":"200px"}
                  }, null, 8, _hoisted_24)
                ]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(langObj)['幅值谱图']), 1),
        _cache[3] || (_cache[3] = _createElementVNode("div", {
          id: "chartDD1",
          class: "h-56 w-full"
        }, null, -1))
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"width":"3%"} }, null, -1)),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(langObj)['包络谱图']), 1),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          id: "chartDD2",
          class: "h-56 w-full"
        }, null, -1))
      ])
    ], 512), [
      [_vShow, _unref(isShowChart)]
    ]),
    (_unref(reportInfo).pictures && _unref(reportInfo).pictures.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("div", null, "4." + _toDisplayString(_unref(langObj)['图片']), 1),
          _createElementVNode("div", _hoisted_31, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(reportInfo).pictures, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                style: {"width":"50%"},
                key: index
              }, [
                _createElementVNode("div", _hoisted_32, _toDisplayString(item.remark), 1),
                _createVNode(_component_a_image, {
                  class: "w-full",
                  src: item.url,
                  alt: item.remark
                }, null, 8, ["src", "alt"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})