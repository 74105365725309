<template>
  <div class="bule_list_page theme-common">
    <div class="search_wrap">
      <a-input v-model:value="searchFac" placeholder="请输入工厂名称搜索">
        <template #suffix>
          <search-outlined style="cursor: pointer;" @click="factoryFilter" />
        </template>
      </a-input>
    </div>

    <a-layout-content style="padding: 0 20px" class="flex box">
      <a-tree style="height: 80vh; overflow: auto; padding: 10px 0; width: 300px" class="draggable-tree" block-node
        :tree-data="treeData" @select="selectTree" />
      <div class="w-full fac-box p-3 ml-4" v-if="factory_id">
        
        <div class="freqx_formTitle">设备报警</div>
       
        <a-button @click="onAdd" type="primary" >
          <plus-outlined />
          新增
        </a-button>

        <a-table :emptyText="'暂无数据'" bordered :columns="columns" :data-source="machinePushConfigs" :pagination="false" :loading="loading" size="small">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'group'">
              {{ record.group.name || record.group.Name }}
            </template>
            <template v-if="column.dataIndex === 'machineIds'">
              <span v-for="(item, index) in text" :key="index">
                <a-tag color="#2db7f5"
                      v-if="machineMatch.find((t: any) => t.id == item)">{{
                        machineMatch.find((t: any) => t.id == item)?.machineName
                      }}</a-tag>
              </span>
            </template>

            <template v-if="column.dataIndex === 'act'">
              <div class="w-full flex">
                <div class="flex-1"></div>
                <div style="width: 180px">
                  <a-popconfirm title="确定删除此条数据？"
                              ok-text="确定"
                              cancel-text="取消" @confirm="deleteThis(record)">
                  <a-button type="primary" danger>
                    <delete-outlined />
                    删除
                  </a-button>
                </a-popconfirm>

                  <a-button type="primary" class="plft" @click="onEdit(record)">
                    <edit-outlined />
                    编辑
                  </a-button>
                </div>
                <div class="flex-1"></div>
              </div>

            </template>
          </template>
        </a-table>

      </div>
      <div class="w-full fac-box p-3 ml-4" v-else>
        <a-alert message="请选择工厂" type="warning" />
      </div>
    </a-layout-content>






    <a-modal :width="1300" v-model:visible="modalState.visible" :title="modalState.title" :maskClosable="false" :confirm-loading="modalState.confirmLoading"
             @ok="onSaveMachinePushConfigs"  v-model:z-index="editRindex">
      <div class="freqx_formTitle" style="color: #fff">基本信息</div>
      <a-form :model="formInfo" name="basic" autocomplete="off" layout="inline" ref="formStateRef" :label-col="{ style: { width: '120px' } }">


        <a-form-item label="区域" name="groupId" :rules="[{ required: true, message: '请选择' }]" @change="clearValidate('groupId')">
          <a-select  v-model:value="formInfo.groupId" :options="groupList" placeholder="请选择" style="min-width: 150px" @select="selectGroup">
          </a-select>
        </a-form-item>
        <a-form-item label="设备" name="machineIds" :rules="[{ required: true, message: '请选择' }]" @change="clearValidate('machines')">
          <a-select  v-model:value="formInfo.machineIds" style="width: 380px" :options="machinesList" placeholder="请选择" mode="multiple" :max-tag-count="5" :max-tag-text-length="6">
          </a-select>
        </a-form-item>


      </a-form>
      <div class="freqx_formTitle" style="color: #fff">推送配置</div>
      <a-table bordered :columns="pushColumns" :dataSource="formInfo.pushItems" :pagination="false">
        <template #bodyCell="{ column, record, text, index }">
          <template v-if="column.dataIndex === 'messageType'">
            <div class="flex items-center">
              <div class="ml-2">{{ getMessageType(Number(text)) }}</div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'members'">
            <div class="flex items-center">
              <div class="ml-2">
                <a-select 
                          mode="multiple"
                          v-model:value="record.members"
                          style="width: 150px"
                          placeholder="请选择接收人"
                          :filterOption="memberFilterOption">
                  <a-select-option v-for="(item) in oMembers" :key="item._id">
                    {{ item.nickName || item.userName }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'level1Members'">
            <div class="flex items-center">
              <div class="ml-2">
                <a-select 
                          mode="multiple"
                          v-model:value="record.level1Members"
                          style="width: 150px"
                          placeholder="请选择接收人"
                          :filterOption="memberFilterOption">
                  <a-select-option v-for="(item) in oMembers" :key="item._id">
                    {{ item.nickName || item.userName }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'level2Members'">
            <div class="flex items-center">
              <div class="ml-2">
                <a-select 
                          mode="multiple"
                          v-model:value="record.level2Members"
                          style="width: 150px"
                          placeholder="请选择接收人"
                          :filterOption="memberFilterOption">
                  <a-select-option v-for="(item) in oMembers" :key="item._id">
                    {{ item.nickName || item.userName }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'level3Members'">
            <div class="flex items-center">
              <div class="ml-2">
                <a-select 
                          mode="multiple"
                          v-model:value="record.level3Members"
                          style="width: 150px"
                          placeholder="请选择接收人"
                          :filterOption="memberFilterOption">
                  <a-select-option v-for="(item) in oMembers" :key="item._id">
                    {{ item.nickName || item.userName }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'platforms'">
            <div class="flex items-center">
              <div class="ml-2">
                <a-select 
                          mode="multiple"
                          v-model:value="record.platforms"
                          style="width: 200px"
                          placeholder="请选择推送平台">
                  <a-select-option
                                   v-for="(item) in pushTypes"
                                   :key="item.code"
                                   :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </template>
        </template>
      </a-table>



    </a-modal>



    <pushRuleDriverComponent @savePushRuleDriver="savePushRuleDriver" @cancel="cancel" :visible="pushRuleDriverVisible"
      v-if="pushRuleDriverVisible" :editRindex="editRindex"></pushRuleDriverComponent>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  ref,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";
import pushRuleDriverComponent from "./push-rule-driver.vue";
const router = useRouter();

let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
};
getLang();

const getMessageType = (t) => {
  switch (t) {
    case 4:
      return getLanguage("智能诊断报警", language.value);
    case 5:
      return getLanguage("设备门限报警", language.value);
  }
};

const getMembers = async (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId, take: 10000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/members", config);
  oMembers.value = res?.data?.list
  return res?.data?.list || [];
};

let pushTypes = ref([
  {
    code: 0,
    name: "短信",
  },
  {
    code: 1,
    name: "微信公众号",
  },
]);
let searchFac = ref('');

let oMembers = ref([]);


let columns = ref<any[]>([
  {
    title: "工厂",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "group",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineIds",
    align: "center"
  },
  {
    title: "操作",
    dataIndex: "act",
    with: '200px',
    align: "center"
  }
]);

const filterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

let dataList = ref<any>([]);

let _selectedFactoryId: string = "";
let factoryList = ref<any>();
let treeData: any = ref([]);
let allFactory = ref([]);

let factory_id:any = ref()
let factory_Name:any = ref()
let groupList:any = ref([]);
let machineMatch:any = ref([]);
const machinePushConfigs = ref<any>();


const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      factoryFilter()
    }
  });
};


const factoryFilter = () => {
  let factoryF = JSON.parse(JSON.stringify(allFactory.value)) || []
  var list1 = factoryF.map((t: any) => ({
    key: t._id,
    title: t.factoryName,
    value: t._id,
    label: t.factoryName,
    parentId: t.parentId,
    id: t._id,
    type: "factory",
  }));
  let factory: any = [];
  let parentFs = list1.filter((p: any) => !p.parentId);
  parentFs.map((d: any) => {
    let sonFactory = list1.filter((p: any) => p.parentId === d.id);
    if (sonFactory && sonFactory.length) {
      d.children = sonFactory;
    }
    factory.push(d);
  });

  let list = JSON.parse(JSON.stringify(factory));
  factoryList.value = [];
  if (searchFac.value) {
    list.map((d: any) => {
      if (d.children && d.children.length) {
        let list1 = d.children.filter((p: any) =>
          p.label.includes(searchFac.value)
        );
        if (list1.length) {
          d.children = list1;
          factoryList.value.push(d);
        } else {
          if (d.label.includes(searchFac.value)) {
            factoryList.value.push(d);
          }
        }
      } else {
        if (d.label.includes(searchFac.value)) {
          factoryList.value.push(d);
        }
      }
    });
  } else {
    factoryList.value = list;
  }
  treeData.value = factoryList.value;
}


const selectTree = async (ev: any, info: any) => {
  if (ev && ev.length && info.node.type == "factory") {
    var cutFactory:any = allFactory.value.find((t:any) => t._id == info.node.id);
    console.log('cutFactory', cutFactory)
    if (cutFactory) {
      groupList.value = cutFactory.groups.map((p: any) => ({ label: p.name, value: p.sonID }))
      factory_id.value = cutFactory._id;
      factory_Name.value = cutFactory.factoryName;
      await getMembers(cutFactory._id);
      await loadMachine();
      search();
    } else {
      factory_id.value = "";
    }
  }
};

const loadMachine = async () => {
  let config = {
    params: { factoryId: factory_id.value },
    headers: {
      requestId: uuidv4(),
    },
  }
  let res = await request.get("/api/machines", config)
  console.log('res2222222222222', res)
  machineMatch.value = res?.data || []
};


const search = () => {
  const config = {
    params: {
      factoryId: factory_id.value
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  request.get("/api/push-config", config).then((res: any) => {
    if (res && res.data) {
      machinePushConfigs.value = res.data.filter((t: any) => t.machineIds && t.machineIds.length > 0);
    }
  });
}

let formStateRef:any = ref()
const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
});
let formInfo:any = ref({})
class PushItem {
  messageType = 4;
  platforms: number[] = [];
  members: string[] = [];
  level1Members: string[] = [];
  level2Members: string[] = [];
  level3Members: string[] = [];

  constructor(messageType: number) {
    this.messageType = messageType;
  }
}
let machinesList:any = ref([])
let pushColumns = [
  {
    title: "报警类型",
    dataIndex: "messageType",
  },
  {
    title: "接收人",
    dataIndex: "members",
  },
  {
    title: "可用接收人",
    dataIndex: "level1Members",
  },
  {
    title: "警戒接收人",
    dataIndex: "level2Members",
  },
  {
    title: "故障接收人",
    dataIndex: "level3Members",
  },
  {
    title: "推送平台",
    dataIndex: "platforms",
  },
];
const messageTypes = [
  { type: 4, label: "智能诊断报警", group: "machine" },
  { type: 5, label: "设备门限报警", group: "machine" },
  { type: 6, label: "采集卡网关异常报警", group: "factory" },
  { type: 7, label: "传感器异常报警", group: "factory" }
]

const memberFilterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const onAdd = () => {
  modalState.title = "新增";
  formStateRef.value?.clearValidate();

  formInfo = ref<any>({
    id: 0,
    factoryId: factory_id.value,
    factoryName: factory_Name.value,
    machineIds: [],
    groupId: "",
    group: {},
    pushItems: [new PushItem(4), new PushItem(5)]
  });

  modalState.visible = true;
};

// 保存
const onSaveMachinePushConfigs = () => {
  formStateRef.value
    .validate()
    .then(() => {
      formInfo.value = {
        ...formInfo.value
      }
      if (!formInfo.value.factoryName) {
        formInfo.value.factoryName = factory_Name.value
      }
      if (formInfo.value.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        let body = formInfo.value;

        request.put("/api/push-config", body, config)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };

        request.post("/api/push-config", formInfo.value, config)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};



const clearValidate = (name: any) => {
  formStateRef.value?.clearValidate(name);
};

const selectGroup = async (ev: any) => {
  let groupInfo = groupList.value.find((p: any) => p.value === ev)
  if (groupInfo) {
    formInfo.value.group = {
      sonID: groupInfo.value,
      name: groupInfo.label,
    };
    let config = {
      params: { factoryId: factory_id.value, groupId: ev },
      headers: {
        requestId: uuidv4(),
      },
    }
    let res = await request.get("/api/machines", config)
    console.log('res', res)
    machinesList.value = (res?.data || []).map((p: any) => ({ label: p.machineName, value: p.id }))
  }
}


const onEdit = (val: any) => {
  modalState.visible = true;
  modalState.title = "编辑";

  setTimeout(() => {
    formInfo.value = JSON.parse(JSON.stringify(val));
    formStateRef.value.clearValidate();
    if (formInfo.value.groupId) {
      //   machinesList.value = groupMachineOptionsMap.get(formInfo.value.groupId) || [];
      selectGroup(formInfo.value.groupId)
    }
    if (!formInfo.value.pushItems) {
      formInfo.value.pushItems = [];
    }
    for (let type of messageTypes) {
      if (type.group === "machine" && formInfo.value.pushItems.findIndex((t: any) => t.messageType === type.type) < 0) {
        formInfo.value.pushItems.push(new PushItem(type.type))
      }
    }
  }, 500);
};


const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/push-config", config).then((res) => {
    if (res) {
      message.success("操作成功");
      search();
    }
  });
};



const getPlatforms = () => {
  request.get("/api/push-config/platforms").then((res: any) => {
    if (res?.data) {
      pushTypes.value = res.data || [];
    }
  });
}



onMounted(async () => {
  await loadFactory();
  getPlatforms()
  //新注册用户，未经过引导的需弹窗进行引导
  if (!getMemberOnboarding()) {
    await selectTree([treeData.value[0].id], { node: treeData.value[0] });
    setTimeout(() => {
      highlightStep(getPopover(".pushruleDriver", "8/9 报警推送", "针对智能诊断报警和门限报警，可配置接收人和推送平台。（系统提供微信公众号和短信推送平台）", "推送配置", "bottom", "center", nextRoute, pushruleDriver));
    }, 400)
  }
});

let pushRuleDriverVisible = ref(false);
let editRindex = ref("auto");
//新用户引导报警推送配置按钮回调
const pushruleDriver = () => {
  editRindex = 1000000001;
  pushRuleDriverVisible.value = true;
};
const cancel = () => {
  pushRuleDriverVisible.value = false;
}
//新用户引导报警推送下一步按钮回调
const nextRoute = () => {
  router.push("/report/list");
  return;
};
const savePushRuleDriver = async (ev: any) => {
  pushRuleDriverVisible.value = false;
  await selectTree([treeData.value[0].id], { node: treeData.value[0] });
}
</script>

<style scoped>
.flex {}

.code-box-description {
  padding-bottom: 0;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.bigFont {
  font-size: 22px;
  font-weight: 700;
}

.freqx_tips {
  margin-top: 4px;
}

.facInfo {
  border: 1px solid gray;
}

.fac-box {
  border: 1px solid rgba(128, 128, 128, 0.384);
}
</style>

<style lang="less" scoped>
/deep/ .ant-table {
  color: #fff;
}

/deep/ .ant-table-thead .ant-table-cell {
  background-color: #020f46;
  color: #fff;
}

/deep/ table tbody tr:hover>td {
  background-color: #020f46 !important;
}

/deep/ .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #020f46 !important;
}

/deep/ .ant-table-tbody>tr>td {
  transition: 0s;
}

/deep/ .ant-table-tbody>tr.ant-table-row-selected>td {
  background: transparent;
  border-color: #fff;
}

/deep/ .ant-form-item-label>label {
  color: #fff;
}

/deep/ .ant-picker {
  background: transparent;
  border: 1px solid #0d53b7cc;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.6;
}

/deep/ .ant-input {
  background: transparent;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-form-item-has-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent;
  color: #fff;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 1px solid #0d53b7cc;
}

.content-o {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

/deep/ .ant-btn-primary {
  background: #0d53b7cc !important;
  color: #fff !important;
}

.ant-picker-ok {
  .ant-btn-sm {
    color: #000 !important;
  }
}

.btn1-o {}

.btn1-select-o {
  border-bottom: 1px solid #00fff4;
}

.btn2-o {
  border-radius: 2px;
  border: 1px solid #0d53b7cc;
  background: #07249966;
}

.btn3-o {
  background: #072499;
  padding: 3px 10px;
  cursor: pointer;
}

.img-ico {
  width: 20px;
  height: 20px;
}

.box1-o {
  border: 1px solid #ffffff66;
  border-radius: 2px;
  padding: 1px 4px;
}

.opt6 {
  opacity: 0.6;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

.search_wrap {
  width: 300px;
  box-sizing: border-box;
  margin: 20px 20px;
}

/deep/ .ant-input {
  border: none !important;
}
</style>
