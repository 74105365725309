<template>
  <div
    id="pdfCompany"
    class="p-4 tanchuang_bg"
    style="color: #fff;  border: 1px solid #0d53b7cc"
  >
    <div class="w-full flex">
      <div class="flex-1"></div>
      <div class="cursor-pointer" @click="cancel">
        <close-outlined style="font-size: 20px" />
      </div>
    </div>
    <div class="w-full flex items-center">
      <div class="text-center flex-1" style="font-size: 24px">{{langObj['检修记录']}}</div>
      <button  @click="handleDown()" nz-button nzType="text" class="ml-2">
          <download-outlined style="font-size: 18px;" />
      </button>
    </div>
    

    <div class="mt-5" style="font-size: 16px" v-if="repairForm.list?.length">{{langObj['故障信息']}}</div>

    <div class="box mt-4" style="width: 100%" v-if="repairForm.list?.length">
      <a-table
        style="width: 100%"
        :pagination="false"
        size="small"
        :row-class-name="getRowClassName"
        :columns="repairColumns"
        :data-source="repairForm.list"
      >
        <template #bodyCell="{ column, text }">
          <template v-if="column.dataIndex === 'condition'">
            {{langObj[conditionList.find((p) => p.value === text)?.label]  }}
          </template>
        </template>
      </a-table>
    </div>

    <div class="mt-10 mb-4" style="font-size: 16px">{{langObj['检修详情']}}</div>
    <a-descriptions
      :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }"
      :labelStyle="{ color: '#fff' }"
    >
      <a-descriptions-item :labelStyle="{ color: '#fff' }" :label="langObj['开始时间']">{{
        repairForm.begin
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['结束时间']">{{
        repairForm.end
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['设备名称']">{{
        repairForm.machineName
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['设备区域']">{{
        repairForm.groupName
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['检修人员']">{{
        repairForm.maintainPerson
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['现场设备状态']">{{

        langObj[conditionList.find((p) => p.value === repairForm.condition)?.label]
      }}</a-descriptions-item>
      <a-descriptions-item :label="langObj['故障原因']">{{
        repairForm.faultCause
      }}</a-descriptions-item>
    </a-descriptions>
    <div>{{langObj['检修内容']}}:</div>
    <div>{{ repairForm.maintainMessage }}</div>

    <div>{{langObj['检修照片']}}:</div>
    <div class="flex mt-4">
      <a-card  :bordered="false"  style=" background-color: rgb(2, 15, 70)">
        <a-card-grid  :bordered="false" style="width: 25%; text-align: center;padding: 5px; " v-for="(item, index) in repairForm.pictures" :key="index"
          ><a-image :src="item.url" /></a-card-grid
        >
      </a-card>
      <div >
       
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import request from "../../../common/request";
import { ref, reactive, toRaw } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getGroupName } from "../../../common/tools";
import { langList } from "../../../common/lang";
import htmlToPdf from '../../../common/htmlToPdf';
import { message } from 'ant-design-vue';
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
let repairForm: any = ref({
  list: [],
});
let accuracyList = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 2 },
  { label: "故障", value: 3 },
  { label: "不确定", value: 4 },
];
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];
let repairColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];

const show = async (id: any) => {
  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);
  if(!result?.data?.repairReport){
    return false;
  }

  if (result && result.data) {
    repairForm.value = {
      begin: result?.data?.repairReport?.begin,
      end: result?.data?.repairReport?.end,
      maintainMessage: result?.data?.repairReport?.maintainMessage,
      maintainPerson: result?.data?.repairReport?.maintainPerson,
      machineName: result.data.machines[0].machineName,
      accuracy: accuracyList.find(
        (p: any) => p.value === result?.data?.repairReport?.accuracy
      )?.label,
      list: result.data?.repairReport?.list,
      condition: result.data.repairReport.condition,
      faultCause: result.data.repairReport.faultCause,
      pictures: result.data.pictures,
    };
    if (result.data.machines.length) {
      repairForm.value.groupName = getGroupName(
        result.data.machines[0].groupId
      );
    }
  }
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

const cancel = () => {
  emits("cancel", true);
};

const emits = defineEmits(["cancel"]);

defineExpose({
  show,
});
const resultReport = ref<any>({});

// 下载pdf
let loadingDown = ref(false)
const handleDown = async() => {
    loadingDown.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    let result = await htmlToPdf.getPdf('检修报告', 'a4' );
    console.log('result', result)
    loadingDown.value = false
    if(result) {
        message.success('下载成功')
    } else {
        message.warning('下载失败，请重试')
    }

}

</script>
<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}
/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0px solid #fff;
}
/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}
</style>
