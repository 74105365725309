import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useTresContext } from '@tresjs/core'
import { useLoader } from '@tresjs/core'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';

import { Box3, Scene, Vector3 } from 'three'


export default /*@__PURE__*/_defineComponent({
  __name: 'd3d-model',
  props: {
    modelPath: { default: '' },
    modelSize: { default: 2 },
    cameraFov: { default: 15 }
  },
  emits: ['context-menu'],
  async setup(__props: any, { emit: __emit }) {

let __temp: any, __restore: any

let props = __props;

let emits = __emit

const { camera } = useTresContext()
const setModelSize = (sceneV: Scene | null) => {
  if (!sceneV) return;
  sceneV.updateMatrixWorld();
  const box = new Box3().setFromObject(sceneV);
  let vector3 = new Vector3();
  const size = box.getSize(vector3);
  const center = box.getCenter(vector3);
  // 计算缩放比例
  const maxSize = Math.max(size.x, size.y, size.z);

  let targetSize = props.modelSize; // 目标大小

  //const scale = targetSize / (maxSize > 1 ? maxSize : 0.5);
  const scale = targetSize / maxSize;
  sceneV.scale.set(scale, scale, scale);
  // 设置模型位置
  sceneV.position.sub(center.multiplyScalar(scale));
}

let model: any = (
  ([__temp,__restore] = _withAsyncContext(() => useLoader(GLTFLoader, props.modelPath))),
  __temp = await __temp,
  __restore(),
  __temp
)
let scene: Scene = model.scene;
setModelSize(scene)


// 设置相机位置
camera.value?.position.set(0, 20, 50);
(camera.value as any).fov = props.cameraFov;
(camera.value as any).far = 10000;
(camera.value as any).near = 0.1;
// 设置相机坐标系
(camera.value as any)?.updateProjectionMatrix();


let timer: any = null
function debounce(fn: any, delay = 1000) {
  if (timer != null) {
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(fn, delay)
}

const onMarkPoint = (ev: any) => {
  if (!ev) return;

  // 每次变更模型文件，都会导致多绑定一个右键事件（疑似tres的 bug）,所以这里做了一个防抖处理
  debounce(() => {
    emits('context-menu', ev)
  }, 200)
}


return (_ctx: any,_cache: any) => {
  const _component_primitive = _resolveComponent("primitive")!

  return (_ctx.modelPath && _unref(scene))
    ? (_openBlock(), _createBlock(_component_primitive, {
        key: 0,
        object: _unref(scene),
        onContextMenu: onMarkPoint
      }, null, 8, ["object"]))
    : _createCommentVNode("", true)
}
}

})