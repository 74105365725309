<template>
  <div id="pdfCompany" class="p-4 w-full tanchuang_bg" style="color: #fff; background-color: #020f46; border: 1px solid #0d53b7cc">
    <div class="w-full flex">
      <div class="flex-1"></div>
      <div class="cursor-pointer" @click="cancel">
        <close-outlined class="clsIco" style="font-size: 20px" />
      </div>
    </div>

    <div class="text-center w-full" style="font-size: 18px">
      {{ reportInfo.factoryName }} - {{ reportInfo.groupName }} -
      {{ reportInfo.machineName }} - {{ langObj['诊断报告'] }}
    </div>

    <div class="w-full flex items-center">
      <div class="text-center flex-1" style="font-size: 16px">
        {{ langObj['报告时间'] }}: {{ reportInfo.date }}
      </div>
      <div>
        <button  @click="handleDown()" nz-button nzType="text" class="ml-2">
              <download-outlined style="font-size: 18px;" />
          </button>
      </div>
    </div>
    

    <div class="mt-2">
      <div>1.{{ langObj['设备信息'] }}</div>
      <div>
        {{ langObj['诊断设备'] }}: {{ reportInfo.factoryName }} / {{ reportInfo.groupName }} /
        {{ reportInfo.machineName }} / {{ langObj['诊断报告'] }}
      </div>
    </div>

    <div class="mt-2">
      <div>2.{{ langObj['设备健康状态'] }}</div>
      <div v-if="reportInfo.expertReport" class="flex items-center">
        <div class="mr-1" style="width: 10px; height: 10px; border-radius: 50%" :style="{
        background: gradeList.find(
          (p) => p.value === reportInfo.expertReport.grade
        )?.color,
      }"></div>
        <span>{{
        langObj[gradeList.find((p) => p.value === reportInfo.expertReport.grade)
          ?.label]
      }}</span>
        <span>：</span>
        <span>{{
          langObj[gradeList.find((p) => p.value === reportInfo.expertReport.grade)?.dec]
          
        }}</span>
      </div>
    </div>

    <div class="mt-2">
      <div>3.{{ langObj['诊断结果'] }}</div>
      <div class="mt-2 w-full" style="border: 1px solid #465488">
        <div class="w-full flex items-center p-2">
          <div style="width: 10%" class="px-2">{{ langObj['故障模式'] }}</div>
          <div style="width: 25%" class="px-2">{{ langObj['诊断结论'] }}</div>
          <div style="width: 20%" class="px-2">{{ langObj['信号分析'] }}</div>
          <div style="width: 25%" class="px-2">{{ langObj['故障原因'] }}</div>
          <div style="width: 20%" class="px-2">{{ langObj['建议及措施'] }}</div>
        </div>
        <div v-if="reportInfo.expertReport && reportInfo.expertReport?.diagnosisDetails
        ">
          <div style="border-top: 1px solid #465488" v-for="(item, index) in reportInfo.expertReport.diagnosisDetails"
            :key="index" class="w-full flex items-center p-2">
            <div style="width: 10%" class="px-2">{{ langObj[item.faultEvent] || item.faultEvent }}</div>
            <div style="width: 25%" class="px-2">
              {{ item.diagnoseMessage }}
            </div>
            <div style="width: 20%" class="px-2">
              {{ item.signalAnalysis }}
            </div>
            <div style="width: 25%" class="px-2">
              <div v-for="(cause, i) in item.faultCauses" :key="i">
                {{ cause }}
              </div>
            </div>
            <div style="width: 20%" class="px-2">
              <div v-for="(suggest, i) in item.faultSuggests" :key="i">
                {{ suggest }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 w-full" v-if="reportInfo.expertReport && reportInfo.expertReport?.diagnosisDetails">
      <div v-for="(item, index) in reportInfo.expertReport.diagnosisDetails" :key="index">
        <div v-for="(t, i) in item.trends" :key="i">
          <div :id="'chart' + index + i" style="margin-top: 10px; width: 100%; height: 200px"></div>
        </div>
      </div>
    </div>

    <div class="mt-2 flex" v-show="isShowChart">
      <div class="flex-1 bor1">
        <div class="w-full text-center" style="font-size: 12px">{{langObj['幅值谱图']}}</div>
        <div id="chartDD1" class="h-56 w-full"></div>
      </div>
      <div style="width: 3%"></div>
      <div class="flex-1 bor1">
        <div class="w-full text-center" style="font-size: 12px">{{langObj['包络谱图']}}</div>
        <div id="chartDD2" class="h-56 w-full"></div>
      </div>
    </div>

    <div class="mt-2 w-full" v-if="reportInfo.pictures && reportInfo.pictures.length">
      <div>4.{{ langObj['图片'] }}</div>
      <div class="w-full">
        <div style="width: 50%" v-for="(item, index) in reportInfo.pictures" :key="index">
          <div class="w-full text-center">{{ item.remark }}</div>
          <a-image class="w-full" :src="item.url" :alt="item.remark" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import request from "../../../common/request";
import { ref, reactive, toRaw } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getGroupName, getFactoryName } from "../../../common/tools";
import * as echarts from "echarts";
import { langList } from "../../../common/lang";
import htmlToPdf from '../../../common/htmlToPdf';
import { message } from 'ant-design-vue';
let reportInfo: any = ref({});
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
let gradeList = [
  { value: -1, label: "健康", color: "#61c08f", dec: "无需操作" },
  {
    value: 0,
    label: "可用",
    color: "#d2de49",
    dec: "发现异常特征，按照巡查计划现场查看设备状态",
  },
  {
    value: 1,
    label: "警戒",
    color: "#cd5f3b",
    dec: "发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划，适时维护设备",
  },
  {
    value: 2,
    label: "故障",
    color: "#921e37",
    dec: "设备故障严重，建议停机维修",
  },
];

let isShowChart = ref(false)

const show = async (id: any) => {
  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  if (result && result.data) {
    reportInfo.value = result.data;

    if (reportInfo.value.machines && reportInfo.value.machines.length) {
      reportInfo.value.factoryName = getFactoryName(
        reportInfo.value.machines[0].factoryId
      );
      reportInfo.value.groupName = getGroupName(
        reportInfo.value.machines[0].groupId
      );
      reportInfo.value.machineName = reportInfo.value.machines[0].machineName;

      reportInfo.value.expertReport?.diagnosisDetails &&
        reportInfo.value.expertReport?.diagnosisDetails.map((d: any) => {
          d.faultCauses = d.faultCause
            ? d.faultCause.replace("\r\n", "\n").split("\n")
            : [];
          d.faultSuggests = d.faultSuggest
            ? d.faultSuggest.replace("\r\n", "\n").split("\n")
            : [];
        });

      if(reportInfo.value?.expertReport?.sensorId) {
        getChartData()
      } else {
        isShowChart.value = false
      }

      setTimeout(() => {
        reportInfo.value.expertReport &&
          reportInfo.value.expertReport?.diagnosisDetails.map(
            (d: any, index: any) => {
              d.trends &&
                d.trends.map((item: any, i: any) => {
                  initChart(item, "chart" + index + i);
                });
            }
          );
      }, 1000);
    }
  }
};

const getChartData = async() => {
  let config: any = {
    params: {
      sensorId: reportInfo.value.expertReport.sensorId,
      date: reportInfo.value.date,
      type: 'HZ'
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/external/singles/info', config)
//   result.data =  {
//     url:  
// "https://source.freqx.com/singles/66260e2b6f2b8fa334bc48f6/20240708/null_662616946f2b8fa334bc4915_HZ_20240708_060000.Single"
//   }
  if(result?.data?.url) {
    isShowChart.value = true
    let fftResult = await request.post('/draw/api/fft', {url: [result.data.url]})
    let hhtResult = await request.post('/draw/api/hht', {url: [result.data.url]})
    if(fftResult?.data) {
      initChartPT(fftResult.data.axisX, fftResult.data.axisY, 'chartDD1')
    }
    if(hhtResult?.data) {
      initChartPT(hhtResult.data.axisX, hhtResult.data.axisY, 'chartDD2')
    }
  } else {
    isShowChart.value = false
  }
}

const initChartPT = (xData:any, yData:any, id:any) => {
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById(id));

  const option = {
    title: {
      left: "center",
      textStyle: {
        fontSize: 12,
        color: theme.value=='white'? "#333": "#ffffff",
      },
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: '6%',
      left: "6%",
      right: "6%",
      bottom: "6%",
      containLabel: true,
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: yData,
      },
    ],
  };
  myChart.setOption(option);

}

const initChart = (data: any, id: any) => {
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById(id));

  let markList: any[] = [];

  data.markLine?.data?.forEach((d: any) => {
    if (d.name == '开') {
      markList.push({
        name: langObj.value['开'],
        xAxis: d.xAxis,
        label: { show: true,  color: theme.value=='white'? "#333": "#ffffff", formatter: '{b}' },
        lineStyle: { color: 'green' },
      });
    }
    if (d.name == '关') {
      markList.push({
        name: langObj.value['关'],
        xAxis: d.xAxis,
        label: { show: true,  color: theme.value=='white'? "#333": "#ffffff", formatter: '{b}' },
        lineStyle: { color: 'rgb(173, 38, 64)' },
      });
    }
  });

  let titleT = ref('')
  if(!data.title) {data.title = ''}
  if (data.title.indexOf(":") != -1) {
    let titleArr = data.title.split(":")
    let title1 = langObj.value[titleArr[0]] || titleArr[0];
    let title2 = langObj.value[titleArr[1]] || titleArr[1];
    titleT.value = title1 + ":" + title2;
  } else if (data.title.indexOf("：") != -1) {
    let titleArr = data.title.split("：")
    let title1 = langObj.value[titleArr[0]] || titleArr[0];
    let title2 = langObj.value[titleArr[1]] || titleArr[1];
    titleT.value = title1 + ":" + title2;
  }


  const option = {
    title: {
      left: "center",
      textStyle: {
        fontSize: 12,
        color: theme.value=='white'? "#333": "#ffffff",
      },
      text: titleT.value,
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: "20%",
      left: "6%",
      right: "6%",
      bottom: "6%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data.date,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: data.value,
        markLine: {
          data: markList,
          silent: true,
        },
      },
    ],
  };
  myChart.setOption(option);
};

const cancel = () => {
  emits("cancel", true);
};

const emits = defineEmits(["cancel"]);

defineExpose({
  show,
});
const resultReport = ref<any>({});

// 下载pdf
let loadingDown = ref(false)
const handleDown = async() => {
    loadingDown.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    let result = await htmlToPdf.getPdf('诊断报告', 'a4' );
    console.log('result', result)
    loadingDown.value = false
    if(result) {
        message.success('下载成功')
    } else {
        message.warning('下载失败，请重试')
    }

}


</script>
<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

.clsIco {
  svg {
    color: #fff !important;
  }
}
</style>
