<template>
  <div class="chat-container text-primary">
    <chat-messages :messages="messages" />
    <div class="selection-area">
      <div class="mb-2">
        请选择下方设备测点波形数据后，进行提问：
        <!--
        {{ currentSelection }}
        -->
      </div>
      <a-row class="selection-controls">
        <a-col :span="6">
          <a-select v-model:value="selectedGroupId" placeholder="选择区域" @change="onGroupChange">
            <a-select-option v-for="group in groupOptions" :key="group.id" :value="group.id">
              {{ group.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-select v-model:value="selectedMachineId" placeholder="选择设备" @change="onMachineChange">
            <a-select-option v-for="machine in machineOptions" :key="machine.id" :value="machine.id">
              {{ machine.machineName }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-select v-model:value="selectedSensorId" placeholder="选择测点" @change="onSensorChange">
            <a-select-option v-for="sensor in sensorOptions" :key="sensor.id" :value="sensor.id">
              {{ sensor.sensorPlace }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-select v-model:value="selectedSingleId" placeholder="选择波形数据" @change="onSingleChange">
            <a-select-option v-for="wave in singleOptions" :key="wave._id" :value="wave._id">
              {{ wave.date }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>
    <chat-input
                @send="onMessageSend"
                @new-chat="onNewChat"
                :disabled="!selectedSingleId" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import ChatInput from './chat-input.vue';
import ChatMessages from './chat-messages.vue';
import request from '@/common/request';
import { ChatMessage, ChatHistoryItem } from './chat.types';
import { getGroupListByFactory } from '@/common/tools';
import dayjs from "dayjs";
import emitter from "@/common/event-bus";

function debounce<T extends (...args: any[]) => any>(fn: T, delay: number) {
  let timeoutId: number | null = null;
  return function (this: any, ...args: Parameters<T>) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
      timeoutId = null;
    }, delay);
  };
}

let aiProfile = "您好，我是天机智能AI，请先选择设备数据后，向我提问。";

const props = defineProps<{
  chat?: ChatHistoryItem | null;
}>();

let currentChat = props.chat;

const emit = defineEmits(['update-chat', 'new-chat']);

const messages = ref<ChatMessage[]>([]);

const initMessages = async () => {
  messages.value = [
    { type: 'bot', content: aiProfile as string, time: null }
  ];
};

const handleChatHistoryChange = debounce(async (newChat: ChatHistoryItem | null) => {
  initMessages();

  if (!newChat) {
    // Handle case where chat history is cleared
    messages.value = [];
    return;
  }

  if (newChat?.id !== currentChat?.id) {
    // Handle chat history change, e.g., load messages for the new chat
    // Switched chat;

    currentChat = newChat;

    if (!newChat.chatId) {
      initMessages();
      return;
    }

    let res = await request.get(`/api/p-chat/app/chat/${newChat.chatId}/chat_record/`)
    if (res?.data?.length) {
      let array: ChatMessage[] = [];
      array.push({ type: 'bot', content: aiProfile as string, time: null })
      for (let item of res.data) {
        array.push({ type: 'user', content: item.problem_text as string, time: new Date(item.create_time) })
        array.push({ type: 'bot', content: item.answer_text as string, time: new Date(item.update_time) })
        messages.value = array
      }
    } else {
      initMessages()
    }
  }
}, 300);

watch(() => props.chat, (newItem) => {
  handleChatHistoryChange(newItem as ChatHistoryItem);
}, { deep: true });

const onNewChat = async () => {
  initMessages();
  //emit('new-chat');
};

const onMessageSend = async (messageContent: string) => {

  messageContent = messageContent?.trim();
  if (!messageContent) {
    return;
  }

  const sentMessage: ChatMessage = {
    type: 'user',
    content: messageContent,
    time: new Date()
  };

  messages.value.push(sentMessage);

  let chatId = currentChat?.chatId
  let isNewChat = false;
  if (!chatId) {
    // 新对话
    let res = await request.get('/api/p-chat/app/chat/open');
    if (!res?.data) {
      console.error('无法创建新对话');
      return;
    }
    chatId = res.data
    isNewChat = true;
  }

  const response = await request.post(`/api/p-chat/chat_message/${chatId}`, {
    message: messageContent,
    re_chat: false
  }, { responseType: 'stream', adapter: 'fetch' });

  const reader = response.data.getReader();
  const decoder = new TextDecoder();

  let awnser = '';

  const startTime = Date.now();
  const timeoutLimit = 2 * 60 * 1000; // in milliseconds

  while (true) {
    if (Date.now() - startTime > timeoutLimit) {
      console.warn('Response streaming timed out after 3 minutes');
      break;
    }

    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value, { stream: true });
    const lines = chunk.split('\n').filter(Boolean);

    for (const line of lines) {
      try {
        const tempData = JSON.parse(line.replace("data:", ""));
        awnser += tempData.content;
        //console.log("stream", tempData);
      } catch (error) {
        console.error('Error parsing update:', error);
      }
    }
  }

  const awnserMessage: ChatMessage = {
    type: 'bot',
    content: awnser,
    time: new Date()
  };

  messages.value.push(awnserMessage);

  if (isNewChat) {
    // 新建对话历史记录
    let abstract = messageContent;
    if (abstract.length > 10) {
      abstract = abstract.substring(0, 10) + "..."
    }
    let data = {
      chatId: chatId,
      abstract: abstract
    };
    request.post(`/api/chat`, data).then((res) => {
      if (currentChat && res.data) {
        currentChat.abstract = abstract
        currentChat.id = res.data.id!;
        currentChat.chatId = chatId!;
        emit('update-chat', currentChat);
      }
    })
  }
};

let machines: any[] = []
let sensors: any[] = []

const groupOptions = ref<any[]>([]);
const machineOptions = ref<any[]>([]);
const sensorOptions = ref<any[]>([]);
const singleOptions = ref<any[]>([]);

const selectedGroupId = ref(null);
const selectedMachineId = ref(null);
const selectedSensorId = ref(null);
const selectedSingleId = ref<any>(null);
let selectedSingleUrl = ref<string>('');

const currentSelection = computed(() => {
  const selections = [];
  if (selectedGroupId.value) selections.push(groupOptions.value.find(a => a.id === selectedGroupId.value)?.name);
  if (selectedMachineId.value) selections.push(machineOptions.value.find(d => d.id === selectedMachineId.value)?.machineName);
  if (selectedSensorId.value) selections.push(sensorOptions.value.find(p => p.id === selectedSensorId.value)?.sensorPlace);
  if (selectedSingleId.value) selections.push(singleOptions.value.find(w => w._id === selectedSingleId.value)?.date);
  return selectedSingleId.value ? selections.join(' / ') : '未选择';
});

const onGroupChange = async (groupId: string) => {
  selectedMachineId.value = null;
  selectedSensorId.value = null;
  selectedSingleId.value = null;

  machineOptions.value = machines.filter(m => m.groupId === groupId)
};

const onMachineChange = async (machineId: string) => {
  selectedSensorId.value = null;
  selectedSingleId.value = null;
  sensorOptions.value = sensors.filter(s => s.machineId === machineId);
};

const onSensorChange = async (sensorId: string) => {
  let now = dayjs();

  selectedSingleId.value = null;

  let res = await request.get("/api/external/singles", {
    params: {
      machineId: selectedMachineId.value,
      sensorId: sensorId,
      begin: now.subtract(7, 'day').format('YYYY/MM/DD HH:mm:ss'),
      end: now.format("YYYY/MM/DD HH:mm:ss"),
      type: "HZ",
    }
  })

  if (res?.data) {
    singleOptions.value = res.data?.list || [];
  } else {
    singleOptions.value = [];
  }
};

const onSingleChange = async (singleId: string) => {
  selectedSingleUrl = singleOptions.value.find(w => w._id === singleId)?.url;
};

const loadBasicOptions = async () => {
  selectedGroupId.value = null;
  groupOptions.value = getGroupListByFactory();

  let machineRes = await request("/api/machines", { params: { factoryId: groupOptions.value[0].factoryId } });
  if (machineRes?.data) {
    machines = machineRes.data || [];
  } else {
    machines = [];
  }

  let sensorRes = await request.get("/api/sensors/search", { params: { factoryId: groupOptions.value[0].factoryId } });
  if (sensorRes?.data) {
    sensors = sensorRes.data || [];
  } else {
    sensors = [];
  }
}

onMounted(async () => {
  await loadBasicOptions();
  emitter.on('factoryChanged', async (fac) => {
    await loadBasicOptions();
  });

});

onUnmounted(() => {
  emitter.off('factoryChanged', (res) => {
    console.log("factoryChanged off", res);
  });
});
</script>

<style scoped lang="less">
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.selection-area {
  .selection-controls {
    .ant-col {
      padding: 0 8px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .ant-select {
      width: 100%;
    }
  }
}
</style>
