import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/screen/m_state_bg.png'
import _imports_1 from '@/assets/screen/m_run_bg.png'
import _imports_2 from '@/assets/screen/m_zd_bg.png'
import _imports_3 from '@/assets/screen/m_mx_bg.png'
import _imports_4 from '@/assets/zhenduan.png'
import _imports_5 from '@/assets/weixiu.png'
import _imports_6 from '@/assets/menxian.png'
import _imports_7 from '@/assets/jiancha.png'


const _hoisted_1 = {
  class: "w-full theme-bg theme-machine theme-common",
  style: {"background-color":"#000a31","color":"#fff","overflow-y":"hidden","height":"calc(100vh - 100px)"}
}
const _hoisted_2 = {
  class: "w-full mt-4 flex px-7 py-2",
  style: {"height":"100%","overflow-y":"hidden"}
}
const _hoisted_3 = { style: {"width":"12%"} }
const _hoisted_4 = {
  key: 0,
  class: "flex-1 ml-7",
  style: {"overflow-y":"scroll"}
}
const _hoisted_5 = { class: "center-tit-bg" }
const _hoisted_6 = { style: {"font-size":"24px"} }
const _hoisted_7 = { class: "w-full flex mt-2" }
const _hoisted_8 = {
  class: "mr-8",
  style: {"min-width":"476px","width":"20%","position":"relative","z-index":"1000"}
}
const _hoisted_9 = {
  class: "zd_wrap",
  style: {"width":"100%"}
}
const _hoisted_10 = { class: "title flex items-center" }
const _hoisted_11 = { class: "p-text-color2 ml-10" }
const _hoisted_12 = { class: "bg_wrap" }
const _hoisted_13 = { class: "w-full flex items-center justify-between whiteBg" }
const _hoisted_14 = { class: "w-full flex" }
const _hoisted_15 = {
  key: 0,
  id: "chartMachienB",
  style: {"width":"100%","height":"240px"}
}
const _hoisted_16 = {
  class: "zd_wrap mt-2",
  style: {"width":"100%"}
}
const _hoisted_17 = { class: "title flex items-center" }
const _hoisted_18 = { class: "p-text-color2 ml-10" }
const _hoisted_19 = { class: "w-full mt-2" }
const _hoisted_20 = { class: "title flex items-center" }
const _hoisted_21 = { class: "p-text-color2 ml-10" }
const _hoisted_22 = { class: "w-full flex bg_wrap" }
const _hoisted_23 = {
  class: "flex-1 flex flex-col",
  style: {"height":"100%"}
}
const _hoisted_24 = { class: "flex-1" }
const _hoisted_25 = {
  key: 0,
  id: "chartAM",
  style: {"height":"200px","width":"200px"}
}
const _hoisted_26 = { class: "w-full" }
const _hoisted_27 = { style: {"color":"#4efaee"} }
const _hoisted_28 = { class: "ml-4" }
const _hoisted_29 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_30 = { class: "ml-2" }
const _hoisted_31 = { class: "ml-2" }
const _hoisted_32 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_33 = { class: "ml-2 opt8" }
const _hoisted_34 = { class: "ml-2 opt8" }
const _hoisted_35 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_36 = { class: "ml-2" }
const _hoisted_37 = { class: "ml-2" }
const _hoisted_38 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_39 = { class: "ml-2 opt8" }
const _hoisted_40 = { class: "ml-2 opt8" }
const _hoisted_41 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_42 = { class: "ml-2" }
const _hoisted_43 = { class: "ml-2" }
const _hoisted_44 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_45 = { class: "ml-2 opt8" }
const _hoisted_46 = { class: "ml-2 opt8" }
const _hoisted_47 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_48 = { class: "ml-2" }
const _hoisted_49 = { class: "ml-2" }
const _hoisted_50 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_51 = { class: "ml-2 opt8" }
const _hoisted_52 = { class: "ml-2 opt8" }
const _hoisted_53 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_54 = { class: "ml-2" }
const _hoisted_55 = { class: "ml-2" }
const _hoisted_56 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_57 = { class: "ml-2 opt8" }
const _hoisted_58 = { class: "ml-2 opt8" }
const _hoisted_59 = { class: "flex-1" }
const _hoisted_60 = { class: "flex w-full mb-3 mt-4" }
const _hoisted_61 = { class: "flex items-center center_img_wrap" }
const _hoisted_62 = { class: "ml-2 center_text" }
const _hoisted_63 = { class: "flex items-center center_img_wrap" }
const _hoisted_64 = { class: "ml-2 center_text" }
const _hoisted_65 = { class: "flex items-center center_img_wrap" }
const _hoisted_66 = { class: "ml-2 center_text" }
const _hoisted_67 = {
  class: "w-full",
  style: {"font-size":"24px","color":"#0C79FF"}
}
const _hoisted_68 = { class: "flex items-center center_img_wrap" }
const _hoisted_69 = { class: "ml-2 center_text" }
const _hoisted_70 = {
  class: "w-full",
  style: {"font-size":"24px","color":"#2DCDFF"}
}
const _hoisted_71 = { class: "w-full flex mt-1" }
const _hoisted_72 = { class: "flex items-center mr-8" }
const _hoisted_73 = { class: "ml-2" }
const _hoisted_74 = { class: "ml-2" }
const _hoisted_75 = { class: "ml-2" }
const _hoisted_76 = { class: "ml-2" }
const _hoisted_77 = {
  key: 1,
  id: "yulan",
  style: {"margin":"auto","padding":"20px","width":"800px","height":"600px","position":"relative","top":"5%"}
}
const _hoisted_78 = ["src"]
const _hoisted_79 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_80 = { class: "w-full flex" }
const _hoisted_81 = { class: "w-full flex items-center" }
const _hoisted_82 = { style: {"width":"35%"} }
const _hoisted_83 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_84 = { style: {"width":"35%"} }
const _hoisted_85 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_86 = { style: {"width":"30%","word-wrap":"break-word"} }
const _hoisted_87 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_88 = { class: "w-full flex" }
const _hoisted_89 = {
  class: "mr-2",
  style: {"font-size":"12px","opacity":"0.6"}
}
const _hoisted_90 = {
  class: "ml-8",
  style: {"width":"20%","min-width":"350px"}
}
const _hoisted_91 = { class: "w-full mt-2" }
const _hoisted_92 = { class: "title flex items-center" }
const _hoisted_93 = { class: "p-text-color2 ml-10" }
const _hoisted_94 = {
  class: "bg_wrap",
  style: {"height":"auto"}
}
const _hoisted_95 = {
  key: 0,
  style: {"height":"180px"}
}
const _hoisted_96 = { class: "w-full flex flex-wrap mt-4" }
const _hoisted_97 = { class: "w-1/2 text-center" }
const _hoisted_98 = { style: {"opacity":"0.8"} }
const _hoisted_99 = { class: "w-1/2 text-center" }
const _hoisted_100 = { style: {"opacity":"0.8"} }
const _hoisted_101 = { class: "w-1/2 mt-2 text-center" }
const _hoisted_102 = { style: {"opacity":"0.8"} }
const _hoisted_103 = { class: "w-1/2 mt-2 text-center" }
const _hoisted_104 = { style: {"opacity":"0.8"} }
const _hoisted_105 = { class: "mt-2 w-full" }
const _hoisted_106 = { class: "title flex items-center" }
const _hoisted_107 = { class: "p-text-color2 ml-10" }
const _hoisted_108 = { class: "text-small" }
const _hoisted_109 = {
  class: "bg_wrap",
  style: {"height":"auto"}
}
const _hoisted_110 = { class: "mt-2 w-full flex whiteBg" }
const _hoisted_111 = { class: "mt-2 w-full mb-4" }
const _hoisted_112 = { class: "w-full flex items-center" }
const _hoisted_113 = {
  key: 0,
  style: {"width":"18px","height":"18px"},
  src: _imports_4,
  alt: ""
}
const _hoisted_114 = {
  key: 1,
  style: {"width":"18px","height":"18px"},
  src: _imports_5,
  alt: ""
}
const _hoisted_115 = {
  key: 2,
  style: {"width":"18px","height":"18px"},
  src: _imports_6,
  alt: ""
}
const _hoisted_116 = {
  key: 4,
  class: "ml-2"
}
const _hoisted_117 = {
  key: 5,
  class: "ml-2"
}
const _hoisted_118 = { class: "mt-2" }
const _hoisted_119 = {
  class: "mt-2 opt6",
  style: {"font-size":"12px"}
}
const _hoisted_120 = {
  key: 1,
  class: "flex-1 ml-7",
  style: {"height":"98%","overflow-y":"scroll"}
}
const _hoisted_121 = { class: "w-full flex items-center" }
const _hoisted_122 = { style: {"font-size":"24px"} }
const _hoisted_123 = { class: "w-full flex" }
const _hoisted_124 = {
  class: "w-full flex items-center",
  style: {"border-bottom":"1px solid rgba(62, 73, 114, 1)"}
}
const _hoisted_125 = { class: "ml-3" }
const _hoisted_126 = { class: "mt-2 w-full flex items-center" }
const _hoisted_127 = { class: "pic" }
const _hoisted_128 = ["src"]
const _hoisted_129 = { class: "flex-1 ml-4" }
const _hoisted_130 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_131 = { class: "text-gray-50 pl-2" }
const _hoisted_132 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_133 = { class: "text-gray-50 pl-2" }
const _hoisted_134 = { class: "flex items-center mt-2" }
const _hoisted_135 = { class: "text-gray-50 pl-2" }
const _hoisted_136 = { class: "flex-1 flex" }
const _hoisted_137 = { style: {"width":"80%","display":"flex","justify-content":"center","position":"relative"} }
const _hoisted_138 = {
  key: 0,
  id: "yulan",
  style: {"margin":"auto","margin-top":"6%","padding":"20px","width":"1000px","height":"800px","position":"relative"}
}
const _hoisted_139 = ["src"]
const _hoisted_140 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_141 = { class: "w-full flex" }
const _hoisted_142 = { style: {"font-size":"18px"} }
const _hoisted_143 = { style: {"color":"#fff"} }
const _hoisted_144 = { class: "w-full flex" }
const _hoisted_145 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_146 = { class: "flex items-center" }
const _hoisted_147 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_148 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_149 = { key: 0 }
const _hoisted_150 = { key: 1 }
const _hoisted_151 = { key: 2 }
const _hoisted_152 = { key: 3 }
const _hoisted_153 = { class: "ml-2" }
const _hoisted_154 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_155 = { class: "ml-2" }
const _hoisted_156 = { key: 0 }
const _hoisted_157 = { key: 1 }
const _hoisted_158 = ["onClick"]
const _hoisted_159 = ["onClick"]
const _hoisted_160 = ["onClick"]
const _hoisted_161 = { style: {"color":"#fff"} }
const _hoisted_162 = { class: "w-full flex" }
const _hoisted_163 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_164 = { class: "flex items-center" }
const _hoisted_165 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_166 = { key: 0 }
const _hoisted_167 = ["onClick"]
const _hoisted_168 = { style: {"color":"#fff"} }
const _hoisted_169 = { class: "w-full flex" }
const _hoisted_170 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}

import { ref,computed,component  } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import { langList } from "../../common/lang";
import {
  transformDate,
  transformDate2,
  getGroupName,
  getRealFactoryIdList ,
  getGroupListByFactory,
  getFactoryName,
} from "../../common/tools";
import * as echarts from "echarts";
import D3dViewer from "../components/d3d-viewer.vue";
import { getLanguage } from "../../common/translate"


export default /*@__PURE__*/_defineComponent({
  __name: 'machine',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
  console.log(theme.value)
}
getLang()

const router = useRouter();
let machine_id = "";
let machineInfo: any = ref({});

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7" },
  { label: "可用", value: [1, 2], color: "#d2de49", color2: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b", color2: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37", color2: "#921e37" },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

// let conditionLists = [
//     {label: '健康', value: 0, color: '#61c08f'},
//     {label: '可用', value: 1, color: '#d2de49'},
//     {label: '可用', value: 2, color: '#d2de49'},
//     {label: '警戒', value: 3, color: '#cd5f3b'},
//     {label: '警戒', value: 4, color: '#cd5f3b'},
//     {label: '警戒', value: 5, color: '#cd5f3b'},
//     {label: '故障', value: 6, color: '#921e37'},
//     {label: '故障', value: 7, color: '#921e37'},
// ]
let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "停机", value: false, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
  { label: "运行", value: true, color: "#4EFAEE" },
];

let selectType: any = ref(1)

const selectMachine = (ev: any) => {
  selectType.value = 1
  machine_id = ev;
  selecrRandar.value = 2;
  picListAll.value = [];
  picList.value = [];
  init();
};
let  groupList = ref([])
let machineListZdWarn = ref([])

const init = () => {
  
  getMachineInfo();
  getMachineStatusTime(1);
  getMachineWarning();
  getMachineWarningZnZd()
  groupList.value = getGroupListByFactory()
};

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    machineInfo.value.status = statusList[0];
    if (machineInfo.value &&
      machineInfo.value.workStatus) {
      let status = statusList.find(
        (p: any) => p.value === (machineInfo.value.workStatus.quotaWkStatus ?? machineInfo.value.workStatus.scoreWkStatus ?? 0)
      );
      status && (machineInfo.value.status = status)
    }
    load3D();
  }

  if (machineInfo.value.pictures?.length > 0) {
    // picList.value.push({
    //   name: "设备",
    //   url: machineInfo.value.pictures[0].url,
    // });
    machineInfo.value.pictures.map((d: any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getRandarChartData();
  getScore(1)
  // initScoreChart()
  // getRunTime()

};

let picList: any = ref([]);
let picListAll: any = ref([]);
const getSensorList = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures) {
        d.pictures.map((p: any) => {
          picList.value.push({
            name: d.sensorPlace,
            url: p.url
          })
        })
      }
    });
  }

  picListAll.value = picList.value;
};

// 获取设备状态占比
let satusTimeDate: any = ref(1);
let visibleChart = ref(true);
let conditionTotal: any = ref({
  condition1Date: "--",
  condition2Date: "--",
  condition3Date: "--",
  condition4Date: "--",
  condition1Rate: 0,
  condition2Rate: 0,
  condition3Rate: 0,
  condition4Rate: 0,
  wkStatus1Date: "--",
  wkStatus2Date: "--",
  wkStatus1Rate: 0,
  wkStatus2Rate: 0,
});
let machineStatusTime = ref(1);
const getMachineStatusTime = async (dateType:any) => {
  machineStatusTime.value = dateType;
  conditionTotal.value = {
    condition1Date: "--",
    condition2Date: "--",
    condition3Date: "--",
    condition4Date: "--",
    condition1Rate: 0,
    condition2Rate: 0,
    condition3Rate: 0,
    condition4Rate: 0,
    wkStatus1Date: "--",
    wkStatus2Date: "--",
    wkStatus1Rate: 0,
    wkStatus2Rate: 0,
  };
  let time = new Date();
  let endDate = transformDate2(time, 1);


  // satusTimeDate.value = 3;
  // //本年
  // if (satusTimeDate.value === 3) {
  //   startDate = `${year}/01/01 00:00:00`;
  // }
  //本月
  let num = dateType === 2 ? 30 : 7

  let startTime = time.getTime() - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let stableSearch = {
    params: {
      begin: startDate,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result2 = await request.get(
    "/api/machines/workstatus/stables",
    stableSearch
  );
  let stableList: any = [];
  if (result2 && result2.data && result2.data.length) {
    stableList = result2.data;
  }

  let beginT = new Date(startDate).getTime();
  let endT = new Date(endDate).getTime();

  let condition0 = 0; //无
  let condition1 = 0; //健康
  let condition2 = 0; //可用
  let condition3 = 0; //警戒
  let condition4 = 0; //故障
  let conditionT = endT - beginT;
  if (stableList?.length > 0) {
    stableList.map((d: any, index: any) => {
      if (index == 0) {
        condition0 = new Date(d.date).getTime() - beginT;
      } else {
        let time =
          new Date(d.date).getTime() -
          new Date(stableList[index - 1].date).getTime();

        if (d.condition === 0) {
          condition1 = condition1 + time;
        }
        if (d.condition >= 1 && d.condition <= 2) {
          condition2 = condition2 + time;
        }
        if (d.condition >= 3 && d.condition <= 5) {
          condition3 = condition3 + time;
        }
        if (d.condition >= 6 && d.condition <= 7) {
          condition4 = condition4 + time;
        }
      }

      if (index === stableList.length - 1) {
        condition0 = condition0 + (endT - new Date(d.date).getTime());
      }
    });
  } else {
    condition1 = 0
    condition2 = 0
    condition3 = 0
    condition4 = 0
    condition0 = conditionT
  }


  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
  let wkStatus1 = 0; //运行
  let wkStatus2 = 0; //停机
  let dateWK1 = 0
  if (result4 && result4.data) {
    result4.data.map((d: any) => {
      dateWK1 = dateWK1 + Math.round(d.online / 3600)
    })
  }
  wkStatus1 = dateWK1 * 60 * 60 * 1000
  wkStatus2 = conditionT - wkStatus1

  function convertTimestamp(timestamp: any) {
    var milliseconds = timestamp;
    var date: any = new Date(milliseconds);

    var days = Math.floor(date / (24 * 60 * 60 * 1000));
    var hours = Math.floor((date % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    var minutes = Math.floor((date % (60 * 60 * 1000)) / (60 * 1000));
    return days + getLanguage("天", language.value) + ' ' + hours + getLanguage("时", language.value) + ' ' + minutes + getLanguage("分", language.value);
  }

  conditionTotal.value = {
    condition1Date: convertTimestamp(condition1),
    condition2Date: convertTimestamp(condition2),
    condition3Date: convertTimestamp(condition3),
    condition4Date: convertTimestamp(condition4),
    condition0Rate: Number(((condition0 / conditionT) * 100).toFixed(0)),
    condition1Rate: Number(((condition1 / conditionT) * 100).toFixed(0)),
    condition2Rate: Number(((condition2 / conditionT) * 100).toFixed(0)),
    condition3Rate: Number(((condition3 / conditionT) * 100).toFixed(0)),
    condition4Rate: Number(((condition4 / conditionT) * 100).toFixed(0)),
    wkStatus1Rate: Number(((wkStatus1 / conditionT) * 100).toFixed(0)),
    wkStatus2Rate: Number(
      (((wkStatus2) / conditionT) * 100).toFixed(0)
    ),
    wkStatus1Date: convertTimestamp(wkStatus1),
    wkStatus2Date: convertTimestamp(wkStatus2),
    wkStatsu: convertTimestamp(wkStatus1)
  };

  visibleChart.value = false;
  visibleChart.value = true;

  setTimeout(() => {
    initMacineStatusRateChart();
  }, 500);
};

//设备报警趋势弹窗
let typeList = [
  { label: "智能诊断", value: 1 },
  {label: '检修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let handleStatusList:any=ref([
    { label: '未处理', value: 2 },
    { label: '已处理', value: 3 },
])

let warnTrendVisible = ref(false);
let warnTrendData = ref([])
let warnTrendSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    typeList:[1,4]
})
const paginationWarnTrend = computed(() => ({
  total: warnTrendSearch.value.total,
  current: warnTrendSearch.value.skip,
  pageSize: warnTrendSearch.value.take,
}));
 

const warnTrendColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];

const viewWarnMore = () => {
  if(warningType.value === 1) {
    selectWarnTrend()
  }
  if(warningType.value === 2) {
    selectMaintenanceTrend()
  }
}


const selectWarnTrend = () => {
    warnTrendVisible.value = true
    resetWarnTrend()
    if(warningType.value==1){
      warnTrendSearch.value.typeList = [1,4]
    }else{
      warnTrendSearch.value.typeList = [2]
    }
    warnTrendSearch.value.groupId = machineInfo.value.groupId;
    warnTrendSearch.value.machineId = machine_id
    if(warnTrendSearch.value.groupId) {
      let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '{}')
      machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === machineInfo.value.groupId)
    }
    getWarnTrendData()
}  
const filterOptionZdReport = (input: string, option: any) => {
    return option.name.indexOf(input) >= 0;
};

// 选择区域
const selectGroupWarnTrend = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    warnTrendSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getWarnTrendData = async () => {
   

  let config: any = {
    params: {
      skip: warnTrendSearch.value.skip,
      take: warnTrendSearch.value.take,
      groupId: warnTrendSearch.value.groupId,
      machineId: warnTrendSearch.value.machineId,
      typeList:warnTrendSearch.value.typeList,
      handleStatus: warnTrendSearch.value.handleStatus,
      expertCertification: warnTrendSearch.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (warnTrendSearch.value.date && warnTrendSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnTrendSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(warnTrendSearch.value.date[1]), 2);
  }
   
  if (warnTrendSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == warnTrendSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warnTrendData.value = result.data.list;
    warnTrendSearch.value.total = result.data.total;
  }
};

const resetWarnTrend = () => {
    warnTrendSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        typeList:[1,4]
    }
    // getWarnTrendData()
}
const handleTableChangeWarnTrend = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    warnTrendSearch.value.sort = sorter.field;
    warnTrendSearch.value.order = order;
  }
  // 处理分页数据
  warnTrendSearch.value.skip = pagination.current;
  warnTrendSearch.value.take = pagination.pageSize;

  getWarnTrendData();
};

// 检修记录弹窗
let maintenanceSearch:any = ref({
  skip: 1,
  take: 10,
  total: 0,
  groupId: null,
  machineId: null,
  date: null,
  visible: false,
  keyword: '',
})
const maintenancePagination = computed(() => ({
    total: maintenanceSearch.value.total,
    current: maintenanceSearch.value.skip,
    pageSize: maintenanceSearch.value.take,
}));
let machineListMaintenance:any = ref([])
let maintenanceList:any = ref([])
const maintenanceColumns = [
    {
        title: langObj.value['报告编号'],
        dataIndex: 'id',
    },
    {
        title: getLanguage('项目名称'),
        dataIndex: 'factoryId',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['检修内容'],
        dataIndex: 'maintainMessage',
    },
    {
        title: langObj.value['提交时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['检修人'],
        dataIndex: 'maintainPerson',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]
const selectMaintenanceTrend = () => {
  maintenanceSearch.value.visible = true
  maintenanceSearch.value.groupId = machineInfo.value.groupId;
  maintenanceSearch.value.machineId = machine_id
  if(maintenanceSearch.value.groupId) {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '{}')
    machineListMaintenance.value = memberInfo.machines.filter((p:any) => p.groupId === machineInfo.value.groupId)
  }
  getMaintenanceList()
}

const resetMaintenance = () => {
  maintenanceSearch.value = {
    ... maintenanceSearch.value,
    groupId: null,
    machineId: null,
    date: null,
    keyword: '',
  }
}

const handleTableChangeMaintenance = (pagination: any) => {
  maintenanceSearch.value.skip = pagination.current
  maintenanceSearch.value.take = pagination.pageSize
  getMaintenanceList();
}

const getMaintenanceList = async() => {
  let config: any = {
      params: {
        skip: maintenanceSearch.value.skip,
        take: maintenanceSearch.value.take,
        type: [2],
        keyword: maintenanceSearch.value.keyword,
        groupId: maintenanceSearch.value.groupId,
        machineId: maintenanceSearch.value.machineId,
        factoryIds: getRealFactoryIdList(),
      },
      headers: {
          requestId: uuidv4(),
      },
  };

  if (maintenanceSearch.value.date && maintenanceSearch.value.date.length === 2) {
      config.params.begin = transformDate2(new Date(maintenanceSearch.value.date[0]), 1);
      config.params.end = transformDate2(new Date(maintenanceSearch.value.date[1]), 2);
  }

  let result = await request.get('/api/external/reports', config)
  if (result && result.status === 200) {
      result.data.list.forEach((element: any) => {
          if (element.machines && element.machines[0]) {
              element.machineName = element.machines[0].machineName;
              element.groupName = getGroupName(element.machines[0]?.groupId)
          }

          if (element.repairReport) {
              element.disposition = element.repairReport.disposition
              element.maintainMessage = element.repairReport.maintainMessage
              element.maintainPerson = element.repairReport.maintainPerson
          }
      })
      maintenanceList.value = result.data.list
      maintenanceSearch.value.total = result.data.count
    }


}


const selectGroupMaintenance = (ev:any) => {
  let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
  maintenanceSearch.value.machineId = ''
  if(ev) {
    machineListMaintenance.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
  } else {
    machineListMaintenance.value = []
  }
}

const detailsMaintenance = (ev: any) => {
    // console.log(ev)
    // maintenanceSearch.value.visible2 = true
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.id)
    }, 1000);
}

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}


//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};



//获取当前设备从创建到此时的运行时间
const getRunTime = async() => {
 
  let endT = new Date().getTime();
  let endDate = transformDate2(endT, 1)
  let stableSearch = {
    params: {
      begin: machineInfo.value.createdAt,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
}

//绘制设备状态占比图
const initMacineStatusRateChart = () => {
  const ec = echarts as any;
  if (!document.getElementById("chartAM")) return;
  let myChart = ec.init(document.getElementById("chartAM"));

  const data = [
    {
      name: "外层饼图",
      children: [
        { value: conditionTotal.value.condition1Rate, name: "健康" },
        { value: conditionTotal.value.condition2Rate, name: "可用" },
        { value: conditionTotal.value.condition3Rate, name: "警戒" },
        { value: conditionTotal.value.condition4Rate, name: "故障" },
        { value: conditionTotal.value.condition0Rate, name: "停机" },
      ],
    },
    {
      name: "内层饼图",
      children: [
        { value: conditionTotal.value.wkStatus1Rate, name: "运行" },
        { value: conditionTotal.value.wkStatus2Rate, name: "停机" },
      ],
    },
  ];

  // 配置选项
  const option = {
    color: ["#1CC48B", "#DDD246", "#CB6A34", "#B81212", "#ADADB0"],
    grid: {
      right: "0%",
      left: "0%",
      top: 0,
      bottom: 0,
    },
    series: [
      {
        name: "外层饼图",
        type: "pie",
        radius: ["70%", "100%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[0].children,
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
      {
        name: "内层饼图",
        type: "pie",
        radius: ["40%", "70%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[1].children,
        color: ["#4DFAED", "#ADADB0"],
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
    ],
  };

  // 使用配置项显示图表
  myChart.setOption(option);
};

// 获取雷达图数据
let selecrRandar = ref(2); //1健康  2振动
let visibleChart2 = ref(true);
const getRandarChartData = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/faultnames", faultnamesSearch);
  let faultName = result.data.faultNames;
  let faultNameII = result.data.faultNameExpects;

  let Slist: any = [];
  let Tlist: any = [];

  if (
    machineInfo.value.workStatus &&
    machineInfo.value.workStatus.stableScores
  ) {
    for (let index in machineInfo.value.workStatus.stableScores) {
      const item = machineInfo.value.workStatus.stableScores[index];
      if (index.includes("S")) {
        Slist.push({
          quota: index,
          score: item,
          text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title,
        });
      }

      if (index.includes("T")) {
        Tlist.push({
          quota: index,
          score: item,
          text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
        });
      }
    }
  }

  machineInfo.value.Tlist = Tlist;
  machineInfo.value.Slist = Slist;

  if (!Tlist && Slist) {
    selecrRandar.value = 1;
  } else {
    selecrRandar.value = 2;
  }

  

  visibleChart2.value = false;
  visibleChart2.value = true;

  setTimeout(() => {
    if (selecrRandar.value === 1) {
      initRandarChart(Slist);
    }
    if (selecrRandar.value === 2) {
      initRandarChart(Tlist);
    }
  }, 500);
};

// 绘制雷达图
const initRandarChart = (dataList: any) => {
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  if (!dataList.length) return;
  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = theme.value == 'white' ? '#333' : "#fff";
    }

    let name = ''
    if (language.value == "Chinese" && d.text) {
      if (d.text.length > 8) {
        name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
      } else if (d.text.length > 4 && d.text.length <= 8) {
        name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
      } else {
        name = d.text
      }
    }

    if (language.value == "English" && d.text) {
      let list1 = d.text.split(' ')

      let str: any = ''
      let num = 0
      let sy = ''
      list1.map((n: any, i: any) => {
        if (i == 0) {
          if (n.length <= 10) {
            num = n.length
            str = n
          } else {
            num = 10
            let a1 = n.substring(0, 10)
            sy = n.slice(10)
            str = a1
          }
        } else {

          if (sy) {
            str = str + '\n';
            let n2 = sy.length + n.length
            if (n2 <= 10) {
              num = n2
              str = str + sy + ' ' + n
              sy = ''
            } else {
              num = 10
              str = str + sy
              let n2 = 10 - sy.length >= 0 ? 10 - sy.length : 0

              let a1 = n.substring(0, n2)
              sy = n.slice(10)
              str = str + a1
            }
          } else {
            if (num + n.length <= 10) {
              str = str + ' ' + n
              num = num + n.length
            } else {
              str = str + '\n' + n
              num = n.length
            }
          }
        }
      })
      name = str
    }

    indicator.push({
      color,
      max: 100,
      text: name
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: { position: { left: '0px' } },
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 60,
        startAngle: 90,
        splitNumber: 5,
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 12,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: "当前值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};
//获取分数趋势
let scoreTime=ref(1)
const getScore = async(dateType:any)=>{
  scoreTime.value = dateType
  // 获取分数趋势数据
  // let end = transformDate(new Date());
  // let begin = transformDate(new Date().getTime() - 1000 * 60 * 60 * 24);
  let time = new Date();
  let endDate = transformDate2(time, 1);
  //本月
  let num = dateType === 2 ? 30 : 7

  let startTime = time.getTime() - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let search = {
    params: {
      begin:startDate,
      end:endDate,
      sensorId: "",
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let score = await request.get("/api/external/machines/score-full", search);
  let scoreAll = score.data;

  let scoreDataList: any = [];

  scoreAll.map((item: any) => {
    let d = item.date;
    let info1 = scoreAll.find((p: any) => p.date === d);
    delete info1.date;
    delete info1.wkStatus;
    delete info1.condition;
    let list = Object.values(info1).sort();
    scoreDataList.push({
      date: d,
      value: list[0],
    });
  });

  machineInfo.value.scoreDataList = scoreDataList;
  setTimeout(() => {
    initScoreChart()
  }, 500);
 
}

//绘制分数趋势图
const initScoreChart = () => {
  let data = machineInfo.value.scoreDataList;
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB2")) return;
  let myChart = ec.init(document.getElementById("chartMachienB2"));
  myChart.clear();
  let xData = data?.map((p: any) => p.date);
  let yData = data?.map((p: any) => p.value);

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
        fontSize:9
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      type: "value",
      name: langObj.value['分'], 
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart.setOption(option);
};

// 选择雷达图、分数趋势图
const selectTag = (ev: any) => {
  selecrRandar.value = ev;
  if (ev === 1) {
    initRandarChart(machineInfo.value.Slist);
  }

  if (ev === 2) {
    initRandarChart(machineInfo.value.Tlist);
  }

  if (ev === 3) {
    // initScoreChart();
  }
};

// 获取设备事件
let warningType = ref(1);
let warningTotal = ref(0);
let machineWarningData: any = ref([]);
const getMachineWarning = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: warningType.value === 1 ? [1, 4] : [2],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
    machineWarningData.value = result.data.list;
    warningTotal.value = result.data.total;
  }
};
let znZdWarnNum = ref(0)
//获取当前设备的智能诊断报警数量
const getMachineWarningZnZd = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: [1],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
   
    znZdWarnNum.value = result.data.total;
  }
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks?.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let markList = ref<any[]>([]);

let config_3d = ref<any>({
  marks: [],
});

let line = ref<any[]>([]);

const load3D = async () => {
  config_3d.value = {};
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);
  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);

      if (res.data) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    config_3d.value.marks = config_3d.value.marks || [];
    markList.value = config_3d.value.marks;
  }
  line.value = [];
  if (config_3d.value.marks?.length > 0) {
    for (var i = 0; i < config_3d.value.marks.length; i++) {
      var item = config_3d.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
        var find = config_3d.value.marks.find(
          (t) =>
            t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
            t.bind.indexOf("b-") > -1
        );
        if (find) {
          var distance = Math.sqrt(
            Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
            Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          line.value.push({
            ...item,
            distance: distance,
            angle: angle_d,
          });
        }
      }
    }
  }

  if (config_3d.value.marks.length > 0) {
    get3DData();
  }
};

// 查看更多
const viewMore = () => {
  router.push("/warning-manage/warning-list");
};


// 选择区域
let group_info: any = ref({})
let group_machineList: any = ref([])
let config_3d_group: any = ref({})
let lineGroup: any = ref([])

const selectGroup = (ev: any) => {
  selectType.value = 2
  if (ev) {
    group_info.value = ev
    group_machineList = ev.machineList || []
  }

  loadGroup3D()
}

const loadGroup3D = async () => {
  config_3d_group.value = { marks: [] };

  let config: any = {
    params: {
      groupId: group_info.value.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/group-3D", config);
  console.log('result.data2222222222', result.data)
  if (result?.data) {
    config_3d_group.value = result.data

    markList.value = config_3d_group.value.marks;
  }


  lineGroup.value = [];
  for (var i = 0; i < config_3d_group.value.marks.length; i++) {
    var item = config_3d_group.value.marks[i];
    if (item.bind.indexOf("a-") > -1) {
      var find = config_3d_group.value.marks.find(
        (t: any) =>
          t.bind.substring(2, t.bind.length) ==
          item.bind.substring(2, item.bind.length) &&
          t.bind.indexOf("b-") > -1
      );
      if (find) {
        var distance = Math.sqrt(
          Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
        );
        var angle_d =
          Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
        lineGroup.value.push({
          ...item,
          distance: distance,
          angle: angle_d,
        });
      }
    }

  }
}


return (_ctx: any,_cache: any) => {
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(machineListLeftComponent, {
          onSelectMachine: selectMachine,
          onSelectGroup: selectGroup,
          type: 3,
          mode: 1
        })
      ]),
      (_unref(selectType) == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(machineInfo).machineName), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(langObj)['设备诊断']), 1),
                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectTag(2))),
                        class: _normalizeClass(["btn1 cursor-pointer", _unref(selecrRandar) === 2 ? 'btn1BgSelected' : '']),
                        style: _normalizeStyle([{ color: _unref(selecrRandar) === 2 ? '#fff' : '' }, {"margin-right":"10px"}])
                      }, _toDisplayString(_unref(langObj)['健康指标']), 7),
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectTag(1))),
                        class: _normalizeClass(["btn1 cursor-pointer", _unref(selecrRandar) === 1 ? 'btn1BgSelected' : '']),
                        style: _normalizeStyle({ color: _unref(selecrRandar) === 1 ? '#fff' : '' })
                      }, _toDisplayString(_unref(langObj)['振动指标']), 7),
                      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      (_unref(visibleChart2))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15))
                        : _createCommentVNode("", true),
                      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)['诊断趋势']), 1),
                    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createVNode(_component_a_dropdown, null, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_menu_item, {
                              key: "1",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (getScore(1)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["近一周"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_item, {
                              key: "2",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (getScore(2)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["近一月"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "text-gray-50 flex items-center p-text-right",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                        }, [
                          _createTextVNode(_toDisplayString(_unref(scoreTime)==1?_unref(langObj)["近一周"]:_unref(langObj)["近一月"]) + " ", 1),
                          _createVNode(_component_CaretDownOutlined, { style: {"font-size":"16px","padding-left":"5px"} })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _cache[36] || (_cache[36] = _createElementVNode("div", { class: "bg_wrap" }, [
                    _createElementVNode("div", {
                      id: "chartMachienB2",
                      style: {"width":"100%","height":"260px"}
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(langObj)['设备状态占比']), 1),
                    _cache[37] || (_cache[37] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createVNode(_component_a_dropdown, null, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_menu_item, {
                              key: "1",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (getMachineStatusTime(1)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["近一周"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_item, {
                              key: "2",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (getMachineStatusTime(2)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["近一月"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "text-gray-50 flex items-center p-text-right",
                          onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
                        }, [
                          _createTextVNode(_toDisplayString(_unref(machineStatusTime)==1?_unref(langObj)["近一周"]:_unref(langObj)["近一月"]) + " ", 1),
                          _createVNode(_component_CaretDownOutlined, { style: {"font-size":"16px","padding-left":"5px"} })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        (_unref(visibleChart))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createTextVNode(_toDisplayString(_unref(langObj)['累计运行时间']) + "：", 1),
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(conditionTotal).wkStatsu), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_29, [
                          _cache[38] || (_cache[38] = _createElementVNode("div", {
                            class: "box4",
                            style: {"background-color":"#4efaee"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(langObj)['运行占比']) + " :", 1),
                          _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(conditionTotal).wkStatus1Rate) + "%", 1)
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                          _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                          _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)['运行时间']) + ":", 1),
                          _createElementVNode("div", _hoisted_34, _toDisplayString(_unref(conditionTotal).wkStatus1Date), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_35, [
                          _cache[40] || (_cache[40] = _createElementVNode("div", {
                            class: "box4",
                            style: {"background-color":"#1cc48b"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(langObj)['健康占比']) + ":", 1),
                          _createElementVNode("div", _hoisted_37, _toDisplayString(_unref(conditionTotal).condition1Rate) + "%", 1)
                        ]),
                        _createElementVNode("div", _hoisted_38, [
                          _cache[41] || (_cache[41] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                          _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(langObj)['健康时间']) + ":", 1),
                          _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(conditionTotal).condition1Date), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_41, [
                          _cache[42] || (_cache[42] = _createElementVNode("div", {
                            class: "box4",
                            style: {"background-color":"#ddd246"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(langObj)['可用占比']) + ":", 1),
                          _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(conditionTotal).condition2Rate) + "%", 1)
                        ]),
                        _createElementVNode("div", _hoisted_44, [
                          _cache[43] || (_cache[43] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                          _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(langObj)['可用时间']) + ":", 1),
                          _createElementVNode("div", _hoisted_46, _toDisplayString(_unref(conditionTotal).condition2Date), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_47, [
                          _cache[44] || (_cache[44] = _createElementVNode("div", {
                            class: "box4",
                            style: {"background-color":"#cb6a34"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_48, _toDisplayString(_unref(langObj)['警戒占比']) + ":", 1),
                          _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(conditionTotal).condition3Rate) + "%", 1)
                        ]),
                        _createElementVNode("div", _hoisted_50, [
                          _cache[45] || (_cache[45] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                          _createElementVNode("div", _hoisted_51, _toDisplayString(_unref(langObj)['警戒时间']) + ":", 1),
                          _createElementVNode("div", _hoisted_52, _toDisplayString(_unref(conditionTotal).condition3Date), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_53, [
                          _cache[46] || (_cache[46] = _createElementVNode("div", {
                            class: "box4",
                            style: {"background-color":"#b81212"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_54, _toDisplayString(_unref(langObj)['故障占比']) + ":", 1),
                          _createElementVNode("div", _hoisted_55, _toDisplayString(_unref(conditionTotal).condition4Rate) + "%", 1)
                        ]),
                        _createElementVNode("div", _hoisted_56, [
                          _cache[47] || (_cache[47] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                          _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(langObj)['故障时间']) + ":", 1),
                          _createElementVNode("div", _hoisted_58, _toDisplayString(_unref(conditionTotal).condition4Date), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    _cache[48] || (_cache[48] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                    _createElementVNode("div", _hoisted_62, [
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)("设备健康状态",_unref(language))), 1),
                      _createElementVNode("div", {
                        class: "w-full",
                        style: _normalizeStyle([{ color: _unref(machineInfo)?.condition?.color }, {"font-size":"24px"}])
                      }, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.condition?.label]), 5)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    _cache[49] || (_cache[49] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)("设备运行状态",_unref(language))), 1),
                      _createElementVNode("div", {
                        class: "w-full",
                        style: _normalizeStyle([{ color: _unref(machineInfo)?.status?.color }, {"font-size":"24px"}])
                      }, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.status?.label]), 5)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_65, [
                    _cache[50] || (_cache[50] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                    _createElementVNode("div", _hoisted_66, [
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)("智能诊断报警",_unref(language))), 1),
                      _createElementVNode("div", _hoisted_67, _toDisplayString(_unref(znZdWarnNum)) + _toDisplayString(_unref(langObj)['条']), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_68, [
                    _cache[51] || (_cache[51] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                    _createElementVNode("div", _hoisted_69, [
                      _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)("平均故障间隔",_unref(language))) + "(" + _toDisplayString(_unref(langObj)['小时']) + ")", 1),
                      _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(machineInfo)?.workStatus?.faultIntervalTimes?.toFixed(2) || 0.00), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_71, [
                  _cache[56] || (_cache[56] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createElementVNode("div", _hoisted_72, [
                    _cache[52] || (_cache[52] = _createElementVNode("div", {
                      class: "circle",
                      style: {"background":"#1cc48b"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_73, _toDisplayString(_unref(langObj)['健康']), 1),
                    _cache[53] || (_cache[53] = _createElementVNode("div", {
                      class: "circle ml-8",
                      style: {"background":"#ddd246"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_74, _toDisplayString(_unref(langObj)['可用']), 1),
                    _cache[54] || (_cache[54] = _createElementVNode("div", {
                      class: "circle ml-8",
                      style: {"background":"#cb6a34"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_75, _toDisplayString(_unref(langObj)['警戒']), 1),
                    _cache[55] || (_cache[55] = _createElementVNode("div", {
                      class: "circle ml-8",
                      style: {"background":"#b81212"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_76, _toDisplayString(_unref(langObj)['故障']), 1)
                  ])
                ]),
                (_unref(config_3d).type === '3d')
                  ? (_openBlock(), _createBlock(D3dViewer, {
                      key: 0,
                      config3d: _unref(config_3d),
                      "machine-id": _unref(machine_id),
                      style: {"margin":"auto auto auto -200px"},
                      width: 1200,
                      height: 800
                    }, null, 8, ["config3d", "machine-id"]))
                  : _createCommentVNode("", true),
                (_unref(config_3d) && _unref(config_3d).img && _unref(config_3d).type !== '3d')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
                      _createElementVNode("img", {
                        src: _unref(config_3d)?.picture,
                        style: _normalizeStyle([{"position":"absolute"}, {
                width: _unref(config_3d)?.img?.width + 'px',
                height: _unref(config_3d)?.img?.height + 'px',
                top: _unref(config_3d)?.img?.y + 'px',
                left: _unref(config_3d)?.img?.x + 'px',
                transform: 'rotate(' + _unref(config_3d)?.img?.rotate + 'deg)',
              }])
                      }, null, 12, _hoisted_78),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d)?.marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('a-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_point",
                                style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                              }, null, 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d)?.marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('b-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_bq",
                                style: _normalizeStyle([{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(item.condition),
                  'box-shadow': getShadow(item.condition),
                }, {"width":"225px","height":"100px"}])
                              }, [
                                _createElementVNode("div", _hoisted_79, [
                                  _createElementVNode("div", _hoisted_80, [
                                    _createElementVNode("div", null, _toDisplayString(item.sensorPlace), 1),
                                    _cache[57] || (_cache[57] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                    _createElementVNode("div", {
                                      style: _normalizeStyle([{ 'background-color': getColor2(item.condition) }, {"width":"15px","height":"15px","border-radius":"50%"}])
                                    }, null, 4)
                                  ]),
                                  _createElementVNode("div", _hoisted_81, [
                                    _createElementVNode("div", _hoisted_82, [
                                      _createElementVNode("div", null, _toDisplayString(item.hz) + "m/s²", 1),
                                      _createElementVNode("div", _hoisted_83, _toDisplayString(_unref(langObj)['振动加速度']), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_84, [
                                      _createElementVNode("div", null, _toDisplayString(item.sz) + "mm/s", 1),
                                      _createElementVNode("div", _hoisted_85, _toDisplayString(_unref(langObj)['振动速度']), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_86, [
                                      _createElementVNode("div", null, _toDisplayString(item.temp) + "℃", 1),
                                      _createElementVNode("div", _hoisted_87, _toDisplayString(_unref(langObj)['温度']), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_88, [
                                    _cache[58] || (_cache[58] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                    _createElementVNode("div", _hoisted_89, _toDisplayString(item.date), 1)
                                  ])
                                ])
                              ], 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(line), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createElementVNode("div", {
                            class: "d3_line",
                            style: _normalizeStyle({
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                })
                          }, null, 4)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_90, [
                _createElementVNode("div", _hoisted_91, [
                  _createElementVNode("div", _hoisted_92, [
                    _createElementVNode("div", _hoisted_93, _toDisplayString(_unref(langObj)['设备信息']), 1),
                    _cache[59] || (_cache[59] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_94, [
                    _createVNode(pictureListComponent, {
                      type: 3,
                      picList: _unref(picListAll)
                    }, null, 8, ["picList"]),
                    (!_unref(picListAll).length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_95))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_96, [
                      _createElementVNode("div", _hoisted_97, [
                        _createElementVNode("div", _hoisted_98, _toDisplayString(_unref(langObj)['设备名称']), 1),
                        _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_99, [
                        _createElementVNode("div", _hoisted_100, _toDisplayString(_unref(langObj)['设备区域']), 1),
                        _createElementVNode("div", null, _toDisplayString(_unref(getGroupName)(_unref(machineInfo).groupId)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_101, [
                        _createElementVNode("div", _hoisted_102, _toDisplayString(_unref(langObj)['设备厂家']), 1),
                        _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).supplier), 1)
                      ]),
                      _createElementVNode("div", _hoisted_103, [
                        _createElementVNode("div", _hoisted_104, _toDisplayString(_unref(langObj)['设备编号']), 1),
                        _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineCode), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_105, [
                  _createElementVNode("div", _hoisted_106, [
                    _createElementVNode("div", _hoisted_107, [
                      _createTextVNode(_toDisplayString(_unref(langObj)['设备事件']), 1),
                      _createElementVNode("span", _hoisted_108, " （" + _toDisplayString(_unref(warningTotal)) + " " + _toDisplayString(_unref(langObj)['条']) + "） ", 1)
                    ]),
                    _cache[60] || (_cache[60] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", {
                      onClick: viewWarnMore,
                      class: "p-text-right",
                      style: {"cursor":"pointer"}
                    }, _toDisplayString(_unref(langObj)['查看更多']) + " > ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_109, [
                    _createElementVNode("div", _hoisted_110, [
                      _cache[61] || (_cache[61] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => ((_isRef(warningType) //@ts-ignore
 ? warningType.value = 1 : warningType = 1), getMachineWarning())),
                        class: _normalizeClass(["btn1 cursor-pointer", _unref(warningType) === 1 ? 'btn1BgSelected' : '']),
                        style: _normalizeStyle({ color: _unref(warningType) === 1 ? '#fff' : '' })
                      }, _toDisplayString(_unref(langObj)['报警事件']), 7),
                      _createElementVNode("div", {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => ((_isRef(warningType) //@ts-ignore
 ? warningType.value = 2 : warningType = 2), getMachineWarning())),
                        class: _normalizeClass(["btn99 cursor-pointer", _unref(warningType) === 2 ? 'btn1BgSelected' : '']),
                        style: _normalizeStyle([{"margin-left":"10px"}, { color: _unref(warningType) === 2 ? '#fff' : '' }])
                      }, _toDisplayString(_unref(langObj)['检修记录']), 7),
                      _cache[62] || (_cache[62] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_111, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "box5 mt-2"
                        }, [
                          _createElementVNode("div", _hoisted_112, [
                            (item.type === 1)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_113))
                              : _createCommentVNode("", true),
                            (item.type === 2)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_114))
                              : _createCommentVNode("", true),
                            (item.type === 4)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_115))
                              : _createCommentVNode("", true),
                            (item.type === 1)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 3,
                                  style: _normalizeStyle([{
                        color: _unref(conditionList).find((p) =>
                          p.value.includes(item.condition)
                        )?.color,
                      }, {"font-size":"16px"}]),
                                  class: "ml-2"
                                }, _toDisplayString(_unref(langObj)['诊断报醒']) + "：" + _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) =>
                            p.value.includes(item.condition)
                          )?.label ?? ""]), 5))
                              : _createCommentVNode("", true),
                            (item.type === 2)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_116, _toDisplayString(_unref(langObj)['现场维修']), 1))
                              : _createCommentVNode("", true),
                            (item.type === 4)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_117, _toDisplayString(_unref(langObj)['门限报警']), 1))
                              : _createCommentVNode("", true),
                            _cache[63] || (_cache[63] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                          ]),
                          _createElementVNode("div", _hoisted_118, _toDisplayString(item.diagnosticNotes), 1),
                          _createElementVNode("div", _hoisted_119, _toDisplayString(item.date), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(selectType) == 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_120, [
            _createElementVNode("div", _hoisted_121, [
              _createElementVNode("span", _hoisted_122, _toDisplayString(_unref(group_info).name), 1)
            ]),
            _createElementVNode("div", _hoisted_123, [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(group_machineList), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "group-box mt-3 p-2",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_124, [
                      _cache[64] || (_cache[64] = _createElementVNode("div", { class: "shu" }, null, -1)),
                      _createElementVNode("div", _hoisted_125, _toDisplayString(item.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_126, [
                      _createElementVNode("div", _hoisted_127, [
                        _createElementVNode("img", {
                          style: {"width":"100%","max-height":"100%"},
                          src: item.picture,
                          alt: ""
                        }, null, 8, _hoisted_128)
                      ]),
                      _createElementVNode("div", _hoisted_129, [
                        (item.scoreWkStatus)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_130, [
                              _cache[65] || (_cache[65] = _createElementVNode("div", { class: "line" }, [
                                _createElementVNode("div", { class: "line animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_131, _toDisplayString(_unref(langObj)['运行']), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_132, [
                              _cache[66] || (_cache[66] = _createElementVNode("div", { class: "down" }, [
                                _createElementVNode("div", { class: "down animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_133, _toDisplayString(_unref(langObj)['停机']), 1)
                            ])),
                        _createElementVNode("div", _hoisted_134, [
                          _createElementVNode("div", {
                            class: "line",
                            style: _normalizeStyle({ 'background-color': item?.condition?.color })
                          }, [
                            _createElementVNode("div", {
                              class: "line animation",
                              style: _normalizeStyle({ 'background-color': item?.condition?.color })
                            }, null, 4)
                          ], 4),
                          _createElementVNode("span", _hoisted_135, _toDisplayString(item?.condition?.label), 1)
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_136, [
                _cache[68] || (_cache[68] = _createElementVNode("div", { style: {"width":"10%"} }, null, -1)),
                _createElementVNode("div", _hoisted_137, [
                  (_unref(config_3d_group) && _unref(config_3d_group).img)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_138, [
                        _createElementVNode("img", {
                          src: _unref(config_3d_group).picture,
                          style: _normalizeStyle([{"position":"absolute"}, {
                  width: _unref(config_3d_group).img.width + 'px',
                  height: _unref(config_3d_group).img.height + 'px',
                  top: _unref(config_3d_group).img.y + 'px',
                  left: _unref(config_3d_group).img.x + 'px',
                  transform: 'rotate(' + _unref(config_3d_group).img.rotate + 'deg)',
                }])
                        }, null, 12, _hoisted_139),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d_group).marks, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (item.bind.indexOf('a-') > -1)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "d3_point",
                                  style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                                }, null, 4))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d_group).marks, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (item.bind.indexOf('b-') > -1)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "d3_bq",
                                  style: _normalizeStyle([{
                    top: item.y - 50 + 'px',
                    left: item.x - 100 + 'px',
                    'border-color': getColor(0),
                  }, {"width":"220px","height":"auto"}])
                                }, [
                                  _createElementVNode("div", _hoisted_140, [
                                    _createElementVNode("div", _hoisted_141, [
                                      _createElementVNode("div", _hoisted_142, _toDisplayString(_unref(group_info)?.name), 1)
                                    ]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(group_machineList), (item, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: "w-full flex items-center"
                                      }, [
                                        _createElementVNode("div", null, _toDisplayString(item.name), 1),
                                        _cache[67] || (_cache[67] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                        _createElementVNode("div", {
                                          style: _normalizeStyle([{"width":"16px","height":"16px","border-radius":"50%","min-width":"16px"}, { 'background': item.condition.color }])
                                        }, null, 4)
                                      ]))
                                    }), 128))
                                  ])
                                ], 4))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lineGroup), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createElementVNode("div", {
                              class: "d3_line",
                              style: _normalizeStyle({
                    top: item.y + 5 + 'px',
                    left: item.x + 5 + 'px',
                    width: item.distance + 'px',
                    transform: 'rotate(' + item.angle + 'deg)',
                  })
                            }, null, 4)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_modal, {
        visible: _unref(warnTrendVisible),
        "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => (_isRef(warnTrendVisible) ? (warnTrendVisible).value = $event : warnTrendVisible = $event)),
        closable: false,
        footer: null,
        style: {"top":"10%"},
        width: "80%",
        maskClosable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_143, [
            _createElementVNode("div", _hoisted_144, [
              _cache[69] || (_cache[69] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_isRef(warnTrendVisible) //@ts-ignore
 ? warnTrendVisible.value = false : warnTrendVisible  = false))
              }, [
                _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
              ])
            ]),
            _createElementVNode("div", _hoisted_145, _toDisplayString(_unref(langObj)['报警记录']), 1),
            _createVNode(_component_a_form, {
              model: _ctx.znZdWarnSearch,
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('设备区域', _unref(language)),
                  name: "groupId",
                  allowClear: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      onChange: selectGroupWarnTrend,
                      value: _unref(warnTrendSearch).groupId,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(warnTrendSearch).groupId) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(warnTrendSearch).machineId,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(warnTrendSearch).machineId) = $event)),
                      style: {"width":"220px"},
                      placeholder: _unref(langObj)['请选择设备'],
                      options: _unref(machineListZdWarn),
                      "filter-option": filterOptionZdReport,
                      "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                      allowClear: "",
                      "show-search": ""
                    }, null, 8, ["value", "placeholder", "options"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['报警时间'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_range_picker, {
                      value: _unref(warnTrendSearch).date,
                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(warnTrendSearch).date) = $event)),
                      "disabled-date": _ctx.disabledDate,
                      onCalendarChange: _ctx.onCalendarChange
                    }, null, 8, ["value", "disabled-date", "onCalendarChange"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('处理状态', _unref(language)),
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(warnTrendSearch).status,
                      "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(warnTrendSearch).status) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('严重程度', _unref(language)),
                  name: "condition"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(warnTrendSearch).condition,
                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(warnTrendSearch).condition) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_146, [
                      _createElementVNode("div", {
                        onClick: resetWarnTrend,
                        class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                        style: {"background-color":"#072499","color":"#fff"}
                      }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                      _createElementVNode("div", {
                        onClick: getWarnTrendData,
                        class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499","color":"#fff"}
                      }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_147, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: warnTrendColumns,
                "data-source": _unref(warnTrendData),
                pagination: paginationWarnTrend.value,
                onChange: handleTableChangeWarnTrend
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_148, [
                        (text === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_149, _cache[70] || (_cache[70] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_4,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_150, _cache[71] || (_cache[71] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_5,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_151, _cache[72] || (_cache[72] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_7,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_152, _cache[73] || (_cache[73] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_6,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_153, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_154, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                            background: _unref(conditionList).find((p) => p.value === text)
                                                ?.color,
                                            }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_155, _toDisplayString(_unref(langObj)[
                                                _unref(conditionList).find((p) => p.value === text)?.label
                                                ]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'handleStatus')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_156, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                          : _createCommentVNode("", true),
                        (text !== 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_157, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                ? _unref(langObj)[
                                                _unref(handleStatusList).find((p) => p.value === text)?.label
                                                ]
                                                : _unref(langObj)["未处理"]), 1))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (record?.reportIds?.length>0)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (viewReport(record))
                            }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_158))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_divider, { type: "vertical" }),
                        (record?.repairReportId)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              onClick: ($event: any) => (detailsJx(record))
                            }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_159))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_divider, { type: "vertical" }),
                        (record.handleStatus === 3)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              onClick: ($event: any) => (viewClose(record))
                            }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_160))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source", "pagination"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_a_modal, {
        visible: _unref(maintenanceSearch).visible,
        "onUpdate:visible": _cache[23] || (_cache[23] = ($event: any) => ((_unref(maintenanceSearch).visible) = $event)),
        closable: false,
        footer: null,
        style: {"top":"10%"},
        width: "80%",
        maskClosable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_161, [
            _createElementVNode("div", _hoisted_162, [
              _cache[74] || (_cache[74] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_unref(maintenanceSearch).visible  = false))
              }, [
                _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
              ])
            ]),
            _createElementVNode("div", _hoisted_163, _toDisplayString(_unref(langObj)['检修记录']), 1),
            _createVNode(_component_a_form, {
              model: _unref(maintenanceSearch),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('设备区域', _unref(language)),
                  name: "groupId",
                  allowClear: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      onChange: selectGroupMaintenance,
                      value: _unref(maintenanceSearch).groupId,
                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(maintenanceSearch).groupId) = $event)),
                      style: {"width":"220px"},
                      allowClear: "",
                      placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(maintenanceSearch).machineId,
                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(maintenanceSearch).machineId) = $event)),
                      style: {"width":"220px"},
                      placeholder: _unref(langObj)['请选择设备'],
                      options: _unref(machineListMaintenance),
                      "filter-option": filterOptionZdReport,
                      "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                      allowClear: "",
                      "show-search": ""
                    }, null, 8, ["value", "placeholder", "options"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['报警时间'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_range_picker, {
                      value: _unref(maintenanceSearch).date,
                      "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_unref(maintenanceSearch).date) = $event)),
                      "disabled-date": _ctx.disabledDate
                    }, null, 8, ["value", "disabled-date"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('搜索', _unref(language)),
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(maintenanceSearch).keyword,
                      "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_unref(maintenanceSearch).keyword) = $event)),
                      placeholder: _unref(getLanguage)('请输入关键词搜索', _unref(language))
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_164, [
                      _createElementVNode("div", {
                        onClick: resetMaintenance,
                        class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                        style: {"background-color":"#072499","color":"#fff"}
                      }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                      _createElementVNode("div", {
                        onClick: _cache[22] || (_cache[22] = ($event: any) => {_unref(maintenanceSearch).skip=1;  getMaintenanceList()}),
                        class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499","color":"#fff"}
                      }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_165, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: maintenanceColumns,
                "data-source": _unref(maintenanceList),
                pagination: maintenancePagination.value,
                onChange: handleTableChangeMaintenance
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'factoryId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_166, _toDisplayString(_unref(getFactoryName)(text)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        onClick: ($event: any) => (detailsMaintenance(record)),
                        style: {},
                        class: "theme-table-link"
                      }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_167))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source", "pagination"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_a_modal, {
        width: "50%",
        closable: false,
        footer: null,
        visible: _unref(jxVisible),
        "onUpdate:visible": _cache[24] || (_cache[24] = ($event: any) => (_isRef(jxVisible) ? (jxVisible).value = $event : jxVisible = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(maintenanceReportDetails, {
            ref_key: "mrD",
            ref: mrD,
            onCancel: cancelJx
          }, null, 512)
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_a_modal, {
        width: "50%",
        closable: false,
        footer: null,
        visible: _unref(visibleDR),
        "onUpdate:visible": _cache[25] || (_cache[25] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(DiagnosticReportDetails, {
            ref_key: "mrDref",
            ref: mrDref,
            onCancel: cancelDR
          }, null, 512)
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_a_modal, {
        visible: _unref(visibledel2),
        "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
        closable: false,
        footer: null,
        bodyStyle: { background: '#020F46' },
        style: {"top":"30%"},
        width: "1200px",
        maskClosable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_168, [
            _createElementVNode("div", _hoisted_169, [
              _cache[75] || (_cache[75] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[26] || (_cache[26] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
              }, [
                _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
              ])
            ]),
            _createElementVNode("div", _hoisted_170, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
            _createVNode(_component_a_form, {
              ref: "formRef",
              name: "advanced_search",
              class: "ant-advanced-search-form",
              style: {"margin-top":"20px"},
              model: _unref(formClose)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          name: "closeCause",
                          label: "关闭原因",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _unref(formClose).closeCause,
                              "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                              placeholder: "",
                              rows: 4,
                              readonly: ""
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}
}

})