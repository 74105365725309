<template>

    <!-- 厂级大屏-设备 -->
    <div  class="w-full px-3 py-2 theme-bg theme-factory"
        style="background-color: #000A31; color: #fff; height: calc(100vh - 100px);overflow: hidden;">
            <div class="flex w-full mt-8" style="height: 4vh;">
                <div class="t_left_wrap flex">
                    <div class="flex items-center t_img">
                        <img class="mr-1" src="@/assets/screen/g_mointor_bg.png"/><span>{{langObj['监测设备']}}:</span>{{machineAllNum}}{{langObj['台']}}
                    </div>
                    <div class="flex  items-center t_img">
                        <img class="mr-1" src="@/assets/screen/g_point_bg.png"/><span>{{langObj['监测测点']}}:</span>{{sensorAllNum}}个
                    </div>
                   
                </div>
                <div class="t_center_wrap flex-1 flex items-center">
                        <img src="@/assets/screen/g_fac_bg.png"/>
                        <div class="select_wrap" >
                            <a-select ref="select" v-model:value="listSelectFac" style="width: 100%;"
                                @change="getGroupListDetail" :dropdownMatchSelectWidth="false">
                                <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                                    item.factoryName
                                }}</a-select-option>
                                
                            </a-select>
                            <CaretDownOutlined class="right_arrow" />
                         </div>

                        <div class="flex ml-6 mr-6">
                            <div style="font-size: 0.8vw;color:#0C79FF;line-height: 0.8vw;"><right-outlined /></div>
                             <div style="font-size: 0.8vw;color:#0C79FF;opacity: 0.6; line-height: 0.8vw;"><right-outlined /></div> 
                             <div style="font-size: 0.8vw;color:#0C79FF;opacity: 0.4;line-height: 0.8vw;"><right-outlined /></div>
                        </div>
                        <div class="select_wrap2"  style="width: 45%;position: relative;">
                            <a-select ref="select"  mode="multiple" v-model:value="listSelectGroup" style="width: 100%;"
                            @change="selectGroup"
                            :dropdownMatchSelectWidth="false">
                            <a-select-option v-for="(item, index) in groupList2" :key="index" :value="item.sonID">{{ item.name
                                }}</a-select-option>
                        </a-select>
                       
                         <CaretDownOutlined class="right_arrow" />
                        </div>

                        


                </div>
                <div class="t_right_wrap flex">
                    <div class="flex items-center t_img">
                        <img class="mr-1" src="@/assets/screen/g_run_bg.png"/><span>{{langObj['运行设备']}}:</span>{{machineRunNum}}{{langObj['台']}}
                    </div>
                    <div class="flex  items-center t_img">
                        <img class="mr-1" src="@/assets/screen/g_health_bg.png"/><span>{{langObj['健康设备']}}:</span>{{machineHealthNum}}{{langObj['台']}}
                    </div>

                </div>
            </div>
            <div class="flex mt-1" style="height: 92%;overflow: hidden">
                <!-- 左侧 -->
                <div class="flex flex-col  box-size  box-size-y hide-scrollbar">
                <div class="title flex items-center">
                    <div ></div>
                    <div class="p-text-color2 ml-10" >{{langObj['设备诊断']}}</div>
                </div>

                <div class="flex items-center bg_wrap" style="padding-bottom: 3%;height:23vh">
                    <div style="width: 50%;">
                        <div v-if="visibleChart2" id="chart2A" style="width: 240px; height: 220px;"></div>
                        <div  style="text-align: center;">{{langObj['健康指标']}}</div>
                    </div>
                    <div style="width: 50%;">
                        <div v-if="visibleChart2" id="chart2B" style="width: 240px; height: 220px;"></div>
                        <div  style="text-align: center;">{{langObj['振动指标']}}</div>
                    </div>
                </div>

                <div class="title flex items-center mt-1">
                    <div></div>
                    <div class="p-text-color2 ml-10">{{langObj['设备照片']}}</div>
                </div>
                <div class="bg_wrap" style="padding-bottom: 3%;">
                    <pictureListComponent :type="4" :picList="picListAll"></pictureListComponent>
                </div>

            

                <div class="w-full flex items-center mt-1">
                    <div class="w-full title flex items-center">
                        <div></div>
                        <div class="p-text-color2 ml-10" >{{langObj['智能诊断报警']}}</div>
                        <div class="flex-1"></div>
                        <div @click="selectZnZdWarn" class="flex items-center cursor-pointer whiteBgColor p-text-right" style="color: #ffffffe6;">
                            <span>{{langObj['查看更多']}}</span>
                            <right-outlined />
                        </div>
                    </div>
                
                </div>

                <div class="w-full bg_wrap" style="padding-bottom: 12%;">
                    <div class="table_wrap">
                        <div class="w-full flex items-center theme-table-head" style="background-color: #020F46;">
                            <div class="table-info" style="opacity: 0.6">{{langObj['设备名称']}}</div>
                            <div class="table-info" style="opacity: 0.6">{{langObj['报警类型']}}</div>
                            <div class="table-info" style="opacity: 0.6">{{langObj['严重程度']}}</div>
                            <div class="table-info" style="opacity: 0.6">{{langObj['报警时间']}}</div>
                        </div>

                        <div class="w-full flex items-center" v-for="(item, index) in machineWarningData2" :key="index"
                            :style="{ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' }">
                            <div class="table-info" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{
                item.machineName }}</div>
                            <div class="table-info">{{ langObj[warningTypeList.find(p => p.value === item.type)?.label] }}</div>
                            <div class="table-info" :style="{ 'color': getMachineWarningName(item.condition)?.color }">{{ langObj[getMachineWarningName(item.condition)?.name] }}</div>
                            <div class="table-info">{{ item.date }}</div>
                        </div>

                    </div>
                </div>

                </div>
                 <!-- 右侧 -->
                <div class="flex-1 ml-4 right_wrap" style="height: 100%%;overflow-y: scroll;margin-left:1%">
                    <div class="w-full flex items-center">
                        <div class="title flex items-center  box-size ">
                            <div></div>
                            <div class="p-text-color2 ml-10" style="padding-bottom: 4%;">{{langObj['设备概览']}}</div>
                        </div>

                        <!-- <div class="ml-6">{{langObj['显示单位']}}:</div> -->
                     

                        <div class="flex-1"></div>

                        <a-select class="right_select_wrap" ref="select" v-model:value="showSearchModel.follow" 
                        >
                            <a-select-option :value="null">
                                <div class="flex items-center">
                                    <img v-if="theme=='white'" class="picIcon" src="@/assets/factory-machine-1-w.png" alt="">
                                    <img v-else class="picIcon" src="@/assets/factory-machine-1.png" alt="">
                                    <div class="ml-2">{{langObj['全部']}}</div>
                                </div>
                            </a-select-option>
                            <a-select-option :value="true">
                                <div class="flex items-center">
                                    <img v-if="theme=='white'" class="picIcon" src="@/assets/factory-machine-1-w.png" alt="">
                                    <img v-else class="picIcon" src="@/assets/factory-machine-1.png" alt="">
                                    <div class="ml-2">{{langObj['关注']}}</div>
                                </div>
                            </a-select-option>
                            <a-select-option :value="false">
                                <div class="flex items-center">
                                    <img v-if="theme=='white'" class="picIcon" src="@/assets/factory-machine-2-w.png" alt="">
                                    <img v-else class="picIcon" src="@/assets/factory-machine-2.png" alt="">
                                    <div class="ml-2">{{langObj['未关注']}}</div>
                                </div>
                            </a-select-option>
                        </a-select>

                        <!-- <a-select
                        ref="select"
                        v-model:value="value"
                        style="width: 120px; margin-left:10px;"
                    >
                        <a-select-option value="">
                            <div class="flex items-center">
                                <img class="picIcon" src="@/assets/factory-machine-3.png" alt=""><div class="ml-2">内容</div>
                            </div>
                        </a-select-option>
                    </a-select> -->

                        <!-- <a-select
                        ref="select"
                        v-model:value="value"
                        style="width: 120px; margin-left:10px;"
                    >
                        <a-select-option value="">
                            <div class="flex items-center">
                                <img class="picIcon" src="@/assets/factory-machine-4.png" alt=""><div class="ml-2">行列</div>
                            </div>
                        </a-select-option>
                    </a-select>

                    <a-select
                        ref="select"
                        v-model:value="value"
                        style="width: 120px; margin-left:10px;"
                    >
                        <a-select-option value="">
                            <div class="flex items-center">
                                <img class="picIcon" src="@/assets/factory-machine-5.png" alt=""><div class="ml-2">排序</div>
                            </div>
                        </a-select-option>
                    </a-select> -->

                        <a-select class="right_select_wrap"  ref="select" v-model:value="showSearchModel.status" 
                            >
                            <a-select-option :value="null">{{langObj['运行状态']}}</a-select-option>
                            <a-select-option :value="1">{{langObj['运行']}}</a-select-option>
                            <a-select-option :value="2">{{langObj['停机']}}</a-select-option>
                        </a-select>

                        <a-select class="right_select_wrap"  ref="select" v-model:value="showSearchModel.codnition" 
                            >
                            <a-select-option :value="null">{{langObj['健康状态']}}</a-select-option>
                            <a-select-option :value="1">{{langObj['健康']}}</a-select-option>
                            <a-select-option :value="2">{{langObj['可用']}}</a-select-option>
                            <a-select-option :value="3">{{langObj['警戒']}}</a-select-option>
                            <a-select-option :value="4">{{langObj['故障']}}</a-select-option>
                        </a-select>
                        <a-input class="right_select_wrap"  v-model:value="showSearchModel.keyWord"  :placeholder="langObj['请输入设备名称搜索']">
                            <template #suffix>
                                <!-- @click="getMachineList" -->
                            <search-outlined  />
                            </template>
                        </a-input>
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                            {{ langObj["重置"] }}
                        </div>
                        <div @click="getMachineListShow2" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                            style="background-color: #072499">
                            {{ langObj["查询"] }}
                        </div>


                    </div>
                <template v-for="(group,index1) in selectGroupsInfo">
                    <div class="w-ful box3 mb-3">
                        <div class="w-full flex items-center">
                            <environment-outlined style="color: #06DEF5; font-size: 0.7vw;" />
                            <div class="ml-2" style="font-size: 0.8vw;">{{ group.name }}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['设备总数']}}:<span style="color: #0C79FF;"> {{ group.machineNum }}</span>{{langObj['台']}}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['运行数']}}:<span style="color: #4EFAEE;"> {{ group.machineRun }}</span>{{langObj['台']}}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['健康设备']}}:<span style="color: #1CC48B;"> {{ group.machineHealNum }}</span>{{langObj['台']}}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['可用设备']}}:<span style="color: #DDD246;"> {{ group.machineAvaNum }}</span>{{langObj['台']}}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['警戒设备']}}:<span style="color: #CB4334;"> {{ group.machineWarnNum }}</span>{{langObj['台']}}</div>
                            <div class="ml-4" style="font-size: 0.7vw;line-height: normal;">{{langObj['故障设备']}}:<span style="color: #D10001"> {{ group.machineGzNum }}</span>{{langObj['台']}}</div>
                            <div class="flex-1"></div>
                            <!-- <div style="font-size: 12px;">收起</div> -->
                        </div>

                        <div class="flex items-center flex-wrap ">
                            <div @click="selectMachine(item)" v-for="(item, index) in group.machineListShow" :key="index"
                                :style="{ 'background': item.id === machine_id ? '#001672' : '#020F46', 'border-color': item?.workStableStatus?.condition ? getMachineWarningName(item?.workStableStatus?.condition).color : '#0d53b7cc' }"
                                class="box4 cursor-pointer mt-3 mr-3" :class="item.id === machine_id?'theme-box4-selected':''">
                                <div class="w-full flex items-center">
                                    <div class="shu"></div>
                                    <div class="size14 ml-2" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; " :title="item.machineName">{{ item.machineName }}</div>
                                    <div class="flex-1"></div>
                                    <template v-if="theme=='white'">
                                        <img class="picIcon" @click="follow(item, false)" v-if="item.follow"
                                        src="@/assets/factory-machine-1-w.png" alt="">
                                    <img class="picIcon" @click="follow(item, true)" v-if="!item.follow"
                                        src="@/assets/factory-machine-2-w.png" alt="">
                                    </template>
                                    <template v-else>
                                        <img class="picIcon" @click="follow(item, false)" v-if="item.follow"
                                        src="@/assets/factory-machine-1-w.png" alt="">
                                    <img class="picIcon" @click="follow(item, true)" v-if="!item.follow"
                                        src="@/assets/factory-machine-2.png" alt="">
                                    </template>
                                    
                                </div>

                                <div class="heng"></div>

                                <div class="w-full flex items-center" style="margin-top: 1%;justify-content: space-between;">
                                  
                                    <!-- <d3d-viewer :config3d="config_3d" :machine-id="machine_id" v-if="config_3d.type === '3d'" style="margin:auto auto auto -200px" :width="1200" :height="800"></d3d-viewer> -->
                                     <div style="width: 45%;">
                                        <img style="height: 7.5vh;margin: 0 auto;display: block;" :src="item.url" alt="">
                                     </div>
                                    
                                    <div class="flex-1"></div>
                                    <div   style="margin-left: 4%;width: 42%;">
                                        <div  class="flex flex-wrap">
                                            <div style="margin-right: 12%;" class="flex items-center" v-if="item.workStatus && item.workStatus.wkStatus">
                                                <div class="line">
                                                    <div class="line animation"></div>
                                                </div>
                                                <span class="text-gray-50 pl-2">{{langObj['运行']}}</span>
                                            </div>
                                            <div style="margin-right: 12%;" class="flex items-center" v-else>
                                                <div class="down">
                                                    <div class="down animation"></div>
                                                </div>
                                                <span class="text-gray-50 pl-2">{{langObj['停机']}}</span>
                                            </div>

                                            <div class="flex items-center">
                                                <div class="line"
                                                    :style="{ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color }">
                                                    <div class="line animation"
                                                        :style="{ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color }">
                                                    </div>
                                                </div>
                                                <span class="text-gray-50 pl-2">{{
                                                    langObj[getMachineWarningName(item?.workStableStatus?.condition).name ]}}</span>
                                            </div>
                                        </div>
                                        <div class="zb_wrap">
                                            <div>{{langObj['高频加速度']}}</div>
                                            <div>{{ Number(item.sz)?.toFixed(2) }}mm/s</div>
                                        </div>
                                        <div class="zb_wrap">
                                            <div>{{langObj['平均故障间隔']}}</div>
                                            <div>{{ item.workStatus?.faultIntervalTimes?.toFixed(2) || 0.00}}{{langObj['小时']}}</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="w-full warn_wrap" :style="{'background-color': item?.workStableStatus?.condition ? getMachineWarningNameBgColor(item?.workStableStatus?.condition).color : 'rgba(10, 161, 255, 0.28)'}">
                                    <div> {{ item.latestFault?.date || "--"}} {{ item.latestFault?.diagnosticNotes || "--" }}</div>
                                   
                                </div>

                                <div class="w-full items-center flex">
                                    <span v-if="machine_info?.workStatus?.onLine">{{langObj['累计运行']}}</span>
                                    <div class="ml-2 textColor" style="color: #4EFAEE;" v-if="machine_info?.workStatus?.onLine">{{
                                        machine_info.workStatus.onLine }}{{langObj['小时']}}</div>
                                    <div class="flex-1"></div>
                                    <!-- <div style="max-width: 110px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;"
                                        :title="item.faultTitle">{{ langObj[item.faultTitle]?langObj[item.faultTitle]:item.faultTitle }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                </div>
            </div>
             <!-- 智能诊断报警记录 -->
             <a-modal v-model:visible="znZdWarnVisible" :closable="false" :footer="null"
                    style="top: 10%" width="80%" :maskClosable="false">
                    <div style="color: #fff">
                        <div class="w-full flex">
                            <div class="flex-1"></div>
                            <div class="cursor-pointer" @click="znZdWarnVisible = false">
                                <close-outlined style="font-size: 20px" />
                            </div>
                        </div>
                        <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能诊断报警记录', language) }}</div>
                        <a-form :model="znZdWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                            <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                <a-select  @change="selectGroupZdWarn" v-model:value="znZdWarnSearch.groupId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择区域', language)">
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                          
                            <a-form-item :label="langObj['设备']" name="status">
                                <a-select v-model:value="znZdWarnSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']"
                                    :options="machineListZdWarn" :filter-option="filterOptionZdReport"
                                    :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear show-search>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="getLanguage('报警时间', language)" name="status">
                                <a-range-picker v-model:value="znZdWarnSearch.date" :disabled-date="disabledDate"
                                @calendarChange="onCalendarChange" />
                            </a-form-item>
                            <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                <a-select v-model:value="znZdWarnSearch.handleStatus" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择处理状态', language)">
                                    <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                <a-select v-model:value="znZdWarnSearch.condition" style="width: 220px" allowClear
                                :placeholder="getLanguage('请选择严重程度', language)">
                                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>



                            <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                            <a-form-item>
                                <div class="flex items-center">
                                    <div @click="resetZdWarn" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('重置', language) }}
                                    </div>

                                    <div @click="getZdWarnData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('查询', language) }}
                                    </div>
                                </div>
                            </a-form-item>
                        </a-form>

                        <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName"
                                :columns="znZdWarnColumns" :data-source="zdWarnData" :pagination="paginationZdWarn" @change="handleTableChangeZdWarn">
                                <template #bodyCell="{ column, text, record }">
                                    <template v-if="column.dataIndex === 'type'">
                                        <div class="flex items-center">
                                            <div v-if="text === 1">
                                            <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                            </div>
                                            <div v-if="text === 2">
                                            <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                            </div>
                                            <div v-if="text === 3">
                                            <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                            </div>
                                            <div v-if="text === 4">
                                            <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                            </div>
                                            <div class="ml-2">
                                            {{
                                                langObj[typeList.find((p) => p.value === text)?.label]
                                            }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'condition'">
                                        <div class="flex items-center">
                                            <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                            background: conditionList.find((p) => p.value === text)
                                                ?.color,
                                            }"></div>
                                            <div class="ml-2">
                                            {{
                                                langObj[
                                                conditionList.find((p) => p.value === text)?.label
                                                ]
                                            }}
                                            </div>
                                         </div>
                                    </template>

                                    <template v-if="column.dataIndex === 'handleStatus'">
                                      
                                        <div  v-if="text === 3">
                                           {{ langObj["已关闭"] }}
                                        </div>
                                        <div v-if="text !== 3">
                                            {{
                                            handleStatusList.find((p) => p.value === text)
                                                ? langObj[
                                                handleStatusList.find((p) => p.value === text)?.label
                                                ]
                                                : langObj["未处理"]
                                            }}
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'act'">
                                      
                                       
                                        <a  v-if="record?.reportIds?.length>0" @click="viewReport(record)" >{{ langObj["诊断报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a  @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"]}}</a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </a-modal>
        
                       <!-- 检修报告 -->
                       <a-modal width="50%" :closable="false" :footer="null" v-model:visible="jxVisible">
                    <maintenanceReportDetails ref="mrD" @cancel="cancelJx"></maintenanceReportDetails>
                 </a-modal>
                <!-- 诊断报告详情 -->
                <a-modal width="50%" :closable="false" :footer="null" v-model:visible="visibleDR">
                    <DiagnosticReportDetails ref="mrDref" @cancel="cancelDR"></DiagnosticReportDetails>
                 </a-modal>
                 <!-- 关闭原因 -->
                 <a-modal v-model:visible="visibledel2" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
                    style="top: 30%" width="1200px" :maskClosable="false">
                    <div style="color: #fff">
                    <div class="w-full flex">
                        <div class="flex-1"></div>
                        <div class="cursor-pointer" @click="visibledel2 = false">
                        <close-outlined style="font-size: 20px; color: #fff !important" />
                        </div>
                    </div>
                    <div class="text-center w-full" style="font-size: 24px">
                        {{ langObj["关闭报警"] }}
                    </div>

                    <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
                        :model="formClose">
                        <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-item name="closeCause" label="关闭原因" required>
                            <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" readonly />
                            </a-form-item>
                        </a-col>
                        </a-row>
                    </a-form>
                    </div>
                </a-modal>

    
        
        </div>




</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch,computed  } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import { transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    transformDate3,
    getGroupListByFactory,
    getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import D3dViewer from "../components/d3d-viewer.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"
const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(2)
let search = ref("")
let config_3d = ref<any>({
  marks: [],
  picture:'http://192.168.0.150:9000/public/20250104/637dd30a-4c5f-47e5-9c29-babef9a3086a.glb'
});
let factory_id = ''
let group_id = ""
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
let machineAllNum = ref(0)
let sensorAllNum = ref(0)
let machineRunNum = ref(0)
let machineHealthNum = ref(0)
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: langObj.value['健康'], num: 0, color: '#61c08f' },
    { label: langObj.value['可用'], num: 0, color: '#d2de49' },
    { label: langObj.value['警戒'], num: 0, color: '#cd5f3b' },
    { label: langObj.value['故障'], num: 0, color: '#921e37' },
])
let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];
let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref([])

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    conditionSelectFac.value = factory_id
    listSelectFac.value = factory_id
    loadFactory()
   
}

// 获取工厂
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        factoryList.value = result.data.filter((p: any) => p._id === factory_id || p.parentId === factory_id)


        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }
        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
        

        getMain(groups);
      
    }

}


// 获取区域列表
const getMain = async (groups: any) => {

    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp && tempInfo.value !== 0) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? '' : temp?.toFixed(2)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
            d.latestFault = d.latestFault
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (!d.workStableStatus.condition) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }

   
    setTimeout(() => {
        // listSelectGroup.value='1687830223495'
        // selectGroup('1687830223495')
        getConditionChartData()
        getMachineWarningChartData()
        getGroupListDetail()
       
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: langObj.value['健康'], num: n1, color: '#61c08f' },
        { label: langObj.value['可用'], num: n2, color: '#d2de49' },
        { label: langObj.value['警戒'], num: n3, color: '#cd5f3b' },
        { label: langObj.value['故障'], num: n4, color: '#921e37' },
    ]

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + langObj.value["设备数"]+"：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill: theme.value=='white'? '#333':'#fff',
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        // options.graphic.style.text = name;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"]+":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === factory_id)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [factory_id]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            factoryIds,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}
// 获取严重程度名称颜色
const getMachineWarningNameBgColor = (condition: any) => {
    let name = '健康'
    let color = 'red'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = 'rgba(221, 210, 70, 0.3)'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = 'rgba(203, 67, 52, 0.3)'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = []
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo.groups)
        list1 = facInfo.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            let machineGzNum =0;
            let machineHealNum =0;
            let machineAvaNum = 0;
            let machineWarnNum=0;
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
                if (m.workStatus && m.workStatus.condition==0) {
                    machineHealNum++
                }
                if (m.workStatus && m.workStatus.condition>=1 && m.workStatus && m.workStatus.condition<3) {
                    machineAvaNum++
                }
                if (m.workStatus && m.workStatus.condition>=3 && m.workStatus && m.workStatus.condition<6) {
                    machineWarnNum++
                }
                if (m.workStatus && m.workStatus.condition>=6 && m.workStatus && m.workStatus.condition<=7) {
                    machineGzNum++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun;
            g.machineHealNum = machineHealNum
            g.machineAvaNum = machineAvaNum
            g.machineWarnNum = machineWarnNum
            g.machineGzNum = machineGzNum
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList;
   
    if(localStorage.getItem('groupId')){
        if( groupListData.value?.filter(p=>p.sonID==localStorage.getItem('groupId'))?.length>0){
             group_id =  localStorage.getItem('groupId') || ""
        }else{
            group_id = groupListData.value[0].sonID
        }
    }else{
        group_id = groupListData.value[0].sonID
    }
    
    selectGroup()
}

// 选择区域
let groupInfo: any = ref({ machines: [] })
let machineListShow: any = ref([])
let showSearchModel: any = ref({
    follow: null,
    codnition: null,
    status: null,
    keyWord:""
})
let selectGroupsInfo :any = ref([])
let machine_id = ref('')
const selectGroup = async (ev:any) => {
    selectGroupsInfo.value = []
    machineAllNum.value = 0;
    machineHealthNum.value  = 0;
    machineRunNum.value = 0;
    if(ev){
        listSelectGroup.value = ev
    }else{
        listSelectGroup.value = [group_id]
    }

    listSelectGroup.value.map((item:any)=>{
        selectGroupsInfo.value.push(groupListData.value?.find((p: any) => p.sonID === item))
    })
    selectGroupsInfo.value.map((item:any)=>{
        getMachineListShow(item)
    })
    let sensorList :any = []
    selectGroupsInfo.value.map((item:any)=>{
        machineAllNum.value = machineAllNum.value += item.machineNum;
        machineHealthNum.value  = machineHealthNum.value  += item.machineHealNum;
        machineRunNum.value  = machineRunNum.value  += item.machineRun;
        item.machines.map((d: any) => {
            sensorList = sensorList.concat(d.sensors)
        })
       
    })
    sensorAllNum.value = sensorList.length;
    
   
    
    
    if (listSelectGroup.value[0]) {
        selectModel.value = 2
        visibleChart2.value = false
        visibleChart2.value = true

        groupInfo.value = groupListData.value.find((p: any) => p.sonID === listSelectGroup.value[0])

        if (groupInfo.value?.machines?.length > 0) {
            getSensorList(groupInfo.value.machines[0])
            getRandarData(groupInfo.value.machines[0])
            getMachineWarningData(groupInfo.value.machines[0])
            machine_id.value = groupInfo.value.machines[0].id
        }
        getMachineListShow(groupInfo.value)

    } 

}

// const selectGroup2 = (ev: any) => {
//     console.log(ev)
//     listSelectGroup.value = ev.sonID
//     selectGroup(listSelectGroup.value)
// }

//筛选显示设备
const getMachineListShow2 = ()=>{
    selectGroupsInfo.value.map(item=>{
        getMachineListShow(item)
    })
}
const reset=()=>{
    showSearchModel.value={
        follow: null,
        codnition: null,
        status: null,
        keyWord:""
  }
  getMachineListShow2()
}
//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};
const getMachineListShow = (groupInfo:any) => {
  
    let list = JSON.parse(JSON.stringify(groupInfo.machines))

    if (showSearchModel.value.follow == true) {
        list = list.filter((p: any) => p.follow === showSearchModel.value.follow)
    }
    if(showSearchModel.value.follow == false) {
        list = list.filter((p: any) => !p.follow)
    }
    if (showSearchModel.value.status) {
        if (showSearchModel.value.status === 1) {
            list = list.filter((p: any) => p.workStatus && p.workStatus.wkStatus === 1)
        }
        if (showSearchModel.value.status === 2) {
            list = list.filter((p: any) => !p.workStatus || p.workStatus.wkStatus === 0)
        }
    }
    if (showSearchModel.value.codnition) {
        if (showSearchModel.value.codnition === 1) {
            list = list.filter((p: any) => !p.workStableStatus || p.workStableStatus.condition === 0)
        }
        if (showSearchModel.value.codnition === 2) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 1 && p.workStableStatus.condition <= 2)
        }
        if (showSearchModel.value.codnition === 3) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 3 && p.workStableStatus.condition <= 5)
        }
        if (showSearchModel.value.codnition === 4) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 6 && p.workStableStatus.condition <= 7)
        }
    }
    if(showSearchModel.value.keyWord){
        list = list.filter((p: any) => p.machineName?.includes(showSearchModel.value.keyWord))
    }

    list.map((p: any) => {
        p.latestFault = p.latestFault || {};
        p.fault = p.fault? p.fault : 0;
        if (p.pictures?.length > 0) {
            p.url = p.pictures[0].url;
        }
    });
   
  
    groupInfo.machineListShow = list
}


// 获取雷达图数据
const getRandarData = async (machine: any) => {
    let faultnamesSearch = {
        params: { machineId: machine.id },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get('/api/machines/faultnames', faultnamesSearch)
    let faultName = result.data.faultNames
    let faultNameII = result.data.faultNameExpects

    let Slist: any = []
    let Tlist: any = []

    if (machine.workStableStatus && machine.workStableStatus.scores) {
        for (let index in machine.workStableStatus.scores) {
            const item = machine.workStableStatus.scores[index];
            if (index.includes('S')) {
                Slist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title
                })
            }

            if (index.includes('T')) {
                Tlist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
                })
            }
        }
    }

    setTimeout(() => {
        initRandarChart(Tlist, 'chart2A')
        initRandarChart(Slist, 'chart2B')
    }, 500);
}

// 雷达图绘制
const initRandarChart = (dataList: any, id: any) => {
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChart = ec.init(document.getElementById(id));
    myChart.clear()

    if (!dataList.length) return

    let data: any = []
    let error: any = []
    let indicator: any = []
    dataList.map((d: any) => {
        data.push(d.score)
        error.push(60)
        let color = ''
        if (d.score >= 0 && d.score < 60) {
            color = '#CD5F3B'
        }
        if (d.score >= 60 && d.score < 80) {
            color = '#D2DE49'
        }
        if (d.score >= 80 && d.score <= 100) {
            color = theme.value =='white'? '#333':'#ffffff'
        }
        let name = ''
        if(language.value == "Chinese" && d.text) {
        if (d.text.length > 8) {
            name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
        } else if (d.text.length > 4 && d.text.length <= 8) {
            name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
        } else {
            name = d.text
        }
        } 

        if(language.value == "English" && d.text) {
        let list1 = d.text.split(' ')

        let str:any = ''
        let num = 0
        let sy = ''
        list1.map((n:any, i:any) => {
            if(i==0) {
            if(n.length <= 10) {
                num = n.length
                str = n
            } else {
                num = 10
                let a1 = n.substring(0,10)
                sy = n.slice(10)
                str = a1
            }
            } else {

            if(sy) {
                str = str + '\n' ;
                let n2 = sy.length + n.length
                if(n2 <= 10) {
                num = n2
                str = str + sy + ' ' + n
                sy = ''
                } else {
                num = 10
                str = str + sy
                let n2 = 10 - sy.length >=0 ? 10 - sy.length : 0

                let a1 = n.substring(0,n2)
                sy = n.slice(10)
                str = str + a1
                }
            } else {
                if(num + n.length <= 10) {
                str = str + ' ' + n
                num = num + n.length
                } else {
                str = str + '\n'  + n
                num = n.length
                }
            }
            }
        })
        name = str
        }

        indicator.push({
            color,
            max: 100,
            text:name
        })
    })

    const option = {
        title: {
            text: ''
        },
        tooltip: {
            position: { right: '-50px' }
        },
        radar: [
            {
                indicator: indicator,
                center: ['50%', '50%'],
                radius: 50,
                startAngle: 90,
                splitNumber: 5,
                axisName: {
                    formatter: '{value}',
                    fontSize: 10,
                },
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: 'rgba(46, 214, 157, 1)',
                        fontSize: 11
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: [
                            '#605969',
                            '#453A47',
                        ]
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: [
                            'rgb(241,243,250)'
                        ],
                        width: 0
                    }
                }
            }
        ],
        series: [
            {
                name: '当前值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 4
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: data,
                        lineStyle: {
                            width: 1,
                            color: 'rgba(247,220,225,0.1)',
                            normal: {
                                type: 'dotted'
                            }
                        },
                        itemStyle: {
                            color: '#FF4E13',
                            borderWidth: 5
                        },
                        areaStyle: {
                            color: '#F7DCE1'
                        }
                    }
                ]
            },
            {
                name: '预警值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 1
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: error,
                        name: '',
                        lineStyle: {
                            width: 2,
                            color: 'rgba(255, 25, 59, 0.2)'
                        },
                        itemStyle: {
                            color: 'rgba(255, 25, 59, 0.2)',
                            borderWidth: 0
                        }
                    }
                ]
            }
        ]
    }

    option && myChart.setOption(option)
}

// 获取设备报警记录
let machineWarningData2: any = ref([])
const getMachineWarningData = async (machine: any) => {
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            machineId: machine.id,
            typeList: [1]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    if (result && result.data) {
        machineWarningData2.value = result.data.list
        machineWarningData2.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}


//选择设备
let picList: any = ref([])
let picListAll: any = ref([])
let machine_info: any = ref({})
const selectMachine = (ev: any) => {
    machine_info.value = ev
    machine_id.value = ev.id
    getSensorList(ev)
    getRandarData(ev)
    getMachineWarningData(ev)
}

const getSensorList = async (machine: any) => {
    picList.value = [];
    if (machine.pictures?.length > 0) {
        picList.value.push({ name: machine.machineName, url: machine.pictures[0].url });
    }

    let config: any = {
        params: {
            machineId: machine.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors", config);

    if (result && result.status === 200) {
        result.data.map((d: any) => {
            if (d.pictures) {
                d.pictures.map((p: any) => {
                    picList.value.push({
                        name: d.sensorPlace,
                        url: p.url
                    })
                })

            }
        })
    }

    picListAll.value = picList.value

};
//智能诊断报警记录

// let handleStatusList:any=ref([
//     { label: '未处理', value: 2 },
//     { label: '已处理', value: 3 },
// ])
let handleStatusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let znZdWarnVisible = ref(false);
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let zdWarnData = ref([])
let znZdWarnSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type:1
})
const paginationZdWarn = computed(() => ({
  total: znZdWarnSearch.value.total,
  current: znZdWarnSearch.value.skip,
  pageSize: znZdWarnSearch.value.take,
}));
 

const znZdWarnColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];
const selectZnZdWarn = () => {
    znZdWarnVisible.value = true
    resetZdWarn()
   
    
    getZdWarnData()
}  
let machineListZdWarn = ref([])
// 选择区域
const selectGroupZdWarn = (ev:any) => {
   
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getZdWarnData = async () => {
    

  let config: any = {
    params: {
      skip: znZdWarnSearch.value.skip,
      take: znZdWarnSearch.value.take,
      groupId: znZdWarnSearch.value.groupId,
      machineId: znZdWarnSearch.value.machineId,
      type: znZdWarnSearch.value.type,
      handleStatus: znZdWarnSearch.value.handleStatus,
      expertCertification: znZdWarnSearch.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(znZdWarnSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
  }
   
  if (znZdWarnSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == znZdWarnSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    zdWarnData.value = result.data.list;
    znZdWarnSearch.value.total = result.data.total;
  }
};

const resetZdWarn = () => {
    znZdWarnSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:1
    }
    // getZdWarnData()
}
const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    znZdWarnSearch.value.sort = sorter.field;
    znZdWarnSearch.value.order = order;
  }
  // 处理分页数据
  znZdWarnSearch.value.skip = pagination.current;
  znZdWarnSearch.value.take = pagination.pageSize;

  getZdWarnData();
};





//关注、取关
const follow = async (ev: any, value: any) => {
    ev.follow = value
    let form = {
        machineId: ev.id,
        follow: value
    }

    let result = await request.post('/api/machines/follow', form)
    if(result?.data) {
        let info = groupInfo.value.machines.find((p:any) => p.id === ev.id)
        if(info) {
            info.follow = value
        }
    }
}


// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}

</script>

<style lang="less" scoped>
.enW{
    width: 150px;
}
/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input-affix-wrapper {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input {
    background-color: #04165D;
    color: #fff;
}

.title {
    width: 100%;
    // height: 48px;
    font-size: 1.2vw;
    // text-shadow: 0 4px 0 #0a285fb0;
    font-family: "YouSheBiaoTiHei";
    background: url('@/assets/screen/p_left_tit_bg.png') no-repeat;
    background-size: 100% 100%;
    font-weight: 700;
    font-style: italic;
    // justify-content: space-between;
    .p-text-right{
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;
        padding-right:1%;
    }

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}
.box-size-y{
    width:27%!important;
    height:100%!important;
    overflow-y:scroll;
   
}




.bg_wrap{
    width: 100%;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    // height:21vh;
    height:480px!important;
    .table_wrap{
        width:99%;
        margin: 0 auto;
        overflow: hidden
    }
   
}

.box1 {
    width: 230px;
    height: 80px;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        color: #06DEF5;
    }
}

.table-info {
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.bg_wrap{
    width: 100%;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    .table_wrap{
        width:98%;
        margin: 0 auto;
        overflow: hidden
    }
   
}

.box2 {
    width: 31%;
    // height: 225px;
    // background: #07249933;
    // border: 1px solid #0d53b7cc;
    background: url("@/assets/screen/f_center_bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding:2% 0;
    margin-right:2%;
    overflow: hidden;
    // padding: 15px 10px 0 10px;

    .shu {
        width: 0.2vw;
        height: 0.9vw;
        background-color: #13DCEA;
        margin-left: 5%;
    }
    .text{
        color: rgb(255, 255, 255);
        font-family: "PingFang SC";
        font-size: 0.9vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.9vw;
        margin-left: 1%;
    }

    .heng {
        margin: 0 auto;
        margin-top: 2%;
        width: 95%;
        border: 1px solid #ffffff4d;
    }
    .heng_wrap{
        width: 65%;
        justify-content: center;
        margin-top: 4%;
        text-align: center;
        div{
            width:50%;
            color: rgb(255, 255, 255);
            font-family: "PingFang SC";
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 500;
            line-height: 0.8vw;
        }
    }
    .heng_wrap_bg{
        width: 65%;
        justify-content: center;
        color: rgb(6, 222, 245);
        font-family: "YouSheBiaoTiHei";
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2vw;
        // padding: 2.04px 42.85px 2.04px 20.41px;
        align-items: flex-start;
        margin-top: 2%;
        text-align: center;
        
        background: linear-gradient(90deg, rgba(6, 222, 245, 0.4) 0%, rgba(6, 222, 245, 0) 105.15%);
        div{
          width:50%;
         
        }
        &.heng_wrap_bg2{
            color: rgb(91, 254, 183);
            background: linear-gradient(90deg, rgba(91, 254, 183, 0.4) 0%, rgba(91, 254, 183, 0) 105.15%);
        }
        &.heng_wrap_bg3{
            color: rgb(184, 18, 18);
            background: linear-gradient(90deg, rgba(184, 18, 18, 0.4) 0%, rgba(184, 18, 18, 0) 105.15%);
        }
    }

    .content {
        margin-top: 1%;
        padding-left: 10%;

        img {
            width: 120px;
            height: 142px;
            // margin-left: 40px;
        }
        .content_right_wrap{
            margin-left: 10%;
        }
    }
}

.box3 {
    border: 1px solid #0d53b7;
    border-radius: 2px;
    padding: 1%;

    .box4 {
        width: 19%;
        box-sizing: border-box;
        // height: 168px;
        border: 1.5px solid #0d53b7cc;
        padding: 0.2vw 0.2vw 0 0.2vw;

        .shu {
            width: 2px;
            height: 10px;
            background-color: #13DCEA;
        }

        .heng {
            margin-top: 5px;
            width: 100%;
            border: 1px solid #ffffff4d;
        }
        .zb_wrap{
            width: 100%;
            margin-top: 2%;
          
            overflow: hidden;
            text-align: center;
            // height: 1.8vw;
            border-radius: 5%;
            border: 2px solid #ffffff33;
            div:nth-of-type(1){
               background: rgba(6, 222, 245, 0.4);
            }
        }
        .warn_wrap{
            width:103%;
            margin-top: 1%;
            background: rgba(221, 210, 70, 0.3);
            // rgba(203, 67, 52, 0.67)
            margin-left: -0.2vw;
            height: 22px;
            div{
                width: 95%;
                margin: 0 auto;
                white-space: nowrap;         /* 禁止换行 */
                overflow: hidden;            /* 隐藏溢出内容 */
                text-overflow: ellipsis;     /* 超出用省略号表示 */     
            }
        }
    }
}

.t_left_wrap{
    width: 25%;
    background: url('@/assets/screen/g_top_left_bg.png') no-repeat;
    background-size: 100% 100%;
    // padding: 0.5% 0;
    justify-content: center;
}
.t_right_wrap{
    width: 25%;
    background: url('@/assets/screen/g_top_right_bg.png') no-repeat;
    background-size: 100% 100%;
    // padding: 0.5% 0;
    justify-content: center;
    margin-left: 0.5%;
}
.t_center_wrap{
    margin-left: 0.5%;
    width: 52%;
    height: 4.8vh;
    background: url('@/assets/screen/g_top_center_bg.png') no-repeat;
    background-size: 100% 100%;
    margin-top: -0.5vh;
    // padding: 0.5% 0;
    img{
        display: block;
        margin-left: 3%;
        margin-right: 3%;
        height: 1vw;
        margin-top: -0.3vw;
    }
    
    /deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            font-size: 0.8vw;
            background-color: transparent !important;
            border: none !important;
            box-shadow: none!important;
            // height: 1vw;
            
            // color: #fff !important;
        }
    /deep/ .ant-select-arrow{
        display: none;
    }
    .select_wrap{
        width: 40%;
        position: relative;
        .right_arrow{
                color: #fff;
                position: absolute;
                right: 5%;
                top: 25%;
                font-size: 0.8vw;
            }
        /deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            font-size: 0.8vw;
           
            
            // color: #fff !important;
        }
    }
    .select_wrap2{
        /deep/ .ant-select-multiple .ant-select-selection-item{
            background-color: #0C79FF !important;
            border:none;
            font-size: 0.6vw;
            height: auto;
            
        }
        .right_arrow{
                color: #fff;
                position: absolute;
                right: 5%;
                top: 25%;
                font-size: 0.8vw;
            }
    }

}
.t_img{
    width: 50%;
    img{
        display: block;
        height: 1vw;
        margin-left: 10%;
    }
    font-family: "YouSheBiaoTiHei";
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1vw;
    span{
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;  
    }
}
.right_wrap{
    /deep/ .ant-input {
        border: 0px solid #0d53b7cc !important
    }
    .right_select_wrap{
        width: 10%;
        margin-right: 0.2vw;
    }
}



.size18 {
    font-size: 18px;
}

.size14 {
    font-size: 14px;
}

.opc8 {
    opacity: 0.8
}

.picIcon {
    width: 14px;
    height: 14px;
}

.line {
    width: 10px;
    height: 10px;
    background-color: #4efaee;
    border-radius: 5px;
}

.down {
    width: 10px;
    height: 10px;
    background-color: #ADADAD;
    border-radius: 5px;
}

.animation {
    -webkit-animation: twinkling 5s infinite ease-in-out;
    animation: twinkling 5s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes twinkling {
    0% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }

    50% {
        transform: scale(2);
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    100% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }
}

</style>