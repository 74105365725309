import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { TableProps } from "ant-design-vue";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";

import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { operateLogs } from "@/common/logs";
import { getLanguage } from "@/common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'Member',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang();
const loading = ref(false);
/**操作日志记录 */
operateLogs('访问账号管理');
/**操作日志记录 */
const modalState = reactive({
  visible: false,
  title: "配置",
  confirmLoading: false,
});
let formState = reactive({
  factoryId: '',
  keyword: "",
  enable: "-1",
  skip: 1,
  take: 10,
});

const rules = {
  password: [
    {
      max: 22,
      min: 6,
      required: true,
      pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/),
      message:
        "密码至少包含数字，大小写字母和字符的四种组合，长度在12-22之间",
    },
  ],
};
const filterOption = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
const filterOption2 = (input: string, option: any) => {

  return option.label.indexOf(input) >= 0;
};
// 新增
const add = () => {
  rules.password[0].required = true;
  modalState.title = langObj.value["新增"];
  modalForm.value = {
    enable: true,
    factoryId: "",
    id: 0,
    nickName: "",
    userName: "",
    password: '',
    phone: '',
  };
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);
};
let total = ref(0)
let pagination = computed(() => ({
  total: total.value,
  current: formState.skip,
  pageSize: formState.take,
}));

let dataSource = ref([]);
const columns = [
  {
    title: langObj.value["昵称"],
    dataIndex: "nickName",
    align: "center"
  },
  {
    title: langObj.value["用户名"],
    dataIndex: "userName",
    align: "center"
  },
  {
    title: langObj.value["所属工厂"],
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: langObj.value["角色"],
    dataIndex: "roles",
    align: "center"
  },
  {
    title: langObj.value["是否启用"],
    dataIndex: "enable",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center"
  },
];
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  console.log('pagination', pagination)
  formState.skip = pagination.current
  formState.take = pagination.pageSize
  search();
};

const checkedNodes = ref<any>([])
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let postForm = JSON.parse(JSON.stringify(modalForm.value))

      let groupsList: any = []
      allFactory.value.map((d: any) => {
        // groupsList = groupsList.concat(d.groups)
        if (d?.groups?.length > 0) {
          groupsList = groupsList.concat(d.groups.map((p: any) => ({
            name: p.name,
            sonID: p.sonID,
            factoryId: d._id,
            factoryParentId: d.parentId,
          })))
        }
      })

      let factoryIds: any = []
      let groupIds: any = []

      postForm.powerIds && postForm.powerIds.map((d: any) => {
        let info = groupsList.find((p: any) => p.sonID === d)
        if (info) {
          groupIds.push(d)
          factoryIds.push(info.factoryId)
          if (info.factoryParentId) {
            factoryIds.push(info.factoryParentId)
          }
        }
      })

      factoryIds = [...new Set(factoryIds)]
      groupIds = [...new Set(groupIds)]

      postForm.factoryIds = factoryIds
      postForm.groupIds = groupIds

      postForm.menusIds = postForm.menusIds || []
      let menuIds: any = []
      postForm.menusIds.map((d: any) => {
        menuIds.push(d)
        let p = menuListAll.value.find((p: any) => p.id === d)
        if (p?.parentId !== '0') {
          menuIds.push(p?.parentId)
        }
      })
      postForm.menusIds = [... new Set(menuIds)]
      postForm.platform = 2

      if (postForm.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/member", postForm, config).then((res) => {
          if (res) {
            operateLogs('修改账号，账号名称：' + postForm.userName + ",id:" + postForm.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/member", postForm, config).then((res) => {
          if (res) {
            operateLogs('新增账号，账号名称：' + postForm.userName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });

};

let modalForm: any = ref<any>({
  enable: true,
  factoryId: "",
  id: 0,
  nickName: "",
  userName: "",
  phone: '',

});
const formStateRef = ref<any>();
const roleSetting = async (val: any) => {
  rules.password[0].required = false;
  delete val.password
  if (val?.factoryId) {
    getRole(val.factoryId)
  }
  modalState.title = "配置";

  modalForm.value = JSON.parse(JSON.stringify(val))
  if (modalForm.value?.roles?.length > 0) {
    modalForm.value.roleIds = modalForm.value.roles.map((d: any) => d.id)
  } else {
    modalForm.value.roleIds = []
  }
  if (val.memberPowersInfo) {
    modalForm.value.powerIds = val.memberPowersInfo.groupIds
    modalForm.value.menusIds = val.memberPowersInfo.menusIds
  }

  let index = modalForm.value.menusIds?.length ? modalForm.value.menusIds.length - 1 : -1

  for (let i = index; i >= 0; i--) {
    const element = modalForm.value.menusIds[i];
    let parentInfo = menuList.value.find((p: any) => p.id === element)
    if (parentInfo) {
      modalForm.value.menusIds.splice(i, 1)
    }
  }

  modalForm.value.id = modalForm.value._id
  delete modalForm.value._id


  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};

// 查询
const search = () => {
  const config = {
    params: formState,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/members", config).then((res) => {
    if (res) {

      dataSource.value = res.data.list;
      total.value = res.data.count;
    }
  });
};

search();
const roleList = ref<any>();

const selectFactory = (ev: any) => {
  console.log('ev', ev)
  getRole(ev)
}

let menuList = ref<any>();
let menuListAll = ref<any>();
let menuListNotLevel = ref<any>([]);
const getMenu = () => {
  request.get("/api/menus").then((res: any) => {
    if (res) {
      menuListAll.value = res.data
      res.data = res.data.map((t: any) => ({
        ...t,
        key: t.id,
      }));
      menuListNotLevel.value = res.data;
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      menuList.value = oneLevel;
    }
  });
};
getMenu();




// 查询
const getRole = (factoryId: any) => {
  const config = {
    params: { factoryId, skip: 1, take: 1000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/roles", config).then((res: any) => {
    if (res) {
      roleList.value = res.data.list;
      console.log('roleList.value', roleList.value)
    }
  });
};
let factoryList = ref<any>()
const loadFactory = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {

      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory"
      }));
      // let factory = [];
      // for (var i = 0; i < list.length; i++) {
      //   var element = list[i];
      //   if (!element.parentId) {
      //     element.children = list.filter((t: any) => t.parentId == element.id);
      //     factory.push(element);
      //   }
      // }
      factoryList.value = list;
    }
  });
};
loadFactory()


let factoryListS = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactorys = () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups)
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: 'factory',
        children: t.groups.map((group: any) => ({
          type: 'group',
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
          factoryId: t._id,
          factoryPId: t.parentId,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryListS.value = factory;
    }
  });
};



onMounted(() => {
  loadFactorys()
  //新注册用户，未经过引导的需弹窗进行引导
  if (!getMemberOnboarding()) {
    highlightStep(getPopover(".addMemberDriver", "4/9 新建账号", "在“账号管理”中，点击新增可创建账号并配置账号密码及对应的角色权限。", "新建账号", "right", "start", nextRoute, addMember));
  }
})

//新用户引导新建账号按钮回调
let editMindex = ref("auto");
let dropdownClassName = ref("");
const addMember = () => {
  editMindex = 1000000001;
  dropdownClassName = "dropdownStyleDriver";
  add();
};
const router = useRouter();
//新用户引导新建账号下一步按钮回调
const nextRoute = () => {
  router.push("/equipment/list");
  return;
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['关键词']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formState).keyword,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formState).keyword) = $event)),
                      placeholder: _unref(langObj)['昵称/用户名/手机号']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['所属工厂']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      ref: "select",
                      allowClear: "",
                      value: _unref(formState).factoryId,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).factoryId) = $event)),
                      options: _unref(factoryList),
                      "filter-option": filterOption,
                      "show-search": "",
                      placeholder: _unref(langObj)['请选择所属工厂'],
                      style: {"width":"180px"}
                    }, null, 8, ["value", "options", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['是否启用']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      ref: "select",
                      value: _unref(formState).enable,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formState).enable) = $event)),
                      style: {"width":"120px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select_option, { value: "-1" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['全部']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "1" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['是']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "0" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['否']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(formState).skip = 1, search()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['搜索']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: add,
                      class: "addMemberDriver"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['新增']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: _unref(langObj)['暂无数据'],
                columns: columns,
                "data-source": _unref(dataSource),
                pagination: _unref(pagination),
                onChange: handleTableChange,
                loading: loading.value,
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        onClick: ($event: any) => (roleSetting(record))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(langObj)['配置']), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : (column.dataIndex === 'roles')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text, (tag) => {
                            return (_openBlock(), _createBlock(_component_a_tag, {
                              key: tag,
                              color: 'volcano'
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tag.name), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]))
                      : (column.dataIndex === 'enable')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(text ? _unref(langObj)['是'] : _unref(langObj)['否']), 1))
                        : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["emptyText", "data-source", "pagination", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: modalState.visible,
          "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((modalState.visible) = $event)),
          title: modalState.title,
          "z-index": _unref(editMindex),
          "onUpdate:zIndex": _cache[18] || (_cache[18] = ($event: any) => (_isRef(editMindex) ? (editMindex).value = $event : editMindex = $event)),
          okText: _unref(langObj)['确定'],
          cancelText: _unref(langObj)['关闭'],
          "confirm-loading": modalState.confirmLoading,
          onOk: handleOk
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(modalForm),
              ref_key: "formStateRef",
              ref: formStateRef,
              name: "basic",
              autocomplete: "off",
              rules: rules,
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['用户名'],
                  required: "",
                  autocomplete: "off",
                  "auto-complete": "off"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      type: "text",
                      autocomplete: "off",
                      "auto-complete": "off",
                      value: _unref(modalForm).userName,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(modalForm).userName) = $event)),
                      placeholder: _unref(langObj)['输入用户名'],
                      onChange: _cache[5] || (_cache[5] = ($event: any) => (clearValidate('userName')))
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['昵称'],
                  name: "nickName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).nickName,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(modalForm).nickName) = $event)),
                      placeholder: _unref(langObj)['输入昵称']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['所属工厂'],
                  name: "factoryId",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      onChange: selectFactory,
                      ref: "select",
                      value: _unref(modalForm).factoryId,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(modalForm).factoryId) = $event)),
                      options: _unref(factoryList),
                      "show-search": "",
                      placeholder: _unref(langObj)['请选择所属工厂'],
                      dropdownClassName: _unref(dropdownClassName),
                      "filter-option": filterOption2
                    }, null, 8, ["value", "options", "placeholder", "dropdownClassName"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['密码'],
                  name: "password"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      type: "password",
                      value: _unref(modalForm).password,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(modalForm).password) = $event)),
                      autocomplete: "off",
                      "auto-complete": "off",
                      placeholder: _unref(modalForm).id ? _unref(langObj)['不修改密码无需输入'] : _unref(langObj)['请输入密码']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['手机号'],
                  name: "phone"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).phone,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(modalForm).phone) = $event)),
                      placeholder: _unref(langObj)['请输入手机号']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['是否启用'],
                  name: "enable"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      name: "radioGroup",
                      value: _unref(modalForm).enable,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(modalForm).enable) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['是']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['否']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['角色'],
                  name: "roleIds"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      ref: "select",
                      value: _unref(modalForm).roleIds,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(modalForm).roleIds) = $event)),
                      placeholder: _unref(langObj)['请选择角色'],
                      mode: "multiple",
                      dropdownClassName: _unref(dropdownClassName),
                      onChange: _cache[12] || (_cache[12] = ($event: any) => (clearValidate('role')))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roleList.value, (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder", "dropdownClassName"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['菜单'],
                  name: "menuIds",
                  onChange: _cache[14] || (_cache[14] = ($event: any) => (clearValidate('menuIds')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tree, {
                      checkedKeys: _unref(modalForm).menusIds,
                      "onUpdate:checkedKeys": _cache[13] || (_cache[13] = ($event: any) => ((_unref(modalForm).menusIds) = $event)),
                      checkable: "",
                      "tree-data": _unref(menuList)
                    }, {
                      title: _withCtx(({ title, key }) => [
                        _createTextVNode(_toDisplayString(_unref(langObj)[title] || _unref(getLanguage)(title)), 1)
                      ]),
                      _: 1
                    }, 8, ["checkedKeys", "tree-data"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['工厂权限'],
                  name: "menuIds",
                  onChange: _cache[16] || (_cache[16] = ($event: any) => (clearValidate('menuIds')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tree, {
                      checkedKeys: _unref(modalForm).powerIds,
                      "onUpdate:checkedKeys": _cache[15] || (_cache[15] = ($event: any) => ((_unref(modalForm).powerIds) = $event)),
                      checkable: "",
                      "tree-data": _unref(factoryListS),
                      onCheck: selectTree
                    }, {
                      title: _withCtx(({ title, key }) => [
                        _createTextVNode(_toDisplayString(title), 1)
                      ]),
                      _: 1
                    }, 8, ["checkedKeys", "tree-data"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title", "z-index", "okText", "cancelText", "confirm-loading"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})