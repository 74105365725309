import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/screen/g_mointor_bg.png'
import _imports_1 from '@/assets/screen/g_point_bg.png'
import _imports_2 from '@/assets/screen/g_fac_bg.png'
import _imports_3 from '@/assets/screen/g_run_bg.png'
import _imports_4 from '@/assets/screen/g_health_bg.png'
import _imports_5 from '@/assets/factory-machine-1-w.png'
import _imports_6 from '@/assets/factory-machine-1.png'
import _imports_7 from '@/assets/factory-machine-2-w.png'
import _imports_8 from '@/assets/factory-machine-2.png'
import _imports_9 from '@/assets/zhenduan.png'
import _imports_10 from '@/assets/weixiu.png'
import _imports_11 from '@/assets/jiancha.png'
import _imports_12 from '@/assets/menxian.png'


const _hoisted_1 = {
  class: "w-full px-3 py-2 theme-bg theme-factory",
  style: {"background-color":"#000A31","color":"#fff","height":"calc(100vh - 100px)","overflow":"hidden"}
}
const _hoisted_2 = {
  class: "flex w-full mt-8",
  style: {"height":"4vh"}
}
const _hoisted_3 = { class: "t_left_wrap flex" }
const _hoisted_4 = { class: "flex items-center t_img" }
const _hoisted_5 = { class: "flex items-center t_img" }
const _hoisted_6 = { class: "t_center_wrap flex-1 flex items-center" }
const _hoisted_7 = { class: "select_wrap" }
const _hoisted_8 = { class: "flex ml-6 mr-6" }
const _hoisted_9 = { style: {"font-size":"0.8vw","color":"#0C79FF","line-height":"0.8vw"} }
const _hoisted_10 = { style: {"font-size":"0.8vw","color":"#0C79FF","opacity":"0.6","line-height":"0.8vw"} }
const _hoisted_11 = { style: {"font-size":"0.8vw","color":"#0C79FF","opacity":"0.4","line-height":"0.8vw"} }
const _hoisted_12 = {
  class: "select_wrap2",
  style: {"width":"45%","position":"relative"}
}
const _hoisted_13 = { class: "t_right_wrap flex" }
const _hoisted_14 = { class: "flex items-center t_img" }
const _hoisted_15 = { class: "flex items-center t_img" }
const _hoisted_16 = {
  class: "flex mt-1",
  style: {"height":"92%","overflow":"hidden"}
}
const _hoisted_17 = { class: "flex flex-col box-size box-size-y hide-scrollbar" }
const _hoisted_18 = { class: "title flex items-center" }
const _hoisted_19 = { class: "p-text-color2 ml-10" }
const _hoisted_20 = {
  class: "flex items-center bg_wrap",
  style: {"padding-bottom":"3%","height":"23vh"}
}
const _hoisted_21 = { style: {"width":"50%"} }
const _hoisted_22 = {
  key: 0,
  id: "chart2A",
  style: {"width":"240px","height":"220px"}
}
const _hoisted_23 = { style: {"text-align":"center"} }
const _hoisted_24 = { style: {"width":"50%"} }
const _hoisted_25 = {
  key: 0,
  id: "chart2B",
  style: {"width":"240px","height":"220px"}
}
const _hoisted_26 = { style: {"text-align":"center"} }
const _hoisted_27 = { class: "title flex items-center mt-1" }
const _hoisted_28 = { class: "p-text-color2 ml-10" }
const _hoisted_29 = {
  class: "bg_wrap",
  style: {"padding-bottom":"3%"}
}
const _hoisted_30 = { class: "w-full flex items-center mt-1" }
const _hoisted_31 = { class: "w-full title flex items-center" }
const _hoisted_32 = { class: "p-text-color2 ml-10" }
const _hoisted_33 = {
  class: "w-full bg_wrap",
  style: {"padding-bottom":"12%"}
}
const _hoisted_34 = { class: "table_wrap" }
const _hoisted_35 = {
  class: "w-full flex items-center theme-table-head",
  style: {"background-color":"#020F46"}
}
const _hoisted_36 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_37 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_38 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_39 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_40 = {
  class: "table-info",
  style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
}
const _hoisted_41 = { class: "table-info" }
const _hoisted_42 = { class: "table-info" }
const _hoisted_43 = {
  class: "flex-1 ml-4 right_wrap",
  style: {"height":"100%%","overflow-y":"scroll","margin-left":"1%"}
}
const _hoisted_44 = { class: "w-full flex items-center" }
const _hoisted_45 = { class: "title flex items-center box-size" }
const _hoisted_46 = {
  class: "p-text-color2 ml-10",
  style: {"padding-bottom":"4%"}
}
const _hoisted_47 = { class: "flex items-center" }
const _hoisted_48 = {
  key: 0,
  class: "picIcon",
  src: _imports_5,
  alt: ""
}
const _hoisted_49 = {
  key: 1,
  class: "picIcon",
  src: _imports_6,
  alt: ""
}
const _hoisted_50 = { class: "ml-2" }
const _hoisted_51 = { class: "flex items-center" }
const _hoisted_52 = {
  key: 0,
  class: "picIcon",
  src: _imports_5,
  alt: ""
}
const _hoisted_53 = {
  key: 1,
  class: "picIcon",
  src: _imports_6,
  alt: ""
}
const _hoisted_54 = { class: "ml-2" }
const _hoisted_55 = { class: "flex items-center" }
const _hoisted_56 = {
  key: 0,
  class: "picIcon",
  src: _imports_7,
  alt: ""
}
const _hoisted_57 = {
  key: 1,
  class: "picIcon",
  src: _imports_8,
  alt: ""
}
const _hoisted_58 = { class: "ml-2" }
const _hoisted_59 = { class: "w-ful box3 mb-3" }
const _hoisted_60 = { class: "w-full flex items-center" }
const _hoisted_61 = {
  class: "ml-2",
  style: {"font-size":"0.8vw"}
}
const _hoisted_62 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_63 = { style: {"color":"#0C79FF"} }
const _hoisted_64 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_65 = { style: {"color":"#4EFAEE"} }
const _hoisted_66 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_67 = { style: {"color":"#1CC48B"} }
const _hoisted_68 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_69 = { style: {"color":"#DDD246"} }
const _hoisted_70 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_71 = { style: {"color":"#CB4334"} }
const _hoisted_72 = {
  class: "ml-4",
  style: {"font-size":"0.7vw","line-height":"normal"}
}
const _hoisted_73 = { style: {"color":"#D10001"} }
const _hoisted_74 = { class: "flex items-center flex-wrap" }
const _hoisted_75 = ["onClick"]
const _hoisted_76 = { class: "w-full flex items-center" }
const _hoisted_77 = ["title"]
const _hoisted_78 = ["onClick"]
const _hoisted_79 = ["onClick"]
const _hoisted_80 = ["onClick"]
const _hoisted_81 = ["onClick"]
const _hoisted_82 = {
  class: "w-full flex items-center",
  style: {"margin-top":"1%","justify-content":"space-between"}
}
const _hoisted_83 = { style: {"width":"45%"} }
const _hoisted_84 = ["src"]
const _hoisted_85 = { style: {"margin-left":"4%","width":"42%"} }
const _hoisted_86 = { class: "flex flex-wrap" }
const _hoisted_87 = {
  key: 0,
  style: {"margin-right":"12%"},
  class: "flex items-center"
}
const _hoisted_88 = { class: "text-gray-50 pl-2" }
const _hoisted_89 = {
  key: 1,
  style: {"margin-right":"12%"},
  class: "flex items-center"
}
const _hoisted_90 = { class: "text-gray-50 pl-2" }
const _hoisted_91 = { class: "flex items-center" }
const _hoisted_92 = { class: "text-gray-50 pl-2" }
const _hoisted_93 = { class: "zb_wrap" }
const _hoisted_94 = { class: "zb_wrap" }
const _hoisted_95 = { class: "w-full items-center flex" }
const _hoisted_96 = { key: 0 }
const _hoisted_97 = {
  key: 1,
  class: "ml-2 textColor",
  style: {"color":"#4EFAEE"}
}
const _hoisted_98 = { style: {"color":"#fff"} }
const _hoisted_99 = { class: "w-full flex" }
const _hoisted_100 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_101 = { class: "flex items-center" }
const _hoisted_102 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_103 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_104 = { key: 0 }
const _hoisted_105 = { key: 1 }
const _hoisted_106 = { key: 2 }
const _hoisted_107 = { key: 3 }
const _hoisted_108 = { class: "ml-2" }
const _hoisted_109 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_110 = { class: "ml-2" }
const _hoisted_111 = { key: 0 }
const _hoisted_112 = { key: 1 }
const _hoisted_113 = ["onClick"]
const _hoisted_114 = ["onClick"]
const _hoisted_115 = ["onClick"]
const _hoisted_116 = { style: {"color":"#fff"} }
const _hoisted_117 = { class: "w-full flex" }
const _hoisted_118 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch,computed  } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import { transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    transformDate3,
    getGroupListByFactory,
    getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import D3dViewer from "../components/d3d-viewer.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"

export default /*@__PURE__*/_defineComponent({
  __name: 'group',
  setup(__props) {

const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(2)
let search = ref("")
let config_3d = ref<any>({
  marks: [],
  picture:'http://192.168.0.150:9000/public/20250104/637dd30a-4c5f-47e5-9c29-babef9a3086a.glb'
});
let factory_id = ''
let group_id = ""
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
let machineAllNum = ref(0)
let sensorAllNum = ref(0)
let machineRunNum = ref(0)
let machineHealthNum = ref(0)
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: langObj.value['健康'], num: 0, color: '#61c08f' },
    { label: langObj.value['可用'], num: 0, color: '#d2de49' },
    { label: langObj.value['警戒'], num: 0, color: '#cd5f3b' },
    { label: langObj.value['故障'], num: 0, color: '#921e37' },
])
let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];
let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref([])

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    conditionSelectFac.value = factory_id
    listSelectFac.value = factory_id
    loadFactory()
   
}

// 获取工厂
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        factoryList.value = result.data.filter((p: any) => p._id === factory_id || p.parentId === factory_id)


        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }
        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
        

        getMain(groups);
      
    }

}


// 获取区域列表
const getMain = async (groups: any) => {

    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp && tempInfo.value !== 0) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? '' : temp?.toFixed(2)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
            d.latestFault = d.latestFault
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (!d.workStableStatus.condition) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }

   
    setTimeout(() => {
        // listSelectGroup.value='1687830223495'
        // selectGroup('1687830223495')
        getConditionChartData()
        getMachineWarningChartData()
        getGroupListDetail()
       
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: langObj.value['健康'], num: n1, color: '#61c08f' },
        { label: langObj.value['可用'], num: n2, color: '#d2de49' },
        { label: langObj.value['警戒'], num: n3, color: '#cd5f3b' },
        { label: langObj.value['故障'], num: n4, color: '#921e37' },
    ]

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + langObj.value["设备数"]+"：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill: theme.value=='white'? '#333':'#fff',
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        // options.graphic.style.text = name;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"]+":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === factory_id)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [factory_id]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            factoryIds,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}
// 获取严重程度名称颜色
const getMachineWarningNameBgColor = (condition: any) => {
    let name = '健康'
    let color = 'red'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = 'rgba(221, 210, 70, 0.3)'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = 'rgba(203, 67, 52, 0.3)'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = []
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo.groups)
        list1 = facInfo.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            let machineGzNum =0;
            let machineHealNum =0;
            let machineAvaNum = 0;
            let machineWarnNum=0;
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
                if (m.workStatus && m.workStatus.condition==0) {
                    machineHealNum++
                }
                if (m.workStatus && m.workStatus.condition>=1 && m.workStatus && m.workStatus.condition<3) {
                    machineAvaNum++
                }
                if (m.workStatus && m.workStatus.condition>=3 && m.workStatus && m.workStatus.condition<6) {
                    machineWarnNum++
                }
                if (m.workStatus && m.workStatus.condition>=6 && m.workStatus && m.workStatus.condition<=7) {
                    machineGzNum++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun;
            g.machineHealNum = machineHealNum
            g.machineAvaNum = machineAvaNum
            g.machineWarnNum = machineWarnNum
            g.machineGzNum = machineGzNum
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList;
   
    if(localStorage.getItem('groupId')){
        if( groupListData.value?.filter(p=>p.sonID==localStorage.getItem('groupId'))?.length>0){
             group_id =  localStorage.getItem('groupId') || ""
        }else{
            group_id = groupListData.value[0].sonID
        }
    }else{
        group_id = groupListData.value[0].sonID
    }
    
    selectGroup()
}

// 选择区域
let groupInfo: any = ref({ machines: [] })
let machineListShow: any = ref([])
let showSearchModel: any = ref({
    follow: null,
    codnition: null,
    status: null,
    keyWord:""
})
let selectGroupsInfo :any = ref([])
let machine_id = ref('')
const selectGroup = async (ev:any) => {
    selectGroupsInfo.value = []
    machineAllNum.value = 0;
    machineHealthNum.value  = 0;
    machineRunNum.value = 0;
    if(ev){
        listSelectGroup.value = ev
    }else{
        listSelectGroup.value = [group_id]
    }

    listSelectGroup.value.map((item:any)=>{
        selectGroupsInfo.value.push(groupListData.value?.find((p: any) => p.sonID === item))
    })
    selectGroupsInfo.value.map((item:any)=>{
        getMachineListShow(item)
    })
    let sensorList :any = []
    selectGroupsInfo.value.map((item:any)=>{
        machineAllNum.value = machineAllNum.value += item.machineNum;
        machineHealthNum.value  = machineHealthNum.value  += item.machineHealNum;
        machineRunNum.value  = machineRunNum.value  += item.machineRun;
        item.machines.map((d: any) => {
            sensorList = sensorList.concat(d.sensors)
        })
       
    })
    sensorAllNum.value = sensorList.length;
    
   
    
    
    if (listSelectGroup.value[0]) {
        selectModel.value = 2
        visibleChart2.value = false
        visibleChart2.value = true

        groupInfo.value = groupListData.value.find((p: any) => p.sonID === listSelectGroup.value[0])

        if (groupInfo.value?.machines?.length > 0) {
            getSensorList(groupInfo.value.machines[0])
            getRandarData(groupInfo.value.machines[0])
            getMachineWarningData(groupInfo.value.machines[0])
            machine_id.value = groupInfo.value.machines[0].id
        }
        getMachineListShow(groupInfo.value)

    } 

}

// const selectGroup2 = (ev: any) => {
//     console.log(ev)
//     listSelectGroup.value = ev.sonID
//     selectGroup(listSelectGroup.value)
// }

//筛选显示设备
const getMachineListShow2 = ()=>{
    selectGroupsInfo.value.map(item=>{
        getMachineListShow(item)
    })
}
const reset=()=>{
    showSearchModel.value={
        follow: null,
        codnition: null,
        status: null,
        keyWord:""
  }
  getMachineListShow2()
}
//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};
const getMachineListShow = (groupInfo:any) => {
  
    let list = JSON.parse(JSON.stringify(groupInfo.machines))

    if (showSearchModel.value.follow == true) {
        list = list.filter((p: any) => p.follow === showSearchModel.value.follow)
    }
    if(showSearchModel.value.follow == false) {
        list = list.filter((p: any) => !p.follow)
    }
    if (showSearchModel.value.status) {
        if (showSearchModel.value.status === 1) {
            list = list.filter((p: any) => p.workStatus && p.workStatus.wkStatus === 1)
        }
        if (showSearchModel.value.status === 2) {
            list = list.filter((p: any) => !p.workStatus || p.workStatus.wkStatus === 0)
        }
    }
    if (showSearchModel.value.codnition) {
        if (showSearchModel.value.codnition === 1) {
            list = list.filter((p: any) => !p.workStableStatus || p.workStableStatus.condition === 0)
        }
        if (showSearchModel.value.codnition === 2) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 1 && p.workStableStatus.condition <= 2)
        }
        if (showSearchModel.value.codnition === 3) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 3 && p.workStableStatus.condition <= 5)
        }
        if (showSearchModel.value.codnition === 4) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 6 && p.workStableStatus.condition <= 7)
        }
    }
    if(showSearchModel.value.keyWord){
        list = list.filter((p: any) => p.machineName?.includes(showSearchModel.value.keyWord))
    }

    list.map((p: any) => {
        p.latestFault = p.latestFault || {};
        p.fault = p.fault? p.fault : 0;
        if (p.pictures?.length > 0) {
            p.url = p.pictures[0].url;
        }
    });
   
  
    groupInfo.machineListShow = list
}


// 获取雷达图数据
const getRandarData = async (machine: any) => {
    let faultnamesSearch = {
        params: { machineId: machine.id },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get('/api/machines/faultnames', faultnamesSearch)
    let faultName = result.data.faultNames
    let faultNameII = result.data.faultNameExpects

    let Slist: any = []
    let Tlist: any = []

    if (machine.workStableStatus && machine.workStableStatus.scores) {
        for (let index in machine.workStableStatus.scores) {
            const item = machine.workStableStatus.scores[index];
            if (index.includes('S')) {
                Slist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title
                })
            }

            if (index.includes('T')) {
                Tlist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
                })
            }
        }
    }

    setTimeout(() => {
        initRandarChart(Tlist, 'chart2A')
        initRandarChart(Slist, 'chart2B')
    }, 500);
}

// 雷达图绘制
const initRandarChart = (dataList: any, id: any) => {
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChart = ec.init(document.getElementById(id));
    myChart.clear()

    if (!dataList.length) return

    let data: any = []
    let error: any = []
    let indicator: any = []
    dataList.map((d: any) => {
        data.push(d.score)
        error.push(60)
        let color = ''
        if (d.score >= 0 && d.score < 60) {
            color = '#CD5F3B'
        }
        if (d.score >= 60 && d.score < 80) {
            color = '#D2DE49'
        }
        if (d.score >= 80 && d.score <= 100) {
            color = theme.value =='white'? '#333':'#ffffff'
        }
        let name = ''
        if(language.value == "Chinese" && d.text) {
        if (d.text.length > 8) {
            name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
        } else if (d.text.length > 4 && d.text.length <= 8) {
            name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
        } else {
            name = d.text
        }
        } 

        if(language.value == "English" && d.text) {
        let list1 = d.text.split(' ')

        let str:any = ''
        let num = 0
        let sy = ''
        list1.map((n:any, i:any) => {
            if(i==0) {
            if(n.length <= 10) {
                num = n.length
                str = n
            } else {
                num = 10
                let a1 = n.substring(0,10)
                sy = n.slice(10)
                str = a1
            }
            } else {

            if(sy) {
                str = str + '\n' ;
                let n2 = sy.length + n.length
                if(n2 <= 10) {
                num = n2
                str = str + sy + ' ' + n
                sy = ''
                } else {
                num = 10
                str = str + sy
                let n2 = 10 - sy.length >=0 ? 10 - sy.length : 0

                let a1 = n.substring(0,n2)
                sy = n.slice(10)
                str = str + a1
                }
            } else {
                if(num + n.length <= 10) {
                str = str + ' ' + n
                num = num + n.length
                } else {
                str = str + '\n'  + n
                num = n.length
                }
            }
            }
        })
        name = str
        }

        indicator.push({
            color,
            max: 100,
            text:name
        })
    })

    const option = {
        title: {
            text: ''
        },
        tooltip: {
            position: { right: '-50px' }
        },
        radar: [
            {
                indicator: indicator,
                center: ['50%', '50%'],
                radius: 50,
                startAngle: 90,
                splitNumber: 5,
                axisName: {
                    formatter: '{value}',
                    fontSize: 10,
                },
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: 'rgba(46, 214, 157, 1)',
                        fontSize: 11
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: [
                            '#605969',
                            '#453A47',
                        ]
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: [
                            'rgb(241,243,250)'
                        ],
                        width: 0
                    }
                }
            }
        ],
        series: [
            {
                name: '当前值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 4
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: data,
                        lineStyle: {
                            width: 1,
                            color: 'rgba(247,220,225,0.1)',
                            normal: {
                                type: 'dotted'
                            }
                        },
                        itemStyle: {
                            color: '#FF4E13',
                            borderWidth: 5
                        },
                        areaStyle: {
                            color: '#F7DCE1'
                        }
                    }
                ]
            },
            {
                name: '预警值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 1
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: error,
                        name: '',
                        lineStyle: {
                            width: 2,
                            color: 'rgba(255, 25, 59, 0.2)'
                        },
                        itemStyle: {
                            color: 'rgba(255, 25, 59, 0.2)',
                            borderWidth: 0
                        }
                    }
                ]
            }
        ]
    }

    option && myChart.setOption(option)
}

// 获取设备报警记录
let machineWarningData2: any = ref([])
const getMachineWarningData = async (machine: any) => {
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            machineId: machine.id,
            typeList: [1]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    if (result && result.data) {
        machineWarningData2.value = result.data.list
        machineWarningData2.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}


//选择设备
let picList: any = ref([])
let picListAll: any = ref([])
let machine_info: any = ref({})
const selectMachine = (ev: any) => {
    machine_info.value = ev
    machine_id.value = ev.id
    getSensorList(ev)
    getRandarData(ev)
    getMachineWarningData(ev)
}

const getSensorList = async (machine: any) => {
    picList.value = [];
    if (machine.pictures?.length > 0) {
        picList.value.push({ name: machine.machineName, url: machine.pictures[0].url });
    }

    let config: any = {
        params: {
            machineId: machine.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors", config);

    if (result && result.status === 200) {
        result.data.map((d: any) => {
            if (d.pictures) {
                d.pictures.map((p: any) => {
                    picList.value.push({
                        name: d.sensorPlace,
                        url: p.url
                    })
                })

            }
        })
    }

    picListAll.value = picList.value

};
//智能诊断报警记录

// let handleStatusList:any=ref([
//     { label: '未处理', value: 2 },
//     { label: '已处理', value: 3 },
// ])
let handleStatusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let znZdWarnVisible = ref(false);
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let zdWarnData = ref([])
let znZdWarnSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type:1
})
const paginationZdWarn = computed(() => ({
  total: znZdWarnSearch.value.total,
  current: znZdWarnSearch.value.skip,
  pageSize: znZdWarnSearch.value.take,
}));
 

const znZdWarnColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];
const selectZnZdWarn = () => {
    znZdWarnVisible.value = true
    resetZdWarn()
   
    
    getZdWarnData()
}  
let machineListZdWarn = ref([])
// 选择区域
const selectGroupZdWarn = (ev:any) => {
   
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getZdWarnData = async () => {
    

  let config: any = {
    params: {
      skip: znZdWarnSearch.value.skip,
      take: znZdWarnSearch.value.take,
      groupId: znZdWarnSearch.value.groupId,
      machineId: znZdWarnSearch.value.machineId,
      type: znZdWarnSearch.value.type,
      handleStatus: znZdWarnSearch.value.handleStatus,
      expertCertification: znZdWarnSearch.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(znZdWarnSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
  }
   
  if (znZdWarnSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == znZdWarnSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    zdWarnData.value = result.data.list;
    znZdWarnSearch.value.total = result.data.total;
  }
};

const resetZdWarn = () => {
    znZdWarnSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:1
    }
    // getZdWarnData()
}
const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    znZdWarnSearch.value.sort = sorter.field;
    znZdWarnSearch.value.order = order;
  }
  // 处理分页数据
  znZdWarnSearch.value.skip = pagination.current;
  znZdWarnSearch.value.take = pagination.pageSize;

  getZdWarnData();
};





//关注、取关
const follow = async (ev: any, value: any) => {
    ev.follow = value
    let form = {
        machineId: ev.id,
        follow: value
    }

    let result = await request.post('/api/machines/follow', form)
    if(result?.data) {
        let info = groupInfo.value.machines.find((p:any) => p.id === ev.id)
        if(info) {
            info.follow = value
        }
    }
}


// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}


return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_environment_outlined = _resolveComponent("environment-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[18] || (_cache[18] = _createElementVNode("img", {
            class: "mr-1",
            src: _imports_0
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(langObj)['监测设备']) + ":", 1),
          _createTextVNode(_toDisplayString(_unref(machineAllNum)) + _toDisplayString(_unref(langObj)['台']), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[19] || (_cache[19] = _createElementVNode("img", {
            class: "mr-1",
            src: _imports_1
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(langObj)['监测测点']) + ":", 1),
          _createTextVNode(_toDisplayString(_unref(sensorAllNum)) + "个 ", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[20] || (_cache[20] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_a_select, {
            ref: "select",
            value: _unref(listSelectFac),
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(listSelectFac) ? (listSelectFac).value = $event : listSelectFac = $event)),
            style: {"width":"100%"},
            onChange: getGroupListDetail,
            dropdownMatchSelectWidth: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: index,
                  value: item._id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.factoryName), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_CaretDownOutlined, { class: "right_arrow" })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_right_outlined)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_right_outlined)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_right_outlined)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_a_select, {
            ref: "select",
            mode: "multiple",
            value: _unref(listSelectGroup),
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_isRef(listSelectGroup) ? (listSelectGroup).value = $event : listSelectGroup = $event)),
            style: {"width":"100%"},
            onChange: selectGroup,
            dropdownMatchSelectWidth: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList2), (item, index) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: index,
                  value: item.sonID
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_CaretDownOutlined, { class: "right_arrow" })
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[21] || (_cache[21] = _createElementVNode("img", {
            class: "mr-1",
            src: _imports_3
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(langObj)['运行设备']) + ":", 1),
          _createTextVNode(_toDisplayString(_unref(machineRunNum)) + _toDisplayString(_unref(langObj)['台']), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[22] || (_cache[22] = _createElementVNode("img", {
            class: "mr-1",
            src: _imports_4
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(langObj)['健康设备']) + ":", 1),
          _createTextVNode(_toDisplayString(_unref(machineHealthNum)) + _toDisplayString(_unref(langObj)['台']), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _cache[23] || (_cache[23] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(langObj)['设备诊断']), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            (_unref(visibleChart2))
              ? (_openBlock(), _createElementBlock("div", _hoisted_22))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(langObj)['健康指标']), 1)
          ]),
          _createElementVNode("div", _hoisted_24, [
            (_unref(visibleChart2))
              ? (_openBlock(), _createElementBlock("div", _hoisted_25))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(langObj)['振动指标']), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _cache[24] || (_cache[24] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(langObj)['设备照片']), 1)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createVNode(pictureListComponent, {
            type: 4,
            picList: _unref(picListAll)
          }, null, 8, ["picList"])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _cache[25] || (_cache[25] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(langObj)['智能诊断报警']), 1),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              onClick: selectZnZdWarn,
              class: "flex items-center cursor-pointer whiteBgColor p-text-right",
              style: {"color":"#ffffffe6"}
            }, [
              _createElementVNode("span", null, _toDisplayString(_unref(langObj)['查看更多']), 1),
              _createVNode(_component_right_outlined)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(langObj)['设备名称']), 1),
              _createElementVNode("div", _hoisted_37, _toDisplayString(_unref(langObj)['报警类型']), 1),
              _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(langObj)['严重程度']), 1),
              _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(langObj)['报警时间']), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData2), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full flex items-center",
                key: index,
                style: _normalizeStyle({ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' })
              }, [
                _createElementVNode("div", _hoisted_40, _toDisplayString(item.machineName), 1),
                _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(langObj)[_unref(warningTypeList).find(p => p.value === item.type)?.label]), 1),
                _createElementVNode("div", {
                  class: "table-info",
                  style: _normalizeStyle({ 'color': getMachineWarningName(item.condition)?.color })
                }, _toDisplayString(_unref(langObj)[getMachineWarningName(item.condition)?.name]), 5),
                _createElementVNode("div", _hoisted_42, _toDisplayString(item.date), 1)
              ], 4))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _cache[27] || (_cache[27] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_46, _toDisplayString(_unref(langObj)['设备概览']), 1)
          ]),
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createVNode(_component_a_select, {
            class: "right_select_wrap",
            ref: "select",
            value: _unref(showSearchModel).follow,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(showSearchModel).follow) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: null }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_47, [
                    (_unref(theme)=='white')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_48))
                      : (_openBlock(), _createElementBlock("img", _hoisted_49)),
                    _createElementVNode("div", _hoisted_50, _toDisplayString(_unref(langObj)['全部']), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: true }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_51, [
                    (_unref(theme)=='white')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_52))
                      : (_openBlock(), _createElementBlock("img", _hoisted_53)),
                    _createElementVNode("div", _hoisted_54, _toDisplayString(_unref(langObj)['关注']), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: false }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_55, [
                    (_unref(theme)=='white')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_56))
                      : (_openBlock(), _createElementBlock("img", _hoisted_57)),
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_unref(langObj)['未关注']), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_a_select, {
            class: "right_select_wrap",
            ref: "select",
            value: _unref(showSearchModel).status,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(showSearchModel).status) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: null }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['运行状态']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['运行']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['停机']), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_a_select, {
            class: "right_select_wrap",
            ref: "select",
            value: _unref(showSearchModel).codnition,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(showSearchModel).codnition) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: null }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['健康状态']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['健康']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['可用']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 3 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['警戒']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 4 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['故障']), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_a_input, {
            class: "right_select_wrap",
            value: _unref(showSearchModel).keyWord,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(showSearchModel).keyWord) = $event)),
            placeholder: _unref(langObj)['请输入设备名称搜索']
          }, {
            suffix: _withCtx(() => [
              _createVNode(_component_search_outlined)
            ]),
            _: 1
          }, 8, ["value", "placeholder"]),
          _createElementVNode("div", {
            onClick: reset,
            class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
          }, _toDisplayString(_unref(langObj)["重置"]), 1),
          _createElementVNode("div", {
            onClick: getMachineListShow2,
            class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
            style: {"background-color":"#072499"}
          }, _toDisplayString(_unref(langObj)["查询"]), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectGroupsInfo), (group, index1) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_59, [
            _createElementVNode("div", _hoisted_60, [
              _createVNode(_component_environment_outlined, { style: {"color":"#06DEF5","font-size":"0.7vw"} }),
              _createElementVNode("div", _hoisted_61, _toDisplayString(group.name), 1),
              _createElementVNode("div", _hoisted_62, [
                _createTextVNode(_toDisplayString(_unref(langObj)['设备总数']) + ":", 1),
                _createElementVNode("span", _hoisted_63, _toDisplayString(group.machineNum), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _createElementVNode("div", _hoisted_64, [
                _createTextVNode(_toDisplayString(_unref(langObj)['运行数']) + ":", 1),
                _createElementVNode("span", _hoisted_65, _toDisplayString(group.machineRun), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _createElementVNode("div", _hoisted_66, [
                _createTextVNode(_toDisplayString(_unref(langObj)['健康设备']) + ":", 1),
                _createElementVNode("span", _hoisted_67, _toDisplayString(group.machineHealNum), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _createElementVNode("div", _hoisted_68, [
                _createTextVNode(_toDisplayString(_unref(langObj)['可用设备']) + ":", 1),
                _createElementVNode("span", _hoisted_69, _toDisplayString(group.machineAvaNum), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _createElementVNode("div", _hoisted_70, [
                _createTextVNode(_toDisplayString(_unref(langObj)['警戒设备']) + ":", 1),
                _createElementVNode("span", _hoisted_71, _toDisplayString(group.machineWarnNum), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _createElementVNode("div", _hoisted_72, [
                _createTextVNode(_toDisplayString(_unref(langObj)['故障设备']) + ":", 1),
                _createElementVNode("span", _hoisted_73, _toDisplayString(group.machineGzNum), 1),
                _createTextVNode(_toDisplayString(_unref(langObj)['台']), 1)
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_74, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.machineListShow, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  onClick: ($event: any) => (selectMachine(item)),
                  key: index,
                  style: _normalizeStyle({ 'background': item.id === _unref(machine_id) ? '#001672' : '#020F46', 'border-color': item?.workStableStatus?.condition ? getMachineWarningName(item?.workStableStatus?.condition).color : '#0d53b7cc' }),
                  class: _normalizeClass(["box4 cursor-pointer mt-3 mr-3", item.id === _unref(machine_id)?'theme-box4-selected':''])
                }, [
                  _createElementVNode("div", _hoisted_76, [
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "shu" }, null, -1)),
                    _createElementVNode("div", {
                      class: "size14 ml-2",
                      style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},
                      title: item.machineName
                    }, _toDisplayString(item.machineName), 9, _hoisted_77),
                    _cache[31] || (_cache[31] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    (_unref(theme)=='white')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.follow)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "picIcon",
                                onClick: ($event: any) => (follow(item, false)),
                                src: _imports_5,
                                alt: ""
                              }, null, 8, _hoisted_78))
                            : _createCommentVNode("", true),
                          (!item.follow)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: "picIcon",
                                onClick: ($event: any) => (follow(item, true)),
                                src: _imports_7,
                                alt: ""
                              }, null, 8, _hoisted_79))
                            : _createCommentVNode("", true)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (item.follow)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "picIcon",
                                onClick: ($event: any) => (follow(item, false)),
                                src: _imports_5,
                                alt: ""
                              }, null, 8, _hoisted_80))
                            : _createCommentVNode("", true),
                          (!item.follow)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: "picIcon",
                                onClick: ($event: any) => (follow(item, true)),
                                src: _imports_8,
                                alt: ""
                              }, null, 8, _hoisted_81))
                            : _createCommentVNode("", true)
                        ], 64))
                  ]),
                  _cache[36] || (_cache[36] = _createElementVNode("div", { class: "heng" }, null, -1)),
                  _createElementVNode("div", _hoisted_82, [
                    _createElementVNode("div", _hoisted_83, [
                      _createElementVNode("img", {
                        style: {"height":"7.5vh","margin":"0 auto","display":"block"},
                        src: item.url,
                        alt: ""
                      }, null, 8, _hoisted_84)
                    ]),
                    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", _hoisted_85, [
                      _createElementVNode("div", _hoisted_86, [
                        (item.workStatus && item.workStatus.wkStatus)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                              _cache[32] || (_cache[32] = _createElementVNode("div", { class: "line" }, [
                                _createElementVNode("div", { class: "line animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_88, _toDisplayString(_unref(langObj)['运行']), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_89, [
                              _cache[33] || (_cache[33] = _createElementVNode("div", { class: "down" }, [
                                _createElementVNode("div", { class: "down animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_90, _toDisplayString(_unref(langObj)['停机']), 1)
                            ])),
                        _createElementVNode("div", _hoisted_91, [
                          _createElementVNode("div", {
                            class: "line",
                            style: _normalizeStyle({ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color })
                          }, [
                            _createElementVNode("div", {
                              class: "line animation",
                              style: _normalizeStyle({ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color })
                            }, null, 4)
                          ], 4),
                          _createElementVNode("span", _hoisted_92, _toDisplayString(_unref(langObj)[getMachineWarningName(item?.workStableStatus?.condition).name ]), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_93, [
                        _createElementVNode("div", null, _toDisplayString(_unref(langObj)['高频加速度']), 1),
                        _createElementVNode("div", null, _toDisplayString(Number(item.sz)?.toFixed(2)) + "mm/s", 1)
                      ]),
                      _createElementVNode("div", _hoisted_94, [
                        _createElementVNode("div", null, _toDisplayString(_unref(langObj)['平均故障间隔']), 1),
                        _createElementVNode("div", null, _toDisplayString(item.workStatus?.faultIntervalTimes?.toFixed(2) || 0.00) + _toDisplayString(_unref(langObj)['小时']), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "w-full warn_wrap",
                    style: _normalizeStyle({'background-color': item?.workStableStatus?.condition ? getMachineWarningNameBgColor(item?.workStableStatus?.condition).color : 'rgba(10, 161, 255, 0.28)'})
                  }, [
                    _createElementVNode("div", null, _toDisplayString(item.latestFault?.date || "--") + " " + _toDisplayString(item.latestFault?.diagnosticNotes || "--"), 1)
                  ], 4),
                  _createElementVNode("div", _hoisted_95, [
                    (_unref(machine_info)?.workStatus?.onLine)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_96, _toDisplayString(_unref(langObj)['累计运行']), 1))
                      : _createCommentVNode("", true),
                    (_unref(machine_info)?.workStatus?.onLine)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_97, _toDisplayString(_unref(machine_info).workStatus.onLine) + _toDisplayString(_unref(langObj)['小时']), 1))
                      : _createCommentVNode("", true),
                    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ])
                ], 14, _hoisted_75))
              }), 128))
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(_component_a_modal, {
      visible: _unref(znZdWarnVisible),
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(znZdWarnVisible) ? (znZdWarnVisible).value = $event : znZdWarnVisible = $event)),
      closable: false,
      footer: null,
      style: {"top":"10%"},
      width: "80%",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_98, [
          _createElementVNode("div", _hoisted_99, [
            _cache[37] || (_cache[37] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(znZdWarnVisible) //@ts-ignore
 ? znZdWarnVisible.value = false : znZdWarnVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_100, _toDisplayString(_unref(getLanguage)('智能诊断报警记录', _unref(language))), 1),
          _createVNode(_component_a_form, {
            model: _unref(znZdWarnSearch),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('设备区域', _unref(language)),
                name: "groupId",
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    onChange: selectGroupZdWarn,
                    value: _unref(znZdWarnSearch).groupId,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(znZdWarnSearch).groupId) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.sonID
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['设备'],
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).machineId,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(znZdWarnSearch).machineId) = $event)),
                    style: {"width":"220px"},
                    placeholder: _unref(langObj)['请选择设备'],
                    options: _unref(machineListZdWarn),
                    "filter-option": _ctx.filterOptionZdReport,
                    "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                    allowClear: "",
                    "show-search": ""
                  }, null, 8, ["value", "placeholder", "options", "filter-option"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('报警时间', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: _unref(znZdWarnSearch).date,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(znZdWarnSearch).date) = $event)),
                    "disabled-date": _ctx.disabledDate,
                    onCalendarChange: _ctx.onCalendarChange
                  }, null, 8, ["value", "disabled-date", "onCalendarChange"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('处理状态', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).handleStatus,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(znZdWarnSearch).handleStatus) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('严重程度', _unref(language)),
                name: "condition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).condition,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(znZdWarnSearch).condition) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_101, [
                    _createElementVNode("div", {
                      onClick: resetZdWarn,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                    _createElementVNode("div", {
                      onClick: getZdWarnData,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_102, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": _ctx.getRowClassName,
              columns: znZdWarnColumns,
              "data-source": _unref(zdWarnData),
              pagination: paginationZdWarn.value,
              onChange: handleTableChangeZdWarn
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'type')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_103, [
                      (text === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_104, _cache[38] || (_cache[38] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_9,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_105, _cache[39] || (_cache[39] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_10,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_106, _cache[40] || (_cache[40] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_11,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 4)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_107, _cache[41] || (_cache[41] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_12,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_108, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                            background: _unref(conditionList).find((p) => p.value === text)
                                                ?.color,
                                            }])
                      }, null, 4),
                      _createElementVNode("div", _hoisted_110, _toDisplayString(_unref(langObj)[
                                                _unref(conditionList).find((p) => p.value === text)?.label
                                                ]), 1)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'handleStatus')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (text === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_111, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                        : _createCommentVNode("", true),
                      (text !== 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_112, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                ? _unref(langObj)[
                                                _unref(handleStatusList).find((p) => p.value === text)?.label
                                                ]
                                                : _unref(langObj)["未处理"]), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      (record?.reportIds?.length>0)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            onClick: ($event: any) => (viewReport(record))
                          }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_113))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      (record?.repairReportId)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            onClick: ($event: any) => (detailsJx(record))
                          }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_114))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      (record.handleStatus === 3)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            onClick: ($event: any) => (viewClose(record))
                          }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_115))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["row-class-name", "data-source", "pagination"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: "50%",
      closable: false,
      footer: null,
      visible: _unref(jxVisible),
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(jxVisible) ? (jxVisible).value = $event : jxVisible = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(maintenanceReportDetails, {
          ref_key: "mrD",
          ref: mrD,
          onCancel: cancelJx
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: "50%",
      closable: false,
      footer: null,
      visible: _unref(visibleDR),
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(DiagnosticReportDetails, {
          ref_key: "mrDref",
          ref: mrDref,
          onCancel: cancelDR
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibledel2),
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_116, [
          _createElementVNode("div", _hoisted_117, [
            _cache[42] || (_cache[42] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_118, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
          _createVNode(_component_a_form, {
            ref: "formRef",
            name: "advanced_search",
            class: "ant-advanced-search-form",
            style: {"margin-top":"20px"},
            model: _unref(formClose)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "closeCause",
                        label: "关闭原因",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _unref(formClose).closeCause,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                            placeholder: "",
                            rows: 4,
                            readonly: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})