<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full px-5 py-6 bule_list_page flex">
      <div class="page_left">
      <machineTypeListLeftComponent @selectType="selectType"></machineTypeListLeftComponent>
      </div>
      <div class="flex-1 page_fight">
        <a-form layout="inline">
        <a-form-item :label="langObj['细分故障']">
          <a-input
            v-model:value="searchModel.segmentFault"
            :placeholder="langObj['搜索细分故障']"
          >
          </a-input>
        </a-form-item>

        <a-form-item :label="langObj['故障原因']">
          <a-input
            v-model:value="searchModel.faultCause"
            :placeholder="langObj['搜索故障原因']"
          >
          </a-input>
        </a-form-item>

        <a-form-item :label="langObj['建议及措施']">
          <a-input
            v-model:value="searchModel.faultSuggest"
            :placeholder="langObj['搜索建议及措施']"
          >
          </a-input>
        </a-form-item>

        <a-form-item label="">
          <a-button type="primary" @click="dealWithData">
            <template #icon><SearchOutlined /></template
            >{{ langObj["搜索"] }}</a-button
          >
        </a-form-item>
        <a-form-item label="">
          <a-button type="primary" @click="exportExceel">
            <template #icon><FileExcelOutlined /></template
            >{{ langObj["导出"] }}</a-button
          >
        </a-form-item>
      </a-form>

      <a-tabs size="large" v-model:activeKey="activeKey" @change="selectTab">
        <a-tab-pane
          style="font-size: 18px"
          key="Expect"
          :tab="langObj['健康指标']"
        ></a-tab-pane>
        <a-tab-pane key="Common" :tab="langObj['振动指标']"></a-tab-pane>
      </a-tabs>

      <a-table
        bordered
        :emptyText="langObj['暂无数据']"
        :columns="columns"
        :data-source="dataList"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'segmentFault'">
            <span v-if="language === 'Chinese'">{{ text }}</span>
            <span v-if="language === 'English'">{{ record['segmentFaultEng'] || text }}</span>
          </template>
          <template v-if="column.dataIndex === 'faultExplain'">
            <span v-if="language === 'Chinese'">{{ text }}</span>
            <span v-if="language === 'English'">{{ record['faultExplainEng'] || text }}</span>
          </template>
          <template v-if="column.dataIndex === 'faultCause'">
            <span v-if="language === 'Chinese'">{{ text }}</span>
            <span v-if="language === 'English'">{{ record['faultCauseEng'] || text }}</span>
          </template>
          <template v-if="column.dataIndex === 'faultSuggest'">
            <span v-if="language === 'Chinese'">{{ text }}</span>
            <span v-if="language === 'English'">{{ record['faultSuggestEng'] || text }}</span>
          </template>

          <template v-if="column.dataIndex === 'act'">
            <a v-if="isEditable" @click="edit(record)">{{ langObj["编辑"] }}</a>
          </template>
        </template>
      </a-table>

      </div>
    
      <a-modal
        v-model:visible="visible"
        :title="langObj['编辑']"
        :maskClosable="false"
        :footer="null"
        width="800px"
      >
        <a-form
          :model="form"
          name="basic"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-item
            :label="langObj['类型']"
            name="featureType"
            :rules="[{ required: true, message: langObj['请选择类型'] }]"
          >
            <!-- <a-input v-model:value="form.featureType" /> -->
            <a-select
              ref="select"
              v-model:value="form.featureType"
              :options="typeList"
            >
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['细分故障']" name="segmentFault">
            <a-input v-model:value="form.segmentFault" />
          </a-form-item>

          <a-form-item :label="langObj['故障释义']" name="faultExplain">
            <a-textarea v-model:value="form.faultExplain" :rows="4" />
          </a-form-item>

          <a-form-item :label="langObj['故障原因']" name="faultCause">
            <a-textarea v-model:value="form.faultCause" :rows="4" />
          </a-form-item>

          <a-form-item :label="langObj['建议及措施']" name="faultSuggest">
            <a-textarea v-model:value="form.faultSuggest" :rows="4" />
          </a-form-item>

          <a-form-item :wrapper-col="{ offset: 7, span: 14 }">
            <a-button type="primary" @click="submit">{{
              langObj["提交"]
            }}</a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { ref, reactive, toRaw, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({});
let language: any = ref("Chinese");
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import machineTypeListLeftComponent from "./machine-type-left.vue";
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();
operateLogs("访问知识库");
const columns = [
  // {
  //         title: langObj.value['类型'],
  //         dataIndex: 'featureType',
  //         width: '120px',
  //     },
  {
    title: langObj.value["细分故障"],
    dataIndex: "segmentFault",
    width: "120px",
  },
  {
    title: langObj.value["故障释义"],
    dataIndex: "faultExplain",
    width: "150px",
  },
  {
    title: langObj.value["故障原因"],
    dataIndex: "faultCause",
    width: "150px",
  },
  {
    title: langObj.value["建议及措施"],
    dataIndex: "faultSuggest",
    width: "150px",
  },
  // {
  //   title: langObj.value["操作"],
  //   dataIndex: "act",
  //   width: "60px",
  // },
];

let activeKey = ref("Expect");

let searchModel: any = ref({
  segmentFault: "",
  faultCause: "",
  faultSuggest: "",
  skip: 1,
  take: 10,
  total: 0,
});

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let dataListAll: any = ref([]);
let dataList: any = ref([]);
let visible = ref(false);
let form: any = ref({});

const selectTypes:any = ref({})

let machineTypeListAll = ref([]) 
const selectType = async(type:any,all:any)=>{
    selectTypes.value = type||{faultIds:[]}
    machineTypeListAll.value = all
    dataList.value=[];
    await search()
    dealWithData();
   
  

}

const typeList = [
  { label: "健康指标", value: "Expect" },
  { label: "振动指标", value: "Common" },
];
let isEditable: any = ref(false);

onMounted(() => {
  init();
});

const init = () => {
  if (JSON.parse(localStorage.getItem("userName") || "") === "freqx_yszh") {
    isEditable.value = false;
  } else {
    isEditable.value = true;
  }
  searchModel.value.skip = 1;
  const config = {
    params: { take: 1000000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/machine-type/list", config).then((res: any) => {
    if (res) {
      machineTypeList.value = res?.data;
    }
  });
  search();
};

let machineTypeList: any = ref([]);

const search = async () => {
  dataListAll.value = [];
  if(selectTypes.value?.faultIds?.length==0){
    message.warning("未绑定！");
    return false;
  }
  

  const config = {
    params: { take: 1000000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.post(
    "/api/faultinfos",
    { FeatureType: "",faultIds: selectTypes.value.faultIds},
    config
  );
  if (result.status === 200) {
    dataListAll.value = result.data;
  } else {
    dataListAll.value = [];
  }

  // dealWithData();
};

const dealWithData = () => {
  let list = JSON.parse(JSON.stringify(dataListAll.value));
  // &&selectTypes.value.faultIds.indexOf(p.id)>-1
  list = list.filter((p: any) => p.featureType === activeKey.value);
  if (searchModel.value.segmentFault) {
    list = list.filter(
      (p: any) =>
        p.segmentFault &&
        p.segmentFault.includes(searchModel.value.segmentFault)
    );
  }
  if (searchModel.value.faultCause) {
    list = list.filter(
      (p: any) =>
        p.faultCause && p.faultCause.includes(searchModel.value.faultCause)
    );
  }
  if (searchModel.value.faultSuggest) {
    list = list.filter(
      (p: any) =>
        p.faultSuggest &&
        p.faultSuggest.includes(searchModel.value.faultSuggest)
    );
  }

  dataList.value = list;
  searchModel.value.total = dataList.value.length;
};

const edit = (ev: any) => {
  form.value = JSON.parse(JSON.stringify(ev));
  visible.value = true;
};

const submit = async () => {
  operateLogs("修改数据");
  let result = await request.put("/api/faultinfos", form.value);
  if (result && result.data) {
    message.success("操作成功");
    visible.value = false;
    init();
  } else {
    message.warning("操作失败,请联系管理员！");
  }
};

const handleTableChange = (ev: any) => {
  searchModel.value.skip = ev.current;
  searchModel.value.take = ev.pageSize;
};

// 选择指标
const selectTab = (ev: any) => {
  searchModel.value.skip = 1;
  dealWithData();
};

const exportExceel=async ()=>{
    // Expect Common   p.featureType === activeKey.value&&
    let faultinfos = []
    const config = {
    params: { take: 1000000 },
    headers: {
      requestId: uuidv4(),
    },
  };
    let result = await request.post(
    "/api/faultinfos",
      {},
      config
    );
    if (result.status === 200) {
      faultinfos = result.data;
    } 

    let list:any=[]
    console.log(machineTypeListAll.value,"machineTypeListAll.value")

    machineTypeListAll.value.map((item:any)=>{
        (item.faultIds||[]).map((t:any)=>{
            const fault = faultinfos.find((tt:any)=>tt.id==t)

            list.push({
                FirstCategory:item.FirstCategory,
                SecondCategory:item.SecondCategory,
                ThirdCategory:item.ThirdCategory,
                featureType:fault.featureType=="Expect"?"健康指标":"振动指标",
                faultName:fault.faultName,
                faultExplain:fault.faultExplain,
                faultCause:fault.faultCause,
                faultSuggest:fault.faultSuggest,

                FirstCategoryEng:item.FirstCategoryEng,
                SecondCategoryEng:item.SecondCategoryEng,
                ThirdCategoryEng:item.ThirdCategoryEng,
                featureTypeEng:fault.featureType,
                faultNameEng:fault.faultNameEng,
                faultExplainEng:fault.faultExplainEng,
                faultCauseEng:fault.faultCauseEng,
                faultSuggestEng:fault.faultSuggestEng,
            })
        })
    })

    const tabledata: any =JSON.parse(JSON.stringify(list))  ;
    exportToExcel(tabledata, "设备故障类型"+new Date().getTime());
}

const exportToExcel = (data: any, fileName: any) => {
    data = data.map((t:any)=>({
        '一级类型':t.FirstCategory,
        '二级类型':t.SecondCategory,
        '三级类型':t.ThirdCategory,
        '故障类型':t.featureType,
        '细分故障':t.faultName,
        '故障释义':t.faultExplain,
        '故障原因':t.faultCause,
        '建议及措施':t.faultSuggest
    }))

    const worksheet = XLSX.utils.json_to_sheet(data);

    //worksheet['!merges'] =merges
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(dataBlob, fileName + ".xlsx");
}

</script>
<style scoped>
.bule_list_page {
  height: calc(100vh - 100px) !important;
}
.page_fight{
    padding-left: 20px;
}
</style>