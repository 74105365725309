<template>
  <div class="box" style="width: 250px; height: 85vh; overflow: auto">
    <div class="w-full flex items-center">
      <a-input v-model:value="search" :placeholder="langObj['请输入名称搜索']">
        <template #suffix>
          <search-outlined @click="searchType" />
        </template>
      </a-input>
    </div>

    <div class="w-full" style="color: #fff">
      <div
        v-for="(factory, index1) in machineTypeList"
        :key="index1"
        style="margin-top: 20px"
        v-show="!factory.isHide"
      >
        <div
          class="w-full flex items-center cursor-pointer"
          style="font-size: 18px"
          @click="factory.isShow = !factory.isShow"
        >
          <img style="width: 18px" src="@/assets/facIco.png" alt="" />
          <div
            class="flex-1 ml-2 ellipsis"
            :title="factory.value"
          >
            {{ factory.value }}
          </div>
          <caret-up-outlined v-if="factory.isShow" style="font-size: 18px" />
          <caret-down-outlined v-if="!factory.isShow" style="font-size: 18px" />
        </div>
        <div v-if="factory.isShow">
          <div
            class="w-full"
            style="margin-top: 30px; margin-left: 10px"
            v-for="(group, index2) in factory.children"
            v-show="!group.isHide"
            :key="index2"
          >
            <div
              class="w-full flex items-center cursor-pointer"
              style="font-size: 16px"
              @click="group.isShow = !group.isShow"
            >
              <img style="width: 16px" src="@/assets/groupIco.png" alt="" />
              <div
                class="flex-1 ml-2 ellipsis"
                :title="group.value"
              >
                {{ group.value }}
              </div>
              <caret-up-outlined v-if="group.isShow" style="font-size: 16px" />
              <caret-down-outlined
                v-if="!group.isShow"
                style="font-size: 16px"
              />
            </div>

            <div v-if="group.isShow">
              <div
                class="w-full"
                style="margin-top: 20px; margin-left: 10px"
                v-for="(machine, index3) in group.children"
                v-show="!machine.isHide"
                :key="index3"
              >
                <div
                  @click="selectType(machine, machineTypeListAll)"
                  :class="{ 'select-machine': machine.id === selectId }"
                  class="w-full flex items-center machine"
                  style="font-size: 14px"
                >
                  <div class="flex-1">{{ machine.value }}</div>

                  <!-- <caret-up-outlined style="font-size:20px"/> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";

let dataList: any = ref([]);
let selectId: any = ref("");
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const props = defineProps<{ type?: number; from?: number; mode?: number }>();

let type = ref(props.type || 1);
let from = ref(props.from || 2);

let search = ref("");

onMounted(() => {
  init();
});

let machineTypeListAll = ref([]);
let machineTypeList = ref([]);
//获取设备列表/测点列表
const init = () => {
  getMachineType();

  // if (props.type === 1 || props.type === 3) {
  //   if (localStorage.getItem('machine_id')) {
  //     emits('selectType', localStorage.getItem('machine_id'))
  //   }
  //   getMachineList()
  // }

  // if (props.type === 2) {
  //   if (localStorage.getItem('sensor_id')) {
  //     emits('selectSensor', localStorage.getItem('sensor_id'))
  //   }
  //   getSensorList()
  // }
};
const searchType = async () => {
  // search.value
  var key = search.value;
  machineTypeList.value?.map((t: any) => {
    t.children.map((tt: any) => {
      tt.children.map((ttt: any) => {
        if (key) {
          if (t.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
          }
          if (tt.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
            tt.isShow = true;
            tt.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
            tt.isShow = false;
            tt.isHide = true;
          }
          if (ttt.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
            tt.isShow = true;
            tt.isHide = false;
            ttt.isShow = true;
            ttt.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
            tt.isShow = false;
            tt.isHide = true;
            ttt.isShow = false;
            ttt.isHide = true;
          }
        } else {
            t.isShow = false;
            t.isHide = false;
            tt.isShow = false;
            tt.isHide = false;
            ttt.isShow = false;
            ttt.isHide = false;
        }
      });
    });
  });
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result?.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
        isShow: false,
        isHide: false,
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
          isShow: false,
          isHide: false,
        };

        let list2 = result.data.filter(
          (p: any) => p.SecondCategory === d2 && p.FirstCategory === d1
        );

        list2.map((d3: any) => {
          let third = {
            value: d3.ThirdCategory,
            label: d3.ThirdCategory,
            id: d3.id,
            faults: d3.faults || [],
            isShow: false,
            isHide: false,
            faultIds:d3.faultIds || []
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    list[0].isShow = true;
    list[0].children[0].isShow = true;
    list[0].children[0].children[0].isShow = true;
    selectId.value = list[0].children[0].children[0].id;
    selectType(list[0].children[0].children[0], machineTypeListAll.value);
    machineTypeList.value = list;
  }
};

// 选择设备类型
const selectType = (ev: any, all: any) => {
  selectId.value = ev.id;
  emits("selectType", ev, all);
};

const emits = defineEmits(["selectType"]);
</script>

<style lang="less" scoped>
.select-machine {
  background-color: #3136af;
}

.machine {
  padding: 4px 2px;
  border-radius: 2px;
  cursor: pointer;
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.box::-webkit-scrollbar {
  width: 5px; //修改滚动条宽度
}

.box::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #00249b;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

.txt-select {
  border-bottom: 2px solid #00fff4;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-input {
  border: 0px solid #0d53b7cc !important;
}

.ellipsis {
  overflow: hidden;
  /* 隐藏超出部分内容 */
  white-space: nowrap;
  /* 不换行 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
</style>
