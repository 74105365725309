import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/screen/p_on_bg.png'
import _imports_1 from '@/assets/screen/p_off_bg.png'
import _imports_2 from '@/assets/screen/p_monitor_bg.png'
import _imports_3 from '@/assets/screen/p_quu_bg.png'
import _imports_4 from '@/assets/zhenduan.png'
import _imports_5 from '@/assets/weixiu.png'
import _imports_6 from '@/assets/jiancha.png'
import _imports_7 from '@/assets/menxian.png'
import _imports_8 from '@/assets/screen/p_company_bg.png'
import _imports_9 from '@/assets/screen/p_factory_bg.png'
import _imports_10 from '@/assets/screen/p_qu_bg.png'


const _hoisted_1 = {
  class: "px-4 py-2 theme-bg theme-factory theme-common",
  style: {"background-color":"#000A31","color":"#fff","height":"calc(100vh - 100px)","overflow":"hidden"}
}
const _hoisted_2 = {
  class: "w-full flex",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  style: {"height":"100%","overflow":"hidden"},
  class: "flex flex-col mt-5 box-size"
}
const _hoisted_4 = { class: "w-full state_wrap conditon_state" }
const _hoisted_5 = { class: "title flex items-center" }
const _hoisted_6 = { class: "p-text-color2 ml-10" }
const _hoisted_7 = {
  class: "w-full flex items-center bg_wrap justify-center",
  style: {"padding":"2% 0"}
}
const _hoisted_8 = { style: {"width":"60%","height":"18vh","margin-top":"-10%","position":"relative"} }
const _hoisted_9 = { class: "pie_tex_wrap" }
const _hoisted_10 = { class: "ml-2" }
const _hoisted_11 = { class: "rate_wrap" }
const _hoisted_12 = { class: "ml-2" }
const _hoisted_13 = { class: "rate_wrap" }
const _hoisted_14 = { class: "ml-2" }
const _hoisted_15 = { class: "rate_wrap" }
const _hoisted_16 = { class: "ml-2" }
const _hoisted_17 = { class: "rate_wrap" }
const _hoisted_18 = { class: "w-full state_wrap monitor_state mt-5" }
const _hoisted_19 = { class: "title flex items-center" }
const _hoisted_20 = { class: "p-text-color2 ml-10" }
const _hoisted_21 = { class: "w-full flex items-center bg_wrap" }
const _hoisted_22 = { class: "moitor_clip cursor-pointer" }
const _hoisted_23 = { class: "off_text" }
const _hoisted_24 = { class: "moitor_clip" }
const _hoisted_25 = { class: "monitor_text" }
const _hoisted_26 = { class: "w-full monitor_state" }
const _hoisted_27 = { class: "w-full flex items-center mt-7" }
const _hoisted_28 = { class: "title flex items-center" }
const _hoisted_29 = { class: "p-text-color2 ml-10" }
const _hoisted_30 = {
  class: "w-full bg_wrap",
  style: {"padding-bottom":"12%"}
}
const _hoisted_31 = { class: "table_wrap" }
const _hoisted_32 = {
  class: "flex items-center theme-table-head",
  style: {"background-color":"#020F46"}
}
const _hoisted_33 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_34 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_35 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_36 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_37 = ["title"]
const _hoisted_38 = { class: "table-info" }
const _hoisted_39 = { class: "table-info" }
const _hoisted_40 = {
  class: "flex-1 mt-5",
  style: {"height":"85%","overflow-y":"scroll","margin-left":"3%"}
}
const _hoisted_41 = { class: "flex items-center box-size" }
const _hoisted_42 = { class: "title flex items-center" }
const _hoisted_43 = {
  class: "p-text-color2 ml-10",
  style: {"padding-bottom":"4%"}
}
const _hoisted_44 = { class: "w-full flex items-center flex-wrap whiteBg" }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = { class: "w-full flex items-center" }
const _hoisted_47 = ["title"]
const _hoisted_48 = { class: "content flex items-center w-full" }
const _hoisted_49 = { class: "flex-1 content_right_wrap" }
const _hoisted_50 = { class: "w-full flex items-center heng_wrap" }
const _hoisted_51 = { class: "size14 opc8" }
const _hoisted_52 = { class: "size14 opc8" }
const _hoisted_53 = { class: "w-full flex items-center heng_wrap_bg" }
const _hoisted_54 = { class: "size18" }
const _hoisted_55 = { class: "size18" }
const _hoisted_56 = { class: "w-full flex items-center heng_wrap" }
const _hoisted_57 = { class: "size14 opc8" }
const _hoisted_58 = { class: "size14 opc8" }
const _hoisted_59 = { class: "w-full flex items-center heng_wrap_bg heng_wrap_bg2" }
const _hoisted_60 = { class: "size18" }
const _hoisted_61 = {
  class: "size18",
  style: {"color":"#5BFEB7"}
}
const _hoisted_62 = { class: "w-full flex items-center heng_wrap" }
const _hoisted_63 = { class: "size14 opc8" }
const _hoisted_64 = { class: "size14 opc8" }
const _hoisted_65 = { class: "w-full flex items-center heng_wrap_bg heng_wrap_bg3" }
const _hoisted_66 = { class: "size18" }
const _hoisted_67 = { class: "size18" }
const _hoisted_68 = { style: {"color":"#fff"} }
const _hoisted_69 = { class: "w-full flex" }
const _hoisted_70 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_71 = { class: "flex items-center" }
const _hoisted_72 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { key: 1 }
const _hoisted_75 = { key: 2 }
const _hoisted_76 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_77 = {
  key: 5,
  class: "ml-1"
}
const _hoisted_78 = { style: {"color":"#fff"} }
const _hoisted_79 = { class: "w-full flex" }
const _hoisted_80 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_81 = { class: "flex items-center" }
const _hoisted_82 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_83 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_84 = { key: 0 }
const _hoisted_85 = { key: 1 }
const _hoisted_86 = { key: 2 }
const _hoisted_87 = { key: 3 }
const _hoisted_88 = { class: "ml-2" }
const _hoisted_89 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_90 = { class: "ml-2" }
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { key: 1 }
const _hoisted_93 = ["onClick"]
const _hoisted_94 = ["onClick"]
const _hoisted_95 = ["onClick"]
const _hoisted_96 = { style: {"color":"#fff"} }
const _hoisted_97 = { class: "w-full flex" }
const _hoisted_98 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_99 = { class: "w-full p-tit-bottom" }
const _hoisted_100 = { style: {"width":"47%","margin-left":"26.5%","margin-top":"-3%"} }
const _hoisted_101 = ["onClick"]
const _hoisted_102 = {
  key: 0,
  src: _imports_8
}
const _hoisted_103 = {
  key: 1,
  src: _imports_9
}
const _hoisted_104 = {
  key: 2,
  src: _imports_10
}
const _hoisted_105 = { class: "fac_text" }
const _hoisted_106 = ["onClick"]
const _hoisted_107 = {
  key: 0,
  src: _imports_8
}
const _hoisted_108 = {
  key: 1,
  src: _imports_9
}
const _hoisted_109 = {
  key: 2,
  src: _imports_10
}
const _hoisted_110 = { class: "fac_text" }

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch ,computed} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
    transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    transformDate3,
    getGroupListByFactory,
    getRealFactoryIdList
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css'; // 确保引入样式文件
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import My3DPie from "./pie3d.vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'factory',
  setup(__props) {

SwiperCore.use([Navigation, Pagination, Autoplay]);
let bottom_select_facId = ref('') // 底部选择的工厂Id
const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(1)

let factory_id = ''
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
let machinesAllV :any = ref(0)
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
{ name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
{ name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
{ name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
{ name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
])
// 传入数据生成 option
const optionsData = ref([
{ name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
{ name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
{ name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
{ name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
])//3d饼图数据
let statusChartData: any = ref([      //设备运行状态统计 数据
    { label: '运行', num: 0, color: '#06DEF5' },
    { label: '停机', num: 0, color: '#ADADAD' },
    { label: '测点', num: 0, color: '#ADADAD' },
])
let factoryListBottom :any = ref([])//底部工厂数组
const factoryListLen:any = ref(1)
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref('')

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    bottom_select_facId.value = factory_id
    conditionSelectFac.value = bottom_select_facId.value
    listSelectFac.value = bottom_select_facId.value
    loadFactory()
}
const  splitArrayIntoSubarrays=(arr:any, size:any)=> {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => {
        const end = Math.min((i + 1) * size, arr.length); // 使用Math.min来避免越界
        return arr.slice(i * size, end);
    });
}

// 获取工厂
// const loopFlag = ref(true);
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        // factoryList.value = result.data.filter((p: any) => p.parentId === factory_id)
        factoryList.value = result.data.filter((p:any) => p._id === bottom_select_facId.value || p.parentId === bottom_select_facId.value)
        for(let i =0;i<factoryList.value.length;i++){
                factoryList.value[i].selected = false;
                if(!factoryList.value[i].parentId){
                    factoryList.value[i].selected = true;
                }
            }
            let factoryListBottomc = []
            factoryListBottomc = JSON.parse(JSON.stringify(factoryList.value))
            factoryListLen.value=Math.ceil((factoryList.value.length)/3)
            factoryListBottom.value = factoryListBottomc;
    
        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === bottom_select_facId.value)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
            let targetTime: any = new Date(info?.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }

        factoryInfo()
    }

}




//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};


//获取工厂信息
const factoryInfo = async() => {
    let groups:any = []
      factoryList.value.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
         groupList.value = groups;
        getMain(groups);

}



// const loopFlag=computed(() => factoryListBottom.value?.length <3 ? false:true);
let autoplay = ref({
    delay: 10 * 60 * 1000, // 10 分钟（单位：毫秒）
    disableOnInteraction: false, // 用户操作后是否停止自动轮播
})
// Swiper 实例
let swiperInstance: any = null;
const activeIndex = ref(0); // 当前激活的幻灯片索引
const onSwiper = (swiper: any) => {//初始化
    swiperInstance = swiper;
};


// 幻灯片切换时更新激活索引
const onSlideChange = () => {
    if (swiperInstance) {
        activeIndex.value = swiperInstance.realIndex;
        selectFac(activeIndex.value )
    }
};



// 点击幻灯片时滑动到中间
const onSlideClick = (index: number) => {
    if (swiperInstance) {
        if(activeIndex.value==index){
            return
        }else{
            if(index-activeIndex.value==1){
                swiperInstance.slideNext()
            }else if(index-activeIndex.value==-1){
                swiperInstance.slidePrev()
            }else if(index==0){
                swiperInstance.slideNext()
             }
            else if(index==factoryListBottom.value.length-1){
                swiperInstance.slidePrev()
            }
            else {
                swiperInstance.slideNext()
            }
        }
        // selectFac(index)
      }
  
};




// 获取区域列表
const getMain = async (groups: any) => {
  
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
       
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp && tempInfo.value !== 0) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? '' : temp?.toFixed(2)
            console.log('d.temp', d.temp)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (!d.workStableStatus.condition) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getStatusChartData()
        getMachineWarningChartData()
        getGroupListDetail()
    }, 500);

}
// 获取设备运行状态统计 数据
const getStatusChartData = () => {
    let s1 = 0, s2 = 0,s3=0;
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === bottom_select_facId.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    s3 = s3 + m?.sensors?.length
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                s3 = s3 + m?.sensors?.length
                if (m.workStatus.wkStatus) {
                    s1++
                } else {
                    s2++
                }
            })
        })
    }

    statusChartData.value = [
        { label: '运行', num: s1, color: '#06DEF5' },
        { label: '停机', num: s2, color: '#ADADAD' },
        { label: '测点', num: s3, color: '#ADADAD' },
    ]

    // initStatusChart()
}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0;
    let r1 = '0.00', r2 = '0.00', r3 = '0.00', r4 = '0.00';
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    let machinesAll = (n1+n2+n3+n4)*1;
    machinesAllV.value = 0;
    if(machinesAll>0){
        machinesAllV.value = machinesAll
        r1 = ((n1/machinesAll)*100).toFixed(2)
        r2 = ((n2/machinesAll)*100).toFixed(2)
        r3 = ((n3/machinesAll)*100).toFixed(2)
        r4 = ((n4/machinesAll)*100).toFixed(2)
        
    }
    conditionChartData.value = [
            {name: '健康', value: n1,rate:r1, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
            {name: '可用', value: n2,rate:r2, itemStyle: {opacity: 0.8,color: '#d2de49'}},
            {name: '警戒', value: n3,rate:r3, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
            {name: '故障', value: n4,rate:r4, itemStyle: {opacity: 0.8,color: '#921e37'}}
    ]
   
    optionsData.value= JSON.parse(JSON.stringify(conditionChartData.value))

    // initConditionChart()
}


//智能诊断报警记录

// let handleStatusList:any=ref([
//     { label: '未处理', value: 2 },
//     { label: '已处理', value: 3 },
// ])
let handleStatusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let znZdWarnVisible = ref(false);
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let zdWarnData = ref([])
let znZdWarnSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type:1
})
const paginationZdWarn = computed(() => ({
  total: znZdWarnSearch.value.total,
  current: znZdWarnSearch.value.skip,
  pageSize: znZdWarnSearch.value.take,
}));
 

const znZdWarnColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];
const selectZnZdWarn = () => {
    znZdWarnVisible.value = true
    resetZdWarn()
   
    
    getZdWarnData()
}  
let machineListZdWarn = ref([])
// 选择区域
const selectGroupZdWarn = (ev:any) => {
   
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getZdWarnData = async () => {
    

  let config: any = {
    params: {
      skip: znZdWarnSearch.value.skip,
      take: znZdWarnSearch.value.take,
      groupId: znZdWarnSearch.value.groupId,
      machineId: znZdWarnSearch.value.machineId,
      type: znZdWarnSearch.value.type,
      handleStatus: znZdWarnSearch.value.handleStatus,
      expertCertification: znZdWarnSearch.value.expertCertification,
      factoryIds: getRealFactoryIdListById(bottom_select_facId.value) 
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(znZdWarnSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
  }
   
  if (znZdWarnSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == znZdWarnSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    zdWarnData.value = result.data.list;
    znZdWarnSearch.value.total = result.data.total;
  }
};

const resetZdWarn = () => {
    znZdWarnSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:1
    }
    // getZdWarnData()
}
const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    znZdWarnSearch.value.sort = sorter.field;
    znZdWarnSearch.value.order = order;
  }
  // 处理分页数据
  znZdWarnSearch.value.skip = pagination.current;
  znZdWarnSearch.value.take = pagination.pageSize;

  getZdWarnData();
};



// 设备列表弹框
let machineModelSearch:any = ref({
    condition: null,
    status: null,
    groupId: null,
    factoryId: '',
    keyWord: '',
    skip:1,
    take:20,
    total:0,

})

const viewMachine = (type:any)=>{
    if(type==1){
        router.push('/dashboard/machine')
    }else{
        router.push('/diagnosisN/overview')
    }
 
}
let machineVisible = ref(false)
let machineDataList: any = ref([])
const handleTableChangeMachineList = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  machineModelSearch.value.skip = pagination.current
  machineModelSearch.value.take = pagination.pageSize
};


const paginationMachineList = computed(() => ({
  total: machineModelSearch.value.total,
  current: machineModelSearch.value.skip,
  pageSize: machineModelSearch.value.take,
}));

let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
    { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
    { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
];

let whetherList = [
    { label: "是", value: true },
    { label: "否", value: false },
];

const machineColumns = [
    {
        title: getLanguage('设备编号', language.value),
        dataIndex: "machineCode",
    },
    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    {
        title: getLanguage('所属工厂', language.value),
        dataIndex: "factoryId",
    },
    {
        title: getLanguage('设备区域', language.value),
        dataIndex: "groupId",
    },
    {
        title: getLanguage('设备位置', language.value),
        dataIndex: "position",
    },
    // {
    //     title: getLanguage('设备厂家', language.value),
    //     dataIndex: "supplier",
    // },
    // {
    //     title: getLanguage('设备型号', language.value),
    //     dataIndex: "model",
    // },
    {
        title: getLanguage('设备类型', language.value),
        dataIndex: "machineTypeAll",
    },
    {
        title: getLanguage('设备SN号', language.value),
        dataIndex: "sn",
    },
    {
        title: getLanguage('巡检序号', language.value),
        dataIndex: "sort",
    },
    // {
    //     title: getLanguage('设备描述', language.value),
    //     dataIndex: "remark",
    // },
    {
        title: getLanguage('健康状态', language.value),
        dataIndex: "condition",
    },
    {
        title: getLanguage('运行状态', language.value),
        dataIndex: "status",
    },
    {
        title: getLanguage('设备运行时间', language.value),
        dataIndex: "onlineHours",
    },
    {
        title: getLanguage('检修次数', language.value),
        dataIndex: "repairReportsCount",
    },
    {
        title: getLanguage('平均故障间隔时间', language.value),
        dataIndex: "faultIntervalTimes",
    },
    {
    title: "操作",
    dataIndex: "act",
    },
];

const machineHealth = () =>{
    router.push('/dashboard/machine')
}
const machineScreen = () =>{
    router.push('/dashboard/machine')
}
// 获取设备健康状态
const getMachienCondition = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.stableCondition) {
        return conditionList.find(
            (p: any) => p.value === ev.workStatus.stableCondition
        );
    } else {
        return conditionList[0];
    }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
    if(ev.workStatus?.quotaWkStatus === true) {
        return statusList[0]
    } else if(ev.workStatus?.quotaWkStatus === false) {
        return statusList[1];
    } else if(ev.workStatus?.scoreWkStatus) {
        return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
    } else {
        return statusList[1];
    }
};


const selectMachineCondition = (condition: any) => {
    resetMachine()
    machineModelSearch.value.condition = condition
    machineModelSearch.value.factoryId = conditionSelectFac.value
    machineVisible.value = true
    getDataMachine()//(选择完底部数据后需要更新)
}


const selectMachineStatus = (status: any) => {
    resetMachine()
    machineModelSearch.value.status = status
    machineModelSearch.value.factoryId = bottom_select_facId.value
    machineVisible.value = true
    getDataMachine()//(选择完底部数据后需要更新)
}

const resetMachine = () => {
    machineModelSearch.value = {
        condition: null,
        status: null,
        groupId: null,
        factoryId: '',
        keyWord: '',
    }
}

const getDataMachine = async () => {
    let config: any = {
        params: {
            groupId: machineModelSearch.value.groupId,
            status: machineModelSearch.value.status,
            factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
            keyWord: machineModelSearch.value.keyWord,
            mode: [1],
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (machineModelSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value === machineModelSearch.value.condition
        )?.realValue;
    }

    let result = await request("/api/machines", config);

    if (result && result.data) {
        machineDataList.value = result.data;
        machineModelSearch.value.total = result.data.length
    } else {
        machineDataList.value = []
    }
}




//底部选择工厂

const selectFac = (index:any)=>{

    for(let i =0;i<factoryList.value.length;i++){
         factoryList.value[i].selected = false
        }
        factoryListBottom.value = JSON.parse(JSON.stringify(factoryList.value))
        // factoryListBottom.value = splitArrayIntoSubarrays(factoryList.value, 3);
        factoryListBottom.value[index].selected = true;
        bottom_select_facId.value = factoryListBottom.value[index]._id;
       if(!bottom_select_facId.value){
         bottom_select_facId.value=factory_id;
     }
         listSelectFac.value = bottom_select_facId.value;
         conditionSelectFac.value = bottom_select_facId.value
        
      
        setTimeout(()=>{
            factoryInfo()
        },100)
       
        
       
    
}
// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === bottom_select_facId.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [bottom_select_facId.value]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            factoryIds,
            typeList: [1]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
        // console.log(machineWarningData.value,"machineWarningData.value")
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([{ name: langObj.value['全部区域'], sonID: '' }])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = [{ name: langObj.value['全部区域'], sonID: '' }]
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds?.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo?.groups)
        list1 = facInfo?.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList
}




const selectGroup2 = (ev: any) => {
    listSelectGroup.value = ev.sonID;
    localStorage.setItem('groupId', listSelectGroup.value)
    // selectGroup(listSelectGroup.value)
    setTimeout(()=>{
        router.push('/dashboard/group')
    },500)
   
}
















// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}


return (_ctx: any,_cache: any) => {
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[26] || (_cache[26] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)['健康状态统计']), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_unref(machinesAllV)), 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(langObj)['台']), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_unref(langObj)['设备总数']), 1)
              ]),
              _createVNode(My3DPie, {
                style: {"z-index":"2"},
                optionsData: optionsData.value
              }, null, 8, ["optionsData"]),
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "pie_bg_wrap" }, null, -1))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "flex items-center cursor-pointer tl_state_wrap",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMachineCondition(1)))
              }, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
                _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[0].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ]),
              _createElementVNode("div", _hoisted_11, "(" + _toDisplayString(_unref(getLanguage)('健康率',_unref(language))) + _toDisplayString(_unref(conditionChartData)[0].rate) + "%)", 1),
              _createElementVNode("div", {
                class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMachineCondition(2)))
              }, [
                _cache[29] || (_cache[29] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
                _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[1].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ]),
              _createElementVNode("div", _hoisted_13, "(" + _toDisplayString(_unref(getLanguage)('可用率',_unref(language))) + _toDisplayString(_unref(conditionChartData)[1].rate) + "%)", 1),
              _createElementVNode("div", {
                class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMachineCondition(3)))
              }, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
                _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[2].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ]),
              _createElementVNode("div", _hoisted_15, "(" + _toDisplayString(_unref(getLanguage)('警戒率',_unref(language))) + _toDisplayString(_unref(conditionChartData)[2].rate) + "%)", 1),
              _createElementVNode("div", {
                class: "flex items-center cursor-pointer mt-3 tl_state_wrap",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (selectMachineCondition(4)))
              }, [
                _cache[31] || (_cache[31] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
                _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[3].value) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ]),
              _createElementVNode("div", _hoisted_17, "(" + _toDisplayString(_unref(getLanguage)('故障率',_unref(language))) + _toDisplayString(_unref(conditionChartData)[3].rate) + "%)", 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[32] || (_cache[32] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(langObj)['设备监测统计']), 1)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              class: "moitor_clip cursor-pointer",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (selectMachineStatus(1)))
            }, [
              _cache[33] || (_cache[33] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
              _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('运行设备数',_unref(language))), 1),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[0]?.num), 1),
                _createTextVNode(_toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("img", {
                src: _imports_1,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (selectMachineStatus(0)))
              }),
              _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(getLanguage)('停机设备数',_unref(language))), 1),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[1]?.num), 1),
                _createTextVNode(_toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _cache[34] || (_cache[34] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
              _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(getLanguage)('监测测点数',_unref(language))), 1),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_unref(statusChartData)[2]?.num), 1),
                _createTextVNode(_toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(langObj)['智能诊断报警']), 1),
              _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                onClick: selectZnZdWarn,
                class: "flex items-center cursor-pointer whiteBgColor p-text-right",
                style: {"color":"#ffffffe6"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['查看更多']), 1),
                _createVNode(_component_right_outlined)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)['设备名称']), 1),
                _createElementVNode("div", _hoisted_34, _toDisplayString(_unref(langObj)['报警类型']), 1),
                _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(langObj)['严重程度']), 1),
                _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(langObj)['报警时间']), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex items-center",
                  key: index,
                  style: _normalizeStyle({ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' })
                }, [
                  _createElementVNode("div", {
                    class: "table-info",
                    style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},
                    title: item.machineName
                  }, _toDisplayString(item.machineName), 9, _hoisted_37),
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(langObj)[_unref(warningTypeList).find(p => p.value === item.type)?.label]), 1),
                  _createElementVNode("div", {
                    class: "table-info",
                    style: _normalizeStyle({ 'color': getMachineWarningName(item.condition)?.color })
                  }, _toDisplayString(_unref(langObj)[getMachineWarningName(item.condition)?.name]), 5),
                  _createElementVNode("div", _hoisted_39, _toDisplayString(item.date), 1)
                ], 4))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _cache[36] || (_cache[36] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(langObj)['区域概览']), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_44, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupListData), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (selectGroup2(item)),
              key: index,
              class: "box2 mt-6 mr-4"
            }, [
              _createElementVNode("div", _hoisted_46, [
                _cache[37] || (_cache[37] = _createElementVNode("div", { class: "shu" }, null, -1)),
                _createElementVNode("div", {
                  class: "text",
                  title: item?.name || '',
                  style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                }, _toDisplayString(item?.name), 9, _hoisted_47)
              ]),
              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "heng" }, null, -1)),
              _createElementVNode("div", _hoisted_48, [
                _cache[38] || (_cache[38] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_unref(langObj)['监测设备']), 1),
                    _createElementVNode("div", _hoisted_52, _toDisplayString(_unref(langObj)['监测测点']), 1)
                  ]),
                  _createElementVNode("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, _toDisplayString(item?.machineNum) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(item?.sensorNum) + _toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(langObj)['运行设备']), 1),
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_unref(langObj)['运行率']), 1)
                  ]),
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, _toDisplayString(item?.machineRun) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1),
                    _createElementVNode("div", _hoisted_61, _toDisplayString(item?.machineRate), 1)
                  ]),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("div", _hoisted_63, _toDisplayString(_unref(langObj)['报警设备']), 1),
                    _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(langObj)['未处理报警']), 1)
                  ]),
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("div", _hoisted_66, _toDisplayString(item?.warningMachineNum) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1),
                    _createElementVNode("div", _hoisted_67, _toDisplayString(item?.noHandleNum) + _toDisplayString(_unref(getLanguage)("条", _unref(language))), 1)
                  ])
                ])
              ])
            ], 8, _hoisted_45))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      visible: _unref(machineVisible),
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(machineVisible) ? (machineVisible).value = $event : machineVisible = $event)),
      closable: false,
      footer: null,
      style: {"top":"10%"},
      width: "80%",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_68, [
          _createElementVNode("div", _hoisted_69, [
            _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(machineVisible) //@ts-ignore
 ? machineVisible.value = false : machineVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(getLanguage)('设备列表', _unref(language))), 1),
          _createVNode(_component_a_form, {
            model: _unref(machineModelSearch),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('设备区域', _unref(language)),
                name: "groupId",
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    onChange: _ctx.selectGroupMachine,
                    value: _unref(machineModelSearch).groupId,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(machineModelSearch).groupId) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.sonID
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["onChange", "value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('健康状态', _unref(language)),
                name: "condition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(machineModelSearch).condition,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(machineModelSearch).condition) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择健康状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('运行状态', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(machineModelSearch).status,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(machineModelSearch).status) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择运行状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('搜索', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _unref(machineModelSearch).keyWord,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(machineModelSearch).keyWord) = $event)),
                    placeholder: _unref(getLanguage)('请输入设备名称/编号', _unref(language))
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("div", {
                      onClick: resetMachine,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                    _createElementVNode("div", {
                      onClick: getDataMachine,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_72, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": _ctx.getRowClassName,
              columns: machineColumns,
              "data-source": _unref(machineDataList),
              onChange: handleTableChangeMachineList,
              pagination: paginationMachineList.value
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'factoryId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_73, _toDisplayString(_unref(getFactoryName)(text)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'groupId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_74, _toDisplayString(_unref(getGroupName)(text)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'experiment')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString(_unref(getLanguage)(_unref(whetherList).find((p) => p.value === text)?.label, _unref(language))), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getMachienCondition(record)?.color }])
                      }, null, 4),
                      _createElementVNode("div", {
                        class: "ml-1",
                        style: _normalizeStyle({ color: getMachienCondition(record)?.color })
                      }, _toDisplayString(_unref(getLanguage)(getMachienCondition(record)?.label, _unref(language))), 5)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'status')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      class: "ml-1",
                      style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                    }, _toDisplayString(_unref(getLanguage)(getMachienStatus(record)?.label, _unref(language))), 5))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'onlineHours')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_77, _toDisplayString(record?.workStatus?.onlineHours?.toFixed(2) || 0), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'faultIntervalTimes')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 6,
                      class: "ml-1",
                      style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                    }, _toDisplayString(record?.workStatus?.faultIntervalTimes?.toFixed(2) || 0), 5))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                      _createElementVNode("a", {
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (viewMachine(1)))
                      }, _toDisplayString(_unref(langObj)["健康总览"]), 1),
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      _createElementVNode("a", {
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (viewMachine(2)))
                      }, _toDisplayString(_unref(langObj)["健康诊断"]), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["row-class-name", "data-source", "pagination"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(znZdWarnVisible),
      "onUpdate:visible": _cache[20] || (_cache[20] = ($event: any) => (_isRef(znZdWarnVisible) ? (znZdWarnVisible).value = $event : znZdWarnVisible = $event)),
      closable: false,
      footer: null,
      style: {"top":"10%"},
      width: "80%",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_78, [
          _createElementVNode("div", _hoisted_79, [
            _cache[41] || (_cache[41] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(znZdWarnVisible) //@ts-ignore
 ? znZdWarnVisible.value = false : znZdWarnVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_80, _toDisplayString(_unref(getLanguage)('智能诊断报警记录', _unref(language))), 1),
          _createVNode(_component_a_form, {
            model: _unref(znZdWarnSearch),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('设备区域', _unref(language)),
                name: "groupId",
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    onChange: selectGroupZdWarn,
                    value: _unref(znZdWarnSearch).groupId,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(znZdWarnSearch).groupId) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.sonID
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['设备'],
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).machineId,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(znZdWarnSearch).machineId) = $event)),
                    style: {"width":"220px"},
                    placeholder: _unref(langObj)['请选择设备'],
                    options: _unref(machineListZdWarn),
                    "filter-option": _ctx.filterOptionZdReport,
                    "field-names": { label: 'name', value: 'id', options: 'machineListZdWarn' },
                    allowClear: "",
                    "show-search": ""
                  }, null, 8, ["value", "placeholder", "options", "filter-option"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('报警时间', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: _unref(znZdWarnSearch).date,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(znZdWarnSearch).date) = $event)),
                    "disabled-date": _ctx.disabledDate,
                    onCalendarChange: _ctx.onCalendarChange
                  }, null, 8, ["value", "disabled-date", "onCalendarChange"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('处理状态', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).handleStatus,
                    "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(znZdWarnSearch).handleStatus) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择处理状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleStatusList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('严重程度', _unref(language)),
                name: "condition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(znZdWarnSearch).condition,
                    "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(znZdWarnSearch).condition) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择严重程度', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_81, [
                    _createElementVNode("div", {
                      onClick: resetZdWarn,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                    _createElementVNode("div", {
                      onClick: getZdWarnData,
                      class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_82, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": _ctx.getRowClassName,
              columns: znZdWarnColumns,
              "data-source": _unref(zdWarnData),
              pagination: paginationZdWarn.value,
              onChange: handleTableChangeZdWarn
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'type')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                      (text === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_84, _cache[42] || (_cache[42] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_4,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_85, _cache[43] || (_cache[43] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_5,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_86, _cache[44] || (_cache[44] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_6,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      (text === 4)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_87, _cache[45] || (_cache[45] = [
                            _createElementVNode("img", {
                              style: {"width":"20px"},
                              src: _imports_7,
                              alt: ""
                            }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_88, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                                            background: _unref(conditionList).find((p) => p.value === text)
                                                ?.color,
                                            }])
                      }, null, 4),
                      _createElementVNode("div", _hoisted_90, _toDisplayString(_unref(langObj)[
                                                _unref(conditionList).find((p) => p.value === text)?.label
                                                ]), 1)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'handleStatus')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (text === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_91, _toDisplayString(_unref(langObj)["已关闭"]), 1))
                        : _createCommentVNode("", true),
                      (text !== 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_92, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                                                ? _unref(langObj)[
                                                _unref(handleStatusList).find((p) => p.value === text)?.label
                                                ]
                                                : _unref(langObj)["未处理"]), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      (record?.reportIds?.length>0)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            onClick: ($event: any) => (viewReport(record))
                          }, _toDisplayString(_unref(langObj)["诊断报告"]), 9, _hoisted_93))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      (record?.repairReportId)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            onClick: ($event: any) => (detailsJx(record))
                          }, _toDisplayString(_unref(langObj)["检修报告"]), 9, _hoisted_94))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      (record.handleStatus === 3)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            onClick: ($event: any) => (viewClose(record))
                          }, _toDisplayString(_unref(langObj)["关闭原因"]), 9, _hoisted_95))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["row-class-name", "data-source", "pagination"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: "50%",
      closable: false,
      footer: null,
      visible: _unref(jxVisible),
      "onUpdate:visible": _cache[21] || (_cache[21] = ($event: any) => (_isRef(jxVisible) ? (jxVisible).value = $event : jxVisible = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(maintenanceReportDetails, {
          ref_key: "mrD",
          ref: mrD,
          onCancel: cancelJx
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: "50%",
      closable: false,
      footer: null,
      visible: _unref(visibleDR),
      "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(DiagnosticReportDetails, {
          ref_key: "mrDref",
          ref: mrDref,
          onCancel: cancelDR
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibledel2),
      "onUpdate:visible": _cache[25] || (_cache[25] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_96, [
          _createElementVNode("div", _hoisted_97, [
            _cache[46] || (_cache[46] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[23] || (_cache[23] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_98, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
          _createVNode(_component_a_form, {
            ref: "formRef",
            name: "advanced_search",
            class: "ant-advanced-search-form",
            style: {"margin-top":"20px"},
            model: _unref(formClose)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "closeCause",
                        label: "关闭原因",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _unref(formClose).closeCause,
                            "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                            placeholder: "",
                            rows: 4,
                            readonly: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("div", _hoisted_99, [
      _createElementVNode("div", _hoisted_100, [
        (_unref(factoryListBottom).length>=2)
          ? (_openBlock(), _createBlock(_unref(Swiper), {
              key: 0,
              slidesPerView: 3,
              spaceBetween: 0,
              navigation: true,
              autoplay: _unref(autoplay),
              loop: true,
              initialSlide: 3,
              onSwiper: onSwiper,
              onSlideChange: onSlideChange,
              centeredSlides: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryListBottom), (item, index) => {
                  return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["fac_wrap", {'selected': activeIndex.value === index}]),
                        onClick: ($event: any) => (onSlideClick(index))
                      }, [
                        (!item.parentId && item.factoryName )
                          ? (_openBlock(), _createElementBlock("img", _hoisted_102))
                          : _createCommentVNode("", true),
                        (item.parentId)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_103))
                          : _createCommentVNode("", true),
                        (!item.factoryName)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_104))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_105, _toDisplayString(item.factoryName?item.factoryName:item.name), 1)
                      ], 10, _hoisted_101)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["autoplay"]))
          : _createCommentVNode("", true),
        (_unref(factoryListBottom).length==1)
          ? (_openBlock(), _createBlock(_unref(Swiper), {
              key: 1,
              slidesPerView: 3,
              spaceBetween: 0,
              navigation: true,
              autoplay: _unref(autoplay),
              loop: false,
              initialSlide: 3,
              onSwiper: onSwiper,
              onSlideChange: onSlideChange,
              centeredSlides: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryListBottom), (item, index) => {
                  return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: index }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["fac_wrap", {'selected': activeIndex.value === index}]),
                        onClick: ($event: any) => (onSlideClick(index))
                      }, [
                        (!item.parentId && item.factoryName )
                          ? (_openBlock(), _createElementBlock("img", _hoisted_107))
                          : _createCommentVNode("", true),
                        (item.parentId)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_108))
                          : _createCommentVNode("", true),
                        (!item.factoryName)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_109))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_110, _toDisplayString(item.factoryName?item.factoryName:item.name), 1)
                      ], 10, _hoisted_106)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["autoplay"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})