<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full box1 p-3 whiteBg">
        <div class="w-full flex items-center">
            <div>{{langObj['采样时间']}}：</div>
            <a-range-picker @change="getHistrendData" style="width: 300px;margin-left: 20px;" v-model:value="date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
        </div>
        <div class="flex items-center">
          <div class="flex-1 text-center" style="font-size:18px">{{langObj['健康指标诊断分数趋势图']}}</div>
          <a-switch v-model:checked="isShowHealthRemark" @change="initChartC()" :checked-children="langObj['显示']" :un-checked-children="langObj['隐藏']" />
          <div style="font-size: 14px;">{{langObj['开关机线']}}</div>
        </div>
        
        <div id="chart6A" class="w-full" style="height: 500px"></div>

        <div class="flex items-center">
          <div class="flex-1 text-center" style="font-size:18px">{{langObj['振动指标诊断分数趋势图']}}</div>
          <a-switch v-model:checked="isShowVibrationRemark" @change="initChartD()" :checked-children="langObj['显示']" :un-checked-children="langObj['隐藏']" />
          <div style="font-size: 14px;">{{langObj['开关机线']}}</div>
        </div>
        <div id="chart6B" class="w-full " style="height: 500px"></div>


    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { transformDate2, transformDate } from "../../../common/tools";
import { Dayjs } from 'dayjs';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()

const props:any = defineProps({
    sensorInfo: null,
});
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo

watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);
let date:any = ref(null)
let machine_date = ''

let faultName:any = []
let faultNameII:any = []

let healthChartSelectlenged:any = {}
let faultChartSelectlenged:any = {}

let scoreExpertMarkLine:any
let scoreMarkLine:any

let myChartC:any 
let myChartD:any 

let isShowHealthRemark:any = ref(true)
let isShowVibrationRemark:any = ref(true)

let isHideHealth = ref(false)
let isHideVibration = ref(false)

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value || !sensorInfo.value.id) return
    getFaultName()
}

const getFaultName = async() => {
    let faultnamesSearch = {
        params: { machineId: sensorInfo.value.machineId },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result2 = await request.get('/api/machines/faultnames', faultnamesSearch) 
    if(result2 && result2.data) {
        faultName = result2.data.faultNames
        faultNameII = result2.data.faultNameExpects
    }

    faultName.map((item: any) => {
      healthChartSelectlenged[item.title] = true
    })

    faultNameII.map((item: any) => {
      faultChartSelectlenged[item.title] = true
    })

    getMachineInfo()
    
}

const getMachineInfo = async() => {
    let config = {
        params: { machineId: sensorInfo.value.machineId },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get('/api/machines/info', config)
    if(result && result.data && result.data.workStatus && result.data.workStatus.scoreDate) {
        machine_date = result.data.workStatus.scoreDate
    } else {
        machine_date = ''
    }

    getHistrendData()
}

const getHistrendData = async() => {
    if(!machine_date) {
        return
    }

    let begin:any = ''
    let end:any = ''

    if(date.value && date.value.length === 2) {
        begin = transformDate2(date.value[0], 1)
        end = transformDate2(date.value[1], 2)
    } else {
        end = transformDate(new Date(machine_date))
        begin = transformDate(new Date(new Date(machine_date).getTime() - (1000 * 60 * 60 * 24 * 7)))
    }

    let search = {
        params: {
            begin,
            end,
            sensorId: '',
            machineId: sensorInfo.value.machineId,
        },
        headers: {
            requestId: uuidv4(),
        },
    }

    // 获取全设备数据(开关机数据)
    let scoreA = await request.get('/api/external/machines/score-full', search) 
    let scoreAll = scoreA.data
    let scoreExpertAll = scoreA.data

      // 获取开关机线
    //健康
    if(scoreExpertAll.length > 0) {
      let markLine:any = {
        data: [],
        silent: true
      }
      let wkStatus = 0;
      let x = '';
      scoreExpertAll.map( (item:any, index:any) => {
        let time = new Date(item.date);
        let month = (time.getMonth() + 1) > 9 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)
        let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
        let minutes = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        let seconds = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        x = month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        if (index == 0) {
          wkStatus = item.wkStatus
        } else {
          if (item.wkStatus != wkStatus) {
            if (item.wkStatus == 1) {
              markLine.data.push({
                name: '开',
                xAxis: x,
                label: { show: true, color: theme.value=='white'?'#333':'#fff', formatter: '{b}' },
                lineStyle: { color: 'green' }
              })
            } else {
              markLine.data.push({
                name: '关',
                xAxis: x,
                label: { show: true, color: theme.value=='white'?'#333':'#fff', formatter: '{b}' },
                lineStyle: { color: 'rgb(173, 38, 64)' }
              })
            }
            wkStatus = item.wkStatus
          }
        }
      })
      scoreExpertMarkLine = markLine
    }

    //振动
    if(scoreAll.length > 0) {
      let markLine:any = {
        data: [],
        silent: true
      }
      let wkStatus = 0;
      let x = '';
      scoreAll.map( (item:any, index:any) => {
        let time = new Date(item.date);
        let month = (time.getMonth() + 1) > 9 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)
        let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
        let minutes = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        let seconds = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        x = month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        if (index == 0) {
          wkStatus = item.wkStatus
        } else {
          if (item.wkStatus != wkStatus) {
            if (item.wkStatus == 1) {
              markLine.data.push({
                name: '开',
                xAxis: x,
                label: { show: true, color: theme.value=='white'?'#333':'#fff', formatter: '{b}' },
                lineStyle: { color: 'green' }
              })
            } else {
              markLine.data.push({
                name: '关',
                xAxis: x,
                label: { show: true, color: theme.value=='white'?'#333':'#fff', formatter: '{b}' },
                lineStyle: { color: 'rgb(173, 38, 64)' }
              })
            }
            wkStatus = item.wkStatus
          }
        }
      })
      scoreMarkLine = markLine
    }

    search.params.sensorId = sensorInfo.value.id
    let scoreAB = await request.get('/api/external/machines/score-full', search)
    scoreAll = scoreAB.data

    scoreExpertAll = scoreAB.data

    faultName.map((d:any) => {d.data = []; d.dateList = []})
    faultNameII.map((d:any) => {d.data = []; d.dateList = []})

    scoreAll.map((item:any) => {
        faultName.map((fault:any) => {
            fault.dateList.push(item.date)
            let date = item.date.slice(5,19)
            fault.data.push([date ,item[fault.column] || null])
        })
    })


    scoreExpertAll.map((item:any) => {
        faultNameII.map((fault:any) => {
            fault.dateList.push(item.date)
            let date = item.date.slice(5,19)
            fault.data.push([date ,item[fault.column] || null])
        })
    })

    initChartC()
    initChartD()
}

// 健康分数曲线图
// 健康
const initChartC = () => {
    let data = faultNameII
    
    let xData = data&&data.length ? data[0].data.map((p:any) => p[0])  : []
    
    const ec = echarts as any;
    if(!document.getElementById('chart6A')) return
    myChartC = ec.init(document.getElementById('chart6A'));
    myChartC.resize()
  
    let min = 0, max = 100, colors:any[] = [];


    let option:any = null;
    let LengData:any[] = [], SelectedData = {}, YaData, SeriesData, DataInfo:any[] = [];
    colors = ['rgb(87,147,243)', 'rgb(255,165,0)', 'rgb(22,58,255)', 'rgb(0,255,0)', 'rgb(255,51,51)', 'rgb(0,255,255)',
    'rgb(0,128,0)', "rgb(32, 157, 179)", 'rgb(120, 32, 179)', 'rgb(214, 55, 188)'];


    data.map( (item:any, index:any) => {
        if (item.data && item.data.length) {
          LengData.push(langObj.value[item.title] || item.title)
            if(isShowHealthRemark.value == true) {
            DataInfo.push({
                name: langObj.value[item.title],
                type: 'line',
                data: item.data,
                smooth: false,
                symbolSize: 3,
                markLine: scoreExpertMarkLine,
                connectNulls: true
            })
            } else if(isShowHealthRemark.value == false) {
            DataInfo.push({
                name: langObj.value[item.title],
                type: 'line',
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true
            })
            }
        }
    })

    if(!DataInfo || !DataInfo.length) {
      isHideHealth.value = true
    } 

    function Init(sel: { [x: string]: any; }, dataInfo: any[]) {
      SelectedData = sel || {};
      YaData = [], SeriesData = [];
      let Datas = JSON.parse(JSON.stringify(dataInfo));
      for (let n = 0; n < LengData.length; n++) {
        const ydata = Datas[n].data;
        let max = Math.max(...ydata) / 0.8 / 5
        if (max > 0 && max < 0.5) {
          max = 1
        }
        const ymax = Math.round(max) * 5;

        // 如果该图例状态为false时，则跳过 不push
        if (sel[LengData[n]]) {
          YaData.push({
            type: 'value',
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
              lineStyle: {
                color: ['rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgba(250, 60, 88, 1)', 'rgba(254, 178, 7, 1)', 'rgb(117, 116, 116)']
              }
            },
            position: 'left',
            offset: '-10',
            axisLine: {
              lineStyle: {
                color: theme.value=='white'?'#333':'#fff'
              }
            },
          })
        } else {
          Datas[n].data = [];
        }
        Datas[n].yAxisIndex = 0
        SeriesData.push(Datas[n]);
      }
      if (YaData.length == 0) {
        YaData = [{ type: 'value' }];
      }
      option = {
        color: colors,
        tooltip: {
          hideDelay: 100,
          transitionDuration: 0,
          animation: false ,
          trigger: 'axis',
          axisPointer: {
            type: 'cross' ,
          }
        },
        grid: {
          right: '5%',
          left: '7%',
        },
        toolbox: {
          trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
          data: LengData,
          selected: SelectedData,
          textStyle: {
            color: theme.value=='white'?'#333':'#fff',
          }
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: xData,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.3
              }
            },
            axisLabel: {
              color: theme.value=='white'?'#333':'#fff',
              fontSize: 16,
              showMaxLabel: true
            },
          }
        ],
        yAxis: [
            {
                type: 'value',
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                lineStyle: {
                    color: ['rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgba(250, 60, 88, 1)', 'rgba(254, 178, 7, 1)', 'rgb(117, 116, 116)']
                }
                },
                position: 'left',
                offset: '-10',
                axisLine: {
                lineStyle: {
                   color: theme.value=='white'?'#333':'#fff'
                }
                },
            }
        ],
        series: SeriesData
      };
    }

    Init(faultChartSelectlenged, DataInfo);

    if (option && typeof option === "object") {
      myChartC && myChartC.clear()
      myChartC && myChartC.setOption(option, true);
    }


    myChartC.off('legendselectchanged').on("legendselectchanged", function (params: { selected: any; }) {
      // 得到当前的图例显示隐藏状态分别有哪些
      faultChartSelectlenged = params.selected;
      Init(faultChartSelectlenged, DataInfo);
      if (option && typeof option === "object") {
        myChartC?.clear()
        myChartC?.setOption(option, true);
      }
    })
}

// 振动
const initChartD = () => {
    let data = faultName
    let xData = data&&data.length ? data[0].data.map((p:any) => p[0])  : []

    const ec = echarts as any;
    if(!document.getElementById('chart6B')) return
    myChartD = ec.init(document.getElementById('chart6B'));
    myChartD.resize()
  
    let min = 0, max = 100, colors:any[] = [];


    let option:any = null;
    let LengData:any[] = [], SelectedData = {}, YaData, SeriesData, DataInfo:any[] = [];
    colors = ['rgb(87,147,243)', 'rgb(255,165,0)', 'rgb(22,58,255)', 'rgb(0,255,0)', 'rgb(255,51,51)', 'rgb(0,255,255)',
    'rgb(0,128,0)', "rgb(32, 157, 179)", 'rgb(120, 32, 179)', 'rgb(214, 55, 188)'];


    data.map( (item:any, index:any) => {
        if (item.data && item.data.length) {
          LengData.push(langObj.value[item.title] || item.title)
            if(isShowVibrationRemark.value == true) {
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: 'line',
                data: item.data,
                smooth: false,
                symbolSize: 3,
                markLine: scoreMarkLine,
                connectNulls: true
            })
            } else if(isShowVibrationRemark.value == false) {
            DataInfo.push({
                name: langObj.value[item.title] || item.title,
                type: 'line',
                yAxisIndex: 0,
                data: item.data,
                smooth: false,
                symbolSize: 3,
                connectNulls: true
            })
            }
        }
    })

    if(!DataInfo || !DataInfo.length) {
      isHideVibration.value = true
    }

    function Init(sel: { [x: string]: any; }, dataInfo: any[]) {
      SelectedData = sel || {};
      YaData = [], SeriesData = [];
      let Datas = JSON.parse(JSON.stringify(dataInfo));
      for (let n = 0; n < LengData.length; n++) {
        const ydata = Datas[n].data;
        let max = Math.max(...ydata) / 0.8 / 5
        if (max > 0 && max < 0.5) {
          max = 1
        }
        const ymax = Math.round(max) * 5;

        // 如果该图例状态为false时，则跳过 不push
        if (sel[LengData[n]]) {
          YaData.push({
            type: 'value',
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
              lineStyle: {
                color: ['rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgba(250, 60, 88, 1)', 'rgba(254, 178, 7, 1)', 'rgb(117, 116, 116)']
              }
            },
            position: 'left',
            offset: '-10',
            axisLine: {
              lineStyle: {
                color: theme.value=='white'?'#333':'#fff'
              }
            },
          })
        } else {
          Datas[n].data = [];
        }
        Datas[n].yAxisIndex = 0
        SeriesData.push(Datas[n]);
      }
      if (YaData.length == 0) {
        YaData = [{ type: 'value' }];
      }
      option = {
        color: colors,
        tooltip: {
          hideDelay: 100,
          transitionDuration: 0,
          animation: false ,
          trigger: 'axis',
          axisPointer: {
            type: 'cross' ,
          }
        },
        grid: {
          right: '5%',
          left: '7%',
        },
        toolbox: {
          trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
          data: LengData,
          selected: SelectedData,
          textStyle: {
            color: theme.value=='white'?'#333':'#fff',
          }
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: xData,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.3
              }
            },
            axisLabel: {
              color: theme.value=='white'?'#333':'#fff',
              fontSize: 16,
              showMaxLabel: true
            },
          }
        ],
        yAxis: [
            {
                type: 'value',
                scale: true,
                min: 0,
                max: 100,
                splitNumber: 5,
                splitLine: {
                lineStyle: {
                    color: ['rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgb(117, 116, 116)', 'rgba(250, 60, 88, 1)', 'rgba(254, 178, 7, 1)', 'rgb(117, 116, 116)']
                }
                },
                position: 'left',
                offset: '-10',
                axisLine: {
                lineStyle: {
                    color: theme.value=='white'?'#333':'#fff'
                }
                },
            }
        ],
        series: SeriesData
      };
    }
    Init(healthChartSelectlenged, DataInfo);

    if (option && typeof option === "object") {
        myChartD && myChartD.clear()
        myChartD && myChartD.setOption(option, true);
    }


    myChartD.off('legendselectchanged').on("legendselectchanged", function (params: { selected: any; }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlenged = params.selected;
        Init(healthChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
            myChartD?.clear()
            myChartD?.setOption(option, true);
        }
    })
}


const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


</script>

<style lang="less" scoped>
/deep/ .ant-picker {
  background-color: #07249933 !important ;
}

.box1 {
    border: 1px solid #0d53b7cc;
    // background: #07249933;
    background: url("@/assets/screen/p_left_bodyb_bg.png") no-repeat;
    background-size: 100% 100%;
}

.btn1 {
    padding: 5px 20px;
    background-color: #072499;
    border-radius: 2px;
}

</style>
