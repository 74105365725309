import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000A32","overflow-y":"scroll","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}
const _hoisted_2 = { class: "box" }

import {  onMounted, ref } from "vue";
import request from "../../common/request";
// import request from "../../common/request";
import MonthReportComponent from "./MonthReport.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getMemberOnboarding } from "../../common/tools";

export default /*@__PURE__*/_defineComponent({
  __name: 'listReportMonth',
  setup(__props) {

let menuSelect:any = ref(1)

const selectMenu = (ev:any) => {
    menuSelect.value = ev
}
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
onMounted(() => {
    init()

    //新注册用户，未经过引导的需弹窗进行引导
    if(!getMemberOnboarding()){
        selectMenu(3);
    }
})

const init = () => {
    if(localStorage.getItem('report_type')) {
        menuSelect.value = JSON.parse(localStorage.getItem('report_type') || '1')
        localStorage.removeItem('report_type')
    }
}

return (_ctx: any,_cache: any) => {
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MonthReportComponent)
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})