import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-4 text-white" }
const _hoisted_3 = {
  href: "https://source.freqx.com/docs/temp.xlsx",
  download: "abc"
}

import { computed, onMounted, ref } from "vue";
import { langList } from "@/common/lang";
import { message, Upload } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue"
import { UploadFile } from "ant-design-vue/es";
import { utils, read } from 'xlsx';
import { getFactoryName, getGroupListByFactory } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import request from "@/common/request";
import { getLanguage } from "@/common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'import-machine',
  props: {
    machineTypes: {}
  },
  emits: ["success"],
  setup(__props: any, { emit: __emit }) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()


let props = __props
let emits = __emit

let isFileSelected = ref(false);

let factoryId = localStorage.getItem("factory_id");
let factoryName = getFactoryName(factoryId)
let groups = getGroupListByFactory();
//{id: '1687830223495', name: '冶炼车间', factoryId: '649a3ee1154bc03e85f25ff7', parentId: null}

onMounted(async () => {
  await getInspectionOptions()
})

let inspectionOptions: any[] = [];
/**
 * 查询智能诊断配置选项,包含转速范围
 */
const getInspectionOptions = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    inspectionOptions = result.data;
    //{_id: '6678ca20f3d0f01fc523dcf9', firstCategory: '离心泵', secondCategory: '卧式离心泵', thirdCategory: '小于300kw', speedRange: '100-400rpm', …}
  }
}



// excel上传
let fileList = ref<any>([]);

const beforeUpload = (file: UploadFile) => {
  const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  if (!isExcel) {
    message.error('请上传xlsx后缀的文件');
    return Upload.LIST_IGNORE;
  }


  return true
};

let previewTableRows = ref<any[]>([]);
let importErrors = ref<string[]>([])
let pushImportError = (rowIndex: number, err: string) => {
  importErrors.value.push(`行${rowIndex + 2}: ${err}`);
}

let isImportDataValid = computed<boolean>(() => {
  return !importErrors.value || importErrors.value.length === 0 && previewTableRows.value.length > 0
})
let machineDtoList: any[] = [];
let isUploading = ref(false);
let isImporting = ref(false);
let uploadExcel = async (options: any) => {
  const { onSuccess, file } = options;

  isUploading.value = true;
  importErrors.value = []
  previewTableRows.value = []
  let buffer = await (file as File).arrayBuffer()
  let workBook = read(buffer)
  let ws = workBook.Sheets[workBook.SheetNames[0]]
  let rows = utils.sheet_to_json(ws) as any[]

  let machineDtoMap = new Map<string, any>();
  let tableRows: any[] = []
  for (let i = 0; i < rows.length; i++) {
    if (importErrors.value.length >= 10) {
      continue
    }
    const row = rows[i];
    //["区域", "设备名称", "设备编号", "巡检序号", "监测类别", "设备位号", "设备类型", "设备厂家", "设备型号", "设备SN号","设备功率", "设备ID", "设备执行人", "传感器SN", "安装位置", "测点类型", "功率", "转频提取", "智能诊断配置", "转速范围"];

    let label = {
      groupName: row["区域"]?.toString()?.trim(),
      modeLabel: row["监测类别"]?.toString()?.trim(),
      ysshExecutor: row["设备执行人"]?.toString()?.trim(),
      ysshId: row["设备ID"]?.toString()?.trim(),
      machineTypeLabel: row["设备类型"]?.toString()?.trim(),
      sensorMachineTypeLabel: row["测点类型"]?.toString()?.trim(),
      inspectionSubLabel: row["智能诊断配置"]?.toString()?.trim(),
      speedRangeLabel: row["转速范围"]?.toString()?.trim()
    }

    if (!label.groupName) {
      pushImportError(i, "区域不能为空")
      continue;
    }

    if (label.groupName === "必填") {
      continue; // 防呆
    }

    let group = groups.find((p: any) => p.name === label.groupName)
    if (!group) {
      pushImportError(i, "区域不存在")
      continue;
    }

    let mode = [];
    if (label.modeLabel === "在线监测") {
      mode.push(1)
    } else if (label.modeLabel === "巡检") {
      mode.push(2)
    } else {
      pushImportError(i, "监测类别填写错误")
      continue
    }

    let machineTypeId: string = "";
    if (label.machineTypeLabel) {
      machineTypeId = parseMachineTypeId(label.machineTypeLabel);
      if (!machineTypeId) {
        pushImportError(i, "未找到匹配的设备类型")
        continue
      }
    }

    let machine = {
      factoryId: factoryId,
      parentFactoryId: group.parentId,
      machineCode: row["设备编号"]?.toString()?.trim(),
      groupId: group.id,
      machineName: row["设备名称"]?.toString()?.trim(),
      position: null, // 设备位置
      supplier: row["设备厂家"]?.toString()?.trim(),
      model: row["设备型号"]?.toString()?.trim(),
      machineTypeId: machineTypeId || null,
      sn: row["设备SN号"]?.toString()?.trim(),
      sort: Number(row["巡检序号"] || 1) || 1,
      experiment: false,
      params: {
        ysshExecutor: label.ysshExecutor,
        ysshId: label.ysshId
      },
      bitNumber: row["设备位号"]?.toString()?.trim(),
      machinePower: row["设备功率"]?.toString()?.trim(),
      mode: mode, // 监测类别
      sensors: []
    }

    if (!machine.machineName) {
      pushImportError(i, "设备名称不能为空")
      continue
    }
    if (!machine.machineCode) {
      pushImportError(i, "设备编号不能为空")
      continue
    }
    if (!machine.sort) {
      pushImportError(i, "巡检序号不能为空")
      continue
    }
    machine.sort = Number(machine.sort)

    let sensorMachineTypeId: string = "";
    if (label.sensorMachineTypeLabel) {
      sensorMachineTypeId = parseMachineTypeId(label.sensorMachineTypeLabel);
      if (!sensorMachineTypeId) {
        pushImportError(i, "未找到匹配的测点类型")
        continue
      }
    }

    let inspectionSubId: string = "";
    let inspectionSubs: string = "";
    let defaultSpeed: string = "";
    if (label.inspectionSubLabel) {
      let inspectionSubSplits = label.inspectionSubLabel.split("/").filter((t: string) => t);
      if (inspectionSubSplits.length === 2) {
        inspectionSubSplits.push('')
      }
      if (inspectionSubSplits.length !== 3) {
        pushImportError(i, "未找到匹配的智能诊断配置")
        continue
      }
      let inspection = inspectionOptions.find((p: any) => p.firstCategory === inspectionSubSplits[0] && p.secondCategory === inspectionSubSplits[1] && (p.thirdCategory === inspectionSubSplits[2] || '/'));
      if (!inspection) {
        pushImportError(i, "未找到匹配的智能诊断配置")
        continue
      }
      inspectionSubId = inspection._id
      inspectionSubs = inspectionSubSplits;

      if (label.speedRangeLabel) {
        let speedRange = inspectionOptions.find((p: any) => p.speedRange == label.speedRangeLabel && p.firstCategory === inspectionSubSplits[0] && p.secondCategory === inspectionSubSplits[1] && (p.thirdCategory === inspectionSubSplits[2] || "/"));
        if (speedRange) {
          defaultSpeed = speedRange._id
        } else {
          pushImportError(i, "未找到匹配的转速范围")
          continue
        }
      }
    }

    // "转速范围"
    let sensor = {
      sensorSn: row["传感器SN"]?.toString()?.trim(),
      sensorPlace: row["安装位置"]?.toString()?.trim(),
      machineName: machine.machineName,
      machineId: "",
      machineTypeId: sensorMachineTypeId, // 测点类型 
      national: row["功率"]?.toString()?.trim(),
      disable: false,
      inspectionSubId: inspectionSubId, //智能诊断配置 
      inspectionSubs: inspectionSubs, // 智能诊断配置
      defaultSpeed: defaultSpeed // 转速范围
    }

    if (!sensor.sensorPlace) {
      pushImportError(i, "安装位置不能为空")
      continue
    }

    tableRows.push(Object.assign(label, sensor, machine))
    let machineDto = machineDtoMap.get(machine.sort.toString())
    if (!machineDto) {
      machineDto = Object.assign({}, machine)
      machineDtoMap.set(machine.sort.toString(), machineDto)
    }

    if (sensor.sensorSn && machineDto.sensors.findIndex((t: any) => { return t.sensorSn === sensor.sensorSn }) > -1) {
      pushImportError(i, "测点编号重复")
      continue
    }

    if (machineDto.sensors.findIndex((t: any) => { return t.sensorPlace === sensor.sensorPlace }) > -1) {
      pushImportError(i, "测点安装位置重复")
      continue
    }
    machineDto.sensors.push(sensor)
  }
  previewTableRows.value = tableRows;
  machineDtoList = [...machineDtoMap.values()]

  isUploading.value = false;
  onSuccess({ url: "", status: "done" });
};

let doImport = async () => {
  if (!isImportDataValid.value || machineDtoList.length === 0) return;

  isImporting.value = true
  try {
    let res: any = await request.post("/api/machines/import?__ignoreError=true", {
      factoryId,
      machines: machineDtoList
    });
    if (res && res.status === 200) {
      message.success("导入成功");
      machineDtoList.length = 0;
      previewTableRows.value.length = 0;
      emits("success")
    } else {
      message.error("导入失败");
      importErrors.value.push(res.message)
    }
  } catch (err) {
    message.error("导入失败");
  } finally {
    isImporting.value = false;
  }
}

function parseMachineTypeId(label: string): string {
  let machineTypeId: string = "";
  if (label) {
    let machineTypeSplits = label.split("/").filter((t: string) => t);
    if (machineTypeSplits.length !== 3) {
      return machineTypeId;
    }
    let machineType = props.machineTypes.find((p: any) => p.FirstCategory === machineTypeSplits[0] && p.SecondCategory === machineTypeSplits[1] && p.ThirdCategory === machineTypeSplits[2]);
    if (!machineType) {
      return machineTypeId;
    }
    machineTypeId = machineType.id
  }
  return machineTypeId
}

//["区域", "设备名称", "设备编号", "巡检序号", "监测类别", "设备位号", "设备类型", "设备厂家", "设备型号", "设备SN号", "设备功率", "设备ID", "设备执行人"],
const previewColumns = [
  { title: langObj.value["区域"], dataIndex: "groupName", fixed: 'left', },
  { title: langObj.value["设备名称"], dataIndex: "machineName", fixed: 'left', },
  { title: langObj.value["设备编号"], dataIndex: "machineCode", fixed: 'left', },
  { title: langObj.value["巡检序号"], dataIndex: "sort" },
  { title: langObj.value["监测类别"], dataIndex: "modeLabel" },
  { title: langObj.value["设备位号"], dataIndex: "bitNumber" },
  { title: langObj.value["设备类型"], dataIndex: "machineTypeLabel", ellipsis: true },
  { title: langObj.value["设备厂家"], dataIndex: "supplier" },
  { title: langObj.value["设备型号"], dataIndex: "model" },
  { title: langObj.value["设备SN号"], dataIndex: "sn" },
  { title: langObj.value["设备功率"], dataIndex: "machinePower" },
  { title: langObj.value["设备ID"], dataIndex: "ysshId" },
  { title: langObj.value["设备执行人"], dataIndex: "ysshExecutor" },
  // "传感器SN", "安装位置", "测点类型", "功率", "转频提取", "智能诊断配置", "转速范围"],
  { title: langObj.value["传感器SN"], dataIndex: "sensorSn" },
  { title: langObj.value["安装位置"], dataIndex: "sensorPlace" },
  { title: langObj.value["测点类型"] || "测点类型", dataIndex: "sensorMachineTypeLabel", ellipsis: true },
  { title: langObj.value["功率"], dataIndex: "national", ellipsis: true },
  { title: langObj.value["智能诊断配置"] || '智能诊断配置', dataIndex: "inspectionSubLabel" },
  { title: langObj.value["转速范围"], dataIndex: "speedRangeLabel" }
];


return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isFileSelected))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "文件已选择", -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, { type: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: 1 }),
        _createVNode(_component_a_col, {
          flex: 1,
          style: {"text-align":"center"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_upload, {
              "file-list": _unref(fileList),
              "before-upload": beforeUpload,
              "custom-request": _unref(uploadExcel)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  loading: _unref(isUploading)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(UploadOutlined)),
                    _createTextVNode(" " + _toDisplayString(_unref(langObj)["上传"] || "上传") + "Excel ", 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            }, 8, ["file-list", "custom-request"]),
            _createElementVNode("p", _hoisted_2, [
              _createTextVNode(_toDisplayString(_unref(getLanguage)('仅支持xlsx格式文件')) + ", " + _toDisplayString(_unref(getLanguage)('点击')) + " ", 1),
              _createElementVNode("a", _hoisted_3, _toDisplayString(_unref(getLanguage)('下载Excel模板')), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: 1 })
      ]),
      _: 1
    }),
    (_unref(importErrors).length)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 1,
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { flex: 1 }),
            _createVNode(_component_a_col, { flex: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(importErrors), (err, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "text-red-600"
                    }, _toDisplayString(err), 1))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { flex: 1 })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(previewTableRows).length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_a_row, { type: "flex" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_table, {
                    style: {"width":"100%"},
                    size: "small",
                    columns: previewColumns,
                    "data-source": _unref(previewTableRows),
                    pagination: true,
                    scroll: { x: 2500 },
                    sticky: ""
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { flex: 1 }),
              _createVNode(_component_a_col, {
                flex: 1,
                style: {"text-align":"center"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    disabled: !_unref(isImportDataValid),
                    onClick: _unref(doImport),
                    loading: _unref(isImporting)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)["确认导入"] || "确认导入"), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick", "loading"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { flex: 1 })
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})