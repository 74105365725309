<template>
  <div>
    <a-card class="whiteBg" :bordered="false">
      <template #title>
        <div class="cardHead">
          <node-index-outlined/>
          <span style="position: relative; top: 4px; left: 5px">{{
              dataSource.sensorPlace
            }}</span>
        </div>
      </template>
      <template #extra>
        <a-radio-group size="default">
          <a-radio-button value="c" @click="copy">{{ getLanguage('复制') }}</a-radio-button>
        </a-radio-group>
        <a-radio-group size="default">
          <a-radio-button value="c" @click="pasteOverlay">{{ getLanguage('粘贴') }}</a-radio-button>
        </a-radio-group>
        <a-radio-group size="default">
          <a-radio-button value="c" @click="editSave">{{ getLanguage('保存') }}</a-radio-button>
        </a-radio-group>
      </template>
    </a-card>
    <div class="main whiteBg" style="max-height: 1450px; overflow-y: scroll">
      <a-table
          bordered
          :emptyText="'暂无数据'"
          :columns="columns"
          :data-source="dataSource.table"
          :pagination="false"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'dataType'">
           {{ getLanguage(text) }}
          </template>
          <template v-if="column.dataIndex === 'enable'">
            <a-switch
                v-model:checked="record.enable"
                :checked-children="getLanguage('开')"
                :un-checked-children="getLanguage('关')"
            />
          </template>
          <template v-if="column.dataIndex === 'level1Threshold'">
            <a-input-number v-model:value="record.level1RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            {{getLanguage('笔内累计')}}
            <a-input-number v-model:value="record.level1LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('笔大于阈值')}}:
            <a-input-number v-model:value="record.level1Threshold" :controls="false"
                            style="width: 70px" size="small" :min="0"/>
                            {{getLanguage('触发报警')}}
            <a-input-number v-model:value="record.level1IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('天')}}
            <a-input-number v-model:value="record.level1IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('次')}}
          </template>
          <template v-if="column.dataIndex === 'level2Threshold'">
            <a-input-number v-model:value="record.level2RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('笔内累计')}}
            <a-input-number v-model:value="record.level2LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('笔大于阈值')}}:
            <a-input-number v-model:value="record.level2Threshold" :controls="false"
                            style="width: 70px" size="small" :min="0"/>
                            {{getLanguage('触发报警')}}
            <a-input-number v-model:value="record.level2IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('天')}}
            <a-input-number v-model:value="record.level2IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('次')}}
          </template>
          <template v-if="column.dataIndex === 'level3Threshold'">
            <a-input-number v-model:value="record.level3RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('笔内累计')}}
            <a-input-number v-model:value="record.level3LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('笔大于阈值')}}:
            <a-input-number v-model:value="record.level3Threshold" :controls="false"
                            style="width: 70px" size="small" :min="0"/>
                            {{getLanguage('触发报警')}}
            <a-input-number v-model:value="record.level3IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('天')}}
            <a-input-number v-model:value="record.level3IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('次')}}
          </template>
          <template v-if="column.dataIndex === 'level4Threshold'">
            {{getLanguage('阈值')}}:
            <a-input-number v-model:value="record.level4Threshold" :controls="false"
                            style="width: 70px" size="small" :min="0"/>
            ,
            <a-input-number v-model:value="record.level4IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('天')}}
            <a-input-number v-model:value="record.level4IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
                            {{getLanguage('次')}}
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script lang="ts">
import request from "../../../common/request";
import { defineComponent, onMounted, PropType } from "vue";
import { ref, computed, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { getLanguage } from "../../../common/translate";


export class QuotaAlarmItem {
  _id: string | null;
  sensorId: string = "";
  sensorPlace: string = "";
  machineId: string = "";
  dataType: string = "";
  enable: boolean = false;
  level1Threshold: number | null;
  level1RangeCount: number;
  level1LimitCount: number;
  level1IntervalDays: number;
  level1IntervalTimes: number;
  level2Threshold: number | null;
  level2RangeCount: number;
  level2LimitCount: number;
  level2IntervalDays: number;
  level2IntervalTimes: number;
  level3Threshold: number | null;
  level3RangeCount: number;
  level3LimitCount: number;
  level3IntervalDays: number;
  level3IntervalTimes: number;
  level4Threshold: number | null;
  level4IntervalDays: number;
  level4IntervalTimes: number;
}

/**操作日志记录 */
export default defineComponent({
  props: {
    sensor: {
      type: Object,
      default: () => ({}),
    },
    machineId: {
      type: String,
      default: "",
    },
    expertCare: {
      type: Boolean,
      default: null
    }
  },
  setup(props, { emit }) {
    watch(
        () => props.sensor,
        (newVal, oldVal) => {
          dataSource.value = props.sensor;
        },
    );
    onMounted(() => {
    })
    const isEdit = ref(false);
    const dataSource = ref();

    dataSource.value = props.sensor;

    const columns = [
      {
        title: getLanguage("数据类型"),
        dataIndex: "dataType",
      },
      {
        title: getLanguage("开启报警"),
        dataIndex: "enable",
      },
      {
        title: getLanguage("可用报警触发规则"),
        dataIndex: "level1Threshold",
      },
      {
        title: getLanguage("警戒报警触发规则"),
        dataIndex: "level2Threshold",
      },
      {
        title: getLanguage("故障报警触发规则"),
        dataIndex: "level3Threshold",
      },
      {
        title: getLanguage("熔断报警触发规则"),
        dataIndex: "level4Threshold",
      }
    ];
    const editSave = () => {
      var items: any = dataSource.value.table;
      var flag = true;
      var msg = "";
      for (var i = 0; i < items.length; i++) {
        var item: QuotaAlarmItem = items[i];
        if (item.enable &&
            (!item.level1RangeCount || !item.level2RangeCount || !item.level3RangeCount ||
                !item.level1LimitCount || !item.level2LimitCount || !item.level3LimitCount ||
                !item.level1Threshold || !item.level2Threshold || !item.level3Threshold || !item.level4Threshold ||
                !item.level1IntervalDays || !item.level2IntervalDays || !item.level3IntervalDays || !item.level4IntervalTimes ||
                !item.level1IntervalTimes || !item.level2IntervalTimes || !item.level3IntervalTimes || !item.level4IntervalTimes)) {
          msg = "开启报警的阈值、笔数、报警间隔必须配置";
          flag = false;
          break;
        }
      }
      if (!flag) {
        message.error(msg);
        return;
      }
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };

      request.put("/api/sensors/quota-alarm", {
        isManual: true,
        expertCare: props.expertCare,
        list: items
      }, config).then((res) => {
        if (res) {
          message.success("操作成功");
          emit("search");
          operateLogs("保存状态监测报警，传感器：" + items[0].sensorPlace);
        }
      });
    };

    // 复制
    const copy = () => {
      localStorage.setItem('copyRows3', JSON.stringify(dataSource.value.table))
    }

    // 粘贴
    const pasteOverlay = () => {
      let data = JSON.parse(localStorage.getItem('copyRows3') || '')
      if (data) {
        dataSource.value.table.map((d: any) => {
          let info = data.find((p: any) => p.dataType == d.dataType)
          if (info) {
            let temp = Object.assign({}, info);
            delete temp.id;
            delete temp.sensorId;
            delete temp.sensorPlace;
            delete temp.machineId;
            Object.assign(d, temp);
          }
        })
      }
    }

    return {
      dataSource,
      columns,
      isEdit,
      editSave,
      copy,
      pasteOverlay,
      getLanguage,
    };
  },
});
</script>
<style scoped>
:deep(.ant-card-head) {
  padding-left: 0;
}

.cardHead {
  color: #1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0;
  font-size: 18px;
}

.main {
  margin-top: 30px;
}

:deep(.ant-card-head) {
  background: #000A32 !important;
  border-bottom: 1px solid #0d53b7cc !important;
}

:deep(.ant-table-thead .ant-table-cell) {
  background: #03155C !important;
  border-right: 0 !important;
  color: white !important;
}

:deep(.ant-radio-button-wrapper) {
  background: #000A32 !important;
  color: #fff !important;
  border: 1px solid #0d53b7cc !important;
}
</style>
