<template>
  <div>
    <a-modal v-model:visible="visible" :closable="false" :footer="null" :bodyStyle="{ 'background': '#020F46' }"
      style="top: 30%" width="1200px" :maskClosable="false" v-model:z-index="editRindex">
      <div class="w-full flex">
        <div class="flex-1"></div>
        <div class="cursor-pointer" @click="close">
          <close-outlined style="font-size: 20px" />
        </div>
      </div>
      <a-layout-content style="padding: 0 20px" class="flex theme-common">
        <a-tree style="height: 40vh; overflow: auto; padding: 10px 0" class="draggable-tree" block-node
          :tree-data="treeData" @select="selectTree" :selectedKeys="selectedKeys" />

        <div class="w-full fac-box p-3 ml-4">
          <a-form :model="formInfo" name="basic" autocomplete="off" layout="inline"
            :label-col="{ style: { width: '150px' } }" style="color: #fff">
            <div class="w-full flex items-center">
              <div class="freqx_formTitle">
                {{ langObj["月报配置"] }}
              </div>
            </div>

            <div class="w-full">
              <a-tabs v-model:activeKey="activeKey" @change="tabChange">
                <a-tab-pane :key="key" v-for="(item, key) in factoryList">
                  <template #tab>
                    <span>
                      <bank-outlined />
                      {{ item.factoryName }}
                    </span>
                  </template>
                </a-tab-pane>
              </a-tabs>
            </div>

            <div class="code-box-meta markdown">
              <div class="code-box-title" style="background: #000a32">
                {{ langObj["报告生成策略"] }}
              </div>
              <div class="code-box-description">
                <a-form-item :label="langObj['是否生成报告']" name="alarmTactics">
                  <span class="flex">
                    <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.enabledOfMonth">
                      <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                      <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                    </a-radio-group>
                  </span>
                </a-form-item>
              </div>
              <div class="code-box-description">
                <a-form-item :label="getLanguage('是否需要登录查看')" name="alarmTactics">
                  <span class="flex">
                    <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.isLoginOfMonth">
                      <a-radio :value="false">{{ langObj["否"] }}</a-radio>
                      <a-radio :value="true">{{ langObj["是"] }}</a-radio>
                    </a-radio-group>
                  </span>
                </a-form-item>
              </div>
              <div class="code-box-description flex">
                <a-form-item label="" name="deadline" style="color: #fff">
                  {{ langObj["每月第"] }}&nbsp;&nbsp;<a-input-number :min="1" :max="31"
                    :disabled="!formInfo.reportRule.enabledOfMonth"
                    v-model:value="formInfo.reportRule.dayOfMonth" />&nbsp;&nbsp;{{ langObj["天生成月报"] }}
                </a-form-item>
                <a-form-item :label="getLanguage('月报生成范围')" name="alarmTactics">
                  <span class="flex">
                    <a-radio-group name="radioGroup" v-model:value="formInfo.reportRule.rangeOfMonth">
                      <a-radio :value="1">{{ getLanguage("上月月报")}}</a-radio>
                      <a-radio :value="2">{{ getLanguage("本月月报" )}}</a-radio>
                    </a-radio-group>
                  </span>
                </a-form-item>
              </div>

              <div style="display: none;" class="code-box-description">
                <a-form-item label="" name="deadline" style="color: #fff">
                  {{ langObj["每周第"] }}&nbsp;&nbsp;
                  <a-checkbox-group v-model:value="formInfo.reportRule.dayOfWeek" name="checkboxgroup"
                    :options="dayOfWeeks" />
                  &nbsp;&nbsp;{{ langObj["天生成周报"] }}
                </a-form-item>
              </div>
              <div class="code-box-description">
                <a-form-item label="" name="deadline" style="color: #fff">
                  {{ langObj["第"] }}&nbsp;&nbsp;<a-input-number :min="0" max="23"
                    :disabled="!formInfo.reportRule.enabledOfMonth"
                    v-model:value="formInfo.reportRule.dayOfMonthHour" />&nbsp;&nbsp;{{ langObj["小时生成报告"] }}
                </a-form-item>                                                                                               
              </div>
            </div>
          </a-form>
          <a-button class="center_btn" type="primary" @click="handleOk">{{
            langObj["保存"]
            }}</a-button>

        </div>
      </a-layout-content>
    </a-modal>

  </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2, getGroupListByFactory } from "../../common/tools";
import { defineProps, defineExpose, defineEmits, computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";
let langObj: any = ref({});
let language: any = ref("Chinese");
/**操作日志记录 */
var activeKey = ref<any>(0);
let facForm = ref([]);

let factoryListAll: any = ref([]);
let visible = ref(true)
let editRindex = ref("auto");

const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};

operateLogs("访问基础配置");

let factory_id = "";
getLang();

let dayOfWeeks: any = ref([
  { label: getLanguage("星期日", language.value), value: 0 },
  { label: getLanguage("星期一", language.value), value: 1 },
  { label: getLanguage("星期二", language.value), value: 2 },
  { label: getLanguage("星期三", language.value), value: 3 },
  { label: getLanguage("星期四", language.value), value: 4 },
  { label: getLanguage("星期五", language.value), value: 5 },
  { label: getLanguage("星期六", language.value), value: 6 },
]);
const close =() =>{
  visible.value = false
  emits("saveWeekReportRule", false);
}
// 保存
const handleOk = () => {
  if (!factory_id) {
    message.warning("请选择工厂后提交");
    return;
  }
  formInfo.value.factoryId = factory_id;
  request.post("/api/factories/setting", formInfo.value).then((res) => {
    if (res) {
      operateLogs("修改基础配置");
      message.success(langObj.value["操作成功"]);
      search(formInfo.value.factoryId);
      visible.value = false
      emits("saveWeekReportRule", true);
    }
  });
};

const tabChange = () => {
  var selectFact = factoryList.value[activeKey.value];
  factory_id = selectFact._id;
  search(selectFact._id);
};

const handleTableChange = (val: any) => { };
// 查询
const search = (id: any) => {
  formInfo.value = {
    reportRule: {
      enabled: true,
      dayOfMonth: 0,
      dayOfWeek: [],
      dayOfHour: 0,
      isLogin: false,
      rangeOfMonth:false
    },
  };

  const config = {
    params: { id: id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res && res.data) {
      if (res.data.reportRule?.dayOfWeek) {
        if (typeof res.data.reportRule.dayOfWeek == "number") {
          // res.data.reportRule.dayOfWeek = [res.data.reportRule.dayOfWeek];
        }
      } else {
        res.data.reportRule = {
          enabled: true,
          dayOfMonth: 0,
          dayOfWeek: [],
          dayOfHour: 0,
        };
      }

      formInfo.value = { ...res.data };
    }
  });
};

let formInfo = ref<any>({
  reportRule: {
    enabled: true,
    dayOfMonth: 0,
    dayOfWeek: [],
    dayOfHour: 0,
    rangeOfMonth:false
  },
});
let factoryList = ref<any>();
let treeData: any = ref([]);

const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      factoryListAll.value = JSON.parse(JSON.stringify(res.data));
      let tree: any = [];

      let list1 = res.data.filter((t: any) => t.parentId);

      let list2 = res.data.filter((t: any) => !t.parentId);

      list2.map((d: any) => {
        let first = {
          title: d.factoryName,
          key: d._id,
          children: [],
        };
        let list3 = list1.filter((p: any) => p.parentId == d._id);
        if (list3 && list3.length) {
          first.children = list3.map((p: any) => ({
            title: p.factoryName,
            key: p._id,
          }));
        }
        tree.push(first);
      });

      treeData.value = tree;
    }
  });
};

let selectedKeys:any = ref([])
const selectTree = (ev: any) => {
  console.log('ev', ev)
  if (ev && ev.length) {
    factory_id = ev[0];
    selectedKeys.value = ev
    search(ev[0]);
  }
};

onMounted(async () => {
  editRindex.value = props.editRindex
  await loadFactory();
  selectTree([localStorage.getItem('factory_id')]);
  // await selectTree([treeData.value[0].key]);
});

const props: any = defineProps({
  visible: null,
  editRindex: null
});
const emits = defineEmits(["saveWeekReportRule"]);

watch(
  () => props.visible,
  (newValue, oldValue) => {
    visible.value = newValue
    init()
  }
);
</script>
<style scoped>
.code-box-description {
  padding-bottom: 0;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.bigFont {
  font-size: 22px;
  font-weight: 700;
}

.freqx_tips {
  margin-top: 4px;
}

.facInfo {
  border: 1px solid gray;
}

.fac-box {
  border: 1px solid rgba(128, 128, 128, 0.384);
}
.freqx_formTitle{
  text-align:center;
  border-left:none;
}
</style>
