<template>
    <!-- 厂级大屏-区域 -->
    <div  class=" px-4 py-2 theme-bg theme-factory theme-common"
        style="background-color: #000A31; color: #fff; height: calc(100vh - 100px);overflow: hidden;">
            <div class="w-full flex" style="height:100%"  >
                <!-- 左侧 -->
                <div style="height:100%;overflow: hidden;" class="flex flex-col  mt-5 box-size">
                
                    <div class="w-full state_wrap conditon_state">
                                <div class="title flex items-center">
                                    <div ></div>
                                    <div class="p-text-color2 ml-10">  {{ langObj['健康状态统计'] }}</div>
                                </div>
                            
                                <div class="w-full flex items-center bg_wrap justify-center" style="padding: 2% 0;">
                                    <div style="width: 60%; height: 18vh;margin-top:-10%;;position:relative">
                                        <div class="pie_tex_wrap">
                                            <div>{{machinesAllV}}<span>{{langObj['台'] }}</span></div>
                                            <div> {{ langObj['设备总数'] }}</div>
                                        </div>
                                        <My3DPie style="z-index:2" :optionsData="optionsData" />
                                        <div class="pie_bg_wrap"></div>
                                    
                                    </div>
                                
                                
                                    <div>
                                        <!-- <div v-if="isShowRate" class="flex items-center">
                                            <div > {{ '可用率' }}：</div>
                                            <div>{{ availabilityRatio }}%</div>
                                        </div> -->
                                        <div class="flex items-center cursor-pointer tl_state_wrap" @click="selectMachineCondition(1)">
                                            <div style="width:14px; height:14px; background-color: #61c08f"></div>
                                            <div class="ml-2"> {{ langObj['健康'] }}：</div>
                                            <div>{{ conditionChartData[0].value }}{{getLanguage("台", language)}}</div>
                                        </div>
                                        <div class="rate_wrap">({{getLanguage('健康率',language)}}{{ conditionChartData[0].rate }}%)</div>
                                        <div class="flex items-center cursor-pointer mt-3 tl_state_wrap" @click="selectMachineCondition(2)">
                                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                                            <div class="ml-2"> {{ langObj['可用'] }}：</div>
                                            <div>{{ conditionChartData[1].value }}{{getLanguage("台", language)}}</div>
                                        </div>
                                        <div class="rate_wrap">({{getLanguage('可用率',language)}}{{ conditionChartData[1].rate }}%)</div>
                                        <div class="flex items-center cursor-pointer mt-3 tl_state_wrap" @click="selectMachineCondition(3)">
                                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                                            <div class="ml-2"> {{ langObj['警戒'] }}：</div>
                                            <div>{{ conditionChartData[2].value }}{{getLanguage("台", language)}}</div>
                                        </div>
                                        <div class="rate_wrap">({{getLanguage('警戒率',language)}}{{ conditionChartData[2].rate }}%)</div>
                                        <div class="flex items-center cursor-pointer  mt-3 tl_state_wrap" @click="selectMachineCondition(4)">
                                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                                            <div class="ml-2"> {{ langObj['故障'] }}：</div>
                                            <div>{{ conditionChartData[3].value }}{{getLanguage("台", language)}}</div>
                                        </div>
                                        <div class="rate_wrap">({{getLanguage('故障率',language)}}{{ conditionChartData[3].rate }}%)</div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full state_wrap monitor_state mt-5">
                                <div class="title flex items-center">
                                    <div ></div>
                                    <div class="p-text-color2 ml-10">  {{ langObj['设备监测统计'] }}</div>
                                </div>
                                <div  class="w-full flex items-center bg_wrap">
                                    <div class="moitor_clip cursor-pointer" @click="selectMachineStatus(1)">
                                        <img src="@/assets/screen/p_on_bg.png" />
                                        <div>{{getLanguage('运行设备数',language)}}</div>
                                        <div><span>{{ statusChartData[0]?.num }}</span>{{getLanguage("台", language)}}</div>
                                    </div>
                                    <div class="moitor_clip cursor-pointer">
                                        <img src="@/assets/screen/p_off_bg.png" @click="selectMachineStatus(0)" />
                                        <div class="off_text">{{getLanguage('停机设备数',language)}}</div>
                                        <div><span>{{ statusChartData[1]?.num }}</span>{{getLanguage("台", language)}}</div>
                                    </div>
                                    <div class="moitor_clip">
                                        <img src="@/assets/screen/p_monitor_bg.png" />
                                        <div class="monitor_text">{{getLanguage('监测测点数',language)}}</div>
                                        <div><span>{{ statusChartData[2]?.num }}</span>{{getLanguage("个", language)}}</div>
                                    </div>
                                </div>
                            </div>




                    <div class="w-full monitor_state">
                
                        <div class="w-full flex items-center mt-7">
                            <div class="title flex items-center">
                                <!-- <div></div> -->
                                <div class="p-text-color2 ml-10">{{ langObj['智能诊断报警'] }}</div>
                                <div class="flex-1"></div>
                                <div @click="selectZnZdWarn" class="flex items-center cursor-pointer whiteBgColor p-text-right" style="color: #ffffffe6;">
                                    <span>{{ langObj['查看更多'] }}</span>
                                    <right-outlined />
                                </div>
                            </div>
                        
                        </div>

                        <div class="w-full bg_wrap" style="padding-bottom: 12%;">
                            <div class="table_wrap">
                                <div class="flex items-center theme-table-head" style="background-color: #020F46;">
                                    <div class="table-info" style="opacity: 0.6">{{ langObj['设备名称'] }}</div>
                                    <div class="table-info" style="opacity: 0.6">{{ langObj['报警类型'] }}</div>
                                    <div class="table-info" style="opacity: 0.6">{{ langObj['严重程度'] }}</div>
                                    <div class="table-info" style="opacity: 0.6">{{ langObj['报警时间'] }}</div>
                                </div>

                                <div class="flex items-center" v-for="(item, index) in machineWarningData" :key="index"
                                    :style="{ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' }">
                                    <div class="table-info" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                        :title="item.machineName">{{  item.machineName }}</div>
                                    <div class="table-info">{{  langObj[warningTypeList.find(p => p.value === item.type)?.label] }}</div>
                                    <div class="table-info" :style="{ 'color': getMachineWarningName(item.condition)?.color }">{{
                                        langObj[getMachineWarningName(item.condition)?.name]
                        }}</div>
                                    <div class="table-info">{{ item.date }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 右侧 -->
                <div class="flex-1 mt-5" style="height: 85%;overflow-y: scroll;margin-left:3%">
                    <div class="flex items-center box-size">
                        <div class="title flex items-center ">
                            <div></div>
                            <div class="p-text-color2 ml-10" style="padding-bottom: 4%;">{{ langObj['区域概览'] }}</div>
                        </div>

                        <!-- <span>{{ langObj['显示单位'] }}:</span>

                        <a-select ref="select" v-model:value="listSelectFac" style="width: 120px; margin-left: 20px;"
                            @change="getGroupListDetail" :dropdownMatchSelectWidth="false">
                            <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
                            item.factoryName
                        }}</a-select-option>
                        </a-select>

                        <div class="mx-4">></div>

                        <a-select ref="select" v-model:value="listSelectGroup" style="width: 120px;" @change="selectGroup">
                            <a-select-option v-for="(item, index) in groupList2" :key="index" :value="item.sonID">{{ item.name
                                }}</a-select-option>
                        </a-select>

                        <div class="flex-1"></div> -->

                        <!-- <a-input v-model:value="value" style="width: 150px" placeholder="请输入区域名称">
                        <template #prefix>
                            <search-outlined style="color: #fff" />
                        </template>
                    </a-input> -->

                    </div>

                    <div class="w-full flex items-center flex-wrap whiteBg ">
                        <div v-for="(item, index) in groupListData" @click="selectGroup2(item)" :key="index"
                            class="box2 mt-6 mr-4 ">
                            <div class="w-full flex items-center">
                                <div class="shu"></div>
                                <div class="text" :title="item?.name || ''"
                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item?.name }}
                                </div>
                            </div>

                            <div class="heng"></div>

                            <div class="content flex items-center w-full">
                                <img src="@/assets/screen/p_quu_bg.png">
                                <div class="flex-1 content_right_wrap">
                                    <div class="w-full flex items-center heng_wrap">
                                            <div class="size14 opc8">{{ langObj['监测设备'] }}</div>
                                            <div class="size14 opc8">{{ langObj['监测测点'] }}</div>
                                    </div>
                                    <div class="w-full flex items-center heng_wrap_bg" >
                                            <div class="size18">{{ item?.machineNum }}{{getLanguage("台", language)}}</div>
                                            <div class="size18">{{ item?.sensorNum }}{{getLanguage("个", language)}}</div>
                                    </div>
                                    <div class="w-full flex items-center heng_wrap">
                                            <div class="size14 opc8">{{ langObj['运行设备'] }}</div>
                                            <div class="size14 opc8">{{ langObj['运行率'] }}</div>
                                            
                                    </div>
                                    <div class="w-full flex items-center heng_wrap_bg heng_wrap_bg2">
                                            <div class="size18">{{ item?.machineRun }}{{getLanguage("台", language)}}</div>
                                            <div class="size18" style="color: #5BFEB7;">{{ item?.machineRate }}</div>
                                    </div>

                                    <div class="w-full flex items-center heng_wrap">
                                            <div class="size14 opc8">{{ langObj['报警设备'] }}</div>
                                            <div class="size14 opc8">{{ langObj['未处理报警'] }}</div>
                                    </div>
                                    <div class="w-full flex items-center heng_wrap_bg heng_wrap_bg3">
                                            <div class="size18">{{ item?.warningMachineNum }}{{getLanguage("台", language)}}</div>
                                            <div class="size18">{{ item?.noHandleNum }}{{getLanguage("条", language)}}</div>
                                    </div>
                                </div>

                            

                            </div>
                        </div>
                    </div>
                </div>
            </div>

                 <!-- 设备列表 -->
                 <a-modal v-model:visible="machineVisible" :closable="false" :footer="null"
                    style="top: 10%" width="80%" :maskClosable="false">
                    <div style="color: #fff">
                        <div class="w-full flex">
                            <div class="flex-1"></div>
                            <div class="cursor-pointer" @click="machineVisible = false">
                                <close-outlined style="font-size: 20px" />
                            </div>
                        </div>
                        <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('设备列表', language) }}</div>

                        <a-form :model="machineModelSearch" name="horizontal_login" layout="inline" autocomplete="off">
                            <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                <a-select @change="selectGroupMachine" v-model:value="machineModelSearch.groupId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择区域', language)">
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item :label="getLanguage('健康状态', language)" name="condition">
                                <a-select v-model:value="machineModelSearch.condition" style="width: 220px" allowClear
                                :placeholder="getLanguage('请选择健康状态', language)">
                                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item :label="getLanguage('运行状态', language)" name="status">
                                <a-select v-model:value="machineModelSearch.status" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择运行状态', language)">
                                    <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="machineModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item>

                            <a-form-item>
                                <div class="flex items-center">
                                    <div @click="resetMachine" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('重置', language) }}
                                    </div>

                                    <div @click="getDataMachine" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('查询', language) }}
                                    </div>
                                </div>
                            </a-form-item>
                        </a-form>

                        <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName"
                                :columns="machineColumns" :data-source="machineDataList" 
                                @change="handleTableChangeMachineList"
                                :pagination="paginationMachineList">
                                <template #bodyCell="{ column, text, record }">
                                    <template v-if="column.dataIndex === 'factoryId'">
                                        <span>{{ getFactoryName(text) }}</span>
                                    </template>

                                    <template v-if="column.dataIndex === 'groupId'">
                                        <span>{{ getGroupName(text) }}</span>
                                    </template>

                                    <template v-if="column.dataIndex === 'experiment'">
                                        <span>{{
                                            getLanguage(whetherList.find((p) => p.value === text)?.label, language)
                                        
                                    }}</span>
                                    </template>

                                    <template v-if="column.dataIndex === 'condition'">
                                        <div class="flex items-center">
                                            <div style="width: 14px; height: 14px; border-radius: 50%"
                                                :style="{ background: getMachienCondition(record)?.color }"></div>
                                            <div class="ml-1" :style="{ color: getMachienCondition(record)?.color }">
                                                {{ getLanguage(getMachienCondition(record)?.label, language) }}
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="column.dataIndex === 'status'">
                                        <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                                            {{ getLanguage(getMachienStatus(record)?.label, language) }}
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'onlineHours'">
                                        <div class="ml-1">
                                            {{ record?.workStatus?.onlineHours?.toFixed(2) || 0 }}
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'faultIntervalTimes'">
                                        <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                                            {{ record?.workStatus?.faultIntervalTimes?.toFixed(2) || 0  }}
                                        </div>
                                    </template>
                                   



                                    <template v-if="column.dataIndex === 'act'">
                                       
                                        <a @click="viewMachine(1)">{{ langObj["健康总览"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a @click="viewMachine(2)">{{ langObj["健康诊断"]}}</a>
                                    </template>
                                </template>
                            </a-table>
                        </div>










                    </div>
                </a-modal>
                 <!-- 智能诊断报警记录 -->
                 <a-modal v-model:visible="znZdWarnVisible" :closable="false" :footer="null"
                    style="top: 10%" width="80%" :maskClosable="false">
                    <div style="color: #fff">
                        <div class="w-full flex">
                            <div class="flex-1"></div>
                            <div class="cursor-pointer" @click="znZdWarnVisible = false">
                                <close-outlined style="font-size: 20px" />
                            </div>
                        </div>
                        <div class="text-center w-full mb-4" style="font-size: 24px">{{ getLanguage('智能诊断报警记录', language) }}</div>
                        <a-form :model="znZdWarnSearch" name="horizontal_login" layout="inline" autocomplete="off">
                            <a-form-item :label="getLanguage('设备区域', language)" name="groupId" allowClear>
                                <a-select  @change="selectGroupZdWarn" v-model:value="znZdWarnSearch.groupId" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择区域', language)">
                                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.sonID">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                          
                            <a-form-item :label="langObj['设备']" name="status">
                                <a-select v-model:value="znZdWarnSearch.machineId" style="width: 220px" :placeholder="langObj['请选择设备']"
                                    :options="machineListZdWarn" :filter-option="filterOptionZdReport"
                                    :field-names="{ label: 'name', value: 'id', options: 'machineListZdWarn' }" allowClear show-search>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="getLanguage('报警时间', language)" name="status">
                                <a-range-picker v-model:value="znZdWarnSearch.date" :disabled-date="disabledDate"
                                @calendarChange="onCalendarChange" />
                            </a-form-item>
                            <a-form-item :label="getLanguage('处理状态', language)" name="status">
                                <a-select v-model:value="znZdWarnSearch.handleStatus" style="width: 220px" allowClear
                                    :placeholder="getLanguage('请选择处理状态', language)">
                                    <a-select-option v-for="(item, index) in handleStatusList" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="getLanguage('严重程度', language)" name="condition">
                                <a-select v-model:value="znZdWarnSearch.condition" style="width: 220px" allowClear
                                :placeholder="getLanguage('请选择严重程度', language)">
                                    <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>



                            <!-- <a-form-item :label="getLanguage('搜索', language)" name="status">
                                <a-input v-model:value="yjModelSearch.keyWord" :placeholder="getLanguage('请输入设备名称/编号', language)" />
                            </a-form-item> -->

                            <a-form-item>
                                <div class="flex items-center">
                                    <div @click="resetZdWarn" class="px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('重置', language) }}
                                    </div>

                                    <div @click="getZdWarnData" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                                        style="background-color: #072499; color: #fff">
                                        {{ getLanguage('查询', language) }}
                                    </div>
                                </div>
                            </a-form-item>
                        </a-form>

                        <div class="mt-4" style="width: 100%;border: 1px solid #0d53b7cc;background: #07249933;">
                            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName"
                                :columns="znZdWarnColumns" :data-source="zdWarnData" :pagination="paginationZdWarn" @change="handleTableChangeZdWarn">
                                <template #bodyCell="{ column, text, record }">
                                    <template v-if="column.dataIndex === 'type'">
                                        <div class="flex items-center">
                                            <div v-if="text === 1">
                                            <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                                            </div>
                                            <div v-if="text === 2">
                                            <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                                            </div>
                                            <div v-if="text === 3">
                                            <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                                            </div>
                                            <div v-if="text === 4">
                                            <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                                            </div>
                                            <div class="ml-2">
                                            {{
                                                langObj[typeList.find((p) => p.value === text)?.label]
                                            }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'condition'">
                                        <div class="flex items-center">
                                            <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                                            background: conditionList.find((p) => p.value === text)
                                                ?.color,
                                            }"></div>
                                            <div class="ml-2">
                                            {{
                                                langObj[
                                                conditionList.find((p) => p.value === text)?.label
                                                ]
                                            }}
                                            </div>
                                         </div>
                                    </template>

                                    <template v-if="column.dataIndex === 'handleStatus'">
                                      
                                        <div  v-if="text === 3">
                                           {{ langObj["已关闭"] }}
                                        </div>
                                        <div v-if="text !== 3">
                                            {{
                                            handleStatusList.find((p) => p.value === text)
                                                ? langObj[
                                                handleStatusList.find((p) => p.value === text)?.label
                                                ]
                                                : langObj["未处理"]
                                            }}
                                        </div>
                                    </template>
                                    <template v-if="column.dataIndex === 'act'">
                                      
                                       
                                        <a  v-if="record?.reportIds?.length>0" @click="viewReport(record)" >{{ langObj["诊断报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a @click="detailsJx(record)" v-if="record?.repairReportId">{{ langObj["检修报告"]}}</a>
                                        <a-divider  type="vertical" />
                                        <a  @click="viewClose(record)" v-if="record.handleStatus === 3">{{ langObj["关闭原因"]}}</a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </a-modal>

                  <!-- 检修报告 -->
                  <a-modal width="50%" :closable="false" :footer="null" v-model:visible="jxVisible">
                    <maintenanceReportDetails ref="mrD" @cancel="cancelJx"></maintenanceReportDetails>
                 </a-modal>
                <!-- 诊断报告详情 -->
                <a-modal width="50%" :closable="false" :footer="null" v-model:visible="visibleDR">
                    <DiagnosticReportDetails ref="mrDref" @cancel="cancelDR"></DiagnosticReportDetails>
                 </a-modal>
                 <!-- 关闭原因 -->
                 <a-modal v-model:visible="visibledel2" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
                    style="top: 30%" width="1200px" :maskClosable="false">
                    <div style="color: #fff">
                    <div class="w-full flex">
                        <div class="flex-1"></div>
                        <div class="cursor-pointer" @click="visibledel2 = false">
                        <close-outlined style="font-size: 20px; color: #fff !important" />
                        </div>
                    </div>
                    <div class="text-center w-full" style="font-size: 24px">
                        {{ langObj["关闭报警"] }}
                    </div>

                    <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
                        :model="formClose">
                        <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-item name="closeCause" label="关闭原因" required>
                            <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" readonly />
                            </a-form-item>
                        </a-col>
                        </a-row>
                    </a-form>
                    </div>
                </a-modal>


             
             
             
             
             
                <!-- 底部切换 -->
             
             
             
             
             
             
                <div class="w-full p-tit-bottom">
                <div  style="width: 47%;margin-left: 26.5%;margin-top:-3%;">
                    <!-- :centeredSlides=true -->
                    <swiper 
                            v-if="factoryListBottom.length>=2"
                            :slidesPerView=3
                            :spaceBetween=0
                            :navigation=true
                            :autoplay="autoplay"
                            :loop="true"
                            :initialSlide= 3
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            :centeredSlides=true 
                        >
                        <swiper-slide 
                        v-for="(item,index) in factoryListBottom" :key="index">
                            <div class="fac_wrap"  :class="{'selected': activeIndex === index}"  @click="onSlideClick(index)">
                                <img v-if="!item.parentId && item.factoryName " src="@/assets/screen/p_company_bg.png"/>
                                <img v-if="item.parentId" src="@/assets/screen/p_factory_bg.png"/>
                                <img v-if="!item.factoryName" src="@/assets/screen/p_qu_bg.png"/>
                                <div class="fac_text">{{item.factoryName?item.factoryName:item.name}}</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <swiper 
                            v-if="factoryListBottom.length==1"
                            :slidesPerView=3
                            :spaceBetween=0
                            :navigation=true
                            :autoplay="autoplay"
                            :loop="false"
                            :initialSlide= 3
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            :centeredSlides=true 
                        >
                        <swiper-slide 
                        v-for="(item,index) in factoryListBottom" :key="index">
                            <div class="fac_wrap"  :class="{'selected': activeIndex === index}"  @click="onSlideClick(index)">
                                <img v-if="!item.parentId && item.factoryName " src="@/assets/screen/p_company_bg.png"/>
                                <img v-if="item.parentId" src="@/assets/screen/p_factory_bg.png"/>
                                <img v-if="!item.factoryName" src="@/assets/screen/p_qu_bg.png"/>
                                <div class="fac_text">{{item.factoryName?item.factoryName:item.name}}</div>
                            </div>
                        </swiper-slide>
                    </swiper>

                   
                </div>
           
            </div>

    </div>

    

</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch ,computed} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
    transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    transformDate3,
    getGroupListByFactory,
    getRealFactoryIdList
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css'; // 确保引入样式文件
import maintenanceReportDetails from "@/components/report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails from "@/components/report4/common/DiagnosticReportDetails2.vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
let bottom_select_facId = ref('') // 底部选择的工厂Id
import My3DPie from "./pie3d.vue"
const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(1)

let factory_id = ''
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
let machinesAllV :any = ref(0)
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
{ name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
{ name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
{ name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
{ name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
])
// 传入数据生成 option
const optionsData = ref([
{ name: '健康', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
{ name: '可用', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#d2de49'}},
{ name: '警戒', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
{ name: '故障', value: 0,rate:0.00, itemStyle: {opacity: 0.8,color: '#921e37'}},
])//3d饼图数据
let statusChartData: any = ref([      //设备运行状态统计 数据
    { label: '运行', num: 0, color: '#06DEF5' },
    { label: '停机', num: 0, color: '#ADADAD' },
    { label: '测点', num: 0, color: '#ADADAD' },
])
let factoryListBottom :any = ref([])//底部工厂数组
const factoryListLen:any = ref(1)
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref('')

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    bottom_select_facId.value = factory_id
    conditionSelectFac.value = bottom_select_facId.value
    listSelectFac.value = bottom_select_facId.value
    loadFactory()
}
const  splitArrayIntoSubarrays=(arr:any, size:any)=> {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => {
        const end = Math.min((i + 1) * size, arr.length); // 使用Math.min来避免越界
        return arr.slice(i * size, end);
    });
}

// 获取工厂
// const loopFlag = ref(true);
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        // factoryList.value = result.data.filter((p: any) => p.parentId === factory_id)
        factoryList.value = result.data.filter((p:any) => p._id === bottom_select_facId.value || p.parentId === bottom_select_facId.value)
        for(let i =0;i<factoryList.value.length;i++){
                factoryList.value[i].selected = false;
                if(!factoryList.value[i].parentId){
                    factoryList.value[i].selected = true;
                }
            }
            let factoryListBottomc = []
            factoryListBottomc = JSON.parse(JSON.stringify(factoryList.value))
            factoryListLen.value=Math.ceil((factoryList.value.length)/3)
            factoryListBottom.value = factoryListBottomc;
    
        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === bottom_select_facId.value)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
            let targetTime: any = new Date(info?.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }

        factoryInfo()
    }

}




//诊断报告查看详情
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

//检修报告
let jxVisible:any = ref(false);
let mrD = ref()
const detailsJx = (ev: any) => {
    jxVisible.value = true
    setTimeout(() => {
        mrD.value.show(ev.repairReportId)
    }, 1000);
}

const cancelJx = (ev: any) => {
        jxVisible.value = false
}

// 查看已关闭
let formClose: any = ref({
  closeCause: "",
});
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};


//获取工厂信息
const factoryInfo = async() => {
    let groups:any = []
      factoryList.value.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
         groupList.value = groups;
        getMain(groups);

}



// const loopFlag=computed(() => factoryListBottom.value?.length <3 ? false:true);
let autoplay = ref({
    delay: 10 * 60 * 1000, // 10 分钟（单位：毫秒）
    disableOnInteraction: false, // 用户操作后是否停止自动轮播
})
// Swiper 实例
let swiperInstance: any = null;
const activeIndex = ref(0); // 当前激活的幻灯片索引
const onSwiper = (swiper: any) => {//初始化
    swiperInstance = swiper;
};


// 幻灯片切换时更新激活索引
const onSlideChange = () => {
    if (swiperInstance) {
        activeIndex.value = swiperInstance.realIndex;
        selectFac(activeIndex.value )
    }
};



// 点击幻灯片时滑动到中间
const onSlideClick = (index: number) => {
    if (swiperInstance) {
        if(activeIndex.value==index){
            return
        }else{
            if(index-activeIndex.value==1){
                swiperInstance.slideNext()
            }else if(index-activeIndex.value==-1){
                swiperInstance.slidePrev()
            }else if(index==0){
                swiperInstance.slideNext()
             }
            else if(index==factoryListBottom.value.length-1){
                swiperInstance.slidePrev()
            }
            else {
                swiperInstance.slideNext()
            }
        }
        // selectFac(index)
      }
  
};




// 获取区域列表
const getMain = async (groups: any) => {
  
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(bottom_select_facId.value) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
       
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp && tempInfo.value !== 0) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? '' : temp?.toFixed(2)
            console.log('d.temp', d.temp)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (!d.workStableStatus.condition) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getStatusChartData()
        getMachineWarningChartData()
        getGroupListDetail()
    }, 500);

}
// 获取设备运行状态统计 数据
const getStatusChartData = () => {
    let s1 = 0, s2 = 0,s3=0;
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === bottom_select_facId.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    s3 = s3 + m?.sensors?.length
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === bottom_select_facId.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                s3 = s3 + m?.sensors?.length
                if (m.workStatus.wkStatus) {
                    s1++
                } else {
                    s2++
                }
            })
        })
    }

    statusChartData.value = [
        { label: '运行', num: s1, color: '#06DEF5' },
        { label: '停机', num: s2, color: '#ADADAD' },
        { label: '测点', num: s3, color: '#ADADAD' },
    ]

    // initStatusChart()
}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0;
    let r1 = '0.00', r2 = '0.00', r3 = '0.00', r4 = '0.00';
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    let machinesAll = (n1+n2+n3+n4)*1;
    machinesAllV.value = 0;
    if(machinesAll>0){
        machinesAllV.value = machinesAll
        r1 = ((n1/machinesAll)*100).toFixed(2)
        r2 = ((n2/machinesAll)*100).toFixed(2)
        r3 = ((n3/machinesAll)*100).toFixed(2)
        r4 = ((n4/machinesAll)*100).toFixed(2)
        
    }
    conditionChartData.value = [
            {name: '健康', value: n1,rate:r1, itemStyle: {opacity: 0.8,color: '#1CC48B'}},
            {name: '可用', value: n2,rate:r2, itemStyle: {opacity: 0.8,color: '#d2de49'}},
            {name: '警戒', value: n3,rate:r3, itemStyle: {opacity: 0.8,color: '#cd5f3b'}},
            {name: '故障', value: n4,rate:r4, itemStyle: {opacity: 0.8,color: '#921e37'}}
    ]
   
    optionsData.value= JSON.parse(JSON.stringify(conditionChartData.value))

    // initConditionChart()
}


//智能诊断报警记录

// let handleStatusList:any=ref([
//     { label: '未处理', value: 2 },
//     { label: '已处理', value: 3 },
// ])
let handleStatusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let znZdWarnVisible = ref(false);
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let zdWarnData = ref([])
let znZdWarnSearch:any = ref({
    groupId: null,
    machineId: null,
    date: null,
    skip: 1,
    take: 10,
    condition: "",
    handleStatus: null,
    expertCertification: null,
    type:1
})
const paginationZdWarn = computed(() => ({
  total: znZdWarnSearch.value.total,
  current: znZdWarnSearch.value.skip,
  pageSize: znZdWarnSearch.value.take,
}));
 

const znZdWarnColumns = [
{
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
   
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
 {
        title: getLanguage('操作', language.value),
        dataIndex: "act",
    }
    
];
const selectZnZdWarn = () => {
    znZdWarnVisible.value = true
    resetZdWarn()
   
    
    getZdWarnData()
}  
let machineListZdWarn = ref([])
// 选择区域
const selectGroupZdWarn = (ev:any) => {
   
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    znZdWarnSearch.value.machineId = ''
    if(ev) {
        machineListZdWarn.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineListZdWarn.value = []
    }
}
const getZdWarnData = async () => {
    

  let config: any = {
    params: {
      skip: znZdWarnSearch.value.skip,
      take: znZdWarnSearch.value.take,
      groupId: znZdWarnSearch.value.groupId,
      machineId: znZdWarnSearch.value.machineId,
      type: znZdWarnSearch.value.type,
      handleStatus: znZdWarnSearch.value.handleStatus,
      expertCertification: znZdWarnSearch.value.expertCertification,
      factoryIds: getRealFactoryIdListById(bottom_select_facId.value) 
    },
    headers: {
      requestId: uuidv4(),
    },
  };
 
 

  if (znZdWarnSearch.value.date && znZdWarnSearch.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(znZdWarnSearch.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(znZdWarnSearch.value.date[1]), 2);
  }
   
  if (znZdWarnSearch.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value == znZdWarnSearch.value.condition
    )?.realValue;
    
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    zdWarnData.value = result.data.list;
    znZdWarnSearch.value.total = result.data.total;
  }
};

const resetZdWarn = () => {
    znZdWarnSearch.value={
        groupId: null,
        machineId: null,
        date: null,
        skip: 1,
        take: 10,
        condition: "",
        handleStatus: null,
        expertCertification: null,
        type:1
    }
    // getZdWarnData()
}
const handleTableChangeZdWarn = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    znZdWarnSearch.value.sort = sorter.field;
    znZdWarnSearch.value.order = order;
  }
  // 处理分页数据
  znZdWarnSearch.value.skip = pagination.current;
  znZdWarnSearch.value.take = pagination.pageSize;

  getZdWarnData();
};



// 设备列表弹框
let machineModelSearch:any = ref({
    condition: null,
    status: null,
    groupId: null,
    factoryId: '',
    keyWord: '',
    skip:1,
    take:20,
    total:0,

})

const viewMachine = (type:any)=>{
    if(type==1){
        router.push('/dashboard/machine')
    }else{
        router.push('/diagnosisN/overview')
    }
 
}
let machineVisible = ref(false)
let machineDataList: any = ref([])
const handleTableChangeMachineList = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  machineModelSearch.value.skip = pagination.current
  machineModelSearch.value.take = pagination.pageSize
};


const paginationMachineList = computed(() => ({
  total: machineModelSearch.value.total,
  current: machineModelSearch.value.skip,
  pageSize: machineModelSearch.value.take,
}));

let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
    { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
    { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
];

let whetherList = [
    { label: "是", value: true },
    { label: "否", value: false },
];

const machineColumns = [
    {
        title: getLanguage('设备编号', language.value),
        dataIndex: "machineCode",
    },
    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    {
        title: getLanguage('所属工厂', language.value),
        dataIndex: "factoryId",
    },
    {
        title: getLanguage('设备区域', language.value),
        dataIndex: "groupId",
    },
    {
        title: getLanguage('设备位置', language.value),
        dataIndex: "position",
    },
    // {
    //     title: getLanguage('设备厂家', language.value),
    //     dataIndex: "supplier",
    // },
    // {
    //     title: getLanguage('设备型号', language.value),
    //     dataIndex: "model",
    // },
    {
        title: getLanguage('设备类型', language.value),
        dataIndex: "machineTypeAll",
    },
    {
        title: getLanguage('设备SN号', language.value),
        dataIndex: "sn",
    },
    {
        title: getLanguage('巡检序号', language.value),
        dataIndex: "sort",
    },
    // {
    //     title: getLanguage('设备描述', language.value),
    //     dataIndex: "remark",
    // },
    {
        title: getLanguage('健康状态', language.value),
        dataIndex: "condition",
    },
    {
        title: getLanguage('运行状态', language.value),
        dataIndex: "status",
    },
    {
        title: getLanguage('设备运行时间', language.value),
        dataIndex: "onlineHours",
    },
    {
        title: getLanguage('检修次数', language.value),
        dataIndex: "repairReportsCount",
    },
    {
        title: getLanguage('平均故障间隔时间', language.value),
        dataIndex: "faultIntervalTimes",
    },
    {
    title: "操作",
    dataIndex: "act",
    },
];

const machineHealth = () =>{
    router.push('/dashboard/machine')
}
const machineScreen = () =>{
    router.push('/dashboard/machine')
}
// 获取设备健康状态
const getMachienCondition = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.stableCondition) {
        return conditionList.find(
            (p: any) => p.value === ev.workStatus.stableCondition
        );
    } else {
        return conditionList[0];
    }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
    if(ev.workStatus?.quotaWkStatus === true) {
        return statusList[0]
    } else if(ev.workStatus?.quotaWkStatus === false) {
        return statusList[1];
    } else if(ev.workStatus?.scoreWkStatus) {
        return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
    } else {
        return statusList[1];
    }
};


const selectMachineCondition = (condition: any) => {
    resetMachine()
    machineModelSearch.value.condition = condition
    machineModelSearch.value.factoryId = conditionSelectFac.value
    machineVisible.value = true
    getDataMachine()//(选择完底部数据后需要更新)
}


const selectMachineStatus = (status: any) => {
    resetMachine()
    machineModelSearch.value.status = status
    machineModelSearch.value.factoryId = bottom_select_facId.value
    machineVisible.value = true
    getDataMachine()//(选择完底部数据后需要更新)
}

const resetMachine = () => {
    machineModelSearch.value = {
        condition: null,
        status: null,
        groupId: null,
        factoryId: '',
        keyWord: '',
    }
}

const getDataMachine = async () => {
    let config: any = {
        params: {
            groupId: machineModelSearch.value.groupId,
            status: machineModelSearch.value.status,
            factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
            keyWord: machineModelSearch.value.keyWord,
            mode: [1],
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (machineModelSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value === machineModelSearch.value.condition
        )?.realValue;
    }

    let result = await request("/api/machines", config);

    if (result && result.data) {
        machineDataList.value = result.data;
        machineModelSearch.value.total = result.data.length
    } else {
        machineDataList.value = []
    }
}




//底部选择工厂

const selectFac = (index:any)=>{

    for(let i =0;i<factoryList.value.length;i++){
         factoryList.value[i].selected = false
        }
        factoryListBottom.value = JSON.parse(JSON.stringify(factoryList.value))
        // factoryListBottom.value = splitArrayIntoSubarrays(factoryList.value, 3);
        factoryListBottom.value[index].selected = true;
        bottom_select_facId.value = factoryListBottom.value[index]._id;
       if(!bottom_select_facId.value){
         bottom_select_facId.value=factory_id;
     }
         listSelectFac.value = bottom_select_facId.value;
         conditionSelectFac.value = bottom_select_facId.value
        
      
        setTimeout(()=>{
            factoryInfo()
        },100)
       
        
       
    
}
// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === bottom_select_facId.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [bottom_select_facId.value]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 3,
            factoryIds,
            typeList: [1]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
        // console.log(machineWarningData.value,"machineWarningData.value")
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([{ name: langObj.value['全部区域'], sonID: '' }])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = [{ name: langObj.value['全部区域'], sonID: '' }]
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds?.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo?.groups)
        list1 = facInfo?.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList
}




const selectGroup2 = (ev: any) => {
    listSelectGroup.value = ev.sonID;
    localStorage.setItem('groupId', listSelectGroup.value)
    // selectGroup(listSelectGroup.value)
    setTimeout(()=>{
        router.push('/dashboard/group')
    },500)
   
}
















// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}

</script>

<style lang="less" scoped>
.enW{
    width: 150px;
}
/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input-affix-wrapper {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input {
    background-color: #04165D;
    color: #fff;
}
//左侧部分样式
.tl_state_wrap{
    font-size: 0.6vw;
}

.rate_wrap{
    color: #ffffff99;
    font-family: "PingFang SC";
    font-size: 0.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 0.6vw;
    padding-left:20px;
}
.pie_bg_wrap{
    position:absolute;
    width:85%;
    height:8vh;
    background: url("@/assets/screen/p_pie_bg.png") no-repeat;
    background-size: 100% 100%;
    top:55%;
    left:-10%;
    z-index:0;
}
.pie_tex_wrap{
    position:absolute;
    width:55%;
    text-align:center;
    left:5%;
    top:35%;
    // z-index:3;
    div:nth-of-type(1){
        font-family: "YouSheBiaoTiHei";
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 700;
        color: #fff;
        line-height:1.2vw;
        span{
            font-size: 0.7vw;
            line-height:0.7vw;
        }
    }
    div:nth-of-type(2){
        color: #f6f6f6;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.6vw;
        font-style: normal;
        font-weight: 400;
        line-height: 0.6vw;
        margin-top:2%;
    }
  
}
.moitor_clip{
    width: 33%;
    text-align: center;
    margin-top: 5%;
    padding-bottom: 5%;
    img{
        display: block;
        width: 65%;
        margin: 0 auto;
        // height: 105px;
    }
    div:nth-of-type(1){
        color: #4efaee;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.8vw;
        &.off_text{
            color: #a7a7a7;
         }
        &.monitor_text{
            color: #71a4ff;
        }
    }
    div:nth-of-type(2){
        padding-bottom: 5%;
        margin-top: 5%;
        color: #ffffff;
        font-family: "YouSheBiaoTiHei";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5vw;
        span{
            // color: #ffffff;
            font-family: "YouSheBiaoTiHei";
            font-size: 1.2vw;
            font-weight:700;
        }
    }
    
}
.p-tit-bottom{
    position:fixed;
    width: 100%;
    height: 6.8vh;
    bottom:0;
    left:0;
    z-index:100;
    background: url("@/assets/screen/p_tit_bg.png") no-repeat;
    background-size: 100% 100%;
    
}
.bg_wrap{
    width: 100%;
    height:21vh;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 2%;
   
}


.title {
    width: 100%;
    // height: 48px;
    font-size: 1.2vw;
    // text-shadow: 0 4px 0 #0a285fb0;
    font-family: "YouSheBiaoTiHei";
    background: url('@/assets/screen/p_left_tit_bg.png') no-repeat;
    background-size: 100% 100%;
    font-weight: 700;
    font-style: italic;
    // justify-content: space-between;
    .p-text-right{
        font-family: "PingFang SC";
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.8vw;
        padding-right:1%;
    }

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}

.box1 {
    width: 230px;
    height: 80px;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        color: #06DEF5;
    }
}
.content{
    width: 96%;
    margin: 0 auto;
}

.table-info {
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.bg_wrap{
    width: 100%;
    background: url("@/assets/screen/p_left_body_bg.png") no-repeat;
    background-size: 100% 100%;
    .table_wrap{
        width:98%;
        margin: 0 auto;
        overflow: hidden
    }
   
}

.box2 {
    width: 31%;
    // height: 225px;
    // background: #07249933;
    // border: 1px solid #0d53b7cc;
    background: url("@/assets/screen/f_center_bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding:2% 0;
    margin-right:2%;
    overflow: hidden;
    // padding: 15px 10px 0 10px;

    .shu {
        width: 0.2vw;
        height: 0.9vw;
        background-color: #13DCEA;
        margin-left: 5%;
    }
    .text{
        color: rgb(255, 255, 255);
        font-family: "PingFang SC";
        font-size: 0.9vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.9vw;
        margin-left: 1%;
    }

    .heng {
        margin: 0 auto;
        margin-top: 2%;
        width: 95%;
        border: 1px solid #ffffff4d;
    }
    .heng_wrap{
        width: 90%;
        justify-content: center;
        margin-top: 4%;
        text-align: center;
        div{
            width:50%;
            color: rgb(255, 255, 255);
            font-family: "PingFang SC";
            font-size: 0.7vw;
            font-style: normal;
            font-weight: 500;
            line-height: 0.7vw;
        }
    }
    .heng_wrap_bg{
        width: 90%;
        justify-content: center;
        color: rgb(6, 222, 245);
        font-family: "YouSheBiaoTiHei";
        font-size: 1.1vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.1vw;
        // padding: 2.04px 42.85px 2.04px 20.41px;
        align-items: flex-start;
        margin-top: 2%;
        text-align: center;
        padding:3px 0;
        
        background: linear-gradient(90deg, rgba(6, 222, 245, 0.4) 0%, rgba(6, 222, 245, 0) 105.15%);
        div{
          width:50%;
         
        }
        &.heng_wrap_bg2{
            color: rgb(91, 254, 183);
            background: linear-gradient(90deg, rgba(91, 254, 183, 0.4) 0%, rgba(91, 254, 183, 0) 105.15%);
        }
        &.heng_wrap_bg3{
            color: rgb(184, 18, 18);
            background: linear-gradient(90deg, rgba(184, 18, 18, 0.4) 0%, rgba(184, 18, 18, 0) 105.15%);
        }
    }

    .content {
        margin-top: 1%;
        padding-left: 5%;

        img {
            width: 7.3vw;
            // height: 142px;
            // margin-left: 40px;
        }
        .content_right_wrap{
            margin-left: 5%;
        }
    }
}

.box3 {
    border: 1px solid #0d53b7;
    border-radius: 2px;
    padding: 16px 10px;

    .box4 {
        width: 320px;
        height: 168px;
        border: 1.5px solid #0d53b7cc;
        padding: 10px;

        .shu {
            width: 2px;
            height: 10px;
            background-color: #13DCEA;
        }

        .heng {
            margin-top: 5px;
            width: 100%;
            border: 1px solid #ffffff4d;
        }

    }
}



.size18 {
    font-size: 18px;
}

.size14 {
    font-size: 14px;
}

.opc8 {
    opacity: 0.8
}

.picIcon {
    width: 14px;
    height: 14px;
}

.line {
    width: 10px;
    height: 10px;
    background-color: #4efaee;
    border-radius: 5px;
}

.down {
    width: 10px;
    height: 10px;
    background-color: #29314d;
    border-radius: 5px;
}

.animation {
    -webkit-animation: twinkling 5s infinite ease-in-out;
    animation: twinkling 5s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes twinkling {
    0% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }

    50% {
        transform: scale(2);
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    100% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }
}
// 走马灯
.change_wrap{
    display:flex!important;
    justify-content:center;
    // margin-top:-3%
}
.fac_wrap{
    width:100%;
    text-align:center;
    margin:0 auto;
    img{
        display:block;
        width:29%;
        margin:0 auto;
        transform:scale(0.9)
        
    }
    div.fac_text{
        display:block;
        color:#fff;
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 700;
        line-height:normal;
        margin-top:7px;

    }
    &.selected{
        img{
            transform:scale(1.2)
        }
       
        // transform: translateX(300px); /* 应用平移效果 */
        // transition:transform 2s ease;
    }
}
.moved {
  transform: translateX(33.3%); /* 应用平移效果 */
}

:deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
//   background: #364d79;
  overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
    display:flex!important;
    align-items:center;
    width: 1vw;
    height:25%;
    // padding:2% 0;
    // height: 51.02px;
    color:#9FD1FF;font-size:1vw;
    font-weight:700;
    background: #116ae64d;
    transition: ease all 0.3s;
    opacity:0.5;
    z-index: 1;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #fff;
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
}
/deep/ .ant-modal-content{
  /* width: 100vw;
  height: 30vh; */
  /* background: red!important; */
  background: url('@/assets/screen/modal_bg.png') no-repeat !important;
  background-size: 100% 100%;
}

</style>