import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search_wrap" }
const _hoisted_2 = { class: "w-full fac-box p-3 ml-4" }
const _hoisted_3 = { class: "w-full flex items-center" }
const _hoisted_4 = {
  class: "freqx_formTitle",
  style: {"width":"400px"}
}
const _hoisted_5 = { class: "code-box-meta markdown" }
const _hoisted_6 = {
  class: "code-box-title",
  style: {"background":"#000a32"}
}
const _hoisted_7 = { class: "code-box-description" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "code-box-description" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "code-box-description" }
const _hoisted_12 = { class: "code-box-description" }
const _hoisted_13 = { class: "w-full flex items-center" }
const _hoisted_14 = {
  class: "freqx_formTitle",
  style: {"width":"400px"}
}
const _hoisted_15 = { class: "code-box-meta markdown" }
const _hoisted_16 = {
  class: "code-box-title",
  style: {"background":"#000a32"}
}
const _hoisted_17 = { class: "code-box-description" }
const _hoisted_18 = { class: "flex" }
const _hoisted_19 = { class: "code-box-description" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { class: "code-box-description flex" }
const _hoisted_22 = { class: "flex" }
const _hoisted_23 = { class: "code-box-description" }

import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { onMounted } from "vue";
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'RuleSetting',
  setup(__props) {

let langObj: any = ref({});
let language: any = ref("Chinese");
/**操作日志记录 */
var activeKey = ref<any>(0);
let facForm = ref([]);

let factoryListAll: any = ref([]);
let searchFac = ref('');


const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};

operateLogs("访问基础配置");

let factory_id = "";
getLang();

let dayOfWeeks: any = ref([
  { label: getLanguage("星期日", language.value), value: 0 },
  { label: getLanguage("星期一", language.value), value: 1 },
  { label: getLanguage("星期二", language.value), value: 2 },
  { label: getLanguage("星期三", language.value), value: 3 },
  { label: getLanguage("星期四", language.value), value: 4 },
  { label: getLanguage("星期五", language.value), value: 5 },
  { label: getLanguage("星期六", language.value), value: 6 },
]);

// 保存
const handleOk = () => {
  if (!factory_id) {
    message.warning("请选择工厂后提交");
    return;
  }
  formInfo.value.factoryId = factory_id;
  console.log(formInfo.value)
  request.post("/api/factories/setting", formInfo.value).then((res) => {
    if (res) {
      operateLogs("修改基础配置");
      message.success(langObj.value["操作成功"]);
      search(formInfo.value.factoryId);
    }
  });
};

const tabChange = () => {
  var selectFact = factoryList.value[activeKey.value];
  factory_id = selectFact._id;
  search(selectFact._id);
};

const handleTableChange = (val: any) => { };
// 查询
const search = (id: any) => {
  formInfo.value = {
    reportRule: {
      enabled: true,
      dayOfMonth: 0,
      dayOfWeek: [],
      dayOfHour: 0,
      rangeOfMonth:1
    },
  };

  const config = {
    params: { id: id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res && res.data) {
      if (res.data.reportRule?.dayOfWeek) {
        if (typeof res.data.reportRule.dayOfWeek == "number") {
          res.data.reportRule.dayOfWeek = [res.data.reportRule.dayOfWeek];
        }
        if(!res.data.reportRule.rangeOfMonth){
          res.data.reportRule.rangeOfMonth=1
        }
       
       
      } else {
        res.data.reportRule = {
          enabled: true,
          dayOfMonth: 0,
          dayOfWeek: [],
          dayOfHour: 0,
          rangeOfMonth:1
        };
      }
     
      formInfo.value = { ...res.data };
      
    }
  });
};

let formInfo = ref<any>({
  reportRule: {
    enabled: true,
    dayOfMonth: 0,
    dayOfWeek: [],
    dayOfHour: 0,
    rangeOfMonth:null
  },
});
let factoryList = ref<any>();
let treeData: any = ref([]);

const loadFactory = () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      factoryListAll.value = JSON.parse(JSON.stringify(res.data));
      factoryFilter()
      // let tree: any = [];

      // let list1 = res.data.filter((t: any) => t.parentId);

      // let list2 = res.data.filter((t: any) => !t.parentId);

      // list2.map((d: any) => {
      //   let first = {
      //     title: d.factoryName,
      //     key: d._id,
      //     children: [],
      //   };
      //   let list3 = list1.filter((p: any) => p.parentId == d._id);
      //   if (list3 && list3.length) {
      //     first.children = list3.map((p: any) => ({
      //       title: p.factoryName,
      //       key: p._id,
      //     }));
      //   }
      //   tree.push(first);
      // });

      // treeData.value = tree;
    }
  });
};

const factoryFilter = () => {
  let factoryF = JSON.parse(JSON.stringify(factoryListAll.value)) || []
  var list1 = factoryF.map((t: any) => ({
    key: t._id,
    title: t.factoryName,
    value: t._id,
    label: t.factoryName,
    parentId: t.parentId,
    id: t._id,
    type: "factory",
  }));
  let factory: any = [];
  let parentFs = list1.filter((p: any) => !p.parentId);
  parentFs.map((d: any) => {
    let sonFactory = list1.filter((p: any) => p.parentId === d.id);
    if (sonFactory && sonFactory.length) {
      d.children = sonFactory;
    }
    factory.push(d);
  });

  let list = JSON.parse(JSON.stringify(factory));
  factoryList.value = [];
  if (searchFac.value) {
    list.map((d: any) => {
      if (d.children && d.children.length) {
        let list1 = d.children.filter((p: any) =>
          p.label.includes(searchFac.value)
        );
        if (list1.length) {
          d.children = list1;
          factoryList.value.push(d);
        } else {
          if (d.label.includes(searchFac.value)) {
            factoryList.value.push(d);
          }
        }
      } else {
        if (d.label.includes(searchFac.value)) {
          factoryList.value.push(d);
        }
      }
    });
  } else {
    factoryList.value = list;
  }
  treeData.value = factoryList.value;
}


const selectTree = (ev: any) => {
  if (ev && ev.length) {
    factory_id = ev[0];
    search(ev[0]);
  }
};

onMounted(() => {
  loadFactory();
});

return (_ctx: any,_cache: any) => {
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_layout_content, {
      style: {"padding":"0 20px"},
      class: "flex theme-common"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_input, {
              value: _unref(searchFac),
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchFac) ? (searchFac).value = $event : searchFac = $event)),
              placeholder: "请输入工厂名称搜索"
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_search_outlined, {
                  style: {"cursor":"pointer"},
                  onClick: factoryFilter
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _createVNode(_component_a_tree, {
            style: {"height":"80vh","overflow":"auto","padding":"10px 0"},
            class: "draggable-tree",
            "block-node": "",
            "tree-data": _unref(treeData),
            onSelect: selectTree
          }, null, 8, ["tree-data"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_form, {
            model: _unref(formInfo),
            name: "basic",
            autocomplete: "off",
            layout: "inline",
            "label-col": { style: { width: '150px' } },
            style: {"color":"#fff"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(langObj)["周报配置"]), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)["报告生成策略"]), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['是否生成报告'],
                    name: "alarmTactics"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(formInfo).reportRule.enabled,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formInfo).reportRule.enabled) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_form_item, {
                    label: _unref(getLanguage)('是否需要登录查看'),
                    name: "alarmTactics"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, [
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(formInfo).reportRule.isLogin,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formInfo).reportRule.isLogin) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_a_form_item, {
                    label: "",
                    name: "deadline",
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)["每周第"]) + "   ", 1),
                      _createVNode(_component_a_checkbox_group, {
                        value: _unref(formInfo).reportRule.dayOfWeek,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formInfo).reportRule.dayOfWeek) = $event)),
                        name: "checkboxgroup",
                        options: _unref(dayOfWeeks)
                      }, null, 8, ["value", "options"]),
                      _createTextVNode("   " + _toDisplayString(_unref(langObj)["天生成周报"]), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_a_form_item, {
                    label: "",
                    name: "deadline",
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)["第"]) + "  ", 1),
                      _createVNode(_component_a_input_number, {
                        min: 0,
                        max: "23",
                        disabled: !_unref(formInfo).reportRule.enabled,
                        value: _unref(formInfo).reportRule.dayOfHour,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formInfo).reportRule.dayOfHour) = $event))
                      }, null, 8, ["disabled", "value"]),
                      _createTextVNode("  " + _toDisplayString(_unref(langObj)["小时生成报告"]), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["model"]),
          _createVNode(_component_a_form, {
            model: _unref(formInfo),
            name: "basic",
            autocomplete: "off",
            layout: "inline",
            "label-col": { style: { width: '150px' } },
            style: {"color":"#fff"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)["月报配置"]), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)["报告生成策略"]), 1),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['是否生成报告'],
                    name: "alarmTactics"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_18, [
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(formInfo).reportRule.enabledOfMonth,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formInfo).reportRule.enabledOfMonth) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_a_form_item, {
                    label: _unref(getLanguage)('是否需要登录查看'),
                    name: "alarmTactics"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_20, [
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(formInfo).reportRule.isLoginOfMonth,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formInfo).reportRule.isLoginOfMonth) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_a_form_item, {
                    label: "",
                    name: "deadline",
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)["每月第"]) + "  ", 1),
                      _createVNode(_component_a_input_number, {
                        min: 1,
                        max: 31,
                        disabled: !_unref(formInfo).reportRule.enabledOfMonth,
                        value: _unref(formInfo).reportRule.dayOfMonth,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formInfo).reportRule.dayOfMonth) = $event))
                      }, null, 8, ["disabled", "value"]),
                      _createTextVNode("  " + _toDisplayString(_unref(langObj)["天生成月报"]), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    label: _unref(getLanguage)('月报生成范围'),
                    name: "alarmTactics"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_22, [
                        _createVNode(_component_a_radio_group, {
                          name: "radioGroup",
                          value: _unref(formInfo).reportRule.rangeOfMonth,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formInfo).reportRule.rangeOfMonth) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getLanguage)("上月月报")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: 2 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getLanguage)("本月月报" )), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_a_form_item, {
                    label: "",
                    name: "deadline",
                    style: {"color":"#fff"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)["第"]) + "  ", 1),
                      _createVNode(_component_a_input_number, {
                        min: 0,
                        max: "23",
                        disabled: !_unref(formInfo).reportRule.enabledOfMonth,
                        value: _unref(formInfo).reportRule.dayOfMonthHour ,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formInfo).reportRule.dayOfMonthHour ) = $event))
                      }, null, 8, ["disabled", "value"]),
                      _createTextVNode("  " + _toDisplayString(_unref(langObj)["小时生成报告"]), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["model"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: handleOk
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(langObj)["保存"]), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})