import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"rgb(0, 10, 50)","overflow-y":"hidden","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}
const _hoisted_2 = { class: "w-full flex items-center justify-center" }
const _hoisted_3 = { class: "mt-4 w-full" }

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
// import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import listComponent from "./list.vue";
import infoComponent from "./info.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'equipment',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()


let menuSelect = ref(1)     //1: 列表显示  2:台账显示

const selelctMenu = (ev:any) => {
    menuSelect.value = ev
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===1?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 1 ? '#072499' : ''}),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selelctMenu(1)))
      }, _toDisplayString(_unref(langObj)['列表显示']), 7),
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===2?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 2 ? '#072499' : ''}),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selelctMenu(2)))
      }, _toDisplayString(_unref(langObj)['台账显示']), 7)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_unref(menuSelect) === 1)
        ? (_openBlock(), _createBlock(listComponent, { key: 0 }))
        : _createCommentVNode("", true),
      (_unref(menuSelect) === 2)
        ? (_openBlock(), _createBlock(infoComponent, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})