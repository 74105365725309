<template>
    <div class="chat-layout text-primary">
        <!-- Left panel - Chat history -->
        <div class="chat-left-panel" :class="{ 'chat-left-hidden': !showLeftPanel }">
            <div class="role-selector-tabs" v-if="currentRole">
                <button
                        class="role-tab"
                        :class="{ active: currentRole === 'common' }"
                        @click="onChatRoleChanged('common')">
                    <img src="@/assets/chat/chat-role-icon1.png" :alt="getLanguage('维保专家图标')">
                    {{ getLanguage('维保专家') }} 
                </button>
                <!--
                <button
                        class="role-tab"
                        :class="{ active: currentRole === 'diagnostic' }"
                        @click="onChatRoleChanged('diagnostic')">
                        <img src="@/assets/chat/chat-role-icon2.png" alt="诊断专家图标">
                    诊断专家
                </button>
            -->
            </div>
            <div class="chat-history-content" v-if="showHistoryData">
                <!-- Group chats by date -->
                <template v-for="(group, groupIndex) in groupedChatHistory" :key="groupIndex">
                    <div class="chat-history-group-header">{{ group.label }}</div>
                    <div v-for="(item, index) in group.chats" :key="item.id || index"
                         class="chat-history-item"
                         :class="{ 'selected': selectedChat?.id === item.id }">
                        <div class="chat-history-item-content" @click="selectChat(item)">
                            <a-typography-text class="text-primary" style="width: 200px;" :ellipsis="{}"
                                               :content="item.abstract" />
                        </div>
                        <button v-if="item.id" class="delete-btn" @click="deleteChat(item)">
                            <delete-outlined />
                        </button>
                    </div>
                </template>
            </div>
        </div>

        <!-- Floating toggle button -->
        <div class="chat-history-toggle">
            <button @click="toggleHistory">
                <menu-fold-outlined v-if="showLeftPanel" />
                <menu-unfold-outlined v-if="!showLeftPanel" />
            </button>
        </div>

        <!-- Right panel - Chat content -->
        <div class="chat-panel" :class="{ 'full-width': !showLeftPanel }">
            <chat-role-selector v-if="!currentRole" @chat-role-changed="onChatRoleChanged" />
            <div class="chat-content">
                <chat-panel-common v-show="currentRole === 'common'" :chat="selectedChat" @new-chat="onNewChat" @update-chat="onUpdateChat" />
                <chat-panel-diagnostic v-show="currentRole === 'diagnostic'" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import ChatPanelCommon from './chat-panel-common.vue';
import ChatPanelDiagnostic from './chat-panel-dianostic.vue';
import ChatRoleSelector from './chat-role-selector.vue';
import { MenuFoldOutlined, MenuUnfoldOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import request from '@/common/request';
import dayjs from "dayjs";
import { ChatHistoryItem, ChatHistoryGroup } from './chat.types';
import { getLanguage } from "../../../common/translate";
const showLeftPanel = ref<boolean>(true);
const showHistoryData = ref<boolean>(false);
const chatHistory = ref<ChatHistoryItem[]>([]);
const getNewChat = (): ChatHistoryItem => {
    let now = dayjs();
    return { id: "", title: getLanguage('新对话'), abstract: getLanguage('新对话'), date: now.format("YYYY/MM/DD"), createAt: now.format("YYYY/MM/DD HH:mm:ss") };
};

const selectedChat = ref<ChatHistoryItem | null>();
const currentRole = ref<'common' | 'diagnostic' | ''>('');

// Group chat history by date categories
const groupedChatHistory = computed<ChatHistoryGroup[]>(() => {
    const today = dayjs().format('YYYY/MM/DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY/MM/DD');

    const groups: ChatHistoryGroup[] = [
        { label: '', chats: [] },
        { label: getLanguage('昨天'), chats: [] },
        { label: getLanguage('7天内'), chats: [] }
    ];

    chatHistory.value.forEach(chat => {
        if (chat.date === today) {
            groups[0].chats.push(chat);
        } else if (chat.date === yesterday) {
            groups[1].chats.push(chat);
        } else {
            groups[2].chats.push(chat);
        }
    });

    return groups;
});

function toggleHistory(): void {
    showLeftPanel.value = !showLeftPanel.value;
}

function selectChat(chat: ChatHistoryItem): void {
    selectedChat.value = chat;
}

const getHistories = async (): Promise<void> => {
    let params = {
        skip: 1,
        take: 20,
        startDate: dayjs().subtract(7, 'day').format('YYYY/MM/DD'),
        endDate: dayjs().format('YYYY/MM/DD')
    }
    const response = await request.get('api/chat', { params });
    let newChat = getNewChat();
    if (response?.data?.list.length > 0) {
        chatHistory.value = [newChat, ...response.data.list];
    } else {
        chatHistory.value = [newChat];
    }
    selectChat(chatHistory.value[0]);
};

const onNewChat = (): void => {
    //console.log('chat-layout: new chat');
    if (!chatHistory.value[0].id) {
        // 新对话已存在
        selectedChat.value = chatHistory.value[0];
        return;
    }
    selectedChat.value = getNewChat();

    chatHistory.value = [selectedChat.value, ...chatHistory.value];
};

const onUpdateChat = (chat: ChatHistoryItem): void => {
    //console.log('chat-layout: update chat');
    let chatToUpdate = chatHistory.value[0];
    chatToUpdate.title = chat.abstract;
    chatToUpdate.abstract = chat.abstract;
    chatToUpdate.createAt = chat.createAt;
    chatToUpdate.date = chat.date;;
};

const onChatRoleChanged = (role: string): void => {
    currentRole.value = role as 'common' | 'diagnostic';
};

const deleteChat = async (chat: ChatHistoryItem): Promise<void> => {
    await request.delete(`api/chat?id=${chat.id}`);
    chatHistory.value = chatHistory.value.filter(item => item.id !== chat.id);
    if (selectedChat.value?.id === chat.id) {
        selectChat(chatHistory.value[0]);
    }
};

watch(currentRole, (newRole) => {
    showHistoryData.value = newRole === 'common';
});

onMounted(async () => {
    await getHistories();
});
</script>

<style scoped lang="less">
.chat-layout {
    display: flex;
    height: 95%;
    width: 100%;
    overflow: auto;
}

.chat-left-panel {
    display: flex;
    flex-direction: column;
    width: 200px;
    border-right: 2px solid #0d53b7cc;
    overflow-x: hidden;
    transition: width 0.3s ease;

    ::-webkit-scrollbar {
        background: transparent;
        /* Chrome/Safari/Webkit */
        width: 0px;
    }
}

.chat-left-hidden {
    width: 0;
    border-right: none;
}

.chat-history-content {
    flex: 1;
    padding: 0 5px 20px;
    overflow-y: auto;
}

.chat-history-group-header {
    padding: 6px 8px 5px;
    font-size: 0.9rem;
    font-weight: semibold;
    color: rgba(255, 255, 255, 0.7);
}

.chat-history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    border-radius: 2px;
    min-width: 150px;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 5px;

    &:hover {
        background-color: #2d55f5;
    }

    &.selected {
        background-color: #2d55f5;
    }
}

.chat-history-item-content {
    flex: 1;
    overflow: hidden;
}

.delete-btn {
    opacity: 0;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    padding: 4px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: white;
    }
}

.chat-history-item:hover .delete-btn {
    opacity: 1;
}

.chat-history-toggle {
    position: absolute;
    left: 40px;
    bottom: 16px;
    transform: translateX(-100%);
    z-index: 100;

    button {
        width: 40px;
        height: 40px;
        padding: 8px;
        cursor: pointer;
        font-size: 25px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.chat-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.full-width {
    margin-left: 0;
}

.chat-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.role-selector-tabs {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 8px;
    border-bottom: 1px solid #0d53b7cc;
}

.role-tab {
    flex: 1;
    padding: 5px 12px;
    border-radius: 4px;
    border: 2px solid #0d53b7cc;
    background: #043b86;
    color: white;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
        background: #2d55f5;
        color: white;
    }

    img {
        width: 18px;
        height: 18px;
        margin-right: 3px;
    }
}
</style>
