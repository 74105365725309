import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/facIco.png'
import _imports_1 from '@/assets/groupIco.png'


const _hoisted_1 = {
  class: "box",
  style: {"width":"250px","height":"85vh","overflow":"auto"}
}
const _hoisted_2 = { class: "w-full flex items-center" }
const _hoisted_3 = {
  class: "w-full",
  style: {"color":"#fff"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex-1" }

import { onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'machine-type-left',
  props: {
    type: {},
    from: {},
    mode: {}
  },
  emits: ["selectType"],
  setup(__props: any, { emit: __emit }) {

let dataList: any = ref([]);
let selectId: any = ref("");
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const props = __props;

let type = ref(props.type || 1);
let from = ref(props.from || 2);

let search = ref("");

onMounted(() => {
  init();
});

let machineTypeListAll = ref([]);
let machineTypeList = ref([]);
//获取设备列表/测点列表
const init = () => {
  getMachineType();

  // if (props.type === 1 || props.type === 3) {
  //   if (localStorage.getItem('machine_id')) {
  //     emits('selectType', localStorage.getItem('machine_id'))
  //   }
  //   getMachineList()
  // }

  // if (props.type === 2) {
  //   if (localStorage.getItem('sensor_id')) {
  //     emits('selectSensor', localStorage.getItem('sensor_id'))
  //   }
  //   getSensorList()
  // }
};
const searchType = async () => {
  // search.value
  var key = search.value;
  machineTypeList.value?.map((t: any) => {
    t.children.map((tt: any) => {
      tt.children.map((ttt: any) => {
        if (key) {
          if (t.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
          }
          if (tt.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
            tt.isShow = true;
            tt.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
            tt.isShow = false;
            tt.isHide = true;
          }
          if (ttt.value.indexOf(key) > -1) {
            t.isShow = true;
            t.isHide = false;
            tt.isShow = true;
            tt.isHide = false;
            ttt.isShow = true;
            ttt.isHide = false;
          } else {
            t.isShow = false;
            t.isHide = true;
            tt.isShow = false;
            tt.isHide = true;
            ttt.isShow = false;
            ttt.isHide = true;
          }
        } else {
            t.isShow = false;
            t.isHide = false;
            tt.isShow = false;
            tt.isHide = false;
            ttt.isShow = false;
            ttt.isHide = false;
        }
      });
    });
  });
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result?.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
        isShow: false,
        isHide: false,
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
          isShow: false,
          isHide: false,
        };

        let list2 = result.data.filter(
          (p: any) => p.SecondCategory === d2 && p.FirstCategory === d1
        );

        list2.map((d3: any) => {
          let third = {
            value: d3.ThirdCategory,
            label: d3.ThirdCategory,
            id: d3.id,
            faults: d3.faults || [],
            isShow: false,
            isHide: false,
            faultIds:d3.faultIds || []
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    list[0].isShow = true;
    list[0].children[0].isShow = true;
    list[0].children[0].children[0].isShow = true;
    selectId.value = list[0].children[0].children[0].id;
    selectType(list[0].children[0].children[0], machineTypeListAll.value);
    machineTypeList.value = list;
  }
};

// 选择设备类型
const selectType = (ev: any, all: any) => {
  selectId.value = ev.id;
  emits("selectType", ev, all);
};

const emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input, {
        value: _unref(search),
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
        placeholder: _unref(langObj)['请输入名称搜索']
      }, {
        suffix: _withCtx(() => [
          _createVNode(_component_search_outlined, { onClick: searchType })
        ]),
        _: 1
      }, 8, ["value", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypeList), (factory, index1) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: index1,
          style: {"margin-top":"20px"}
        }, [
          _createElementVNode("div", {
            class: "w-full flex items-center cursor-pointer",
            style: {"font-size":"18px"},
            onClick: ($event: any) => (factory.isShow = !factory.isShow)
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              style: {"width":"18px"},
              src: _imports_0,
              alt: ""
            }, null, -1)),
            _createElementVNode("div", {
              class: "flex-1 ml-2 ellipsis",
              title: factory.value
            }, _toDisplayString(factory.value), 9, _hoisted_5),
            (factory.isShow)
              ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                  key: 0,
                  style: {"font-size":"18px"}
                }))
              : _createCommentVNode("", true),
            (!factory.isShow)
              ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                  key: 1,
                  style: {"font-size":"18px"}
                }))
              : _createCommentVNode("", true)
          ], 8, _hoisted_4),
          (factory.isShow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(factory.children, (group, index2) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: "w-full",
                    style: {"margin-top":"30px","margin-left":"10px"},
                    key: index2
                  }, [
                    _createElementVNode("div", {
                      class: "w-full flex items-center cursor-pointer",
                      style: {"font-size":"16px"},
                      onClick: ($event: any) => (group.isShow = !group.isShow)
                    }, [
                      _cache[2] || (_cache[2] = _createElementVNode("img", {
                        style: {"width":"16px"},
                        src: _imports_1,
                        alt: ""
                      }, null, -1)),
                      _createElementVNode("div", {
                        class: "flex-1 ml-2 ellipsis",
                        title: group.value
                      }, _toDisplayString(group.value), 9, _hoisted_8),
                      (group.isShow)
                        ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                            key: 0,
                            style: {"font-size":"16px"}
                          }))
                        : _createCommentVNode("", true),
                      (!group.isShow)
                        ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                            key: 1,
                            style: {"font-size":"16px"}
                          }))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_7),
                    (group.isShow)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.children, (machine, index3) => {
                            return _withDirectives((_openBlock(), _createElementBlock("div", {
                              class: "w-full",
                              style: {"margin-top":"20px","margin-left":"10px"},
                              key: index3
                            }, [
                              _createElementVNode("div", {
                                onClick: ($event: any) => (selectType(machine, _unref(machineTypeListAll))),
                                class: _normalizeClass([{ 'select-machine': machine.id === _unref(selectId) }, "w-full flex items-center machine"]),
                                style: {"font-size":"14px"}
                              }, [
                                _createElementVNode("div", _hoisted_11, _toDisplayString(machine.value), 1)
                              ], 10, _hoisted_10)
                            ])), [
                              [_vShow, !machine.isHide]
                            ])
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])), [
                    [_vShow, !group.isHide]
                  ])
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])), [
          [_vShow, !factory.isHide]
        ])
      }), 128))
    ])
  ]))
}
}

})